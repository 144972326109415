import React from "react";

const HorizontalConnectionIcon = ({ color = "var(--textColor)" }) => (
	<svg width="37" height="19" viewBox="0 0 37 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M27.4583 0.541668H20.2916V4.125H27.4583C30.4145 4.125 32.8333 6.54375 32.8333 9.5C32.8333 12.4563 30.4145 14.875 27.4583 14.875H20.2916V18.4583H27.4583C32.4033 18.4583 36.4166 14.445 36.4166 9.5C36.4166 4.555 32.4033 0.541668 27.4583 0.541668ZM16.7083 14.875H9.54159C6.58534 14.875 4.16659 12.4563 4.16659 9.5C4.16659 6.54375 6.58534 4.125 9.54159 4.125H16.7083V0.541668H9.54159C4.59659 0.541668 0.583252 4.555 0.583252 9.5C0.583252 14.445 4.59659 18.4583 9.54159 18.4583H16.7083V14.875ZM11.3333 7.70833H25.6666V11.2917H11.3333V7.70833Z"
			fill={color}
		/>
	</svg>
);

export default HorizontalConnectionIcon;
