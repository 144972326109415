import numberFormatter from "src/js/Utilities/numberFormatter";

const COLUMNS = ["Price", "Quantity", "Discount", "DeliveredQuantity", "OrderedQuantity"];

export const formatRowNumberToRead = (number: number): any => {
	return numberFormatter({
		value: formatRowNumberToWrite(String(number || 0)),
		minimumFractionDigits: 2,
		removeCurrencySymbol: true,
	});
};

export const formatRowToRead = (propsRow: any): any => {
	const row = { ...(propsRow || {}) };

	COLUMNS.forEach((column) => {
		if (column in row) {
			row[column] = formatRowNumberToRead(row[column]) as unknown as number;
		}
	});

	return row;
};

export const formatRowNumberToWrite = (number: string): any => {
	return parseFloat((number || 0).toString().replace(/−/g, "-").replace(/,/g, ".").replace(/\s/g, ""));
};

export const formatRowToWrite = (propsRow: any): any => {
	const row = { ...(propsRow || {}) };

	COLUMNS.forEach((column) => {
		if (column in row) {
			row[column] = row[column] ? formatRowNumberToWrite(row[column]) : 0;

			if (["Quantity", "DeliveredQuantity", "OrderedQuantity"].includes(column)) {
				row[column] = String(row[column]);
			}
		}
	});

	return row;
};
