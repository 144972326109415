import React, { PureComponent } from "react";
import { Tooltip, Icon } from "@shopify/polaris";
import { CaretUpMinor, CaretDownMinor } from "@shopify/polaris-icons";

export default class SimpleSortButton extends PureComponent {
	constructor(props) {
		super(props);

		this.sortableColumns = ["title", "group", "board_number", "person", "created_at", "datetime", "timeline", "number", "text", "timetrack"];

		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(props) {}

	haveSort(columnId) {
		for (let i = 0; i < this.props.sorting.length; i++) {
			if (this.props.sorting[i].column_id + "" == columnId + "") {
				return this.props.sorting[i].order;
			}
		}
		return null;
	}

	onClick(event) {
		event.preventDefault();
		const myOrder = this.haveSort(this.props.column_id);
		if (this.props.onClick) this.props.onClick();
		if (myOrder) {
			if (myOrder == "DESC") {
				let myIndex = null;

				for (let i = 0; i < this.props.sorting.length; i++) {
					if (this.props.sorting[i].column_id + "" == this.props.column_id + "") {
						myIndex = i;
						break;
					}
				}
				this.props.sorting.splice(myIndex, 1);
				this.props.onChangeSorting(this.props.sorting);
				// Remove sorting
				return;
			}
			for (let i = 0; i < this.props.sorting.length; i++) {
				if (this.props.sorting[i].column_id + "" == this.props.column_id + "") {
					this.props.sorting[i].order = this.props.sorting[i].order == "ASC" ? "DESC" : "ASC";
					this.props.onChangeSorting(this.props.sorting);
					return;
				}
			}
		} else {
			this.props.sorting.splice(0, this.props.sorting.length);
			this.props.sorting.push({ column_id: this.props.column_id, order: "ASC" });
			this.props.onChangeSorting(this.props.sorting);
		}
	}

	render() {
		if (this.props.column_id != "title" && this.sortableColumns.indexOf(this.props.column_type) < 0) {
			return <span />;
		}

		let order = "ASC";

		const myOrder = this.haveSort(this.props.column_id);
		if (myOrder) {
			order = myOrder;
		}

		return (
			<div className={"sort-button simple" + (myOrder ? " active" : "") + " inline"}>
				<div className="container" onClick={this.onClick.bind(this)}>
					<Tooltip
						content={
							myOrder
								? order == "ASC"
									? this.props.t("column.header.sort.ASC", "Sortera stigande")
									: this.props.t("column.header.sort.DESC", "Sorterar fallande")
								: this.props.t("column.header.sort.choose", "Klicka för att sortera")
						}
					>
						<span className="icon">
							<Icon source={order == "ASC" ? CaretUpMinor : CaretDownMinor} color="inkLighter" />
						</span>
					</Tooltip>
				</div>
			</div>
		);
	}
}
