// eslint-disable-next-line max-classes-per-file
import { Icon, Popover } from "@shopify/polaris";
import { MobileCancelMajor, MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { store } from "../../store/index";

class TabsBar extends Component {
	constructor(props) {
		super(props);
		this.state = { visibilityState: {} };
		this.navbar = React.createRef();
		this.observer = null;
	}

	handleIntersection(entries) {
		const newVisibilityState = entries.reduce((acc, entry) => {
			const targetid = entry.target.dataset.targetid;
			// if visible
			acc[targetid] = entry.isIntersecting && entry.intersectionRatio >= 0.99;
			return acc;
		}, {});

		// if (overflowBtn) {
		// 	const overflowBtn = document.querySelector("#overflow-button");
		// 	const visibleItems = document.querySelector(".DashboardTabs-tabs").querySelectorAll(".item.visible");
		// 	const width = Array.from(visibleItems).reduce((acc, item) => item.clientWidth + acc, 0);
		// 	const wrapperWidth = document.querySelector(".DashboardTabs-tabs").clientWidth;
		// 	overflowBtn.style.width = wrapperWidth - width + "px";
		// }
		this.setState((c) => ({ visibilityState: Object.assign(c.visibilityState || {}, newVisibilityState) }));
	}

	componentDidMount() {
		if (this.navbar.current) {
			this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
				root: this.navbar.current,
				threshold: [0, 0.5, 1],
			});

			this.updateObservers();
		}
	}

	UNSAFE_componentWillReceiveProps() {
		this.updateObservers();
	}

	componentDidUpdate() {
		this.updateObservers();
	}

	componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	updateObservers() {
		if (this.navbar.current) {
			Array.from(this.navbar.current.children).forEach((element) => {
				if (element.dataset.targetid) {
					this.observer.observe(element);
				} else if (element.children[0] && element.children[0].dataset.targetid) {
					element.className = "dropdown-trigger";
					this.observer.observe(element.children[0]);
				}
			});
		}
	}

	render() {
		const isAdmin = store.getState().user.roles.includes("ROLE_ADMIN");

		return (
			<div className="DashboardTabs" style={{ backgroundColor: this.props.backgroundColor }}>
				<div
					className="DashboardTabs-tabs"
					// style={Object.values(this.state.visibilityState).some((visible) => visible) ? { width: "calc(100% - 50px)" } : {}}
					ref={this.navbar}
				>
					{this.props.children}
					{this.props.tabs &&
						this.props.tabs
							.filter((tab) => !tab.admin || isAdmin)
							.map((tab = {}, index) => {
								const visible = !this.state.visibilityState || !(tab.id in this.state.visibilityState) || this.state.visibilityState[tab.id];

								return (
									<div className={`item${visible ? " visible" : " inVisible"}`} key={tab.id || index} data-targetid={tab.id} onClick={tab.onAction}>
										{this.props.renderItem ? this.props.renderItem(tab) : tab.content || tab.id}
									</div>
								);
							})}
					{this.props.onDismiss && (
						<span onClick={this.props.onDismiss} className="dismiss_button">
							<Icon source={MobileCancelMajor} />
						</span>
					)}
					<OverflowMenu
						renderItem={this.props.renderItem}
						visibilityState={this.state.visibilityState}
						selectedTab={this.props.selectedTab}
						tabs={this.props.tabs}
						backgroundColor={this.props.backgroundColor}
					/>
				</div>
			</div>
		);
	}
}
export default TabsBar;

class OverflowMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleOpen() {
		this.setState((c) => ({ active: !c.active }));
	}

	handleClose() {
		this.setState({ active: false });
	}

	onClick(tab) {
		this.handleClose();
		if (tab.onAction) {
			tab.onAction();
		}
	}

	render() {
		const { tabs, visibilityState, selectedTab } = this.props;
		const inVisibleTabs = tabs.filter((tab) => visibilityState && tab.id in visibilityState && !visibilityState[tab.id]) || [];

		if (!inVisibleTabs || !inVisibleTabs.length) return null;

		const itemInOverflowIsAcitve = inVisibleTabs.flatMap((tab) => tab.dropdownItems || tab).some((tab) => tab.id === selectedTab);

		const ele = document.querySelector(".overflow-button");
		if (ele && ele.parentElement && !ele.parentElement.classList.contains("overflow-button-parent")) {
			ele.parentElement.classList.add("overflow-button-parent");
		}

		return (
			<Popover
				active={this.state.active}
				activator={
					<button
						className={`transparentButton${itemInOverflowIsAcitve ? " active" : ""} overflow-button`}
						id="overflow-button"
						onClick={this.handleOpen.bind(this)}
						style={{ backgroundColor: this.props.backgroundColor }}
					>
						<Icon source={MobileVerticalDotsMajor} />
					</button>
				}
				onClose={this.handleClose.bind(this)}
			>
				<div className="dropdown-container" style={{ backgroundColor: this.props.backgroundColor }}>
					{inVisibleTabs.map((tab = {}, index) => (
						<div
							className={`Polaris-ActionList__Item item dropdown-item${selectedTab === tab.id ? " active" : ""}`}
							key={tab.id || index}
							onClick={this.onClick.bind(this, tab)}
							data-targetid={tab.id}
						>
							{this.props.renderItem ? this.props.renderItem(tab) : tab.content || tab.id}
						</div>
					))}
				</div>
			</Popover>
		);
	}
}
