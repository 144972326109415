export default (state = {}, action) => {
	switch (action.type) {
		case "SET_WORKSPACES":
			return action.workspaces;
		case "ADD_WORKSPACE": {
			const found = state.find((work) => String(work.id) === String(action.workspace.id));
			if (found) return [...state];
			return [...state, action.workspace];
		}
		case "UPDATE_WORKSPACE": {
			const index = state.findIndex((work) => String(work.id) === String(action.workspace.id));
			if (index >= 0) {
				state.splice(index, 1, action.workspace);
			}
			return [...state];
		}
		case "REMOVE_WORKSPACE": {
			const index = state.findIndex((work) => String(work.id) === String(action.id));
			if (index >= 0) {
				state.splice(index, 1);
			}
			return [...state];
		}
		case "REMOVE_BOARD_FROM_WORKSPACES": {
			const workspaces = state.map((workspace) => {
				const boards = workspace.boards.filter((board) => String(board.id) !== String(action.board.id));
				return { ...workspace, boards };
			});

			return workspaces;
		}
		default:
			return state;
	}
};
