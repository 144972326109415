import React, { Component } from "react";
import { Badge, Text, LegacyStack, ResourceList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import IndexTable from "src/js/components/IndexTable/IndexTable";

class FacebookIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderItem(item) {
		return (
			<ResourceList.Item
				id={item.id}
				onClick={() => {
					window.location.href = "/api/facebook/accounts/" + item.account_id + ".json?name=" + item.name;
				}}
			>
				<LegacyStack>
					<div>{item.name}</div>
					{item.business ? <Badge>{item.business.name}</Badge> : null}
				</LegacyStack>
				<div>
					<Text variation="subdued">{item.account_id}</Text>
				</div>
			</ResourceList.Item>
		);
	}

	render() {
		return (
			<div>
				<IndexTable
					history={this.props.history}
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					title={this.props.t("ad_accounts.facebook.title", "Annonskonton på facebook")}
					resourceUrl="/api/facebook/accounts.json"
					resourceHandle="accounts"
					resourceName={{
						singular: this.props.t("ad_accounts.facebook.singular", "annonskonto"),
						plural: this.props.t("ad_accounts.facebook.plural", "annonskonton"),
					}}
					noHeader
					columns={[{ render: this.renderItem.bind(this) }]}
				/>
			</div>
		);
	}
}

export default withTranslation(["facebook", "common"], { withRef: true })(FacebookIndex);
