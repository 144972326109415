import React, { useEffect, useMemo, useState } from "react";
import Banner from "./components/banner";
import { Trans, withTranslation } from "react-i18next";
import Button from "./components/Button";
import { ButtonGroup, FormLayout } from "@shopify/polaris";

const BrowserWarningBanner = ({ t }) => {
	// const supported = ["chrome", "firefox", "Safari", "Opera", "Edge", "MSIE", "Trident"];
	const [show, setShow] = useState(false);
	const supported = useMemo(() => ["chrome", "firefox"], []);

	useEffect(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		const isElectron = userAgent.includes("electron");

		if (!isElectron && !supported.some((browser) => userAgent.includes(browser))) {
			setShow(true);
		}
	}, [supported]);

	if (show) {
		return (
			<div>
				<Banner
					style={{
						padding: "3rem",
					}}
					asOverlay
					title={t("common.browserWarning.title", "Denna webbläsare stöds inte")}
					status="warning"
					backgroundColor={"#5e4e11f8"}
					onDismiss={() => setShow(false)}
				>
					<FormLayout>
						<div>
							<Trans
								i18nKey="common.browserWarning.content"
								defaults="<b>Sellfinity</b> är optimerad för följande webbläsare: {{supported}}."
								values={{ supported: supported?.map((b: any) => b.capitalize()).join(", ") }}
								components={{
									b: <b />,
								}}
							/>
							<p>{t("common.browserWarning.content2", "För att undvika problem rekommenderar vi att du använder en av dessa webbläsare")}</p>
						</div>

						<ButtonGroup>
							<Button url="https://www.google.com/chrome/">Ladda ner Chrome</Button>

							<Button url="https://www.mozilla.org/firefox/new/">Ladda ner Firefox</Button>
						</ButtonGroup>
					</FormLayout>
				</Banner>
			</div>
		);
	}

	return null;
};
export default withTranslation(["common"])(BrowserWarningBanner);
