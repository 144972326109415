import React, { Component } from "react";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import svLocale from "@fullcalendar/core/locales/sv";

import { withTranslation } from "react-i18next";
import $ from "jquery";
import Page from "src/js/components/page";
import API from "../../../../API";
import CalendarPopupPreview from "../CalendarModal/CalendarPopupPreview";
import RowSheet from "../RowSheet";
import CalendarHeader from "./CalendarHeader";
import SyncCalendarModal from "../SyncCalendarModal";
import Banner from "../../../../components/banner";
import BoardHelper from "../../BoardHelper.js";
import { colorToRgba } from "../../../../Colors.js";
import Toast from "src/js/components/Toast";

class FixedCalendarView extends Component {
	constructor(props) {
		super(props);
		this.state = { events: [], showWeekends: false };
	}

	componentDidMount() {
		this.updateView(this.props);
		this.updateDefaultValues();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings == this.props.showSettings) {
			this.updateView(props);
		}
	}

	updateState(state, callback) {
		this.setState(state, callback);
	}

	updateDefaultValues() {
		const values = [];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			const column = this.props.board.columns[i];
			if (column.type == "datetime" || column.type == "timeline") {
				if (this.props.view.value_columns.length < 1 || this.props.view.value_columns.indexOf(column.id) >= 0) {
					if (column.type == "datetime") {
						const val = { datetime: moment().format("YYYY-MM-DD HH") + ":00" };
						values.push({ column_id: column.id, value: val });
					}
					if (column.type == "timeline") {
						const val = { start: moment().format("YYYY-MM-DD HH") + ":00", end: moment().add("1", "hours").format("YYYY-MM-DD HH") + ":00" };
						values.push({ column_id: column.id, value: val });
					}
				}
			}
		}
		this.props.onChangeDefaultValues(values);
		// this.setState({ defaultValues: values });
	}

	getColorByName(name) {
		const colors = ["#47c1bf", "#de3618", "#f49342", "#50b83c", "#006fbb", "#9c6ade"];

		if (!name) {
			return colors[0];
		}
		return colors[name.charCodeAt(0) % colors.length];
	}

	updateView(props) {
		let colorByColumn = null;
		if (props.view.color_by != "group") {
			for (let b = 0; b < props.board.columns.length; b++) {
				if (props.board.columns[b].id == props.view.color_by) {
					colorByColumn = props.board.columns[b];
					break;
				}
			}
		}

		const events = [];

		const columns = {};
		for (let a = 0; a < props.board.columns.length; a++) {
			columns[props.board.columns[a].id + ""] = props.board.columns[a];
		}

		for (let i = 0; i < props.rows.length; i++) {
			const row = props.rows[i];
			let color = "#666666";

			if (colorByColumn && (colorByColumn.type == "status" || colorByColumn.type == "person")) {
				for (let s = 0; s < row.values.length; s++) {
					const value = row.values[s];
					const column = columns[value.column_id + ""];
					if (column.id == colorByColumn.id) {
						if (value.value || value.value === 0 || value.value === "0") {
							if (colorByColumn.type == "status") {
								for (let x = 0; x < colorByColumn.options.statuses.length; x++) {
									if (colorByColumn.options.statuses[x].id == value.value) {
										color = colorByColumn.options.statuses[x].color;
										break;
									}
								}
							} else if (colorByColumn.type == "person" && value.value.length) {
								for (let c = 0; c < this.props.board.members.length; c++) {
									if (this.props.board.members[c].user_id == value.value[0]) {
										color = this.getColorByName(this.props.board.members[c].title);
										break;
									}
								}
							}
						}
					}
				}
			} else {
				for (let r = 0; r < props.groups.length; r++) {
					if (props.groups[r].id == row.group_id) {
						color = props.groups[r].color;
						break;
					}
				}
			}

			row.values?.forEach((value) => {
				const column = columns[value.column_id + ""];

				if ((column && column.type == "datetime") || (column && column.type == "timeline")) {
					if (value.value && (props.view.value_columns.length < 1 || props.view.value_columns.indexOf(column.id) >= 0)) {
						let taskStart = null;
						let taskEnd = null;

						if (column.type == "datetime") {
							if (value.value.datetime && value.value.datetime.length > 10) {
								// Have time
								taskStart = value.value.datetime + ":00";
								taskEnd = moment(value.value.datetime).add(1, "hour").format("YYYY-MM-DD HH:mm") + ":00";
							} else {
								// Only date
								taskStart = value.value.datetime + " 00:00:00";
								taskEnd = moment(value.value.datetimep).add(1, "day").format("YYYY-MM-DD") + " 00:00:00";
							}
						} else if (column.type == "timeline") {
							if (value.value.isAllDay && value.value.start.length < 15) {
								taskStart = moment(value.value.start).format("YYYY-MM-DD") + " 00:00";
								taskEnd = moment(value.value.end).format("YYYY-MM-DD") + " 23:59";
							} else {
								taskStart = value.value.start + ":00";
								taskEnd = value.value.end + ":00";
							}
						}

						events.push({
							id: row.id + "_" + column.id,
							start: taskStart.replace(" ", "T"),
							end: taskEnd.replace(" ", "T"),
							column,
							row,
							backgroundColor: colorToRgba(color, 0.2),
							color,
							borderColor: color,
							title: row.title,
							startEditable: column.type == "timeline",
							durationEditable: column.type == "timeline",
							editable: true,
						});
					}
				}
			});
		}

		this.setState({
			events: events.sort((a, b) => moment(a.start).unix() - moment(b.start).unix()),
		});
	}

	getRowModalValues(e) {
		const dateColumn = this.props.board.columns.find((c) => c.type === "datetime" || c.type === "timeline");
		const startDate = (() => {
			if (e.allDay) {
				return moment(e.dateStr + " 08:00").format("YYYY-MM-DD hh:mm");
			}
			return moment(e.dateStr).format("YYYY-MM-DD hh:mm");
		})();

		const values = [
			{
				column_id: dateColumn.id,
				value: { start: startDate, end: moment(startDate).add(1, "h").format("YYYY-MM-DD hh:mm") },
			},
		];

		return values;
	}

	getOptions() {
		const view = this.props.board && this.props.board.views && this.props.board.views.find((view) => view.type === "calendar");
		return view && view.options;
	}

	render() {
		const syncExists = BoardHelper.getColumnIntegrationActive(this.props.board);
		const syncExpired = BoardHelper.getColumnIntegrationIsExpired(this.props.board);

		return (
			<Page fullWidth>
				{syncExpired && (
					<Banner
						title={
							syncExists ? this.props.t("calendar.reconnect.title", "Återkoppla kalender") : this.props.t("calendar.connect.title", "Koppla kalender")
						}
						status={syncExists ? "warning" : "info"}
						dismissible
						actions={[
							{
								content: syncExists
									? this.props.t("calendar.reconnect.title", "Återkoppla kalender")
									: this.props.t("calendar.connect.title", "Koppla kalender"),
								onAction: () => this.setState({ showSyncCalendarModal: true }),
								primary: true,
							},
						]}
						style={{ marginBottom: "0.6250rem" }}
					>
						<span>
							{syncExists
								? this.props.t("calendar.reconnect.description", "Din kalendar har förlorat kopplingen mot Outlook/Gmail, återkoppla din kalendar.")
								: this.props.t("calendar.connect.description", "Koppla din Outlook eller Gmail kalendar")}
						</span>
					</Banner>
				)}
				<div className="fixed_calendar">
					<CalendarHeader
						showWeekends={this.state.showWeekends}
						calendar={this.calendar}
						updateState={this.updateState.bind(this)}
						board={this.props.board}
					/>
					<div className="fixed_calendar_body">
						{/* <div className="calendar_view_header">
						<Button
							icon={ChevronLeftMinor}
							onClick={() => {
								this.calendar && this.calendar.getApi().prev();
								this.setState({ rnd: Math.random() });
							}}
						/>
						<h2>{viewTitle}</h2>
						<Button
							icon={ChevronRightMinor}
							onClick={() => {
								this.calendar && this.calendar.getApi().next();
								this.setState({ rnd: Math.random() });
							}}
						/>
					</div> */}
						<FullCalendar
							ref={(calendar) => {
								this.calendar = calendar;
							}}
							dateClick={(e) => {
								const values = this.getRowModalValues(e);
								// this.props.onCreateRow(null, null, values);
								this.setState({
									open: true,
									row: {
										column_values: values.reduce((acc, value) => {
											acc[value.column_id] = value;
											return acc;
										}, {}),
										values,
									},
								});
							}}
							dayMaxEventRows
							// views={{
							// 	timeGrid: {
							// 		dayMaxEventRows: 5,
							// 	},
							// }}
							// contentHeight={200}
							// aspectRatio={2}
							initialView="timeGridWeek"
							allDaySlot={false}
							eventClick={(e) => {
								const { event, el } = e;
								this.calendarPopup.open(event.extendedProps.row, event.extendedProps.column, this.props.board, event.backgroundColor, el);
							}}
							eventResize={(info) => {
								const event = info.event;
								const newStart = event.start.toISOString();
								const newEnd = event.end.toISOString();

								const data = { value: { start: moment(newStart).format("YYYY-MM-DD HH:mm"), end: moment(newEnd).format("YYYY-MM-DD HH:mm") } };

								API.post(
									"/" +
										"api" +
										"/boards/" +
										this.props.board.id +
										"/rows/" +
										event.extendedProps.row.id +
										"/columns/" +
										event.extendedProps.column.id +
										"/values.json",
									data,
									{ params: {} }
								)
									.then((result) => {
										if (result.data.error) {
											Toast.error(result.data.error);
											return;
										}
										this.props?.onUpdateRow?.(result.data.row);
									})
									.catch((error) => Toast.error(error));
							}}
							eventDrop={(info) => {
								const event = info.event;
								const newStart = event.start.toString();
								const newEnd = event.end.toString();
								// if (info.newResource) {
								// 	let newSlotId = info.newResource.id;
								// } else {
								// 	let newSlotId = event.resourceId;
								// }

								const row = event.extendedProps.row;
								let data = null;

								if (event.extendedProps.column.type == "timeline") {
									data = { value: { start: moment(newStart).format("YYYY-MM-DD HH:mm"), end: moment(newEnd).format("YYYY-MM-DD HH:mm") } };
								} else if (event.extendedProps.column.type == "datetime") {
									const currentValue = row.values?.find((v) => String(v.column_id) === String(event.extendedProps.column.id));

									if (currentValue.value.datetime && currentValue.value.datetime.length > 10) {
										// Have time
										data = { value: { datetime: moment(newStart).format("YYYY-MM-DD HH:mm") } };
									} else {
										// Only date
										data = { value: { datetime: moment(newStart).format("YYYY-MM-DD") } };
									}
								}

								for (let v = 0; v < row.values.length; v++) {
									if (row.values[v].column_id == event.extendedProps.column.id) {
										Object.assign(row.values[v], data);
										break;
									}
								}
								API.post(
									"/api/boards/" + this.props.board.id + "/rows/" + row.id + "/columns/" + event.extendedProps.column.id + "/values.json",
									data,
									{ params: {} }
								)
									.then((result) => {
										if (result.data.error) {
											Toast.error(result.data.error);
										}
									})
									.catch((error) => Toast.error(error));

								if (this.props.onUpdateRow) this.props.onUpdateRow(row);
							}}
							nowIndicator
							height={
								$(window).height() - (($(".Polaris-TopBar")?.length ? 56 : 0) + (($(".calendar_header__wrapper")?.height() || 0) + 50) + 20 + 30)
							}
							firstDay={1}
							businessHours={{
								daysOfWeek: [1, 2, 3, 4, 5],
								startTime: (this.getOptions() && this.getOptions().workStart) || "08:00",
								endTime: (this.getOptions() && this.getOptions().workEnd) || "17:00",
							}}
							slotDuration="00:15:00"
							slotLabelInterval={{ hours: 1 }}
							// slotMinTime="08:00:00"
							locale={svLocale}
							slotLabelFormat={{
								hour: "numeric",
								minute: "2-digit",
								omitZeroMinute: false,
								meridiem: "short",
							}}
							headerToolbar={{
								left: "",
								center: "",
								right: "",
							}}
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
							events={this.state.events}
							weekends={this.state.showWeekends}
							displayEventEnd={false}
							eventTimeFormat={{
								hour: "numeric",
								minute: "2-digit",
								meridiem: false,
							}}
						/>
					</div>

					<CalendarPopupPreview
						ref={(r) => {
							this.calendarPopup = r;
						}}
					/>

					<RowSheet
						open={this.state.open}
						row={this.state.row}
						board={this.props.board}
						initialTabIndex={0}
						onUpdateRow={this.props.onUpdateRow}
						onClose={() => this.setState({ open: false })}
					/>

					{this.props.board &&
						this.props.board.columns.map((column) => {
							if (column.type == "timeline") {
								return (
									<SyncCalendarModal
										key={column.id}
										open={this.state.showSyncCalendarModal}
										onClose={() => this.setState({ showSyncCalendarModal: false })}
										onOpen={() => this.setState({ showSyncCalendarModal: true })}
										onUpdateColumn={(column) => {}}
										column={column}
										board={this.props.board}
										title={this.props.t("calendar.actions.connect.one", "Koppla en kalender")}
									/>
								);
							}

							return null;
						})}
				</div>
			</Page>
		);
	}
}
export default withTranslation(["calendar", "common"], { withRef: true })(FixedCalendarView);
