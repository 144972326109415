import React, { Component } from "react";
import { ExceptionList, Icon, Tooltip, TextField, FormLayout } from "@shopify/polaris";
import moment from "moment";
import { EmailMajor, SocialAdMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../../store";
import ProfileAvatar from "../../components/ProfileAvatar";
import IndexTable from "src/js/components/IndexTable";
import Modal from "src/js/components/modal";

class VisitorsConversions extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const columns = [
			{
				handle: "TITLE",
				label: this.props.t("common.conversions.columns.title.label", "Händelse"),
				sortable: false,
				type: "text",
				render: (item) => {
					const exceptions = [];
					if (item.content) {
						exceptions.push({
							icon: "notes",
							description: item.content,
						});
					}

					const exceptionList = exceptions.length ? (
						<div className="CustomerListItem__Exceptions">
							<ExceptionList items={exceptions} />
						</div>
					) : null;
					return (
						<div>
							<div className="CustomerListItem__Profile">
								<h3 className="CustomerListItem__Title">{item.title}</h3>
								<span className="CustomerListItem__Location" />
							</div>
							{exceptionList}
						</div>
					);
				},
			},
			{
				handle: "SOURCE",
				label: this.props.t("common.conversions.columns.source.label", "Källa"),
				sortable: false,
				type: "text",
				render: (item) => {
					const exceptions = [];

					const title = (() => {
						if (item.email_campaign) return item.email_campaign.title;
						if (item.ad) return item.ad.title;
						return "-";
					})();
					const icon = (() => {
						if (item.email_campaign) return EmailMajor;
						if (item.ad) return SocialAdMajor;
						return null;
					})();

					const tooltip = (() => {
						if (item.email_campaign) return this.props.t("common.conversions.text.email", "Email");
						if (item.ad) return this.props.t("common.conversions.text.ad", "Ad");
						return null;
					})();

					const exceptionList = exceptions.length ? (
						<div className="CustomerListItem__Exceptions">
							<ExceptionList items={exceptions} />
						</div>
					) : null;
					return (
						<div>
							<div className="CustomerListItem__Profile">
								{icon && (
									<Tooltip content={tooltip}>
										<Icon source={icon} />
									</Tooltip>
								)}
								<h3 className="CustomerListItem__Title">{title}</h3>
								<span className="CustomerListItem__Location" />
							</div>
							{exceptionList}
						</div>
					);
				},
			},
			{
				handle: "CONTACT",
				label: this.props.t("common.conversions.columns.contact.label", "Kontakt"),
				sortable: false,
				type: "text",
				render: (item) => {
					if (!item.contact) return "-";

					return (
						<ProfileAvatar
							size="small"
							onClick={() => {
								this.props.history.push("/admin/contacts/" + item.contact.id);
							}}
							user={item.contact}
						>
							{item.contact.name}
						</ProfileAvatar>
					);
				},
			},
			{
				handle: "DATE",
				label: this.props.t("common.conversions.columns.date.label", "Datum"),
				sortable: false,
				type: "text",
				render: (item) => (item.created_at ? moment(item.created_at).locale("sv").calendar() : "–"),
			},
		];

		const filters = [];
		return (
			<React.Fragment>
				<IndexTable
					history={this.props.history}
					title={this.props.t("common.conversions.title", "Konverteringar")}
					secondaryActions={[
						{
							content: this.props.t("common.conversions.actions.tracking_code", "Spårningskod"),
							onAction: () => {
								this.setState({ showTrackingCodeModal: true });
							},
						},
						{
							content: this.props.t("common.conversions.actions.conversions_code", "Konverteringskod"),
							onAction: () => {
								this.setState({ showConversionCodeModal: true });
							},
						},
					]}
					// primaryAction={{ content: "Ny e-postkampanj", url: "/admin/email_campaigns/new" }}
					savedSearchHandle="visitors_conversions"
					resourceUrl="/api/visitors_conversions.json"
					resourceHandle="visitors_conversions"
					resourceName={{
						singular: this.props.t("common.conversions.singular", "conversion"),
						plural: this.props.t("common.conversions.plural", "conversions"),
					}}
					defaultSort="DATE"
					// onClickRow={(item) => {
					// 	this.gotoCampaign(item);
					// }}
					columns={columns}
					filters={filters}
				/>

				<Modal
					open={this.state.showConversionCodeModal}
					onClose={() => {
						this.setState({ showConversionCodeModal: false });
					}}
					title={this.props.t("common.conversions.actions.conversions_code", "Konverteringskod")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showConversionCodeModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("common.conversions.fields.sales_tracking_code.label", "Kod för att spåra försäljning på din hemsida")}
								multiline
								value={
									"<script>\n\twindow.__ct = window.__ct||[];\n\twindow.__ct.push({\n\t\tid: '55',\t\t//Unikt id för konverteringen\n\t\ttype: 'sale',\t\t//Ett av event, lead eller sale\n\t\ttitle: 'Köp på hemsida',\t\t//Namn för din konvertering\n\t\tvalue: 500,\t\t//Värdet\n\t\tcurrency: 'SEK'\t\t//Valutakod\n\t});\n</script>"
								}
							/>
							<TextField
								label={this.props.t("common.conversions.fields.leads_tracking_code.label", "Kod för att spåra lead på din hemsida")}
								multiline
								value={
									"<script>\n\twindow.__ct = window.__ct||[];\n\twindow.__ct.push({\n\t\ttype: 'lead',\t\t//Ett av event, lead eller sale\n\t\ttitle: 'Nytt demokonto'\t\t//Namn för din konvertering\n\t});\n</script>"
								}
							/>
							<TextField
								label={this.props.t("common.conversions.fields.events_tracking_code.label", "Kod för att spåra händelse")}
								multiline
								value={
									"<script>\n\twindow.__ct = window.__ct||[];\n\twindow.__ct.push({\n\t\ttype: 'event',\t\t//Ett av event, lead eller sale\n\t\ttitle: 'Tryckte på knappen'\t\t//Namn för din konvertering\n\t});\n</script>"
								}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
				<Modal
					open={this.state.showTrackingCodeModal}
					onClose={() => {
						this.setState({ showTrackingCodeModal: false });
					}}
					title={this.props.t("common.conversions.titles.tracking_code", "Spårningskod")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showTrackingCodeModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("common.conversions.fields.copy_script.label", "Kopiera och klistra in följande kod på din hemsida")}
								multiline
								value={
									'<script>\n\twindow.__st=window.__st || {"offset":3600};\n\t//User id: window.__st.uid=4;\n\t//User e-mail: window.__st.umail=\'user@example.com\';\n</script>\n<script src="https://' +
									store.getState().account.handle +
									".sellfinity.com/tracking/" +
									store.getState().account.id +
									'.js"></script>'
								}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</React.Fragment>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(VisitorsConversions);
