import React from "react";
import Colors from "../Colors";

const SellfinityLogo = ({ color = Colors.accent }) =>
	window.location.href.includes("acordo.se") || window.location.href.includes("nollettnollnoll.se") ? (
		<svg width="59" height="57" viewBox="0 0 22 20" fill="none">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M9 3L17.6931 20H22L13.3075 3H9Z" fill={color} />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5892 0L0 20H4.40488L15 0H10.5892Z" fill={color} />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M0.605469 11L2.67891 15H15.2894L13.2153 11H0.605469Z" fill={color} />
		</svg>
	) : (
		<svg width="67" height="69" viewBox="0 0 67 69" fill="none">
			<path
				d="M27.0324 27.857C19.8602 26.5801 14.3531 25.1989 14.3531 19.8829C14.3531 17.555 15.5933 15.6006 17.6747 14.3584C19.4526 13.4724 21.7075 12.9252 24.3526 12.9252C30.5362 12.9252 35.4275 16.4605 35.4275 20.4823C35.4275 21.1598 35.2887 21.82 35.0286 22.4541C36.6243 22.8276 38.4802 23.4356 40.4056 24.2608C43.1114 25.4161 45.4096 26.7625 46.9013 27.9612C47.838 25.6941 48.341 23.1837 48.341 20.4736C48.3323 8.09563 37.8645 0 24.3439 0C24.2919 0 24.2399 0 24.1792 0C24.1445 0 24.1098 0 24.0751 0C22.913 0 21.7855 0.0694904 20.6754 0.191099C20.5887 0.199785 20.4933 0.208471 20.4066 0.217157C20.3112 0.225844 20.2245 0.243216 20.1291 0.251903C12.7313 1.16396 6.85134 4.50819 3.48638 9.60704C1.2662 12.7254 0 16.4345 0 20.4736C0 35.4401 13.4598 38.9841 23.3986 40.6519C27.1278 41.2947 30.354 41.8506 32.5395 43.1014C34.3261 44.4738 35.3148 46.2893 35.3148 48.2263C35.3148 52.2394 31.0305 55.7834 24.2399 55.7834C18.0563 55.7834 13.165 52.2481 13.165 48.2263C13.165 47.5401 13.3037 46.8713 13.5726 46.2285C12.0115 45.6031 9.40108 44.4912 6.92072 43.0493C5.09081 41.9896 3.39965 40.7735 2.12478 39.7746C0.919294 42.2849 0.251505 45.1253 0.251505 48.2263C0.251505 60.613 10.7193 68.7086 24.2399 68.7086C24.873 68.7086 25.4887 68.6825 26.0958 68.6565V68.6739C39.4256 68.0745 49.8327 59.6141 49.8327 48.1395C49.8327 33.0774 36.8585 29.5247 27.0324 27.857Z"
				fill={color}
			/>
			<ellipse cx="58.8711" cy="61.3507" rx="7.35059" ry="7.35777" fill={color} />
		</svg>
	);

export default SellfinityLogo;
