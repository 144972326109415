import React, { Component } from "react";

class NewPopup extends Component {
	constructor(props) {
		super(props);
		this.state = { x: 0, y: 0, height: 0, yOffset: 0, open: false };
		this.width = 420;
		this.onClickOutside = (event) => {
			if (this.wrapper && !this.wrapper.contains(event.target)) this.close();
		};
		this.onScroll = (event) => {
			if (!this.wrapper?.contains(event.target)) this.close();
		};

		this.handleScroll = this.onScroll.bind(this);
		this.handleClickOutside = this.onClickOutside.bind(this);
	}

	componentDidMount() {
		// document.addEventListener("mouseup", this.handleClickOutside);
		// window.addEventListener("wheel", this.onScroll.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener("mouseup", this.handleClickOutside);
		window.removeEventListener("wheel", this.handleScroll);
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	open(domElement) {
		if (!domElement) return null;
		const parentRecs = domElement?.getBoundingClientRect();
		const { height, width, top, left, right } = parentRecs || {};

		if (this.fitsToTheRight(right)) {
			this.setState({ position: "right", x: right, y: Math.max(0, top - window.scrollY) });
			// this.setState({ position: "right", x: right, y: top });
		} else if (this.fitsToTheLeft(left)) {
			this.setState({ position: "left", x: left - this.width, y: Math.max(0, top - window.scrollY) });
			// this.setState({ position: "left", x: left - this.width, y: top });
		} else {
			this.setState({ position: "center", x: width / 2 - this.width / 2 + left, y: Math.max(0, top - window.scrollY) });
			// this.setState({ position: "center", x: width / 2 - this.width / 2 + left, y: top });
		}

		this.initialRender = true;

		this.setState({ eventHeight: height });
	}

	close(closeParent = true) {
		this.setState({ open: false, height: 0 });
		clearInterval(this.interval);
		this.interval = null;
		if (this.props.onClose && closeParent) this.props.onClose();
	}

	checkHeight() {
		const { height } = (this.wrapper && this.wrapper.getBoundingClientRect()) || { height: 0 };
		if (height == this.state.height) {
			return;
		}
		let yOffset;
		let bottomOverflow;

		if (this.state.position == "center" || this.state.position == "top" || this.state.position == "bottom") {
			if (this.state.y + this.state.eventHeight + height < window.innerHeight) {
				yOffset = this.state.eventHeight;
				this.setState({ position: "bottom" });
			} else {
				yOffset = height * -1;
				this.setState({ position: "top" });
			}
		} else {
			yOffset = this.state.eventHeight / 2 - height / 2;
			bottomOverflow = window.innerHeight - (this.state.y + yOffset + height);
			if (bottomOverflow < 0) {
				yOffset += bottomOverflow;
			}
		}
		this.setState({ yOffset, height, bottomOverflow });
	}

	fitsToTheRight(right) {
		if (right + this.width < window.innerWidth) {
			return true;
		}
		return false;
	}

	fitsToTheLeft(left) {
		if (left - this.width > 0) {
			return true;
		}
		return false;
	}

	componentDidUpdate() {
		if (this.initialRender) {
			this.checkHeight();
			this.initialRender = false;
			this.setState({ open: true });
			this.interval = setInterval(this.checkHeight.bind(this), 25);
			this.listener = false;
		}

		if (!this.state.open) {
			window.removeEventListener("wheel", this.handleScroll);
			document.removeEventListener("mouseup", this.handleClickOutside);
			this.listener = false;
		} else {
			if (!this.listener) {
				window.addEventListener("wheel", this.handleScroll);
				document.addEventListener("mouseup", this.handleClickOutside);
			}
			this.listener = true;
		}
	}

	getArrow(primary) {
		const position = {};
		if (this.state.position == "left" || this.state.position == "right") {
			if (this.state.bottomOverflow < 0) {
				position.top = this.state.height / 2 - 15 / 2 - this.state.bottomOverflow;
			} else {
				position.top = this.state.height / 2 - 15 / 2;
			}
		}

		if (this.state.position == "left") {
			position.right = primary ? 2 : 0;
			position.rotate = "90deg";
		} else if (this.state.position == "right") {
			position.left = primary ? 2 : 0;
			position.rotate = "270deg";
		} else if (this.state.position == "top") {
			position.bottom = primary ? 2 : 0;
			position.rotate = "180deg";
			position.left = this.width / 2 - 15 / 2;
		} else if (this.state.position == "bottom") {
			position.top = primary ? 2 : 0;
			position.rotate = "0deg";
			position.left = this.width / 2 - 15 / 2;
		}

		const styles = Object.assign(
			{
				backgroundColor: primary ? "var(--main3)" : "rgb(80,80,80)",
				clipPath: "polygon(50% 0, 100% 100%, 0 100%)",
				height: 15,
				width: 15,
				position: "absolute",
			},
			position
		);
		return <div style={styles} />;
	}

	render() {
		return (
			<div
				ref={(r) => {
					this.wrapper = r;
				}}
				style={{
					position: "fixed",
					zIndex: 999,
					visibility: this.state.open ? "visible" : "hidden",
					width: this.width,
					left: this.state.x,
					top: Math.max((this.wrapper && this.wrapper.getBoundingClientRect().height) || 0, window.scrollY + this.state.y + this.state.yOffset),
					// top: pageYOffset + this.state.y + this.state.yOffset,
				}}
				className="new_popup"
			>
				<div style={{ position: "relative", padding: 15 }}>
					<div
						style={{
							position: "relative",
							backgroundColor: "var(--main3)",
							// border: "1px solid rgb(80,80,80)",
							borderRadius: 6,
							boxShadow: "4px 4px 9px rgb(0, 0, 0,0.2)",
						}}
					>
						{this.props.children}
					</div>
					{/* {this.getArrow(false)} */}
					{this.getArrow(true)}
				</div>
			</div>
		);
	}
}
export default NewPopup;
