import Colors, { colorToRgba } from "../../../Colors";

type GraphData = {
	labels: string[];
	series: { name: string; data: any; color?: string }[];
	loading: boolean;
	type?: string;
};

const getDefaultGraphOptions = ({ labels, series, loading, type = "column" }: GraphData) => {
	const defaultSerie = {
		name: "-",
		data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		color: {
			linearGradient: {
				x1: 1,
				x2: 0,
				y1: 0,
				y2: 0,
			},
			stops: [
				[0, loading ? "rgba(100,100,100, 0.5)" : colorToRgba(Colors.green, 0.75)],
				[1, loading ? "rgba(150,150,150, 0.5)" : Colors.green],
			],
		},
		showInLegend: false,
	};

	const graphData = {
		title: null,
		credits: {
			enabled: false,
		},
		tooltip: {
			borderRadius: 2,
			padding: 15,
			shadow: true,
		},
		chart: {
			type,
			// height,
		},

		yAxis: {
			title: {
				text: null,
			},
			labels: {
				// style: {
				// 	color: "rgba(242, 242, 242, 0.1)",
				// },
			},

			stackLabels: {
				style: {
					color: "var(--textColor2)",
					border: "none",
					textOutline: "none",
				},
			},
			// gridLineColor: "#F2F2F2;",
			gridLineDashStyle: "longdash",
		},
		xAxis: {
			categories: labels,
			//hide the bottom most line on the x-axis
			lineColor: "transparent",
		},
		series:
			series.length > 0
				? [...(series || [])]?.map((serie) => {
						return {
							...defaultSerie,
							stops: [
								[0, loading ? "rgba(100,100,100, 0.5)" : colorToRgba(serie.color, 0.75)],
								[1, loading ? "rgba(150,150,150, 0.5)" : serie.color],
							],
							...serie,

							// id: index,
							// linbkedTo: 0,
							// dataSorting: {
							// 	enabled: true,
							// 	matchByName: true,
							// },
						};
				  })
				: [defaultSerie],
		plotOptions: {
			series: { borderRadius: 4, borderColor: "rgba(0,0,0,0)" },
			column: { pointWidth: 20 },
			// bar: {},
		},

		legend: {
			align: "left",
			x: 10,
			verticalAlign: "top",
			y: 10,
			floating: false,
			backgroundColor: Colors.main3,
			borderColor: Colors.main3,
			borderWidth: 1,
			shadow: false,
		},
		// exporting: {
		// 	allowHTML: true,
		// },
	};

	return graphData;
};

export default getDefaultGraphOptions;
