import Colors from "../../../../Colors";
import { store } from "../../../../store";
import i18n from "../../../../i18n";

class CalendarHelper {
	getContent(value) {
		return value?.content || value?.external_event?.description;
	}

	haveResponded(value) {
		return this.isMeeting(value) && this.getMyResponse(value) !== "notResponded" && this.getMyResponse(value) !== "needsAction";
	}

	isMeeting(value) {
		return value?.attendees?.length > 0;
	}

	isRepliable(value) {
		return !this.isOrganizer(value) && value.external_event && !value.external_event?.error;
	}

	getOrganizer(value) {
		const organizer = value.external_event?.organizer;
		if (organizer) {
			if (organizer.emailAddress) {
				return { email: organizer.emailAddress.address, name: organizer.emailAddress.name };
			}
			return { email: organizer.email, name: organizer.displayName || organizer.email };
		}
		return null;
	}

	isOrganizer(value) {
		if (value?.external_event?.organizer?.self) {
			return true;
		}
		return value.external_event?.isOrganizer;
	}

	getTeamsMeetingUrl(value) {
		return value.external_event?.onlineMeeting && value.external_event?.onlineMeeting.joinUrl;
	}

	getGoogleHangoutUrl(value) {
		return value.external_event?.hangoutLink;
	}

	isEditable(value) {
		return !value.external_event || this.isOrganizer(value);
	}

	getAttendeesStatus(value, att) {
		const foundAttendee = value?.external_event?.attendees?.find?.((attendee) => {
			const email1 = attendee.email || attendee.emailAddress?.address;
			const email2 = att.email || att.emailAddress?.address || att;

			return email1 == email2;
		});

		return foundAttendee?.status || (foundAttendee?.responseStatus ? { response: foundAttendee?.responseStatus } : {});
	}

	getAttendeesByStatus(value) {
		const groupObjects = value.attendees
			.filter(
				(att) =>
					att.email !== store.getState().user.email &&
					att.email != (value?.external_event?.organizer?.email || value?.external_event?.organizer?.emailAddress?.address)
			)
			.reduce((cur, att) => {
				const externalAttendee = value?.external_event?.attendees?.find((attendee) => {
					const email1 = attendee.email || attendee.emailAddress?.address;
					const email2 = att.email || att.emailAddress?.address;

					return email1 == email2;
				});
				const response = externalAttendee?.responseStatus || externalAttendee?.status?.response;
				const label = response || "Kontakter";
				if (!cur[label]) {
					cur[label] = { status: label, attendees: [] };
				}

				cur[label].attendees.push(att);
				return cur;
			}, {});

		return Object.values(groupObjects).sort((a, b) => a.status.localeCompare(b.status));
	}

	getInlineStatusLabel(status) {
		const label = (() => {
			switch (status) {
				case "accepted":
					return i18n.t("calendarhelper.inline_status.accepted", "godkänt");
				case "declined":
					return i18n.t("calendarhelper.inline_status.declined", "nekat");
				case "tentative":
				case "tentativelyAccepted":
					return i18n.t("calendarhelper.inline_status.tentative", "sagt kanske");
				case "none":
				case "notResponded":
				case "needsAction":
					return i18n.t("calendarhelper.inline_status.none", "inte svarat");
				default:
					return i18n.t(`calendarhelper.inline_status.${status || ""}`, status || "");
			}
		})();

		return label;
	}

	getInlineStatusLabel2(status) {
		const label = (() => {
			switch (status) {
				case "accepted":
				case "accept":
					return i18n.t("calendarhelper.inline_status2.accept", "godkändes");
				case "declined":
				case "decline":
					return i18n.t("calendarhelper.inline_status2.decline", "nekades");
				case "maybe":
				case "tentative":
				case "tentativelyAccepted":
					return i18n.t("calendarhelper.inline_status2.tentative", "väntas");
				case "none":
				case "notResponded":
				case "needsAction":
					return i18n.t("calendarhelper.inline_status2.none", "ej svarad");
				default:
					return i18n.t(`calendarhelper.inline_status2.${status || ""}`, status || "");
			}
		})();

		return label;
	}

	getMyResponse(value) {
		return value?.external_event?.responseStatus?.response || value.external_event?.attendees?.find((att) => att?.self)?.responseStatus;
	}

	getTime(date) {
		const time = date?.split(" ")?.[1];
		return time?.trim?.();
	}

	getInlineStatusLabelColor(status) {
		const label = (() => {
			switch (status) {
				case "accepted":
					return Colors.green;
				case "declined":
					return Colors.red;
				case "tentative":
				case "tentativelyAccepted":
					return Colors.blue;
				case "none":
				case "notResponded":
				case "needsAction":
					return "#ffffff";
				case "organizer":
					return Colors.green;
				default:
					return Colors.green;
			}
		})();

		return label;
	}

	/**
	 *
	 * @param {Object} row
	 * @param {Object} board
	 * @returns
	 */
	isRowCreator(row, board) {
		const column = board?.columns.find((col) => (col.type === "datetime" || col.type === "timeline") && col.connected_column_id);
		const noExternalValue = !row?.column_values?.[column?.id]?.value?.external_event;

		const linkColumnId = board?.columns?.find((col) => col.link_column_id)?.link_column_id;
		const personColumn = board?.columns?.find((col) => String(col.id) === String(linkColumnId) && col.type === "person");
		const person = row?.column_values?.[personColumn?.id]?.value?.includes(store.getState().user.id);

		return noExternalValue && person && row?.id;
	}
}

export default new CalendarHelper();
