import React from "react";

const ThumbsUpIcon = ({ color = "var(textColor)" }) => (
	<svg width="24" height="23" viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_1170_686)">
			<path
				fill={color}
				d="M21 7H14.69L15.64 2.43L15.67 2.11C15.67 1.7 15.5 1.32 15.23 1.05L14.17 0L7.59 6.59C7.22 6.95 7 7.45 7 8V18C7 19.1 7.9 20 9 20H18C18.83 20 19.54 19.5 19.84 18.78L22.86 11.73C22.95 11.5 23 11.26 23 11V9C23 7.9 22.1 7 21 7ZM21 11L18 18H9V8L13.34 3.66L12.23 9H21V11ZM1 8H5V20H1V8Z"
			/>
		</g>
		<defs>
			<filter id="filter0_d_1170_686" x="-3" y="0" width="30" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1170_686" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1170_686" result="shape" />
			</filter>
		</defs>
	</svg>
);

export default ThumbsUpIcon;
