import React, { Component } from "react";
import { Icon, Spinner } from "@shopify/polaris";
import { CircleCancelMinor, EditMajor } from "@shopify/polaris-icons";

import { withTranslation } from "react-i18next";
import BoardHelper from "./BoardHelper";

class CellDropdownOption extends Component {
	constructor(props) {
		super(props);
		this.state = { value: props.choice };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!this.props.editing) this.setState({ value: props.choice });
	}

	onSave(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ loading: true });
		this.props.onFocus(false);

		this.props.column.options.choices[this.props.index] = this.state.value;
		BoardHelper.updateColumnOptions(this.props.column, this.props.column.options, () => {
			this.setState({ loading: false });
		});

		if (this.props.onUpdateColumn) this.props.onUpdateColumn(this.props.column);
	}

	onToggleEdit(e) {
		e.preventDefault();
		e.stopPropagation();
		this.props.onFocus();
	}

	onKeyDown(e) {
		if (e.key === "Enter") {
			this.onSave(e);
		} else if (e.key === "Escape") {
			this.onToggleEdit(e);
		}
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	render() {
		const { choice, removeChoiceOption, editing } = this.props;
		return (
			<div
				className="board-dropdowncell-choice"
				onClick={(e) => {
					// if (this.state.editing) {
					// 	e.stopPropagation();
					// }
				}}
			>
				{editing ? (
					<input
						autoFocus
						onFocus={(e) => {
							e.stopPropagation();
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
						value={this.state.value}
						onChange={this.onChange.bind(this)}
						onKeyDown={this.onKeyDown.bind(this)}
						disabled={this.state.loading}
					/>
				) : (
					choice
				)}
				{editing ? (
					<span className="Polaris-Button Polaris-Button--primary" onClick={this.onSave.bind(this)}>
						{this.state.loading ? <Spinner size="small" /> : this.props.t("common.ations.save", "Spara")}
					</span>
				) : (
					<div className="edit-action-buttons">
						<span className="edit-button-inline" onClick={this.onToggleEdit.bind(this)}>
							<Icon source={EditMajor} />
						</span>
						<span className="clear-button-inline" onClick={removeChoiceOption}>
							<Icon source={CircleCancelMinor} />
						</span>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(CellDropdownOption);
