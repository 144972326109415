import i18next from "i18next";

const getTermsOfPaymentOptions = () => {
	return [
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.null", "Välj betalningsvillkor"),
			disabled: true,
			value: "",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.0", "0 dagar"),
			value: "0",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.10", "10 dagar"),
			value: "10",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.15", "15 dagar"),
			value: "15",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.20", "20 dagar"),
			value: "20",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.22", "22 dagar"),
			value: "22",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.30", "30 dagar"),
			value: "30",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.AG", "Autogiro"),
			value: "AG",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.K", "Kontant"),
			value: "K",
		},
		{
			label: i18next.t("fortnox.fields.TermsOfPayment.PF", "Postförskott"),
			value: "PF",
		},
	];
};
export default getTermsOfPaymentOptions;
