import React, { Component } from "react";
import { Badge, LegacyCard, FormLayout, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import MyFilters from "../../components/MyFilters.js";
import { store } from "../../store";
import Toast from "src/js/components/Toast";

class AudienceCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			appliedFilters: [],
			contacts: [],
			searchValue: "",
		};

		if (props.location.state && props.location.state.filters) {
			this.state.appliedFilters = props.location.state.filters;
		}
	}

	componentDidMount() {
		this.updateSearch(this.state.appliedFilters, this.state.searchValue);
	}

	handleSearchChange(searchValue) {
		clearTimeout(this.state.timeout);
		this.setState({
			searchValue,
			loading: true,
			timeout: setTimeout(this.updateSearch.bind(this, this.state.appliedFilters, searchValue), 10),
		});
	}

	handleFiltersChange(appliedFilters) {
		this.setState({ appliedFilters });
		this.updateSearch(appliedFilters, this.state.searchValue);
	}

	updateSearch(filters) {
		const params = {};
		for (let i = 0; i < filters.length; i++) {
			params[filters[i].key] = filters[i].value;
		}
		// params.q = search;
		// params.have_email = 1;

		params.limit = 10;
		params.offset = 0;
		params.is_company = 0;
		params.is_visitor = 0;
		this.setState({ loading: true });
		API.get("/api/contacts.json", { params })
			.then((result) => {
				this.setState({ loading: false, count: result.data.count, contacts: result.data.contacts });
			})
			.catch((error) => {
				this.setState({ loading: false });
				Toast.error(error);
			});
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/audiences.json", { title: this.state.title, filters: this.state.appliedFilters })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				Toast.success(this.props.t("audience.reponse.created", "Målgrupp skapades"));
				this.props.history.replace("/admin/audiences/" + result.data.audience.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	getValidationBadge(status) {
		if (status == "validated") {
			return <Badge status="success">{this.props.t("audience.status.validated", "validerad")}</Badge>;
		} else if (status == "bounced") {
			return <Badge status="warning">{this.props.t("audience.status.bounced", "studsad")}</Badge>;
		} else if (status == "failed") {
			return <Badge status="critical">{this.props.t("audience.status.failed", "studsad")}</Badge>;
		} else if (status) {
			return <Badge>{this.props.t(`audience.status.${status}`, status)}</Badge>;
		}

		return null;
	}

	render() {
		const filters = [
			{
				key: "user_id",
				label: this.props.t("audience.filters.user.label", "Medarbetare"),
				type: "user",
				shortcut: true,
			},
			{
				key: "group_id",
				label: this.props.t("audience.filters.group.label", "Grupp"),

				type: "group",
				shortcut: false,
			},
			{
				key: "tagged",
				label: this.props.t("audience.filters.user.contact_with_tag.label", "Kontaktperson taggad med"),
				type: "contact_person_tag",
				shortcut: true,
			},
			{
				key: "parent_tagged",
				label: this.props.t("audience.filters.company_with_tag.label", "Företag taggad med"),
				type: "contact_company_tag",
				shortcut: true,
			},
			{
				key: "keywords",
				label: this.props.t("audience.filters.keyword.label", "Nyckelord"),

				type: "text",
				shortcut: false,
			},
			{
				key: "have_email",
				label: this.props.t("audience.filters.email.label", "Har e-post"),
				type: "select",
				options: [
					{ label: this.props.t("common.terms.yes", "Ja"), value: 1 },
					{ label: this.props.t("common.terms.no", "Nej"), value: 0 },
				],
				shortcut: false,
			},
			{
				key: "email_opted",
				label: this.props.t("audience.filters.email_opt.label", "Är e-postkontakt"),
				type: "select",
				options: [
					{ label: this.props.t("common.terms.yes", "Ja"), value: 1 },
					{ label: this.props.t("common.terms.no", "Nej"), value: 0 },
				],
				shortcut: false,
			},
			{
				key: "last_activity_at_range",
				label: this.props.t("audience.filters.have_activity.label", "Har aktivitet"),
				operatorText: this.props.t("common.terms.since", "sedan"),
				type: "dateSelector",
				future: false,
				shortcut: false,
				range: false,
			},
			{
				key: "no_activity_in_range",
				label: this.props.t("audience.filters.no_activity.label", "Ingen aktivitet"),
				operatorText: this.props.t("common.terms.since", "sedan"),
				type: "dateSelector",
				future: false,
				shortcut: true,
				range: false,
			},
			{
				key: "last_session_at_range",
				label: this.props.t("audience.filters.have_session.label", "Har besökt hemsida"),
				operatorText: this.props.t("common.terms.since", "sedan"),
				type: "dateSelector",
				future: false,
				range: false,
				shortcut: false,
			},
			{
				key: "no_session_in_range",
				label: this.props.t("audience.filters.no_session.label", "Inget hemsidebesök"),
				operatorText: this.props.t("common.terms.since", "sedan"),
				type: "dateSelector",
				future: false,
				range: false,
				shortcut: true,
			},
			{
				key: "country",
				label: this.props.t("common.terms.country", "Land"),
				operatorText: this.props.t("common.terms.is", "är"),
				type: "select",
				options: store.getState().countries,
			},
		];

		for (let i = 0; i < store.getState().fields.length; i++) {
			filters.push({
				key: "relationship_" + store.getState().fields[i].id,
				label: this.props.t("audience.filters.relation.label", "Relation {{title}}", {
					title: store.getState().fields.length > 1 ? " " + store.getState().fields[i].title : "",
				}),
				type: "select",
				options: [
					{ label: this.props.t("audience.filters.relation.none", "Ingen relation"), value: "none" },
					{ label: this.props.t("audience.filters.relation.contacted", "Varit i kontakt"), value: "contacted" },
					{ label: this.props.t("audience.filters.relation.in_progress", "Jobbar på det"), value: "in_progress" },
					{ label: this.props.t("audience.filters.relation.customer", "Är kund"), value: "customer" },
					{ label: this.props.t("audience.filters.relation.ex_customer", "Har varit kund"), value: "ex_customer" },
				],
				shortcut: false,
			});
		}

		for (let i = 0; i < store.getState().contact_metafields.length; i++) {
			if (store.getState().contact_metafields[i].for == "person") {
				if (store.getState().contact_metafields[i].type == "boolean") {
					filters.push({
						key: "metafield_" + store.getState().contact_metafields[i].id,
						label: store.getState().contact_metafields[i].title,
						type: "select",
						options: [
							{ label: this.props.t("common.tersm.yes", "Ja"), value: "1" },
							{ label: this.props.t("common.tersm.no", "Nej"), value: "0" },
						],
						shortcut: false,
					});
				} else if (store.getState().contact_metafields[i].type == "options") {
					filters.push({
						key: "metafield_" + store.getState().contact_metafields[i].id,
						label: store.getState().contact_metafields[i].title,
						type: "select",
						options: store.getState().contact_metafields[i].options,
						shortcut: false,
					});
				} else if (store.getState().contact_metafields[i].type == "input") {
					filters.push({
						key: "metafield_" + store.getState().contact_metafields[i].id,
						label: store.getState().contact_metafields[i].title,
						type: "textField",
						shortcut: false,
					});
				}
			}
		}

		return (
			<Page
				primaryAction={{
					content: this.props.t("common.actions.create", "Skapa"),
					disabled: this.state.loading || !this.state.appliedFilters.length || !this.state.title,
					loading: this.state.saving,
					onClick: this.saveForm.bind(this),
				}}
				title={this.props.t("audience.actions.create", "Skapa ny målgrupp")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
			>
				<LegacyCard>
					<LegacyCard.Section>
						<FormLayout>
							<TextField
								label={this.props.t("audience.fields.name.label", "Namn på målgrupp")}
								value={this.state.title}
								onChange={(v) => {
									this.setState({ title: v });
								}}
							/>
						</FormLayout>
					</LegacyCard.Section>
				</LegacyCard>
				<LegacyCard title={this.props.t("audience.fields.contacts.label", "Kontakter")}>
					<LegacyCard.Section>
						<div className="hide-search">
							<MyFilters
								filters={filters}
								resourceName={{
									singular: this.props.t("audience.fields.contacts.singular", "Kontakt"),
									plural: this.props.t("audience.fields.contacts.plural", "Kontakter"),
								}}
								appliedFilters={this.state.appliedFilters}
								onFiltersChange={this.handleFiltersChange.bind(this)}
								searchValue={this.state.searchValue}
								onSearchChange={this.handleSearchChange.bind(this)}
							/>
						</div>
					</LegacyCard.Section>
					{!this.state.loading &&
						this.state.contacts.map((contact, key) => {
							const email = contact.email || <Badge status="warning">{this.props.t("audience.error.missing_email", "Saknar email address")}</Badge>;
							return (
								<LegacyCard.Section key={contact?.id || key}>
									{contact.name} - {email} {this.getValidationBadge(contact.validation_status)}
								</LegacyCard.Section>
							);
						})}
					{!this.state.loading ? (
						<LegacyCard.Section>
							<strong>
								{this.props.t("audience.count_text", "Visar {{counts}} av {{total}} kontakter", {
									counts: this.state.contacts.length,
									total: this.state.count,
								})}
							</strong>
						</LegacyCard.Section>
					) : null}
				</LegacyCard>
			</Page>
		);
	}
}
export default withTranslation(["audience", "common"], { withRef: true })(AudienceCreate);
