import i18next from "i18next";

const Options = {
	label: i18next.t("fortnox.offer.label2", "Offert"),
	value: "Fortnox.Offer",
	options: [
		{ label: i18next.t("fortnox.offer.fields.documentNumber.label", "Dokumentnummer"), value: "Fortnox.Offer.DocumentNumber" },
		{ label: i18next.t("fortnox.offer.fields.administrationFee.label", "Administrationsavgift"), value: "Fortnox.Offer.AdministrationFee" },
		{
			label: i18next.t("fortnox.offer.fields.administrationFeeVAT.label", "Administrationsavgift Moms"),
			value: "Fortnox.Offer.AdministrationFeeVAT",
		},
		{ label: i18next.t("fortnox.offer.fields.address1.label", "Adress 1"), value: "Fortnox.Offer.Address1" },
		{ label: i18next.t("fortnox.offer.fields.address2.label", "Adress 2"), value: "Fortnox.Offer.Address2" },
		{ label: i18next.t("fortnox.offer.fields.basisTaxReduction.label", "Grundskattereduktion"), value: "Fortnox.Offer.BasisTaxReduction" },
		{ label: i18next.t("fortnox.offer.fields.cancelled.label", "Avbruten"), value: "Fortnox.Offer.Cancelled" },
		{ label: i18next.t("fortnox.offer.fields.city.label", "Stad"), value: "Fortnox.Offer.City" },
		{ label: i18next.t("fortnox.offer.fields.comments.label", "Kommentarer"), value: "Fortnox.Offer.Comments" },
		{ label: i18next.t("fortnox.offer.fields.contributionPercent.label", "Bidragsprocent"), value: "Fortnox.Offer.ContributionPercent" },
		{ label: i18next.t("fortnox.offer.fields.contributionValue.label", "Bidragsvärde"), value: "Fortnox.Offer.ContributionValue" },
		{ label: i18next.t("fortnox.offer.fields.copyRemarks.label", "Kopiera Anmärkningar"), value: "Fortnox.Offer.CopyRemarks" },
		{ label: i18next.t("fortnox.offer.fields.country.label", "Land"), value: "Fortnox.Offer.Country" },
		{ label: i18next.t("fortnox.offer.fields.costCenter.label", "Kostnadsställe"), value: "Fortnox.Offer.CostCenter" },
		{ label: i18next.t("fortnox.offer.fields.currency.label", "Valuta"), value: "Fortnox.Offer.Currency" },
		{ label: i18next.t("fortnox.offer.fields.currencyRate.label", "Valutakurs"), value: "Fortnox.Offer.CurrencyRate" },
		{ label: i18next.t("fortnox.offer.fields.currencyUnit.label", "Valutaenhet"), value: "Fortnox.Offer.CurrencyUnit" },
		{ label: i18next.t("fortnox.offer.fields.customerName.label", "Kundnamn"), value: "Fortnox.Offer.CustomerName" },
		{ label: i18next.t("fortnox.offer.fields.customerNumber.label", "Kundnummer"), value: "Fortnox.Offer.CustomerNumber" },
		{ label: i18next.t("fortnox.offer.fields.deliveryAddress1.label", "Leveransadress 1"), value: "Fortnox.Offer.DeliveryAddress1" },
		{ label: i18next.t("fortnox.offer.fields.deliveryAddress2.label", "Leveransadress 2"), value: "Fortnox.Offer.DeliveryAddress2" },
		{ label: i18next.t("fortnox.offer.fields.deliveryCity.label", "Leveransstad"), value: "Fortnox.Offer.DeliveryCity" },
		{ label: i18next.t("fortnox.offer.fields.deliveryCountry.label", "Leveransland"), value: "Fortnox.Offer.DeliveryCountry" },
		{ label: i18next.t("fortnox.offer.fields.deliveryDate.label", "Leveransdatum"), value: "Fortnox.Offer.DeliveryDate" },
		{ label: i18next.t("fortnox.offer.fields.deliveryName.label", "Leveransnamn"), value: "Fortnox.Offer.DeliveryName" },
		{ label: i18next.t("fortnox.offer.fields.deliveryZipCode.label", "Leverans postnummer"), value: "Fortnox.Offer.DeliveryZipCode" },
		{
			label: i18next.t("fortnox.offer.fields.emailInformation.label", "E-postinformation"),
			value: "Fortnox.Offer.EmailInformation",
			options: [
				{
					label: i18next.t("fortnox.offer.fields.emailInformation.emailAddressFrom.label", "E-postadress Från"),
					value: "Fortnox.Offer.EmailInformation.EmailAddressFrom",
				},
				{
					label: i18next.t("fortnox.offer.fields.emailInformation.emailAddressTo.label", "E-postadress Till"),
					value: "Fortnox.Offer.EmailInformation.EmailAddressTo",
				},
				{
					label: i18next.t("fortnox.offer.fields.emailInformation.emailAddressCC.label", "E-postadress CC"),
					value: "Fortnox.Offer.EmailInformation.EmailAddressCC",
				},
				{
					label: i18next.t("fortnox.offer.fields.emailInformation.emailAddressBCC.label", "E-postadress BCC"),
					value: "Fortnox.Offer.EmailInformation.EmailAddressBCC",
				},
				{
					label: i18next.t("fortnox.offer.fields.emailInformation.emailSubject.label", "E-postämne"),
					value: "Fortnox.Offer.EmailInformation.EmailSubject",
				},
				{
					label: i18next.t("fortnox.offer.fields.emailInformation.emailBody.label", "E-postkropp"),
					value: "Fortnox.Offer.EmailInformation.EmailBody",
				},
			],
		},
		{ label: i18next.t("fortnox.offer.fields.expireDate.label", "Utgångsdatum"), value: "Fortnox.Offer.ExpireDate" },
		{ label: i18next.t("fortnox.offer.fields.freight.label", "Frakt"), value: "Fortnox.Offer.Freight" },
		{ label: i18next.t("fortnox.offer.fields.freightVAT.label", "Moms på frakt"), value: "Fortnox.Offer.FreightVAT" },
		{ label: i18next.t("fortnox.offer.fields.gross.label", "Brutto"), value: "Fortnox.Offer.Gross" },
		{ label: i18next.t("fortnox.offer.fields.houseWork.label", "Hushållsarbete"), value: "Fortnox.Offer.HouseWork" },
		{ label: i18next.t("fortnox.offer.fields.invoiceReference.label", "Fakturareferens"), value: "Fortnox.Offer.InvoiceReference" },
		{ label: i18next.t("fortnox.offer.fields.labels.label", "Etiketter"), value: "Fortnox.Offer.Labels" },
		{ label: i18next.t("fortnox.offer.fields.language.label", "Språk"), value: "Fortnox.Offer.Language" },
		{ label: i18next.t("fortnox.offer.fields.net.label", "Netto"), value: "Fortnox.Offer.Net" },
		{ label: i18next.t("fortnox.offer.fields.notCompleted.label", "Ej slutförd"), value: "Fortnox.Offer.NotCompleted" },
		{ label: i18next.t("fortnox.offer.fields.offerDate.label", "Offerdatum"), value: "Fortnox.Offer.OfferDate" },

		{ label: i18next.t("fortnox.offer.fields.orderReference.label", "Beställningsreferens"), value: "Fortnox.Offer.OrderReference" },
		{ label: i18next.t("fortnox.offer.fields.organisationNumber.label", "Organisationsnummer"), value: "Fortnox.Offer.OrganisationNumber" },
		{ label: i18next.t("fortnox.offer.fields.ourReference.label", "Vår referens"), value: "Fortnox.Offer.OurReference" },
		{ label: i18next.t("fortnox.offer.fields.phone1.label", "Telefon 1"), value: "Fortnox.Offer.Phone1" },
		{ label: i18next.t("fortnox.offer.fields.phone2.label", "Telefon 2"), value: "Fortnox.Offer.Phone2" },
		{ label: i18next.t("fortnox.offer.fields.priceList.label", "Prislista"), value: "Fortnox.Offer.PriceList" },
		{ label: i18next.t("fortnox.offer.fields.printTemplate.label", "Utskriftsmall"), value: "Fortnox.Offer.PrintTemplate" },
		{ label: i18next.t("fortnox.offer.fields.project.label", "Projekt"), value: "Fortnox.Offer.Project" },
		{ label: i18next.t("fortnox.offer.fields.remarks.label", "Anmärkningar"), value: "Fortnox.Offer.Remarks" },
		{ label: i18next.t("fortnox.offer.fields.roundOff.label", "Avrundning"), value: "Fortnox.Offer.RoundOff" },
		{ label: i18next.t("fortnox.offer.fields.sent.label", "Skickad"), value: "Fortnox.Offer.Sent" },
		{ label: i18next.t("fortnox.offer.fields.taxReduction.label", "Skattereduktion"), value: "Fortnox.Offer.TaxReduction" },
		{ label: i18next.t("fortnox.offer.fields.termsOfDelivery.label", "Leveransvillkor"), value: "Fortnox.Offer.TermsOfDelivery" },
		{ label: i18next.t("fortnox.offer.fields.termsOfPayment.label", "Betalningsvillkor"), value: "Fortnox.Offer.TermsOfPayment" },
		{ label: i18next.t("fortnox.offer.fields.total.label", "Totalt"), value: "Fortnox.Offer.Total" },
		{ label: i18next.t("fortnox.offer.fields.totalToPay.label", "Totalt att betala"), value: "Fortnox.Offer.TotalToPay" },
		{ label: i18next.t("fortnox.offer.fields.totalVAT.label", "Total moms"), value: "Fortnox.Offer.TotalVAT" },
		{ label: i18next.t("fortnox.offer.fields.vatIncluded.label", "Moms inkluderad"), value: "Fortnox.Offer.VATIncluded" },
		{ label: i18next.t("fortnox.offer.fields.wayOfDelivery.label", "Leveranssätt"), value: "Fortnox.Offer.WayOfDelivery" },
		{ label: i18next.t("fortnox.offer.fields.yourReference.label", "Din referens"), value: "Fortnox.Offer.YourReference" },
		{ label: i18next.t("fortnox.offer.fields.yourReferenceNumber.label", "Din referensnummer"), value: "Fortnox.Offer.YourReferenceNumber" },
		{ label: i18next.t("fortnox.offer.fields.zipCode.label", "Postnummer"), value: "Fortnox.Offer.ZipCode" },
		{ label: i18next.t("fortnox.offer.fields.taxReductionType.label", "Typ av skattereduktion"), value: "Fortnox.Offer.TaxReductionType" },

		{
			label: i18next.t("fortnox.offer.fields.offerRows.label", "Rader"),
			value: "Fortnox.Offer.OfferRows",
			type: "array",
			options: [
				{ label: i18next.t("fortnox.offer.fields.offerRows.articleNumber.label", "Artikelnummer"), value: "ArticleNumber" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.description.label", "Beskrivning"), value: "Description" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.quantity.label", "Beställd kvantitet"), value: "Quantity" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.price.label", "Pris"), value: "Price" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.total.label", "Totalt"), value: "Total" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.unit.label", "Enhet"), value: "Unit" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.vat.label", "Moms"), value: "VAT" },
				{ label: i18next.t("fortnox.offer.fields.offerRows.discount.label", "Rabatt"), value: "Discount" },
			],
		},
	],
};

export default Options;
