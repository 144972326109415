import { decodeTokenUser } from "../API/utils";
import { AddCookie, RemoveCookie } from "../Utilities";

const token = (state = null, action) => {
	switch (action?.type) {
		case "SET_TOKEN": {
			const tokenData = decodeTokenUser(action.token);
			AddCookie("JWTTOKEN", action.token, { expires: new Date(tokenData.exp * 1000 - 60000).toUTCString() });

			return action.token;
		}
		case "REMOVE_TOKEN": {
			RemoveCookie("JWTTOKEN");
			return null;
		}
		default:
			return state;
	}
};
export default token;
