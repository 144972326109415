import numberFormatter from "src/js/Utilities/numberFormatter";

export const formatNumberToWrite = (number: string): any => {
	return parseFloat((number || 0).toString().replace(/,/g, ".").replace(/\s/g, ""));
};

export const formatNumberToRead = (number: number, decimals = 2): any => {
	return numberFormatter({
		value: formatNumberToWrite(String(number || 0)),
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
		removeCurrencySymbol: true,
	});
};
