/* eslint-disable react/jsx-props-no-spreading */
import { Scrollable } from "@shopify/polaris";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FortnoxIntegration from "./FortnoxIntegration";

class AppIntegration extends Component {
	constructor(props) {
		super(props);
		this.state = { options: props.integration.options || {} };
		if (!this.state.options.sync_fields) {
			this.state.options.sync_fields = [];
		}
		if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
			// Send to Apps Webview
			window.ReactNativeWebView.postMessage(JSON.stringify({ view_integration_id: props.integration.id }));
		}

		if (this.props.integration.type == "fortnox" || this.props.integration.type == "fortnox_invoice") {
			this.props.history.push("/admin/fortnox/settings");
		}
	}

	render() {
		if (this.props.integration.type == "fortnox" || this.props.integration.type == "fortnox_invoice") {
			return (
				<Scrollable style={{ height: "100vh" }}>
					<FortnoxIntegration {...this.props} path={this.props.path} integration={this.props.integration} />
				</Scrollable>
			);
		}
	}
}

export default withRouter(AppIntegration);
