import React from "react";

const ContractIcon = ({ color = "var(--textColor)" }) => (
	<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.8275 0.449219H2.67293C1.74209 0.449219 0.980499 1.21081 0.980499 2.14165V13.9886H2.67293V2.14165H12.8275V0.449219ZM15.3661 3.83407H6.05778C5.12695 3.83407 4.36535 4.59567 4.36535 5.5265V17.3735C4.36535 18.3043 5.12695 19.0659 6.05778 19.0659H15.3661C16.297 19.0659 17.0586 18.3043 17.0586 17.3735V5.5265C17.0586 4.59567 16.297 3.83407 15.3661 3.83407ZM15.3661 17.3735H6.05778V5.5265H15.3661V17.3735Z"
			fill={color}
		/>
	</svg>
);

export default ContractIcon;
