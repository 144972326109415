export default (state = [], action) => {
	switch (action.type) {
		case "ADD_SUBSCRIPTION": {
			const newState = state.slice();
			newState.push(action.subscription);
			return newState;
		}
		case "UPDATE_SUBSCRIPTION": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.subscription.id) {
					newState[i] = action.subscription;
				}
			}
			return newState;
		}
		default:
			return state;
	}
};
