import { LegacyCard, Layout } from "@shopify/polaris";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import FortnoxArticleContext from "./FortnoxArticleContext";
// import FortnoxArticleRows from "./FortnoxArticleRows";
import { InfoWrapper } from "../styles";
import FortnoxArticleWarehouseInfo from "./FortnoxArticleWarehouseInfo";
import SkeletonPage from "src/js/components/Skeletons/Page";

const FortnoxArticleWarehouseInfoView = ({ history, t }) => {
	const { form, isFetching, setForm, isSaving } = useContext(FortnoxArticleContext);

	const getChangeHandler = (field: FortnoxArticleTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<FortnoxArticleType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;
			return newForm;
		});
	};

	return (
		<InfoWrapper>
			<LegacyCard sectioned>
				{!form || isFetching ? (
					<SkeletonPage />
				) : (
					<Layout>
						<Layout.Section>
							<FortnoxArticleWarehouseInfo isSaving={isSaving} article={form} onChange={getChangeHandler} />
						</Layout.Section>
					</Layout>
				)}
			</LegacyCard>
		</InfoWrapper>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxArticleWarehouseInfoView);
