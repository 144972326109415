import React, { Component } from "react";
import RowComment from "./RowComment.js";
import CommentInput from "./CommentInput.js";

class RowCommentForm extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	addComment(comment, ticket = null) {
		this.props.onCreate(comment);
	}

	render() {
		return (
			<div>
				<CommentInput
					initialTabIndex={this.props.initialTabIndex}
					onUpdateRow={this.props.onUpdateRow}
					row={this.props.row ? this.props.row : null}
					board={this.props.board}
					onSave={this.props.onSave}
					onCreate={this.addComment.bind(this)}
					onChange={this.props.onChange}
				/>
				<div style={{ marginTop: 20 }}>
					{this.props.comments.map((comment, index) => (
						<RowComment onUpdate={this.props.onUpdate} index={index} key={comment.id + "" + index} comment={comment} onRemove={this.props.onRemove} />
					))}
				</div>
			</div>
		);
	}
}
export default RowCommentForm;
