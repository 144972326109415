import React from "react";

const MailIcon = ({ color = "var(--textColor)" }) => (
	<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.49 7C20.49 6.28 20.12 5.65 19.55 5.3L10.5 0L1.45 5.3C0.88 5.65 0.5 6.28 0.5 7V17C0.5 18.1 1.4 19 2.5 19H18.5C19.6 19 20.5 18.1 20.5 17L20.49 7ZM10.5 2.32L18.49 7V7.01L10.5 12L2.5 7L10.5 2.32V2.32ZM2.5 17V9.34L10.5 14.36L18.49 9.37L18.5 17H2.5Z"
			fill={color}
		/>
	</svg>
);

export default MailIcon;
