import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const devSites = ["127.0.0.1", "192.168.1.201", "192.168.10.225", "localhost", "dev.sellfinity.com", "itbmedia3.eu.ngrok.io"];

const options = {
	lng: initialState?.user?.locale || "sv",
	backend: {
		// translation file path
		loadPath: "/api/translations/entries.json?lng={{lng}}&ns={{ns}}",
		addPath: "/api/translations/{{lng}}/entries.json",
		allowMultiLoading: true,
		parsePayload(namespace, key, fallbackValue) {
			return { key, fallbackValue, namespace };
		},
	},
	fallbackLng: "sv",
	fallbackNS: "common",
	defaultNS: "common",
	load: "languageOnly",
	saveMissing: !(devSites.indexOf(window.location.hostname) >= 0),
	// disabled in production
	debug: false,
	// can have multiple namespaces, in case you want to divide a huge
	// translation into smaller pieces and load them on demand
	ns: ["common"],

	interpolation: {
		espaceValue: false,
		formatSeparator: ",",
	},
	react: {
		// wait: true,
		useSuspense: false,
	},
};

if (devSites.indexOf(window.location.hostname) >= 0) {
	i18n.use(initReactI18next).init(options);
} else {
	i18n.use(Backend).use(initReactI18next).init(options);
}

export default i18n;
