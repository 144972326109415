/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { Popover, LegacyStack, Button, TextField, Icon, Tooltip } from "@shopify/polaris";
import { CircleCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import API from "../../API";
import Toast from "src/js/components/Toast";
import MyPopover from "../../components/Popover";
import BoardHelper from "./BoardHelper.js";
import ColorPicker from "../../components/ColorPicker.js";
import { colorToRgba } from "../../Colors.js";
import { store } from "../../store/index.js";
import DollarIcon from "../../icons/DollarIcon.js";
import CellStatusOption from "./components/CellStatusOption";
import CellStatusOptionEditing from "./components/CellStatusOptionEditing";

class CellStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			active: false,
			value: props.value,
			statuses: {},
		};
		if (props.summary) {
			const statuses = {};
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value === 0 || props.values[i].value === "0" || props.values[i].value) {
					const statusId = parseInt(props.values[i].value);
					const status = props.column.options.statuses.find((s) => s.id == statusId);
					const handle = statusId + "";

					if (!(handle in statuses) && status) {
						statuses[handle] = {
							label: status.label,
							color: status.color,
							count: 0,
						};
					}
					if (statuses[handle] && statuses[handle].count) {
						statuses[handle].count++;
					}
				} else {
					const handle = "none";
					if (!(handle in statuses)) {
						statuses[handle] = {
							label: this.props.t("cell.status.fields.status.options.none", "Ingen"),
							color: "#666",
							count: 0,
						};
					}
					if (statuses[handle] && statuses[handle].count) {
						statuses[handle].count++;
					}
				}
			}
			this.state.total_rows = props.values.length;
			this.state.statuses = statuses;
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.summary) {
			const statuses = {};
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value === 0 || props.values[i].value === "0" || props.values[i].value) {
					const statusId = parseInt(props.values[i].value);
					const status = props.column.options.statuses.find((s) => s.id == statusId);
					const handle = statusId + "";

					if (!(handle in statuses) && status) {
						statuses[handle] = {
							label: status.label,
							color: status.color,
							count: 0,
						};
					}
					if (statuses[handle] && statuses[handle].count) {
						statuses[handle].count++;
					}
				} else {
					const handle = "none";
					if (!(handle in statuses)) {
						statuses[handle] = {
							label: this.props.t("cell.status.fields.status.options.none", "Ingen"),
							color: "#666",
							count: 0,
						};
					}
					if (statuses[handle] && statuses[handle].count) {
						statuses[handle].count++;
					}
				}
			}
			this.setState({ total_rows: props.values.length, statuses });
		} else {
			this.setState({ value: props.value });
		}
	}

	setStatus(index) {
		if (this.props.disabled) return null;

		if (this.state.value != index) {
			this.props.onChange({ value: index });
		}
		this.setState({ value: index, active: false }, this.props.onBlur);
	}

	getStatus() {
		if (this.state.value === 0 || this.state.value === "0" || this.state.value) {
			return this.props.column.options.statuses.find((s) => s.id == parseInt(this.state.value));
		}
		return null;
	}

	updateStatusOption(index, field) {
		return (value) => {
			this.state.options.statuses[index][field] = value;
			this.setState({ options: this.state.options });
		};
	}

	saveOptions() {
		const options = this.state.options;
		this.setState({ saving: true });
		API.put("/api/boards/" + this.props.column.board_id + "/columns/" + this.props.column.id + ".json", { options }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				this.setState({ saving: false, editing: false });
				BoardHelper.updateColumn(result.data.column);
			})
			.catch((error) => {
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ saving: false });
			});
	}

	newOption() {
		this.state.options.statuses.push({ label: "", color: "#ffffff" });
		this.setState({ options: this.state.options });
	}

	handleDelete(index, e) {
		e.preventDefault();
		e.stopPropagation();

		this.state.options.statuses[index].removed = true;
		this.setState({ options: this.state.options });
	}

	render() {
		if (this.props.summary) {
			return (
				<div style={{ display: "flex" }}>
					{this.props.column.options.statuses.map((_, index) => {
						if (index + "" in this.state.statuses) {
							const status = this.state.statuses[index + ""];
							return (
								<div
									key={index}
									style={{
										backgroundColor: status.color,
										height: 35,
										width: (status.count / this.state.total_rows) * 100 + "%",
									}}
								/>
							);
						}
						return null;
					})}
					{this.state.total_rows > 0 && "none" in this.state.statuses ? (
						<div
							style={{
								backgroundColor: this.state.statuses.none.color,
								height: 35,
								width: (this.state.statuses.none.count / this.state.total_rows) * 100 + "%",
							}}
						/>
					) : null}
				</div>
			);
		}

		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						className={`board-status${!this.props.onChange ? " disabled" : ""}`}
						style={{
							backgroundColor:
								this.props.inheritColor || this.props.opacity
									? colorToRgba(this.getStatus()?.color, this.props.opacity || 0.65)
									: this.getStatus()?.color || null,
							color: this.props.inheritColor ? this.getStatus()?.color : this.getStatus()?.color === "#ffffff" ? "rgb(15,15,15)" : "#ffffff",
							...(this.props.style || {}),
						}}
						onClick={(e) => {
							if (!this.props.onChange) return null;
							e.stopPropagation();

							if (!this.state.active) {
								this.setState({ active: true, editing: false }, this.props.onFocus);
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						{this.getStatus() ? this.getStatus().label : null}
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				{!this.state.editing ? (
					<div style={{ width: 345 }}>
						<Popover.Section>
							<LegacyStack distribution="fillEvenly">
								{this.props.column.options.statuses.map((status, index) => {
									if (status.removed) return null;

									return (
										<CellStatusOption key={status.id} status={status} column={this.props.column} onClick={this.setStatus.bind(this, status.id)} />
									);
								})}
							</LegacyStack>
						</Popover.Section>

						<Popover.Section>
							<div style={{ display: "flex" }}>
								<Button
									// disabled={this.props.column.locked}
									plain
									fullWidth
									onClick={(e) => {
										e.stopPropagation();
										this.setState({
											editing: true,
											options: JSON.parse(JSON.stringify(this.props.column.options)),
										});
									}}
								>
									{this.props.t("cell.status.actions.handle", "Hantera statusar")}
								</Button>
								<Button
									// disabled={this.props.column.locked}
									plain
									fullWidth
									onClick={(e) => {
										e.stopPropagation();
										this.setStatus(null);
									}}
								>
									{this.props.t("cell.status.actions.clear", "Rensa")}
								</Button>
							</div>
						</Popover.Section>
					</div>
				) : null}
				{this.state.editing ? (
					<div style={{ width: 345 }}>
						<Popover.Section>
							<DragDropContext
								onDragEnd={(result) => {
									const { draggableId, destination, source } = result || {};

									if (!destination || !draggableId) {
										console.warn("onDragEnd result:", result);
										return;
									}

									const status = this.state.options.statuses[source.index];
									if (!status) return;

									this.state.options.statuses.splice(source.index, 1);
									this.state.options.statuses.splice(destination.index, 0, status);
									this.setState({ options: this.state.options });
								}}
							>
								<Droppable droppableId={"status-options-" + this.props.column.id}>
									{(droppableProvided, snapshot) => {
										return (
											<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
												{this.state.options.statuses
													.map((status, index) => {
														if (!status || status?.removed) return null;

														return (
															<Draggable draggableId={String(index)} index={index} key={index}>
																{(provided, snapshot) => {
																	return (
																		// eslint-disable-next-line react/jsx-props-no-spreading
																		<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																			<CellStatusOptionEditing
																				key={index}
																				status={status}
																				isDragging={snapshot.isDragging}
																				onDelete={this.handleDelete.bind(this, index)}
																				onChange={this.updateStatusOption.bind(this, index)}
																			/>
																		</div>
																	);
																}}
															</Draggable>
														);
													})
													?.filter(Boolean)}
												{droppableProvided.placeholder}
											</div>
										);
									}}
								</Droppable>
							</DragDropContext>
						</Popover.Section>
						<span
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<div
								style={{
									display: "flex",
									gap: "10px",
									padding: "10px 10px 0 10px",
								}}
							>
								<Button loading={this.state.creating} fullWidth style={{ marginBottom: "7px" }} primary onClick={this.newOption.bind(this)}>
									{this.props.t("cell.status.actions.add_new", "Lägg till ny")}
								</Button>

								<Button
									loading={this.state.saving}
									// disabled={this.state.options && this.state.options.statuses.some((s) => !s.removed && !s.label)}
									fullWidth
									primary
									onClick={this.saveOptions.bind(this)}
								>
									{this.props.t("common.actions.save", "Spara")}
								</Button>
							</div>
						</span>
					</div>
				) : null}
			</MyPopover>
		);
	}
}

const mapStateToProps = (state) => ({
	boards: state.boards,
});

export default connect(mapStateToProps)(withTranslation(["board", "common"], { withRef: true })(CellStatus));
