import { Button, Icon, Tooltip } from "@shopify/polaris";
import moment from "moment";
import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import Colors, { colorToRgba } from "src/js/Colors";
import Badge from "src/js/components/Badge";
import InfoView from "src/js/components/InfoView";
import IconWithBackround from "src/js/components/IconWithBackround";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import { PaperCheckMajor, SendMajor } from "@shopify/polaris-icons";
import API from "src/js/API";
import { UseQueryResult } from "@tanstack/react-query";
import NewDatePicker from "src/js/components/NewDatePicker";
import { store } from "src/js/store";
import useQuery from "src/js/hooks/useQuery";
import { getPartyName, getPartyStatus, getStatus, getStatuses } from "../Utilities";
import IndexTable from "src/js/components/IndexTable";

const ScriveIndex = ({ history, t }) => {
	const refresh = useRef();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const fetchStats = async () => {
		try {
			const params = new URLSearchParams();
			if (startDate) params.set("start", startDate);
			if (endDate) params.set("end", endDate);

			const res = await API.get("/api/scrive/documents/stats.json" + (params.toString() ? `?${params.toString()}` : ""));

			return res.data;
		} catch (error) {
			console.error("error:", error);
			throw error;
		}
	};

	const queryKeys = [
		`scrive_document_stats`,
		startDate && `scrive_document_stats_start_${startDate}`,
		endDate && `scrive_document_stats_end_${endDate}`,
	].filter(Boolean);
	const { data: stats, isFetching }: UseQueryResult<any> = useQuery({
		queryKey: queryKeys,
		queryFn: fetchStats,
		refetchOnWindowFocus: false,
	});

	const bulkActions = [];
	const filters = [
		{
			key: "status",
			label: t("scrive.filters.status.label", "Status"),
			type: "options",
			allowMultiple: true,
			options: getStatuses(),
			shortcut: true,
		},
		// {
		// 	key: "is_author",
		// 	label: t("scrive.filters.is_author.label", "Är författare"),
		// 	type: "select",
		// 	options: [
		// 		{
		// 			value: 1,
		// 			label: t("common.yes", "Ja"),
		// 		},
		// 		{'
		// 			value: 0,
		// 			label: t("common.no", "Nej"),
		// 		},
		// 	],
		// },
	];
	const columns = [
		{
			handle: "TITLE",
			label: t("scrive.columns.title.label", "Titel"),
			sortable: true,
			render: (document) => {
				return (
					<>
						{document.title}
						{document.is_trashed && (
							<Badge style={{ marginLeft: 10 }} color={Colors.red}>
								{t("scrive.statuses.trashed", "Slängt")}
							</Badge>
						)}{" "}
						{document.is_deleted && (
							<Badge style={{ marginLeft: 10 }} color={Colors.red}>
								{t("scrive.statuses.deleted", "Borttaget")}
							</Badge>
						)}{" "}
					</>
				);
			},
		},
		{
			handle: "STATUS",
			label: t("scrive.columns.status.label", "Status"),
			sortable: true,
			render: (document) => {
				const { color = null, label = null } = getStatus(document);

				return <Badge color={color}>{label}</Badge>;
			},
		},
		{
			handle: "SIGNED_COUNT",
			label: t("scrive.columns.signed_count.label", "Signaturer"),
			render: (document) => {
				const signatories = document.parties.filter((s) => s.is_signatory);
				const signed = signatories.filter((s) => s.sign_time);
				const { color: statusColor = null } = getStatus(document);

				return (
					<Tooltip
						content={
							<InfoView
								style={{ padding: 6 }}
								details={signatories.map((signatory) => {
									const name = getPartyName(signatory);
									const { color, label } = getPartyStatus(signatory);

									const statusBadge = (() => {
										if (signatory.sign_time) return <Badge color={color}>{label}</Badge>;
										if (signatory.rejected_time) return <Badge color={color}>{label}</Badge>;
										if (signatory.deferred_time) return <Badge color={color}>{label}</Badge>;
										if (signatory.seen_time) return <Badge color={color}>{label}</Badge>;
										return label && <Badge color={color}>{label}</Badge>;
									})();
									return {
										label: name,
										value: statusBadge,
									};
								})}
							/>
						}
					>
						<Badge color={statusColor}>{`${signed.length}/${signatories.length}`}</Badge>
					</Tooltip>
				);
			},
		},
		{
			handle: "MODIFIED_AT",
			label: t("scrive.columns.modified_at.label", "Senast ändrad"),
			render: (document) => moment(document.mtime).format("YYYY-MM-DD"),
		},
		{
			handle: "CREATED_AT",
			label: t("scrive.columns.creaeted_at.label", "Skapad"),

			render: (document) => moment(document.ctime).format("YYYY-MM-DD"),
		},
	];

	const getBoxTitle = (handle: string) => {
		return stats?.[handle] || "-";
	};

	return (
		<IndexTable
			limit={25}
			extraHeader={
				store.getState().user.scrive_user_id && (
					<>
						<NewDatePicker
							optionalTime={false}
							popup
							activator={
								<div style={{ marginBottom: 15, width: "max-content" }}>
									<Button plain>{startDate ? `${startDate} - ${endDate}` : "Välj datum"}</Button>
								</div>
							}
							range
							value={{
								start: startDate,
								end: endDate,
							}}
							onChange={({ start, end }) => {
								setStartDate(start);
								setEndDate(end);
							}}
						/>

						<StatsBoxWrapper
							perRow={4}
							loading={isFetching}
							items={[
								{
									title: getBoxTitle("user_total_signed"),
									text: t("scrive.box.mine_signed_total.text", "Mina signerade dokument"),
									icon: <IconWithBackround icon={<Icon source={PaperCheckMajor} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
								},
								{
									title: getBoxTitle("user_total_sent"),
									text: t("scrive.box.mine_sent_total.text", "Mina skickade dokument"),
									icon: <IconWithBackround icon={<Icon source={SendMajor} />} backgroundColor={colorToRgba(Colors.yellow2, 0.2)} />,
									helpText: t("scrive.box.mine_sent_total.help_text", "Skickade dokument som ej är signerade eller avvisade"),
								},
								{
									title: getBoxTitle("total_signed"),
									text: t("scrive.box.signed_total.text", "Totalt signerade dokument"),
									icon: <IconWithBackround icon={<Icon source={PaperCheckMajor} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
									helpText: t("scrive.box.signed_total.help_text", "Totalt antal dokument som är skickade och signerade"),
								},
								{
									title: getBoxTitle("total_sent"),
									text: t("scrive.box.sent_total.text", "Totalt skickade dokument"),
									icon: <IconWithBackround icon={<Icon source={SendMajor} />} backgroundColor={colorToRgba(Colors.yellow2, 0.2)} />,
									helpText: t("scrive.box.sent_total.help_text", "Skickade dokument som ej är signerade eller avvisade"),
								},
							]}
						/>
					</>
				)
			}
			setRefreshHandler={(refreshHandler) => {
				refresh.current = refreshHandler;
			}}
			history={history}
			title={t("scrive.documents.title", "Scrive dokument")}
			// primaryAction={{
			// 	content: t("contact.company.actions.new", "Skicka nytt dokument för signering"),
			// 	onAction: () => {},
			// }}
			onClickRow={(document) => {
				history.push(`/admin/scrive/documents/${document.id}`, { document });
			}}
			savedSearchHandle="scrive_documents"
			resourceUrl="/api/scrive/documents.json"
			resourceHandle="documents"
			resourceName={{
				singular: t("scrive.documents.singular", "dokument"),
				plural: t("scrive.documents.plural", "dokument"),
			}}
			filters={filters}
			columns={columns}
			defaultSort="MODIFIED_AT_DESC"
			promotedBulkActions={[]}
			bulkActions={bulkActions}
			defaultSavedSearches={[
				{
					id: "scrive_document_status_preparation",
					title: getStatus({ status: "preparation" } as ScriveDocumentType).label,
					filters: [
						{
							key: "status",
							value: ["preparation"],
						},
					],
				},
				{
					id: "scrive_document_status_pending",
					title: getStatus({ status: "pending" } as ScriveDocumentType).label,
					filters: [
						{
							key: "status",
							value: ["pending"],
						},
					],
				},
				{
					id: "scrive_document_status_closed",
					title: getStatus({ status: "closed" } as ScriveDocumentType).label,
					filters: [
						{
							key: "status",
							value: ["closed"],
						},
					],
				},
				{
					id: "scrive_document_status_canceled",
					title: getStatus({ status: "canceled" } as ScriveDocumentType).label,
					filters: [
						{
							key: "status",
							value: ["canceled"],
						},
					],
				},
				{
					id: "scrive_document_status_timedout",
					title: getStatus({ status: "timedout" } as ScriveDocumentType).label,
					filters: [
						{
							key: "status",
							value: ["timedout"],
						},
					],
				},
				{
					id: "scrive_document_status_rejected",
					title: getStatus({ status: "rejected" } as ScriveDocumentType).label,
					filters: [
						{
							key: "status",
							value: ["rejected"],
						},
					],
				},
			].map((s) => ({
				...s,
				sorting: "MODIFIED_AT_DESC",
				search: "",
				title: s.title as string,
			}))}
		/>
	);
};
export default withTranslation(["scrive", "common"])(ScriveIndex);
