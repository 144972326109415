import Colors from "src/js/Colors";
import { getPortalsMaxZIndex } from "src/js/Utilities";
import styled, { css } from "styled-components";

const FloatingActionBarWrapper = styled.div.attrs({
	className: "FloatingActionBar",
})<{
	open: boolean;
}>`
	pointer-events: ${({ open }) => (open ? "auto" : "none")};
	position: fixed;
	bottom: 0px;
	left: 0;
	right: 0;
	z-index: ${({ open }) => (open ? getPortalsMaxZIndex() || 9999999999 : -1)};
	min-height: 80px;
	transition: 0.1s ease;
	margin-bottom: -120px;
	opacity: 0;
	max-width: calc(100vw - var(--navigation-sidebar-width));

	min-width: 700px;
	margin: 0 auto;
	background-color: rgb(44, 50, 79);

	border-radius: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	width: fit-content;
	display: flex;

	${({ open }) =>
		open &&
		css`
			margin-bottom: 20px;
			opacity: 1;
		`}
`;

const Action = styled.button<{ loading?: boolean; disabled?: boolean; color?: string }>`
	border: none;
	background-color: transparent;
	cursor: pointer;
	transition: background-color 0.25s ease;
	padding: 0.6250rem;
	color: ${({ color }) => color || "var(--textColor)"};
	width: 100px;
	height: 80px;
	display: grid;
	grid-template-rows: 1fr 1fr;

	&:hover {
		background-color: var(--main2);
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}
`;
const Cancel = styled(Action)`
	width: 80px;
	background-color: ${Colors.red};
	height: unset;

	&:hover {
		background-color: ${Colors.red};
	}
`;
const ActionsContainer = styled.div`
	flex: 1;
	justify-content: start;
	display: flex;
	flex-wrap: wrap;
`;
const Counter = styled.div`
	font-size: 30px;
	color: #fff;
	min-width: 70px;
	text-align: center;
	border-radius: 5px 0px 0px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline: 5px;
	/* display: flex;
	justify-content: center;
	align-items: center; */
	background-color: ${({ color }) => color || "#0085ff"};
`;
const Icon = styled.div`
	display: flex;
	justify-content: center;
	align-items: end;

	svg,
	.Polaris-Icon {
		height: 30px;
		fill: var(--textColor);
		margin: unset;
		width: unset;
	}
`;

interface FloatingActionBarType {
	Action: typeof Action;
	Cancel: typeof Cancel;
	ActionsContainer: typeof ActionsContainer;
	Counter: typeof Counter;
	Icon: typeof Icon;
}

const StyledFloatingActionBar: FloatingActionBarType | any = FloatingActionBarWrapper;
StyledFloatingActionBar.Action = Action;
StyledFloatingActionBar.Cancel = Cancel;
StyledFloatingActionBar.ActionsContainer = ActionsContainer;
StyledFloatingActionBar.Counter = Counter;
StyledFloatingActionBar.Icon = Icon;
export { StyledFloatingActionBar };
