import { getLocalStorage, getCookie, getSessionStorage } from "src/js/Utilities";
import { decodeTokenUser } from "../API/utils";
import BASE_URL from "../API/BASE_URL";

export default (tkn = null) => {
	const token = tkn || getCookie("JWTTOKEN");
	const refreshToken = getSessionStorage("refresh_token") || getLocalStorage("refresh_token");
	const initialState: any = {};
	const td = decodeTokenUser(token as string | null) || {
		account: {},
		roles: [],
	};

	const usr = {
		id: td.id,
		avatar: td.avatar,
		email: td.email,
		excluded_boards: td.excluded_boards,
		fullname: td.fullname,
		group: td.group,
		initials: td.initials,
		locale: td.locale,
		name: td.name,
		phone: td.phone,
		roles: td.roles,
		settings: td.settings,
		signed_token: td.signed_token,
		token: td.token,
		username: td.username,
		calendar_board_id: td.calendar_board_id || td.account?.calendar_board_id,
		todo_board_id: td.todo_board_id || td.account?.todo_board_id,
		integrations: td?.integrations || [],
	};

	// "temporary" simple account, fetching full account in PreApp.
	const tda = td?.account;
	const acc = {
		...(tda || {}),
		id: tda.id,
		company: tda.company,
		handle: tda.handle,
		connection_id: tda.connection_id,
		color: tda.color || "#000000", // legacy, for topbar
		integrations: tda.integrations || [],
		sales_board_id: tda.sales_board_id,
		sales_workspace_id: tda.sales_workspace_id,
		support_board_id: tda.support_board_id,
		upload_board_id: tda.upload_board_id,
		visitor_board_id: tda.visitor_board_id,
		todo_board_id: td.todo_board_id ? tda.todo_board_id : null,
	};

	initialState.navigation = {
		baseURL: BASE_URL,
	};

	initialState.token = token;
	initialState.refresh_token = refreshToken;
	initialState.user = usr;
	initialState.account = acc;
	if (usr?.id) initialState.users = [usr];
	initialState.accounts = [acc];
	initialState.user_group_selection = null;
	initialState.column_selection = {};
	initialState.boards = {};
	if (initialState.user && initialState.user.todo_board) {
		initialState.boards[initialState.user.todo_board.id] = initialState.user.todo_board;
	}
	if (initialState.user && initialState.user.calendar_board) {
		initialState.boards[initialState.user.calendar_board.id] = initialState.user.calendar_board;
	}
	initialState.terms = [];
	initialState.goals = [];
	initialState.contact_activities = [];
	initialState.board_rows = [];
	initialState.board_groups = [];
	initialState.board_linked_rows = [];
	initialState.board_linked_from_rows = [];
	initialState.board_contacts = {};
	initialState.board_companies = {};
	initialState.board_tickets = {};
	initialState.board_visitors = {};
	initialState.contact_activity_selection = { show_all: true };
	initialState.workspaces = [];
	initialState.dashboards = [];
	initialState.favorite_dashboards = [];
	initialState.favorite_boards = [];
	initialState.contact_metafields = [];
	initialState.chats = [];
	initialState.chat_messages = {};
	initialState.cities = [
		{
			id: "1440",
			catalog_id: "3",
			title: "Ale",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1489",
			catalog_id: "3",
			title: "Alings\u00e5s",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0764", catalog_id: "3", title: "Alvesta", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{ id: "0604", catalog_id: "3", title: "Aneby", region_id: "06", region_title: "J\u00f6nk\u00f6pings l\u00e4n", region_handle: "jonkopings-lan" },
		{ id: "1984", catalog_id: "3", title: "Arboga", region_id: "19", region_title: "V\u00e4stmanlands l\u00e4n", region_handle: "vastmanlands-lan" },
		{ id: "2506", catalog_id: "3", title: "Arjeplog", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "2505", catalog_id: "3", title: "Arvidsjaur", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "1784", catalog_id: "3", title: "Arvika", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "1882", catalog_id: "3", title: "Askersund", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "2084", catalog_id: "3", title: "Avesta", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "1460",
			catalog_id: "3",
			title: "Bengtsfors",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "2326", catalog_id: "3", title: "Berg", region_id: "23", region_title: "J\u00e4mtlands l\u00e4n", region_handle: "jamtlands-lan" },
		{
			id: "2403",
			catalog_id: "3",
			title: "Bjurholm",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "1260", catalog_id: "3", title: "Bjuv", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "2582", catalog_id: "3", title: "Boden", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{
			id: "1443",
			catalog_id: "3",
			title: "Bollebygd",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "2183",
			catalog_id: "3",
			title: "Bolln\u00e4s",
			region_id: "21",
			region_title: "G\u00e4vleborgs l\u00e4n",
			region_handle: "gavleborgs-lan",
		},
		{ id: "0885", catalog_id: "3", title: "Borgholm", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "2081", catalog_id: "3", title: "Borl\u00e4nge", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "1490",
			catalog_id: "3",
			title: "Bor\u00e5s",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0127", catalog_id: "3", title: "Botkyrka", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "0560",
			catalog_id: "3",
			title: "Boxholm",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "1272", catalog_id: "3", title: "Brom\u00f6lla", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "2305", catalog_id: "3", title: "Br\u00e4cke", region_id: "23", region_title: "J\u00e4mtlands l\u00e4n", region_handle: "jamtlands-lan" },
		{ id: "1231", catalog_id: "3", title: "Burl\u00f6v", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1278", catalog_id: "3", title: "B\u00e5stad", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1438",
			catalog_id: "3",
			title: "Dals-Ed",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0162", catalog_id: "3", title: "Danderyd", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "1862", catalog_id: "3", title: "Degerfors", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{
			id: "2425",
			catalog_id: "3",
			title: "Dorotea",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "1730", catalog_id: "3", title: "Eda", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "0125", catalog_id: "3", title: "Eker\u00f6", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "0686",
			catalog_id: "3",
			title: "Eksj\u00f6",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{ id: "0862", catalog_id: "3", title: "Emmaboda", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "0381", catalog_id: "3", title: "Enk\u00f6ping", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{
			id: "0484",
			catalog_id: "3",
			title: "Eskilstuna",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{ id: "1285", catalog_id: "3", title: "Esl\u00f6v", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1445",
			catalog_id: "3",
			title: "Essunga",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1982",
			catalog_id: "3",
			title: "Fagersta",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{ id: "1382", catalog_id: "3", title: "Falkenberg", region_id: "13", region_title: "Hallands l\u00e4n", region_handle: "hallands-lan" },
		{
			id: "1499",
			catalog_id: "3",
			title: "Falk\u00f6ping",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "2080", catalog_id: "3", title: "Falun", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{ id: "1782", catalog_id: "3", title: "Filipstad", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "0562",
			catalog_id: "3",
			title: "Finsp\u00e5ng",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "0482", catalog_id: "3", title: "Flen", region_id: "04", region_title: "S\u00f6dermanlands l\u00e4n", region_handle: "sodermanlands-lan" },
		{ id: "1763", catalog_id: "3", title: "Forshaga", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "1439",
			catalog_id: "3",
			title: "F\u00e4rgelanda",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "2026", catalog_id: "3", title: "Gagnef", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "0662",
			catalog_id: "3",
			title: "Gislaved",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{
			id: "0461",
			catalog_id: "3",
			title: "Gnesta",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{
			id: "0617",
			catalog_id: "3",
			title: "Gnosj\u00f6",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{ id: "0980", catalog_id: "3", title: "Gotland", region_id: "09", region_title: "Gotlands l\u00e4n", region_handle: "gotlands-lan" },
		{ id: "1764", catalog_id: "3", title: "Grums", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "1444",
			catalog_id: "3",
			title: "Gr\u00e4storp",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1447",
			catalog_id: "3",
			title: "Gullsp\u00e5ng",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "2523", catalog_id: "3", title: "G\u00e4llivare", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "2180", catalog_id: "3", title: "G\u00e4vle", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{
			id: "1480",
			catalog_id: "3",
			title: "G\u00f6teborg",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1471",
			catalog_id: "3",
			title: "G\u00f6tene",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0643", catalog_id: "3", title: "Habo", region_id: "06", region_title: "J\u00f6nk\u00f6pings l\u00e4n", region_handle: "jonkopings-lan" },
		{ id: "1783", catalog_id: "3", title: "Hagfors", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "1861", catalog_id: "3", title: "Hallsberg", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{
			id: "1961",
			catalog_id: "3",
			title: "Hallstahammar",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{ id: "1380", catalog_id: "3", title: "Halmstad", region_id: "13", region_title: "Hallands l\u00e4n", region_handle: "hallands-lan" },
		{ id: "1761", catalog_id: "3", title: "Hammar\u00f6", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "0136", catalog_id: "3", title: "Haninge", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "2583", catalog_id: "3", title: "Haparanda", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "0331", catalog_id: "3", title: "Heby", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{ id: "2083", catalog_id: "3", title: "Hedemora", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{ id: "1283", catalog_id: "3", title: "Helsingborg", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1466",
			catalog_id: "3",
			title: "Herrljunga",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1497",
			catalog_id: "3",
			title: "Hjo",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "2104", catalog_id: "3", title: "Hofors", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{ id: "0126", catalog_id: "3", title: "Huddinge", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "2184", catalog_id: "3", title: "Hudiksvall", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{ id: "0860", catalog_id: "3", title: "Hultsfred", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "1315", catalog_id: "3", title: "Hylte", region_id: "13", region_title: "Hallands l\u00e4n", region_handle: "hallands-lan" },
		{ id: "1863", catalog_id: "3", title: "H\u00e4llefors", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{
			id: "2361",
			catalog_id: "3",
			title: "H\u00e4rjedalen",
			region_id: "23",
			region_title: "J\u00e4mtlands l\u00e4n",
			region_handle: "jamtlands-lan",
		},
		{
			id: "2280",
			catalog_id: "3",
			title: "H\u00e4rn\u00f6sand",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{
			id: "1401",
			catalog_id: "3",
			title: "H\u00e4rryda",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1293", catalog_id: "3", title: "H\u00e4ssleholm", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "0305", catalog_id: "3", title: "H\u00e5bo", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{ id: "1284", catalog_id: "3", title: "H\u00f6gan\u00e4s", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "0821", catalog_id: "3", title: "H\u00f6gsby", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "1266", catalog_id: "3", title: "H\u00f6rby", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1267", catalog_id: "3", title: "H\u00f6\u00f6r", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "2510", catalog_id: "3", title: "Jokkmokk", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{
			id: "0123",
			catalog_id: "3",
			title: "J\u00e4rf\u00e4lla",
			region_id: "01",
			region_title: "Stockholms l\u00e4n",
			region_handle: "stockholms-lan",
		},
		{
			id: "0680",
			catalog_id: "3",
			title: "J\u00f6nk\u00f6ping",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{ id: "2514", catalog_id: "3", title: "Kalix", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "0880", catalog_id: "3", title: "Kalmar", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{
			id: "1446",
			catalog_id: "3",
			title: "Karlsborg",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1082", catalog_id: "3", title: "Karlshamn", region_id: "10", region_title: "Blekinge l\u00e4n", region_handle: "blekinge-lan" },
		{ id: "1883", catalog_id: "3", title: "Karlskoga", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "1080", catalog_id: "3", title: "Karlskrona", region_id: "10", region_title: "Blekinge l\u00e4n", region_handle: "blekinge-lan" },
		{ id: "1780", catalog_id: "3", title: "Karlstad", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "0483",
			catalog_id: "3",
			title: "Katrineholm",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{ id: "1715", catalog_id: "3", title: "Kil", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "0513",
			catalog_id: "3",
			title: "Kinda",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "2584", catalog_id: "3", title: "Kiruna", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "1276", catalog_id: "3", title: "Klippan", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "0330", catalog_id: "3", title: "Knivsta", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{
			id: "2282",
			catalog_id: "3",
			title: "Kramfors",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{ id: "1290", catalog_id: "3", title: "Kristianstad", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1781", catalog_id: "3", title: "Kristinehamn", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "2309", catalog_id: "3", title: "Krokom", region_id: "23", region_title: "J\u00e4mtlands l\u00e4n", region_handle: "jamtlands-lan" },
		{ id: "1881", catalog_id: "3", title: "Kumla", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "1384", catalog_id: "3", title: "Kungsbacka", region_id: "13", region_title: "Hallands l\u00e4n", region_handle: "hallands-lan" },
		{
			id: "1960",
			catalog_id: "3",
			title: "Kungs\u00f6r",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{
			id: "1482",
			catalog_id: "3",
			title: "Kung\u00e4lv",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1261", catalog_id: "3", title: "K\u00e4vlinge", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1983",
			catalog_id: "3",
			title: "K\u00f6ping",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{ id: "1381", catalog_id: "3", title: "Laholm", region_id: "13", region_title: "Hallands l\u00e4n", region_handle: "hallands-lan" },
		{ id: "1282", catalog_id: "3", title: "Landskrona", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1860", catalog_id: "3", title: "Lax\u00e5", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "1814", catalog_id: "3", title: "Lekeberg", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "2029", catalog_id: "3", title: "Leksand", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "1441",
			catalog_id: "3",
			title: "Lerum",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0761", catalog_id: "3", title: "Lessebo", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{ id: "0186", catalog_id: "3", title: "Liding\u00f6", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "1494",
			catalog_id: "3",
			title: "Lidk\u00f6ping",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1462",
			catalog_id: "3",
			title: "Lilla Edet",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1885", catalog_id: "3", title: "Lindesberg", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{
			id: "0580",
			catalog_id: "3",
			title: "Link\u00f6ping",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "0781", catalog_id: "3", title: "Ljungby", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{ id: "2161", catalog_id: "3", title: "Ljusdal", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{ id: "1864", catalog_id: "3", title: "Ljusnarsberg", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "1262", catalog_id: "3", title: "Lomma", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "2085", catalog_id: "3", title: "Ludvika", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{ id: "2580", catalog_id: "3", title: "Lule\u00e5", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "1281", catalog_id: "3", title: "Lund", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "2481",
			catalog_id: "3",
			title: "Lycksele",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "1484",
			catalog_id: "3",
			title: "Lysekil",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1280", catalog_id: "3", title: "Malm\u00f6", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "2023", catalog_id: "3", title: "Malung-S\u00e4len", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "2418",
			catalog_id: "3",
			title: "Mal\u00e5",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "1493",
			catalog_id: "3",
			title: "Mariestad",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1463",
			catalog_id: "3",
			title: "Mark",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0767", catalog_id: "3", title: "Markaryd", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{
			id: "1461",
			catalog_id: "3",
			title: "Mellerud",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "0586",
			catalog_id: "3",
			title: "Mj\u00f6lby",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "2062", catalog_id: "3", title: "Mora", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "0583",
			catalog_id: "3",
			title: "Motala",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{
			id: "0642",
			catalog_id: "3",
			title: "Mullsj\u00f6",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{
			id: "1430",
			catalog_id: "3",
			title: "Munkedal",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1762", catalog_id: "3", title: "Munkfors", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "1481",
			catalog_id: "3",
			title: "M\u00f6lndal",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0861", catalog_id: "3", title: "M\u00f6nster\u00e5s", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "0840", catalog_id: "3", title: "M\u00f6rbyl\u00e5nga", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "0182", catalog_id: "3", title: "Nacka", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "1884", catalog_id: "3", title: "Nora", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "1962", catalog_id: "3", title: "Norberg", region_id: "19", region_title: "V\u00e4stmanlands l\u00e4n", region_handle: "vastmanlands-lan" },
		{ id: "2132", catalog_id: "3", title: "Nordanstig", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{
			id: "2401",
			catalog_id: "3",
			title: "Nordmaling",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "0581",
			catalog_id: "3",
			title: "Norrk\u00f6ping",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "0188", catalog_id: "3", title: "Norrt\u00e4lje", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "2417",
			catalog_id: "3",
			title: "Norsj\u00f6",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "0881", catalog_id: "3", title: "Nybro", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{ id: "0140", catalog_id: "3", title: "Nykvarn", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "0480",
			catalog_id: "3",
			title: "Nyk\u00f6ping",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{ id: "0192", catalog_id: "3", title: "Nyn\u00e4shamn", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "0682",
			catalog_id: "3",
			title: "N\u00e4ssj\u00f6",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{ id: "2101", catalog_id: "3", title: "Ockelbo", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{ id: "1060", catalog_id: "3", title: "Olofstr\u00f6m", region_id: "10", region_title: "Blekinge l\u00e4n", region_handle: "blekinge-lan" },
		{ id: "2034", catalog_id: "3", title: "Orsa", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "1421",
			catalog_id: "3",
			title: "Orust",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1273", catalog_id: "3", title: "Osby", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "0882", catalog_id: "3", title: "Oskarshamn", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{
			id: "2121",
			catalog_id: "3",
			title: "Ovan\u00e5ker",
			region_id: "21",
			region_title: "G\u00e4vleborgs l\u00e4n",
			region_handle: "gavleborgs-lan",
		},
		{
			id: "0481",
			catalog_id: "3",
			title: "Oxel\u00f6sund",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{ id: "2521", catalog_id: "3", title: "Pajala", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{
			id: "1402",
			catalog_id: "3",
			title: "Partille",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1275", catalog_id: "3", title: "Perstorp", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "2581", catalog_id: "3", title: "Pite\u00e5", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "2303", catalog_id: "3", title: "Ragunda", region_id: "23", region_title: "J\u00e4mtlands l\u00e4n", region_handle: "jamtlands-lan" },
		{
			id: "2409",
			catalog_id: "3",
			title: "Robertsfors",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "1081", catalog_id: "3", title: "Ronneby", region_id: "10", region_title: "Blekinge l\u00e4n", region_handle: "blekinge-lan" },
		{ id: "2031", catalog_id: "3", title: "R\u00e4ttvik", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{ id: "1981", catalog_id: "3", title: "Sala", region_id: "19", region_title: "V\u00e4stmanlands l\u00e4n", region_handle: "vastmanlands-lan" },
		{ id: "0128", catalog_id: "3", title: "Salem", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "2181", catalog_id: "3", title: "Sandviken", region_id: "21", region_title: "G\u00e4vleborgs l\u00e4n", region_handle: "gavleborgs-lan" },
		{ id: "0191", catalog_id: "3", title: "Sigtuna", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "1291", catalog_id: "3", title: "Simrishamn", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1265", catalog_id: "3", title: "Sj\u00f6bo", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1495",
			catalog_id: "3",
			title: "Skara",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "2482",
			catalog_id: "3",
			title: "Skellefte\u00e5",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "1904",
			catalog_id: "3",
			title: "Skinnskatteberg",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{ id: "1264", catalog_id: "3", title: "Skurup", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1496",
			catalog_id: "3",
			title: "Sk\u00f6vde",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "2061", catalog_id: "3", title: "Smedjebacken", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "2283",
			catalog_id: "3",
			title: "Sollefte\u00e5",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{ id: "0163", catalog_id: "3", title: "Sollentuna", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "0184", catalog_id: "3", title: "Solna", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "2422",
			catalog_id: "3",
			title: "Sorsele",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "1427",
			catalog_id: "3",
			title: "Soten\u00e4s",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1230", catalog_id: "3", title: "Staffanstorp", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1415",
			catalog_id: "3",
			title: "Stenungsund",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0180", catalog_id: "3", title: "Stockholm", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "1760", catalog_id: "3", title: "Storfors", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "2421",
			catalog_id: "3",
			title: "Storuman",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "0486",
			catalog_id: "3",
			title: "Str\u00e4ngn\u00e4s",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{
			id: "1486",
			catalog_id: "3",
			title: "Str\u00f6mstad",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "2313",
			catalog_id: "3",
			title: "Str\u00f6msund",
			region_id: "23",
			region_title: "J\u00e4mtlands l\u00e4n",
			region_handle: "jamtlands-lan",
		},
		{ id: "0183", catalog_id: "3", title: "Sundbyberg", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "2281",
			catalog_id: "3",
			title: "Sundsvall",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{ id: "1766", catalog_id: "3", title: "Sunne", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{
			id: "1907",
			catalog_id: "3",
			title: "Surahammar",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{ id: "1214", catalog_id: "3", title: "Sval\u00f6v", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1263", catalog_id: "3", title: "Svedala", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1465",
			catalog_id: "3",
			title: "Svenljunga",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1785", catalog_id: "3", title: "S\u00e4ffle", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "2082", catalog_id: "3", title: "S\u00e4ter", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "0684",
			catalog_id: "3",
			title: "S\u00e4vsj\u00f6",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{
			id: "2182",
			catalog_id: "3",
			title: "S\u00f6derhamn",
			region_id: "21",
			region_title: "G\u00e4vleborgs l\u00e4n",
			region_handle: "gavleborgs-lan",
		},
		{
			id: "0582",
			catalog_id: "3",
			title: "S\u00f6derk\u00f6ping",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{
			id: "0181",
			catalog_id: "3",
			title: "S\u00f6dert\u00e4lje",
			region_id: "01",
			region_title: "Stockholms l\u00e4n",
			region_handle: "stockholms-lan",
		},
		{ id: "1083", catalog_id: "3", title: "S\u00f6lvesborg", region_id: "10", region_title: "Blekinge l\u00e4n", region_handle: "blekinge-lan" },
		{
			id: "1435",
			catalog_id: "3",
			title: "Tanum",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1472",
			catalog_id: "3",
			title: "Tibro",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1498",
			catalog_id: "3",
			title: "Tidaholm",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0360", catalog_id: "3", title: "Tierp", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{
			id: "2262",
			catalog_id: "3",
			title: "Timr\u00e5",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{ id: "0763", catalog_id: "3", title: "Tingsryd", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{
			id: "1419",
			catalog_id: "3",
			title: "Tj\u00f6rn",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1270", catalog_id: "3", title: "Tomelilla", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "1737", catalog_id: "3", title: "Torsby", region_id: "17", region_title: "V\u00e4rmlands l\u00e4n", region_handle: "varmlands-lan" },
		{ id: "0834", catalog_id: "3", title: "Tors\u00e5s", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{
			id: "1452",
			catalog_id: "3",
			title: "Tranemo",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "0687",
			catalog_id: "3",
			title: "Tran\u00e5s",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{ id: "1287", catalog_id: "3", title: "Trelleborg", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1488",
			catalog_id: "3",
			title: "Trollh\u00e4ttan",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "0488", catalog_id: "3", title: "Trosa", region_id: "04", region_title: "S\u00f6dermanlands l\u00e4n", region_handle: "sodermanlands-lan" },
		{ id: "0138", catalog_id: "3", title: "Tyres\u00f6", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "0160", catalog_id: "3", title: "T\u00e4by", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "1473",
			catalog_id: "3",
			title: "T\u00f6reboda",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1485",
			catalog_id: "3",
			title: "Uddevalla",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "1491",
			catalog_id: "3",
			title: "Ulricehamn",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "2480",
			catalog_id: "3",
			title: "Ume\u00e5",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "0114",
			catalog_id: "3",
			title: "Upplands V\u00e4sby",
			region_id: "01",
			region_title: "Stockholms l\u00e4n",
			region_handle: "stockholms-lan",
		},
		{ id: "0139", catalog_id: "3", title: "Upplands-Bro", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "0380", catalog_id: "3", title: "Uppsala", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{ id: "0760", catalog_id: "3", title: "Uppvidinge", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{ id: "0199", catalog_id: "3", title: "Utlandet", region_id: null, region_title: null, region_handle: "" },
		{
			id: "0584",
			catalog_id: "3",
			title: "Vadstena",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{
			id: "0665",
			catalog_id: "3",
			title: "Vaggeryd",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{
			id: "0563",
			catalog_id: "3",
			title: "Valdemarsvik",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "0115", catalog_id: "3", title: "Vallentuna", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "2021", catalog_id: "3", title: "Vansbro", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{
			id: "1470",
			catalog_id: "3",
			title: "Vara",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{ id: "1383", catalog_id: "3", title: "Varberg", region_id: "13", region_title: "Hallands l\u00e4n", region_handle: "hallands-lan" },
		{ id: "0187", catalog_id: "3", title: "Vaxholm", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{ id: "1233", catalog_id: "3", title: "Vellinge", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "0685",
			catalog_id: "3",
			title: "Vetlanda",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{
			id: "2462",
			catalog_id: "3",
			title: "Vilhelmina",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "0884", catalog_id: "3", title: "Vimmerby", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{
			id: "2404",
			catalog_id: "3",
			title: "Vindeln",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{
			id: "0428",
			catalog_id: "3",
			title: "Ving\u00e5ker",
			region_id: "04",
			region_title: "S\u00f6dermanlands l\u00e4n",
			region_handle: "sodermanlands-lan",
		},
		{
			id: "1487",
			catalog_id: "3",
			title: "V\u00e4nersborg",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "2460",
			catalog_id: "3",
			title: "V\u00e4nn\u00e4s",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "0120", catalog_id: "3", title: "V\u00e4rmd\u00f6", region_id: "01", region_title: "Stockholms l\u00e4n", region_handle: "stockholms-lan" },
		{
			id: "0683",
			catalog_id: "3",
			title: "V\u00e4rnamo",
			region_id: "06",
			region_title: "J\u00f6nk\u00f6pings l\u00e4n",
			region_handle: "jonkopings-lan",
		},
		{ id: "0883", catalog_id: "3", title: "V\u00e4stervik", region_id: "08", region_title: "Kalmar l\u00e4n", region_handle: "kalmar-lan" },
		{
			id: "1980",
			catalog_id: "3",
			title: "V\u00e4ster\u00e5s",
			region_id: "19",
			region_title: "V\u00e4stmanlands l\u00e4n",
			region_handle: "vastmanlands-lan",
		},
		{ id: "0780", catalog_id: "3", title: "V\u00e4xj\u00f6", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{
			id: "1442",
			catalog_id: "3",
			title: "V\u00e5rg\u00e5rda",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "0512",
			catalog_id: "3",
			title: "Ydre",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "1286", catalog_id: "3", title: "Ystad", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{ id: "0765", catalog_id: "3", title: "\u00c4lmhult", region_id: "07", region_title: "Kronobergs l\u00e4n", region_handle: "kronobergs-lan" },
		{ id: "2039", catalog_id: "3", title: "\u00c4lvdalen", region_id: "20", region_title: "Dalarnas l\u00e4n", region_handle: "dalarnas-lan" },
		{ id: "0319", catalog_id: "3", title: "\u00c4lvkarleby", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{ id: "2560", catalog_id: "3", title: "\u00c4lvsbyn", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{ id: "1292", catalog_id: "3", title: "\u00c4ngelholm", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "1492",
			catalog_id: "3",
			title: "\u00c5m\u00e5l",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "2260",
			catalog_id: "3",
			title: "\u00c5nge",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{ id: "2321", catalog_id: "3", title: "\u00c5re", region_id: "23", region_title: "J\u00e4mtlands l\u00e4n", region_handle: "jamtlands-lan" },
		{
			id: "1765",
			catalog_id: "3",
			title: "\u00c5rj\u00e4ng",
			region_id: "17",
			region_title: "V\u00e4rmlands l\u00e4n",
			region_handle: "varmlands-lan",
		},
		{
			id: "2463",
			catalog_id: "3",
			title: "\u00c5sele",
			region_id: "24",
			region_title: "V\u00e4sterbottens l\u00e4n",
			region_handle: "vasterbottens-lan",
		},
		{ id: "1277", catalog_id: "3", title: "\u00c5storp", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "0561",
			catalog_id: "3",
			title: "\u00c5tvidaberg",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{
			id: "1407",
			catalog_id: "3",
			title: "\u00d6cker\u00f6",
			region_id: "14",
			region_title: "V\u00e4stra G\u00f6talands l\u00e4n",
			region_handle: "vastra-gotalands-lan",
		},
		{
			id: "0509",
			catalog_id: "3",
			title: "\u00d6desh\u00f6g",
			region_id: "05",
			region_title: "\u00d6sterg\u00f6tlands l\u00e4n",
			region_handle: "ostergotlands-lan",
		},
		{ id: "1880", catalog_id: "3", title: "\u00d6rebro", region_id: "18", region_title: "\u00d6rebro l\u00e4n", region_handle: "orebro-lan" },
		{ id: "1257", catalog_id: "3", title: "\u00d6rkelljunga", region_id: "12", region_title: "Sk\u00e5ne l\u00e4n", region_handle: "skane-lan" },
		{
			id: "2284",
			catalog_id: "3",
			title: "\u00d6rnsk\u00f6ldsvik",
			region_id: "22",
			region_title: "V\u00e4sternorrlands l\u00e4n",
			region_handle: "vasternorrlands-lan",
		},
		{
			id: "2380",
			catalog_id: "3",
			title: "\u00d6stersund",
			region_id: "23",
			region_title: "J\u00e4mtlands l\u00e4n",
			region_handle: "jamtlands-lan",
		},
		{
			id: "0117",
			catalog_id: "3",
			title: "\u00d6ster\u00e5ker",
			region_id: "01",
			region_title: "Stockholms l\u00e4n",
			region_handle: "stockholms-lan",
		},
		{ id: "0382", catalog_id: "3", title: "\u00d6sthammar", region_id: "03", region_title: "Uppsala l\u00e4n", region_handle: "uppsala-lan" },
		{
			id: "1256",
			catalog_id: "3",
			title: "\u00d6stra G\u00f6inge",
			region_id: "12",
			region_title: "Sk\u00e5ne l\u00e4n",
			region_handle: "skane-lan",
		},
		{ id: "2513", catalog_id: "3", title: "\u00d6verkalix", region_id: "25", region_title: "Norrbottens l\u00e4n", region_handle: "norrbottens-lan" },
		{
			id: "2518",
			catalog_id: "3",
			title: "\u00d6vertorne\u00e5",
			region_id: "25",
			region_title: "Norrbottens l\u00e4n",
			region_handle: "norrbottens-lan",
		},
	];
	initialState.categories = [
		{ bransch2: "Ambassader & Internationella Org.", bransch1: "Ambassader & Internationella Org." },
		{ bransch2: "Avfallshantering & \u00c5tervinning", bransch1: "Avlopp, Avfall, El & Vatten" },
		{ bransch2: "Avloppsrening", bransch1: "Avlopp, Avfall, El & Vatten" },
		{ bransch2: "Eldistribution", bransch1: "Avlopp, Avfall, El & Vatten" },
		{ bransch2: "Elgenerering", bransch1: "" },
		{ bransch2: "Elhandel", bransch1: "" },
		{ bransch2: "El\u00f6verf\u00f6ring", bransch1: "" },
		{ bransch2: "Farligt Avfall", bransch1: "Avlopp, Avfall, El & Vatten" },
		{ bransch2: "Gasdistribution Avlopp, Avfall, El & Vatten", bransch1: "" },
		{ bransch2: "Gasframst\u00e4llning", bransch1: "" },
		{ bransch2: "Gashandel", bransch1: "Avlopp, Avfall, El & Vatten" },
		{ bransch2: "Icke-Farligt Avfall Avlopp, Avfall, El & Vatten", bransch1: "" },
		{ bransch2: "K\u00e4llsorterat Material", bransch1: "Avlopp, Avfall, El & Vatten" },
		{ bransch2: "Vatten & Avlopp Avlopp, Avfall, El & Vatten", bransch1: "" },
		{ bransch2: "V\u00e4rme & Kyla", bransch1: "" },
		{ bransch2: "Centralbanksverksamhet", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Finans, Administrativa Tj\u00e4nster Bank, Finans & F\u00f6rs\u00e4kring", bransch1: "" },
		{ bransch2: "Finansf\u00f6rmedling, \u00d6vrig Bank, Finans & F\u00f6rs\u00e4kring", bransch1: "" },
		{ bransch2: "Finansiell Leasing", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Finansiella St\u00f6dtj\u00e4nster, \u00d6vriga", bransch1: "" },
		{ bransch2: "Fondanknuten Livf\u00f6rs\u00e4kring", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Fonder & Liknande Finansiella Enheter, \u00d6vriga", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Fondf\u00f6rvaltning, \u00d6vrig", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "F\u00f6rs\u00e4kring & Pensionsfond St\u00f6dtj\u00e4nster, \u00d6vriga", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "F\u00f6rvaltning & Handel Med V\u00e4rdepapper", bransch1: "" },
		{ bransch2: "Holdingverksamhet I Finansiella Koncerner", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Holdingverksamhet I Icke-Finansiella Koncerner", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Investeringsfonder", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Investment- & Riskkapitalbolag", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Kreditgivning, \u00d6vrig", bransch1: "" },
		{ bransch2: "Livf\u00f6rs\u00e4kring, \u00d6vrig", bransch1: "" },
		{ bransch2: "Monet\u00e4r Finansf\u00f6rmedling, \u00d6vrig Bank, Finans & F\u00f6rs\u00e4kring", bransch1: "" },
		{ bransch2: "Pensionsfondsverksamhet Bank, Finans & F\u00f6rs\u00e4kring", bransch1: "" },
		{ bransch2: "Risk- & Skadebed\u00f6mning", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Skadef\u00f6rs\u00e4kring Bank, Finans & F\u00f6rs\u00e4kring", bransch1: "" },
		{
			bransch2: "Verksamhet Utf\u00f6rd Av F\u00f6rs\u00e4kringsombud & F\u00f6rs\u00e4kringsm\u00e4klare",
			bransch1: "Bank, Finans & F\u00f6rs\u00e4kring",
		},
		{ bransch2: "Verksamhet Utf\u00f6rd Av V\u00e4rdepappers- & Varum\u00e4klare", bransch1: "" },
		{ bransch2: "\u00c5terf\u00f6rs\u00e4kring", bransch1: "Bank, Finans & F\u00f6rs\u00e4kring" },
		{ bransch2: "Arbetsf\u00f6rmedling & Rekrytering", bransch1: "Bemanning & Arbetsf\u00f6rmedling" },
		{ bransch2: "Personalfunktioner, \u00d6vrigt", bransch1: "Bemanning & Arbetsf\u00f6rmedling" },
		{ bransch2: "Personaluthyrning", bransch1: "Bemanning & Arbetsf\u00f6rmedling" },
		{ bransch2: "Intressebev. Arbetsgivarorg.", bransch1: "" },
		{ bransch2: "Intressebev. Arbetstagarorg.", bransch1: "" },
		{ bransch2: "Intressebev. Branschorg.", bransch1: "" },
		{ bransch2: "Intressebev. Yrkesorg.", bransch1: "Bransch-, Arbetsgivar- & Yrkesorg." },
		{ bransch2: "Intressorganisationer, \u00d6vriga", bransch1: "Bransch-, Arbetsgivar- & Yrkesorg." },
		{ bransch2: "Politiska Organisationer", bransch1: "" },
		{ bransch2: "Religi\u00f6sa Samfund", bransch1: "Bransch-, Arbetsgivar- & Yrkesorg." },
		{ bransch2: "Anl\u00e4ggningsarbeten", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Arkitektverksamhet", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Byggnadssnickeriarbeten Bygg-, Design- & Inredningsverksamhet", bransch1: "" },
		{ bransch2: "Byggverksamhet", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "El-Vvs & Bygginstallationer Bygg-, Design- & Inredningsverksamhet", bransch1: "" },
		{ bransch2: "Glasm\u00e4steriarbeten", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Golv- & V\u00e4ggbel\u00e4ggningsarbeten", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Grafisk Designverksamhet", bransch1: "" },
		{ bransch2: "Industri- & Produktdesignverksamhet Bygg-, Design- & Inredningsverksamhet", bransch1: "" },
		{ bransch2: "Inredningsarkitekt", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Markunders\u00f6kning", bransch1: "" },
		{ bransch2: "M\u00e5leriarbeten", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Puts-, Fasad- & Stuckat\u00f6rsarbeten", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Rivning Av Hus & Byggnader", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Slutbehandling Av Byggnader Bygg-, Design- & Inredningsverksamhet", bransch1: "" },
		{ bransch2: "Takarbeten", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Teknisk Konsult Inom Bygg- & Anl\u00e4ggningsteknik", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Utformning Av Byggprojekt", bransch1: "Bygg-, Design- & Inredningsverksamhet" },
		{ bransch2: "Uthyrning Av Bygg- & Anl\u00e4ggningsmaskiner Med F\u00f6rare Bygg-, Design- & Inredningsverksamhet", bransch1: "" },
		{ bransch2: "Datakonsultverksamhet", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Dataprogrammering", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Datordrifttj\u00e4nster", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "It- & Datatj\u00e4nster, \u00d6vriga", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Telekommunikation, Satellit Data, It & Telekommunikation", bransch1: "" },
		{ bransch2: "Telekommunikation, Tr\u00e5dbunden", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Telekommunikation, Tr\u00e5dl\u00f6s", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Telekommunikation, \u00d6vrig", bransch1: "" },
		{ bransch2: "Utgivning Av Annan Programvara", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Utgivning Av Dataspel", bransch1: "Data, It & Telekommunikation" },
		{ bransch2: "Alkoholhaltiga Drycker, Butikshandel", bransch1: "" },
		{ bransch2: "Apotekshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Auktioner", bransch1: "Detaljhandel" },
		{ bransch2: "Band & Skivor, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Barnkl\u00e4der, Butikshandel", bransch1: "" },
		{ bransch2: "Begagnade Varor \u00d6vriga, Butikshandel", bransch1: "" },
		{ bransch2: "Belysningsartiklar, Butikshandel", bransch1: "" },
		{ bransch2: "Blommor & V\u00e4xter, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Br\u00f6d & Konditori, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Butikshandel, \u00d6vrig Detaljhandel", bransch1: "" },
		{ bransch2: "B\u00e5tar, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "B\u00f6cker, Butikshandel", bransch1: "" },
		{ bransch2: "Cyklar, Butikshandel", bransch1: "" },
		{ bransch2: "Damkl\u00e4der, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Datorer, Program, Data-& Tv-Spel, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Detaljhandel, \u00d6vrig Detaljhandel", bransch1: "" },
		{ bransch2: "Drivmedel, Detaljhandel Detaljhandel", bransch1: "" },
		{ bransch2: "Elektriska Hush\u00e5llsmaskiner, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Fisk & Skaldjur, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Fotoutrustning, Butikshandel", bransch1: "" },
		{ bransch2: "Frukt & Gr\u00f6nsaker, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "F\u00e4rg & Lack, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Glas & Porslin, Butikshandel", bransch1: "" },
		{ bransch2: "Guldsmedsvaror & Smycken, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Herrkl\u00e4der, Butikshandel", bransch1: "" },
		{ bransch2: "H\u00e4lsokost, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Inredningstextilier, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "J\u00e4rn- & Vvs- Varor, Butikshandel", bransch1: "" },
		{ bransch2: "Klockor, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Kl\u00e4der, Butikshandel", bransch1: "" },
		{ bransch2: "Konfektyr, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Konst & Galleri, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Kontorsf\u00f6rbrukningsvaror, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Kontorsm\u00f6bler, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Kosmetika & Hygienartiklar, Butikshandel", bransch1: "" },
		{ bransch2: "K\u00f6tt & Charkuterier, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Livsmedel \u00d6vriga, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Livsmedelshandel", bransch1: "" },
		{ bransch2: "Ljud & Bild, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Mattor & V\u00e4ggbekl\u00e4dnad, Butikshandel", bransch1: "" },
		{ bransch2: "Musikinstrument & Noter, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Mynt & Frim\u00e4rken, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "M\u00f6bler F\u00f6r Hemmet, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Optiker, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Postorder- & Internethandel Detaljhandel", bransch1: "" },
		{ bransch2: "P\u00e4lsar, Butikshandel", bransch1: "" },
		{ bransch2: "Sjukv\u00e5rdsartiklar, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Skor, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Spel & Leksaker, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Sport- & Fritidsartiklar, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "S\u00e4llskapsdjur, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Telekommunikationsutrustning, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Textilier, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Tidningar, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "Tobaksvaror, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Torg- & Marknadshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Varuhus- & Stormarknadshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Virke & Byggvaror, Butikshandel Detaljhandel", bransch1: "" },
		{ bransch2: "V\u00e4skor & Reseffekter, Butikshandel", bransch1: "Detaljhandel" },
		{ bransch2: "Fastighetsf\u00f6rmedling", bransch1: "" },
		{ bransch2: "Fastighetsf\u00f6rvaltning P\u00e5 Uppdrag", bransch1: "" },
		{ bransch2: "Fastighetsrelaterade St\u00f6dtj\u00e4nster", bransch1: "Fastighetsverksamhet" },
		{ bransch2: "F\u00f6rvaltning I Bostadsr\u00e4ttsf\u00f6reningar", bransch1: "" },
		{ bransch2: "Handel Med Egna Fastigheter Fastighetsverksamhet", bransch1: "" },
		{ bransch2: "Reng\u00f6ring & Lokalv\u00e5rd", bransch1: "Fastighetsverksamhet" },
		{ bransch2: "Sk\u00f6tsel & Underh\u00e5ll Av Gr\u00f6nytor Fastighetsverksamhet", bransch1: "" },
		{ bransch2: "Uthyrning & F\u00f6rvaltning Av Fastigheter", bransch1: "Fastighetsverksamhet" },
		{ bransch2: "Bud- & Kurirverksamhet", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "Callcenterverksamhet", bransch1: "" },
		{ bransch2: "Databehandling & Hosting", bransch1: "" },
		{ bransch2: "Fotolaboratorieverksamhet", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "F\u00f6retagstj\u00e4nster, \u00d6vriga", bransch1: "" },
		{ bransch2: "F\u00f6rpackningsverksamhet", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "Informationstj\u00e4nster, \u00d6vriga", bransch1: "" },
		{ bransch2: "Inkasso- & Kreditupplysningsverksamhet", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "Kongresser & M\u00e4ssor F\u00f6retagstj\u00e4nster", bransch1: "" },
		{ bransch2: "Kontorstj\u00e4nster F\u00f6retagstj\u00e4nster", bransch1: "" },
		{ bransch2: "Nyhetsservice", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "Post- & Kurirverksamhet F\u00f6retagstj\u00e4nster", bransch1: "" },
		{ bransch2: "Press- & \u00d6vrig Fotografverksamhet", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "Reklamfotoverksamhet", bransch1: "" },
		{ bransch2: "Spanings- & Detektivverksamhet", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "S\u00e4kerhetssystemtj\u00e4nster F\u00f6retagstj\u00e4nster", bransch1: "" },
		{ bransch2: "S\u00e4kerhetsverksamhet F\u00f6retagstj\u00e4nster", bransch1: "" },
		{ bransch2: "Tidningsdistribution", bransch1: "" },
		{ bransch2: "Verksamheter Som Ut\u00f6vas Av Huvudkontor", bransch1: "F\u00f6retagstj\u00e4nster" },
		{ bransch2: "Webbportaler", bransch1: "" },
		{ bransch2: "Campingplatsverksamhet", bransch1: "Hotell & Restaurang" },
		{ bransch2: "Catering", bransch1: "" },
		{ bransch2: "Centralk\u00f6k F\u00f6r Sjukhus", bransch1: "Hotell & Restaurang" },
		{ bransch2: "Centralk\u00f6k F\u00f6r Skolor & Omsorg", bransch1: "Hotell & Restaurang" },
		{ bransch2: "Hotell & Restaurang Hotell & Restaurang", bransch1: "" },
		{ bransch2: "Konferensanl\u00e4ggningar", bransch1: "Hotell & Restaurang" },
		{ bransch2: "Logiverksamhet, \u00d6vrig", bransch1: "Hotell & Restaurang" },
		{ bransch2: "Personalmatsalar", bransch1: "" },
		{ bransch2: "Restaurangverksamhet", bransch1: "" },
		{ bransch2: "Stugbyverksamhet", bransch1: "" },
		{ bransch2: "Vandrarhemsverksamhet", bransch1: "Hotell & Restaurang" },
		{ bransch2: "Fris\u00f6rer", bransch1: "" },
		{ bransch2: "Sk\u00f6nhetsv\u00e5rd", bransch1: "" },
		{ bransch2: "H\u00e4lso- & Sjukv\u00e5rd, \u00d6vriga", bransch1: "H\u00e4lsa & Sjukv\u00e5rd" },
		{ bransch2: "Prim\u00e4rv\u00e5rdsmottagning", bransch1: "H\u00e4lsa & Sjukv\u00e5rd" },
		{ bransch2: "Sluten Sjukv\u00e5rd H\u00e4lsa & Sjukv\u00e5rd", bransch1: "" },
		{ bransch2: "Sociala Insatser", bransch1: "" },
		{ bransch2: "Specialistl\u00e4kare P\u00e5 Sjukhus H\u00e4lsa & Sjukv\u00e5rd", bransch1: "" },
		{ bransch2: "Tandl\u00e4kare", bransch1: "H\u00e4lsa & Sjukv\u00e5rd" },
		{ bransch2: "Veterin\u00e4rverksamhet H\u00e4lsa & Sjukv\u00e5rd", bransch1: "" },
		{ bransch2: "V\u00e5rd & Omsorg Med Boende", bransch1: "" },
		{ bransch2: "\u00d6ppen H\u00e4lso- & Sjukv\u00e5rd H\u00e4lsa & Sjukv\u00e5rd", bransch1: "" },
		{ bransch2: "Blandat Jordbruk", bransch1: "" },
		{ bransch2: "Djuruppf\u00f6dning, \u00d6vrig", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Drivning", bransch1: "" },
		{ bransch2: "Fiberv\u00e4xtodling Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "Fiskodling", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Fj\u00e4derf\u00e4, Uppf\u00f6dning", bransch1: "" },
		{ bransch2: "Frukt, B\u00e4r & N\u00f6tter, Odling Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "Fruktodling Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "F\u00e5r- & Getuppf\u00f6dning", bransch1: "" },
		{ bransch2: "Gr\u00f6nsaker, Frilandsodling", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Gr\u00f6nsaker, V\u00e4xthusodling", bransch1: "" },
		{ bransch2: "H\u00e4stuppf\u00f6dning", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Jakt", bransch1: "" },
		{ bransch2: "Kamel- & Kameldjursuppf\u00f6dning", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Mj\u00f6lkproduktion & N\u00f6tkreatursuppf\u00f6dning Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "N\u00f6tkreatur & Bufflar, \u00d6vriga", bransch1: "" },
		{ bransch2: "Plantskolev\u00e4xter, Odling", bransch1: "" },
		{ bransch2: "Potatisodling", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Rensk\u00f6tsel", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Saltvattensfiske", bransch1: "" },
		{ bransch2: "Service Till Husdjurssk\u00f6tsel", bransch1: "" },
		{ bransch2: "Service Till V\u00e4xtodling Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "Skogsbruk", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Skogsf\u00f6rvaltning", bransch1: "" },
		{ bransch2: "Skogssk\u00f6tsel", bransch1: "" },
		{ bransch2: "Slaktsvinuppf\u00f6dning Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "Sm\u00e5grisuppf\u00f6dning", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Socketbetsodling", bransch1: "" },
		{ bransch2: "Spannm\u00e5l, Balj- & Oljev\u00e4xter, Odling", bransch1: "" },
		{ bransch2: "Svampodling Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "S\u00e4llskapsdjur, Uppf\u00f6dning", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "S\u00f6tvattensfiske Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "Tobaksodling", bransch1: "" },
		{ bransch2: "Virkesm\u00e4tning", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "V\u00e4xter, Bearbetning Jordbruk, Skogsbruk, Jakt & Fiske", bransch1: "" },
		{ bransch2: "V\u00e4xtodling", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "\u00c4ggproduktion", bransch1: "Jordbruk, Skogsbruk, Jakt & Fiske" },
		{ bransch2: "Advokatbyr\u00e5er", bransch1: "Juridik, Ekonomi & Konsulttj\u00e4nster" },
		{ bransch2: "Juridisk Verksamhet, \u00d6vrig", bransch1: "Juridik, Ekonomi & Konsulttj\u00e4nster" },
		{ bransch2: "Konsultverksamhet Avseende F\u00f6retags Org.", bransch1: "" },
		{ bransch2: "Patentbyr\u00e5er", bransch1: "" },
		{ bransch2: "Redovisning & Bokf\u00f6ring Juridik, Ekonomi & Konsulttj\u00e4nster", bransch1: "" },
		{ bransch2: "Revision", bransch1: "" },
		{ bransch2: "Skatter\u00e5dgivning", bransch1: "" },
		{ bransch2: "Arkivverksamhet Kultur, N\u00f6je & Fritid", bransch1: "" },
		{ bransch2: "Artistisk Verksamhet", bransch1: "" },
		{ bransch2: "Bibliotek", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Fritids-& N\u00f6jesverksamhet, \u00d6vrig", bransch1: "" },
		{ bransch2: "Golfbanor", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Gymanl\u00e4ggningar Kultur, N\u00f6je & Fritid", bransch1: "" },
		{ bransch2: "Idrottsplatser & Sportanl\u00e4ggningar", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Kultur, N\u00f6je & Fritid", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Litter\u00e4rt & Konstn\u00e4rligt Skapande", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Motorbanor", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Museiverksamhet Kultur, N\u00f6je & Fritid", bransch1: "" },
		{ bransch2: "N\u00f6jes- & Temaparksverksamhet", bransch1: "" },
		{ bransch2: "Skidsportanl\u00e4ggningar", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Spel- & Vadh\u00e5llningsverksamhet", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Sportklubbars & Idrottsf\u00f6r.", bransch1: "Verksamhet Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Sportverksamhet, \u00d6vrig", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "Trav- & Galoppbanor Kultur, N\u00f6je & Fritid", bransch1: "" },
		{ bransch2: "Tr\u00e4dg\u00e5rdar, Djurparker & Naturreservat, Drift", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "T\u00e4vling Med H\u00e4star", bransch1: "Kultur, N\u00f6je & Fritid" },
		{ bransch2: "V\u00e5rd Av Historiska Minnesm\u00e4rken & Byggnader Kultur, N\u00f6je & Fritid", bransch1: "" },
		{ bransch2: "Bageri- & Mj\u00f6lprodukter Livsmedelsframst\u00e4llning", bransch1: "" },
		{ bransch2: "Choklad- & Konfektyrtillverkning", bransch1: "" },
		{ bransch2: "Djurfoderframst\u00e4llning", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "Dryckesframst\u00e4llning", bransch1: "" },
		{ bransch2: "Fisk, Skaldjur & Bl\u00f6tdjur, Beredning", bransch1: "" },
		{ bransch2: "Glasstillverkning", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "Juice & Safttillverkning", bransch1: "" },
		{ bransch2: "Kvarnprodukter", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "K\u00f6ttprodukter", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "Livsmedelsframst\u00e4llning Livsmedelsframst\u00e4llning", bransch1: "" },
		{ bransch2: "Mejerivarutillverkning", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "Potatisberedning", bransch1: "" },
		{ bransch2: "Sockertillverkning", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "St\u00e4rkelsetillverkning", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "Te- & Kaffetillverkning Livsmedelsframst\u00e4llning", bransch1: "" },
		{ bransch2: "Tobaksvarutillverkning", bransch1: "Livsmedelsframst\u00e4llning" },
		{ bransch2: "Annonstidningsutgivning Media", bransch1: "" },
		{ bransch2: "Bokutgivning", bransch1: "" },
		{ bransch2: "Dagstidningsutgivning", bransch1: "Media" },
		{ bransch2: "Film, Video & Tv", bransch1: "" },
		{ bransch2: "Filmvisning Media", bransch1: "" },
		{ bransch2: "F\u00f6rlagsverksamhet, \u00d6vrig", bransch1: "" },
		{ bransch2: "Ljudinspelning & Fonogramutgivning", bransch1: "Media" },
		{ bransch2: "Publicering Av Kataloger & S\u00e4ndlistor", bransch1: "Media" },
		{ bransch2: "Radios\u00e4ndning", bransch1: "Media" },
		{ bransch2: "Reproduktion Av Inspelningar", bransch1: "" },
		{ bransch2: "Tv-Program Planering", bransch1: "" },
		{ bransch2: "Utgivning Av Tidskrifter", bransch1: "" },
		{ bransch2: "Husvagnar, Husbilar & Sl\u00e4p, Handel", bransch1: "Motorfordonshandel" },
		{ bransch2: "Lastbilar, Bussar & Specialfordon, Handel", bransch1: "Motorfordonshandel" },
		{ bransch2: "Personbilar & L\u00e4tta Motorfordon, Handel Motorfordonshandel", bransch1: "" },
		{ bransch2: "Arbetsmarknadsprogram", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Brand- & R\u00e4ddningsverksamhet", bransch1: "" },
		{ bransch2: "Civilt F\u00f6rslet & Frivilligf\u00f6rsvar", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Domstolsverksamhet", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Grundskole- & Gymnasieskoleutbildning", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "H\u00e4lso- & Sjukv\u00e5rd, Administration", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Infrastrukturprogram", bransch1: "" },
		{ bransch2: "Inspektion, Kontroll & Tillst\u00e5ndsgivning", bransch1: "" },
		{ bransch2: "Jord- & Skogsbruk, Administration Av Program", bransch1: "" },
		{ bransch2: "Kriminalv\u00e5rd", bransch1: "" },
		{ bransch2: "Kultur, Milj\u00f6, Boende, Administration", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Milit\u00e4rt F\u00f6rsvar", bransch1: "" },
		{ bransch2: "N\u00e4ringslivsprogram, \u00d6vriga", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Obligatorisk Socialf\u00f6rs\u00e4kring", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Omsorg & Socialtj\u00e4nst", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Personalf\u00f6rvaltning & Andra St\u00f6dtj\u00e4nster", bransch1: "" },
		{ bransch2: "Polisverksamhet Offentlig F\u00f6rvaltning & Samh\u00e4lle", bransch1: "" },
		{ bransch2: "Samh\u00e4llelig Informationsf\u00f6rs\u00f6rjning Offentlig F\u00f6rvaltning & Samh\u00e4lle", bransch1: "" },
		{ bransch2: "Skattef\u00f6rvaltning & Indrivning", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Stats- & Kommunledning, Lagstiftning & Planering", bransch1: "" },
		{ bransch2: "Totalf\u00f6rsvaret", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Utrikesf\u00f6rvaltning", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "\u00c5klagarverksamhet", bransch1: "Offentlig F\u00f6rvaltning & Samh\u00e4lle" },
		{ bransch2: "Avfall & Skrot, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Band & Skivor, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Blandat Sortiment", bransch1: "Partihandel" },
		{ bransch2: "Blommor & V\u00e4xter, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Br\u00e4nsle, Mineraler & Industrikem. Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Datorer, Program & Kringutr, Partihandel", bransch1: "" },
		{ bransch2: "Datoriserad Materialhanteringsutr, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Drycker, Partihandel", bransch1: "" },
		{ bransch2: "Elartiklar, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Elektronikkomponenter, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Emballage, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Fisk, Skalddjur & Andra Livsmedel, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Fotografiska & Optiska Produkter, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Frukt & Gr\u00f6nsaker, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Glas, Porslin & Reng\u00f6ringsmedel, Partihandel", bransch1: "" },
		{ bransch2: "Gruv-, Bygg- & Anl\u00e4ggningsmaskiner, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Hudar, Skinn & L\u00e4der, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Hush\u00e5llsapparater & Elartiklar, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Hush\u00e5llsvaror \u00d6vriga, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Icke Spec. Handel Med Livsmedel, Partihandel", bransch1: "" },
		{ bransch2: "Industrif\u00f6rn\u00f6denheter, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Insatsvaror \u00d6vriga, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Jordbruks- & Textilr\u00e5varor, Provisionshandel", bransch1: "" },
		{ bransch2: "Jordbruksmaskiner, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "J\u00e4rnhandelsvaror, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Kaffe, Te, Kakao & Kryddor, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Kemiska Produkter, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Kl\u00e4der & Skor, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Kontorsf\u00f6rbrukningsvaror, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Kontorsmaskiner & Kontorsutrustning, Partihandel", bransch1: "" },
		{ bransch2: "Kontorsm\u00f6bler, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Kontorsutrustning & Datorer, Partihandel", bransch1: "" },
		{ bransch2: "K\u00f6tt & K\u00f6ttvaror, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Levande Djur, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Ljud, Bild & Videoutrustning, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Maskiner & Utrustning \u00d6vriga, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Maskiner, Industriell Utrustning, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Medicinsk Utrustning & Apoteksvaror, Partihandel", bransch1: "" },
		{ bransch2: "Mejeriprodukter, \u00c4gg, Matolja & Matfett, Partihandel", bransch1: "" },
		{ bransch2: "Metallavfall & Metallskrot, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Metaller & Metallmalmer, Partihandel", bransch1: "" },
		{ bransch2: "Motorcyklar, Handel, Service & Tillbeh\u00f6r", bransch1: "" },
		{ bransch2: "Motorfordon, Reservdelar & Tillbeh\u00f6r", bransch1: "" },
		{ bransch2: "M\u00e4t- & Precisionsinstrument, Partihandel", bransch1: "" },
		{ bransch2: "M\u00f6bler, Hush\u00e5lls- & J\u00e4rnhandelsvaror, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "M\u00f6bler, Mattor & Belysning, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Parfym & Kosmetika, Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Partihandel Partihandel", bransch1: "" },
		{ bransch2: "Partihandel, \u00d6vrig", bransch1: "Partihandel" },
		{ bransch2: "Sanitetsgods, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Socker, Choklad & Sockerkonfekt, Partihandel", bransch1: "" },
		{ bransch2: "Specialsortiment", bransch1: "" },
		{ bransch2: "Sport- & Fritidsartiklar, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Teleprodukter, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Textil-, Sy- & Stickmaskiner, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Textilier, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Tobak, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Ur & Guldssmedsvaror, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Uttj\u00e4nta Fordon, Partihandel", bransch1: "" },
		{ bransch2: "Verktygsmaskiner, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Virke & Byggmaterial, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Vvs-Varor, Partihandel", bransch1: "Partihandel" },
		{ bransch2: "Direktreklamverksamhet", bransch1: "Reklam, Pr & Marknadsunders\u00f6kning" },
		{ bransch2: "Marknads- & Opinionsunders\u00f6kning", bransch1: "" },
		{ bransch2: "Reklam, Pr, Mediebyr\u00e5 & Annonsf\u00f6rs\u00e4lj.", bransch1: "Reklam, Pr & Marknadsunders\u00f6kning" },
		{ bransch2: "Datorer & Kringutrustning, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Elapparatur, Reparation Reparation & Installation", bransch1: "" },
		{ bransch2: "Elektronisk & Optisk Utrustning, Reparation Reparation & Installation", bransch1: "" },
		{ bransch2: "Fartyg & B\u00e5tar, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Hemelektronik, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Hush\u00e5lls- & Personartiklar, Reparation, \u00d6vriga", bransch1: "Reparation & Installation" },
		{ bransch2: "Hush\u00e5llsapparater, Hem & Tr\u00e4dg\u00e5rd, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Industri- Maskiner & Utrustning, Installation", bransch1: "Reparation & Installation" },
		{ bransch2: "Kommunikationsutrustning, Reparation", bransch1: "" },
		{ bransch2: "Luftfartyg & Rymdfarkoster, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Maskiner, Reparation", bransch1: "" },
		{ bransch2: "Metallvaror, Reparation Reparation & Installation", bransch1: "" },
		{ bransch2: "Motorfordon, Reparation & Underh\u00e5ll Reparation & Installation", bransch1: "" },
		{ bransch2: "M\u00f6bler & Heminredning, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Skomakare", bransch1: "Reparation & Installation" },
		{ bransch2: "Transportmedel \u00d6vriga, Reparation", bransch1: "Reparation & Installation" },
		{ bransch2: "Ur & Guldsmedsvaror, Reparation Reparation & Installation", bransch1: "" },
		{ bransch2: "Utrustning Reparation, \u00d6vrig", bransch1: "" },
		{ bransch2: "Researrangemang Resebyr\u00e5 & Turism", bransch1: "" },
		{ bransch2: "Resebyr\u00e5er", bransch1: "Resebyr\u00e5 & Turism" },
		{ bransch2: "Turist- & Bokningsservice", bransch1: "Resebyr\u00e5 & Turism" },
		{ bransch2: "Juridik, Ekonomi, Vetenskap & Teknik, \u00d6vrig Teknisk Konsultverksamhet", bransch1: "" },
		{ bransch2: "Teknisk Konsult Inom Elteknik", bransch1: "Teknisk Konsultverksamhet" },
		{ bransch2: "Teknisk Konsult Inom Energi-, Milj\u00f6- & Vvs-Teknik", bransch1: "Teknisk Konsultverksamhet" },
		{ bransch2: "Teknisk Konsult Inom Industriteknik Teknisk Konsultverksamhet", bransch1: "" },
		{ bransch2: "Teknisk Konsultverksamhet, \u00d6vrig", bransch1: "" },
		{ bransch2: "Teknisk Provning & Analys", bransch1: "Teknisk Konsultverksamhet" },
		{ bransch2: "Arbets- & Skyddskl\u00e4der, Tillverkning", bransch1: "" },
		{ bransch2: "Basplast, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Batteri- Och Ackumulatortillverkning", bransch1: "" },
		{ bransch2: "Bek\u00e4mpningsmedel & Lantbrukskem, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Belysningsarmaturtillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Betong-, Cement- & Gipsvaror, \u00d6vriga", bransch1: "" },
		{ bransch2: "Betongvarutillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Bijouteritillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Borstbinderitillverkning", bransch1: "" },
		{ bransch2: "Byggmaterialtillverkning", bransch1: "" },
		{ bransch2: "Byggnadsmetallvaror, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Byggplastvarutillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "B\u00e5t & Fartyg, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Cementtillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Cyklar & Invalidfordon, Tillverkning", bransch1: "" },
		{ bransch2: "Datorer & Kringutrustning, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "D\u00f6rrar Av Tr\u00e4, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Eldfasta Produkter", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Elektriska Hush\u00e5llsmaskiner, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Elektriska Komponenter & Kretskort, Tillverkning", bransch1: "" },
		{ bransch2: "Elektronikindustri", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Eteriska Oljor, Tillverkning", bransch1: "" },
		{ bransch2: "Fabriksblandad Betongtillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Farmaceutiska Basprodukter, Tillverkning", bransch1: "" },
		{ bransch2: "Fibercementvarutillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "F\u00e4rg, Lack & Tryckf\u00e4rg, Tillverkning", bransch1: "" },
		{ bransch2: "F\u00e4rg\u00e4mnen, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "F\u00f6nster Av Tr\u00e4, Tillverkning", bransch1: "" },
		{ bransch2: "F\u00f6rv\u00e4rvsarbete I Hush\u00e5ll", bransch1: "" },
		{ bransch2: "Garntillverkning", bransch1: "" },
		{ bransch2: "Gipsvarutillverkning", bransch1: "" },
		{ bransch2: "Gjutning", bransch1: "" },
		{ bransch2: "Glas- & Glasvarutillverkning", bransch1: "" },
		{ bransch2: "Glasfibertillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Grafisk Produktion", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Gummivaror, Tillverkning", bransch1: "" },
		{ bransch2: "G\u00f6dsel- & Kv\u00e4veprodukter, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Hemelektronik, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Industrigasframst\u00e4llning", bransch1: "" },
		{ bransch2: "Jord- & Skogsbruksmaskiner, Tillverkning", bransch1: "" },
		{ bransch2: "J\u00e4rnmalmsutvinning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Kabeltillbeh\u00f6r, Tillverkning", bransch1: "" },
		{ bransch2: "Kalk & Gipstillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Kemiska Produkter, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Keramiska Golv- & V\u00e4ggplattor", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Keramiska Produkter, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Kl\u00e4der & Textilier, Tillverkning", bransch1: "" },
		{ bransch2: "Kommunikationsutrustning, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Konstfiber, Tillverkning", bransch1: "" },
		{ bransch2: "Kontors- & Butiksinred, Tillverkning", bransch1: "" },
		{ bransch2: "Kontors- & Butiksm\u00f6ber, Tillverkning", bransch1: "" },
		{ bransch2: "Kontorsmaskiner, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "K\u00f6ksinredningar, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "K\u00f6ksm\u00f6bler, Tillverkning", bransch1: "" },
		{ bransch2: "Lim, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "L\u00e4der- & Skinnkl\u00e4der, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "L\u00e4kemedel, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Madrasser, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Malmutvinning, \u00d6vrig", bransch1: "" },
		{ bransch2: "Maskiner, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Mattor, Tillverkning", bransch1: "" },
		{ bransch2: "Medicin- & Dentalinstrumenttillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Metallindustri", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Milit\u00e4ra Stridsfordon, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Mineralutvinning, \u00d6vrig Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Motorcyklar, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Motorer & Turbiner, Tillverkning", bransch1: "" },
		{ bransch2: "Motorfordonstillverkning", bransch1: "" },
		{ bransch2: "Murbrukstillverkning", bransch1: "" },
		{ bransch2: "Musikinstrumenttillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Mynttillverkning", bransch1: "" },
		{ bransch2: "M\u00f6bler \u00d6vriga, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Oorganiska Baskemikalier, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Optiska Fiberkabeltillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Optiska Instrument & Fotoutrustning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Organiska Baskemikalier, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Pappers- & Pappersvarutillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Petroleumraffinering", bransch1: "" },
		{ bransch2: "Planglas", bransch1: "" },
		{ bransch2: "Plastf\u00f6rpackningstillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Plastvaror, Tillverkning", bransch1: "" },
		{ bransch2: "Plastvarutillverkning, \u00d6vrig", bransch1: "" },
		{ bransch2: "P\u00e4lsvaror, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "R\u00e5petroleumutvinning", bransch1: "" },
		{ bransch2: "R\u00e4lsfordon, Tillverkning", bransch1: "" },
		{ bransch2: "Saltutvinning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Skor, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Smyckestillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Spel- & Leksakstillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Sportartikelstillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Spr\u00e4ng\u00e4mne, Tillverkning", bransch1: "" },
		{ bransch2: "Stenhuggning", bransch1: "" },
		{ bransch2: "Stenkolsprodukter", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Strumpor, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Syntetiskt Basgummi, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "S\u00e5gning, Hyvling & Impregnering Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Tandprotestillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Tillverkning, \u00d6vrig Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Torvutvinning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Transportmedel \u00d6vriga, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Transportmedelsindustri Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Tryckning Av B\u00f6cker & \u00d6vrigt", bransch1: "" },
		{ bransch2: "Tryckning Av Dagstidningar", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Tryckning Av Tidsskrifter", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Tr\u00e4hus, Tillverkning", bransch1: "" },
		{ bransch2: "Tr\u00e4varor, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Tv\u00e5l, S\u00e5pa & Tv\u00e4ttmedel, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Uran- & Toriummalmutvinning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Urtillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "Utvinning, St\u00f6dtj\u00e4nster Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Vapen & Ammunition, Tillverkning", bransch1: "" },
		{ bransch2: "Verktyg & Redskap, Tillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "Verktygsmaskiner, Tillverkning", bransch1: "Tillverkning & Industri" },
		{ bransch2: "V\u00e4vnadstillverkning Tillverkning & Industri", bransch1: "" },
		{ bransch2: "B\u00e4rgning", bransch1: "" },
		{ bransch2: "Flyttj\u00e4nster", bransch1: "" },
		{ bransch2: "Godshantering, \u00d6vrig", bransch1: "" },
		{ bransch2: "Hamngodshantering", bransch1: "Transport & Magasinering" },
		{ bransch2: "Havs- & Sj\u00f6fart, Godstrafik Transport & Magasinering", bransch1: "" },
		{ bransch2: "Havs- & Sj\u00f6fart, Passagerartrafik", bransch1: "Transport & Magasinering" },
		{ bransch2: "J\u00e4rnv\u00e4gstransport- Passagerartrafik Transport & Magasinering", bransch1: "" },
		{ bransch2: "J\u00e4rnv\u00e4gstransport-Godstrafik", bransch1: "" },
		{ bransch2: "Kollektivtrafik, \u00d6vrig", bransch1: "Transport & Magasinering" },
		{ bransch2: "Landtransport Av Passagerare, \u00d6vrig Transport & Magasinering", bransch1: "" },
		{ bransch2: "Landtransport, St\u00f6dtj\u00e4nster Transport & Magasinering", bransch1: "" },
		{ bransch2: "Linjebussverksamhet Transport & Magasinering", bransch1: "" },
		{ bransch2: "Lufttransport, Godstrafik", bransch1: "Transport & Magasinering" },
		{ bransch2: "Lufttransport, Passagerartrafik Transport & Magasinering", bransch1: "" },
		{ bransch2: "Lufttransporter, St\u00f6dtj\u00e4nster", bransch1: "Transport & Magasinering" },
		{ bransch2: "Magasinering & Varulagring", bransch1: "Transport & Magasinering" },
		{ bransch2: "Rymdfart", bransch1: "" },
		{ bransch2: "R\u00f6rsystem", bransch1: "Transport & Magasinering" },
		{ bransch2: "Sj\u00f6transport, St\u00f6dtj\u00e4nster", bransch1: "Transport & Magasinering" },
		{ bransch2: "Taxi", bransch1: "" },
		{ bransch2: "Transport St\u00f6dtj\u00e4nster, \u00d6vriga", bransch1: "Transport & Magasinering" },
		{ bransch2: "V\u00e4gtransport, Godstrafik", bransch1: "" },
		{ bransch2: "Arbetsmarknadsutbildning", bransch1: "" },
		{ bransch2: "Bioteknisk Forskning & Utveckling", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Eftergymnasial Utbildning", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Folkh\u00f6gskoleutbildning", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "F\u00f6rskoleutbildning", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Grundskoleutbildning", bransch1: "" },
		{ bransch2: "Gymnasial Utbildning", bransch1: "" },
		{ bransch2: "Musik-, Dans- & Kulturell Utbildning", bransch1: "" },
		{ bransch2: "Naturvetenskaplig Och Teknisk F&u", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Personalutbildning", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Samh\u00e4llsvet. & Humanistisk F&u", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Sport- & Fritidsutbildning", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Studief\u00f6rbundens & Frivilligorg. Utbildning Utbildning, Forskning & Utveckling", bransch1: "" },
		{ bransch2: "Trafikskoleverksamhet", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Universitets- & H\u00f6gskoleutbildning", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Utbildning, \u00d6vrig", bransch1: "Utbildning, Forskning & Utveckling" },
		{ bransch2: "Utbildningsv\u00e4sendet, St\u00f6dverksamhet Utbildning, Forskning & Utveckling", bransch1: "" },
		{ bransch2: "Yrkesf\u00f6rarutbildning", bransch1: "" },
		{ bransch2: "\u00d6vers\u00e4ttning & Tolkning Utbildning, Forskning & Utveckling", bransch1: "" },
		{ bransch2: "Leasing Av Immateriell Egendom & Liknande Prod. Uthyrning & Leasing", bransch1: "" },
		{ bransch2: "Uthyrning & Leasing Av Andra Hush\u00e5llsartiklar & Varor F\u00f6r Personligt Bruk", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing Av Bygg- & Anl\u00e4ggningsmaskiner", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing Av Fartyg & B\u00e5tar", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing Av Flygplan Uthyrning & Leasing", bransch1: "" },
		{ bransch2: "Uthyrning & Leasing Av Fritids- & Sportutrustning", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing Av Jordbruksmaskiner & Jordbruksredskap Uthyrning & Leasing", bransch1: "" },
		{ bransch2: "Uthyrning & Leasing Av Kontorsmaskiner & Kontorsutrustning", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing Av Lastbilar & Andra Tunga Motorfordon", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing Av Personbilar & L\u00e4tta Motorfordon", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning & Leasing \u00d6vrigt", bransch1: "Uthyrning & Leasing" },
		{ bransch2: "Uthyrning Av Videokassetter & Dvd-Skivor", bransch1: "" },
		{ bransch2: "Begravningsverksamhet", bransch1: "\u00d6vriga Konsumenttj\u00e4nster" },
		{ bransch2: "Konsumenttj\u00e4nster, \u00d6vriga", bransch1: "\u00d6vriga Konsumenttj\u00e4nster" },
		{ bransch2: "Kroppsv\u00e5rd", bransch1: "\u00d6vriga Konsumenttj\u00e4nster" },
		{ bransch2: "Portr\u00e4ttfotoverksamhet", bransch1: "\u00d6vriga Konsumenttj\u00e4nster" },
		{ bransch2: "Tv\u00e4tteriverksamhet", bransch1: "\u00d6vriga Konsumenttj\u00e4nster" },
	];
	initialState.countries = [
		{ label: "Afghanistan", value: "AF", en_label: "Afghanistan" },
		{ label: "Albanien", value: "AL", en_label: "Albania" },
		{ label: "Algeriet", value: "DZ", en_label: "Algeria" },
		{ label: "Amerikanska Jungfru\u00f6arna", value: "VI", en_label: "U.S. Virgin Islands" },
		{ label: "Amerikanska Samoa", value: "AS", en_label: "American Samoa" },
		{ label: "Andorra", value: "AD", en_label: "Andorra" },
		{ label: "Angola", value: "AO", en_label: "Angola" },
		{ label: "Anguilla", value: "AI", en_label: "Anguilla" },
		{ label: "Antarktis", value: "AQ", en_label: "Antarctica" },
		{ label: "Antigua och Barbuda", value: "AG", en_label: "Antigua & Barbuda" },
		{ label: "Argentina", value: "AR", en_label: "Argentina" },
		{ label: "Armenien", value: "AM", en_label: "Armenia" },
		{ label: "Aruba", value: "AW", en_label: "Aruba" },
		{ label: "Australien", value: "AU", en_label: "Australia" },
		{ label: "Azerbajdzjan", value: "AZ", en_label: "Azerbaijan" },
		{ label: "Bahamas", value: "BS", en_label: "Bahamas" },
		{ label: "Bahrain", value: "BH", en_label: "Bahrain" },
		{ label: "Bangladesh", value: "BD", en_label: "Bangladesh" },
		{ label: "Barbados", value: "BB", en_label: "Barbados" },
		{ label: "Belgien", value: "BE", en_label: "Belgium" },
		{ label: "Belize", value: "BZ", en_label: "Belize" },
		{ label: "Benin", value: "BJ", en_label: "Benin" },
		{ label: "Bermuda", value: "BM", en_label: "Bermuda" },
		{ label: "Bhutan", value: "BT", en_label: "Bhutan" },
		{ label: "Bolivia", value: "BO", en_label: "Bolivia" },
		{ label: "Bosnien och Hercegovina", value: "BA", en_label: "Bosnia & Herzegovina" },
		{ label: "Botswana", value: "BW", en_label: "Botswana" },
		{ label: "Bouvet\u00f6n", value: "BV", en_label: "Bouvet Island" },
		{ label: "Brasilien", value: "BR", en_label: "Brazil" },
		{ label: "Brittiska Jungfru\u00f6arna", value: "VG", en_label: "British Virgin Islands" },
		{ label: "Brittiska territoriet i Indiska oceanen", value: "IO", en_label: "British Indian Ocean Territory" },
		{ label: "Brunei", value: "BN", en_label: "Brunei" },
		{ label: "Bulgarien", value: "BG", en_label: "Bulgaria" },
		{ label: "Burkina Faso", value: "BF", en_label: "Burkina Faso" },
		{ label: "Burundi", value: "BI", en_label: "Burundi" },
		{ label: "Cayman\u00f6arna", value: "KY", en_label: "Cayman Islands" },
		{ label: "Centralafrikanska republiken", value: "CF", en_label: "Central African Republic" },
		{ label: "Chile", value: "CL", en_label: "Chile" },
		{ label: "Colombia", value: "CO", en_label: "Colombia" },
		{ label: "Cook\u00f6arna", value: "CK", en_label: "Cook Islands" },
		{ label: "Costa Rica", value: "CR", en_label: "Costa Rica" },
		{ label: "Cura\u00e7ao", value: "CW", en_label: "Cura\u00e7ao" },
		{ label: "Cypern", value: "CY", en_label: "Cyprus" },
		{ label: "C\u00f4te d\u2019Ivoire", value: "CI", en_label: "C\u00f4te d\u2019Ivoire" },
		{ label: "Danmark", value: "DK", en_label: "Denmark" },
		{ label: "Djibouti", value: "DJ", en_label: "Djibouti" },
		{ label: "Dominica", value: "DM", en_label: "Dominica" },
		{ label: "Dominikanska republiken", value: "DO", en_label: "Dominican Republic" },
		{ label: "Ecuador", value: "EC", en_label: "Ecuador" },
		{ label: "Egypten", value: "EG", en_label: "Egypt" },
		{ label: "Ekvatorialguinea", value: "GQ", en_label: "Equatorial Guinea" },
		{ label: "El Salvador", value: "SV", en_label: "El Salvador" },
		{ label: "Eritrea", value: "ER", en_label: "Eritrea" },
		{ label: "Estland", value: "EE", en_label: "Estonia" },
		{ label: "Eswatini", value: "SZ", en_label: "Eswatini" },
		{ label: "Etiopien", value: "ET", en_label: "Ethiopia" },
		{ label: "Falklands\u00f6arna", value: "FK", en_label: "Falkland Islands" },
		{ label: "Fiji", value: "FJ", en_label: "Fiji" },
		{ label: "Filippinerna", value: "PH", en_label: "Philippines" },
		{ label: "Finland", value: "FI", en_label: "Finland" },
		{ label: "Frankrike", value: "FR", en_label: "France" },
		{ label: "Franska Guyana", value: "GF", en_label: "French Guiana" },
		{ label: "Franska Polynesien", value: "PF", en_label: "French Polynesia" },
		{ label: "Franska sydterritorierna", value: "TF", en_label: "French Southern Territories" },
		{ label: "F\u00e4r\u00f6arna", value: "FO", en_label: "Faroe Islands" },
		{ label: "F\u00f6renade Arabemiraten", value: "AE", en_label: "United Arab Emirates" },
		{ label: "Gabon", value: "GA", en_label: "Gabon" },
		{ label: "Gambia", value: "GM", en_label: "Gambia" },
		{ label: "Georgien", value: "GE", en_label: "Georgia" },
		{ label: "Ghana", value: "GH", en_label: "Ghana" },
		{ label: "Gibraltar", value: "GI", en_label: "Gibraltar" },
		{ label: "Grekland", value: "GR", en_label: "Greece" },
		{ label: "Grenada", value: "GD", en_label: "Grenada" },
		{ label: "Gr\u00f6nland", value: "GL", en_label: "Greenland" },
		{ label: "Guadeloupe", value: "GP", en_label: "Guadeloupe" },
		{ label: "Guam", value: "GU", en_label: "Guam" },
		{ label: "Guatemala", value: "GT", en_label: "Guatemala" },
		{ label: "Guernsey", value: "GG", en_label: "Guernsey" },
		{ label: "Guinea", value: "GN", en_label: "Guinea" },
		{ label: "Guinea-Bissau", value: "GW", en_label: "Guinea-Bissau" },
		{ label: "Guyana", value: "GY", en_label: "Guyana" },
		{ label: "Haiti", value: "HT", en_label: "Haiti" },
		{ label: "Heard\u00f6n och McDonald\u00f6arna", value: "HM", en_label: "Heard & McDonald Islands" },
		{ label: "Honduras", value: "HN", en_label: "Honduras" },
		{ label: "Hongkong SAR", value: "HK", en_label: "Hong Kong SAR China" },
		{ label: "Indien", value: "IN", en_label: "India" },
		{ label: "Indonesien", value: "ID", en_label: "Indonesia" },
		{ label: "Irak", value: "IQ", en_label: "Iraq" },
		{ label: "Iran", value: "IR", en_label: "Iran" },
		{ label: "Irland", value: "IE", en_label: "Ireland" },
		{ label: "Island", value: "IS", en_label: "Iceland" },
		{ label: "Isle of Man", value: "IM", en_label: "Isle of Man" },
		{ label: "Israel", value: "IL", en_label: "Israel" },
		{ label: "Italien", value: "IT", en_label: "Italy" },
		{ label: "Jamaica", value: "JM", en_label: "Jamaica" },
		{ label: "Japan", value: "JP", en_label: "Japan" },
		{ label: "Jemen", value: "YE", en_label: "Yemen" },
		{ label: "Jersey", value: "JE", en_label: "Jersey" },
		{ label: "Jordanien", value: "JO", en_label: "Jordan" },
		{ label: "Jul\u00f6n", value: "CX", en_label: "Christmas Island" },
		{ label: "Kambodja", value: "KH", en_label: "Cambodia" },
		{ label: "Kamerun", value: "CM", en_label: "Cameroon" },
		{ label: "Kanada", value: "CA", en_label: "Canada" },
		{ label: "Kap Verde", value: "CV", en_label: "Cape Verde" },
		{ label: "Karibiska Nederl\u00e4nderna", value: "BQ", en_label: "Caribbean Netherlands" },
		{ label: "Kazakstan", value: "KZ", en_label: "Kazakhstan" },
		{ label: "Kenya", value: "KE", en_label: "Kenya" },
		{ label: "Kina", value: "CN", en_label: "China" },
		{ label: "Kirgizistan", value: "KG", en_label: "Kyrgyzstan" },
		{ label: "Kiribati", value: "KI", en_label: "Kiribati" },
		{ label: "Kokos\u00f6arna", value: "CC", en_label: "Cocos (Keeling) Islands" },
		{ label: "Komorerna", value: "KM", en_label: "Comoros" },
		{ label: "Kongo-Brazzaville", value: "CG", en_label: "Congo - Brazzaville" },
		{ label: "Kongo-Kinshasa", value: "CD", en_label: "Congo - Kinshasa" },
		{ label: "Kroatien", value: "HR", en_label: "Croatia" },
		{ label: "Kuba", value: "CU", en_label: "Cuba" },
		{ label: "Kuwait", value: "KW", en_label: "Kuwait" },
		{ label: "Laos", value: "LA", en_label: "Laos" },
		{ label: "Lesotho", value: "LS", en_label: "Lesotho" },
		{ label: "Lettland", value: "LV", en_label: "Latvia" },
		{ label: "Libanon", value: "LB", en_label: "Lebanon" },
		{ label: "Liberia", value: "LR", en_label: "Liberia" },
		{ label: "Libyen", value: "LY", en_label: "Libya" },
		{ label: "Liechtenstein", value: "LI", en_label: "Liechtenstein" },
		{ label: "Litauen", value: "LT", en_label: "Lithuania" },
		{ label: "Luxemburg", value: "LU", en_label: "Luxembourg" },
		{ label: "Macao SAR", value: "MO", en_label: "Macao SAR China" },
		{ label: "Madagaskar", value: "MG", en_label: "Madagascar" },
		{ label: "Malawi", value: "MW", en_label: "Malawi" },
		{ label: "Malaysia", value: "MY", en_label: "Malaysia" },
		{ label: "Maldiverna", value: "MV", en_label: "Maldives" },
		{ label: "Mali", value: "ML", en_label: "Mali" },
		{ label: "Malta", value: "MT", en_label: "Malta" },
		{ label: "Marocko", value: "MA", en_label: "Morocco" },
		{ label: "Marshall\u00f6arna", value: "MH", en_label: "Marshall Islands" },
		{ label: "Martinique", value: "MQ", en_label: "Martinique" },
		{ label: "Mauretanien", value: "MR", en_label: "Mauritania" },
		{ label: "Mauritius", value: "MU", en_label: "Mauritius" },
		{ label: "Mayotte", value: "YT", en_label: "Mayotte" },
		{ label: "Mexiko", value: "MX", en_label: "Mexico" },
		{ label: "Mikronesien", value: "FM", en_label: "Micronesia" },
		{ label: "Mo\u00e7ambique", value: "MZ", en_label: "Mozambique" },
		{ label: "Moldavien", value: "MD", en_label: "Moldova" },
		{ label: "Monaco", value: "MC", en_label: "Monaco" },
		{ label: "Mongoliet", value: "MN", en_label: "Mongolia" },
		{ label: "Montenegro", value: "ME", en_label: "Montenegro" },
		{ label: "Montserrat", value: "MS", en_label: "Montserrat" },
		{ label: "Myanmar (Burma)", value: "MM", en_label: "Myanmar (Burma)" },
		{ label: "Namibia", value: "NA", en_label: "Namibia" },
		{ label: "Nauru", value: "NR", en_label: "Nauru" },
		{ label: "Nederl\u00e4nderna", value: "NL", en_label: "Netherlands" },
		{ label: "Nepal", value: "NP", en_label: "Nepal" },
		{ label: "Nicaragua", value: "NI", en_label: "Nicaragua" },
		{ label: "Niger", value: "NE", en_label: "Niger" },
		{ label: "Nigeria", value: "NG", en_label: "Nigeria" },
		{ label: "Niue", value: "NU", en_label: "Niue" },
		{ label: "Nordkorea", value: "KP", en_label: "North Korea" },
		{ label: "Nordmakedonien", value: "MK", en_label: "North Macedonia" },
		{ label: "Nordmarianerna", value: "MP", en_label: "Northern Mariana Islands" },
		{ label: "Norfolk\u00f6n", value: "NF", en_label: "Norfolk Island" },
		{ label: "Norge", value: "NO", en_label: "Norway" },
		{ label: "Nya Kaledonien", value: "NC", en_label: "New Caledonia" },
		{ label: "Nya Zeeland", value: "NZ", en_label: "New Zealand" },
		{ label: "Oman", value: "OM", en_label: "Oman" },
		{ label: "Pakistan", value: "PK", en_label: "Pakistan" },
		{ label: "Palau", value: "PW", en_label: "Palau" },
		{ label: "Palestinska territorierna", value: "PS", en_label: "Palestinian Territories" },
		{ label: "Panama", value: "PA", en_label: "Panama" },
		{ label: "Papua Nya Guinea", value: "PG", en_label: "Papua New Guinea" },
		{ label: "Paraguay", value: "PY", en_label: "Paraguay" },
		{ label: "Peru", value: "PE", en_label: "Peru" },
		{ label: "Pitcairn\u00f6arna", value: "PN", en_label: "Pitcairn Islands" },
		{ label: "Polen", value: "PL", en_label: "Poland" },
		{ label: "Portugal", value: "PT", en_label: "Portugal" },
		{ label: "Puerto Rico", value: "PR", en_label: "Puerto Rico" },
		{ label: "Qatar", value: "QA", en_label: "Qatar" },
		{ label: "R\u00e9union", value: "RE", en_label: "R\u00e9union" },
		{ label: "Rum\u00e4nien", value: "RO", en_label: "Romania" },
		{ label: "Rwanda", value: "RW", en_label: "Rwanda" },
		{ label: "Ryssland", value: "RU", en_label: "Russia" },
		{ label: "S:t Barth\u00e9lemy", value: "BL", en_label: "St. Barth\u00e9lemy" },
		{ label: "S:t Helena", value: "SH", en_label: "St. Helena" },
		{ label: "S:t Kitts och Nevis", value: "KN", en_label: "St. Kitts & Nevis" },
		{ label: "S:t Lucia", value: "LC", en_label: "St. Lucia" },
		{ label: "S:t Pierre och Miquelon", value: "PM", en_label: "St. Pierre & Miquelon" },
		{ label: "S:t Vincent och Grenadinerna", value: "VC", en_label: "St. Vincent & Grenadines" },
		{ label: "Saint-Martin", value: "MF", en_label: "St. Martin" },
		{ label: "Salomon\u00f6arna", value: "SB", en_label: "Solomon Islands" },
		{ label: "Samoa", value: "WS", en_label: "Samoa" },
		{ label: "San Marino", value: "SM", en_label: "San Marino" },
		{ label: "S\u00e3o Tom\u00e9 och Pr\u00edncipe", value: "ST", en_label: "S\u00e3o Tom\u00e9 & Pr\u00edncipe" },
		{ label: "Saudiarabien", value: "SA", en_label: "Saudi Arabia" },
		{ label: "Schweiz", value: "CH", en_label: "Switzerland" },
		{ label: "Senegal", value: "SN", en_label: "Senegal" },
		{ label: "Serbien", value: "RS", en_label: "Serbia" },
		{ label: "Seychellerna", value: "SC", en_label: "Seychelles" },
		{ label: "Sierra Leone", value: "SL", en_label: "Sierra Leone" },
		{ label: "Singapore", value: "SG", en_label: "Singapore" },
		{ label: "Sint Maarten", value: "SX", en_label: "Sint Maarten" },
		{ label: "Slovakien", value: "SK", en_label: "Slovakia" },
		{ label: "Slovenien", value: "SI", en_label: "Slovenia" },
		{ label: "Somalia", value: "SO", en_label: "Somalia" },
		{ label: "Spanien", value: "ES", en_label: "Spain" },
		{ label: "Sri Lanka", value: "LK", en_label: "Sri Lanka" },
		{ label: "Storbritannien", value: "GB", en_label: "United Kingdom" },
		{ label: "Sudan", value: "SD", en_label: "Sudan" },
		{ label: "Surinam", value: "SR", en_label: "Suriname" },
		{ label: "Svalbard och Jan Mayen", value: "SJ", en_label: "Svalbard & Jan Mayen" },
		{ label: "Sverige", value: "SE", en_label: "Sweden" },
		{ label: "Sydafrika", value: "ZA", en_label: "South Africa" },
		{ label: "Sydgeorgien och Sydsandwich\u00f6arna", value: "GS", en_label: "South Georgia & South Sandwich Islands" },
		{ label: "Sydkorea", value: "KR", en_label: "South Korea" },
		{ label: "Sydsudan", value: "SS", en_label: "South Sudan" },
		{ label: "Syrien", value: "SY", en_label: "Syria" },
		{ label: "Tadzjikistan", value: "TJ", en_label: "Tajikistan" },
		{ label: "Taiwan", value: "TW", en_label: "Taiwan" },
		{ label: "Tanzania", value: "TZ", en_label: "Tanzania" },
		{ label: "Tchad", value: "TD", en_label: "Chad" },
		{ label: "Thailand", value: "TH", en_label: "Thailand" },
		{ label: "Tjeckien", value: "CZ", en_label: "Czechia" },
		{ label: "Togo", value: "TG", en_label: "Togo" },
		{ label: "Tokelau\u00f6arna", value: "TK", en_label: "Tokelau" },
		{ label: "Tonga", value: "TO", en_label: "Tonga" },
		{ label: "Trinidad och Tobago", value: "TT", en_label: "Trinidad & Tobago" },
		{ label: "Tunisien", value: "TN", en_label: "Tunisia" },
		{ label: "Turkiet", value: "TR", en_label: "T\u00fcrkiye" },
		{ label: "Turkmenistan", value: "TM", en_label: "Turkmenistan" },
		{ label: "Turks- och Caicos\u00f6arna", value: "TC", en_label: "Turks & Caicos Islands" },
		{ label: "Tuvalu", value: "TV", en_label: "Tuvalu" },
		{ label: "Tyskland", value: "DE", en_label: "Germany" },
		{ label: "Uganda", value: "UG", en_label: "Uganda" },
		{ label: "Ukraina", value: "UA", en_label: "Ukraine" },
		{ label: "Ungern", value: "HU", en_label: "Hungary" },
		{ label: "Uruguay", value: "UY", en_label: "Uruguay" },
		{ label: "USA", value: "US", en_label: "United States" },
		{ label: "USA:s yttre \u00f6ar", value: "UM", en_label: "U.S. Outlying Islands" },
		{ label: "Uzbekistan", value: "UZ", en_label: "Uzbekistan" },
		{ label: "Vanuatu", value: "VU", en_label: "Vanuatu" },
		{ label: "Vatikanstaten", value: "VA", en_label: "Vatican City" },
		{ label: "Venezuela", value: "VE", en_label: "Venezuela" },
		{ label: "Vietnam", value: "VN", en_label: "Vietnam" },
		{ label: "Vitryssland", value: "BY", en_label: "Belarus" },
		{ label: "V\u00e4stsahara", value: "EH", en_label: "Western Sahara" },
		{ label: "Wallis- och Futuna\u00f6arna", value: "WF", en_label: "Wallis & Futuna" },
		{ label: "Zambia", value: "ZM", en_label: "Zambia" },
		{ label: "Zimbabwe", value: "ZW", en_label: "Zimbabwe" },
		{ label: "\u00c5land", value: "AX", en_label: "\u00c5land Islands" },
		{ label: "\u00d6sterrike", value: "AT", en_label: "Austria" },
		{ label: "\u00d6sttimor", value: "TL", en_label: "Timor-Leste" },
	];
	initialState.currencies = [
		{ label: "Afghan Afghani", value: "AFN" },
		{ label: "Afghan Afghani (1927\u20132002)", value: "AFA" },
		{ label: "Albanian Lek", value: "ALL" },
		{ label: "Albanian Lek (1946\u20131965)", value: "ALK" },
		{ label: "Algerian Dinar", value: "DZD" },
		{ label: "Andorran Peseta", value: "ADP" },
		{ label: "Angolan Kwanza", value: "AOA" },
		{ label: "Angolan Kwanza (1977\u20131991)", value: "AOK" },
		{ label: "Angolan New Kwanza (1990\u20132000)", value: "AON" },
		{ label: "Angolan Readjusted Kwanza (1995\u20131999)", value: "AOR" },
		{ label: "Argentine Austral", value: "ARA" },
		{ label: "Argentine Peso", value: "ARS" },
		{ label: "Argentine Peso (1881\u20131970)", value: "ARM" },
		{ label: "Argentine Peso (1983\u20131985)", value: "ARP" },
		{ label: "Argentine Peso Ley (1970\u20131983)", value: "ARL" },
		{ label: "Armenian Dram", value: "AMD" },
		{ label: "Aruban Florin", value: "AWG" },
		{ label: "Australian Dollar", value: "AUD" },
		{ label: "Austrian Schilling", value: "ATS" },
		{ label: "Azerbaijani Manat", value: "AZN" },
		{ label: "Azerbaijani Manat (1993\u20132006)", value: "AZM" },
		{ label: "Bahamian Dollar", value: "BSD" },
		{ label: "Bahraini Dinar", value: "BHD" },
		{ label: "Bangladeshi Taka", value: "BDT" },
		{ label: "Barbadian Dollar", value: "BBD" },
		{ label: "Belarusian Ruble", value: "BYN" },
		{ label: "Belarusian Ruble (1994\u20131999)", value: "BYB" },
		{ label: "Belarusian Ruble (2000\u20132016)", value: "BYR" },
		{ label: "Belgian Franc", value: "BEF" },
		{ label: "Belgian Franc (convertible)", value: "BEC" },
		{ label: "Belgian Franc (financial)", value: "BEL" },
		{ label: "Belize Dollar", value: "BZD" },
		{ label: "Bermudan Dollar", value: "BMD" },
		{ label: "Bhutanese Ngultrum", value: "BTN" },
		{ label: "Bolivian Boliviano", value: "BOB" },
		{ label: "Bolivian Boliviano (1863\u20131963)", value: "BOL" },
		{ label: "Bolivian Mvdol", value: "BOV" },
		{ label: "Bolivian Peso", value: "BOP" },
		{ label: "Bosnia-Herzegovina Convertible Mark", value: "BAM" },
		{ label: "Bosnia-Herzegovina Dinar (1992\u20131994)", value: "BAD" },
		{ label: "Bosnia-Herzegovina New Dinar (1994\u20131997)", value: "BAN" },
		{ label: "Botswanan Pula", value: "BWP" },
		{ label: "Brazilian Cruzado (1986\u20131989)", value: "BRC" },
		{ label: "Brazilian Cruzeiro (1942\u20131967)", value: "BRZ" },
		{ label: "Brazilian Cruzeiro (1990\u20131993)", value: "BRE" },
		{ label: "Brazilian Cruzeiro (1993\u20131994)", value: "BRR" },
		{ label: "Brazilian New Cruzado (1989\u20131990)", value: "BRN" },
		{ label: "Brazilian New Cruzeiro (1967\u20131986)", value: "BRB" },
		{ label: "Brazilian Real", value: "BRL" },
		{ label: "British Pound", value: "GBP" },
		{ label: "Brunei Dollar", value: "BND" },
		{ label: "Bulgarian Hard Lev", value: "BGL" },
		{ label: "Bulgarian Lev", value: "BGN" },
		{ label: "Bulgarian Lev (1879\u20131952)", value: "BGO" },
		{ label: "Bulgarian Socialist Lev", value: "BGM" },
		{ label: "Burmese Kyat", value: "BUK" },
		{ label: "Burundian Franc", value: "BIF" },
		{ label: "CFP Franc", value: "XPF" },
		{ label: "Cambodian Riel", value: "KHR" },
		{ label: "Canadian Dollar", value: "CAD" },
		{ label: "Cape Verdean Escudo", value: "CVE" },
		{ label: "Cayman Islands Dollar", value: "KYD" },
		{ label: "Central African CFA Franc", value: "XAF" },
		{ label: "Chilean Escudo", value: "CLE" },
		{ label: "Chilean Peso", value: "CLP" },
		{ label: "Chilean Unit of Account (UF)", value: "CLF" },
		{ label: "Chinese People\u2019s Bank Dollar", value: "CNX" },
		{ label: "Chinese Yuan", value: "CNY" },
		{ label: "Chinese Yuan (offshore)", value: "CNH" },
		{ label: "Colombian Peso", value: "COP" },
		{ label: "Colombian Real Value Unit", value: "COU" },
		{ label: "Comorian Franc", value: "KMF" },
		{ label: "Congolese Franc", value: "CDF" },
		{ label: "Costa Rican Col\u00f3n", value: "CRC" },
		{ label: "Croatian Dinar", value: "HRD" },
		{ label: "Croatian Kuna", value: "HRK" },
		{ label: "Cuban Convertible Peso", value: "CUC" },
		{ label: "Cuban Peso", value: "CUP" },
		{ label: "Cypriot Pound", value: "CYP" },
		{ label: "Czech Koruna", value: "CZK" },
		{ label: "Czechoslovak Hard Koruna", value: "CSK" },
		{ label: "Danish Krone", value: "DKK" },
		{ label: "Djiboutian Franc", value: "DJF" },
		{ label: "Dominican Peso", value: "DOP" },
		{ label: "Dutch Guilder", value: "NLG" },
		{ label: "East Caribbean Dollar", value: "XCD" },
		{ label: "East German Mark", value: "DDM" },
		{ label: "Ecuadorian Sucre", value: "ECS" },
		{ label: "Ecuadorian Unit of Constant Value", value: "ECV" },
		{ label: "Egyptian Pound", value: "EGP" },
		{ label: "Equatorial Guinean Ekwele", value: "GQE" },
		{ label: "Eritrean Nakfa", value: "ERN" },
		{ label: "Estonian Kroon", value: "EEK" },
		{ label: "Ethiopian Birr", value: "ETB" },
		{ label: "Euro", value: "EUR" },
		{ label: "European Currency Unit", value: "XEU" },
		{ label: "Falkland Islands Pound", value: "FKP" },
		{ label: "Fijian Dollar", value: "FJD" },
		{ label: "Finnish Markka", value: "FIM" },
		{ label: "French Franc", value: "FRF" },
		{ label: "French Gold Franc", value: "XFO" },
		{ label: "French UIC-Franc", value: "XFU" },
		{ label: "Gambian Dalasi", value: "GMD" },
		{ label: "Georgian Kupon Larit", value: "GEK" },
		{ label: "Georgian Lari", value: "GEL" },
		{ label: "German Mark", value: "DEM" },
		{ label: "Ghanaian Cedi", value: "GHS" },
		{ label: "Ghanaian Cedi (1979\u20132007)", value: "GHC" },
		{ label: "Gibraltar Pound", value: "GIP" },
		{ label: "Greek Drachma", value: "GRD" },
		{ label: "Guatemalan Quetzal", value: "GTQ" },
		{ label: "Guinea-Bissau Peso", value: "GWP" },
		{ label: "Guinean Franc", value: "GNF" },
		{ label: "Guinean Syli", value: "GNS" },
		{ label: "Guyanaese Dollar", value: "GYD" },
		{ label: "Haitian Gourde", value: "HTG" },
		{ label: "Honduran Lempira", value: "HNL" },
		{ label: "Hong Kong Dollar", value: "HKD" },
		{ label: "Hungarian Forint", value: "HUF" },
		{ label: "Icelandic Kr\u00f3na", value: "ISK" },
		{ label: "Icelandic Kr\u00f3na (1918\u20131981)", value: "ISJ" },
		{ label: "Indian Rupee", value: "INR" },
		{ label: "Indonesian Rupiah", value: "IDR" },
		{ label: "Iranian Rial", value: "IRR" },
		{ label: "Iraqi Dinar", value: "IQD" },
		{ label: "Irish Pound", value: "IEP" },
		{ label: "Israeli New Shekel", value: "ILS" },
		{ label: "Israeli Pound", value: "ILP" },
		{ label: "Israeli Shekel (1980\u20131985)", value: "ILR" },
		{ label: "Italian Lira", value: "ITL" },
		{ label: "Jamaican Dollar", value: "JMD" },
		{ label: "Japanese Yen", value: "JPY" },
		{ label: "Jordanian Dinar", value: "JOD" },
		{ label: "Kazakhstani Tenge", value: "KZT" },
		{ label: "Kenyan Shilling", value: "KES" },
		{ label: "Kuwaiti Dinar", value: "KWD" },
		{ label: "Kyrgystani Som", value: "KGS" },
		{ label: "Laotian Kip", value: "LAK" },
		{ label: "Latvian Lats", value: "LVL" },
		{ label: "Latvian Ruble", value: "LVR" },
		{ label: "Lebanese Pound", value: "LBP" },
		{ label: "Lesotho Loti", value: "LSL" },
		{ label: "Liberian Dollar", value: "LRD" },
		{ label: "Libyan Dinar", value: "LYD" },
		{ label: "Lithuanian Litas", value: "LTL" },
		{ label: "Lithuanian Talonas", value: "LTT" },
		{ label: "Luxembourg Financial Franc", value: "LUL" },
		{ label: "Luxembourgian Convertible Franc", value: "LUC" },
		{ label: "Luxembourgian Franc", value: "LUF" },
		{ label: "Macanese Pataca", value: "MOP" },
		{ label: "Macedonian Denar", value: "MKD" },
		{ label: "Macedonian Denar (1992\u20131993)", value: "MKN" },
		{ label: "Malagasy Ariary", value: "MGA" },
		{ label: "Malagasy Franc", value: "MGF" },
		{ label: "Malawian Kwacha", value: "MWK" },
		{ label: "Malaysian Ringgit", value: "MYR" },
		{ label: "Maldivian Rufiyaa", value: "MVR" },
		{ label: "Maldivian Rupee (1947\u20131981)", value: "MVP" },
		{ label: "Malian Franc", value: "MLF" },
		{ label: "Maltese Lira", value: "MTL" },
		{ label: "Maltese Pound", value: "MTP" },
		{ label: "Mauritanian Ouguiya", value: "MRU" },
		{ label: "Mauritanian Ouguiya (1973\u20132017)", value: "MRO" },
		{ label: "Mauritian Rupee", value: "MUR" },
		{ label: "Mexican Investment Unit", value: "MXV" },
		{ label: "Mexican Peso", value: "MXN" },
		{ label: "Mexican Silver Peso (1861\u20131992)", value: "MXP" },
		{ label: "Moldovan Cupon", value: "MDC" },
		{ label: "Moldovan Leu", value: "MDL" },
		{ label: "Monegasque Franc", value: "MCF" },
		{ label: "Mongolian Tugrik", value: "MNT" },
		{ label: "Moroccan Dirham", value: "MAD" },
		{ label: "Moroccan Franc", value: "MAF" },
		{ label: "Mozambican Escudo", value: "MZE" },
		{ label: "Mozambican Metical", value: "MZN" },
		{ label: "Mozambican Metical (1980\u20132006)", value: "MZM" },
		{ label: "Myanmar Kyat", value: "MMK" },
		{ label: "Namibian Dollar", value: "NAD" },
		{ label: "Nepalese Rupee", value: "NPR" },
		{ label: "Netherlands Antillean Guilder", value: "ANG" },
		{ label: "New Taiwan Dollar", value: "TWD" },
		{ label: "New Zealand Dollar", value: "NZD" },
		{ label: "Nicaraguan C\u00f3rdoba", value: "NIO" },
		{ label: "Nicaraguan C\u00f3rdoba (1988\u20131991)", value: "NIC" },
		{ label: "Nigerian Naira", value: "NGN" },
		{ label: "North Korean Won", value: "KPW" },
		{ label: "Norwegian Krone", value: "NOK" },
		{ label: "Omani Rial", value: "OMR" },
		{ label: "Pakistani Rupee", value: "PKR" },
		{ label: "Panamanian Balboa", value: "PAB" },
		{ label: "Papua New Guinean Kina", value: "PGK" },
		{ label: "Paraguayan Guarani", value: "PYG" },
		{ label: "Peruvian Inti", value: "PEI" },
		{ label: "Peruvian Sol", value: "PEN" },
		{ label: "Peruvian Sol (1863\u20131965)", value: "PES" },
		{ label: "Philippine Piso", value: "PHP" },
		{ label: "Polish Zloty", value: "PLN" },
		{ label: "Polish Zloty (1950\u20131995)", value: "PLZ" },
		{ label: "Portuguese Escudo", value: "PTE" },
		{ label: "Portuguese Guinea Escudo", value: "GWE" },
		{ label: "Qatari Rial", value: "QAR" },
		{ label: "RINET Funds", value: "XRE" },
		{ label: "Rhodesian Dollar", value: "RHD" },
		{ label: "Romanian Leu", value: "RON" },
		{ label: "Romanian Leu (1952\u20132006)", value: "ROL" },
		{ label: "Russian Ruble", value: "RUB" },
		{ label: "Russian Ruble (1991\u20131998)", value: "RUR" },
		{ label: "Rwandan Franc", value: "RWF" },
		{ label: "Salvadoran Col\u00f3n", value: "SVC" },
		{ label: "Samoan Tala", value: "WST" },
		{ label: "Saudi Riyal", value: "SAR" },
		{ label: "Serbian Dinar", value: "RSD" },
		{ label: "Serbian Dinar (2002\u20132006)", value: "CSD" },
		{ label: "Seychellois Rupee", value: "SCR" },
		{ label: "Sierra Leonean Leone", value: "SLL" },
		{ label: "Singapore Dollar", value: "SGD" },
		{ label: "Slovak Koruna", value: "SKK" },
		{ label: "Slovenian Tolar", value: "SIT" },
		{ label: "Solomon Islands Dollar", value: "SBD" },
		{ label: "Somali Shilling", value: "SOS" },
		{ label: "South African Rand", value: "ZAR" },
		{ label: "South African Rand (financial)", value: "ZAL" },
		{ label: "South Korean Hwan (1953\u20131962)", value: "KRH" },
		{ label: "South Korean Won", value: "KRW" },
		{ label: "South Korean Won (1945\u20131953)", value: "KRO" },
		{ label: "South Sudanese Pound", value: "SSP" },
		{ label: "Soviet Rouble", value: "SUR" },
		{ label: "Spanish Peseta", value: "ESP" },
		{ label: "Spanish Peseta (A account)", value: "ESA" },
		{ label: "Spanish Peseta (convertible account)", value: "ESB" },
		{ label: "Sri Lankan Rupee", value: "LKR" },
		{ label: "St. Helena Pound", value: "SHP" },
		{ label: "Sudanese Dinar (1992\u20132007)", value: "SDD" },
		{ label: "Sudanese Pound", value: "SDG" },
		{ label: "Sudanese Pound (1957\u20131998)", value: "SDP" },
		{ label: "Surinamese Dollar", value: "SRD" },
		{ label: "Surinamese Guilder", value: "SRG" },
		{ label: "Swazi Lilangeni", value: "SZL" },
		{ label: "Swedish Krona", value: "SEK" },
		{ label: "Swiss Franc", value: "CHF" },
		{ label: "Syrian Pound", value: "SYP" },
		{ label: "S\u00e3o Tom\u00e9 & Pr\u00edncipe Dobra", value: "STN" },
		{ label: "S\u00e3o Tom\u00e9 & Pr\u00edncipe Dobra (1977\u20132017)", value: "STD" },
		{ label: "Tajikistani Ruble", value: "TJR" },
		{ label: "Tajikistani Somoni", value: "TJS" },
		{ label: "Tanzanian Shilling", value: "TZS" },
		{ label: "Thai Baht", value: "THB" },
		{ label: "Timorese Escudo", value: "TPE" },
		{ label: "Tongan Pa\u02bbanga", value: "TOP" },
		{ label: "Trinidad & Tobago Dollar", value: "TTD" },
		{ label: "Tunisian Dinar", value: "TND" },
		{ label: "Turkish Lira", value: "TRY" },
		{ label: "Turkish Lira (1922\u20132005)", value: "TRL" },
		{ label: "Turkmenistani Manat", value: "TMT" },
		{ label: "Turkmenistani Manat (1993\u20132009)", value: "TMM" },
		{ label: "US Dollar", value: "USD" },
		{ label: "US Dollar (Next day)", value: "USN" },
		{ label: "US Dollar (Same day)", value: "USS" },
		{ label: "Ugandan Shilling", value: "UGX" },
		{ label: "Ugandan Shilling (1966\u20131987)", value: "UGS" },
		{ label: "Ukrainian Hryvnia", value: "UAH" },
		{ label: "Ukrainian Karbovanets", value: "UAK" },
		{ label: "United Arab Emirates Dirham", value: "AED" },
		{ label: "Uruguayan Nominal Wage Index Unit", value: "UYW" },
		{ label: "Uruguayan Peso", value: "UYU" },
		{ label: "Uruguayan Peso (1975\u20131993)", value: "UYP" },
		{ label: "Uruguayan Peso (Indexed Units)", value: "UYI" },
		{ label: "Uzbekistani Som", value: "UZS" },
		{ label: "Vanuatu Vatu", value: "VUV" },
		{ label: "Venezuelan Bol\u00edvar", value: "VES" },
		{ label: "Venezuelan Bol\u00edlet (1871\u20132008)", value: "VEB" },
		{ label: "Venezuelan Bol\u00edlet (2008\u20132018)", value: "VEF" },
		{ label: "Vietnamese Dong", value: "VND" },
		{ label: "Vietnamese Dong (1978\u20131985)", value: "VNN" },
		{ label: "WIR Euro", value: "CHE" },
		{ label: "WIR Franc", value: "CHW" },
		{ label: "West African CFA Franc", value: "XOF" },
		{ label: "Yemeni Dinar", value: "YDD" },
		{ label: "Yemeni Rial", value: "YER" },
		{ label: "Yugoslavian Convertible Dinar (1990\u20131992)", value: "YUN" },
		{ label: "Yugoslavian Hard Dinar (1966\u20131990)", value: "YUD" },
		{ label: "Yugoslavian New Dinar (1994\u20132002)", value: "YUM" },
		{ label: "Yugoslavian Reformed Dinar (1992\u20131993)", value: "YUR" },
		{ label: "Zairean New Zaire (1993\u20131998)", value: "ZRN" },
		{ label: "Zairean Zaire (1971\u20131993)", value: "ZRZ" },
		{ label: "Zambian Kwacha", value: "ZMW" },
		{ label: "Zambian Kwacha (1968\u20132012)", value: "ZMK" },
		{ label: "Zimbabwean Dollar (1980\u20132008)", value: "ZWD" },
		{ label: "Zimbabwean Dollar (2008)", value: "ZWR" },
		{ label: "Zimbabwean Dollar (2009)", value: "ZWL" },
	];
	initialState.locales = [
		{ label: "Afrikaans", value: "af" },
		{ label: "Afrikaans (Namibia)", value: "af_NA" },
		{ label: "Afrikaans (South Africa)", value: "af_ZA" },
		{ label: "Akan", value: "ak" },
		{ label: "Akan (Ghana)", value: "ak_GH" },
		{ label: "Albanian", value: "sq" },
		{ label: "Albanian (Albania)", value: "sq_AL" },
		{ label: "Albanian (Kosovo)", value: "sq_XK" },
		{ label: "Albanian (Macedonia)", value: "sq_MK" },
		{ label: "Amharic", value: "am" },
		{ label: "Amharic (Ethiopia)", value: "am_ET" },
		{ label: "Arabic", value: "ar" },
		{ label: "Arabic (Algeria)", value: "ar_DZ" },
		{ label: "Arabic (Bahrain)", value: "ar_BH" },
		{ label: "Arabic (Chad)", value: "ar_TD" },
		{ label: "Arabic (Comoros)", value: "ar_KM" },
		{ label: "Arabic (Djibouti)", value: "ar_DJ" },
		{ label: "Arabic (Egypt)", value: "ar_EG" },
		{ label: "Arabic (Eritrea)", value: "ar_ER" },
		{ label: "Arabic (Iraq)", value: "ar_IQ" },
		{ label: "Arabic (Israel)", value: "ar_IL" },
		{ label: "Arabic (Jordan)", value: "ar_JO" },
		{ label: "Arabic (Kuwait)", value: "ar_KW" },
		{ label: "Arabic (Lebanon)", value: "ar_LB" },
		{ label: "Arabic (Libya)", value: "ar_LY" },
		{ label: "Arabic (Mauritania)", value: "ar_MR" },
		{ label: "Arabic (Morocco)", value: "ar_MA" },
		{ label: "Arabic (Oman)", value: "ar_OM" },
		{ label: "Arabic (Palestinian Territories)", value: "ar_PS" },
		{ label: "Arabic (Qatar)", value: "ar_QA" },
		{ label: "Arabic (Saudi Arabia)", value: "ar_SA" },
		{ label: "Arabic (Somalia)", value: "ar_SO" },
		{ label: "Arabic (South Sudan)", value: "ar_SS" },
		{ label: "Arabic (Sudan)", value: "ar_SD" },
		{ label: "Arabic (Syria)", value: "ar_SY" },
		{ label: "Arabic (Tunisia)", value: "ar_TN" },
		{ label: "Arabic (United Arab Emirates)", value: "ar_AE" },
		{ label: "Arabic (Western Sahara)", value: "ar_EH" },
		{ label: "Arabic (Yemen)", value: "ar_YE" },
		{ label: "Armenian", value: "hy" },
		{ label: "Armenian (Armenia)", value: "hy_AM" },
		{ label: "Assamese", value: "as" },
		{ label: "Assamese (India)", value: "as_IN" },
		{ label: "Azerbaijani", value: "az" },
		{ label: "Azerbaijani (Azerbaijan)", value: "az_AZ" },
		{ label: "Azerbaijani (Cyrillic)", value: "az_Cyrl" },
		{ label: "Azerbaijani (Cyrillic, Azerbaijan)", value: "az_Cyrl_AZ" },
		{ label: "Azerbaijani (Latin)", value: "az_Latn" },
		{ label: "Azerbaijani (Latin, Azerbaijan)", value: "az_Latn_AZ" },
		{ label: "Bambara", value: "bm" },
		{ label: "Bambara (Mali)", value: "bm_ML" },
		{ label: "Bangla", value: "bn" },
		{ label: "Bangla (Bangladesh)", value: "bn_BD" },
		{ label: "Bangla (India)", value: "bn_IN" },
		{ label: "Basque", value: "eu" },
		{ label: "Basque (Spain)", value: "eu_ES" },
		{ label: "Belarusian", value: "be" },
		{ label: "Belarusian (Belarus)", value: "be_BY" },
		{ label: "Bosnian", value: "bs" },
		{ label: "Bosnian (Bosnia & Herzegovina)", value: "bs_BA" },
		{ label: "Bosnian (Cyrillic)", value: "bs_Cyrl" },
		{ label: "Bosnian (Cyrillic, Bosnia & Herzegovina)", value: "bs_Cyrl_BA" },
		{ label: "Bosnian (Latin)", value: "bs_Latn" },
		{ label: "Bosnian (Latin, Bosnia & Herzegovina)", value: "bs_Latn_BA" },
		{ label: "Breton", value: "br" },
		{ label: "Breton (France)", value: "br_FR" },
		{ label: "Bulgarian", value: "bg" },
		{ label: "Bulgarian (Bulgaria)", value: "bg_BG" },
		{ label: "Burmese", value: "my" },
		{ label: "Burmese (Myanmar (Burma))", value: "my_MM" },
		{ label: "Catalan", value: "ca" },
		{ label: "Catalan (Andorra)", value: "ca_AD" },
		{ label: "Catalan (France)", value: "ca_FR" },
		{ label: "Catalan (Italy)", value: "ca_IT" },
		{ label: "Catalan (Spain)", value: "ca_ES" },
		{ label: "Chechen", value: "ce" },
		{ label: "Chechen (Russia)", value: "ce_RU" },
		{ label: "Chinese", value: "zh" },
		{ label: "Chinese (China)", value: "zh_CN" },
		{ label: "Chinese (Hong Kong SAR China)", value: "zh_HK" },
		{ label: "Chinese (Macau SAR China)", value: "zh_MO" },
		{ label: "Chinese (Simplified)", value: "zh_Hans" },
		{ label: "Chinese (Simplified, China)", value: "zh_Hans_CN" },
		{ label: "Chinese (Simplified, Hong Kong SAR China)", value: "zh_Hans_HK" },
		{ label: "Chinese (Simplified, Macau SAR China)", value: "zh_Hans_MO" },
		{ label: "Chinese (Simplified, Singapore)", value: "zh_Hans_SG" },
		{ label: "Chinese (Singapore)", value: "zh_SG" },
		{ label: "Chinese (Taiwan)", value: "zh_TW" },
		{ label: "Chinese (Traditional)", value: "zh_Hant" },
		{ label: "Chinese (Traditional, Hong Kong SAR China)", value: "zh_Hant_HK" },
		{ label: "Chinese (Traditional, Macau SAR China)", value: "zh_Hant_MO" },
		{ label: "Chinese (Traditional, Taiwan)", value: "zh_Hant_TW" },
		{ label: "Cornish", value: "kw" },
		{ label: "Cornish (United Kingdom)", value: "kw_GB" },
		{ label: "Croatian", value: "hr" },
		{ label: "Croatian (Bosnia & Herzegovina)", value: "hr_BA" },
		{ label: "Croatian (Croatia)", value: "hr_HR" },
		{ label: "Czech", value: "cs" },
		{ label: "Czech (Czechia)", value: "cs_CZ" },
		{ label: "Danish", value: "da" },
		{ label: "Danish (Denmark)", value: "da_DK" },
		{ label: "Danish (Greenland)", value: "da_GL" },
		{ label: "Dutch", value: "nl" },
		{ label: "Dutch (Aruba)", value: "nl_AW" },
		{ label: "Dutch (Belgium)", value: "nl_BE" },
		{ label: "Dutch (Caribbean Netherlands)", value: "nl_BQ" },
		{ label: "Dutch (Cura\u00e7ao)", value: "nl_CW" },
		{ label: "Dutch (Netherlands)", value: "nl_NL" },
		{ label: "Dutch (Sint Maarten)", value: "nl_SX" },
		{ label: "Dutch (Suriname)", value: "nl_SR" },
		{ label: "Dzongkha", value: "dz" },
		{ label: "Dzongkha (Bhutan)", value: "dz_BT" },
		{ label: "English", value: "en" },
		{ label: "English (American Samoa)", value: "en_AS" },
		{ label: "English (Anguilla)", value: "en_AI" },
		{ label: "English (Antigua & Barbuda)", value: "en_AG" },
		{ label: "English (Australia)", value: "en_AU" },
		{ label: "English (Austria)", value: "en_AT" },
		{ label: "English (Bahamas)", value: "en_BS" },
		{ label: "English (Barbados)", value: "en_BB" },
		{ label: "English (Belgium)", value: "en_BE" },
		{ label: "English (Belize)", value: "en_BZ" },
		{ label: "English (Bermuda)", value: "en_BM" },
		{ label: "English (Botswana)", value: "en_BW" },
		{ label: "English (British Indian Ocean Territory)", value: "en_IO" },
		{ label: "English (British Virgin Islands)", value: "en_VG" },
		{ label: "English (Burundi)", value: "en_BI" },
		{ label: "English (Cameroon)", value: "en_CM" },
		{ label: "English (Canada)", value: "en_CA" },
		{ label: "English (Cayman Islands)", value: "en_KY" },
		{ label: "English (Christmas Island)", value: "en_CX" },
		{ label: "English (Cocos (Keeling) Islands)", value: "en_CC" },
		{ label: "English (Cook Islands)", value: "en_CK" },
		{ label: "English (Cyprus)", value: "en_CY" },
		{ label: "English (Denmark)", value: "en_DK" },
		{ label: "English (Diego Garcia)", value: "en_DG" },
		{ label: "English (Dominica)", value: "en_DM" },
		{ label: "English (Eritrea)", value: "en_ER" },
		{ label: "English (Falkland Islands)", value: "en_FK" },
		{ label: "English (Fiji)", value: "en_FJ" },
		{ label: "English (Finland)", value: "en_FI" },
		{ label: "English (Gambia)", value: "en_GM" },
		{ label: "English (Germany)", value: "en_DE" },
		{ label: "English (Ghana)", value: "en_GH" },
		{ label: "English (Gibraltar)", value: "en_GI" },
		{ label: "English (Grenada)", value: "en_GD" },
		{ label: "English (Guam)", value: "en_GU" },
		{ label: "English (Guernsey)", value: "en_GG" },
		{ label: "English (Guyana)", value: "en_GY" },
		{ label: "English (Hong Kong SAR China)", value: "en_HK" },
		{ label: "English (India)", value: "en_IN" },
		{ label: "English (Ireland)", value: "en_IE" },
		{ label: "English (Isle of Man)", value: "en_IM" },
		{ label: "English (Israel)", value: "en_IL" },
		{ label: "English (Jamaica)", value: "en_JM" },
		{ label: "English (Jersey)", value: "en_JE" },
		{ label: "English (Kenya)", value: "en_KE" },
		{ label: "English (Kiribati)", value: "en_KI" },
		{ label: "English (Lesotho)", value: "en_LS" },
		{ label: "English (Liberia)", value: "en_LR" },
		{ label: "English (Macau SAR China)", value: "en_MO" },
		{ label: "English (Madagascar)", value: "en_MG" },
		{ label: "English (Malawi)", value: "en_MW" },
		{ label: "English (Malaysia)", value: "en_MY" },
		{ label: "English (Malta)", value: "en_MT" },
		{ label: "English (Marshall Islands)", value: "en_MH" },
		{ label: "English (Mauritius)", value: "en_MU" },
		{ label: "English (Micronesia)", value: "en_FM" },
		{ label: "English (Montserrat)", value: "en_MS" },
		{ label: "English (Namibia)", value: "en_NA" },
		{ label: "English (Nauru)", value: "en_NR" },
		{ label: "English (Netherlands)", value: "en_NL" },
		{ label: "English (New Zealand)", value: "en_NZ" },
		{ label: "English (Nigeria)", value: "en_NG" },
		{ label: "English (Niue)", value: "en_NU" },
		{ label: "English (Norfolk Island)", value: "en_NF" },
		{ label: "English (Northern Mariana Islands)", value: "en_MP" },
		{ label: "English (Pakistan)", value: "en_PK" },
		{ label: "English (Palau)", value: "en_PW" },
		{ label: "English (Papua New Guinea)", value: "en_PG" },
		{ label: "English (Philippines)", value: "en_PH" },
		{ label: "English (Pitcairn Islands)", value: "en_PN" },
		{ label: "English (Puerto Rico)", value: "en_PR" },
		{ label: "English (Rwanda)", value: "en_RW" },
		{ label: "English (Samoa)", value: "en_WS" },
		{ label: "English (Seychelles)", value: "en_SC" },
		{ label: "English (Sierra Leone)", value: "en_SL" },
		{ label: "English (Singapore)", value: "en_SG" },
		{ label: "English (Sint Maarten)", value: "en_SX" },
		{ label: "English (Slovenia)", value: "en_SI" },
		{ label: "English (Solomon Islands)", value: "en_SB" },
		{ label: "English (South Africa)", value: "en_ZA" },
		{ label: "English (South Sudan)", value: "en_SS" },
		{ label: "English (St. Helena)", value: "en_SH" },
		{ label: "English (St. Kitts & Nevis)", value: "en_KN" },
		{ label: "English (St. Lucia)", value: "en_LC" },
		{ label: "English (St. Vincent & Grenadines)", value: "en_VC" },
		{ label: "English (Sudan)", value: "en_SD" },
		{ label: "English (Swaziland)", value: "en_SZ" },
		{ label: "English (Sweden)", value: "en_SE" },
		{ label: "English (Switzerland)", value: "en_CH" },
		{ label: "English (Tanzania)", value: "en_TZ" },
		{ label: "English (Tokelau)", value: "en_TK" },
		{ label: "English (Tonga)", value: "en_TO" },
		{ label: "English (Trinidad & Tobago)", value: "en_TT" },
		{ label: "English (Turks & Caicos Islands)", value: "en_TC" },
		{ label: "English (Tuvalu)", value: "en_TV" },
		{ label: "English (U.S. Outlying Islands)", value: "en_UM" },
		{ label: "English (U.S. Virgin Islands)", value: "en_VI" },
		{ label: "English (Uganda)", value: "en_UG" },
		{ label: "English (United Kingdom)", value: "en_GB" },
		{ label: "English (United States)", value: "en_US" },
		{ label: "English (Vanuatu)", value: "en_VU" },
		{ label: "English (Zambia)", value: "en_ZM" },
		{ label: "English (Zimbabwe)", value: "en_ZW" },
		{ label: "Esperanto", value: "eo" },
		{ label: "Estonian", value: "et" },
		{ label: "Estonian (Estonia)", value: "et_EE" },
		{ label: "Ewe", value: "ee" },
		{ label: "Ewe (Ghana)", value: "ee_GH" },
		{ label: "Ewe (Togo)", value: "ee_TG" },
		{ label: "Faroese", value: "fo" },
		{ label: "Faroese (Denmark)", value: "fo_DK" },
		{ label: "Faroese (Faroe Islands)", value: "fo_FO" },
		{ label: "Finnish", value: "fi" },
		{ label: "Finnish (Finland)", value: "fi_FI" },
		{ label: "French", value: "fr" },
		{ label: "French (Algeria)", value: "fr_DZ" },
		{ label: "French (Belgium)", value: "fr_BE" },
		{ label: "French (Benin)", value: "fr_BJ" },
		{ label: "French (Burkina Faso)", value: "fr_BF" },
		{ label: "French (Burundi)", value: "fr_BI" },
		{ label: "French (Cameroon)", value: "fr_CM" },
		{ label: "French (Canada)", value: "fr_CA" },
		{ label: "French (Central African Republic)", value: "fr_CF" },
		{ label: "French (Chad)", value: "fr_TD" },
		{ label: "French (Comoros)", value: "fr_KM" },
		{ label: "French (Congo - Brazzaville)", value: "fr_CG" },
		{ label: "French (Congo - Kinshasa)", value: "fr_CD" },
		{ label: "French (C\u00f4te d\u2019Ivoire)", value: "fr_CI" },
		{ label: "French (Djibouti)", value: "fr_DJ" },
		{ label: "French (Equatorial Guinea)", value: "fr_GQ" },
		{ label: "French (France)", value: "fr_FR" },
		{ label: "French (French Guiana)", value: "fr_GF" },
		{ label: "French (French Polynesia)", value: "fr_PF" },
		{ label: "French (Gabon)", value: "fr_GA" },
		{ label: "French (Guadeloupe)", value: "fr_GP" },
		{ label: "French (Guinea)", value: "fr_GN" },
		{ label: "French (Haiti)", value: "fr_HT" },
		{ label: "French (Luxembourg)", value: "fr_LU" },
		{ label: "French (Madagascar)", value: "fr_MG" },
		{ label: "French (Mali)", value: "fr_ML" },
		{ label: "French (Martinique)", value: "fr_MQ" },
		{ label: "French (Mauritania)", value: "fr_MR" },
		{ label: "French (Mauritius)", value: "fr_MU" },
		{ label: "French (Mayotte)", value: "fr_YT" },
		{ label: "French (Monaco)", value: "fr_MC" },
		{ label: "French (Morocco)", value: "fr_MA" },
		{ label: "French (New Caledonia)", value: "fr_NC" },
		{ label: "French (Niger)", value: "fr_NE" },
		{ label: "French (Rwanda)", value: "fr_RW" },
		{ label: "French (R\u00e9union)", value: "fr_RE" },
		{ label: "French (Senegal)", value: "fr_SN" },
		{ label: "French (Seychelles)", value: "fr_SC" },
		{ label: "French (St. Barth\u00e9lemy)", value: "fr_BL" },
		{ label: "French (St. Martin)", value: "fr_MF" },
		{ label: "French (St. Pierre & Miquelon)", value: "fr_PM" },
		{ label: "French (Switzerland)", value: "fr_CH" },
		{ label: "French (Syria)", value: "fr_SY" },
		{ label: "French (Togo)", value: "fr_TG" },
		{ label: "French (Tunisia)", value: "fr_TN" },
		{ label: "French (Vanuatu)", value: "fr_VU" },
		{ label: "French (Wallis & Futuna)", value: "fr_WF" },
		{ label: "Fulah", value: "ff" },
		{ label: "Galician", value: "gl" },
		{ label: "Galician (Spain)", value: "gl_ES" },
		{ label: "Ganda", value: "lg" },
		{ label: "Ganda (Uganda)", value: "lg_UG" },
		{ label: "Georgian", value: "ka" },
		{ label: "Georgian (Georgia)", value: "ka_GE" },
		{ label: "German", value: "de" },
		{ label: "German (Austria)", value: "de_AT" },
		{ label: "German (Belgium)", value: "de_BE" },
		{ label: "German (Germany)", value: "de_DE" },
		{ label: "German (Italy)", value: "de_IT" },
		{ label: "German (Liechtenstein)", value: "de_LI" },
		{ label: "German (Luxembourg)", value: "de_LU" },
		{ label: "German (Switzerland)", value: "de_CH" },
		{ label: "Greek", value: "el" },
		{ label: "Greek (Cyprus)", value: "el_CY" },
		{ label: "Greek (Greece)", value: "el_GR" },
		{ label: "Gujarati", value: "gu" },
		{ label: "Gujarati (India)", value: "gu_IN" },
		{ label: "Hausa", value: "ha" },
		{ label: "Hausa (Ghana)", value: "ha_GH" },
		{ label: "Hausa (Niger)", value: "ha_NE" },
		{ label: "Hausa (Nigeria)", value: "ha_NG" },
		{ label: "Hebrew", value: "he" },
		{ label: "Hebrew (Israel)", value: "he_IL" },
		{ label: "Hindi", value: "hi" },
		{ label: "Hindi (India)", value: "hi_IN" },
		{ label: "Hungarian", value: "hu" },
		{ label: "Hungarian (Hungary)", value: "hu_HU" },
		{ label: "Icelandic", value: "is" },
		{ label: "Icelandic (Iceland)", value: "is_IS" },
		{ label: "Igbo", value: "ig" },
		{ label: "Igbo (Nigeria)", value: "ig_NG" },
		{ label: "Indonesian", value: "id" },
		{ label: "Indonesian (Indonesia)", value: "id_ID" },
		{ label: "Interlingua", value: "ia" },
		{ label: "Irish", value: "ga" },
		{ label: "Irish (Ireland)", value: "ga_IE" },
		{ label: "Italian", value: "it" },
		{ label: "Italian (Italy)", value: "it_IT" },
		{ label: "Italian (San Marino)", value: "it_SM" },
		{ label: "Italian (Switzerland)", value: "it_CH" },
		{ label: "Italian (Vatican City)", value: "it_VA" },
		{ label: "Japanese", value: "ja" },
		{ label: "Japanese (Japan)", value: "ja_JP" },
		{ label: "Javanese", value: "jv" },
		{ label: "Javanese (Indonesia)", value: "jv_ID" },
		{ label: "Kalaallisut", value: "kl" },
		{ label: "Kalaallisut (Greenland)", value: "kl_GL" },
		{ label: "Kannada", value: "kn" },
		{ label: "Kannada (India)", value: "kn_IN" },
		{ label: "Kashmiri", value: "ks" },
		{ label: "Kashmiri (India)", value: "ks_IN" },
		{ label: "Kazakh", value: "kk" },
		{ label: "Kazakh (Kazakhstan)", value: "kk_KZ" },
		{ label: "Khmer", value: "km" },
		{ label: "Khmer (Cambodia)", value: "km_KH" },
		{ label: "Kikuyu", value: "ki" },
		{ label: "Kikuyu (Kenya)", value: "ki_KE" },
		{ label: "Kinyarwanda", value: "rw" },
		{ label: "Kinyarwanda (Rwanda)", value: "rw_RW" },
		{ label: "Korean", value: "ko" },
		{ label: "Korean (North Korea)", value: "ko_KP" },
		{ label: "Korean (South Korea)", value: "ko_KR" },
		{ label: "Kurdish", value: "ku" },
		{ label: "Kurdish (Turkey)", value: "ku_TR" },
		{ label: "Kyrgyz", value: "ky" },
		{ label: "Kyrgyz (Kyrgyzstan)", value: "ky_KG" },
		{ label: "Lao", value: "lo" },
		{ label: "Lao (Laos)", value: "lo_LA" },
		{ label: "Latvian", value: "lv" },
		{ label: "Latvian (Latvia)", value: "lv_LV" },
		{ label: "Lingala", value: "ln" },
		{ label: "Lingala (Angola)", value: "ln_AO" },
		{ label: "Lingala (Central African Republic)", value: "ln_CF" },
		{ label: "Lingala (Congo - Brazzaville)", value: "ln_CG" },
		{ label: "Lingala (Congo - Kinshasa)", value: "ln_CD" },
		{ label: "Lithuanian", value: "lt" },
		{ label: "Lithuanian (Lithuania)", value: "lt_LT" },
		{ label: "Luba-Katanga", value: "lu" },
		{ label: "Luba-Katanga (Congo - Kinshasa)", value: "lu_CD" },
		{ label: "Luxembourgish", value: "lb" },
		{ label: "Luxembourgish (Luxembourg)", value: "lb_LU" },
		{ label: "Macedonian", value: "mk" },
		{ label: "Macedonian (Macedonia)", value: "mk_MK" },
		{ label: "Malagasy", value: "mg" },
		{ label: "Malagasy (Madagascar)", value: "mg_MG" },
		{ label: "Malay", value: "ms" },
		{ label: "Malay (Brunei)", value: "ms_BN" },
		{ label: "Malay (Malaysia)", value: "ms_MY" },
		{ label: "Malay (Singapore)", value: "ms_SG" },
		{ label: "Malayalam", value: "ml" },
		{ label: "Malayalam (India)", value: "ml_IN" },
		{ label: "Maltese", value: "mt" },
		{ label: "Maltese (Malta)", value: "mt_MT" },
		{ label: "Manx", value: "gv" },
		{ label: "Manx (Isle of Man)", value: "gv_IM" },
		{ label: "Maori", value: "mi" },
		{ label: "Maori (New Zealand)", value: "mi_NZ" },
		{ label: "Marathi", value: "mr" },
		{ label: "Marathi (India)", value: "mr_IN" },
		{ label: "Mongolian", value: "mn" },
		{ label: "Mongolian (Mongolia)", value: "mn_MN" },
		{ label: "Nepali", value: "ne" },
		{ label: "Nepali (India)", value: "ne_IN" },
		{ label: "Nepali (Nepal)", value: "ne_NP" },
		{ label: "North Ndebele", value: "nd" },
		{ label: "North Ndebele (Zimbabwe)", value: "nd_ZW" },
		{ label: "Northern Sami", value: "se" },
		{ label: "Northern Sami (Finland)", value: "se_FI" },
		{ label: "Northern Sami (Norway)", value: "se_NO" },
		{ label: "Northern Sami (Sweden)", value: "se_SE" },
		{ label: "Norwegian", value: "no" },
		{ label: "Norwegian (Norway)", value: "no_NO" },
		{ label: "Norwegian Bokm\u00e5l", value: "nb" },
		{ label: "Norwegian Bokm\u00e5l (Norway)", value: "nb_NO" },
		{ label: "Norwegian Bokm\u00e5l (Svalbard & Jan Mayen)", value: "nb_SJ" },
		{ label: "Norwegian Nynorsk", value: "nn" },
		{ label: "Norwegian Nynorsk (Norway)", value: "nn_NO" },
		{ label: "Odia", value: "or" },
		{ label: "Odia (India)", value: "or_IN" },
		{ label: "Oromo", value: "om" },
		{ label: "Oromo (Ethiopia)", value: "om_ET" },
		{ label: "Oromo (Kenya)", value: "om_KE" },
		{ label: "Ossetic", value: "os" },
		{ label: "Ossetic (Georgia)", value: "os_GE" },
		{ label: "Ossetic (Russia)", value: "os_RU" },
		{ label: "Pashto", value: "ps" },
		{ label: "Pashto (Afghanistan)", value: "ps_AF" },
		{ label: "Persian", value: "fa" },
		{ label: "Persian (Afghanistan)", value: "fa_AF" },
		{ label: "Persian (Iran)", value: "fa_IR" },
		{ label: "Polish", value: "pl" },
		{ label: "Polish (Poland)", value: "pl_PL" },
		{ label: "Portuguese", value: "pt" },
		{ label: "Portuguese (Angola)", value: "pt_AO" },
		{ label: "Portuguese (Brazil)", value: "pt_BR" },
		{ label: "Portuguese (Cape Verde)", value: "pt_CV" },
		{ label: "Portuguese (Equatorial Guinea)", value: "pt_GQ" },
		{ label: "Portuguese (Guinea-Bissau)", value: "pt_GW" },
		{ label: "Portuguese (Luxembourg)", value: "pt_LU" },
		{ label: "Portuguese (Macau SAR China)", value: "pt_MO" },
		{ label: "Portuguese (Mozambique)", value: "pt_MZ" },
		{ label: "Portuguese (Portugal)", value: "pt_PT" },
		{ label: "Portuguese (Switzerland)", value: "pt_CH" },
		{ label: "Portuguese (S\u00e3o Tom\u00e9 & Pr\u00edncipe)", value: "pt_ST" },
		{ label: "Portuguese (Timor-Leste)", value: "pt_TL" },
		{ label: "Punjabi", value: "pa" },
		{ label: "Punjabi (Arabic)", value: "pa_Arab" },
		{ label: "Punjabi (Arabic, Pakistan)", value: "pa_Arab_PK" },
		{ label: "Punjabi (Gurmukhi)", value: "pa_Guru" },
		{ label: "Punjabi (Gurmukhi, India)", value: "pa_Guru_IN" },
		{ label: "Punjabi (India)", value: "pa_IN" },
		{ label: "Punjabi (Pakistan)", value: "pa_PK" },
		{ label: "Quechua", value: "qu" },
		{ label: "Quechua (Bolivia)", value: "qu_BO" },
		{ label: "Quechua (Ecuador)", value: "qu_EC" },
		{ label: "Quechua (Peru)", value: "qu_PE" },
		{ label: "Romanian", value: "ro" },
		{ label: "Romanian (Moldova)", value: "ro_MD" },
		{ label: "Romanian (Romania)", value: "ro_RO" },
		{ label: "Romansh", value: "rm" },
		{ label: "Romansh (Switzerland)", value: "rm_CH" },
		{ label: "Rundi", value: "rn" },
		{ label: "Rundi (Burundi)", value: "rn_BI" },
		{ label: "Russian", value: "ru" },
		{ label: "Russian (Belarus)", value: "ru_BY" },
		{ label: "Russian (Kazakhstan)", value: "ru_KZ" },
		{ label: "Russian (Kyrgyzstan)", value: "ru_KG" },
		{ label: "Russian (Moldova)", value: "ru_MD" },
		{ label: "Russian (Russia)", value: "ru_RU" },
		{ label: "Russian (Ukraine)", value: "ru_UA" },
		{ label: "Sango", value: "sg" },
		{ label: "Sango (Central African Republic)", value: "sg_CF" },
		{ label: "Scottish Gaelic", value: "gd" },
		{ label: "Scottish Gaelic (United Kingdom)", value: "gd_GB" },
		{ label: "Serbian", value: "sr" },
		{ label: "Serbian (Bosnia & Herzegovina)", value: "sr_BA" },
		{ label: "Serbian (Cyrillic)", value: "sr_Cyrl" },
		{ label: "Serbian (Cyrillic, Bosnia & Herzegovina)", value: "sr_Cyrl_BA" },
		{ label: "Serbian (Cyrillic, Kosovo)", value: "sr_Cyrl_XK" },
		{ label: "Serbian (Cyrillic, Montenegro)", value: "sr_Cyrl_ME" },
		{ label: "Serbian (Cyrillic, Serbia)", value: "sr_Cyrl_RS" },
		{ label: "Serbian (Kosovo)", value: "sr_XK" },
		{ label: "Serbian (Latin)", value: "sr_Latn" },
		{ label: "Serbian (Latin, Bosnia & Herzegovina)", value: "sr_Latn_BA" },
		{ label: "Serbian (Latin, Kosovo)", value: "sr_Latn_XK" },
		{ label: "Serbian (Latin, Montenegro)", value: "sr_Latn_ME" },
		{ label: "Serbian (Latin, Serbia)", value: "sr_Latn_RS" },
		{ label: "Serbian (Montenegro)", value: "sr_ME" },
		{ label: "Serbian (Serbia)", value: "sr_RS" },
		{ label: "Serbo-Croatian", value: "sh" },
		{ label: "Serbo-Croatian (Bosnia & Herzegovina)", value: "sh_BA" },
		{ label: "Shona", value: "sn" },
		{ label: "Shona (Zimbabwe)", value: "sn_ZW" },
		{ label: "Sichuan Yi", value: "ii" },
		{ label: "Sichuan Yi (China)", value: "ii_CN" },
		{ label: "Sindhi", value: "sd" },
		{ label: "Sindhi (Pakistan)", value: "sd_PK" },
		{ label: "Sinhala", value: "si" },
		{ label: "Sinhala (Sri Lanka)", value: "si_LK" },
		{ label: "Slovak", value: "sk" },
		{ label: "Slovak (Slovakia)", value: "sk_SK" },
		{ label: "Slovenian", value: "sl" },
		{ label: "Slovenian (Slovenia)", value: "sl_SI" },
		{ label: "Somali", value: "so" },
		{ label: "Somali (Djibouti)", value: "so_DJ" },
		{ label: "Somali (Ethiopia)", value: "so_ET" },
		{ label: "Somali (Kenya)", value: "so_KE" },
		{ label: "Somali (Somalia)", value: "so_SO" },
		{ label: "Spanish", value: "es" },
		{ label: "Spanish (Argentina)", value: "es_AR" },
		{ label: "Spanish (Belize)", value: "es_BZ" },
		{ label: "Spanish (Bolivia)", value: "es_BO" },
		{ label: "Spanish (Brazil)", value: "es_BR" },
		{ label: "Spanish (Canary Islands)", value: "es_IC" },
		{ label: "Spanish (Ceuta & Melilla)", value: "es_EA" },
		{ label: "Spanish (Chile)", value: "es_CL" },
		{ label: "Spanish (Colombia)", value: "es_CO" },
		{ label: "Spanish (Costa Rica)", value: "es_CR" },
		{ label: "Spanish (Cuba)", value: "es_CU" },
		{ label: "Spanish (Dominican Republic)", value: "es_DO" },
		{ label: "Spanish (Ecuador)", value: "es_EC" },
		{ label: "Spanish (El Salvador)", value: "es_SV" },
		{ label: "Spanish (Equatorial Guinea)", value: "es_GQ" },
		{ label: "Spanish (Guatemala)", value: "es_GT" },
		{ label: "Spanish (Honduras)", value: "es_HN" },
		{ label: "Spanish (Mexico)", value: "es_MX" },
		{ label: "Spanish (Nicaragua)", value: "es_NI" },
		{ label: "Spanish (Panama)", value: "es_PA" },
		{ label: "Spanish (Paraguay)", value: "es_PY" },
		{ label: "Spanish (Peru)", value: "es_PE" },
		{ label: "Spanish (Philippines)", value: "es_PH" },
		{ label: "Spanish (Puerto Rico)", value: "es_PR" },
		{ label: "Spanish (Spain)", value: "es_ES" },
		{ label: "Spanish (United States)", value: "es_US" },
		{ label: "Spanish (Uruguay)", value: "es_UY" },
		{ label: "Spanish (Venezuela)", value: "es_VE" },
		{ label: "Swahili", value: "sw" },
		{ label: "Swahili (Congo - Kinshasa)", value: "sw_CD" },
		{ label: "Swahili (Kenya)", value: "sw_KE" },
		{ label: "Swahili (Tanzania)", value: "sw_TZ" },
		{ label: "Swahili (Uganda)", value: "sw_UG" },
		{ label: "Swedish", value: "sv" },
		{ label: "Swedish (Finland)", value: "sv_FI" },
		{ label: "Swedish (Sweden)", value: "sv_SE" },
		{ label: "Swedish (\u00c5land Islands)", value: "sv_AX" },
		{ label: "Tagalog", value: "tl" },
		{ label: "Tagalog (Philippines)", value: "tl_PH" },
		{ label: "Tajik", value: "tg" },
		{ label: "Tajik (Tajikistan)", value: "tg_TJ" },
		{ label: "Tamil", value: "ta" },
		{ label: "Tamil (India)", value: "ta_IN" },
		{ label: "Tamil (Malaysia)", value: "ta_MY" },
		{ label: "Tamil (Singapore)", value: "ta_SG" },
		{ label: "Tamil (Sri Lanka)", value: "ta_LK" },
		{ label: "Tatar", value: "tt" },
		{ label: "Tatar (Russia)", value: "tt_RU" },
		{ label: "Telugu", value: "te" },
		{ label: "Telugu (India)", value: "te_IN" },
		{ label: "Thai", value: "th" },
		{ label: "Thai (Thailand)", value: "th_TH" },
		{ label: "Tibetan", value: "bo" },
		{ label: "Tibetan (China)", value: "bo_CN" },
		{ label: "Tibetan (India)", value: "bo_IN" },
		{ label: "Tigrinya", value: "ti" },
		{ label: "Tigrinya (Eritrea)", value: "ti_ER" },
		{ label: "Tigrinya (Ethiopia)", value: "ti_ET" },
		{ label: "Tongan", value: "to" },
		{ label: "Tongan (Tonga)", value: "to_TO" },
		{ label: "Turkish", value: "tr" },
		{ label: "Turkish (Cyprus)", value: "tr_CY" },
		{ label: "Turkish (Turkey)", value: "tr_TR" },
		{ label: "Turkmen", value: "tk" },
		{ label: "Turkmen (Turkmenistan)", value: "tk_TM" },
		{ label: "Ukrainian", value: "uk" },
		{ label: "Ukrainian (Ukraine)", value: "uk_UA" },
		{ label: "Urdu", value: "ur" },
		{ label: "Urdu (India)", value: "ur_IN" },
		{ label: "Urdu (Pakistan)", value: "ur_PK" },
		{ label: "Uyghur", value: "ug" },
		{ label: "Uyghur (China)", value: "ug_CN" },
		{ label: "Uzbek", value: "uz" },
		{ label: "Uzbek (Afghanistan)", value: "uz_AF" },
		{ label: "Uzbek (Arabic)", value: "uz_Arab" },
		{ label: "Uzbek (Arabic, Afghanistan)", value: "uz_Arab_AF" },
		{ label: "Uzbek (Cyrillic)", value: "uz_Cyrl" },
		{ label: "Uzbek (Cyrillic, Uzbekistan)", value: "uz_Cyrl_UZ" },
		{ label: "Uzbek (Latin)", value: "uz_Latn" },
		{ label: "Uzbek (Latin, Uzbekistan)", value: "uz_Latn_UZ" },
		{ label: "Uzbek (Uzbekistan)", value: "uz_UZ" },
		{ label: "Vietnamese", value: "vi" },
		{ label: "Vietnamese (Vietnam)", value: "vi_VN" },
		{ label: "Welsh", value: "cy" },
		{ label: "Welsh (United Kingdom)", value: "cy_GB" },
		{ label: "Western Frisian", value: "fy" },
		{ label: "Western Frisian (Netherlands)", value: "fy_NL" },
		{ label: "Wolof", value: "wo" },
		{ label: "Wolof (Senegal)", value: "wo_SN" },
		{ label: "Xhosa", value: "xh" },
		{ label: "Xhosa (South Africa)", value: "xh_ZA" },
		{ label: "Yiddish", value: "yi" },
		{ label: "Yoruba", value: "yo" },
		{ label: "Yoruba (Benin)", value: "yo_BJ" },
		{ label: "Yoruba (Nigeria)", value: "yo_NG" },
		{ label: "Zulu", value: "zu" },
		{ label: "Zulu (South Africa)", value: "zu_ZA" },
	];

	initialState.groups = [];
	initialState.translation = {};
	initialState.calendars = [];
	initialState.dialer_session = [];
	initialState.saved_searches = [];
	initialState.shortcuts = [];
	initialState.stages = [];
	initialState.deal_types = [];
	initialState.ad_accounts = [];

	return initialState;
};
