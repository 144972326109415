import React, { Component } from "react";
import styled from "styled-components";
import API from "src/js/API";
import { store } from "src/js/store";
import { Spinner } from "@shopify/polaris";
import Colors from "../../Colors";
import DocumentListSheetContent from "../../components/DocumentListSheetContent";
import NumberBadge from "../../components/NumberBadge";
import BoardHelper from "../Workspaces/BoardHelper";
import { StyledDocumentItem } from "../Workspaces/components/BoardUploadSheet";

class ContactDocumentGroups extends Component {
	constructor(props) {
		super(props);
		this.state = { loadings: {}, board: null, documentGroup: null };
	}

	selectGroup(documentGroup) {
		this.setState({ documentGroup }, this.setBoard.bind(this));
	}

	async setBoard() {
		const { documentGroup } = this.state;
		try {
			const storeBoard = documentGroup && BoardHelper.getBoard(documentGroup.board_id);
			if (storeBoard) return storeBoard;

			this.state.loadings[documentGroup.board_id] = true;
			this.setState({ loadings: this.state.loadings });
			const result = await API.get(`/api/boards/${documentGroup.board_id}.json`);
			const board = result.data.board;

			store.dispatch({
				type: "SET_BOARDS",
				boards: [board],
			});
			this.setState({ board });
			return board;
		} catch (error) {
			console.error("error:", error);
		} finally {
			this.state.loadings[documentGroup.board_id] = false;
			this.setState({ loadings: this.state.loadings });
		}
	}

	render() {
		const { documentGroups } = this.props;
		const board =
			(this.state.documentGroup && BoardHelper.getBoard(this.state.documentGroup && this.state.documentGroup.board_id)) || this.state.board;

		if (this.state.documentGroup && board) {
			return (
				<div>
					<DocumentListSheetContent
						refresh={this.props.refresh}
						noSheet
						column={board && board.columns.find((col) => col.id == (this.state.documentGroup && this.state.documentGroup.column_id))}
						contact={this.props.contact}
						board={board}
						onGoBack={() => this.setState({ documentGroup: null })}
						hideCreate
						history={this.props.history}
					/>
				</div>
			);
		}
		return (
			<Wrapper>
				{(!documentGroups || !documentGroups.length) && this.props.loading && (
					<React.Fragment>
						<StyledDocumentItem style={{ height: "43px" }} />
						<StyledDocumentItem style={{ height: "43px" }} />
						<StyledDocumentItem style={{ height: "43px" }} />
					</React.Fragment>
				)}
				{documentGroups &&
					documentGroups.map((group, index) => {
						const { count, column_title: ColumnTitle, column_id: ColumnId, board_title: boardTitle, board_id: boardId } = group || {};

						return (
							<StyledRow inactive={!count} key={ColumnId || index} onClick={this.selectGroup.bind(this, group)}>
								<StyledTitle>{ColumnTitle}</StyledTitle>
								{" - "}
								<StyledBoardTitle>{boardTitle}</StyledBoardTitle>
								<NumberBadge color={count ? Colors.green : "rgb(150,150,150)"}>{count}</NumberBadge>
								{this.state.loadings[boardId] && <Spinner size="small" />}
							</StyledRow>
						);

						// return (
						// 	<Group inactive={!count} key={ColumnId || index} onClick={this.selectGroup.bind(this, group)}>
						// 		<Count>{count}</Count>
						// 		<ImageWrapper>
						// 			<BackgroundCirle />
						// 			<Image src={`/assets/document-icons/${index}.png`} />
						// 		</ImageWrapper>
						// 		<Title>{column_title}</Title>
						// 		<HelpText>{board_title}</HelpText>
						// 	</Group>
						// );
					})}
			</Wrapper>
		);
	}
}
export default ContactDocumentGroups;

const StyledRow = styled.div`
	padding: 0.6250rem;
	display: flex;
	gap: 0.6250rem;
	cursor: pointer;
	align-items: center;
	transition: filter 350ms;
	border-radius: 6px;

	:nth-child(even) {
		background-color: var(--main1);
	}
	:nth-child(odd) {
		background-color: var(--main2);
	}

	&:hover {
		filter: brightness(1.2);
	}
`;
const StyledTitle = styled.h4`
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	color: var(--textColor);
`;

const StyledBoardTitle = styled.span`
	color: #828384;
	font-size: 13px;
	line-height: 16px;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: 3.1250rem; */
	width: 100%;
	/* flex-wrap: wrap; */
	/* padding-top: 1.0000rem; */
`;
