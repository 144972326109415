/* eslint-disable camelcase */
import { Checkbox, ChoiceList, FormLayout, Labelled } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import getContactActivityTypes from "src/js/Utilities/getContactActivityTypes";
import OptionList from "src/js/components/OptionList/OptionList";
import Select from "src/js/components/select/Select";
import { store } from "src/js/store";
import styled from "styled-components";

const ActivityFilter = ({ index, filterSource, handleChangeFilter, t }) => {
	const filter = filterSource.contact_activity;
	const storeActivities = useSelector((state: any) => state.contact_activities);
	const [type, setType] = useState<any>(
		(() => {
			if ("contact_activity" in filterSource.contact_activity) return "contact_activity";
			if ("activity_type" in filterSource.contact_activity) return "activity_type";
			return null;
		})()
	);

	useEffect(() => {
		setType(
			(() => {
				if ("contact_activity" in filterSource.contact_activity) return "contact_activity";
				if ("activity_type" in filterSource.contact_activity) return "activity_type";
				return null;
			})()
		);
	}, [filterSource]);

	const onChange = (field) => (value) => {
		if (["contact_activity"].includes(field)) {
			value =
				value === "" ? null : storeActivities.find((contact_actvity: ContactActivityType) => String(contact_actvity.id) === String(value)) || value;
		}

		const newForm = {
			...filterSource,
			contact_activity: {
				...filter,
				[field]: value,
			},
		};

		if (field === "contact_activity") delete newForm.contact_activity.activity_type;
		if (field === "activity_type") delete newForm.contact_activity.contact_activity;

		handleChangeFilter(index)(newForm);
	};

	const options = [
		{
			label: t("targets.fields.filter.source_type.contact_activity", "Kontaktakivitet"),
			value: "contact_activity",
			renderChildren: (selected) => {
				if (!selected) return null;

				const activityOptions = [
					{ value: "", label: t("goals.activities.choose.label", "Välj aktivitet...") },
					...storeActivities.map((activity) => ({ value: String(activity.id), label: activity.title })),
				];

				return (
					<Select
						label={t("targets.fields.filter.contact_activity.label", "Aktivitet")}
						options={activityOptions}
						value={String(filter.contact_activity?.id)}
						onChange={onChange("contact_activity")}
					/>
				);
			},
		},
		{
			label: t("targets.fields.filter.source_type.activity_type", "Aktivitetstyp"),
			value: "activity_type",
			renderChildren: (selected) => {
				if (!selected) return null;

				const activityTypeOptions = [{ value: "", label: t("goals.activities.choose.label", "Välj aktivitet...") }, ...getContactActivityTypes()] as {
					label: string;
					value: any;
				}[];

				return (
					<Select
						label={t("targets.fields.filter.activity_type.label", "Aktivitet typ")}
						options={activityTypeOptions}
						value={filter.activity_type}
						onChange={onChange("activity_type")}
					/>
				);
			},
		},
	];

	return (
		<Wrapper>
			<FormLayout>
				<SelectsWrapper>
					<ChoiceList
						title=""
						titleHidden
						selected={type ? [type] : []}
						choices={options}
						onChange={(value) => {
							setType(value[0]);
						}}
					/>
				</SelectsWrapper>
				<Labelled id="user" label={t("targets.fields.filter.user_ids.label1", "Användare")}>
					<OptionList
						labelPrefix={t("targets.fields.filter.user_ids.label", "Användare: ")}
						style={{ width: "max-content", background: "var(--main3)" }}
						allowMultiple
						popover
						options={store.getState().users.map((u) => ({ value: u.id, label: u.name }))}
						selected={filter?.filters?.user_ids || []}
						onChange={(user_ids) => {
							onChange("filters")({ ...(filter?.filters || {}), user_ids });
						}}
						search
					/>
				</Labelled>
				<Labelled id="groups" label={t("targets.fields.filter.user_ids.label1", "Användare")}>
					<OptionList
						labelPrefix={t("targets.fields.filter.user_group_ids.label", "Grupper: ")}
						style={{ width: "max-content", background: "var(--main3)" }}
						allowMultiple
						popover
						options={store.getState().groups.map((u) => ({ value: u.id, label: u.name }))}
						selected={filter?.filters?.user_group_ids || []}
						onChange={(user_group_ids) => {
							onChange("filters")({ ...(filter?.filters || {}), user_group_ids });
						}}
						search
					/>
				</Labelled>
				<Checkbox
					checked={filter.count_only_unique_contacts}
					label={t("targets.fields.filter.count_only_unique_contacts.label", "Gruppera på kontakt")}
					onChange={onChange("count_only_unique_contacts")}
					helpText={t("targets.fields.filter.count_only_unique_contacts.help_text", 'Gruppera på kontakt så det blir "kund unika aktiviteter".')}
				/>
			</FormLayout>
		</Wrapper>
	);
};
export default withTranslation(["targets"])(ActivityFilter);

const Wrapper = styled.div``;
const SelectsWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;
