import { Button, LegacyCard, Icon } from "@shopify/polaris";
import { CircleCancelMajor, SearchMinor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import TextField from "src/js/components/TextField";
import { store } from "src/js/store";
import styled from "styled-components";
import LicenseUserItem from "./LicenseUserItem";
import { withTranslation } from "react-i18next";
import LicenseUserModal from "./LicenseUserModal";

const ContractLicensUsers = ({ licenseProvider, onClose, t }) => {
	const [userSearch, setUserSearch] = useState("");
	const [user, setUser] = useState<UserType | null>(null);
	const [userModalIsOpen, setUserModalIsOpen] = useState(false);

	const handleOpenUserModal =
		(user = null) =>
		() => {
			setUserModalIsOpen(true);
			setUser(user);
		};

	const handleCloseUserModal = () => {
		setUser(null);
		setUserModalIsOpen(false);
	};

	const users =
		licenseProvider &&
		store.getState().users.filter((user) => {
			const filterRegex = new RegExp(userSearch && userSearch.trimStart(), "i");

			return (
				(!userSearch || filterRegex.test(user.name) || filterRegex.test(user.email) || filterRegex.test(user.id)) &&
				user.roles.includes(`ROLE_${licenseProvider.role}`)
			);
		});

	return (
		<>
			<Title>
				<h2>
					{t("licenses.users.title", "Användare för {{provider}}", {
						provider: licenseProvider?.title,
					})}
				</h2>

				{onClose && <Button plain icon={CircleCancelMajor} onClick={onClose} />}
			</Title>
			<LegacyCard sectioned>
				<HeaderWrapper>
					<TextField
						label=""
						autoComplete="on"
						prefix={<Icon source={SearchMinor} />}
						placeholder={t("common.actions.search", "Sök")}
						value={userSearch}
						onChange={setUserSearch}
					/>
					<Button onClick={handleOpenUserModal(null)} primary>
						{t("licenses.actions.add_user", "Lägg till")}
					</Button>
				</HeaderWrapper>

				<StyledUserList>
					{users?.map((user) => {
						return <LicenseUserItem key={user.id} user={user} onRemove={handleOpenUserModal(user)} />;
					})}
				</StyledUserList>

				<LicenseUserModal
					open={userModalIsOpen}
					onClose={handleCloseUserModal}
					user={user as UserType}
					licenseProvider={licenseProvider}
					onComplete={() => {
						// this.refreshUsers?.(true);
					}}
				/>
			</LegacyCard>
		</>
	);
};
export default withTranslation(["licenses", "common"], { withRef: true })(ContractLicensUsers);

const StyledUserList = styled.div`
	padding-block: 0.625rem;
	display: flex;
	flex-direction: column;
	gap: 0.625rem;

	& > * {
		justify-content: start;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	gap: 0.625rem;

	& > div[class=""] {
		flex: 1;
	}
`;

const Title = styled.div`
	display: flex;
	justify-content: space-between;

	h2 {
		padding: 0.625rem;
		line-height: 20px;
		font-weight: 700;
		font-size: 14px;
	}
`;
