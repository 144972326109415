import React, { Component } from "react";
import { Button, LegacyCard } from "@shopify/polaris";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import IntegrationSheet from "./IntegrationSheet.js";

class IntegrationButtons extends Component {
	constructor(props) {
		super(props);

		this.state = { form: props.contact || {} };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ form: props.contact || {} });
	}

	showIntegration(integration) {
		this.setState({ showSheet: true, integration });
	}

	render() {
		const integrations = [];
		let contact;
		if (store.getState().account.integrations) {
			for (let i = 0; i < store.getState().account.integrations.length; i++) {
				const integration = store.getState().account.integrations[i];
				if (integration.type != "web" && store.getState().user.roles.indexOf("ROLE_INTEGRATION_" + integration.id) >= 0) {
					for (let s = 0; s < integration.links.length; s++) {
						if (integration.links[s].placement == "contact") {
							contact = Object.assign({}, this.state.form);
							try {
								if (!integration.links[s].show_if || eval(integration.links[s].show_if)) {
									integrations.push(Object.assign({}, integration, { link: integration.links[s] }));
								}
							} catch (e) {
								console.error(e);
								integrations.push(Object.assign({}, integration, { link: integration.links[s] }));
							}
						}
					}
				}
				if (integration.iframe_url && store.getState().user.roles.indexOf("ROLE_INTEGRATION_" + integration.id) >= 0) {
					contact = Object.assign({}, this.state.form);
					const metafields = {};
					if (this.state.form && Array.isArray(this.state.form.metafields)) {
						for (let x = 0; x < this.state.form.metafields.length; x++) {
							const handle = this.state.form.metafields[x].handle;
							metafields[handle] = this.state.form.metafields[x].value;
						}
					}
					contact.metafields = metafields;
					try {
						if (!integration.show_if || eval(integration.show_if)) {
							integrations.push(integration);
						}
					} catch (e) {
						console.error(e);
						integrations.push(integration);
					}
				}
			}
		}

		if (!integrations.length) return null;

		const content = (
			<React.Fragment>
				{integrations.length > 0 ? (
					<IntegrationSheet
						open={this.state.showSheet}
						project={this.props.project}
						ticket={this.props.ticket}
						row={this.props.row}
						board={this.props.board}
						projectTask={this.props.projectTask}
						deal={this.props.deal}
						integration={this.state.integration}
						onClose={() => {
							this.setState({ showSheet: false });
						}}
						contact={contact}
					/>
				) : null}
				{integrations.length > 0 && (
					<div className="integrations-list__wrapper">
						{integrations.map((integration) => (
							<Button key={integration.id} onClick={this.showIntegration.bind(this, integration)}>
								{integration.link ? integration.link.title : integration.title}
							</Button>
						))}
					</div>
				)}
			</React.Fragment>
		);

		if (this.props.card) {
			return (
				<div className="integrations">
					<LegacyCard sectioned>
						<h2 className="small_card_title">{this.props.t("mainmenu.section.integrations.my_title", "Mina integrationer")}</h2>
						{content}
					</LegacyCard>
				</div>
			);
		}
		return content;
	}
}

const mapStateToProps = (state, ownProps) => ({
	integrations: state.integrations,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	withTranslation(["board", "common"], { withRef: true })(IntegrationButtons)
);
