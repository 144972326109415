import { Button } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class BoardEmptyScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="board-empty_screen" style={this.props.style}>
				<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
				<h2 className="board-empty_screen__title">
					{this.props.t("board.rows.empty.title", "Inga {{plural}} hittades", {
						plural: this.props.resource || this.props.board?.plural || "rader",
					})}
				</h2>
				{!this.props.noheader && (
					<React.Fragment>
						<p>{this.props.text || this.props.t("board.rows.empty.text", "Prova att ändra filter eller sökning")}</p>
						{this.props.clear && (
							<Button
								primary
								onClick={() => {
									this.props.clear?.();
								}}
							>
								{this.props.t("board.rows.empty.action", "Rensa filters")}
							</Button>
						)}
					</React.Fragment>
				)}
				{this.props.children}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardEmptyScreen);
