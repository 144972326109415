import React, { Component } from "react";

class CellNumber extends Component {
	constructor(props) {
		super(props);
		this.state = { sum: 0, editing: false, value: props.value };
		if (props.summary) {
			let sum = 0;
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value) {
					sum += props.values[i].value * 1;
				}
			}
			this.state.sum = sum;
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.summary) {
			let sum = 0;
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value) {
					sum += props.values[i].value * 1;
				}
			}
			this.setState({ sum });
		} else {
			this.setState({ value: props.value });
		}
	}

	money(amountP, moneyFormat) {
		const amount = amountP;
		let result;
		const pattern = /\{\{\s*(\w+)\s*\}\}/;

		function addSpaces(money) {
			money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			return money;
		}

		switch (moneyFormat.match(pattern)[1]) {
			case "detailed": {
				result = addSpaces(parseInt(amount).toString());
				const dec = parseFloat(amount).toString();
				if (dec.indexOf(".") >= 0) {
					const pcs = dec.split(".", 2);
					result = result + "." + pcs[1];
				}
				break;
			}
			case "amount":
				result = addSpaces(parseFloat(amount).toFixed(2).toString());
				break;
			case "fixed_amount":
			default:
				result = addSpaces(parseFloat(amount).toFixed(0).toString());
				break;
		}
		return moneyFormat.replace(pattern, result);
	}

	toggle() {
		if (this.props.readonly || this.props.disabled) return;

		if (!this.state.editing) {
			this.props.onFocus();
		} else {
			if (this.state.value != this.props.value) {
				this.props.onChange({ value: this.state.value });
			}
			this.props.onBlur();
		}
		this.setState({ editing: !this.state.editing });
	}

	render() {
		if (this.props.summary) {
			return (
				<div
					style={{
						height: "100%",
						width: "100%",
						fontWeight: 600,
						cursor: "text",
						textAlign: "center",
						lineHeight: this.props.height ? this.props.height + "px" : null,
					}}
				>
					{this.state.sum > 0 ? this.money(this.state.sum, "{{detailed}}") : ""}
				</div>
			);
		}
		return (
			<div
				className={
					"overflow-ellipsis board-tabbable board-editable" + (this.state.editing ? " focused" : "") + (this.props.disabled ? " disabled" : "")
				}
				style={{
					height: "100%",
					width: "100%",
					cursor: "text",
					textAlign: "center",
					lineHeight: this.props.height ? this.props.height + "px" : null,
					backgroundColor: this.state.editing ? "transparent" : null,
				}}
				onClick={this.toggle.bind(this)}
			>
				{this.state.editing ? (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
						onDragStart={(event) => {
							event.stopPropagation();
							event.preventDefault();
						}}
						draggable
					>
						<input
							readOnly={this.props.readonly}
							disabled={this.props.disabled}
							ref={(input) => {
								if (input) {
									input.focus();
								}
							}}
							onChange={(event) => {
								let t = 0;
								this.setState({
									value: event.target.value
										.replace(",", ".")
										.replace(/\./g, (match) => {
											t++;
											if (t > 1) {
												return "";
											} else {
												return match;
											}
										})
										.replace(/[^\d.]/, ""),
								});
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									this.toggle();
								} else if (event.key === "Escape") {
									this.setState({ value: this.props.value }, this.toggle.bind(this));
								}
							}}
							onBlur={this.toggle.bind(this)}
							type="text"
							value={this.state.value}
							style={{
								textAlign: "center",
								lineHeight: this.props.height ? this.props.height - 1 + "px" : null,
								display: "block",
								width: "100%",
								border: 0,
							}}
						/>
					</div>
				) : this.props.value ? (
					this.money(this.props.value, "{{detailed}}")
				) : (
					""
				)}
			</div>
		);
	}
}
export default CellNumber;
