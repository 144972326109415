import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SellfinityQuill from "src/js/components/Microsoft365/SellfinityQuill";
import Modal from "src/js/components/modal";

type EmailSignatureModalProps = {
	open: boolean;
	onClose: () => void;
	onComplete: (signature: string) => void;
	emailSignature: string;
} & WithTranslation;

const EmailSignatureModal = ({ open, onClose, onComplete, emailSignature, t }: EmailSignatureModalProps) => {
	const [signature, setSignature] = useState(emailSignature);

	useEffect(() => {
		setSignature(emailSignature);
	}, [emailSignature]);

	const handleclose = () => {
		onClose();
		setSignature(emailSignature);
	};

	return (
		<Modal
			title={t("user.fields.e_email_signature", "E-postsignatur")}
			onClose={handleclose}
			open={open}
			primaryAction={{
				content: t("common.actions.save", "Spara") as string,
				onAction: () => {
					onComplete(signature);
				},
			}}
			secondaryActions={[
				{
					content: t("common.actions.cancel", "Avbryt") as string,
					onAction: handleclose,
				},
			]}
		>
			<Modal.Section>
				<SellfinityQuill value={signature} onChange={setSignature} />
			</Modal.Section>
		</Modal>
	);
};

export default withTranslation(["user", "common"])(EmailSignatureModal);
