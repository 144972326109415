/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { Spinner } from "@shopify/polaris";
import FortnoxArticleContext, { FortnoxArticleContextProvider } from "./FortnoxArticleContext";
import Header from "./Header";
import FortnoxArticleInfoView from "./FortnoxArticleInfoView";
import FortnoxArticleWarehouseInfoView from "./FortnoxArticleWarehouseInfoView";

const FortnoxArticle = ({ id: propsId, match, history, t }) => {
	const { form, handleSave, isFetching, isSaving, isNew } = useContext(FortnoxArticleContext);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "info",
					content: t("fortnox.tabs.info", "Info"),
					render: <FortnoxArticleInfoView history={history} />,
				},
				{
					id: "warehouse_info",
					content: t("fortnox.tabs.warehouse_info", "Lagerinfo"),
					render: <FortnoxArticleWarehouseInfoView history={history} />,
					enabled: form?.Type === "STOCK",
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, t, form]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as FortnoxArticleType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);

	return (
		<Page
			history={history}
			title={
				isFetching
					? ((<Spinner size="small" />) as any)
					: !isNew
					? t("fortnox.articles.title1", "Fortnox artikel: {{id}}", { id: form?.ArticleNumber || "-" })
					: t("fortnox.articles.new_title", "Ny Fortnox artikel")
			}
			primaryAction={{
				content: !isNew ? t("fortnox.actions.save", "Spara") : t("fortnox.actions.create", "Skapa"),
				onAction: handleSaveForm,
				disabled: !form,
				loading: isSaving,
			}}
		>
			<Header article={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["fortnox", "common"])((props) => {
	return (
		<FortnoxArticleContextProvider {...props}>
			<FortnoxArticle {...props} />
		</FortnoxArticleContextProvider>
	);
});
