/* eslint-disable camelcase */
import API from "src/js/API";
import Colors from "src/js/Colors";
import i18n from "src/js/i18n";
import { store } from "src/js/store";

export const getContractFilters = () => {
	return [
		{
			label: i18n.t("fortnox.filters.statuses.active.label", "Aktiv"),
			value: "active",
		},
		{
			label: i18n.t("fortnox.filters.statuses.inactive.label", "Inaktiv"),
			value: "inactive",
		},
		{
			label: i18n.t("fortnox.filters.statuses.finished.label", "Avslutad"),
			value: "finished",
		},
	];
};

export const getInvoiceFilters = () => {
	return [
		{
			label: i18n.t("fortnox.filters.statuses.cancelled.label", "Avbryten"),
			value: "cancelled",
		},
		{
			label: i18n.t("fortnox.filters.statuses.fullypaid.label", "Betald"),
			value: "fullypaid",
		},
		{
			label: i18n.t("fortnox.filters.statuses.unpaid.label", "Obetald"),
			value: "unpaid",
		},
		{
			label: i18n.t("fortnox.filters.statuses.unpaidoverdue.label", "Obetald försenad"),
			value: "unpaidoverdue",
		},
		{
			label: i18n.t("fortnox.filters.statuses.unbooked.label", "Ej bokförd"),
			value: "unbooked",
		},
	];
};

export const getOrderFilters = () => {
	return [
		{
			label: i18n.t("fortnox.filters.statuses.cancelled.label", "Avbryten"),
			value: "cancelled",
		},
		{
			label: i18n.t("fortnox.filters.statuses.expired.label", "Utgången"),
			value: "expired",
		},
		{
			label: i18n.t("fortnox.filters.statuses.invoicecreated.label", "Faktura skapad"),
			value: "invoicecreated",
		},
		{
			label: i18n.t("fortnox.filters.statuses.invoicenotcreated.label", "Faktura ej skapad"),
			value: "invoicenotcreated",
		},
	];
};
export const getArticlesFilters = () => {
	return [
		{
			label: i18n.t("fortnox.filters.statuses.active.label", "Aktiv"),
			value: "active",
		},
		{
			label: i18n.t("fortnox.filters.statuses.inactive.label", "Inaktiv"),
			value: "inactive",
		},
	];
};
export const getOfferFilters = () => {
	return [
		{
			label: i18n.t("fortnox.filters.statuses.cancelled.label", "Avbryten"),
			value: "cancelled",
		},
		{
			label: i18n.t("fortnox.filters.statuses.expired.label", "Utgången"),
			value: "expired",
		},
		{
			label: i18n.t("fortnox.filters.statuses.completed.label", "Färdig"),
			value: "completed",
		},
		{
			label: i18n.t("fortnox.filters.statuses.notcompleted.label", "Ej färdig"),
			value: "notcompleted",
		},
		{
			label: i18n.t("fortnox.filters.statuses.ordercreated.label", "Order skapad"),
			value: "ordercreated",
		},
		{
			label: i18n.t("fortnox.filters.statuses.ordernotcreated.label", "Order ej skapad"),
			value: "ordernotcreated",
		},
	];
};

export const getPDFBlobURL = (base64: any) => {
	if (!base64) return null;
	const binaryBlob = atob(base64);
	const byteArray = new Uint8Array(binaryBlob.length);
	for (let i = 0; i < binaryBlob.length; i++) {
		byteArray[i] = binaryBlob.charCodeAt(i);
	}

	const blob = new Blob([byteArray], { type: "application/pdf" });
	const url = URL.createObjectURL(blob);

	return url;
};
export const downloadPDF = (pdf, filename) => {
	const url = getPDFBlobURL(pdf);
	if (!url) return;

	const a = window.document.createElement("a");
	a.href = url;
	a.download = filename;
	window.document.body.appendChild(a);

	a.click();
	window.document.body.removeChild(a);
	URL.revokeObjectURL(url);
};
export const fetchFortnoxCounts2 = async (contact: ContactType) => {
	if (!contact?.id) return null;
	if (!contact?.fortnox_customer_id) return null;
	if (!store.getState().account.fortnox_integration) return null;
	const scopes = store.getState().account?.fortnox_integration?.authentication?.scope?.split(" ");

	const promises = [
		API.get(`/api/fortnox/contacts/${contact?.id}/invoices.json`, { params: { limit: 1 } })
			.then((response) => response?.data?.count)
			.catch((error) => console.error("error:", error)),
		scopes?.includes("order")
			? API.get(`/api/fortnox/contacts/${contact?.id}/orders.json`, { params: { limit: 1 } })
					.then((response) => response?.data?.count)
					.catch((error) => console.error("error:", error))
			: 0,
		scopes?.includes("order")
			? API.get(`/api/fortnox/contacts/${contact?.id}/offers.json`, { params: { limit: 1 } })
					.then((response) => response?.data?.count)
					.catch((error) => console.error("error:", error))
			: 0,
		API.get(`/api/fortnox/contacts/${contact?.id}/contracts.json`, { params: { limit: 1 } })
			.then((response) => response?.data?.count)
			.catch((error) => console.error("error:", error)),
	];

	const results = await Promise.allSettled(promises);

	const fortnox_invoices = results[0].status === "fulfilled" ? results[0].value : null;
	const fortnox_orders = results[1].status === "fulfilled" ? results[1].value : null;
	const fortnox_offers = results[2].status === "fulfilled" ? results[2].value : null;
	const fortnox_contracts = results[3].status === "fulfilled" ? results[3].value : null;

	return { fortnox_invoices, fortnox_orders, fortnox_offers, fortnox_contracts };
};
export const fetchFortnoxCounts = async (contact: ContactType) => {
	if (!contact?.id) return null;
	if (!contact?.fortnox_customer_id) return null;
	if (!store.getState().account.fortnox_integration) return null;

	try {
		const result = await API.get(`/api/fortnox/contacts/${contact?.id}/counts.json`);
		const { orders, offers, invoices, contracts } = result?.data || {};

		return { fortnox_invoices: invoices, fortnox_orders: orders, fortnox_offers: offers, fortnox_contracts: contracts };
	} catch (error) {
		console.error("error:", error);
		return {};
	}
};

export const getFortnoxContractStatus = (contract: FortnoxContractType) => {
	switch (contract?.Status) {
		case "ACTIVE":
			return { label: i18n.t("fortnox.contract.statuses.active.label", "Aktiv"), color: Colors.green };
		case "INACTIVE":
			return { label: i18n.t("fortnox.contract.statuses.inactive.label", "Inaktiv"), color: Colors.grey };
		case "FINISHED":
			return { label: i18n.t("fortnox.contract.statuses.finished.label", "Avslutad"), color: Colors.purple };
		default:
			return {
				label: "",
				color: null,
			};
	}
};
