import Modal from "src/js/components/modal";
import React from "react";
import { withTranslation } from "react-i18next";

const FortnoxReconnectModal = ({ open, onClose, t, handleReconnect }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			title={t("fortnox.settings.reconnect.title", "Återkoppla Fortnox")}
			primaryAction={{
				content: t("fortnox.settings.reconnect.title", "Återkoppla Fortnox"),
				onAction: handleReconnect,
			}}
			secondaryActions={[
				{
					content: "Cancel",
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<p>
					{t("fortnox.settings.reconnect.description", "Fortnox har tappat anslutningen. Du måste återkoppla för att fortsätta använda Fortnox.")}
				</p>
			</Modal.Section>
		</Modal>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxReconnectModal);
