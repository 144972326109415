import React from "react";

const ClockIcon = ({ color = "var(--textColor)" }) => (
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.24387 3.29462H5.36902V6.79404L8.13939 8.45626L8.57682 7.73888L6.24387 6.35661V3.29462ZM5.95225 0.96167C3.05357 0.96167 0.703125 3.31211 0.703125 6.2108C0.703125 9.10948 3.05357 11.4599 5.95225 11.4599C8.85094 11.4599 11.2014 9.10948 11.2014 6.2108C11.2014 3.31211 8.85094 0.96167 5.95225 0.96167ZM5.95225 10.2935C3.70096 10.2935 1.8696 8.46209 1.8696 6.2108C1.8696 3.95951 3.70096 2.12814 5.95225 2.12814C8.20355 2.12814 10.0349 3.95951 10.0349 6.2108C10.0349 8.46209 8.20355 10.2935 5.95225 10.2935Z"
			fill={color}
		/>
	</svg>
);

export default ClockIcon;
