import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import Toast from "src/js/components/Toast";

type SetFormFunction = (form: Partial<CategoryType> | null | ((prevForm: Partial<CategoryType>) => Partial<CategoryType>)) => void;

type CategoryContextValue = {
	form: Partial<CategoryType> | null;
	category: CategoryType | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isSaving: boolean;
	handleSave: (category: CategoryType) => any;
} & WithTranslation;

const CategoryContext = React.createContext({} as CategoryContextValue);

export const CategoryContextProvider = withTranslation(["category", "common"])(({ id: propsId, match, history, children, t, defaultData = {} }) => {
	const [form, setForm] = useState<Partial<CategoryType> | null>(defaultData);
	const id = propsId || match.params.id;
	const [isSaving, setIsSaving] = useState(false);

	const queryClient = useQueryClient();

	const fetch = useCallback(async () => {
		try {
			const res = await API.get(`/api/categories/${id}.json`);

			return res.data.category;
		} catch (error) {
			Toast.error(error);
			return defaultData || null;
		}
	}, [id, defaultData]);

	const queryKey = [id && `category-${id}`];
	const { data: category = null, isFetching }: UseQueryResult<CategoryType | null> = useQuery({
		queryKey,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.data || defaultData || null,
		enabled: !!id,
	});

	useEffect(() => {
		if (category) setForm(category);
	}, [category]);

	const saveFunction = useCallback(
		async (category: CategoryType) => {
			const endpoint = !id ? `/api/categories.json` : `/api/categories/${id}.json`;

			const res = await (id ? API.put(endpoint, { ...category }) : API.post(endpoint, { ...category }));

			const successMessage = !id
				? t("categories.responses.category_saved", "Artikel uppdaterad")
				: t("categories.responses.category_created", "Artikel skapad");

			Toast.success(successMessage);

			history.replace(`/admin/categories/${res.data.category.id}`, {
				data: res.data.category,
			});

			return res;
		},
		[id, t, history]
	);

	const update = async ({ category }) => {
		const response = await saveFunction(category);
		return response?.data;
	};

	const mutation = useMutation(update, {
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});

	const handleSave = useCallback(
		async (category: CategoryType) => {
			try {
				if (!category) {
					throw new Error("No category");
				}

				setIsSaving(true);
				return await mutation.mutateAsync({ category });
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			} finally {
				setIsSaving(false);
			}
		},
		[mutation]
	);

	const value: any = useMemo(
		() => ({
			form,
			setForm,
			isFetching,
			t,
			id,
			handleSave,
			category,
			isSaving,
		}),
		[isFetching, t, id, form, handleSave, setForm, category, isSaving]
	);

	return useMemo(() => <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>, [value, children]);
});
export default CategoryContext;
