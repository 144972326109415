/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import FortnoxTableColumn from "./FortnoxTableColumn";

export default class FortnoxTableRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...(props || {}),
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ ...this.state, ...props });
	}

	onUpdate(handle, value) {
		this.state.row = { ...(this.state.row || {}), [handle]: value };
		this.setState({ row: this.state.row }, () => {
			this?.props?.onChange?.(this.state.row);
		});
	}

	onSelect(handle, column, value) {
		if (column?.updateFields?.length) {
			column.updateFields.forEach((field) => {
				if (typeof field === "function") {
					this.state.row = field(this.state.row || {}, value);
				} else {
					this.state.row = { ...(this.state.row || {}), [field]: value[field] };
				}
			});
		} else {
			this.state.row = { ...(this.state.row || {}), [handle]: value[handle] };
		}
		this.setState({ row: this.state.row }, () => {
			this?.props?.onChange?.(this.state.row);
		});
	}

	render() {
		return (
			// onContextMenu={this?.props?.onContextMenu}
			<tr className="Fortnox__Table__Row">
				{(this.props.columns || []).map((column, index) => (
					<FortnoxTableColumn
						key={index}
						{...column}
						onChange={this.onUpdate.bind(this, column.handle)}
						onSelect={this.onSelect.bind(this, column.handle, column)}
						value={typeof column?.handle == "function" ? column.handle(this.state.row) : this.state?.row?.[column?.handle] || null}
						// data={this.state?.row?.[column?.handle] || []}
					/>
				))}

				{this?.props?.onRemoveRow && (
					<div onClick={() => this?.props?.onRemoveRow?.(this.state)} className="Fortnox__Table__Row-Action">
						<Icon source={DeleteMajor} />
					</div>
				)}
			</tr>
		);
	}
}
