export default (state = [], action) => {
	switch (action.type) {
		case "SET_SAVED_SEARCHES":
			return action.saved_searches;
		case "ADD_SAVED_SEARCH": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.saved_search.id) {
					return newState;
				}
			}
			newState.push(action.saved_search);
			return newState;
		}
		case "UPDATE_SAVED_SEARCH": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.saved_search.id) {
					newState[i] = action.saved_search;
				}
			}
			return newState;
		}
		case "REMOVE_SAVED_SEARCH": {
			const newState = [];
			for (let i = 0; i < state.length; i++) {
				if (state[i].id != action.id) {
					newState.push(state[i]);
				}
			}
			return newState;
		}
		default:
			return state;
	}
};
