/* eslint-disable quotes */
import React, { ForwardedRef, FC, forwardRef, useState, useCallback, Suspense, useEffect, useMemo } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Pagination, Spinner } from "@shopify/polaris";
import { EmailMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useStateCustom from "src/js/hooks/useStateCustom";
import useQuery from "src/js/hooks/useQuery";
import API from "../../API";
import FloatingActionBar from "../FloatingActionBar";
import Modal from "../modal";
import EmailRow from "./OutlookEmailRow";
import Banner from "../banner";
import Tabs from "../Tabs";
import Toast from "src/js/components/Toast";

type EmailListSheetContentProps = {
	contact: ContactType;
	integration: IntegrationType;

	open?: boolean;
	onClose: () => void;
	inline?: boolean;
} & WithTranslation;

const NUM_EMAILS_PER_PAGE = 25;

const EmailListSheetContent: FC<EmailListSheetContentProps> = forwardRef<HTMLDivElement, EmailListSheetContentProps>(
	({ contact, integration, open, onClose, inline, t }: EmailListSheetContentProps, ref?: ForwardedRef<HTMLDivElement>) => {
		const tabs = useMemo(
			() => [
				{
					id: "sent",
					content: t("emails.tabs.inbox.label", "Inkorg"),
					// content: "Skickade ifrån kontakten",
					accessibilityLabel: t("emails.tabs.inbox.label", "Inkorg"),
					panelID: "sent",
				},
				{
					id: "received",
					content: t("emails.tabs.sent.label", "Skickat"),
					// content: "Mottagna av kontakten",
					accessibilityLabel: t("emails.tabs.sent.label", "Skickat"),
					panelID: "received",
				},
			],
			[t]
		);

		const [selected, setSelected] = useState<Array<string>>([]);
		const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

		const [receivedQuery, setReceivedQuery, resetReceivedQuery] = useStateCustom<EmaiQuery>({
			$top: NUM_EMAILS_PER_PAGE,
			$count: true,
			$search: `"to:${contact.email}"`,
			// $orderby: "sentDateTime desc",
		});

		const [sentQuery, setSentQuery, resetSentQuery] = useStateCustom<EmaiQuery>({
			$filter: `sentDateTime ge 1970-01-01 and from/emailAddress/address eq '${contact.email}' and isDraft eq false `,
			$top: NUM_EMAILS_PER_PAGE,
			$count: true,
			$orderby: "sentDateTime desc",
		});

		const query = (() => {
			switch (tabs[selectedTabIndex]?.id) {
				case "sent":
					return sentQuery;
				case "received":
					return receivedQuery;
				default:
					return sentQuery;
			}
		})();

		const setQuery = useMemo(() => {
			switch (tabs[selectedTabIndex]?.id) {
				case "sent":
					return setSentQuery;
				case "received":
					return setReceivedQuery;
				default:
					return setSentQuery;
			}
		}, [selectedTabIndex, tabs, setSentQuery, setReceivedQuery]);

		const queryKeys = [
			...Object.entries(query)
				.filter(([key, value]) => value !== undefined && value !== null)
				.map(([key, value]) => `${key}_${value}`),
			integration?.type,
			contact?.id,
		];
		const {
			data,
			isFetching,
			isLoading,
			error,
		}: {
			data?: {
				value?: GraphEmail[];
				"@odata.nextLink"?: string;
				"@odata.count"?: number;
			};
			isFetching: boolean;
			isLoading: boolean;
			error: any;
			isError: boolean;
			// refetch: () => void;
		} = useQuery({
			queryKey: queryKeys,
			queryFn: () => fetchEmails(query),
			refetchOnWindowFocus: false,
			enabled: !!open && !!contact?.email && !!query,
		});
		const { value: emails = [], "@odata.nextLink": nextLink, "@odata.count": count } = data || {};

		const fetchEmails = useCallback(
			async (query: EmaiQuery) => {
				const { data } = await API.get<GraphResponse<Array<GraphEmail>>>(`/api/integrations/${integration.type}/${integration.id}/email`, {
					params: { ...query, attachments: true, checkImported: true },
				});

				return data;
			},
			[integration.id, integration.type]
		);

		const importEmails = async (selected: string[]) => {
			const selectedEmails = emails?.filter((email) => selected.includes(email.id));
			if (!selected?.length) return;

			const res = await API.post(`/api/integrations/${integration.type}/${integration.id}/email_import`, {
				emails: selectedEmails,
				contact,
			})
				.then((response) => {
					Toast.success("Emails imported");
					const event = new CustomEvent("refreshActivities");
					window.dispatchEvent(event);
					return selected;
				})
				.catch((error) => {
					Toast.error(error);
					return error;
				});

			return res;
		};

		const queryClient = useQueryClient();
		const doImport = useMutation({
			mutationFn: importEmails,

			onSuccess: (selected) => {
				const data = queryClient.getQueryData(queryKeys);

				queryClient.setQueryData(queryKeys, (prev: any) => {
					return {
						...prev,
						value: prev?.value?.map((email: GraphEmail) => {
							if (selected?.includes?.(email.id)) {
								return {
									...email,
									imported: true,
								};
							}

							return email;
						}),
					};
				});

				onClose();

				return {
					data,
				};
			},
		});

		const handleImport = () => {
			return doImport.mutateAsync(selected);
		};

		useEffect(() => {
			if (!open) {
				setSelected([]);
				resetReceivedQuery();
				resetSentQuery();
			}
		}, [open, resetReceivedQuery, resetSentQuery]);

		const getParamFromNextLink = (key: string) => {
			if (!nextLink) return null;
			const searchUrl = new URL(nextLink);
			const params = new URLSearchParams(searchUrl.search);
			return params.get(key);
		};

		if (!integration) return null;

		const tab = tabs[selectedTabIndex];

		const content = (
			<Suspense fallback={<Spinner />}>
				<Tabs
					selected={selectedTabIndex}
					tabs={tabs}
					onSelect={(selectedTabIndex) => {
						setSelectedTabIndex(selectedTabIndex);
					}}
				/>
				<br />

				{error && !isFetching && (
					<Banner title={error?.message} status="critical">
						<p>{error?.response?.data?.message}</p>
					</Banner>
				)}
				{isLoading && !emails?.length ? (
					<LoadingContainer>
						<Spinner />
					</LoadingContainer>
				) : (
					<div
						style={{
							transition: "all 0.3s ease-in-out",
							opacity: isFetching ? 0.5 : 1,
						}}
					>
						{emails
							?.filter((email) => email?.subject || email?.bodyPreview || email?.attachments?.length)
							?.map((email, index) => (
								<React.Fragment key={index}>
									<EmailRow
										key={index}
										email={email}
										seleted={selected.includes(email.id)}
										onSelect={(selected) => {
											if (selected) setSelected((curr) => [...curr, email.id]);
											else setSelected((curr) => [...curr.filter((i) => i != email.id)]);
										}}
									/>
									{/* {emails.length - 1 !== index && <Spacer />} */}
								</React.Fragment>
							))}
					</div>
				)}

				<FloatingActionBar
					count={selected?.length}
					open={!!selected?.length}
					onClose={() => {
						setSelected([]);
					}}
					actions={[
						{
							icon: <EmailMajor />,
							label: "Importera",
							onAction: handleImport,
						},
					]}
				/>
			</Suspense>
		);

		if (inline) return content;

		return (
			<Modal
				open={!!open}
				onClose={onClose}
				title={t("contact.import_emails_modal_title", "Importera emails")}
				large
				footer={
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Pagination
							hasPrevious={(query?.$skip || 0) > 0}
							onPrevious={() => {
								setQuery((curr) => ({ ...curr, $skip: (query.$skip || 0) - (query?.$top || 0) }));
							}}
							label={
								<div style={{ position: "relative" }}>
									{isFetching && (
										<div
											style={{
												inset: -10,
												background: "#00000037",
												transition: "all 250ms",
												position: "absolute",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												borderRadius: "3px",
											}}
										>
											<Spinner size="small" />
										</div>
									)}
									{`${query.$skip || 0} - ${(query.$skip || 0) + (emails?.length || 0)}`} {count !== undefined && `${t("common.of", "av")} ${count}`}
								</div>
							}
							hasNext={!!nextLink && !isFetching}
							// hasNext={emails?.length === query.$top}
							onNext={() => {
								if (tab.id === "received") {
									setQuery((curr) => ({ ...curr, $skiptoken: getParamFromNextLink("$skiptoken") }));
								} else {
									setQuery((curr) => ({ ...curr, $skip: parseInt(getParamFromNextLink("$skip") || "0") }));
								}
							}}
						/>
					</div>
				}
			>
				<Modal.Section>{content}</Modal.Section>
			</Modal>
		);
	}
);

export default withTranslation(["emails", "board", "common"], { withRef: true })(EmailListSheetContent);

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.625rem;
`;
