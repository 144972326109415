import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TargetDashboardContext from "../Context";
import Leaderboard from "src/js/components/Leaderboard";
import { Icon, OptionList, Tooltip } from "@shopify/polaris";
import { ChevronDownMinor, InfoMinor, SortAscendingMajor, SortDescendingMajor } from "@shopify/polaris-icons";
import Popover from "src/js/components/Popover";
import { numberFormatter } from "src/js/Utilities";
import getTargetConnectionAvatar from "src/js/views/Targets/Utilities/getTargetConnectionAvatar";
import getTargetConnectionLabel from "src/js/views/Targets/Utilities/getTargetConnectionLabel";

const TargetLeaderboard = () => {
	const { target, data, isLoading, targetConnections, t } = useContext(TargetDashboardContext);
	const settings = useMemo(() => data?.leaderboard || [], [data?.leaderboard]);

	const propertyOptions = useMemo(() => {
		return settings?.options?.value_types || [];
	}, [settings?.options?.value_types]);

	const [properties, setProperties] = useState(propertyOptions?.filter((opt) => opt.default).map((opt) => opt.value) || []);

	useEffect(() => {
		setProperties((c) => {
			if (propertyOptions?.length > 0 && !c?.length) {
				return propertyOptions?.filter((opt) => opt.default).map((opt) => opt.value) || [];
			}

			return c;
		});
	}, [propertyOptions]);

	const [sortBy, setSortBy] = useState<any>("difference_to_date_percentage");
	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

	const handleChangeSortBy = (value) => () => {
		setSortBy(value);

		if (value === sortBy) {
			if (sortOrder === "asc") {
				setSortOrder("desc");
				setSortBy("value");
			} else {
				setSortOrder("asc");
			}
		} else {
			setSortOrder("desc");
		}
	};

	const handleChangeProperties = (value) => {
		setProperties(value);

		// setLocalStorage("dashboards", (c) =>
		// 	deepMergeReplaceKey(c || {}, {
		// 		sales: {
		// 			leaderboard: {
		// 				properties: value,
		// 			},
		// 		},
		// 	})
		// );
	};

	const rows = useMemo(() => {
		if (!targetConnections) return [];

		switch (sortBy) {
			case "user":
				if (sortOrder === "asc") {
					return targetConnections.sort((a, b) => ((a.user || a.user_group)?.name || "").localeCompare((b.user || b.user_group)?.name || ""));
				}

				return targetConnections.sort((a, b) => ((b.user || b.user_group)?.name || "").localeCompare((a.user || a.user_group)?.name || ""));

			case "value":
				if (sortOrder === "asc") {
					return targetConnections.sort((a, b) => a.value - b.value);
				}

				return targetConnections.sort((a, b) => b.value - a.value);

			default:
				if (sortOrder === "asc") {
					return targetConnections.sort((a, b) => (a[sortBy] || 0) - (b[sortBy] || 0));
				}

				return targetConnections.sort((a, b) => (b[sortBy] || 0) - (a[sortBy] || 0));
		}
	}, [targetConnections, sortBy, sortOrder]);

	const renderRow = (index, targetConnection) => {
		return (
			<>
				<td style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
					{getTargetConnectionAvatar(targetConnection)}
					{getTargetConnectionLabel(targetConnection)}
				</td>

				{properties?.map((property) => {
					const option = propertyOptions.find((opt) => opt.value === property);
					return (
						<td key={property}>
							{option?.suffix === "%" && (targetConnection?.[property] >= 0 ? "+" : "")}
							{numberFormatter({
								value: targetConnection?.[property],
								removeCurrencySymbol: true,
								minimumFractionDigits: target?.number_of_decimals,
								maximumFractionDigits: target?.number_of_decimals,
							}) || "-"}
							{option?.suffix || ""}
						</td>
					);
				})}

				<td>
					{numberFormatter({
						value: targetConnection?.value,
						maximumFractionDigits: target?.number_of_decimals,
						minimumFractionDigits: target?.number_of_decimals,
						removeCurrencySymbol: true,
					}) ?? "-"}
					{target?.suffix || ""}
				</td>
			</>
		);
	};

	return (
		<Wrapper>
			<Leaderboard
				computeItemKey={(index, row) => row?.id}
				loading={!!isLoading}
				style={{
					// ...(style || {}),
					height: `calc(100%  - ${
						30 + // title
						20 + // title margin
						30 // select
					}px)`,
				}}
				virtuoseStyle={{
					minHeight: 300,
				}}
				data={rows}
				renderRow={renderRow}
				// headers={[{ title: "", width: "50px" }, { title: "Användare", width: "50%" }, "Antal"]}
				headers={[
					{ title: <ColumnText onClick={handleChangeSortBy("user")}>Användare/Grupp</ColumnText>, width: "35%" },
					// { title: "Antal affärer", width: "15%", enabled: properties?.length == 1 },

					...(properties?.map((property) => ({
						title: (
							<ColumnText onClick={handleChangeSortBy(property)} style={{ justifyContent: "end" }}>
								{sortBy === property && <Icon source={sortOrder === "asc" ? SortAscendingMajor : SortDescendingMajor} />}
								{propertyOptions?.find((option) => option.value === property)?.label || "-"}
							</ColumnText>
						),
					})) || []),

					{
						key: "value",
						title: (
							<Popover
								preferredAlignment="right"
								activator={
									<ColumnButton onClick={handleChangeSortBy("value")}>
										{sortBy === "value" && <Icon source={sortOrder === "asc" ? SortAscendingMajor : SortDescendingMajor} />}

										{properties?.length == 1
											? propertyOptions.find((option) => option.value === properties[0])?.label || "-"
											: properties?.length
											? t("targets.dashboard.terms.value", "Värde")
											: t("targets.dashboard.terms.choose_value", "Välj värde")}

										{properties?.length > 1 && (
											<Tooltip
												content={`${properties
													.map((property) => propertyOptions.find((option) => option.value === property)?.label || "-")
													.join(" + ")}
													`}
												// content={`Summering av: ${properties
												// 	.map((property) => propertyOptions.find((option) => option.value === property)?.label || "-")
												// 	.join(", ")}
												// 	`}
											>
												<Icon source={InfoMinor} />
											</Tooltip>
										)}

										<Icon source={ChevronDownMinor} />
									</ColumnButton>
								}
							>
								<OptionList allowMultiple options={propertyOptions} selected={properties} onChange={handleChangeProperties} />
							</Popover>
						),
						style: {
							paddingLeft: 10,
							justifyContent: "end",
							display: "flex",
						},
						// width: "25%",
					},

					// { title: <Select label="" labelHidden options={options} value={property} onChange={setProperty} /> },
				].filter((i) => (!("enabled" in i) || i.enabled) && i)}
				// title={t("dashboards.service_desk.leaderboard.title", "Topplista")}
				// data={stats?.data.leaderboard}
			/>
		</Wrapper>
	);
};
export default TargetLeaderboard;
const Wrapper = styled.div`
	height: 100%;

	td {
		svg {
			width: 2.6rem;
			height: 2.6rem;
		}

		.Polaris-Icon {
			margin: 0;
			width: 2.6rem;
			height: 2.6rem;
		}
	}
`;

const ColumnText = styled.div`
	cursor: pointer;
	display: flex;

	.Polaris-Icon {
		margin: 0;
	}
`;

const ColumnButton = styled.button`
	padding: 3px;
	text-align: start;

	background: transparent;
	color: var(--textColor);
	font-weight: bold;
	border: none;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	/* text-decoration: underline; */

	display: flex;
	gap: 0.2rem;
`;
