import React from "react";

const LeadsIcon = ({ color = "var(--textColor)" }) => (
	<svg width="24" height="24" viewBox="0 0 33 33" fill="none" style={{ fill: "none" }} xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.4102 20.8887C19.1716 20.8887 21.4102 18.6501 21.4102 15.8887C21.4102 13.1272 19.1716 10.8887 16.4102 10.8887C13.6487 10.8887 11.4102 13.1272 11.4102 15.8887C11.4102 18.6501 13.6487 20.8887 16.4102 20.8887Z"
			stroke={color}
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M8.38513 25.8136C9.13752 24.3317 10.2856 23.087 11.7021 22.2175C13.1185 21.3481 14.7481 20.8879 16.4101 20.8879C18.0722 20.8879 19.7017 21.3481 21.1182 22.2175C22.5347 23.087 23.6827 24.3317 24.4351 25.8136"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M22.4102 7.88867H28.4102" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M25.4102 4.88867V10.8887" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M28.2602 15.0012C28.3612 15.6253 28.4114 16.2565 28.4102 16.8887C28.4102 19.2621 27.7064 21.5821 26.3878 23.5555C25.0692 25.5289 23.1951 27.067 21.0024 27.9752C18.8096 28.8835 16.3969 29.1211 14.0691 28.6581C11.7413 28.1951 9.60311 27.0522 7.92488 25.374C6.24665 23.6957 5.10376 21.5576 4.64074 19.2298C4.17772 16.902 4.41536 14.4892 5.32361 12.2965C6.23186 10.1038 7.76993 8.22964 9.74332 6.91106C11.7167 5.59248 14.0368 4.88869 16.4102 4.88869C17.0424 4.88745 17.6736 4.93762 18.2977 5.03869"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default LeadsIcon;
