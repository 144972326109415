import { Spinner } from "@shopify/polaris";
import React, { Component } from "react";
import API from "../js/API";
import Toast from "src/js/components/Toast";
import BoardViewForm from "../js/views/Workspaces/BoardViewForm";

class ExternalBoardViewForm extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const viewId = this.props.match.params.id;

		this.fetchBoardView(viewId);
	}

	async fetchBoardView(id) {
		try {
			const result = await API.get("/forms/" + id + ".json", { params: {} });
			const board = JSON.parse(result.data.board);
			const view = JSON.parse(result.data.view);
			this.setState({ view, board });
		} catch (error) {
			Toast.error(error);
		}
	}

	render() {
		if (!this.state.board || !this.state.view) return <Spinner />;
		return <BoardViewForm board={this.state.board} view={this.state.view} />;
	}
}
export default ExternalBoardViewForm;
