/* eslint-disable no-restricted-syntax */
export default (state = {}, action) => {
	switch (action.type) {
		case "CLEAR_BOARD_GROUPS": {
			const groups = {};
			for (const i in state) {
				const group = state[i];

				if (group.board_id != action.board_id) {
					groups[group.id] = group;
				}
			}
			return groups;
		}
		case "SET_BOARD_GROUPS": {
			const groups = Object.assign({}, state);
			for (let i = 0; i < action.groups.length; i++) {
				const group = action.groups[i];
				const groupId = group.id;
				groups[groupId] = group;
			}
			return groups;
		}
		case "ADD_BOARD_GROUP": {
			const newState = Object.assign({}, state);
			newState[action.group.id] = action.group;
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "UPDATE_BOARD_GROUP": {
			const newState = Object.assign({}, state);
			newState[action.group.id] = Object.assign({}, action.group);
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "UPDATE_BOARD_GROUP_POSITION_DOWN": {
			const newState = Object.assign({}, state);
			for (const i in newState) {
				if (newState[i].board_id === action.board_id && newState[i].position >= action.position) {
					const newItem = Object.assign({}, newState[i]);
					newItem.position++;
					newState[i] = newItem;
				}
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "UPDATE_BOARD_GROUP_POSITION_UP": {
			const newState = Object.assign({}, state);
			for (const i in newState) {
				if (newState[i].board_id === action.board_id && newState[i].position >= action.position) {
					const newItem = Object.assign({}, newState[i]);
					newItem.position--;
					newState[i] = newItem;
				}
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		case "REMOVE_BOARD_GROUP": {
			const newState = Object.assign({}, state);
			if (action.group.id in newState) {
				delete newState[action.group.id];
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return newState;
		}
		default:
			return state;
	}
};
