import { Button, ButtonGroup, FormLayout, Labelled, Layout } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import React from "react";
import { withTranslation } from "react-i18next";
import SearchField from "src/js/components/SearchField";
import TextField from "src/js/components/TextField";

type FortnoxArticleInfoProps = {
	article: Partial<FortnoxArticleType>;
	onChange: (field: FortnoxArticleTypeMutableKeys) => (value: string | boolean) => void;
	t: any;
	isSaving: boolean;
	isNew: boolean;
};

const FortnoxArticleInfo = ({ article, onChange, t, isSaving, isNew }: FortnoxArticleInfoProps) => {
	const disabled = !!isSaving;

	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					<Labelled id="Active" label={t("fortnox.fields.Active.label", "Aktiv")}>
						<ButtonGroup fullWidth segmented>
							<Button primary={article.Active} pressed={article.Active} onClick={() => onChange("Active")(true)} disabled={disabled}>
								{t("fortnox.fields.Active.options.true", "Aktiv") as string}
							</Button>
							<Button primary={!article.Active} onClick={() => onChange("Active")(false)} disabled={disabled}>
								{t("fortnox.fields.Active.options.false", "Ej aktiv") as string}
							</Button>
						</ButtonGroup>
					</Labelled>

					<TextField
						disabled={!isNew}
						label={t("fortnox.fields.ArticleNumber.label", "Artikelnummer")}
						value={article.ArticleNumber}
						onChange={onChange("ArticleNumber")}
					/>

					<TextField
						readOnly
						disabled={disabled}
						label={t("fortnox.fields.SalesPrice.label", "Utpris Prislista A")}
						value={article.SalesPrice}
						onChange={onChange("SalesPrice")}
					/>

					<TextField
						disabled={disabled}
						label={t("fortnox.fields.Description.label", "Beskrivning")}
						value={article.Description}
						onChange={onChange("Description")}
						multiline
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.ManufacturerArticleNumber.label", "Tillverkarens artikelnummer")}
						value={article.ManufacturerArticleNumber}
						onChange={onChange("ManufacturerArticleNumber")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.Note.label", "Notering")}
						value={article.Note}
						onChange={onChange("Note")}
						multiline
					/>
					{/* <TextField
						disabled={disabled && false} // Need an endpoint to search for these in fortnox
						label={t("fortnox.fields.SupplierName.label", "Leverantör")}
						value={article.SupplierName}
						onChange={onChange("SupplierName")}
						multiline
					/>
					<TextField
						disabled={disabled && false} // Need an endpoint to search for these in fortnox
						label={t("fortnox.fields.SupplierNumber.label", "Leverantörsnummer")}
						value={article.SupplierNumber}
						onChange={onChange("SupplierNumber")}
						multiline
					/> */}
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<SearchField
						label={t("fortnox.fields.Unit.label", "Enhet")}
						disabled={disabled}
						prefix={ChevronDownMinor}
						resource="fortnox/units.json"
						onSelect={(v) => onChange("Unit")(v.Code)}
						id_handle="Code"
						label_handle="Code"
						resource_handle="Units"
						searchHandle={null}
						resourceName={{ plural: t("fortnox.units.plural", "enheter"), singular: t("fortnox.units.plural", "enhet") }}
						value={article?.Unit}
					/>

					<Labelled id="Active" label={t("fortnox.fields.Type.label", "Typ av artikel")}>
						<ButtonGroup fullWidth segmented>
							<Button primary={article.Type === "STOCK"} onClick={() => onChange("Type")("STOCK")} disabled={disabled}>
								{t("fortnox.fields.Type.options.STOCK", "Vara") as string}
							</Button>
							<Button primary={article.Type === "SERVICE"} onClick={() => onChange("Type")("SERVICE")} disabled={disabled}>
								{t("fortnox.fields.Type.options.SERVICE", "Tjänst") as string}
							</Button>
						</ButtonGroup>
					</Labelled>

					<Labelled id="Active" label={t("fortnox.fields.StockGoods.label", "Lagervara")}>
						<ButtonGroup fullWidth segmented>
							<Button
								primary={article.StockGoods}
								pressed={article.StockGoods}
								onClick={() => onChange("StockGoods")(true)}
								disabled={disabled || article.Type === "SERVICE"}
							>
								{t("fortnox.fields.StockGoods.options.true", "Ja") as string}
							</Button>
							<Button primary={!article.StockGoods} onClick={() => onChange("StockGoods")(false)} disabled={disabled || article.Type === "SERVICE"}>
								{t("fortnox.fields.StockGoods.options.false", "Nej") as string}
							</Button>
						</ButtonGroup>
					</Labelled>

					<Labelled id="Active" label={t("fortnox.fields.WebshopArticle.label", "Extern webbshop")}>
						<ButtonGroup fullWidth segmented>
							<Button
								primary={article.WebshopArticle}
								pressed={article.WebshopArticle}
								onClick={() => onChange("WebshopArticle")(true)}
								disabled={disabled}
							>
								{t("fortnox.fields.WebshopArticle.options.true", "Ja") as string}
							</Button>
							<Button primary={!article.WebshopArticle} onClick={() => onChange("WebshopArticle")(false)} disabled={disabled}>
								{t("fortnox.fields.WebshopArticle.options.false", "Nej") as string}
							</Button>
						</ButtonGroup>
					</Labelled>

					<Labelled id="Active" label={t("fortnox.fields.Expired.label", "Utgående artikel")}>
						<ButtonGroup fullWidth segmented>
							<Button primary={article.Expired} pressed={article.Expired} onClick={() => onChange("Expired")(true)} disabled={disabled}>
								{t("fortnox.fields.Expired.options.true", "Ja") as string}
							</Button>
							<Button primary={!article.Expired} onClick={() => onChange("Expired")(false)} disabled={disabled}>
								{t("fortnox.fields.Expired.options.false", "Nej") as string}
							</Button>
						</ButtonGroup>
					</Labelled>
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxArticleInfo);
