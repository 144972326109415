import React, { Component } from "react";
import { Popover, Scrollable } from "@shopify/polaris";
import "../../css/variables.scss";
import "../../css/popover.scss";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import Colors from "../Colors";
import { getPortalsMaxZIndex } from "../Utilities";

class MyPopover extends Component {
	constructor(props) {
		super(props);
		this.state = { zIndex: 400, open: false };
		this.headerRef = React.createRef();
	}

	componentDidMount() {
		// eslint-disable-next-line react/no-unused-state
		if (this.props.header) setTimeout(() => this.setState({ rnd: Math.random() }), 0);
	}

	UNSAFE_componentWillReceiveProps(props) {
		// eslint-disable-next-line react/no-unused-state
		if (props.header) setTimeout(() => this.setState({ rnd: Math.random() }), 0);
		if ((props.active && props.active !== this.props.active) || (props.open && props.open !== this.props.open)) {
			this.setState({ zIndex: getPortalsMaxZIndex() });
		}
	}

	onClose() {
		if ("open" in this.props || "active" in this.props) {
			// Do nothing
		} else {
			this.setState({ open: false });
		}

		if (this.props.onClose) this.props.onClose();
	}

	onClick() {
		if (this.props.activator?.onClick) this.props.activator?.onClick?.();
		if (this.props.activator?.props?.onClick) this.props.activator?.props?.onClick?.();
		this.setState({ open: true });
	}

	render() {
		const activator =
			"open" in this.props || "active" in this.props
				? this.props.activator
				: React.cloneElement(this.props.activator, {
						onClick: this.onClick.bind(this),
				  });

		const isOpen = (this.props.active || this.props.open || this.state.open) && !this.props.disabled;

		const closeButton = (() => {
			if (!this.props.showCloseButton) return null;

			// eslint-disable-next-line no-constant-condition
			if (isMobile || true) {
				return (
					<button
						style={{
							paddingTop: "10px",
							paddingBottom: "10px",
							// width: "100%",
							flex: 1,
							background: "var(--main3)",
							fontSize: "0.7812rem",
							cursor: "pointer",
							color: "var(--textColor)",
							// position: "absolute",
							// borderTopWidth: "thin",
							bottom: 0,
							border: "none",
						}}
						onClick={(e) => {
							e.stopPropagation();
							this.onClose();
						}}
					>
						{this.props.t("common.actions.close", "Stäng")}
					</button>
				);
			}

			return null;
		})();
		const content = this.props.header ? (
			<Popover.Pane fixed>
				<div ref={this.headerRef}>{this.props.header}</div>
				<Scrollable
					style={{
						position: "relative",
						// height: 350,
						padding: "var(--p-space-2) 0",
						maxHeight: Math.min(
							window.innerHeight * 0.75,
							400 - (((this.headerRef.current && this.headerRef.current.getBoundingClientRect().height) || 40) + 50)
						),
						// paddingBottom: ((this.headerRef.current && this.headerRef.current.getBoundingClientRect().height) || 40) + 0,
					}}
				>
					{this.props.children}
					{closeButton}
				</Scrollable>
			</Popover.Pane>
		) : (
			<React.Fragment>
				{this.props.children}
				<ButtonsWrapper>
					{closeButton}
					{this.props.action}
				</ButtonsWrapper>
			</React.Fragment>
		);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Popover
				captureOverscroll
				fluidContent
				{...this.props}
				activator={activator}
				onClose={this.onClose.bind(this)}
				active={isOpen}
				zIndexOverride={Math.max(this.state.zIndex, this.props.minZIndex || 400)}
			>
				<div
					onClick={(e) => {
						e.stopPropagation();
						// e.preventDefault();
					}}
				>
					{content}
				</div>
			</Popover>
		);
	}
}

MyPopover.Section = Popover.Section;

export default withTranslation(["common"], { withRef: true })(MyPopover);
MyPopover.defaultProps = {
	showCloseButton: true,
};
const ButtonsWrapper = styled.div`
	display: flex;

	&&&& > * {
		flex: 1;

		&:not(.Polaris-Button--primary) {
			border-radius: 0;
		}

		&.Polaris-Button[type="submit"]:not(.Polaris-Button--primary) {
			color: ${Colors.green};
		}
	}
`;
