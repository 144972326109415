import React, { useMemo } from "react";
import styled from "styled-components";
import { LoadingBox, FlexDiv, Wrapper } from "./styles";
import SkeletonTextField from "./SkeletonTextField";
import { useHistory } from "react-router-dom";

type SkeletonPageProps = {
	title?: string;
	header?: any;
	body?: any;
	secondaryActions?: { content: string }[];
	children?: React.ReactNode;
};

const SkeletonPage = ({ title, header: propsHeader = true, secondaryActions = [...Array(5)], children }: SkeletonPageProps) => {
	const history = useHistory();

	const secondaryActionsWithWidths = useMemo(() => {
		return secondaryActions.map((x, index) => ({
			width: Math.floor(Math.random() * 100) + 100,
			content: x?.content,
		}));
	}, [secondaryActions]);

	const backAction = history?.length > 1;

	const header = React.isValidElement(propsHeader) ? (
		propsHeader
	) : (
		<>
			<FlexDiv gap={10} margin={14} style={{ marginLeft: 0 }}>
				{backAction && <LoadingBox width={30} height={30} />}
				<LoadingBox
					width={title ? "unset" : 150}
					height={30}
					padding={5}
					loading={!title}
					style={{
						background: title ? "transparent" : "asd",
						display: "flex",
						alignItems: "center",
						wordBreak: "break-word",
						overflowWrap: "break-word",
						fontWeight: "var(--p-font-weight-semibold)",
						fontSize: "var(--p-font-size-300)",
						lineHeight: "var(--p-font-line-height-3)",
					}}
				>
					{title}
				</LoadingBox>
			</FlexDiv>

			<FlexDiv gap={10} margin={14} style={{ marginLeft: 0 }}>
				{secondaryActionsWithWidths.map((action, index) => (
					<LoadingBox key={index} width={action.content ? "unset" : action.width} height={30} padding={5}>
						{action.content}
					</LoadingBox>
				))}
			</FlexDiv>
		</>
	);

	const body = React.isValidElement(children) ? (
		children
	) : (
		<>
			<LoadingBox height="80%" width="100%" loading={false}>
				<FlexDiv height={"100%"} width="100%" gap="2rem" padding="2rem">
					<LoadingBox width="50%" loading={false}>
						<SkeletonTextField />
						<SkeletonTextField />
						<SkeletonTextField />
						<SkeletonTextField />
						<SkeletonTextField />
					</LoadingBox>

					<LoadingBox width="50%" loading={false}>
						<SkeletonTextField />
						<SkeletonTextField />
						<SkeletonTextField />
					</LoadingBox>
				</FlexDiv>
			</LoadingBox>
		</>
	);

	return (
		<Container>
			{header}
			{body}
		</Container>
	);
};
export default SkeletonPage;

const Container = styled(Wrapper)`
	height: 80%;
	width: 100%;
`;
