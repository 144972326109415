import i18n from "src/js/i18n";

export const TYPES = [
	{
		label: i18n.t("targets.fields.type.options.yearly2", "Årsvis"),
		value: "yearly",
	},
	{
		label: i18n.t("targets.fields.type.options.quarterly", "Kvartalsvis"),
		value: "quarterly",
	},
	{
		label: i18n.t("targets.fields.type.options.monthly2", "Månadsvis"),
		value: "monthly",
	},
	{
		label: i18n.t("targets.fields.type.options.weekly", "Veckovis"),
		value: "weekly",
	},
	{
		label: i18n.t("targets.fields.type.options.daily", "Dagsvis"),
		value: "daily",
	},
];
