const findOption = (options, value) => {
	return options.reduce((found, option) => {
		if (found) return found; // If found already, return it

		if (option.value === value) {
			return option; // Return the matching option
		}

		if (option.options && option.options.length > 0) {
			return findOption(option.options, value); // Recursively search in nested options
		}

		return null; // No match found, return null
	}, null);
};

export default findOption;
