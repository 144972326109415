import React, { Component } from "react";
import httpBuildQuery from "http-build-query";
import API from "../../API";
import { store } from "../../store";
import AppIntegration from "../../components/AppIntegration.js";

class Integration extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.match.params.id, rand: props.match.params.rand };
	}

	componentDidMount() {
		this.readNotifications(this.state.id);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id || props.match.params.rand != this.state.rand) {
			this.setState({ id: props.match.params.id, rand: props.match.params.rand });
			this.readNotifications(props.match.params.id);
		}
	}

	readNotifications(integrationId) {
		API.post("/api/notifications/read_integration/" + integrationId + ".json", {})
			.then((result) => {})
			.catch((error) => {});
	}

	getIntegrationById(id) {
		for (let i = 0; i < store.getState().account.integrations.length; i++) {
			if (store.getState().account.integrations[i].id == id) {
				return store.getState().account.integrations[i];
			}
		}
		return null;
	}

	getIntegrationContent(path) {
		const integration = this.getIntegrationById(this.state.id);
		return <AppIntegration path={path} integration={integration} />;
	}

	render() {
		let query = window.location.search;
		if (query && query.substring(0, 1) == "?") {
			query = query.substring(1);
		}
		let path = "";
		if (this.props.match.params.path) {
			path = this.props.match.params.path;
		}

		if (this.getIntegrationById(this.state.id)?.type != "web") {
			return this.getIntegrationContent(path);
		}
		let url = this.getIntegrationById(this.state.id).fullscreen_url;

		if (url.indexOf("?") >= 0) {
			const pcs = url.split("?");
			url = pcs[0] + path + "?" + pcs[1];
		} else {
			url += path;
		}

		if (query) {
			url = url + (url.indexOf("?") >= 0 ? "&" : "?") + query;
		}

		return (
			<iframe
				title={url}
				key={this.state.rand}
				src={
					url +
					(url.indexOf("?") >= 0 ? "&" : "?") +
					httpBuildQuery({ signed_token: store.getState().user.signed_token, account_id: store.getState().account.id }).replace(/\+/g, "%20")
				}
				width="100%"
				frameBorder="0"
				// allowTransparency="true"
				height="100%"
				style={{ minHeight: 600 }}
			/>
		);
	}
}

export default Integration;
