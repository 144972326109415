import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Banner from "src/js/components/banner";
import { Wrapper } from "../styles";
import Tabs from "src/js/components/Tabs";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	offer: Partial<FortnoxOfferType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const Header = ({ offer, tabs, onSelect, selected, t }: HeaderProps) => {
	// const status = getStatus(document);
	return (
		<Wrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />

			{offer?.OrderReference && (
				<Banner
					status="info"
					title={t("fortnox.offers.status_order_create", "Order skapad")}
					actions={[
						{
							content: t("fortnox.offers.actions.go_to_order", "Gå till order") as string,
							url: `/admin/fortnox/orders/${offer.OrderReference}`,
						},
					]}
				>
					{t("fortnox.offers.status_order_create_description1", "Order {{order}} har skapats i Fortnox.", { order: offer?.OrderReference })}
				</Banner>
			)}
			{offer?.Cancelled && (
				<Banner status="warning" title={t("fortnox.offers.statuses.cancelled.title", "Offert makulerad")}>
					{t("fortnox.offers.statuses.offert_cancelled.description", "Denna offert är makulerad")}
				</Banner>
			)}
			{offer?.Sent && (
				<Banner status="info" title={t("fortnox.offers.statuses.sent.title", "Offert skickad")}>
					{t("fortnox.offers.statuses.offert_sent.description", "Denna offert är skickad")}
				</Banner>
			)}
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(Header);
