import { Button, LegacyStack, Collapsible, TextField } from "@shopify/polaris";
import { ClockMajor, DeleteMajor, MobileAcceptMajor, MobileCancelMajor, QuestionMarkInverseMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import API from "../../../../API";
import Send2Icon from "../../../../icons/Send2Icon";
import { store } from "../../../../store";
import AttendeesInput from "./AttendeesInput";
import DateTimePicker from "./DateTimePicker";
import CalendarSelect from "./CalendarSelect";
import RTE from "../../../../components/rte";
import { checkEmailValidity, generateId } from "../../../../Utilities";
import BoardHelper from "../../BoardHelper";
import Colors from "../../../../Colors";
import UploadModal from "../../../../components/UploadModal";
import CalendarButton from "./CalendarButton";
import CalendarModalAttendeesListContent from "./CalendarModalAttendeesListContent";
import CalendarModalHeaderButton from "./CalendarModalHeaderButton";
import CalendarHelper from "./CalendarHelper";
import { PreviewButtonAsLink } from "./CalendarPopupPreview";
import NewDatePicker from "../../../../components/NewDatePicker";
import DatepickerHelper from "../../../../components/DatepickerHelper";
import Field from "./Field";
import Switch from "../../../../components/Switch";
import SearchField from "../../../../components/search_field.js";
import BringModalToFront from "../../../../components/BringModalToFront.js";
import InfoIcon from "../../../../icons/Info.js";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

class CalendarModalContent extends Component {
	constructor(props) {
		super(props);

		this.state = this.getInitialState(props);
	}

	getInitialState(props = this.props) {
		const row = props?.row || { column_values: {} };
		const board = props.board || store.getState().user.calendar_board;
		const column = board?.columns?.find((column) => (column.type == "timeline" || column.type === "datetime") && column.connected_column_id);

		const form = props.row?.column_values?.[column?.id]?.value || {};
		const startDate = (props.date && moment(props.date)) || moment();
		const minutesToClosestQuarter = startDate.minute() % 15;

		row.column_values[column?.id] = {
			column_id: column.id,
			value: Object.assign(
				{
					attendees: props.attendees || [],
					isOnlineMeeting: (props.options && props.options.isOnlineMeeting) || (form.external_event && form.external_event.isOnlineMeeting),
					onlineMeetingProvider:
						(props.options && props.options.onlineMeetingProvider) || (form.external_event && form.external_event.onlineMeetingProvider),
					location: form.external_event?.location?.displayName || form.external_event?.location,
					isAllDay:
						row?.value &&
						("isAllDay" in (row?.value || {})
							? row.column_values[column?.id]?.value?.isAllDay
							: !CalendarHelper.getTime(row?.value?.start) && !CalendarHelper.getTime(row?.value?.end)),
				},
				form,
				props.row?.id
					? {
							start: form.start || startDate,
							end: form.end || startDate,
					  }
					: {
							start: moment(startDate)
								.add(minutesToClosestQuarter * -1, "minutes")
								.format("YYYY-MM-DD HH:mm"),
							end: moment(startDate)
								.add(1, "h")
								.add(minutesToClosestQuarter * -1, "minutes")
								.format("YYYY-MM-DD HH:mm"),
					  }
			),
		};

		if (props.optionsByTitle) {
			const columnsByTitle = board?.columns.reduce((acc, column) => {
				if (!column?.connected_column_id && acc[column.title]) return acc;
				return { ...acc, [column.title?.toLowerCase()]: column };
			}, {});

			Object.entries(props.optionsByTitle).forEach(([key, value]) => {
				if (columnsByTitle[key] && value) {
					row.column_values[columnsByTitle[key].id] = {
						column_id: columnsByTitle[key].id,
						row_id: row?.id || null,
						value,
					};
				}
			});
		}

		return {
			row,
			board,
			timeLineColumn: column,
			saving: false,
			// showAttendees: (form && form.attendees && form.attendees.length) || false,
			showResponseButtons: !CalendarHelper.haveResponded(form),
		};
	}

	componentDidMount() {
		const initialState = this.getInitialState(this.props);
		this.setState(initialState);
		const sheet = document.querySelector(".calendar_modal") && document.querySelector(".calendar_modal").closest(".Polaris-Sheet__Container");
		if (sheet) {
			sheet.classList.add("sheet-100");
			const polarisBanner = sheet.querySelector(".Polaris-Banner");
			if (polarisBanner) {
				polarisBanner.style.backgroundColor = "transparent";
			}

			const ribbon = polarisBanner ? polarisBanner.querySelector(".Polaris-Banner__Ribbon") : null;
			if (ribbon) polarisBanner.removeChild(ribbon);
		} else {
			const modal = document.querySelector(".calendar_modal") && document.querySelector(".calendar_modal").closest(".Polaris-Modal-Dialog__Modal");
			if (modal) modal.classList.add("sheet-100");
		}
	}

	getOrganizerEmail() {
		const form = this.state.row.column_values?.[this.state.timeLineColumn?.id]?.value;

		return (CalendarHelper.getOrganizer(form) && CalendarHelper.getOrganizer(form).email) || store.getState().user.email;
	}

	onChange(field, value) {
		if (field === "title") {
			this.state.row.title = value;
			this.setState({ row: this.state.row });
			return;
		}

		const form = this.state.row.column_values?.[this.state.timeLineColumn?.id]?.value;

		if (field === "location") {
			form.location = value?.formatted_address || "";
			this.state.row.column_values[this.state.timeLineColumn.id].value = form;
			this.setState({ row: this.state.row });
			return;
		}

		if (field === "deal") {
			const column = this.state.board?.columns?.find(
				(column) => column.type == "rowlink" && column.connected_board_id == store.getState().account.sales_board_id
			);

			if (!this.state.row.column_values[column?.id]) {
				this.state.row.column_values[column?.id] = {
					column_id: column.id,
					row_id: this.state.row.id,
					value: [],
				};
			}

			if (value) {
				this.state.row.column_values[column?.id].value = [value?.id || (typeof value === "string" ? value : "")];
			} else {
				this.state.row.column_values[column?.id].value = [];
			}
			this.setState({ row: this.state.row });
			return;
		}

		if (field === "isOnlineMeeting") {
			form.onlineMeetingProvider = value ? "teamsForBusiness" : null;
		}

		if (field === "start") {
			const diff = moment(form.end).diff(moment(form.start), "minutes");
			const end = moment(value)
				.add(diff, "minutes")
				.format(`YYYY-MM-DD${this.getTime(value) ? " HH:mm" : ""}`);

			form[field] = value;
			form.end = end;

			this.state.row.column_values[this.state.timeLineColumn.id].value = form;
			this.setState({ row: this.state.row });
			return;
		}

		if (field === "end") {
			if (moment(value).isBefore(moment(form.start))) {
				Toast.warning(this.props.t("calendar.error.invalid_date_span", "Slutdatum kan ej vara tidigare än startdatum"));

				form[field] = form.end;
				this.state.row.column_values[this.state.timeLineColumn.id].value = form;
				this.setState({ row: this.state.row });
				return;
			}
		}

		form[field] = value;
		this.state.row.column_values[this.state.timeLineColumn.id].value = form;
		this.setState({ row: this.state.row });
	}

	onChangeState(field, value) {
		if (field === "responseTimeStart") {
			const diff = moment(this.state.responseTimeEnd).diff(moment(this.state.responseTimeStart), "minutes");
			const end = moment(value)
				.add(diff, "minutes")
				.format(`YYYY-MM-DD${this.getTime(value) ? " HH:mm" : ""}`);

			this.setState({ [field]: value, responseTimeEnd: end });
			return;
		}
		this.setState({ [field]: value });
	}

	removeRow(undo, paramRow) {
		this.setState({ removing: true });
		const params = Object.assign({}, {});
		const row = paramRow || this.props.row;
		if (undo) {
			row.removed = false;
			BoardHelper.addRowAtOldPosition(row.id);
			params.undo = true;
		} else {
			BoardHelper.removeRow(row.id);
		}

		API.delete("/api/boards/" + this.props.board.id + "/rows/" + row.id + ".json", {
			params,
		})
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				if (result.data.row && result.data.row.removed) {
					Toast.warning(
						<>
							<p>{this.props.t("calendar.responses.row_removed", "Tog bort rad")}</p>
							<p>{this.props.t("calendar.actions.revert_row", "Klicka här för att ångra")}</p>
						</>,
						{
							timeOut: 12000,
							onClick: () => this.removeRow(true, result.data.row),
						}
					);
				} else {
					Toast.success(`${this.props.t("calendar.responses.reverted_row", "Ångrade rad")} "${row.title}"`);
				}

				if (this.props.onComplete) this.props.onComplete(result.data.row);
				if (this.props.onClose) this.props.onClose();
			})
			.catch((error) => {
				console.error(error);
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ removing: false, removeModalIsOpen: false });
			});
	}

	onCreate() {
		this.setState({ saving: true });
		if (!this.state.row?.title) {
			this.setState({ saving: false });
			Toast.error(this.props.t("calendar.errors.no_title", "Titel måste vara satt"));
			return;
		}

		const contacts = this.props.contacts || (this.props.contact ? [this.props.contact] : []);

		if (contacts?.length) {
			contacts.forEach((contact) => {
				const contactColumn = this.props.board.columns.find((col) => col.type === "contact");
				if (!this.state.row?.column_values?.[contact.id]?.value) {
					this.state.row.column_values[contactColumn.id] = {
						column_id: contactColumn.id,
						value: [],
					};
				}

				this.state.row?.column_values?.[contactColumn.id]?.value.push(contact.id);
			});
		}

		API.post("/api/boards/" + this.state.board.id + "/rows.json", this.state.row, {
			params: {},
		})
			.then((result) => {
				store.dispatch({ type: "ADD_BOARD_ROW", row: result.data.row });
				// Toast.success(`Skapade ${(this.props.board && this.props.board.singular) || "kalender inlägg"}`);
				Toast.success(this.props.t("calendar.responses.created", "Skapade kalender inlägg"));
				if (this.props.onClose) this.props.onClose();
				if (this.props.onComplete) this.props.onComplete(result.data.row);

				// if (result.data.row) {
				// 	this.setState(
				// 		{
				// 			title: result.data.row.title,
				// 			form:
				// 				(result.data.row &&
				// 					result.data.row.column_values[this.state.timeLineColumn.id] &&
				// 					result.data.row.column_values[this.state.timeLineColumn.id].value) ||
				// 				{},
				// 		},
				// 		() => {
				// 		}
				// 	);
				// }
			})
			.catch((error) => {
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ saving: false });
			});
	}

	onSend() {
		const form = this.state.row.column_values?.[this.state.timeLineColumn?.id]?.value;

		if (form.attendees && Array.isArray(form.attendees) && form.attendees?.filter((i) => i).some((contact) => !checkEmailValidity(contact.email))) {
			Toast.error(this.props.t("calendar.errors.invalid_email", "Ogilitig/saknar kontakt email address"));
			return;
		}

		if (!this.state.row || !this.state.row.id) {
			this.onCreate();
			return;
		}

		setTimeout(() => {
			API.put(`/api/boards/${this.state.board?.id}/rows/${this.state.row?.id}.json`, this.state.row)
				.then((result) => {
					if (this.props.onClose) this.props.onClose();
					if (this.props.onComplete) this.props.onComplete(result.data.row);

					Toast.success(this.props.t("calendar.responses.row_updated", "Kalenderinlägg uppdaterat"));
				})
				.catch((e) => {
					console.error("error:", e);
				})
				.finally(() => {
					// this.setState({ loading: false });
				});

			// const { title, ...value } = this.state.form;
			// this.props.updateCell(this.state.timeLineColumn, { value, title });
		}, 250);

		if (this.props.onClose) this.props.onClose();
		if (this.props.onComplete) this.props.onComplete(this.state.row);

		// if (this.props.row?.id) {
		// 	Toast.success(this.props.t("calendar.responses.updated", "Kalender inlägg uppdaterat"));
		// } else {
		// 	Toast.success(this.props.t("calendar.responses.updated", "Kalender inlägg skickat"));
		// }
	}

	getTime(date = this.state.form.start) {
		const pcs = date.split(" ");
		if (pcs && pcs.length > 1) {
			return date.split(" ")[1];
		}
		return null;
	}

	respondToEmail(response) {
		// DOUBLE CHECK  THIS WITH FREDDE
		this.setState({ [`loading${response && response.capitalize()}`]: true });
		API.put(`/api/boards/${this.props.board.id}/rows/${this.state.row.id}/columns/${this.state.timeLineColumn.id}/invitation_response.json`, {
			response,
			comment: this.state.responseComment,
			start: this.state.responseTimeStart,
			end: this.state.responseTimeEnd,
		})
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					Toast.error(result.data.error);
					return;
				}

				Toast.success(this.props.t(`calendar.respnses.inline.${response}`, `Inbjudan ${CalendarHelper.getInlineStatusLabel2(response)}`));

				if (this.props.onClose) this.props.onClose();
			})
			.catch((error) => {
				console.error("error:", error);
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ [`loading${response.capitalize()}`]: false });
			});
	}

	onCloseResponseModal() {
		this.setState({
			responseModalIsOpen: false,
			action: false,
			showDateTimePicker: false,
			showResponseComment: false,
			responseTimeStart: false,
			responseTimeEnd: false,
		});
	}

	// isRowCreator() {
	// 	const linkColumnId = this.state.board?.columns?.find((col) => col.link_column_id)?.link_column_id;
	// 	const personColumn = this.state.board?.columns?.find((col) => String(col.id) === String(linkColumnId) && col.type === "person");

	// 	return this.state.row.column_values[personColumn?.id]?.value.includes(store.getState().user.id);
	// }

	getHeaderButtons() {
		const form = this.state.row?.column_values?.[this.state.column?.id]?.value || {};
		const value = this.state.row?.column_values?.[this.state.timeLineColumn.id]?.value;

		const headerButtons = [
			{
				label: this.props.t("calendar.header.buttons.remove", "Ta bort"),
				icon: DeleteMajor,
				onClick: () => this.setState({ removeModalIsOpen: true }),
				loading: this.state.removing,
				enabled: this.state.row && this.state.row.id && CalendarHelper.isEditable(form),
				destructive: true,
			},
			{
				label: this.props.t("calendar.header.buttons.send_email_to_all", "Skicka e-post till alla mottagare"),
				icon: <Send2Icon />,
				onClick: () => {
					const title = ` (${this.state.row?.title})`;
					window.open(`mailto:${value?.attendees.map((at) => at.email)?.join(", ")}?cc=${""}&subject=${title}&body=${""}`, "_blank");
				},
				enabled: value?.attendees?.length && this.state.row?.id,
			},
			{
				label: `${this.props.submitPrefix || ""}${this.props.t("calendar.header.buttons.send", "Skicka")}${this.props.submitSuffix || ""}`,
				icon: <Send2Icon color="rgb(15,15,15)" />,
				onClick: this.onSend.bind(this),
				loading: this.state.saving,
				enabled: !CalendarHelper.isRepliable(form),
				primary: true,
			},
		].filter((button) => !("enabled" in button) || button.enabled);

		return headerButtons;
	}

	isExternalOutlookCalendar() {
		return this.state.timeLineColumn?.options?.integration?.type === "outlook365";
	}

	isExternalGoogleCalendar() {
		return this.state.timeLineColumn?.options?.integration?.type === "google";
	}

	onToggleAddGoogleMeeting(checked) {
		const form = this.state.row?.column_values?.[this.state.column?.id]?.value || {};

		if (checked) {
			if (!form.external_event.conferenceData) {
				form.external_event.conferenceData = {
					createRequest: {
						requestId: generateId(10),
					},
				};
			} else {
				form.external_event.conferenceData.createRequest = {
					requestId: generateId(10),
				};
			}
		} else {
			delete form.external_event.conferenceData.createRequest;
		}

		this.state.row.column_values[this.state.timeLineColumn.id].value = form;
		this.setState({ form: this.state.form, row: this.state.row });
	}

	getDeal() {
		const column = this.state.board?.columns?.find(
			(column) => column.type == "rowlink" && column.connected_board_id == store.getState().account.sales_board_id
		);
		const id = this.state.row?.column_values?.[column?.id]?.value?.[0];
		return store.getState().board_linked_rows[id] || store.getState().board_rows[id];
	}

	render() {
		const form = this.state.row.column_values[this.state.timeLineColumn.id].value;
		const repliable = CalendarHelper.isRepliable(form);
		const editable = CalendarHelper.isEditable(form);

		const fields = [
			{
				label: this.props.t("calendar.fields.email.label", "Från"),
				component: <TextField readOnly value={this.getOrganizerEmail()} />,
				disabled: !editable,
			},
			{
				label: this.props.t("calendar.fields.title.label", "Rubrik"),
				component: <TextField value={this.state.row.title} onChange={this.onChange.bind(this, "title")} />,
				disabled: !editable,
			},
			{
				label: this.props.t("calendar.fields.start.label", "Starttid"),
				component: (
					<div style={{ display: "flex", gap: "1.2500rem" }}>
						<NewDatePicker
							style={{ width: "unset" }}
							className="calendar_modal__date_picker start"
							optionalTime
							value={form.start}
							onChange={this.onChange.bind(this, "start")}
							onTimeToggle={({ start, end }) => {
								const v = !DatepickerHelper.haveTime(start);
								if (v) {
									form.start = moment(form.start).format("YYYY-MM-DD");
									form.end = moment(form.end).format("YYYY-MM-DD");
								} else {
									form.start = moment(form.start).format("YYYY-MM-DD") + " " + moment().format("HH:mm");
									form.end = moment(form.end).format("YYYY-MM-DD") + " " + moment().add(1, "hour").format("HH:mm");
								}
								form.isAllDay = v;

								this.state.row.column_values[this.state.timeLineColumn.id].value = form;
								this.setState({ form: this.state.form, row: this.state.row });
							}}
						/>
					</div>
				),
				disabled: !editable,
			},
			{
				label: this.props.t("calendar.fields.end.label", "Sluttid"),
				component: (
					<NewDatePicker
						style={{ width: "unset" }}
						className="calendar_modal__date_picker end"
						value={form.end}
						onChange={this.onChange.bind(this, "end")}
						minDate={form.start}
						minTime={moment(form.start)
							.add(1, "min")
							.format(`YYYY-MM-DD${CalendarHelper.getTime(form.start) ? " HH:mm" : ""}`)}
					/>
				),
				disabled: !editable,
			},
			{
				label: "",
				component: (
					<div>
						<div style={{ display: "flex", gap: "1.2500rem", minHeight: "32px" }}>
							<span className="calendar_modal__body__field__label">{this.props.t("calendar.fields.attendees.label", "Deltagare")}</span>
							<AttendeesInput
								external={this.props.external}
								onChange={this.onChange.bind(this, "attendees")}
								attendees={form.attendees}
								// contacts={form.external_event && form.external_event.attendees}
								required
								style={{ flex: 1 }}
								column={this.state.timeLineColumn}
								form={form}
								editable={editable}
							/>
							{!this.state.showOptionalAttendees && editable && (
								<span className="show_optional_attendees">
									<Button onClick={() => this.setState({ showOptionalAttendees: true })}>
										{this.props.t("calendar.fields.optional.label", "Valfria")}
									</Button>
								</span>
							)}

							{this.props.row && this.props.row.id && (this.isExternalOutlookCalendar() || this.isExternalGoogleCalendar()) && (
								<Button
									icon={<InfoIcon />}
									onClick={() =>
										this.setState((c) => ({
											showTrackingModal: !c.showTrackingModal,
										}))
									}
								/>
							)}
						</div>
						<Collapsible open={this.state.showOptionalAttendees}>
							<div
								style={{
									display: "flex",
									gap: "1.2500rem",
									marginTop: "0.6250rem",
									minHeight: "32px",
								}}
							>
								<span className="calendar_modal__body__field__label">
									{this.props.t("calendar.fields.optional_attendees.label", "Valfria deltagare")}
								</span>
								<AttendeesInput
									external={this.props.external}
									onChange={this.onChange.bind(this, "attendees")}
									attendees={form.attendees}
									style={{ flex: 1 }}
									column={this.state.timeLineColumn}
									form={form}
									editable={editable}
									// contacts={form.external_event && form.external_event.attendees}
								/>
							</div>
						</Collapsible>
					</div>
				),
				disabled: !editable,
				enabled: BoardHelper.getColumnIntegrationActive(this.state.board),
			},
			{
				label: this.props.t("calendar.fields.sensitivity.label", "Känslighet"),
				component: (
					<CalendarSelect
						value={form.sensitivity}
						items={[
							{
								value: "normal",
								label: this.props.t("calendar.fields.sensitivity.options.normal", "Normal"),
							},
							{
								value: "personal",
								label: this.props.t("calendar.fields.sensitivity.options.personal", "Personlig"),
							},
							{
								value: "private",
								label: this.props.t("calendar.fields.sensitivity.options.private", "Privat"),
							},
							{
								value: "confidential",
								label: this.props.t("calendar.fields.sensitivity.options.confidential", "Konfidentiell"),
							},
						]}
						onChange={this.onChange.bind(this, "sensitivity")}
						style={{ width: "max-content" }}
					/>
				),
				disabled: !editable,
				enabled: this.isExternalOutlookCalendar(),
			},
			{
				label: this.props.t("calendar.fields.sensitivity.label", "Känslighet"),
				component: (
					<CalendarSelect
						value={form.visibility}
						items={[
							{
								value: "default",
								label: this.props.t("calendar.fields.sensitivity.options.normal", "Normal"),
							},
							{
								value: "public",
								label: this.props.t("calendar.fields.sensitivity.options.public", "Publik"),
							},
							{
								value: "private",
								label: this.props.t("calendar.fields.sensitivity.options.private", "Privat"),
							},
							{
								value: "confidential",
								label: this.props.t("calendar.fields.sensitivity.options.confidential", "Konfidentiell"),
							},
						]}
						onChange={this.onChange.bind(this, "visibility")}
						style={{ width: "max-content" }}
					/>
				),
				disabled: !editable,
				enabled: this.isExternalGoogleCalendar(),
			},
			{
				label: this.props.t("calendar.fields.reminderMinutesBeforeStart.label", "Påminnelse"),
				component: (
					<LegacyStack>
						<CalendarSelect
							value={form.reminderMinutesBeforeStart || ""}
							items={[
								{
									label: this.props.t("calendar.fields.options.none", "Ingen påminnelse"),
									value: "",
								},
								{
									label: this.props.t("calendar.fields.options.0", "Vid tillfället"),
									value: "0",
								},
								{
									label: this.props.t("calendar.fields.options.5", "5 minuter innan"),
									value: "5",
								},
								{
									label: this.props.t("calendar.fields.options.15", "En kvart innan"),
									value: "15",
								},
								{
									label: this.props.t("calendar.fields.options.30", "En halvtimme innan"),
									value: "30",
								},
								{
									label: this.props.t("calendar.fields.options.60", "En timme innan"),
									value: "60",
								},
								{
									label: this.props.t("calendar.fields.options2.120", "Två timmar innan"),
									value: "120",
								},
								{
									label: this.props.t("calendar.fields.options2.180", "Tre timmar innan"),
									value: "180",
								},
								{
									label: this.props.t("calendar.fields.options.1440", "Ett dygn innan"),
									value: "1440",
								},
								{
									label: this.props.t("calendar.fields.options.10080", "En vecka innan"),
									value: "10080",
								},
							]}
							onChange={this.onChange.bind(this, "reminderMinutesBeforeStart")}
							style={{ width: "max-content" }}
						/>
						{form.reminderMinutesBeforeStart && (
							<CalendarSelect
								label={this.props.t("calendar.fields.priority.label", "Typ av notis")}
								// icon={NotificationMajor}
								value={form.priority}
								items={[
									{
										value: "low",
										label: this.props.t("calendar.fields.priority.options.low", "Inne i CRM:et"),
									},
									{
										value: "normal",
										label: this.props.t("calendar.fields.priority.options.normal", "Notifikation"),
									},
									{
										value: "high",
										label: this.props.t("calendar.fields.priority.options.high", "Notifikation och email"),
									},
								]}
								onChange={this.onChange.bind(this, "priority")}
								style={{ width: "max-content" }}
							/>
						)}
					</LegacyStack>
				),
				disabled: !editable,
			},
			{
				label: <img alt="teams" src="/assets/images/teams.png" style={{ verticalAlign: "middle", height: "20px" }} />,
				component: (
					<Switch height={22} onChange={this.onChange.bind(this, "isOnlineMeeting")} checked={!!form.isOnlineMeeting} onColor={Colors.accent} />
				),
				disabled: !editable || form?.external_event,
				enabled: CalendarHelper.isEditable(form) && this.isExternalOutlookCalendar(),
			},

			{
				label: <img alt="teams" src="/assets/images/teams.png" style={{ verticalAlign: "middle", height: "20px" }} />,
				component: (
					<Switch
						height={22}
						onChange={this.onToggleAddGoogleMeeting.bind(this)}
						checked={!!form?.external_event?.conferenceData?.createRequest?.requestId}
						onColor={Colors.accent}
					/>
				),
				disabled: !editable,
				enabled: form?.external_event?.id && this.isExternalGoogleCalendar(),
			},
			{
				label: this.props.t("calendar.fields.location.label", "Plats"),
				component: (
					<SearchField
						keepValue
						onChange={this.onChange.bind(this, "location")}
						value={form.location}
						prefix={null}
						resource="google_address"
						onSelect={this.onChange.bind(this, "location")}
						id_handle="id"
						label_handle="title"
						resourceName={{ singular: "adress", plural: "adresser" }}
					/>
				),
				disabled: !editable,
				// enabled: this.isExternalOutlookCalendar() || this.isExternalGoogleCalendar(),
			},
			{
				label: this.props.t("calendar.fields.deal.label", "Affär"),
				component: (
					<SearchField
						keepValue
						value={this.getDeal()?.title}
						prefix={null}
						resource="board_rows/search.json"
						params={{
							board_id: store.getState().account.sales_board_id,
							contact_ids: form.attendees.map((c) => c?.contact_id).filter(Boolean),
							assigned_user_ids: form.attendees.map((c) => c?.user_id).filter(Boolean),
						}}
						onSelect={(row) => {
							store.dispatch({
								type: "UPDATE_BOARD_LINKED_ROW",
								linked_row: row,
							});
							this.onChange("deal", row);
						}}
						id_handle="id"
						label_handle="title"
						resource_handle="rows"
						resourceName={{
							singular: this.props.t("row.deal.singular", "affär"),
							plural: this.props.t("row.deal.plural", "affärer"),
						}}
					/>
				),
				disabled: !editable,
				enabled: store.getState().account.sales_board_id && store.getState().workspaces?.find((w) => w.title === "Försäljning"),
			},
			/*
			{
				label: "Prioritet",
				component: (
					<CalendarSelect
						value={form.importance || "normal"}
						items={[
							{ value: "low", label: "Låg" },
							{ value: "normal", label: "Normal" },
							{ value: "high", label: "Hög" },
						]}
						onChange={this.onChange.bind(this, "importance")}
						style={{ width: "max-content" }}
					/>
				),
				enabled: this.isExternalOutlookCalendar(),
				disabled: !editable,
			},
*/
			{
				label: <img alt="teams" src="/assets/images/teams.png" />,
				component: (
					<PreviewButtonAsLink href={CalendarHelper.getTeamsMeetingUrl(form)} target="_blank" style={{ maxWidth: "max-content" }}>
						{this.props.t("calendar.fields.teams.connect", "Anslut till Teams möte")}
					</PreviewButtonAsLink>
				),
				enabled: CalendarHelper.getTeamsMeetingUrl(form),
			},
			{
				label: this.props.t("calendar.fields.respond.label", "Svara"),
				component: (
					<div className="calendar_modal__body__answer_buttons_wrapper">
						{this.state.showResponseButtons ? (
							<React.Fragment>
								<CalendarButton
									label={this.props.t("calendar.fields.respond.options.accept.label", "Acceptera")}
									icon={MobileAcceptMajor}
									className={`accept ${CalendarHelper.getMyResponse(form) === "accepted" ? "active" : ""}`}
									items={[
										{
											content: this.props.t("calendar.fields.respond.options.accept.action", "Acceptera"),
											onAction: this.respondToEmail.bind(this, "accept"),
										},
										{
											content: this.props.t("calendar.fields.respond.options.accept.aciton_with_message.action", "Acceptera med meddelande"),
											onAction: () => {
												this.setState({
													responseModalIsOpen: true,
													action: "accept",
													showResponseComment: true,
												});
											},
										},
									]}
								/>
								<CalendarButton
									label={this.props.t("calendar.fields.respond.options.maybe.label", "Preliminärt")}
									icon={QuestionMarkInverseMajor}
									className={`pre ${CalendarHelper.getMyResponse(form) === "tentativelyAccepted" ? "active" : ""}`}
									items={[
										{
											content: this.props.t("calendar.fields.respond.options.maybe.action", "Preliminärt"),
											onAction: this.respondToEmail.bind(this, "maybe"),
										},
										{
											content: this.props.t("calendar.fields.respond.options.maybe.action_with_message", "Preliminärt med meddelande"),
											onAction: () => {
												this.setState({
													responseModalIsOpen: true,
													action: "maybe",
													showResponseComment: true,
												});
											},
										},
									]}
								/>

								<CalendarButton
									label={this.props.t("calendar.fields.respond.options.decline.label", "Tacka nej")}
									icon={MobileCancelMajor}
									className={`decline ${CalendarHelper.getMyResponse(form) === "declined" ? "active" : ""}`}
									items={[
										{
											content: this.props.t("calendar.fields.respond.options.decline.action", "Neka"),
											onAction: this.respondToEmail.bind(this, "decline"),
										},
										{
											content: this.props.t("calendar.fields.respond.options.decline.action_with_message", "Neka med meddelande"),
											onAction: () => {
												this.setState({
													responseModalIsOpen: true,
													action: "decline",
													showResponseComment: true,
												});
											},
										},
									]}
								/>

								<CalendarButton
									label={this.props.t("calendar.fields.respond.options.request_new_time.label", "Föreslå ny tid")}
									icon={ClockMajor}
									className="new_time"
									items={[
										{
											content: this.props.t("calendar.fields.respond.options.request_new_time.action", "Preliminärt neka och föreslå ny tid"),
											onAction: () =>
												this.setState({
													responseModalIsOpen: true,
													action: "maybe",
													showDateTimePicker: true,
													responseTimeStart: form.start,
													responseTimeEnd: form.end,
												}),
										},
										{
											content: this.props.t("calendar.fields.respond.options.request_new_time.action_with_message", "Neka och föreslå ny tid"),
											onAction: () =>
												this.setState({
													responseModalIsOpen: true,
													action: "decline",
													showDateTimePicker: true,
													responseTimeStart: form.start,
													responseTimeEnd: form.end,
												}),
										},
									]}
								/>
							</React.Fragment>
						) : (
							<React.Fragment>
								<span>Du har {CalendarHelper.getInlineStatusLabel(CalendarHelper.getMyResponse(form))} </span>{" "}
								<span onClick={() => this.setState({ showResponseButtons: true })}>Ändra svar</span>
							</React.Fragment>
						)}
					</div>
				),
				enabled: repliable && (this.isExternalOutlookCalendar() || this.isExternalGoogleCalendar()),
			},
		].filter((field) => !("enabled" in field) || field.enabled);

		return (
			<div className="calendar_modal">
				{/* <div className="calendar_modal__header">
					<div className="calendar_modal__header__buttons_wrapper" />
				</div> */}
				<Modal
					open={this.state.removeModalIsOpen}
					onClose={() => this.setState({ removeModalIsOpen: false })}
					title={
						// eslint-disable-next-line quotes
						this.props.t("calendar.modals.remove.title", 'Ta bort {{singular}} "{{title}}"', {
							singular: this.props.board.singular,
							title: this.state.row.title,
						})
					}
				>
					<Modal.Section>
						<BringModalToFront />
						{/* eslint-disable-next-line quotes */}
						{this.props.t("calendar.modals.remove.confirm", 'Är du säker du vill ta bort {{singular}} "{{title}}"?', {
							singular: this.props.board.singular,
							title: this.state.row.title,
						})}
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "0.6250rem",
							}}
						>
							<Button destructive onClick={this.removeRow.bind(this, false, this.props.row)} loading={this.state.removing}>
								{this.props.t("common.actions.remove", "Ta bort")}
							</Button>
							<Button onClick={() => this.setState({ removeModalIsOpen: false })}>{this.props.t("common.actions.cancel", "Avbryt")}</Button>
						</div>
					</Modal.Section>
				</Modal>
				<div className="calendar_modal__body">
					{/* <div className="calendar_modal_fields_and_send_button_wrapper">
						{!repliable && (
							<div className="calendar_modal__body__send_button" onClick={this.onSend.bind(this)}>
								{this.state.saving ? <Spinner size="small" /> : <Send2Icon />}
								<span>Skicka</span>
							</div>
						)}

					</div> */}
					<div className="calendar_modal__body__content">
						{fields.map((field, index) => (
							<Field key={index} field={field} />
						))}
					</div>

					<div className="calendar_modal__email__content" />
					{(this.isExternalOutlookCalendar() || this.isExternalGoogleCalendar()) && (
						<RTE
							readOnly={!editable}
							placeholder={this.props.t("calendar.fields.content.placeholder", "Skriv ditt meddelande här..")}
							value={form.content}
							onChange={this.onChange.bind(this, "content")}
						/>
					)}

					<div
						style={{
							display: "flex",
							justifyContent: "end",
							alignItems: "center",
							gap: "0.5000rem",
						}}
					>
						{this.getHeaderButtons().map(
							({ label, icon, onClick, loading, disabled, active, color = Colors.grey, items, component, primary, destructive }, index) => (
								<CalendarModalHeaderButton
									key={index}
									label={label}
									icon={icon}
									onClick={onClick}
									loading={loading}
									disabled={disabled}
									active={active}
									color={color}
									items={items}
									primary={primary}
									destructive={destructive}
								/>
							)
						)}
					</div>
				</div>
				<div onClick={(e) => e.stopPropagation()}>
					<Modal
						open={this.state.responseModalIsOpen}
						onClose={this.onCloseResponseModal.bind(this)}
						title={(() => {
							let caption = "";
							if (this.state.action === "decline") {
								caption = this.props.t("calendar.actions.decline", "Neka");
							}
							if (this.state.action === "accept") {
								caption = this.props.t("calendar.actions.accept", "Acceptera");
							}
							if (this.state.action === "maybe") {
								caption = this.props.t("calendar.actions.tentative", "Preliminärt");
							}

							if (this.state.showResponseComment) {
								caption += ` ${this.props.t("calendar.actions.with_comment", "med kommentar")}`;
								caption += " ";
							}

							if (this.state.showDateTimePicker) {
								caption += ` ${this.props.t("calendar.actions.with_request_new_time", "och föreslå ny tid")}`;
								caption += " ";
							}

							return caption;
						})()}
						primaryAction={{
							content: this.props.t("common.actions.send", "Skicka"),
							onAction: this.respondToEmail.bind(this, this.state.action),
							loading: this.state.loadingResponse,
						}}
						secondaryActions={[
							{
								content: this.props.t("common.actions.cancel", "Avbryt"),
								onAction: this.onCloseResponseModal.bind(this),
							},
						]}
					>
						<Modal.Section>
							<BringModalToFront />
							{this.state.showDateTimePicker && (
								<div className="prupose_new_time_modal_times">
									<div
										style={{
											display: "flex",
											gap: "1.2500rem",
											margin: "0.6250rem 0",
											alignItems: "center",
										}}
									>
										<span>{this.props.t("calendar.terms.starttime", "Startid")}: </span>
										<DateTimePicker value={this.state.responseTimeStart} icon="" onChange={this.onChangeState.bind(this, "responseTimeStart")} />
									</div>
									<div
										style={{
											display: "flex",
											gap: "1.2500rem",
											margin: "0.6250rem 0",
											alignItems: "center",
										}}
									>
										<span>{this.props.t("calendar.terms.endtime", "Sluttid")}: </span>
										<DateTimePicker value={this.state.responseTimeEnd} icon="" onChange={this.onChangeState.bind(this, "responseTimeEnd")} />
									</div>
								</div>
							)}
							{this.state.showResponseComment && (
								<TextField
									placeholder={this.props.t("calendar.fields.comment.placeholder", "kommentar..")}
									onChange={this.onChangeState.bind(this, "responseComment")}
									value={this.state.responseComment}
								/>
							)}
						</Modal.Section>
					</Modal>
					<Modal
						open={this.state.showTrackingModal}
						onClose={() => {
							this.setState({ showTrackingModal: false });
						}}
						title={this.props.t("calendar.terms.tracking", "Spårning")}
						secondaryActions={[
							{
								content: this.props.t("common.actions.close", "Stäng"),
								onAction: () => this.setState({ showTrackingModal: false }),
							},
						]}
					>
						<Modal.Section>
							<BringModalToFront />
							<CalendarModalAttendeesListContent attendees={form.attendees} form={form} column={this.state.timeLineColumn} />
						</Modal.Section>
					</Modal>
					<UploadModal
						open={this.state.uploadModalIsOpen}
						onClose={() => {
							this.setState({ uploadModalIsOpen: false });
						}}
						returnBase64
						allowMultiple
						files={form && form.attachments}
						onSelect={(attachments) => {
							// form.attachments = [].concat(form.attachments || [], attachments);
							form.attachments = attachments;
							// this.state.form.attachments = this.state.form.attachments;

							this.state.row.column_values[this.state.timeLineColumn.id].value = form;
							this.setState({
								form: this.state.form,
								row: this.state.row,
								uploadModalIsOpen: false,
							});
						}}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	boards: state.boards,
	// rows: state.board_rows,
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
	board_linked_rows: state.board_linked_rows,
});

export default connect(mapStateToProps)(withTranslation(["calendar", "row", "common"], { withRef: true })(CalendarModalContent));
