import React from "react";
import { currencyFormatter } from "src/js/Utilities";
import styled, { css } from "styled-components";

type ContractRowProps = {
	contract: FortnoxContractType;
	row: FortnoxInvoiceRowType;
	licenseProvider: any;
	onClick?: any;
	selected?: boolean;
};

const ContractRow = ({ contract, row, licenseProvider, onClick, selected }: ContractRowProps) => {
	const licenseQuantity = parseInt(row.DeliveredQuantity as string);
	return (
		<Wrapper onClick={onClick} selected={selected}>
			<td>{row.ArticleNumber}</td>
			<td>{row.Description}</td>
			<td>
				{row.DeliveredQuantity} {licenseProvider && `(${licenseProvider.quantity * licenseQuantity} användare)`}
			</td>
			<td>
				{currencyFormatter({
					value: row.Price * licenseQuantity,
				})}
			</td>
		</Wrapper>
	);
};
export default ContractRow;

const Wrapper = styled.tr<{ onClick?: any; selected?: boolean }>`
	background-color: var(--main2);
	transition: background-color 0.3s;

	${(props) =>
		props.onClick &&
		css`
			cursor: pointer;
		`}

	${(props) =>
		props.selected &&
		css`
			background-color: var(--main3);
		`}



	&:hover {
		background-color: var(--main3);
	}
`;
