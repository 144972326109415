import React, { useMemo } from "react";
import { LoadingBox, FlexDiv } from "./styles";
import SkeletonPage from "./Page";

const SkeletonBoard = ({ noheader }) => {
	const windowWidth = window.innerWidth - (document.querySelector(".Polaris-TopBar") ? 240 : 0);
	const titleWidth = Math.round(windowWidth / 3) - 60;
	const rows = Math.floor((window.innerHeight - 400) / 36 / 2);

	const secondaryActionsWithWidths = useMemo(() => {
		return [...Array(5)].map((x, index) => ({
			width: Math.floor(Math.random() * 100) + 100,
		}));
	}, []);

	const columnsWithWidths = useMemo(() => {
		const columns = Math.floor((windowWidth - titleWidth) / 200);

		return [...Array(columns)].map((x, index) => ({
			width: Math.floor(Math.random() * 100) + 50,
		}));
	}, [windowWidth, titleWidth]);

	return (
		<SkeletonPage
			header={
				noheader ? null : (
					<>
						<FlexDiv gap={10} margin={14} style={{ marginLeft: 0 }}>
							<LoadingBox width={30} height={30} />
							<LoadingBox width={150} height={30} />
						</FlexDiv>

						<FlexDiv gap={10} margin={14} style={{ marginLeft: 0 }}>
							{secondaryActionsWithWidths.map((action, index) => (
								<LoadingBox key={index} width={action.width} height={30} />
							))}
						</FlexDiv>

						<LoadingBox width={"100%"} style={{ padding: 10, opacity: 0.75 }}>
							<FlexDiv gap={10} margin={14}>
								{/* primaryAction */}
								<LoadingBox width={100} height={30} loading={false} style={{ background: "var(--tertiary)" }} />
								{/* serchbar */}
								<LoadingBox width={"100%"} height={30} loading={false} style={{ background: "var(--tertiary)" }} />
								{/* buttons */}
								<LoadingBox width={100} height={30} loading={false} style={{ background: "var(--tertiary)" }} />
							</FlexDiv>
						</LoadingBox>
					</>
				)
			}
		>
			<>
				<FlexDiv style={{ margin: "15px 0 11px", gap: 5 }} height={30}>
					{/* group */}
					<LoadingBox width={titleWidth} loading={false} style={{ background: "none" }}>
						<LoadingBox width={100} height={30} style={{ background: "var(--tertiary)" }} loading={false} />
					</LoadingBox>

					{columnsWithWidths?.map((col, index) => (
						<LoadingBox key={index} width={col.width} height={"100%"} loading={false} style={{ background: "var(--tertiary)" }} />
					))}
				</FlexDiv>

				{[...Array(rows)]?.map((x, index) => (
					<LoadingBox key={index} height={36} style={{ marginBottom: 1, opacity: 0.75 }} loading={false}>
						<FlexDiv height={"100%"} style={{ alignItems: "center", gap: 5 }}>
							{/* title */}
							<LoadingBox width={titleWidth} loading={false} style={{ background: "none" }}>
								<LoadingBox width={100} height={21} />
							</LoadingBox>

							{columnsWithWidths?.map((col, index) => (
								<LoadingBox key={index} width={col.width} height={"80%"} loading={false} style={{ background: "var(--tertiary)", opacity: 0.75 }} />
							))}
						</FlexDiv>
					</LoadingBox>
				))}
			</>
		</SkeletonPage>
	);
};

export default React.memo(SkeletonBoard);
