import React from "react";

const ContactPeopleIcon = ({ color = "var(--textColor)" }) => (
	// eslint-disable-next-line react/no-unknown-property
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" filltype="pathfill" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.75 6.5C6.47183 6.5 4.625 8.34683 4.625 10.625C4.625 12.9032 6.47183 14.75 8.75 14.75C11.0282 14.75 12.875 12.9032 12.875 10.625C12.875 8.34683 11.0282 6.5 8.75 6.5ZM3.125 10.625C3.125 7.5184 5.6434 5 8.75 5C11.8566 5 14.375 7.5184 14.375 10.625C14.375 13.7316 11.8566 16.25 8.75 16.25C5.6434 16.25 3.125 13.7316 3.125 10.625Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.3883 5.00001L16.3906 5C17.8824 5 19.3132 5.59264 20.3681 6.64753C21.423 7.70242 22.0156 9.13316 22.0156 10.625C22.0156 12.1168 21.423 13.5476 20.3681 14.6025C19.3132 15.6574 17.8824 16.25 16.3906 16.25C15.9764 16.25 15.6406 15.9142 15.6406 15.5C15.6406 15.0858 15.9764 14.75 16.3906 14.75C17.4846 14.75 18.5338 14.3154 19.3074 13.5418C20.081 12.7682 20.5156 11.719 20.5156 10.625C20.5156 9.53099 20.081 8.48178 19.3074 7.70819C18.5341 6.93487 17.4854 6.5003 16.3917 6.5C16.0112 6.50126 15.6324 6.55235 15.2651 6.65198C14.8653 6.7604 14.4533 6.52422 14.3449 6.12445C14.2365 5.72468 14.4726 5.3127 14.8724 5.20428C15.3666 5.07024 15.8763 5.00157 16.3883 5.00001Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.75 16.2497C7.54644 16.2497 6.36055 16.5393 5.29251 17.0942C4.22447 17.649 3.30564 18.4528 2.61364 19.4375C2.37548 19.7764 1.90768 19.8581 1.56878 19.6199C1.22988 19.3818 1.14821 18.914 1.38637 18.5751C2.21677 17.3934 3.31936 16.4289 4.60101 15.7631C5.88266 15.0973 7.30573 14.7497 8.75 14.7497C10.1943 14.7497 11.6173 15.0973 12.899 15.7631C14.1806 16.4289 15.2832 17.3934 16.1136 18.5751C16.3518 18.914 16.2701 19.3818 15.9312 19.6199C15.5923 19.8581 15.1245 19.7764 14.8864 19.4375C14.1944 18.4528 13.2755 17.649 12.2075 17.0942C11.1395 16.5393 9.95357 16.2497 8.75 16.2497Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.8486 17.0933C18.7806 16.5385 17.5946 16.2493 16.3911 16.25C15.9769 16.2503 15.6409 15.9147 15.6406 15.5005C15.6404 15.0863 15.9759 14.7503 16.3902 14.75C17.8347 14.7491 19.2582 15.0963 20.5401 15.7622C21.822 16.4281 22.9246 17.393 23.7545 18.5754C23.9925 18.9144 23.9105 19.3821 23.5715 19.6201C23.2325 19.8581 22.7647 19.7762 22.5268 19.4371C21.8353 18.4521 20.9167 17.6481 19.8486 17.0933Z"
			fill={color}
		/>
	</svg>
);

export default ContactPeopleIcon;
