import React, { Component } from "react";

export default class IntegrationConnected extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (window.opener) {
			window.opener.postMessage({ source: window.name, payload: Object.fromEntries(new URLSearchParams(window.location.search)) });
			window.close();
		} else {
			window.location.href = "/admin/dashboard/mine";
		}
	}

	render() {
		return <div />;
	}
}
