import React, { FC, useCallback, useEffect, useImperativeHandle } from "react";
import ReactQuill, { Quill, Value } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

const block = Quill.import("blots/block");
block.tagName = "DIV";
Quill.register(block, true);

Quill.register("modules/imageResize", ImageResize);

type SellfinityQuillProps = {
	onChange: (value: string) => void;
	value?: Value;
	theme?: string;
	ref?: any;
};

const Toolbar = () => (
	<div id="toolbar">
		<select className="ql-header" defaultValue="" onChange={(e) => e.persist()}>
			<option value="1" />
			<option value="2" />
			<option selected />
		</select>
		<button className="ql-bold" />
		<button className="ql-italic" />
		<button className="ql-underline" />
		<button className="ql-strike" />
		<button className="ql-blockquote" />
		<select className="ql-color">
			<option value="#000000" />
			<option value="#e60000" />
			<option value="#ff9900" />
			<option value="#ffff00" />
			<option value="#008a00" />
			<option value="#0066cc" />
			<option value="#9933ff" />
			<option value="#ffffff" />
			<option value="#facccc" />
			<option value="#ffebcc" />
			<option value="#ffffcc" />
			<option value="#cce8cc" />
			<option value="#cce0f5" />
			<option value="#ebd6ff" />
			<option value="#bbbbbb" />
			<option value="#f06666" />
			<option value="#ffc266" />
			<option value="#ffff66" />
			<option value="#66b966" />
			<option value="#66a3e0" />
			<option value="#c285ff" />
			<option value="#888888" />
			<option value="#a10000" />
			<option value="#b26b00" />
			<option value="#b2b200" />
			<option value="#006100" />
			<option value="#0047b2" />
			<option value="#6b24b2" />
			<option value="#444444" />
			<option value="#5c0000" />
			<option value="#663d00" />
			<option value="#666600" />
			<option value="#003700" />
			<option value="#002966" />
			<option value="#3d1466" />
		</select>
		<select className="ql-background" />
		<select className="ql-font" defaultValue="" onChange={(e) => e.persist()} />
		<select className="ql-size" defaultValue="" onChange={(e) => e.persist()}>
			<option value="extra-small" />
			<option value="small" />
			<option value="medium" />
			<option value="large" />
			<option value="extra-large" />
			<option selected />
		</select>
		<select className="ql-align" defaultValue="" onChange={(e) => e.persist()}>
			<option value="center" />
			<option value="right" />
			<option value="justify" />
			<option selected />
		</select>
		<button className="ql-list" value="ordered" />
		<button className="ql-list" value="bullet" />
		<button className="ql-script" value="sub" />
		<button className="ql-direction" value="rtl" />
		<button className="ql-indent" value="-1" />
		<button className="ql-indent" value="+1" />
		<button className="ql-formula" />
		<button className="ql-link" />
		<button className="ql-image" />
		<button className="ql-video" />
		<button className="ql-clean" />
	</div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Formats = [
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"code-block",
	"header",
	"list",
	"indent",
	"size",
	"header",
	"color",
	"background",
	"font",
	"align",
	"clean",
	"link",
	"image",
	"video",
	"direction",
	"formula",
] as string[];

const Modules = {
	toolbar: {
		container: "#toolbar",
	},
	imageResize: {
		parchment: Quill.import("parchment"),
		modules: ["Resize", "DisplaySize"],
	},
};

const SellfinityQuill: FC<SellfinityQuillProps> = React.forwardRef(({ onChange, value, theme }, ref) => {
	const quill = React.createRef<ReactQuill>();

	useImperativeHandle(ref, () => ({
		focus: () => {
			if (quill.current) {
				quill.current.focus();
			}
		},
	}));

	const onClick = useCallback(() => {
		if (quill.current) {
			quill.current.focus();
		}
	}, [quill]);

	useEffect(() => {
		const container = document.querySelector(".ql-container");

		if (container) {
			container.addEventListener("click", onClick);
		}
		return () => {
			if (container) {
				container.removeEventListener("click", onClick);
			}
		};
	}, [onClick]);

	return (
		<div className="sellfinity-quill text-editor">
			<Toolbar />
			<ReactQuill ref={quill} value={value} onChange={onChange} theme={theme || "snow"} modules={Modules} />
		</div>
	);
});

export default SellfinityQuill;
