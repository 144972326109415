import { Button, Text } from "@shopify/polaris";
import { MobileCancelMajor, PinMajor, PinUnfilledMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";

class SheetHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { isPinned, toggleIsPinned } = this.props;

		return (
			<>
				<div
					style={{
						alignItems: "center",
						borderBottom: "1px solid #DFE3E8",
						display: "flex",
						justifyContent: "space-between",
						padding: "1.0000rem",
						width: "100%",
						...(this.props.style || {}),
					}}
				>
					<Text variant="headingMd" as="h2">
						{this.props.title}
					</Text>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							gap: "1.8750rem",
						}}
					>
						{toggleIsPinned && <Button onClick={toggleIsPinned} icon={isPinned ? PinMajor : PinUnfilledMajor} plain />}
						<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.props.onClose} plain />
					</div>
				</div>
				{this.props.children}
			</>
		);
	}
}
export default SheetHeader;
