import React, { useState } from "react";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { Icon, Spinner } from "@shopify/polaris";
import { WithTranslation, withTranslation } from "react-i18next";
import Truncate from "../Truncate";
import { StyledFloatingActionBar as FABar } from "./styles";

type Action = {
	icon?: React.JSX.Element;
	label?: React.ReactNode;
	onAction: () => Promise<any>;
	// loading?: boolean;
	color?: string;
};

type FloatingActionBarProps = {
	open: boolean;
	actions?: Action[];
	count?: number | null;
	onClose?: () => void;
} & WithTranslation;

type FloatingAction = {
	action: Action;
};

const FloatingAction = ({ action }: FloatingAction) => {
	const [loading, setLoading] = useState(false);
	const { icon, label, onAction, color } = action;

	const onClick = async () => {
		setLoading(true);
		await onAction();
		setLoading(false);
	};

	return (
		<FABar.Action color={color} onClick={onClick}>
			<FABar.Icon>{loading ? <Spinner size="small" /> : icon}</FABar.Icon>

			<Truncate numberOfLines={2}>{label}</Truncate>
		</FABar.Action>
	);
};

const FloatingActionBar = ({ open, actions, count, onClose, t }: FloatingActionBarProps) => {
	return (
		<FABar open={open}>
			{typeof count === "number" ? <FABar.Counter>{count}</FABar.Counter> : null}

			{!!actions?.length && (
				<FABar.ActionsContainer>
					{actions?.map((action, index) => {
						return <FloatingAction key={index} action={action} />;
					})}
				</FABar.ActionsContainer>
			)}

			<FABar.Cancel onClick={onClose}>
				<FABar.Icon>
					<Icon source={MobileCancelMajor} />
				</FABar.Icon>

				{t("common.actions.cancel", "Avbryt")}
			</FABar.Cancel>
		</FABar>
	);
};
export default withTranslation(["floatingActionBar", "common"])(FloatingActionBar);
