import { store } from "../store";

interface NumberFormatterOptions {
	value: number;
	currency?: string;
	locale?: string;
	minimumFractionDigits?: number;
	maximumFractionDigits?: number;
	style?: "currency" | "decimal" | "percent";
	removeCurrencySymbol?: boolean;
	notation?: "standard" | "scientific" | "engineering" | "compact" | undefined;
}

const numberFormatter = ({
	value,
	currency = store.getState().account.currency || "SEK",
	locale = store.getState().user.locale || "se-SV",
	minimumFractionDigits = 0,
	maximumFractionDigits = 2,
	style = "currency",
	removeCurrencySymbol = false,
	notation,
}: NumberFormatterOptions): string => {
	if ((!value && typeof value !== "number") || !Number.isFinite(parseFloat(String(value))) || Number.isNaN(value)) {
		return "-";
	}

	const formatter = new Intl.NumberFormat(locale, {
		style,
		currency,
		minimumFractionDigits,
		maximumFractionDigits,
		// minimumSignificantDigits: 21,
		// maximumSignificantDigits: 4,
		currencyDisplay: "narrowSymbol",
		notation,
	});

	const parts = formatter.formatToParts(
		parseFloat(
			String(value)
				.replaceAll(/\s/g, "")
				.replace(/,([^,]*)$/, ".$1")
		)
	);

	if (style === "currency" && currency === "SEK" && !removeCurrencySymbol && parts[0].type === "currency") {
		const currencyPart = parts.shift();
		currencyPart!.value = " kr";
		parts.push(currencyPart as any);
	}

	const formattedNumber = parts
		.map((part) => {
			switch (part.type) {
				case "integer":
					// return part.value.replace(/\B(?=(\d{3})+(?!\d))/g, " "); // apply grouping
					return part.value;
				case "group":
					if (locale === "se-SV") {
						return " ";
					}
					return part.value;
				case "decimal":
					if (locale === "se-SV") {
						return ",";
					}
					return part.value;
				case "currency":
					if (removeCurrencySymbol) return "";
					return part.value;
				// case "compact":
				// 	return notationCompactTranslations(locale, part.value);
				case "fraction":
				default:
					return part.value;
			}
		})
		.reduce((acc, curr) => acc + curr, "");

	return formattedNumber.trim();
};

export default numberFormatter;

// const notationCompactTranslations = (locale, txt) => {
// 	switch (locale) {
// 		case "se-SV":
// 		case "sv":
// 			return txt
// 				.replace(/\btn\b/g, "t") // replace 'tn' with 't'
// 				.replace(/\bmn\b/g, "milj") // replace 'mn' with 'milj'
// 				.replace(/\bmd\b/g, "milja"); // replace 'md' with 'milja'
// 		default:
// 			return txt;
// 	}
// };
