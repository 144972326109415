import cloneDeep from "lodash/cloneDeep";
import Colors from "src/js/Colors";

const handleFormatSeries = ({ series, settings }) => {
	let seriesCopy = cloneDeep(series);

	if (settings.grouped) {
		const data = mergeArrays(...seriesCopy.map((s) => s.data));
		seriesCopy = [
			{
				data,
				name: "Total",
				color: seriesCopy?.[0]?.color || Colors.green,
			},
		];
	}

	return seriesCopy;
};
export default handleFormatSeries;

const mergeArrays = (...arrays) => {
	return arrays.reduce(
		(acc, curr) => {
			return acc.map((value, index) => value + (curr[index] || 0));
		},
		Array.from({ length: Math.max(...arrays.map((arr) => arr.length)) }, () => 0)
	);
};
