import React, { Component } from "react";
import { ActionList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import MyPopover from "../../components/Popover.js";

class SimpleCellOptions extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false, value: props.value };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value });
	}

	selectOption(opt) {
		this.setState({ open: false, value: opt.value });
		this.props.onChange(opt.value);
	}

	render() {
		const currentOption = this.props.options.find((opt) => opt.value + "" == this.state.value + "");
		const options = this.props.options.map((opt) => ({
			content: (
				<span className="Polaris-Badge Polaris-Badge--sizeSmall" style={{ backgroundColor: opt.color }}>
					<span className="Polaris-Badge__Content" style={{ color: "white" }}>
						{opt.label}
					</span>
				</span>
			),
			onAction: this.selectOption.bind(this, opt),
		}));

		if (this.props.allowNull) {
			options.unshift({ content: this.props.t("common.terms.no_value", "<inget värde>"), onAction: this.selectOption.bind(this, { value: null }) });
		}
		return (
			<MyPopover
				active={this.state.open}
				activator={
					<span
						onClick={(e) => {
							e.stopPropagation();
							this.setState({ open: !this.state.open });
						}}
					>
						{currentOption ? (
							<span className="Polaris-Badge Polaris-Badge--sizeSmall" style={{ backgroundColor: currentOption.color }}>
								<span className="Polaris-Badge__Content" style={{ color: "white" }}>
									{currentOption.label}
								</span>
							</span>
						) : (
							"–"
						)}
					</span>
				}
				onClose={() => {
					this.setState({ open: false });
				}}
			>
				<div
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<ActionList items={options} />
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(SimpleCellOptions);
