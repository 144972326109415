import React, { Component } from "react";
import { FormLayout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import FieldSelector from "./FieldSelector.js";
import RelationshipSelector from "./RelationshipSelector.js";
import Modal from "src/js/components/modal";

class ChangeRelationshipModal extends Component {
	constructor(props) {
		super(props);
		this.state = { relationship: "none", field: store.getState().fields[0] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.open) {
			this.setState({ relationship: "none", field: store.getState().fields[0] });
		}
	}

	confirm() {
		this.props.onConfirm(this.state.relationship, this.state.field);
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("relationship.modals.change.title", "Ändra relation på {{counts}} kontakter", {
					counts: this.props.numItems,
				})}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.change", "Ändra"),
					loading: this.props.loading,
					onAction: this.confirm.bind(this),
				}}
			>
				<Modal.Section>
					<FormLayout>
						{store.getState().fields.length > 1 ? (
							<FieldSelector
								label={(this.props.t("relationship.modals.change.fields.field.label"), "Affärsområde")}
								required
								field={this.state.field}
								onChange={(field) => {
									this.setState({ field });
								}}
							/>
						) : null}
						<RelationshipSelector
							relationship={this.state.relationship}
							onChange={(value) => {
								this.setState({ relationship: value });
							}}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["relationship", "common"], { withRef: true })(ChangeRelationshipModal);
