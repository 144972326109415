import React, { useContext, useState } from "react";
import { Collapsible, Icon, Spinner } from "@shopify/polaris";
import { ChevronRightMinor } from "@shopify/polaris-icons";
import Badge from "src/js/components/Badge";
import { WithTranslation, withTranslation } from "react-i18next";
import { AgendaTaskSection } from "./StyledComponents";
import TaskRow from "./TaskRow";
import { WeeklyTasksContext } from "./WeeklyTasksContextProvider";

interface TaskSectionProps extends WithTranslation {
	title: string;
	color: string;
	icon: any;
	open?: boolean;
	rows?: DeadlineBoardRowType[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskSection = ({ title, color, icon, rows, open: propsOpen = false, t }: TaskSectionProps) => {
	const { loading, selection, setSelection, onOpen, refresh, calendar } = useContext(WeeklyTasksContext);

	const [open, setOpen] = useState(propsOpen || false);
	const handleToggle = () => setOpen((c) => !c);
	const count = rows?.length;

	return (
		<AgendaTaskSection color={color}>
			<AgendaTaskSection.Header onClick={handleToggle}>
				<AgendaTaskSection.Header.Title>
					<AgendaTaskSection.Header.Icon>{icon}</AgendaTaskSection.Header.Icon>
					{title}
				</AgendaTaskSection.Header.Title>
				<AgendaTaskSection.Header.RightButtonsWrapper>
					{loading && (
						<div>
							<Spinner size="small" />
						</div>
					)}
					{!!count && !loading && (
						<Badge color={color} alpha={0.2} fontSize={14} style={{ padding: "1.5px 10px" }}>
							{count}
						</Badge>
					)}
					<div
						style={{
							transition: "transform 250ms, opacity 250ms",
							opacity: open ? "1" : "0.5",
							transform: `scale(1.2) rotate(${open ? 90 : 0}deg)`,
							display: "flex",
						}}
					>
						<Icon source={ChevronRightMinor} />
					</div>
				</AgendaTaskSection.Header.RightButtonsWrapper>
			</AgendaTaskSection.Header>

			<Collapsible open={open} id={title}>
				<AgendaTaskSection.List>
					{rows?.map((row) => (
						<TaskRow
							key={row.key}
							row={row}
							color={color}
							selection={selection}
							setSelection={setSelection}
							onOpen={onOpen}
							refresh={refresh}
							calendar={calendar}
						/>
					))}
				</AgendaTaskSection.List>
			</Collapsible>
		</AgendaTaskSection>
	);
};
export default withTranslation(["common"], { withRef: true })(TaskSection);
