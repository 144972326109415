import i18next from "i18next";

const orderRow = {
	article: i18next.t("orders.row.fields.article.label", "Artikel"),
	note: i18next.t("orders.row.fields.description.label", "Benämning"),
	description: i18next.t("orders.row.fields.orderedquantity.label", "Antal"),
	quantity: i18next.t("orders.row.fields.quantity.label", "Antal"),
	unit: i18next.t("orders.row.fields.unit.label", "Enhet"),
	article_pricelist: i18next.t("orders.row.fields.article_pricelist.label", "Prislista"),
	price: i18next.t("orders.row.fields.price.label2", "Á-Pris"),
	tb1: i18next.t("orders.row.fields.tb1.label", "TB1"),
	tb2: i18next.t("orders.row.fields.tb2.label", "TB2"),
	discount: i18next.t("orders.row.fields.discount.label", "Rabatt"),
	tb1InCents: i18next.t("orders.row.fields.tb1.label", "TB1"),
	tb2InCents: i18next.t("orders.row.fields.tb2.label", "TB2"),
	priceInCents: i18next.t("orders.row.fields.price_in_cents.label", "Pris i ören"),
	vatInPercentage: i18next.t("orders.row.fields.vat_in_percentage.label", "Moms i procent"),
	articlePricelist: i18next.t("orders.row.fields.article_pricelist.label", "Prislista"),
	articlePricelistTb1InCents: i18next.t("orders.row.fields.article_pricelist_tb1_in_cents.label", "Prislista TB1 i ören"),
	articlePricelistTb2InCents: i18next.t("orders.row.fields.article_pricelist_tb2_in_cents.label", "Prislista TB2 i ören"),
	articlePricelistPriceInCents: i18next.t("orders.row.fields.article_pricelist_price_in_cents.label", "Prislista Pris i ören"),
};

export default orderRow;
