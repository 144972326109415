/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Button } from "@shopify/polaris";
import Section from "../Section";
import Sheet from "../../../components/sheet";

const Sheets = () => {
	// eslint-disable-next-line no-unused-vars
	const [opened, setOpened] = useState({});

	// const handleChangeTemplateId = useCallback((v) => {
	// 	setTemplateId(v && parseInt(v));
	// }, []);

	const props = [
		{
			sectionTitle: "No/Default props",
			title: "Sheet title",
			onClose: () => {
				handleClose(0);
			},
		},
	];

	const handleOpen = (index) => {
		setOpened((c) => ({ ...c, [index]: { ...(c?.[index] || {}), open: true } }));
	};
	const handleClose = (index) => {
		setOpened((c) => ({ ...c, [index]: { ...(c?.[index] || {}), open: false } }));
	};

	return (
		<>
			{/* <TextField value={String(templateId)} onChange={handleChangeTemplateId} placeholder="Template ID" /> */}

			{props.map(({ sectionTitle, variables, ...rest }, index) => {
				return (
					<Section enableRefresh key={index} title={sectionTitle} props={rest}>
						<Button
							onClick={() => {
								handleOpen(index);
							}}
						>
							Open
						</Button>
						<Sheet open={opened?.[index]?.open} {...rest}>
							<p>Sheet</p>
						</Sheet>
					</Section>
				);
			})}
		</>
	);
};

export default Sheets;
