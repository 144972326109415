const flattenOptions = (options) => {
	return options.flatMap((option) => {
		if (!option.options?.length) {
			return option;
		}

		return [option, ...flattenOptions(option.options)];
	});
};
export default flattenOptions;
