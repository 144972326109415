import { useContext } from "react";

import WorkspacesContext from "../components/Workspaces/WorkspaceContext";

const useWorkspaces = () => {
	const { loading, fetchWorkspaces, workspaces, removeWorkspace, updateWorkspace, uploadToWorkspace, addWorkspace } = useContext(WorkspacesContext);

	return { loading, fetchWorkspaces, workspaces, removeWorkspace, updateWorkspace, uploadToWorkspace, addWorkspace };
};

export default useWorkspaces;
