import React, { Component } from "react";
import { FooterHelp, EmptyState, Button, Link } from "@shopify/polaris";
import { SocialAdMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../../store";
import Datepicker from "../../components/datepicker";
import AdAccountSelector from "../../components/AdAccountSelector.js";
import CampaignModal from "../../components/AdCampaignModal.js";
import IndexTable from "src/js/components/IndexTable";
import styled from "styled-components";

class AdCampaigns extends Component {
	constructor(props) {
		super(props);
		const last30day = new Date();
		last30day.setDate(last30day.getDate() - 30);
		const to = new Date();
		this.state = {
			selectedItems: [],
			visibleItems: [],
			from: last30day,
			to,
			caption: "Senaste 30 dagarna",
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	updateParams(params) {
		this.setState({});
	}

	onItemsFetched(items) {
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/ads/" + items[i].id;
		}
		this.setState({ visibleItems: items });
	}

	getYmd(date) {
		let month = date.getMonth() + 1; // months from 1-12
		let day = date.getDate();
		const year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	changeRange(from, to, caption) {
		this.setState({ from, to, caption });
	}

	gotoCampaign(item) {
		item.path = "/admin/ad_campaigns/" + item.id;
		this.props.history.push("/admin/ad_campaigns/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("ad_campaign.breadcrumb", "Kampanjer"),
					item: { id: item.id, item, path: "/admin/ad_campaigns/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	onCreate(campaign) {
		this.gotoCampaign(campaign);
	}

	render() {
		const filters = [
			{
				key: "tagged",
				label: this.props.t("ads.filters.tagged.label", "Taggad med"),
				type: "textField",
			},
			{
				key: "relationship",
				label: this.props.t("ads.filters.relation.label", "Relation"),
				type: "select",
				options: [
					{ label: this.props.t("ads.filters.relation.none", "Ingen relation"), value: "none" },
					{ label: this.props.t("ads.filters.relation.contacted", "Varit i kontakt"), value: "contacted" },
					{ label: this.props.t("ads.filters.relation.in_progress", "Jobbar på det"), value: "in_progress" },
					{ label: this.props.t("ads.filters.relation.customer", "Är kund"), value: "customer" },
					{ label: this.props.t("ads.filters.relation.ex_customer", "Har varit kund"), value: "ex_customer" },
				],
				shortcut: true,
			},
			{
				key: "country",
				label: this.props.t("common.terms.country", "Land"),
				operatorText: this.props.t("common.terms.is", "är"),
				type: "select",
				options: store.getState().countries,
			},
		];

		const columns = [
			{ handle: "TITLE", label: this.props.t("ads.columns.name", "Namn"), sortable: false, render: (item) => item.title },
			{
				handle: "BUDGET",
				label: this.props.t("ads.columns.daily_budget", "Daglig budget"),
				sortable: false,
				render: (item) => (item.budget ? (item.budget / 100).toFixed(2) + " kr" : "–"),
			},
			{ handle: "IMPRESSIONS", label: this.props.t("ads.columns.views", "Visningar"), sortable: false, render: (item) => item.impressions },
			{
				handle: "COST",
				label: this.props.t("ads.columns.cost", "Kostnad"),
				sortable: false,
				render: (item) => (item.cost ? (item.cost / 100).toFixed(2) + " kr" : "–"),
			},
			{ handle: "CLICKS", label: this.props.t("ads.columns.click", "Klick"), sortable: false, render: (item) => item.clicks },
			{
				handle: "CONVERSIONS",
				label: this.props.t("ads.columns.conversations", "Konverteringar"),
				sortable: false,
				render: (item) => item.conversions,
			},
			{ handle: "STATUS", label: this.props.t("ads.columns.status", "Status"), sortable: false, render: (item) => item.status },
			{
				handle: "ACCOUNT",
				label: this.props.t("ads.columns.account", "Konto"),
				sortable: false,
				render: (item) => <AdAccountSelector disabled ad_account={item.ad_account} onChange={() => {}} />,
			},
		];

		return (
			<div>
				<IndexTable
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					history={this.props.history}
					title={this.props.t("ad_campaign.title", "Kampanjer")}
					secondaryActions={[
						{
							icon: SocialAdMajor,
							content: this.props.t("ads.plural", "Annonser"),
							onAction: () => {
								this.props.history.push("/admin/ads");
							},
						},
					]}
					primaryAction={{
						content: this.props.t("ad_campaign.actions.new", "Ny kampanj"),
						onAction: () => {
							this.setState({ campaignModalOpen: true });
						},
					}}
					savedSearchHandle="ad_campaigns"
					resourceUrl={"/api/ans_campaigns.json?from=" + this.getYmd(this.state.from) + "&to=" + this.getYmd(this.state.to)}
					resourceHandle="ad_campaigns"
					onItemsFetched={this.onItemsFetched.bind(this)}
					onSelectionChange={this.onSelectionChange.bind(this)}
					resourceName={{
						singular: this.props.t("ad_campaign.singular", "kampanj"),
						plural: this.props.t("ad_campaign.plural", "kampanjer"),
					}}
					selector={
						<Datepicker plain onChange={this.changeRange.bind(this)} caption={this.state.caption} from={this.state.from} to={this.state.to} />
					}
					selectedItems={this.state.selectedItems}
					onUpdateParams={this.updateParams.bind(this)}
					filters={filters}
					columns={columns}
					defaultSort="SELLER_DESC"
					onClickRow={this.gotoCampaign.bind(this)}
					emptyState={
						<EmptyStateWrapper>
							<EmptyState
								heading={this.props.t("ad_campaign.empty.title", "Inga kampanjer")}
								action={
									store.getState().ad_accounts.length
										? {
												content: this.props.t("ad_campaign.actions.new", "Skapa kampanj"),
												onAction: () => {
													this.setState({ campaignModalOpen: true });
												},
										  }
										: null
								}
								image="/assets/images/Post.svg"
							>
								<p>
									{this.props.t("ad_campaign.empty.description", "Du har inga kampanjer att visa, koppla ditt konto till ett eller flera konton för")}{" "}
									<Link
										onClick={() => {
											this.props.history.push("/admin/google/accounts");
										}}
									>
										{this.props.t("ad_campaign.google.adwords", "Google Adwords")}
									</Link>{" "}
									eller{" "}
									<Link
										onClick={() => {
											this.props.history.push("/admin/facebook/accounts");
										}}
									>
										{this.props.t("ad_campaign.facebook.adwords", "Facebook")}
									</Link>{" "}
									m.m.
								</p>
							</EmptyState>
							<FooterHelp>
								{this.props.t("ad_campaign.empty.footer", "Du kan lägga till fler annonskonto från exempelvis")}{" "}
								<Button
									onClick={() => {
										this.props.history.push("/admin/google/accounts");
									}}
									plain
								>
									{this.props.t("ad_campaign.google.adwords", "Google Adwords")}
								</Button>
								eller
								<Button
									onClick={() => {
										this.props.history.push("/admin/facebook/accounts");
									}}
									plain
								>
									{this.props.t("ad_campaign.facebook.adwords", "Facebook")}
								</Button>
							</FooterHelp>
							<CampaignModal
								open={this.state.campaignModalOpen}
								campaign={{
									title: this.props.title,
								}}
								onClose={() => {
									this.setState({ campaignModalOpen: false });
								}}
								onCreate={this.onCreate.bind(this)}
							/>
						</EmptyStateWrapper>
					}
				/>

				<FooterHelp>
					{this.props.t("ad_campaign.empty.footer", "Du kan lägga till fler annonskonto från exempelvis")}{" "}
					<Button
						onClick={() => {
							this.props.history.push("/admin/google/accounts");
						}}
						plain
					>
						{this.props.t("ad_campaign.google.adwords", "Google Adwords")}
					</Button>{" "}
					{this.props.t("common.terms.or", "eller")}{" "}
					<Button
						onClick={() => {
							this.props.history.push("/admin/facebook/accounts");
						}}
						plain
					>
						{this.props.t("ad_campaign.google.adwords", "Facebook")}
					</Button>
				</FooterHelp>
				<CampaignModal
					open={this.state.campaignModalOpen}
					campaign={{
						title: this.props.title,
					}}
					onClose={() => {
						this.setState({ campaignModalOpen: false });
					}}
					onCreate={this.onCreate.bind(this)}
				/>
			</div>
		);
	}
}

export default withTranslation(["ad_campaign", "common"], { withRef: true })(AdCampaigns);

const EmptyStateWrapper = styled.div`
	img {
		height: 50vh;
	}
`;
