import { SendMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API from "src/js/API";
import Button from "src/js/components/Button";
import EmailModal from "src/js/components/Emails/EmailModal";
import Toast from "src/js/components/Toast";

const SelfContainedEmailModal = ({ contact }) => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation(["contact", "common"]);
	const user = useSelector((state: any) => state.user);

	const integration = user.integrations.find((i) => i.type === "microsoft365");
	const onSendMail = async (data) => {
		const { type, id } = integration;

		try {
			await API.post(`/api/integrations/${type}/${id}/email`, {
				data: { ...data, contact },
			});

			const event = new CustomEvent("refreshActivities");
			window.dispatchEvent(event);

			Toast.success(t("common.actions.email.sent", "E-posten har skickats"));
		} catch (e) {
			console.error(e);
		}
	};

	if (!integration || !contact.email) return null;

	return (
		<EmailModal
			activator={
				<Button icon={SendMajor} plain onClick={() => setOpen(true)}>
					{t("contact.integration.microsoft.links.send", "Skicka nytt")}
				</Button>
			}
			open={open}
			contact={contact}
			onClose={() => {
				setOpen(false);
			}}
			onSend={(email) => {
				onSendMail(email);
			}}
		/>
	);
};
export default SelfContainedEmailModal;
