import { FormLayout, Layout } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

type SendAsEmailModalProps = {
	type: "order" | "offer" | "invoice" | "contract";
	open: boolean;
	onClose: () => void;
	onSend: (form: EmailInformation, opt?: boolean) => void;
	EmailInformation: EmailInformation | null | undefined;
	loading?: boolean;
	offer?: FortnoxOfferType | null;
	order?: FortnoxOrderType | null;
	invoice?: FortnoxInvoiceType | null;
	options?: any;
} & WithTranslation &
	(
		| {
				type: "order";
				order: FortnoxOrderType | null;
		  }
		| {
				type: "offer";
				offer: FortnoxOfferType | null;
		  }
		| {
				type: "invoice";
				invoice: FortnoxInvoiceType | null;
		  }
	);

const SendAsEmailModal = ({ type, open, onClose, onSend, t, EmailInformation, loading, order, offer, invoice, options }: SendAsEmailModalProps) => {
	const [form, setForm] = useState<EmailInformation>(EmailInformation as EmailInformation);
	const [isSending, setIsSending] = useState(false);

	useEffect(() => {
		setForm(EmailInformation as EmailInformation);
	}, [EmailInformation]);

	const singular = (() => {
		switch (type) {
			case "order":
				return "order";
			case "offer":
				return "offer";
			case "invoice":
				return "invoice";
			default:
				return null;
		}
	})();

	const handleChange = (field: string) => (value: any) => {
		const newForm = { ...form };
		newForm[field] = value;
		setForm(newForm);
	};

	const disabled = (() => {
		switch (type) {
			case "order":
				return order?.Cancelled;
			case "offer":
				return offer?.Cancelled;
			case "invoice":
				return invoice?.Cancelled || invoice?.Booked;
			default:
				return false;
		}
	})();

	const handleSend = async () => {
		try {
			setIsSending(true);
			return await onSend(form, disabled);
		} catch (error) {
			Toast.error(error);
		} finally {
			setIsSending(false);
		}
	};

	return (
		<Modal
			large
			open={open}
			onClose={onClose}
			title={t("fortnox.send_as_email_modal.title", "Skicka {{singular}} som e-post", { singular })}
			primaryAction={{
				content: options?.bookkeep
					? (t("fortnox.send_as_email_modal.send_and_bookkeep", "Skicka och bokför") as string)
					: (t("fortnox.send_as_email_modal.send", "Skicka") as string),
				onAction: handleSend,
				loading: isSending || loading,
			}}
			secondaryActions={[
				{
					content: t("fortnox.send_as_email_modal.cancel", "Avbryt") as string,
					onAction: onClose,
				},
			]}
			sectioned
		>
			<Layout>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField
							disabled={disabled}
							label={t("fortnox.EmailInformation.fields.EmailAddressFrom.label", "Svarsadress")}
							value={form?.EmailAddressFrom}
							onChange={handleChange("EmailAddressFrom")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.EmailInformation.fields.EmailAddressTo.label", "Mottagare")}
							value={form?.EmailAddressTo}
							onChange={handleChange("EmailAddressTo")}
						/>
					</FormLayout>
				</Layout.Section>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField
							disabled={disabled}
							label={t("fortnox.EmailInformation.fields.EmailAddressCC.label", "Kopia")}
							value={form?.EmailAddressCC}
							onChange={handleChange("EmailAddressCC")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.EmailInformation.fields.EmailAddressBCC.label", " Hemlig kopia ")}
							value={form?.EmailAddressBCC}
							onChange={handleChange("EmailAddressBCC")}
						/>
					</FormLayout>
				</Layout.Section>
				<Layout.Section>
					<FormLayout>
						<TextField
							disabled={disabled}
							label={t("fortnox.EmailInformation.fields.EmailSubject.label", "Ämne")}
							value={form?.EmailSubject}
							onChange={handleChange("EmailSubject")}
							multiline
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.EmailInformation.fields.EmailBody.label", "Text")}
							value={form?.EmailBody}
							onChange={handleChange("EmailBody")}
							multiline={3}
						/>
					</FormLayout>
				</Layout.Section>
			</Layout>
		</Modal>
	);
};
export default withTranslation(["fortnox", "common"])(SendAsEmailModal);
