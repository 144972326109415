import { getLocalStorage } from ".";

const debugSocket = (name: string, data = null) => {
	// check if url contains param debug
	const url = new URL(window.location.href);
	const socketDebug = url.searchParams.get("socket_debug") || getLocalStorage("socket_debug");
	if (socketDebug) {
		console.debug("socket: " + name, data);
	}
};

export default debugSocket;
