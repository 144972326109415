import { Icon, TextField } from "@shopify/polaris";
import { CircleCancelMajor, DragHandleMinor } from "@shopify/polaris-icons";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import ColorPicker from "src/js/components/ColorPicker";

const CellStatusOptionEditing = ({ status, t, isDragging, onDelete, onChange }) => {
	return (
		<OuterWrapper className="edit-status-option" isDragging={isDragging} key={status.id} style={{ position: "relative" }}>
			<Wrapper isDragging={isDragging} key={status.id} style={{ position: "relative" }}>
				<TextField
					label=""
					labelHidden
					autoComplete="off"
					value={status.label}
					onChange={onChange?.("label")}
					connectedLeft={
						<div style={{ display: "flex", gap: "0.3125rem", alignItems: "center" }}>
							<div>
								<Icon source={DragHandleMinor} />
							</div>
							<ColorPicker color={status.color} onChange={onChange?.("color")} />
						</div>
					}
					placeholder={t("cell.status.fields.label.placeholder", "Namn *")}
				/>
				<span className="deleteStatus" onClick={onDelete}>
					<Icon source={CircleCancelMajor} />
				</span>
			</Wrapper>
		</OuterWrapper>
	);
};
export default withTranslation(["boards", "common"], { withRef: true })(CellStatusOptionEditing);
const OuterWrapper = styled.div<{ isDragging?: boolean }>`
	padding-block: 0.4688rem;
`;
const Wrapper = styled.div<{ isDragging?: boolean }>``;
