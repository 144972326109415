import { Button } from "@shopify/polaris";
import { AddImageMajor, DeleteMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import UploadModal from "./UploadModal";
import Toast from "src/js/components/Toast";

class UploadProfileAvatar extends Component {
	constructor(props) {
		super(props);
		this.state = { form: store.getState().user, showRemove: store.getState().user && store.getState().user.avatar };
	}

	openModal() {
		this.setState({ open: true });
	}

	closeModal() {
		this.setState({ open: false });
	}

	saveForm() {
		const form = this.state.form;

		if (form) {
			API.put("/api/me.json", form)
				.then((result) => {
					this.setState({ removing: false, open: false, showRemove: result.data.user && result.data.user.avatar });
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					this.setState({ form: result.data.user });
					if (this.props.onChange) this.props.onChange(result.data.user);
					store.dispatch({ type: "SET_USER", user: result.data.user });
					Toast.success(this.props.t("common.profile.responses.updated", "Uppdaterade din profilbild"));
				})
				.catch((error) => {
					console.error("error:", error);
					this.setState({ removing: false, open: false });
					Toast.error(error);
				});
		}
	}

	onSave(imgs) {
		if (imgs[0] && imgs[0].url) {
			this.state.form.avatar = imgs[0].url;
			this.setState({ form: this.state.form });
			this.saveForm();
		}
	}

	removeProfileImage() {
		this.state.form.avatar = "";
		this.setState({ form: this.state.form, removing: true });
		this.saveForm();
	}

	render() {
		return (
			<React.Fragment>
				<span
					className={`update-profile-image__button${this.state.open ? " active" : ""}${!this.state.form.avatar ? " no_avatar" : ""}`}
					style={this.props.style}
				>
					<Button loading={this.state.loading} icon={AddImageMajor} onClick={this.openModal.bind(this)} />
				</span>
				<UploadModal
					disabledToast
					open={this.state.open}
					onClose={this.closeModal.bind(this)}
					onSelect={this.onSave.bind(this)}
					allowMultiple={false}
					secondaryActions={
						this.state.showRemove && [
							{
								content: this.props.t("common.profile.actions.remove", "Ta bort profilbild"),
								onAction: this.removeProfileImage.bind(this),
								destructive: true,
								icon: DeleteMajor,
								loading: this.state.removing,
							},
						]
					}
					as="profile"
				/>
			</React.Fragment>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(UploadProfileAvatar);
