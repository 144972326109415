import React from "react";

const HorizonalDots = ({ color = "black" }) => (
	<svg width="16" height="5" viewBox="0 0 16 5" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2 0.30957C0.9 0.30957 0 1.20957 0 2.30957C0 3.40957 0.9 4.30957 2 4.30957C3.1 4.30957 4 3.40957 4 2.30957C4 1.20957 3.1 0.30957 2 0.30957ZM14 0.30957C12.9 0.30957 12 1.20957 12 2.30957C12 3.40957 12.9 4.30957 14 4.30957C15.1 4.30957 16 3.40957 16 2.30957C16 1.20957 15.1 0.30957 14 0.30957ZM8 0.30957C6.9 0.30957 6 1.20957 6 2.30957C6 3.40957 6.9 4.30957 8 4.30957C9.1 4.30957 10 3.40957 10 2.30957C10 1.20957 9.1 0.30957 8 0.30957Z"
			fill={color}
		/>
	</svg>
);

export default HorizonalDots;
