import React, { useCallback } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Checkbox, Icon, Tooltip } from "@shopify/polaris";
import Truncate from "src/js/components/Truncate";
import Moment from "react-moment";
import moment from "moment";
import { store } from "src/js/store";
import CommentCountBadge from "src/js/components/CommentCountBadge";
import { RefreshMajor } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { AgendaTaskRow } from "./StyledComponents";
import BoardHelper from "../Workspaces/BoardHelper";
import TaskCompleteModal from "./TaskCompleteModal";
import { getBoardTitle } from "../Workspaces/boardutils/BoardUtils";
import Toast from "src/js/components/Toast";

interface TaskRowProps extends WithTranslation {
	row: DeadlineBoardRowType;
	color?: string;
	selection?: string[];
	setSelection?: (selection: string[] | any) => void;
	onOpen: (row: DeadlineBoardRowType, tab?: number) => void;
	refresh: () => void;
	calendar?: any;
}

const TaskRow = ({ row, color, t, selection, setSelection, onOpen, refresh, calendar }: TaskRowProps): any | React.FC<TaskRowProps> => {
	// const { selection, setSelection, onOpen, refresh, calendar } = useContext(WeeklyTasksContext);

	const newSelection = new Set(selection);
	const isSelected = newSelection.has(row.key);

	const handleSelect = useCallback(
		(key) => {
			return (e: any) => {
				e.stopPropagation();
				if (!setSelection) return;

				setSelection((selection: string[]) => {
					if (!selection) return [selection];
					const newSelection = new Set(selection);

					if (!newSelection.has(key)) newSelection.add(key);
					else newSelection.delete(key);

					return [...newSelection] as any;
				});
			};
		},
		[setSelection]
	);

	const getHandleOpen = useCallback(
		(tab = 0) => {
			return () => onOpen(row, tab);
		},
		[onOpen, row]
	);
	// const contacts = [];
	const contacts: ContactType[] = row.contactValue
		?.map((contactId) => {
			return store.getState().board_contacts[contactId];
		})
		.filter((contact) => !!contact);

	const { date, type, dateValue } = row.deadline;

	return (
		<AgendaTaskRow onClick={getHandleOpen()} color={color}>
			<AgendaTaskRow.Checkbox selection={selection} onClick={handleSelect(row.key)}>
				<AgendaTaskRow.Checkbox.Inner>
					<Checkbox labelHidden label checked={isSelected} />
				</AgendaTaskRow.Checkbox.Inner>
			</AgendaTaskRow.Checkbox>

			<AgendaTaskRow.Inner>
				<AgendaTaskRow.Content>
					<AgendaTaskRow.Title>
						<Truncate>{row.title}</Truncate>
					</AgendaTaskRow.Title>

					<AgendaTaskRow.SubTitle>
						<Truncate>
							<AgendaTaskRow.BoardLink to={`/admin/workspaces/${row.board.workspace_id || "none"}/boards/${row.board.id}`}>
								{getBoardTitle(row.board)}
							</AgendaTaskRow.BoardLink>

							{/* {moment(row.date).format("HH:mm") !== "00:00" && ( */}
							<AgendaTaskRow.SubText>
								{row.board && date && " - "}
								{date && (
									<Moment
										format={`DD/MM${moment(date).isSame(moment(), "year") ? "" : "/YYYY"} ${
											moment(date).format("HH:mm") !== "00:00" ? " @ HH:mm" : ""
										}`}
									>
										{date}
									</Moment>
								)}
								{date && type === "timeline" && dateValue.start !== dateValue.end && (
									<React.Fragment>
										{" - "}
										<Moment
											format={`${
												!moment(moment(dateValue.end).format("YYY-MM-DD")).isSame(moment(dateValue.start).format("YYY-MM-DD")) ? "DD/MM" : ""
											} ${moment(dateValue.end).format("HH:mm") !== "00:00" ? " @ HH:mm" : ""}`}
										>
											{dateValue.end}
										</Moment>
									</React.Fragment>
								)}
							</AgendaTaskRow.SubText>
							{/* // )} */}
							{!!contacts && !!contacts.length && (
								<AgendaTaskRow.SubText bold>
									{" - "}
									{contacts.map((c) => c.fullname).join(" & ")}
								</AgendaTaskRow.SubText>
							)}
						</Truncate>
					</AgendaTaskRow.SubTitle>
				</AgendaTaskRow.Content>
			</AgendaTaskRow.Inner>
			<AgendaTaskRow.Actions
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{dateValue.recurring_interval && (
					<Tooltip
						content={`${t("dashboard.terms.recurring", "Återkommande")} ${BoardHelper.getDateRecurringLabelAndNextDate(dateValue).label} (${
							BoardHelper.getDateRecurringLabelAndNextDate(dateValue).upcoming_date
						})`}
					>
						<Icon source={RefreshMajor} />
					</Tooltip>
				)}
				{![store.getState().user.todo_board_id, store.getState().account.todo_board_id].includes(row.board_id) && (
					<CommentCountBadge count={row.comment_count} color={color} onClick={getHandleOpen(1)} />
				)}
				{!row?.deadline?.completed && (
					<TaskCompleteModal
						type="row"
						row={row}
						onClose={(haveSubmit) => {
							if (haveSubmit) refresh();
						}}
						onSuccess={(data) => {
							refresh();
							Toast.success(t("dashboard.terms.completed", "Uppgift slutförd"));
						}}
						calendar={calendar}
					/>
				)}
			</AgendaTaskRow.Actions>
		</AgendaTaskRow>
	);
};

const mapStateToProps = (state) => ({
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
});

export default connect(mapStateToProps, null, null, {
	forwardRef: true,
})(withTranslation(["dashboard", "common"], { withRef: true })(TaskRow));
