export default (state: TargetType[] = [], action) => {
	switch (action.type) {
		case "SET_TARGETS":
			return action.targets;
		case "REMOVE_TARGET": {
			const index = state.findIndex((target) => target.id == action.id);
			if (index === -1) return state;
			state.splice(index, 1);

			return [...state];
		}
		case "ADD_TARGET":
		case "UPDATE_TARGET": {
			const index = state.findIndex((target) => target.id == action.target?.id);
			if (index === -1) {
				state.push(action.target);
			} else {
				state[index] = action.target;
			}

			return [...state];
		}
		default:
			return state;
	}
};
