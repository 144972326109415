import React, { Component } from "react";
import { LegacyStack, Select, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

class BoardFiltersDate extends Component {
	constructor(props) {
		super(props);
		this.state = { operator: "between" };
	}

	getOperatorOptions() {
		const arr = [
			{ value: "between", label: this.props.t("board.filters.date.between", "mellan") },
			{ value: "equals", label: this.props.t("board.filters.date.exact_date", "exakt datum") },
			{ value: "period", label: this.props.t("board.filters.date.period", "period") },
		];
		return arr;
	}

	UNSAFE_componentWillReceiveProps(props) {
		const foundIndex = props.filters.findIndex((filter) => filter.column_id == props.column_id && filter.board_id == props.board.id);
		if (foundIndex >= 0 && props.filters[foundIndex].operator) {
			this.setState({ operator: props.filters[foundIndex].operator });
		}
	}

	toggleFilter(n, value) {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		if (foundIndex >= 0) {
			if (n == 1) {
				this.props.filters[foundIndex].value = value;
			} else {
				this.props.filters[foundIndex].value2 = value;
			}
			this.props.filters[foundIndex].operator = this.state.operator;
		} else if (value && foundIndex < 0) {
			const ob = {
				key: this.props.column_id + "-" + this.props.board.id,
				board_id: this.props.board.id,
				operator: this.state.operator,
				column_id: this.props.column_id,
			};
			if (n == 1) {
				ob.value = value;
			} else {
				ob.value2 = value;
			}
			this.props.filters.push(ob);
		}
		if (foundIndex >= 0 && !this.props.filters[foundIndex].value && !this.props.filters[foundIndex].value2) {
			this.props.filters.splice(foundIndex, 1);
		}
		this.props.onChangeFilters(this.props.filters);
	}

	changeOperator() {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);
		if (foundIndex >= 0) {
			this.props.filters[foundIndex].operator = this.state.operator;
			if (this.state.operator != "between") {
				delete this.props.filters[foundIndex].value2;
			}
			this.props.onChangeFilters(this.props.filters);
		}
	}

	getPeriods() {
		return [
			{ label: `-- ${this.props.t("board.filters.date.periods.choose", "välj period")} --`, value: "" },
			{ label: this.props.t("board.filters.date.periods.last_7", "Senaste 7 dagar"), value: "last_7_days" },
			{ label: this.props.t("board.filters.date.periods.last_30", "Senaste 30 dagar"), value: "last_30_days" },
		];
	}

	render() {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		return (
			<LegacyStack distrubution="fill">
				<Select
					value={this.state.operator}
					onChange={(v) => {
						this.setState({ operator: v }, this.changeOperator.bind(this));
					}}
					options={this.getOperatorOptions()}
				/>
				{this.state.operator == "period" ? (
					<Select
						value={foundIndex >= 0 ? this.props.filters[foundIndex].value : ""}
						onChange={this.toggleFilter.bind(this, 1)}
						options={this.getPeriods()}
					/>
				) : null}
				{this.state.operator != "period" ? (
					<TextField type="date" value={foundIndex >= 0 ? this.props.filters[foundIndex].value : ""} onChange={this.toggleFilter.bind(this, 1)} />
				) : null}
				{this.state.operator != "equals" && this.state.operator != "period" ? (
					<TextField type="date" value={foundIndex >= 0 ? this.props.filters[foundIndex].value2 : ""} onChange={this.toggleFilter.bind(this, 2)} />
				) : null}
			</LegacyStack>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardFiltersDate);
