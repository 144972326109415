import React, { Component } from "react";
import { Elements } from "react-stripe-elements";

import StripeCard from "./stripe_card.js";

class StripeForm extends Component {
	setTokenizerCallback(callback) {
		this.props.setTokenizerCallback(callback);
	}

	render() {
		return (
			<Elements>
				<StripeCard onResponse={this.props.onResponse} name={this.props.name} setTokenizerCallback={this.setTokenizerCallback.bind(this)} />
			</Elements>
		);
	}
}
export default StripeForm;
