import { FormLayout, Spinner, TextField, LegacyStack } from "@shopify/polaris";
import { PrintMajor } from "@shopify/polaris-icons";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "../../API";
import PdfTemplateSheet from "../../components/PdfTemplateSheet";
import useQuery from "src/js/hooks/useQuery";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Page from "src/js/components/page";
import Button from "src/js/components/Button";
import { PDFContext } from "src/js/PDF/PDFContext";
import { store } from "src/js/store";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

const EditPDF = ({ t, history, location, id: paramsId, match, ...props }) => {
	const id = useMemo(() => paramsId || match?.params?.id, [paramsId, match?.params?.id]);
	const [modalStats, setModalStats] = useState({});
	const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const [form, setForm] = useState(location?.state?.stacks?.[0]?.item || { variables: [] });

	const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
	const [testData, setTestData] = useState("");
	const { handleOpen } = useContext(PDFContext);

	const queryClient = useQueryClient();

	const fetch = async () => {
		return await API.get(`/api/pdf_templates/${id}.json`)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				return result.data.pdf_template;
			})
			.catch((error) => {
				Toast.error(error);
			});
	};

	const queryKey = useMemo(() => ["pdf_template", id], [id]);
	const { data: pdfTemplate, isFetching: loading } = useQuery({
		queryKey,
		queryFn: fetch,
		initialData: location?.state?.stacks?.[0]?.item || { variables: [] },
		defaultValue: location?.state?.stacks?.[0]?.item || { variables: [] },
	});

	useEffect(() => {
		if (pdfTemplate) setForm(pdfTemplate);
	}, [pdfTemplate]);

	const handleChange = (field) => (value) => {
		setForm((c) => {
			const current = { ...c };
			current[field] = value;
			return current;
		});
	};

	const saveFunction = useCallback(async () => {
		return await API.put(`/api/pdf_templates/${id}.json`, form)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				Toast.success(t("pdf.responses.updated", "Uppdaterade PDF"));
			})
			.catch((error) => {
				Toast.error(error);
			});
	}, [id, t, form]);

	const mutation = useMutation(saveFunction, {
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});

	const onSave = useCallback(async () => {
		try {
			return await mutation.mutateAsync();
		} catch (e) {
			if (!e?.response) throw e;
			Toast.error(e);
		}
	}, [mutation]);

	const remove = useCallback(async () => {
		setIsRemoving(true);
		API.delete(`/api/pdf_templates/${id}.json`)
			.then((result) => {
				setRemoveModalIsOpen(false);

				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				queryClient.invalidateQueries(queryKey);
				history.push("/admin/pdf_templates/");
			})
			.catch((error) => {
				Toast.error(error);
			})
			.finally(() => setIsRemoving(false));
	}, [history, id, queryClient, queryKey, setIsRemoving, setRemoveModalIsOpen]);

	const gotoEditor = (index) => {
		history.push(`/admin/pdf_templates/editor/${id}${index !== null && index !== undefined ? `?page=${index}` : ""}`);
	};

	const openModal = (name) => () => {
		setModalStats((c) => ({ ...c, [name]: true }));
	};

	const closeModal = (name) => () => {
		setModalStats((c) => ({ ...c, [name]: false }));
	};

	const handleOpenRemove = () => {
		setRemoveModalIsOpen(true);
	};

	const handleCloseRemove = () => {
		setRemoveModalIsOpen(false);
	};

	const duplicatePdf = async () => {
		return await API.post(`/api/pdf_templates/${id}/duplicate.json`, { title: "Kopia av " + (form?.title || "") })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				Toast.success(t("pdf.responses.duplicated", "Duplicerade PDF"));
				history.push("/admin/pdf_templates/" + result.data.pdf_template.id);
			})
			.catch((error) => {
				Toast.error(error);
			});
	};

	const disabled = loading || isRemoving;
	return (
		<Page
			title={
				<span>
					{t("pdf.edit.title", "Redigera PDF")} {loading && <Spinner size="small" />}
				</span>
			}
			backAction={{ content: t("common.actions.back", "Tillbaka"), onAction: () => history.goBack() }}
			primaryAction={{ content: t("common.actions.save", "Spara"), onAction: onSave }}
			secondaryActions={[
				{
					content: t("pdf.actions.test_with_data_obj", "Testa med data eget data object"),
					onAction: () => {
						setPreviewModalIsOpen(true);
					},
					enabled: [2, 3, 419, 1924].includes(store.getState().user?.id),
				},
				{
					content: (
						<span>
							{t("common.actions.remove", "Ta bort")} {isRemoving && <Spinner size="small" />}
						</span>
					),

					loading: isRemoving,
					onAction: handleOpenRemove,
					destructive: true,
				},
				{
					content: t("pdf.actions.duplicate", "Duplicera"),
					// loading: this.state.duplicating,
					onAction: duplicatePdf,
				},
			].filter((o) => !("enabled" in o) || o.enabled)}
		>
			<FormLayout>
				<TextField
					disabled={disabled}
					type="text"
					label={t("pdf.fields.title.label", "Titel")}
					onChange={handleChange("title")}
					value={form?.title}
				/>

				<div className="pdf-pages-overview">
					{form?.pages &&
						form?.pages.map((page = {}, index) => (
							<div key={page.id || index} onClick={() => gotoEditor(index + 1)} className="page-tumbnail">
								<img alt="" src={page.thumbnail_url} />
							</div>
						))}
				</div>
				<LegacyStack>
					<Button disabled={loading} onClick={openModal("printModalOpen")} icon={PrintMajor}>
						{t("common.actions.print", "Skriv ut")}
					</Button>
					<Button
						//  disabled={this.state.saving}
						onClick={() => gotoEditor()}
					>
						{t("pdf.actions.show_all_pages", "se alla sidor")}
					</Button>
					<Button
						primary
						// loading={this.state.saving}
						onClick={onSave}
					>
						{t("common.actions.save", "Spara")}
					</Button>
					<Button destructive loading={isRemoving} onClick={handleOpenRemove}>
						{t("common.actions.remove", "Ta bort")}
					</Button>
				</LegacyStack>
			</FormLayout>
			<PdfTemplateSheet template={form} open={modalStats?.printModalOpen} onClose={closeModal("printModalOpen")} />

			<Modal
				open={removeModalIsOpen}
				title={`${t("common.actions.remove", "Ta bort")} "${form?.title}"`}
				onClose={handleCloseRemove}
				secondaryActions={[
					{
						content: t("common.actions.remove", "Ta bort"),
						onAction: remove,
						loading: isRemoving,
						destructive: true,
					},
					{
						content: t("common.actions.cancel", "Avbryt"),
						onAction: handleCloseRemove,
					},
				]}
			>
				<Modal.Section>
					<p>Säker du vill ta bort PDF &quot;{form?.title}&quot;</p>
				</Modal.Section>
			</Modal>

			<Modal
				open={previewModalIsOpen}
				onClose={() => setPreviewModalIsOpen(false)}
				title={t("pdf.actions.preview", "Förhandsgranska")}
				sectioned
				primaryAction={{
					content: t("pdf.actions.test_with_data_obj_preview", "Förhandsgranska"),
					onAction: () => {
						handleOpen(JSON.parse(testData), {
							saveHandler: () => {
								Toast.error("Kan inte spara denna test förhandsgranskning");
								setPreviewModalIsOpen(false);
							},
							template: form,
						});
					},
				}}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng"),
						onAction: () => setPreviewModalIsOpen(false),
					},
				]}
			>
				<TextField
					label={t("pdf.fields.data_obj.label", "Data object")}
					value={testData}
					multiline={5}
					onChange={(data) => {
						setTestData(data);
					}}
				/>
			</Modal>
		</Page>
	);
};
export default withTranslation(["pdf", "common"], { withRef: true })(EditPDF);
