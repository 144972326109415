import React, { Component } from "react";
import { LegacyStack } from "@shopify/polaris";
import moment from "moment";
import MemberAvatar from "./MemberAvatar";
import { store } from "../../../store";
import BoardHelper from "../BoardHelper";
import CellTimetrack from "../CellTimetrack.js";

export default class KanbanCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getPeople() {
		if (!this.props.board || !this.props.row) return null;
		const peopleColumn = this.props.board && this.props.board.columns && this.props.board.columns.find((c) => c.type === "person");
		const pplVal = peopleColumn && this.props.row.column_values && this.props.row.column_values[peopleColumn.id];
		const peopleValues = pplVal && pplVal.value;
		const people = peopleValues && peopleValues.map((v) => store.getState().users.find((u) => u.id == v));

		return people;
	}

	getColumn(columnId) {
		return this.props.board.columns.find((c) => c.id == columnId);
	}

	getColumnValue(columnId) {
		return this.props.row.column_values[columnId];
	}

	updateCell(column, data) {
		if (this.props.onUpdateValue) {
			this.props.onUpdateValue(this.props.row, column, data);
		} else {
			BoardHelper.onUpdateValue(this.props.row, column, data);
		}
	}

	decorateRowsWithDeadlines(rows) {
		return rows
			.map((row) => {
				const board = BoardHelper.getBoard(row.board_id);
				if (board) {
					const deadlineColumns = board.columns.filter(
						(column) => (column.type == "timeline" || column.type == "datetime") && column.connected_column_id && column.link_column_id
					);

					const deadlines = [];

					if (deadlineColumns.length > 0) {
						for (let i = 0; i < deadlineColumns.length; i++) {
							let date = null;
							let status = null;
							const statusColumn = board.columns.find((col) => col.id == deadlineColumns[i].connected_column_id);
							const userId = row.column_values[deadlineColumns[i].link_column_id].value[0];
							if (deadlineColumns[i].type == "timeline") {
								date = row.column_values[deadlineColumns[i].id].value.start;
							} else {
								date = row.column_values[deadlineColumns[i].id].value.datetime;
							}
							if (statusColumn) {
								status = statusColumn.options.statuses.find((s) => s.id == row.column_values[statusColumn.id]?.value);
							}

							const complete = (status && status.color == "#00c875") || moment(date) < moment(moment().format("YYYY-MM-DD"));

							if (!complete) {
								deadlines.push({
									board_title: board.title,
									status,
									date: moment(date).format("YYYY-MM-DD"),
									userId,
								});
							}
						}
					}

					return Object.assign({ deadlines }, row);
				}
				return row;
			})
			.sort((a, b) => {
				if (a.deadlines?.length > b.deadlines?.length) {
					return -1;
				}
				return 0;
			})
			.sort((a, b) => {
				if (a.deadlines?.length > 0 && b.deadlines?.length > 0) {
					// Both rows have deadlines
					if (moment(a.deadlines[0].date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
						return 1;
					} else if (moment(b.deadlines[0].date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
						return -1;
					} else if (moment(a.deadlines[0].date) > moment(b.deadlines[0].date)) {
						return 1;
					}
					return -1;
				}
				return 0;
			});
	}

	getValue(columnId) {
		const valueSumBase = this.getColumnValue(columnId);
		const valueColumn = this.getColumn(columnId);
		const val = valueSumBase && valueSumBase.value;

		if (valueColumn && valueColumn.type === "rowlinkfrom") {
			let rows = [];

			const showDeadlines = !!valueColumn.options.show_deadlines;

			if (this.props.row.linked_row_ids) {
				for (let i = 0; i < this.props.row.linked_row_ids.length; i++) {
					const row = BoardHelper.getRowLinkFrom(this.props.row.linked_row_ids[i]);
					if (row) {
						rows.push(row);
					}
				}

				if (showDeadlines) {
					// Calculate deadlines and sort by date
					rows = this.decorateRowsWithDeadlines(rows);
				}

				if (rows.length > 0) {
					const row = rows[0];
					if (showDeadlines) {
						// TODO: Status och person kanske?
						return { val: row.deadlines?.length > 0 ? row.deadlines[0].date + " - " + row.deadlines[0].board_title : "" };
					}
				}
			}
		}

		if (valueColumn && valueColumn.type === "status" && valueSumBase && val != undefined) {
			return {
				val: valueColumn.options && valueColumn.options.statuses && valueColumn.options.statuses.find((s) => s.id == val).label,
			};
		}

		if (valueColumn && valueColumn.type === "board_number") {
			if (this.props.row && this.props.row.board_number) {
				return { val: "#" + this.props.row.board_number };
			}
		}
		if (valueColumn && valueColumn.type === "created_at") {
			if (this.props.row && this.props.row.created_at) {
				// return { val: `${val.start} -> ${val.end}` };
				return { val: this.props.row.created_at };
			}
		}

		if (valueColumn && valueColumn.type === "timetrack") {
			return {
				val: (
					<div
						style={{ position: "relative", marginLeft: -10 }}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<CellTimetrack
							onChange={this.updateCell.bind(this, valueColumn)}
							column={valueColumn}
							board={this.props.board}
							row={this.props.row}
							onUpdateColumn={BoardHelper.updateColumn}
							value={val}
						/>
					</div>
				),
			};
		}

		if (!val || (Array.isArray(val) && !val.length)) return null;
		if (typeof val === "number" || typeof val === "string") return { val };

		if (valueColumn && valueColumn.type === "tags") {
			if (val && val.length > 0) {
				const tag = this.props.board.tags.find((t) => t.id == val[0]);
				return { val: <span style={{ color: tag.color }}>#{tag.title}</span> };
			}
		}
		if (valueColumn && valueColumn.type === "contact") {
			const contact = BoardHelper.getContact(val[0]);
			return { val: contact && (contact.parent ? contact.parent.name : contact.name) };
		}

		if (valueColumn && valueColumn.type === "checkbox") {
			if (val) {
				return { val: valueColumn.title };
			}
		}

		if (valueColumn && valueColumn.type === "datetime") {
			if (val) {
				return { val: val.datetime };
			}
		}
		if (valueColumn && valueColumn.type === "company_number") {
			if (val) {
				return { val: val.number };
			}
		}
		if (valueColumn && valueColumn.type === "timeline") {
			if (val) {
				// return { val: `${val.start} -> ${val.end}` };
				// return { val: `From: ${val.start}\nTo: ${val.end}` };
				return {
					val: (
						<span>
							{val.start} <br />
							{val.end}
						</span>
					),
				};
			}
		}

		return null;
	}

	getOption(option) {
		return this.props.view.options && typeof this.props.view.options === "object" && this.props.view.options[option];
	}

	render() {
		const people = this.getPeople();
		const value = this.getValue(this.getOption("sumRow_columnId"));
		const titleValue = this.getValue(this.getOption("titleRow_columnId"));
		const titleValue2 = this.getValue(this.getOption("titleRow_columnId2"));
		const valueColumn = this.getColumn(this.getOption("sumRow_columnId"));
		const lastValue = this.getValue(this.getOption("lastRow_columnId"));
		const infoColumn = this.getColumn(this.getOption("info_columnId"));
		const infoValue = this.getValue(this.getOption("info_columnId"));

		return (
			<div className="kanban-card-wrapper">
				<div
					className={"kanban-card" + (this.props.isDragging ? " dragging" : "")}
					style={{
						height: 130 + (infoColumn ? 50 : 0),
						cursor: "pointer",
						borderLeft: `5px solid ${this.props.color || "transparent"}`,
					}}
					onClick={this.props.onClick}
				>
					{((titleValue && titleValue.val) || (titleValue2 && titleValue2.val)) && (
						<LegacyStack>
							<LegacyStack.Item fill>
								{titleValue && titleValue.val && <div className="kanban-card__secondValue oneline">{titleValue.val}</div>}
							</LegacyStack.Item>
							{titleValue2 && titleValue2.val && <div className="kanban-card__secondValue oneline">{titleValue2.val}</div>}
						</LegacyStack>
					)}
					<div className="kanban-card__title">{this.props.row.title}</div>
					{value && value.val && (
						<div className="kanban-card__value oneline">
							{valueColumn && valueColumn.title + ": "}
							{value.val}
							{value.suffix}
						</div>
					)}
					{infoValue && infoValue.val && (
						<div className="kanban-card__title">
							{infoColumn.type == "rowlinkfrom" && infoColumn.options.show_deadlines ? <div>Nästa aktivitet</div> : null}
							{infoValue.val}
						</div>
					)}
					{lastValue && lastValue.val && <div className="kanban-card__value lastline">{lastValue.val}</div>}
					<div className="kanban-card__people">{people && people.map((p, key) => <MemberAvatar key={key} user={p} tooltip />)}</div>
				</div>
			</div>
		);
	}
}
