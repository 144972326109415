import React from "react";

const HeartIcon = ({ color = "var(--textColor)" }) => (
	<svg width="17" height="16" viewBox="0 0 17 16" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.8299 0.083324C12.3557 0.083324 12.8807 0.157491 13.3799 0.324991C16.4557 1.32499 17.5641 4.69999 16.6382 7.64999C16.1132 9.15749 15.2549 10.5333 14.1307 11.6575C12.5216 13.2158 10.7557 14.5992 8.85489 15.7908L8.64655 15.9167L8.42989 15.7825C6.52239 14.5992 4.74655 13.2158 3.12239 11.6492C2.00572 10.525 1.14656 9.15749 0.613222 7.64999C-0.328445 4.69999 0.779889 1.32499 3.88906 0.307491C4.13072 0.224157 4.37989 0.165824 4.62989 0.133324H4.72989C4.96405 0.0991573 5.19655 0.083324 5.42989 0.083324H5.52155C6.04655 0.0991573 6.55489 0.190824 7.04739 0.358324H7.09655C7.12989 0.374157 7.15489 0.391657 7.17155 0.407491C7.35572 0.466657 7.52989 0.533324 7.69655 0.624991L8.01322 0.766657C8.08974 0.807468 8.17563 0.869825 8.24986 0.923717C8.29689 0.957862 8.33923 0.988609 8.37155 1.00832C8.38515 1.01635 8.39897 1.02441 8.4129 1.03255C8.48436 1.07426 8.55879 1.11771 8.62155 1.16582C9.54739 0.458324 10.6716 0.0749906 11.8299 0.083324ZM14.0466 6.08332C14.3882 6.07416 14.6799 5.79999 14.7049 5.44916V5.34999C14.7299 4.18249 14.0224 3.12499 12.9466 2.71666C12.6049 2.59916 12.2299 2.78332 12.1049 3.13332C11.9882 3.48332 12.1716 3.86666 12.5216 3.99082C13.0557 4.19082 13.4132 4.71666 13.4132 5.29916V5.32499C13.3974 5.51582 13.4549 5.69999 13.5716 5.84166C13.6882 5.98332 13.8632 6.06582 14.0466 6.08332Z"
			fill={color}
		/>
	</svg>
);

export default HeartIcon;
