class DatepickerHelper {
	getDate(value) {
		const v = value && typeof value === "object" && "start" in value ? value.start : value;
		return v && v.split(" ")[0];
	}

	getTime(value) {
		const v = value && typeof value === "object" && "start" in value ? value.start : value;
		const pcs = v && v.split(" ");
		if (pcs && pcs.length > 1) {
			const time = v && v.split(" ")[1];
			return time;
		}
		return null;
	}

	haveTime(value) {
		if (this.getTime(value) !== null) {
			return true;
		}
		return false;
	}
}

export default new DatepickerHelper();
