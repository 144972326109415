import React, { Component } from "react";
import { Collapsible, Icon } from "@shopify/polaris";

import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";

class Accordion extends Component {
	constructor(props) {
		super(props);

		this.state = { expanded: props.expanded };
	}

	render() {
		return (
			<div className={"Polaris-Filters__FilterTriggerContainer" + (this.state.expanded ? " Polaris-Filters--open" : "")}>
				<button
					className="Polaris-Filters__FilterTrigger"
					onClick={() => {
						this.setState({ expanded: !this.state.expanded });
					}}
				>
					<div className="Polaris-Filters__FilterTriggerLabelContainer">
						<h2 className="Polaris-Filters__FilterTriggerTitle">
							<span>{this.props.label}</span>
						</h2>
						<span>
							<Icon source={this.state.expanded ? ChevronUpMinor : ChevronDownMinor} color="inkLightest" />
						</span>
					</div>
				</button>
				<Collapsible open={this.state.expanded}>
					<div className="Polaris-Filters__FilterNodeContainer">{this.props.children}</div>
				</Collapsible>
			</div>
		);
	}
}
export default Accordion;
