import Modal from "src/js/components/modal";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import ModalBoxes from "../../components/ModalBoxes";
import BoardHelper from "./BoardHelper";
import API from "../../API";
import Toast from "src/js/components/Toast";

class BoardExportModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.source = axios.CancelToken.source();
	}

	componentWillUnmount() {
		this.cancelRequest();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open !== this.props.open) {
			if (!props.open) {
				this.cancelRequest();
			}
		}
	}

	cancelRequest() {
		if (this.source) {
			this.source.cancel();
		}
	}

	async fetchRows(withArchived) {
		const params = {};

		if (this.props.contact_id) {
			params.contact_id = this.props.contact_id;
			params.also_archived = 1;
		}
		if (withArchived) params.also_archived = 1;

		const rows = await new Promise((resolve, reject) => {
			BoardHelper.fetchRows(
				[this.props.board.id],
				null,
				(rows) => {
					resolve(rows);
				},
				params,
				true,
				true
			);
		});

		return rows;
	}

	async getRowIds(withArchived) {
		const url = new URL(window.location.href);
		const rows = await (async () => {
			if (!url.searchParams.get("archived") && !withArchived) {
				return this.props.filteredRows;
			}

			const r = await this.fetchRows(withArchived);
			return r;
		})();

		return BoardHelper.filterRows(
			rows.filter((row) => row.board_id == this.props.board.id),
			this.props.filters
		)?.map((row) => row.id);
	}

	async export(withArchived) {
		if (withArchived) {
			this.setState({ exportingWithArchived: true });
		} else {
			this.setState({ exporting: true });
		}

		// this.fetchGroups(withArchived);

		const data = {
			ids: await this.getRowIds(withArchived),
		};

		const params = { ...(this.state.params || {}) };
		if (withArchived) {
			params.also_archived = 1;
		}

		API({
			url: `/api/boards/${this.props.board.id}/export.xls`,
			method: "POST",
			responseType: "blob",
			params,
			data,
		})
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					Toast.error(result.data.error);
					return;
				}

				const type = result.headers["content-type"];
				const blob = new Blob([result.data], { type, encoding: "UTF-8" });
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = `export_${this.props.board.title}.xlsx`;
				link.click();
				window.URL.revokeObjectURL(blob);

				Toast.success(this.props.t("board.responses.exported", "Exporting av {{plural}} klar", { plural: this.props.board.plural }));
			})
			.catch((error) => {
				console.error("error:", error);
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ exporting: false, exportingWithArchived: false });
				this.props.onClose();
			});

		// window.open(`/admin/boards/${this.props.board.id}/export.xls?` + httpBuildQuery(params));
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("board.actions.export", "Exportera")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.cancel", "Avbryt"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<ModalBoxes
						items={[
							{
								title: "Synliga",
								text: "Exportera synliga rader",
								center: true,
								actionText: "Exportera",
								onAction: this.export.bind(this, false),
								loading: this.state.exporting,
							},
							{
								title: "Även arkiverade",
								text: "Exportera även arkiverade rader",
								center: true,
								actionText: "Exportera",
								onAction: this.export.bind(this, true),
								loading: this.state.exportingWithArchived,
							},
						]}
					/>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardExportModal);
