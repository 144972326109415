import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { HeaderWrapper } from "../styles";
import Banner from "src/js/components/banner";
import DocumentStatusBar from "src/js/views/Scrive/Document/DocumentStatusBar";
import { getStatuses } from "src/js/views/Scrive/Utilities";
import Tabs from "src/js/components/Tabs";

type HeaderProps = {
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
	order: OrderType;
	history: any;
} & WithTranslation;

const OrderHeader = ({ tabs, onSelect, selected, order, history, t }: HeaderProps) => {
	const scriveData = order?.upload?.board_row_value?.value.find((v) => v.id === order?.upload?.id)?.scrive_data;

	const userProfile = (user) => {
		if (!user) return null;
		return user?.name || "-";
	};

	const scriveStatusBar = scriveData?.id && (
		<DocumentStatusBar
			title=""
			onClick={() => {
				history.push(`/admin/scrive/documents/${scriveData.id}`, { document: scriveData, edit: true, tabId: "parties" });
			}}
			document={scriveData}
		/>
	);

	const markedAsSigned = (() => {
		if (!order?.marked_as_signed_at) return null;
		const user = order.marked_as_signed_by;
		return (
			<Banner title={<>{t("order.statuses.marked_as_signed.title", "Ordern är markerad som godkänd")}</>} status={"success"}>
				<Trans
					i18nKey="order.statuses.marked_as_signed.desc"
					defaults="Markerades som godkänd <b>{{marked_as_signed_at}}</b> av <b>{{user}}</b>"
					components={{
						b: <span style={{ fontWeight: 700 }} />,
					}}
					values={{
						marked_as_signed_at: order?.marked_as_signed_at,
						user: userProfile(user),
					}}
				/>
			</Banner>
		);
	})();

	const cancelled = (() => {
		if (!order?.cancelled_at) return null;
		const user = order.cancelled_by;
		return (
			<Banner title={<>{t("order.statuses.cancelled.title", "Ordern är makulerad")}</>} status={"error"}>
				<Trans
					i18nKey="order.statuses.cancelled.desc"
					defaults="Makulerades <b>{{cancelled_at}}</b> av <b>{{user}}</b>"
					components={{
						b: <span style={{ fontWeight: 700 }} />,
					}}
					values={{
						cancelled_at: order?.cancelled_at,
						user: userProfile(user),
					}}
				/>
			</Banner>
		);
	})();

	const sent = (() => {
		if (!order?.prepared_for_signing_at) return null;
		// if (order?.signed_at) return null;

		const status = order?.signed_at ? "success" : "info";
		const user = order.prepared_for_signing_by;

		const scriveStatus = scriveData?.status;
		const scriveStatusLabel = getStatuses().find((s) => s.value === scriveStatus)?.label;

		return (
			<Banner
				title={
					<>
						{t("order.prefix.scrive", "Scrive dokument")}
						{scriveStatusLabel && ` (${scriveStatusLabel})`}
					</>
				}
				status={status}
			>
				<Trans
					i18nKey="order.statuses.sent.desc"
					defaults="Skapdes <b>{{sent_at}}</b> av <b>{{user}}</b>"
					components={{
						b: <span style={{ fontWeight: 700 }} />,
					}}
					values={{
						sent_at: order?.prepared_for_signing_at,
						user: userProfile(user),
					}}
				/>
				{scriveStatusBar}
			</Banner>
		);
	})();

	const signed = (() => {
		if (!order?.signed_at) return null;
		const user = order.prepared_for_signing_by;

		return (
			<Banner title={<>{t("order.statuses.signed.title", "Ordern är signerad")}</>} status={"success"}>
				<Trans
					i18nKey="order.statuses.signed.desc"
					defaults="Skickades <b>{{sent_at}}</b> av <b>{{user}}</b> och blev signerad <b>{{signed_at}}</b>"
					components={{
						b: <span style={{ fontWeight: 700 }} />,
					}}
					values={{
						sent_at: order?.prepared_for_signing_at,
						signed_at: order?.signed_at,
						user: userProfile(user),
					}}
				/>
				{scriveStatusBar}
			</Banner>
		);
	})();

	return (
		<HeaderWrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
			{signed}
			{markedAsSigned}
			{cancelled}
			{sent}
		</HeaderWrapper>
	);
};
export default withTranslation(["order", "common"])(OrderHeader);
