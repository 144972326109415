/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import FortnoxTableColumn from "./FortnoxTableColumn";

export default class FortnoxTableSummaryRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...(props || {}),
		};
	}

	render() {
		return (
			// onContextMenu={this?.props?.onContextMenu}
			<tr className="Fortnox__Table__Row">
				{(this.props.columns || []).map((column, index) => (
					<FortnoxTableColumn
						key={index}
						{...column}
						type="summary"
						value={typeof column?.summary == "function" ? column.summary(this.state.row) : column?.summary || null}
						// data={this.state?.row?.[column?.handle] || []}
					/>
				))}
			</tr>
		);
	}
}
