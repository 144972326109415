import Modal from "src/js/components/modal";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { store } from "../../store";
import Box from "./AdAccountSelectorBox";

class AdAccountSelectorModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.types = { adwords: { createUrl: "/admin/google/accounts" }, facebook: { createUrl: "/admin/facebook/accounts" } };
	}

	groupBy() {
		if (!store.getState().ad_accounts || store.getState().ad_accounts.length <= 1) return this.types;

		return store.getState().ad_accounts.reduce((acc, item) => {
			if (acc[item.type] && acc[item.type].items) {
				acc[item.type].items.push(item);
			} else {
				acc[item.type].items = [item];
			}

			return acc;
		}, this.types);
	}

	onSelect(category) {
		this.props.onChange(category);
	}

	render() {
		// if (!store.getState().ad_accounts || store.getState().ad_accounts.length <= 1) return null;

		const { adAccount } = this.props;
		const groupedCategories = this.groupBy();
		const options = Object.keys(groupedCategories).map((key, index) => {
			const categories = groupedCategories[key].items;
			const imgUrl = (() => {
				if (key === "facebook") return "/assets/Facebook_icon_2013_1.svg";
				if (key === "adwords") return "/assets/Google_Anus_logo_1.png";

				return "/assets/Google_Ads_logo_1.png";
			})();

			return (
				<Box
					title={key}
					createUrl={groupedCategories[key].createUrl}
					key={index}
					imgUrl={imgUrl}
					categories={categories}
					onSelect={this.onSelect.bind(this)}
					adAccount={adAccount}
					history={this.props.history}
				/>
			);
		});

		return (
			<Modal open={this.props.open} onClose={this.props.onClose}>
				<Wrapper>{options}</Wrapper>
			</Modal>
		);
	}
}
export default withTranslation(["ads", "common"], { withRef: true })(AdAccountSelectorModal);

const Wrapper = styled.div`
	display: flex;
	gap: 1.875rem;
	padding: 1.875rem;
`;
