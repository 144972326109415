import { Avatar, Icon } from "@shopify/polaris";
import { AdjustMinor } from "@shopify/polaris-icons";
import { People3Icon } from "src/js/icons";

const getTargetConnectionAvatar = (targetConnection) => {
	if (targetConnection.user_group) return <People3Icon />;

	if (targetConnection.user)
		return (
			<Avatar size="medium" initials={targetConnection.user?.initials} name={targetConnection.user?.name} source={targetConnection.user?.avatar} />
		);

	return <Icon source={AdjustMinor} />;
};

export default getTargetConnectionAvatar;
