import { Button, TextField } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import Money from "../../components/Money";

class GoalUserIndexItem extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChange(field, value) {
		this.props.onChange(field, value);
	}

	render() {
		const { user, removeFormUser } = this.props;

		return (
			<tr key={user.id}>
				<td>{user?.user?.name}</td>
				<td>
					<TextField
						disabled={this.props.disabled}
						type="number"
						value={String(user.value)}
						onChange={this.onChange.bind(this, "value")}
						suffix={this.props.goal?.suffix}
					/>
				</td>
				<td>
					<TextField
						disabled={this.props.disabled}
						type="number"
						value={String(user.next_value)}
						onChange={this.onChange.bind(this, "next_value")}
						suffix={this.props.goal?.suffix}
					/>
				</td>
				<td>
					<Money amount={this.props.sum} currency="FIXED" />
				</td>
				<td>
					<Button
						onClick={() => {
							removeFormUser(user);
						}}
						icon={DeleteMinor}
					/>
				</td>
			</tr>
		);
	}
}
export default GoalUserIndexItem;
