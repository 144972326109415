import React, { Component } from "react";
import { LegacyCard, LegacyStack, Text, Button } from "@shopify/polaris";
import moment from "moment";
import nl2br from "react-nl2br";
import ReactLinkify from "react-linkify";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import MemberAvatar from "./MemberAvatar";
import Attachment from "../../../components/Attachment.js";
import { store } from "../../../store";
import CommentInput from "./CommentInput";
import RemoveButtonPopup from "../../../components/RemoveButtonPopup";
import LinkPreview from "../../../components/LinkPreview";
import Toast from "src/js/components/Toast";

class RowComment extends Component {
	constructor(props) {
		super(props);
		this.state = { comment: props.comment, saveable: false };
	}

	afterSave(comment, index) {
		this.setState({ comment, saveable: false, editing: false });
		if (this.props.onUpdate) this.props.onUpdate(comment, index);
	}

	updateForm(object) {
		this.state.comment = Object.assign(this.state.comment, object);
		this.setState({ comment: this.state.comment, saveable: true });
	}

	removeComment() {
		// if (this.props.onRemove) this.props.onRemove(this.state.comment);
		this.setState({ removing: true });
		const comment = this.state.comment;
		API.delete(`/api/boards/${comment.board && comment.board.id}/rows/${comment.row && comment.row.id}/comments/${comment.id}.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					Toast.error(result.data.error);
					return;
				}

				Toast.success(this.props.t("row.comment.responses.removed", "Kommentar borttagen"));
				if (this.props.onRemove) this.props.onRemove(comment);
			})
			.catch((error) => {
				console.error("error:", error);
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	render() {
		const editable = (this.state.comment.user && this.state.comment.user.id) === store.getState().user.id;
		const editing = this.state.editing;
		const header = (
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "0.6250rem",
						alignItems: "center",
					}}
				>
					<MemberAvatar user={this.state.comment.user} />
					<Text variant="headingMd" as="h2">
						{this.state.comment.name}
					</Text>
				</div>
				{this.state.comment.source && <Text variation="subdued">{this.state.comment.source}</Text>}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "0.6250rem",
						alignItems: "center",
					}}
				>
					<Text variation="subdued">{moment(this.state.comment.created_at).locale("sv").calendar()}</Text>
					{editable && (
						<Button onClick={() => this.setState((c) => ({ editing: !c.editing }))}>
							{this.state.editing ? this.props.t("common.actions.cancel", "Avbryt") : this.props.t("common.actions.change", "Ändra")}
						</Button>
					)}
				</div>
			</div>
		);
		if (editing) {
			return (
				<div className="row_comment" style={{ margin: "0.6250rem 0" }}>
					<LegacyCard sectioned>
						{header}
						<CommentInput
							row={this.state.comment.row}
							board={this.state.comment.board}
							onChange={this.updateForm.bind(this)}
							comment={this.state.comment}
							saveable={this.state.saveable}
							afterSave={this.afterSave.bind(this)}
						/>

						<div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
							<div />
							<div style={{ display: "flex", alignItems: "center", gap: "0.5000rem" }}>
								<Button onClick={() => this.setState({ editing: false })}>{this.props.t("common.actions.cancel", "Avbryt")}</Button>

								<RemoveButtonPopup
									loading={this.state.removing}
									title={this.props.t("row.comment.actions.confirm", "Vill du ta bort kommentaren?")}
									onRemove={this.removeComment.bind(this)}
								>
									{this.props.t("common.actions.remove", "Ta bort")}
								</RemoveButtonPopup>
							</div>
						</div>
					</LegacyCard>
				</div>
			);
		}

		return (
			<div className="row_comment" style={{ margin: "0.6250rem 0", position: "relative" }}>
				<LegacyCard sectioned>
					<LegacyStack wrap={false}>
						<LegacyStack.Item fill>
							<LegacyStack vertical spacing="none">
								{header}
								{this.state.comment.content && (
									<div style={{ margin: "15px 0", wordBreak: "break-word" }}>
										<ReactLinkify
											componentDecorator={(decoratedHref, decoratedText, key) => (
												<a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
													{decoratedText}
												</a>
											)}
										>
											{nl2br(this.state.comment.content)}
										</ReactLinkify>
									</div>
								)}
								{this.state.comment.mentions.length ? (
									<LegacyStack>
										{this.state.comment.mentions.map((user, index) => (
											<MemberAvatar key={index} user={user} />
										))}
									</LegacyStack>
								) : null}
								{this.state.comment.files.length ? (
									<div style={{ paddingTop: 5 }}>
										{this.state.comment.files.map((file, index) => (
											<Attachment key={index} file={file} />
										))}
									</div>
								) : null}
								{this.state.comment.metadata && <LinkPreview data={this.state.comment.metadata} />}
							</LegacyStack>
						</LegacyStack.Item>
					</LegacyStack>
				</LegacyCard>
			</div>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(RowComment);
