import BoardHelper from "../views/Workspaces/BoardHelper";

export default (state = {}, action) => {
	switch (action.type) {
		case "SET_BOARD_CONTACTS": {
			const contacts = Object.assign({}, state);
			for (let i = 0; i < action.contacts.length; i++) {
				const contact = action.contacts[i];
				contacts[contact.id] = contact;
			}
			return contacts;
		}
		case "UPDATE_BOARD_CONTACT": {
			state[action.contact.id] = Object.assign({}, BoardHelper.copyObj(action.contact));
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};
