import React, { Component } from "react";
import BoardHelper from "../../BoardHelper";
import CellStatus from "../../CellStatus";

class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// updateCell(column, data) {
	// 	BoardHelper.onUpdateValue(this.props.row, column, data);
	// }

	render() {
		const { row, statusColumn, phoneColumn, titleColumn /* board */ } = this.props;

		return (
			<>
				<td>{titleColumn === "row_title" ? row.title : BoardHelper.getColumnValues(row, titleColumn)?.[0]?.value}</td>
				<td>
					{statusColumn && (
						<CellStatus
							// inheritColor
							style={{ borderRadius: "100vh" }}
							// onChange={this.updateCell.bind(this, statusColumn)}
							column={statusColumn}
							// onFocus={this.focusCell.bind(this)}
							// onBlur={this.blurCell.bind(this)}
							onUpdateColumn={BoardHelper.updateColumn}
							value={row.column_values[statusColumn?.id]?.value}
						/>
					)}
				</td>
				<td style={{ textAlign: "center" }}>{row.column_values[phoneColumn?.id]?.value || ""}</td>
			</>
		);
	}
}
export default Row;
