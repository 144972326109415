import React from "react";

const NoteIcon = ({ color = "var(--textColor)" }) => (
	<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M25.3879 8.95833L34.0417 17.6121V34.0417H8.95833V8.95833H25.3879ZM25.3879 5.375H8.95833C6.9875 5.375 5.375 6.9875 5.375 8.95833V34.0417C5.375 36.0125 6.9875 37.625 8.95833 37.625H34.0417C36.0125 37.625 37.625 36.0125 37.625 34.0417V17.6121C37.625 16.6625 37.2487 15.7487 36.5679 15.0858L27.9142 6.43208C27.2512 5.75125 26.3375 5.375 25.3879 5.375ZM12.5417 26.875H30.4583V30.4583H12.5417V26.875ZM12.5417 19.7083H30.4583V23.2917H12.5417V19.7083ZM12.5417 12.5417H25.0833V16.125H12.5417V12.5417Z"
			fill={color}
		/>
	</svg>
);

export default NoteIcon;
