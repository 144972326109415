import React, { Component } from "react";
import { Button, Text, Scrollable, FormLayout, Card, Form, Select, LegacyStack, TextField } from "@shopify/polaris";
import { ViewMinor, HideMinor, TickSmallMinor, CancelSmallMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import CellText from "./CellText.js";
import Toast from "src/js/components/Toast";
import Sheet from "../../components/sheet";

class BoardViewForm extends Component {
	constructor(props) {
		super(props);
		this.state = { form: {} };

		const params = new URLSearchParams(window.location.search);
		this.embedded = parseInt(params.get("embedded"));
		this.width = this.embedded ? "80%" : "70%";
	}

	componentDidMount() {}

	// componentWillReceiveProps(props) {
	// 	if (props.showSettings != this.props.showSettings) {
	// 		this.setState({ showSettings: props.showSettings });
	// 	}
	// }

	saveForm(e) {
		e.preventDefault();

		if (this.props.editing || !this.validForm()) {
			return;
		}
		this.setState({ saving: true });
		API.post("/forms/" + this.props.view.id + "/submissions.json", this.state.form, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				this.setState({ saving: false, submitted: true });

				if (this.props.view.options.redirect_url) {
					window.location.href = this.props.view.options.redirect_url;
				}
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	getHelpText(field) {
		let helpText = this.props.editing ? "" : "";
		if (field in this.props.view.options.fields && this.props.view.options.fields[field].helpText) {
			helpText = this.props.view.options.fields[field].helpText;
		}
		return this.props.editing ? (
			<span
				className="board-cell"
				style={{ display: "inline-block" }}
				onClick={(e) => {
					e.preventDefault();
				}}
			>
				<CellText
					onChange={this.updateHelptext.bind(this, field)}
					onFocus={() => {}}
					placeholder={this.props.t("board.fields.add_description.placeholder", "lägg till beskrivning")}
					onBlur={() => {}}
					value={helpText}
				/>
			</span>
		) : (
			helpText
		);
	}

	getLabel(field) {
		let label = this.getDefaultLabel(field);
		if (field in this.props.view.options.fields && this.props.view.options.fields[field].label) {
			label = this.props.view.options.fields[field].label;
		}

		const content = this.props.editing ? (
			<span
				className="board-cell"
				style={{ display: "inline-block" }}
				onClick={(e) => {
					e.preventDefault();
				}}
			>
				<CellText onChange={this.updateLabel.bind(this, field)} onFocus={() => {}} onBlur={() => {}} value={label} />
			</span>
		) : (
			label
		);

		if (this.props.view.options.fields[field]?.required) {
			return (
				<span style={{ position: "relative", display: "block" }}>
					{content} <span style={{ position: "absolute", right: -10, top: 0 }}>*</span>
				</span>
			);
		}

		return content;
	}

	updateHelptext(field, value) {
		this.props.view.options.fields[field].helpText = value.value;
		this.props.onUpdateView(this.props.view);
	}

	updateLabel(field, value) {
		this.props.view.options.fields[field].label = value.value;
		this.props.onUpdateView(this.props.view);
	}

	updateTitle(value) {
		this.props.view.options.title = value.value;
		this.props.onUpdateView(this.props.view);
	}

	updateDescription(value) {
		this.props.view.options.description = value.value;
		this.props.onUpdateView(this.props.view);
	}

	getDefaultLabel(field) {
		if (field == "title") {
			return this.props.t("board.fields.name.label", "Namn");
		} else if (field == "comment") {
			return this.props.t("board.fields.comment.label", "Kommentar");
		} else {
			for (let i = 0; i < this.props.board.columns.length; i++) {
				if (this.props.board.columns[i].id + "" == field) {
					return this.props.board.columns[i].title;
				}
			}
		}
		return field;
	}

	toggleRequired(field) {
		this.props.view.options.fields[field].required = !this.props.view.options.fields[field].required;
		this.props.onUpdateView(this.props.view);
	}

	toggleHidden(field) {
		if (field in this.props.view.options.fields) {
			this.props.view.options.fields[field].visible = !this.props.view.options.fields[field].visible;
		} else {
			this.props.view.options.fields[field] = {
				label: "",
				visible: true,
				required: false,
				helpText: "",
			};
		}
		this.props.onUpdateView(this.props.view);
	}

	getFormRow(field, component) {
		let visible = false;
		let required = false;
		if (field in this.props.view.options.fields && this.props.view.options.fields[field].visible) {
			visible = true;
			if (this.props.view.options.fields[field].required) {
				required = true;
			}
		}

		if (!visible && !this.props.editing) {
			return null;
		}
		return (
			<div key={field} className={"form-field" + (this.props.editing ? " editing" : "")} style={this.embedded ? { paddingBlock: 5 } : {}}>
				<div style={{ margin: "0 auto", width: this.width, position: "relative" }}>
					{this.props.editing ? (
						<div className="form-field-controls">
							<LegacyStack spacing="extraTight">
								{visible ? (
									<Button plain icon={required ? CancelSmallMinor : TickSmallMinor} onClick={this.toggleRequired.bind(this, field)}>
										{required
											? this.props.t("board.actions.make_not_required", "gör ej obligatorisk")
											: this.props.t("board.actions.make_required", "gör obligatorisk")}
									</Button>
								) : null}
								<Button plain icon={visible ? HideMinor : ViewMinor} onClick={this.toggleHidden.bind(this, field)} />
							</LegacyStack>
						</div>
					) : null}
					{visible ? (
						component
					) : (
						<Text variation="subdued">
							{this.getDefaultLabel(field)} {this.props.t("board.text.not_shown_in_form", "visas inte på detta formulär")}{" "}
						</Text>
					)}
				</div>
			</div>
		);
	}

	validForm() {
		// eslint-disable-next-line no-restricted-syntax
		return Object.entries(this.props.view?.options?.fields)?.every(([key, field]) => {
			if (!field?.required || !field?.visible) return true;
			return field?.required && field?.visible && this.state.form?.[key];
		});
	}

	getGroupOptions() {
		const arr = this.props.groups.map((group) => ({
			value: group.id + "",
			label: group.title,
		}));

		arr.unshift({
			value: "",
			label: this.props.t("board.group.options.top", "-- Översta gruppen --"),
		});

		return arr;
	}

	render() {
		const content = (
			<div style={{ margin: "0 auto", width: this.embedded ? "100%" : "90%" }}>
				<Card>
					<Form onSubmit={this.saveForm.bind(this)}>
						<div style={{ margin: "0 auto", width: this.width }}>
							<div style={{ paddingTop: 16, paddingBottom: 15 }}>&nbsp;</div>
							<div style={{ paddingTop: 16, paddingBottom: 15 }}>
								<Text variant="headingMd">
									{this.props.editing ? (
										<span
											className="board-cell"
											style={{ display: "inline-block" }}
											onClick={(e) => {
												e.preventDefault();
											}}
										>
											<CellText onChange={this.updateTitle.bind(this)} onFocus={() => {}} onBlur={() => {}} value={this.props.view.options.title} />
										</span>
									) : (
										this.props.view.options.title
									)}
								</Text>
								<div style={{ marginTop: 5 }}>
									<Text variation="subdued">
										{this.props.editing ? (
											<span
												className="board-cell"
												style={{ display: "inline-block" }}
												onClick={(e) => {
													e.preventDefault();
												}}
											>
												<CellText
													onChange={this.updateDescription.bind(this)}
													onFocus={() => {}}
													placeholder={this.props.t("board.fields.description.placeholder", "Skriv en beskrivning här")}
													onBlur={() => {}}
													value={this.props.view.options.description}
												/>
											</span>
										) : (
											this.props.view.options.description
										)}
									</Text>
								</div>
							</div>
						</div>
						{!this.state.submitted ? (
							<div>
								<div
									className="form-field"
									style={{
										margin: "0 auto",
										width: this.width,
										position: "relative",
									}}
								>
									<TextField
										label={this.getLabel("title")}
										disabled={this.state.saving}
										helpText={this.getHelpText("title")}
										value={this.state.form.title}
										onChange={this.updateForm.bind(this, "title")}
									/>
								</div>

								{this.props.board.columns.map((column) => {
									let component = null;
									if (column.type == "text") {
										component = (
											<TextField
												label={this.getLabel(column.id + "")}
												disabled={this.state.saving}
												helpText={this.getHelpText(column.id + "")}
												value={this.state.form[column.id + ""]}
												onChange={this.updateForm.bind(this, column.id + "")}
											/>
										);
									} else if (column.type == "status") {
										const options = column.options.statuses.map((status, index) => ({
											value: status.id + "",
											label: status.label,
										}));
										options.unshift({ value: "", label: "--" });
										component = (
											<Select
												label={this.getLabel(column.id + "")}
												disabled={this.state.saving}
												options={options}
												helpText={this.getHelpText(column.id + "")}
												value={this.state.form[column.id + ""]}
												onChange={this.updateForm.bind(this, column.id + "")}
											/>
										);
									}
									if (component) {
										return this.getFormRow(column.id + "", component);
									}
									return null;
								})}
								{this.getFormRow(
									"comment",
									<TextField
										multiline
										disabled={this.state.saving}
										label={this.getLabel("comment")}
										helpText={this.getHelpText("comment")}
										value={this.state.form.comment}
										onChange={this.updateForm.bind(this, "comment")}
									/>
								)}
								<div style={{ margin: "0 auto", width: this.width }}>
									<div
										style={{
											paddingTop: this.embedded ? 5 : 24,
											paddingBottom: this.embedded ? 5 : 24,
											textAlign: "center",
										}}
									>
										<Button
											size="large"
											primary
											onClick={this.saveForm.bind(this)}
											loading={this.state.saving}
											disabled={this.props.editing || !this.validForm()}
										>
											{this.props.t("common.actions.send", "Skicka")}
										</Button>
										{!this.props.editing && !this.validForm() ? (
											<div
												style={{
													color: "red",
													textAlign: "center",
													padding: 10,
												}}
											>
												{this.props.t("common.errors.fill_required_fields", "Var vänlig fyll i alla obligatoriska fält")}
											</div>
										) : (
											""
										)}
									</div>
									{!this.embedded && (
										<div
											style={{
												paddingTop: 16,
												paddingBottom: 15,
												textAlign: "center",
											}}
										>
											&nbsp;
										</div>
									)}{" "}
								</div>
							</div>
						) : (
							<div style={{ margin: "0 auto", width: this.width }}>
								<div
									style={{
										borderTop: "1px solid #eee",
										marginTop: 30,
										textAlign: "center",
										paddingTop: 30,
										paddingBottom: 100,
									}}
								>
									<Text variant="headingMd">{this.props.view.options.success_title}</Text>
									<div style={{ marginTop: 20 }}>
										<Text variant="headingMd" as="h2">
											{this.props.view.options.success_subtitle}
										</Text>
									</div>
								</div>
							</div>
						)}
					</Form>
				</Card>
			</div>
		);

		return (
			<div>
				{this.props.header}
				{this.embedded ? content : <Page>{content}</Page>}
				{this.props.editing ? (
					<Sheet open={this.props.showSettings} onClose={this.props.onCloseSettings} title={this.props.t("board.settings.title", "Inställningar")}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "calc(100% - 56px)",
							}}
						>
							<Scrollable style={{ padding: "1.0000rem", height: "100%" }}>
								<FormLayout>
									<Select
										label={this.props.t("board.fields.new_rows_in_group.label", "Lägg nya rader i grupp")}
										options={this.getGroupOptions()}
										value={this.props.view.options.group_id}
										onChange={(v) => {
											this.props.view.options.group_id = v;
											this.props.onUpdateView(this.props.view);
										}}
									/>
									<TextField
										label={this.props.t("board.fields.success_title.label", "Meddelande vid skickning")}
										placeholder={this.props.t("board.fields.success_title.placeholder", "Tack!")}
										value={this.props.view.options.success_title}
										onChange={(v) => {
											this.props.view.options.success_title = v;
											// eslint-disable-next-line react/no-unused-state
											this.setState({ rnd: 1 });
										}}
										onBlur={() => {
											this.props.onUpdateView(this.props.view);
										}}
									/>
									<TextField
										placeholder={this.props.t("board.fields.success_subtitle.label", "Informationen har nu tagits emot av oss")}
										value={this.props.view.options.success_subtitle}
										onChange={(v) => {
											this.props.view.options.success_subtitle = v;
											// eslint-disable-next-line react/no-unused-state
											this.setState({ rnd: 1 });
										}}
										onBlur={() => {
											this.props.onUpdateView(this.props.view);
										}}
									/>
									<TextField
										label={this.props.t("board.fields.redirect_url.label", "Redirect URL")}
										placeholder={this.props.t("board.fields.redirect_url.placeholder", "https://www.example.com/thanks")}
										value={this.props.view.options.redirect_url}
										onChange={(v) => {
											this.props.view.options.redirect_url = v;
											// eslint-disable-next-line react/no-unused-state
											this.setState({ rnd: 1 });
										}}
										onBlur={() => {
											this.props.onUpdateView(this.props.view);
										}}
										helpText={this.props.t("board.fields.redirect.url.helptext", "Lämna tom om du inte vill ha någon redirect")}
									/>
								</FormLayout>
							</Scrollable>
						</div>
					</Sheet>
				) : null}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardViewForm);
