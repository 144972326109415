/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from "react";
import { ResourceList, LegacyCard, Text, ExceptionList } from "@shopify/polaris";
import moment from "moment";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import Page from "../../components/page";
import API from "../../API";
import { store } from "../../store";
import FieldModal from "../../components/FieldModal.js";
import UsagePackageModal from "../../components/UsagePackageModal.js";
import UsageOrderModal from "../../components/UsageOrderModal.js";
import AccountModal from "./AccountModal";
import Toast from "src/js/components/Toast";

// import AccountBillingPeriodModal from "./AccountBillingPeriodModal";
// import AccountBillingEmailModal from "./AccountBillingEmailModal";
// import AccountCostModal from "./AccountCostModal";

class AccountIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentField: {
				title: "",
				color_hsl: {
					hue: 120,
					brightness: 1,
					saturation: 1,
				},
			},
			form: store.getState().account || {},
			limits: null,
			modals: {},
		};
	}

	componentDidMount() {
		this.fetchUsage();
		this.fetchLimits();
		this.fetchUsageOrders();
		// this.fetchRow();
	}

	fetchUsageOrders() {
		// this.setState({ fetchingUsageOrders: true });
		API.get("/api/usage_orders.json", {})
			.then((result) => {
				// this.setState({ fetchingUsageOrders: false });
				if (result.data.error) {
					Toast.error(result.data.error);
				}
				// this.setState({ usage_orders: result.data.usage_orders });
			})
			.catch((error) => {
				// this.setState({ fetchingUsageOrders: false });
				Toast.error(error);
			});
	}

	fetchUsage() {
		// this.setState({ fetchingUsage: true });
		API.get("/api/account/usage.json", {})
			.then((result) => {
				// this.setState({ fetchingUsage: false });
				if (result.data.error) {
					Toast.error(result.data.error);
				}
				// this.setState({ usage: result.data.usage });
			})
			.catch((error) => {
				// this.setState({ fetchingUsage: false });
				Toast.error(error);
			});
	}

	fetchLimits() {
		// this.setState({ fetchingLimits: true });
		API.get("/api/account/limits.json", {})
			.then((result) => {
				// this.setState({ fetchingLimits: false });
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.setState({ limits: result.data.limits });
			})
			.catch((error) => {
				// this.setState({ fetchingLimits: false });
				Toast.error(error);
			});
	}

	onSave(form = this.state.form) {
		// this.setState({ saving: true });
		this.state.modals.account.loading = true;
		this.setState({ modals: this.state.modals });
		API.put("/api/account.json", form, {})
			.then((result) => {
				// this.setState({ saving: false });
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.state.modals.account.open = false;
				this.setState({ form: result.data.account, modals: this.state.modals });
				store.dispatch({ type: "SET_ACCOUNT", account: result.data.account });
				Toast.success(this.props.t("account.responses.updated", "Konto uppdaterat"));
			})
			.catch((error) => {
				// this.setState({ saving: false });
				Toast.error(error);
			})
			.finally(() => {
				this.state.modals.account.loading = false;
				this.setState({ modals: this.state.modals });
			});
	}

	// formatRow(row, board) {
	// 	if (row) {
	// 		// eslint-disable-next-line no-unsafe-optional-chaining
	// 		const { avtalsperiod, faktureras, ...columns } = board.columns?.reduce((acc, column) => {
	// 			const t = column.title
	// 				?.normalize("NFD")
	// 				.replace(/\p{Diacritic}/gu, "")
	// 				?.toLowerCase();
	// 			if (t) {
	// 				acc[t] = column;
	// 			}
	// 			return acc;
	// 		}, {});
	// 		const contractPeriod = row.column_values[avtalsperiod?.id]?.value;
	// 		const basePrice = row.column_values[columns?.grundpris?.id]?.value || 0;
	// 		const amountOfUsers = row.column_values[columns?.["antal anvandare"]?.id]?.value || 0;
	// 		const amountOfUsersIncluded = row.column_values[columns?.["antal inkl grundpris"]?.id]?.value || 0;
	// 		const costPerUser = row.column_values[columns?.["pris per anvandare"]?.id]?.value || 0;

	// 		const periodStart =
	// 			row.column_values?.[faktureras?.id]?.value?.datetime &&
	// 			moment(row.column_values?.[faktureras?.id]?.value?.datetime).subtract(1, "year").format("YYYY-MM-DD");
	// 		const periodEnd = row.column_values?.[faktureras?.id]?.value?.datetime;

	// 		const monthlyCost = basePrice + (amountOfUsers - amountOfUsersIncluded) * costPerUser;

	// 		this.setState({
	// 			row: {
	// 				contractPeriod,
	// 				monthlyCost,
	// 				amountOfUsers,
	// 				periodStart,
	// 				periodEnd,
	// 			},
	// 		});
	// 	}
	// }

	// onSaveLead(form) {
	// 	this.state.modals.billing_period.loading = true;
	// 	this.setState({ modals: this.state.modals });

	// 	API.put("/api/account/lead_row.json", form)
	// 		.then((result) => {
	// 			if (result.data.error) {
	// 				console.error("error:", result.data.error);
	// 				Toast.error(result.data.error);
	// 			}
	// 			this.formatRow(result.data.row, result.data.board);
	// 		})
	// 		.catch((error) => {
	// 			console.error("error:", error);
	// 			Toast.error(error);
	// 		})
	// 		.finally(() => {
	// 			this.state.modals.billing_period.loading = false;
	// 			this.state.modals.billing_period.open = false;
	// 			this.setState({ modals: this.state.modals });
	// 		});
	// }

	editItem(item) {
		this.setState({ modalOpen: true, currentField: item });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	renderField(item) {
		const exceptions = [];

		if (item.content) {
			exceptions.push({
				icon: "notes",
				description: item.content,
			});
		}

		const exceptionList = exceptions.length ? (
			<div className="CustomerListItem__Exceptions">
				<ExceptionList items={exceptions} />
			</div>
		) : null;

		return (
			<ResourceList.Item id={item.id} onClick={this.editItem.bind(this, item)}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">
							<span className="Polaris-Badge" style={{ color: item.color_hsl.brightness >= 0.8 ? "#000" : "#fff", backgroundColor: item.color }}>
								{item.title}
							</span>
						</h3>
						<span className="CustomerListItem__Location" />
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				{moment(item.created_at).format("YYYY-MM-DD")}
				{exceptionList}
			</ResourceList.Item>
		);
	}

	onCloseModal(modal) {
		if (!this.state.modals[modal]) {
			this.state.modals[modal] = {};
		}

		this.state.modals[modal].open = false;
		this.setState({ modals: this.state.modals });
	}

	onOpenModal(modal) {
		if (!this.state.modals[modal]) {
			this.state.modals[modal] = {};
		}

		this.state.modals[modal].open = true;
		this.setState({ modals: this.state.modals });
	}

	render() {
		const account = store.getState().account;
		// const { format } = new Intl.NumberFormat(store.getState().user.locale || "en-GB", {
		// 	style: "currency",
		// 	currency: store.getState().account.currency,
		// });
		// const monthlyCost = format(this.state.row?.monthlyCost || 0) || "-";

		return (
			<Page
				title={this.props.t("account.title", "Kontoinställningar")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
			>
				<StyledGrid>
					<LegacyCard
						sectioned
						primaryFooterAction={{
							content: this.props.t("account.account_info_area.actions.change", "Ändra företgsuppgifter"),
							onAction: this.onOpenModal.bind(this, "account"),
						}}
					>
						<StyledTitle>{account.company}</StyledTitle>
						<StyledSubTitle>Org.nr: {account.orgnr}</StyledSubTitle>

						<StyledBody>
							<div>
								<StyledSubText>Postadress</StyledSubText>
								<StyledText>{account.company}</StyledText>
								<StyledText>{account.street}</StyledText>
								<StyledText>
									{account.zip} {account.city}
								</StyledText>
								<StyledText>{account.country}</StyledText>
							</div>

							<div>
								<StyledSubText>Kontaktuppgifter</StyledSubText>
								<StyledText>{account.phone}</StyledText>
								<StyledText>{account.email}</StyledText>
							</div>

							{account.fiscal_year_start_date && (
								<div>
									<StyledSubText>Räkenskapsår</StyledSubText>
									<StyledText>
										{moment(account.fiscal_year_start_date).format("YYYY-MM-DD")}
										{" > "}
										{moment(account.fiscal_year_start_date).add(1, "year").subtract(1, "day").format("YYYY-MM-DD")}
									</StyledText>
								</div>
							)}
						</StyledBody>
					</LegacyCard>

					{/* <LegacyCard sectioned>
						<StyledTitle>{this.props.t("account.billing_address_area.title", "Faktureringsadress")}</StyledTitle>

						<StyledBody>
							<div>
								<StyledSubText>
									<Trans i18nKey="account.billing_address_area.subtitle" components={{ b: <b /> }} defaults="Vi fakturerar er via <b>e-post</b>" />
								</StyledSubText>
								<StyledText>{account.email}</StyledText>
							</div>
						</StyledBody>
					</LegacyCard> */}

					{/* <LegacyCard
						sectioned
						primaryFooterAction={{
							content: this.props.t("account.period_area.actions.change", "Ändra avtalsperiod"),
							onAction: this.onOpenModal.bind(this, "billing_period"),
						}}
					>
						<StyledTitle>{this.props.t("account.period_area.title", "Betalanslet och avtalsperiod")}</StyledTitle>
						<StyledSubTitle>
							{this.props.t("account.period_area.subtitle", "Här visas betalanslet och avtalsperiod för ditt abonnemang och dina plustjänster.")}
						</StyledSubTitle>

						<StyledBody>

						<div>
								<StyledSubText>{this.props.t("account.labels.period.label", "Avtalsperiod")}</StyledSubText>
								<StyledText>{this.state.loading ? <Spinner size="small" /> : this.state.row?.contractPeriod}</StyledText>
							</div>
						</StyledBody>
					</LegacyCard> */}

					{/* <LegacyCard sectioned>
						<div>
							<StyledText>
								{this.props.t("account.labels.subscriptions_nr.label", "Abonnemangsnr")}: {store.getState().account.id}
							</StyledText>
							<StyledText>
								{this.props.t("account.labels.your_monthly_cost.label", "Din månadskostnad")}:{" "}
								{this.state.loading ? <Spinner size="small" /> : monthlyCost}
							</StyledText>
							<StyledText>
								{this.props.t("account.labels.user_count.label", "Antal användarkonton")}:{" "}
								{this.state.loading ? <Spinner size="small" /> : this.state.row?.amountOfUsers || "-"}
							</StyledText>
						</div>
					</LegacyCard> */}

					{account.id === 15 && (
						<LegacyCard
							title={this.props.t("account.business_area.title", "Affärsområden")}
							actions={[
								{
									content: this.props.t("account.business_area.actions.new", "Nytt affärsområde"),
									onAction: () => {
										this.setState({
											modalOpen: true,
											currentField: {
												title: "",
												color_hsl: {
													hue: 120,
													brightness: 1,
													saturation: 1,
												},
											},
										});
									},
								},
							]}
						>
							<ResourceList
								resourceName={{
									singular: this.props.t("account.business_area.title_singular", "affärsområde"),
									plural: this.props.t("account.business_area.title_plural", "affärsområden"),
								}}
								items={store.getState().fields}
								renderItem={this.renderField.bind(this)}
							/>
							{!!store.getState().fields.length && (
								<LegacyCard.Section>
									<Text variation="subdued"> {this.props.t("account.business_area.empty", "Inga affärsområden att visa")}</Text>
								</LegacyCard.Section>
							)}
						</LegacyCard>
					)}
				</StyledGrid>

				<AccountModal
					form={this.state.form}
					open={this.state.modals.account?.open}
					loading={this.state.modals.account?.loading}
					onClose={this.onCloseModal.bind(this, "account")}
					onSave={this.onSave.bind(this)}
				/>

				{/* <AccountBillingPeriodModal
					form={this.state.row}
					open={this.state.modals.billing_period?.open}
					loading={this.state.modals.billing_period?.loading}
					onClose={this.onCloseModal.bind(this, "billing_period")}
					onSave={this.onSaveLead.bind(this)}
				/> */}
				{/*
				<AccountBillingEmailModal
					form={this.state.leadRow}
					open={this.state.modals.billing?.open}
					loading={this.state.modals.billing?.loading}
					onClose={this.onCloseModal.bind(this, "billing")}
					onSave={this.onSaveLead.bind(this)}
				/> */}

				{/* <AccountCostModal
					form={this.state.row}
					open={this.state.modals.admin_acounts?.open}
					loading={this.state.modals.admin_acounts?.loading}
					onClose={this.onCloseModal.bind(this, "admin_acounts")}
					onSave={this.onSaveLead.bind(this)}
				/> */}

				<UsageOrderModal
					open={this.state.showPurchaseExtraModal}
					onClose={() => {
						this.setState({ showPurchaseExtraModal: false });
					}}
					onComplete={() => {
						this.setState({ showPurchaseExtraModal: false });
						this.fetchUsageOrders();
					}}
					type={this.state.type}
					limits={this.state.limits}
				/>
				<UsagePackageModal
					open={this.state.showPackageModal}
					onClose={() => {
						this.setState({ showPackageModal: false });
					}}
					onChange={() => {
						this.setState({ showPackageModal: false });
						this.fetchLimits();
					}}
					type={this.state.type}
					limits={this.state.limits}
				/>
				<FieldModal
					open={this.state.modalOpen}
					field={this.state.currentField}
					onClose={() => {
						this.setState({ modalOpen: false });
					}}
					onCreate={(source) => {
						this.setState({ modalOpen: false });
					}}
				/>
			</Page>
		);
	}
}
const mapStateToProps = (state) => ({
	account: state.account,
	fields: state.fields,
});

export default connect(mapStateToProps)(withTranslation(["account", "common"], { withRef: true })(AccountIndex));

const StyledGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	/* grid-template-areas: "info billing" "period progress"; */
	gap: 1.875rem;

	.Polaris-LegacyCard {
		height: 100%;
		margin: 0;
		display: flex;
		flex-direction: column;

		.Polaris-Card__Header {
		}

		.Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			/* height: 100%; */
			flex: 1;
		}
	}
`;

const StyledSubTitle = styled.p`
	/* padding-inline: 1.2500rem; */
`;
const StyledText = styled.p`
	margin-bottom: 0.4375rem;
`;
const StyledBody = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 1.875rem;
	gap: 1.25rem;
`;
const StyledSubText = styled.p`
	color: var(--textColor2);
	margin-bottom: 0.4375rem;
`;
const StyledTitle = styled.h2`
	font-size: 22px;
	line-height: 27px;
	font-weight: 700;
`;
