import { Icon, Pagination, Spinner, TextField, Tooltip } from "@shopify/polaris";
import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import debounce from "lodash/debounce";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../API";
import ProfileAvatar from "./ProfileAvatar";
import { store } from "../store";
import Toast from "src/js/components/Toast";
import Sheet from "./sheet";

class BoardLogg extends Component {
	constructor(props) {
		super(props);
		this.state = { limit: 50, offset: 0, count: 0 };
		this.onSearch = debounce(this.fetch.bind(this), 500, {
			leading: false,
			trailing: true,
		});
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open && this.props.open !== props.open) {
			this.fetch();
		} else if (!props.open && this.props.open !== props.open) {
			this.cancelRequests();
			this.setState({ offset: 0, count: 0, logs: [], q: "" });
		}
	}

	componentWillUnmount() {
		this.cancelRequests();
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequests() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	fetch() {
		const id = this.props.board && this.props.board.id;
		if (!id) return null;
		this.cancelRequests();
		this.setState({ loading: true });
		const params = {
			limit: this.state.limit,
			offset: this.state.offset,
			q: this.state.q,
		};
		API.get(`/api/boards/${id}/logs.json`, {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState({
					logs: result.data.logs,
					count: result.data.count,
					loading: false,
				});
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				this.setState({ loading: false });
				Toast.error(error);
			});
	}

	goPrevious() {
		if (this.state.offset > 0) {
			const offset = this.state.offset - this.state.limit;
			this.setState({ offset }, this.fetch.bind(this));
		}
	}

	goNext() {
		if (this.state.offset + this.state.limit < this.state.count) {
			const offset = this.state.offset + this.state.limit;
			this.setState({ offset }, this.fetch.bind(this));
		}
	}

	onSearchChange(q) {
		this.setState({ q, offset: 0 }, this.onSearch.bind(this));
	}

	render() {
		const { open, onClose, noSheet, board } = this.props;
		const { logs = [], loading } = this.state;
		const hasPrevious = this.state.offset > 0;
		const hasNext = this.state.offset + this.state.limit < this.state.count;

		const content = (
			<div className="boardlogg noscroll">
				<div className="boardlogg_header">
					<div className="boardlogg_close" onClick={onClose}>
						<Icon source={MobileCancelMajor} />
					</div>
					<h1 className="boardlogg_header__title">{board && board.title}&lsquo;s aktivitetslogg </h1>
					<div className="boardlogg_header__search">
						<TextField
							placeholder={this.props.t("board.logg.search.placeholder", "Sök..")}
							onChange={this.onSearchChange.bind(this)}
							value={this.state.q}
						/>
					</div>
					<div className="boardlogg_header__count">
						{loading && (!logs || !logs.length) ? (
							<span>
								{this.props.t("board.logg.loading", "Hämtar logs..")}
								<Spinner size="small" />
							</span>
						) : !logs || !logs.length ? (
							<span>{this.props.t("board.logg.empty.text", "Inga logs att visa")}</span>
						) : (
							<span>
								{this.props.t("board.logg.footer", "Visar {{from}} - {{to}} av {{total}}", {
									from: this.state.offset,
									to: this.state.offset + logs.length,
									total: this.state.count,
								})}
							</span>
						)}
					</div>
				</div>
				<div className="boardlogg_scroll noscroll">
					{logs &&
						!!logs.length &&
						this.state.logs.map((log, key) => {
							const { title, id, /* row_id, */ user_id: userId, created_at, row_title: rowTitle } = log;

							const user = userId ? store.getState().users.find((u) => u.id == userId) : { name: "Systemet" };
							return (
								<div className={`boardlogg_wrapper ${loading ? "loading" : ""}`} key={id} id={id}>
									<ProfileAvatar user={user} />
									<div className="boardlogg_inner oneline">
										<div className="boardlogg_title">
											<div>
												<b>{user ? user.name : userId}</b>
												{`- ${title}`}
											</div>
											<div className="boardlogg_created_at">
												<Tooltip content={moment(created_at).format("YYYY-MM-DD HH:mm")}>{moment(created_at).locale("sv").calendar()}</Tooltip>
											</div>
										</div>
										{rowTitle && <div className="boardlogg_text">{`Rad: ${rowTitle}`}</div>}
									</div>
								</div>
							);
						})}
					<Pagination hasPrevious={hasPrevious} onPrevious={this.goPrevious.bind(this)} hasNext={hasNext} onNext={this.goNext.bind(this)} />
				</div>
			</div>
		);

		if (noSheet) return content;

		return (
			<Sheet open={open} onClose={onClose} hideHeader>
				{content}
			</Sheet>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(BoardLogg);
