import { FormLayout } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import TextField from "src/js/components/TextField";

const CustomFieldValues = ({ form, onChange, disabled = false, t }) => {
	const getChangeHandler = (id) => (value) => {
		const valueIndex = form.custom_field_values.findIndex((field) => (field.custom_field_id || field.custom_field.id) === id);
		if (valueIndex === -1) {
			form.custom_field_values.push({
				value,
				custom_field_id: id,
			});
		} else {
			form.custom_field_values[valueIndex].value = value;
		}

		//values might be in a different array, we will see when the response is ready
		onChange(form.custom_field_values);
	};

	const customFields = form?.all_custom_fields_to_show_when_editing_article ? Object.values(form.all_custom_fields_to_show_when_editing_article) : [];

	if (!customFields?.length) return null;

	return (
		<FormLayout>
			{customFields
				?.map((field: any, index) => {
					const value = form.custom_field_values.find((f) => (f.custom_field_id || f.custom_field.id) === field.id)?.value || "";

					if (field.type === "text") {
						return <TextField key={field.id} label={field.title} value={value} onChange={getChangeHandler(field.id)} />;
					}

					if (field.type === "dropdown") {
						return (
							<Select
								key={field.id}
								disabled={disabled}
								label={field.title}
								value={value}
								onChange={getChangeHandler(field.id)}
								options={[
									{
										label: t("customfields.fields.default_value.options.select", "Välj"),
										value: "",
									},
									...(field.options || []),
								]}
							/>
						);
					}

					if (field.type === "date") {
						return <TextField key={field.id} label={field.title} value={value} onChange={getChangeHandler(field.id)} type="date" />;
					}

					return null;
				})
				.filter(Boolean)}
		</FormLayout>
	);
};
export default withTranslation(["orders", "common"])(CustomFieldValues);
