import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	margin-bottom: 1.2500rem;
	gap: 1.2500rem;
	display: flex;
	flex-direction: column;
`;

export const FortnoxRowsWrapper = styled.div`
	.row {
		margin-top: 1.0000rem;
	}
`;
export const FortnoxRowWrapper = styled.div<{ plain?: boolean }>`
	/* binvoice-bottom: 1px solid #dfe3e8; */
	display: flex;
	gap: 0.3125rem;
	--p-text-disabled: var(--textColor);

	display: grid;
	grid-template-columns: minmax(75px, 250px) minmax(200px, auto) 80px 100px 100px 120px 75px 150px 25px 25px;

	& > *:not(:last-child) {
		flex: 1;
	}

	.Polaris-Icon {
		margin: 0;
	}

	input,
	textarea,
	.Polaris-TextField__Suffix {
		font-size: 13px;
	}

	&&& {
		.Polaris-Select {
			width: 100%;
		}
	}

	.Polaris-TextField__Prefix {
		opacity: 0.5;

		button {
			display: flex;
		}

		.Polaris-Icon {
			height: 1.0938rem;
			width: 1.0938rem;
		}
	}

	.dropdown {
		opacity: 0.75;
		transition: opacity 250ms ease-in-out;

		&:hover {
			opacity: 1;
		}
	}

	${({ plain }) =>
		plain &&
		css`
			--p-text-disabled: "var(--textColor)";

			.Polaris-TextField {
				opacity: 1;
				/* -webkit-text-fill-color: var(--textColor); */
			}

			.Polaris-TextField__Backdrop {
				background: transparent !important;
			}

			.Polaris-TextField__Prefix {
				display: none;
			}

			* {
				box-shadow: none !important;
			}
		`};
`;

export const InfoWrapper = styled.div`
	--p-border-radius-base: 6px;
	--p-text-disabled: var(--textColor);

	.Polaris-Button {
		&,
		* {
			transition-property: background-color, color, box-shadow, filter;
			transition-duration: 250ms;
		}
	}

	[data-buttongroup-segmented="true"] > :first-child .Polaris-Button {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	[data-buttongroup-segmented="true"] > :last-child .Polaris-Button {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;

export const ColumnHeader = styled.div`
	flex: 1;
	margin-left: 5px;
	font-size: 13px;
`;
export const ColumnHeaderWrapper = styled.div`
	display: flex;
	font-weight: bold;
	gap: 0.3125rem;

	display: grid;
	grid-template-columns: minmax(75px, 250px) auto 80px 100px 100px 120px 75px 150px 50px;
`;
// grid-template-columns: minmax(75px, 250px) auto 100px 100px 150px 100px 150px 50px;
