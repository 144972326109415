/* eslint-disable camelcase */
import React from "react";
import { Banner } from "@shopify/polaris";
import API from "../API";
import Toast from "src/js/components/Toast";
import useQuery from "../hooks/useQuery";

const OpenNotificationBanner = ({ notification_id }) => {
	const { data: notification, isFetching } = useQuery({
		queryKey: [`notification_${notification_id}`],
		queryFn: async () => {
			try {
				const result = await API.get("/api/notifications/" + notification_id + ".json");

				return result.data?.notification;
			} catch (error) {
				Toast.error(error);
			}
		},
		enabled: !!notification_id,
	});

	if (!notification || isFetching) return null;

	return (
		<div style={{ marginBottom: 20 }}>
			<Banner title={notification.content} status="info" />
		</div>
	);
};
export default OpenNotificationBanner;
