import React from "react";

const BuildingIcon = ({ color = "var(--textColor)" }) => (
	<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.3767 7.96612V0.204956H4.67526V4.08554H0.794678V17.6676H8.55584V13.787H10.4961V17.6676H18.2573V7.96612H14.3767ZM4.67526 15.7273H2.73497V13.787H4.67526V15.7273ZM4.67526 11.8467H2.73497V9.90641H4.67526V11.8467ZM4.67526 7.96612H2.73497V6.02583H4.67526V7.96612ZM8.55584 11.8467H6.61555V9.90641H8.55584V11.8467ZM8.55584 7.96612H6.61555V6.02583H8.55584V7.96612ZM8.55584 4.08554H6.61555V2.14525H8.55584V4.08554ZM12.4364 11.8467H10.4961V9.90641H12.4364V11.8467ZM12.4364 7.96612H10.4961V6.02583H12.4364V7.96612ZM12.4364 4.08554H10.4961V2.14525H12.4364V4.08554ZM16.317 15.7273H14.3767V13.787H16.317V15.7273ZM16.317 11.8467H14.3767V9.90641H16.317V11.8467Z"
			fill={color}
		/>
	</svg>
);

export default BuildingIcon;
