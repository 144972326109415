const findOptionPathLabel = (options, value) => {
	return options.reduce((found, option) => {
		if (found) return found; // If the path is already found, return it

		if (option.value === value) {
			return option.label; // Return the label of the matching option
		}

		if (option.options && option.options.length > 0) {
			const nestedPath = findOptionPathLabel(option.options, value); // Recursively search nested options
			if (nestedPath) {
				return `${option.label} → ${nestedPath}`; // Concatenate labels to build the path
			}
		}

		return null; // Continue searching if no match is found
	}, null);
};

export default findOptionPathLabel;
