/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import FortnoxOrdersContactList from "./FortnoxOrdersContactList";

type FortnoxOrdersListProps = {
	history: any;
} & WithTranslation;

const FortnoxOrdersList = ({ ...props }: FortnoxOrdersListProps) => {
	return (
		<Page title={props.t("fortnox.orders.list.title", "Ordrar") as string} history={props.history}>
			<FortnoxOrdersContactList {...props} history={props.history} />
		</Page>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOrdersList);
