import moment from "moment";

const getMutableContract = (contract: FortnoxContractType | null): Partial<FortnoxContractType> => {
	const ignoredKeys: string[] = ["BasisTaxReduction", "TotalToPay", "TotalExcludingVAT"];

	return Object.entries(contract || {}).reduce(
		(acc, [key, value]) => {
			if (ignoredKeys.includes(key)) {
				return acc;
			}

			if (key === "InvoiceRows") {
				acc[key] = getMutableInvoiceRows(value as (FortnoxInvoiceRowType | Partial<FortnoxInvoiceRowType>)[]);
				return acc;
			}

			acc[key] = value;
			return acc;
		},
		{
			InvoiceRows: [],
			ContractDate: moment().format("YYYY-MM-DD"),
			PeriodStart: moment().add(1, "months").startOf("month").format("YYYY-MM-DD"),
			PeriodEnd: moment(moment().add(1, "months").startOf("month").format("YYYY-MM-DD")).add(1, "months").startOf("month").format("YYYY-MM-DD"),
			Active: true,
			TermsOfPayment: contract?.TermsOfPayment || "30",
		} as Partial<FortnoxContractType>
	);
};
export default getMutableContract;

const getMutableInvoiceRows = (invoiceRows: (FortnoxInvoiceRowType | Partial<FortnoxInvoiceRowType>)[]): Partial<FortnoxInvoiceRowType>[] => {
	const ignoredKeys = ["TotalExcludingVAT"];

	return (
		(invoiceRows.map((invoiceRow) => {
			return Object.keys(invoiceRow || {})
				.filter((key) => !ignoredKeys.includes(key))
				.reduce((acc, key) => {
					acc[key] = invoiceRow?.[key];
					return acc;
				}, {} as Partial<FortnoxInvoiceRowType>);
		}) as Partial<FortnoxInvoiceRowType>[]) || []
	);
};
