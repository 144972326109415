import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Wrapper } from "../styles";
import Tabs from "src/js/components/Tabs";

type HeaderProps = {
	article: Partial<FortnoxArticleType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const Header = ({ article, tabs, onSelect, selected, t }: HeaderProps) => {
	return (
		<Wrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(Header);
