import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Page from "src/js/components/page";
import SplashLoadingScreen from "../../SplashLoadingScreen";
import PDFEditorViews from "./PDFEditorView";
import Sheets from "./Sheets";
import Modals from "./Modals";

const Statsbox = React.lazy(() => import("./Statsbox"));
const DatePickers = React.lazy(() => import("./Datepicker"));
const Buttons = React.lazy(() => import("./Button"));
const ComponentsIndex = React.lazy(() => import("."));
const Icons = React.lazy(() => import("./Icons"));
const Acitivites = React.lazy(() => import("./Acitivites"));
const PolarisIcons = React.lazy(() => import("./PolarisIcons"));
const TextFields = React.lazy(() => import("./TextFields"));

export const routes = [
	{
		path: "/admin/components/statsbox",
		component: Statsbox,
		title: "Statsbox",
		searchTerms: ["statsbox", "stats", "box"],
	},
	{
		path: "/admin/components/datepicker",
		component: DatePickers,
		title: "DatePicker",
		searchTerms: ["date", "time", "calendar"],
	},
	{
		path: "/admin/components/buttons",
		component: Buttons,
		title: "Buttons",
		searchTerms: ["button"],
	},
	{
		path: "/admin/components/icons",
		component: Icons,
		title: "Icons",
		searchTerms: ["icon"],
	},
	{
		path: "/admin/components/polairs/icons",
		component: PolarisIcons,
		title: "Polaris icons",
		searchTerms: ["icon", "polaris"],
	},
	{
		path: "/admin/components/pdf_editors",
		component: PDFEditorViews,
		title: "PDF editors",
		searchTerms: ["pdf", "editor", "fabric"],
	},
	{
		path: "/admin/components/sheets",
		component: Sheets,
		title: "Sheets",
		searchTerms: ["sheet"],
	},
	{
		path: "/admin/components/modals",
		component: Modals,
		title: "Modals",
		searchTerms: ["modal"],
	},
	{
		path: "/admin/components/textfields",
		component: TextFields,
		title: "TextFields",
		searchTerms: ["textfields", "text", "fields"],
	},
	{
		path: "/admin/components/acitivites",
		component: Acitivites,
		title: "LateActivity/UpcomingActivity/Timeline (comments/acitivities)",
		searchTerms: ["acitivites", "acitivity", "timeline", "comment", "LateActivity", "UpcomingActivity"],
	},
	{
		path: "/admin/components",
		exact: true,
		component: ComponentsIndex,
	},
	{
		path: "/",
		redirect: "/admin/components",
	},
];

class ComponentRoutes extends Component {
	render() {
		return (
			<Wrapper>
				<Page backAction={{ content: "Tillbaka", onAction: () => this.props.history.goBack() }} fullWidth>
					<Suspense
						fallback={
							<SplashLoadingScreen>
								<p style={{ textAlign: "center" }}>Loading component</p>
							</SplashLoadingScreen>
						}
					>
						<Switch>
							{routes.map((prop, key) => {
								if (prop.redirect) return <Redirect from={prop.path} to={prop.redirect} key={key} />;

								return <Route path={prop.path} exact={prop.exact} component={prop.component} key={key} />;
							})}
						</Switch>
					</Suspense>
				</Page>
			</Wrapper>
		);
	}
}

export default ComponentRoutes;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3.125rem;

	.Polaris-Layout__AnnotationContent {
		padding: 1.25rem 1.25rem 1.25rem 0;
	}
`;
