import React, { Component } from "react";

export default class FortnoxTableInputColumn extends Component {
	constructor(props) {
		super(props);
		this.state = { value: props.value || "" };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value || "" });
	}

	updateValue() {
		this.props.onChange(this.state.value);
	}

	render() {
		return (
			<input
				type="text"
				value={this.state.value}
				onChange={(e) => {
					this.setState({ value: e.target.value }, this.updateValue.bind(this));
				}}
			/>
		);
	}
}
