import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import ItemCard from "src/js/components/ItemCard";
import truncateString from "src/js/Utilities/truncateString";
import getPeriodLabel from "../Utilities/getPeriodLabel";
import IndexTable from "src/js/components/IndexTable";

const TargetsList = ({ history, t }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const handleOnClick = (item) => {
		history.push(`/admin/targets/${item.id}`);
	};

	const renderItem = (item) => {
		return (
			<ItemCard
				title={<>{item.title}</>}
				leftColumn={[
					{
						label: t("targets.fields.status.label", "Status"),
						value: item.disabled ? t("common.inactive", "Inaktiv") : t("common.active", "Aktiv"),
					},
					{
						label: t("targets.fields.description.label", "Beskrivning"),
						value: truncateString(item.description ?? "", 20),
					},
				]}
				rightColumn={[
					{
						label: t("targets.fields.started_at.label", "Startdatum"), // Ska vi vissa detta, och isåfal ändra så det blir current date in period?
						value: item.started_at,
					},
					{
						label: t("targets.fields.period_type.label", "Period typ"),
						value: getPeriodLabel(item.period_type),
					},
					{
						label: t("targets.fields.number_of_periods_per_group.label", "Antal perioder per grupp"),
						value: item.number_of_periods_per_group,
					},
				]}
			/>
		);
	};

	return (
		<IndexTable
			title={t("targets.title", "Mål") as string}
			history={history}
			primaryAction={{
				content: t("targets.new", "Nytt mål"),
				url: "/admin/targets/create",
			}}
			defaultFilters={[
				{
					key: "disabled",
					value: 0,
				},
			]}
			ref={ref}
			// params={params}
			onClick={handleOnClick}
			params={{
				permissions: ["edit_permission"],
			}}
			// filters={filters}
			resourceUrl={"/api/targets.json"}
			resourceName={{ singular: "Mål", plural: "Mål" }}
			resourceHandle={"targets"}
			columns={[
				{
					handle: "",
					label: "",
					render: renderItem,
				},
			]}
			// promotedBulkActions={promotedBulkActions}
			bulkActions={[]}
		/>
	);
};
export default withTranslation(["targets", "common"])(TargetsList);
