import i18next from "i18next";

const contact = {
	name: i18next.t("contact.fields.name.label", "Namn"),
	firstName: i18next.t("contact.fields.first_name.label", "Förnamn"),
	lastName: i18next.t("contact.fields.last_name.label", "Efternamn"),
	isCompany: i18next.t("contact.fields.isCompany.label", "Är Företag"),
	title: i18next.t("contact.fields.title.label", "Befattning/Roll"),
	orgnr: i18next.t("contact.fields.orgnr.label", "Organisationsnummer"),
	fullname: i18next.t("contact.fields.fullname.label", "Fullständigt namn"),
	note: i18next.t("contact.fields.note.label", "Notering"),
	email: i18next.t("contact.fields.email.label", "E-post"),
	emailDomain: i18next.t("contact.fields.email_domain.label", "E-postdomän"),
	phone: i18next.t("contact.fields.phone.label", "Telefon"),
	mobilePhone: i18next.t("contact.fields.mobile_phone.label", "Mobiltelefon"),
	mobilePhone2: i18next.t("contact.fields.mobile_phone2.label", "Mobiltelefon 2"),
	country: i18next.t("contact.fields.country.label", "Land"),
	removed: i18next.t("contact.fields.removed.label", "Borttagen"),
	archived: i18next.t("contact.fields.archived.label", "Arkiverad"),
	isVisitor: i18next.t("contact.fields.is_visitor.label", "Besökare"),
	emailOpted: i18next.t("contact.fields.email_opted.label", "E-postopt-in"),
	emailUnsubscribed: i18next.t("contact.fields.email_unsubscribed.label", "E-postavregistrerad"),
	partnerSourceId: i18next.t("contact.fields.partner_source_id.label", "Partnerkälla"),
	hiddenVisitor: i18next.t("contact.fields.hidden_visitor.label", "Dold besökare"),
	emailCampaigns: i18next.t("contact.fields.email_campaigns.label", "E-postkampanjer"),
	lastEmailReceivedAt: i18next.t("contact.fields.last_email_received_at.label", "Senaste e-post mottagen"),
	lastEmailOpenedAt: i18next.t("contact.fields.last_email_opened_at.label", "Senaste e-post öppnad"),
	lastEmailClickedAt: i18next.t("contact.fields.last_email_clicked_at.label", "Senaste e-post klickad"),
	lastVisitorSessionAt: i18next.t("contact.fields.last_visitor_session_at.label", "Senaste besökarsession"),
	avatar: i18next.t("contact.fields.avatar.label", "Profilbild"),
	tags: i18next.t("contact.fields.tags.label", "Taggar"),
	street: i18next.t("contact.fields.street.label", "Gata"),
	postalStreet: i18next.t("contact.fields.postal_street.label", "Gata (postadress)"),
	reference: i18next.t("contact.fields.reference.label", "Referens"),
	parent: i18next.t("contact.fields.parent.label", "Förälder"),
	defaultChild: i18next.t("contact.fields.default_child.label", "Standardbarn"),
	parentId: i18next.t("contact.fields.parent_id.label", "Förälder ID"),
	children: i18next.t("contact.fields.children.label", "Barn"),
	address1: i18next.t("contact.fields.address1.label", "Adress"),
	address2: i18next.t("contact.fields.address2.label", "Adress 2"),
	zip: i18next.t("contact.fields.zip.label", "Postnummer"),
	city: i18next.t("contact.fields.city.label", "Stad"),
	usePostal: i18next.t("contact.fields.use_postal.label", "Använd postadress"),
	postalAddress1: i18next.t("contact.fields.postal_address1.label", "Postadress 1"),
	postalAddress2: i18next.t("contact.fields.postal_address2.label", "Postadress 2"),
	postalCity: i18next.t("contact.fields.postal_city.label", "Postort"),
	postalZip: i18next.t("contact.fields.postal_zip.label", "Postnummer"),
	addresses: i18next.t("contact.fields.addresses.label", "Adresser"),
	fortnoxCustomerId: i18next.t("contact.fields.fortnox_customer_id.label", "Fortnox kundnummer"),
	customerNumber: i18next.t("contact.fields.customer_number.label", "Kundnummer"),
};

export default contact;
