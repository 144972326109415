import { Checkbox, FormLayout, Layout } from "@shopify/polaris";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import TextField from "src/js/components/TextField";
import Page from "src/js/components/page";
import { store } from "src/js/store";
import Toast from "src/js/components/Toast";

const OrderSettings = ({ t }) => {
	const [form, setForm] = useState(store.getState().account.account_order_settings);

	const handleChange = (field: string) => (value: any) => {
		setForm((c) => ({ ...c, [field]: value }));
	};

	const handleSave = async () => {
		try {
			const res = await API.put(`/api/order_settings.json`, form);

			Toast.success(t("orders.responses.account.updated", "Konto uppdaterat"));

			store.dispatch({ type: "SET_ACCOUNT", account: res.data.account });
			setForm(res.data.account.account_order_settings);

			return res;
		} catch (error) {
			Toast.error(error);
		}
	};

	return (
		<Page
			title={t("orders.settings.title", "Orderinställningar")}
			primaryAction={{
				content: t("common.save", "Spara"),
				onAction: handleSave,
			}}
		>
			<Layout sectioned>
				<FormLayout>
					<TextField
						label={t("orders.settings.order_starting_number.label2", "Ordrar startnummer")}
						value={form?.order_starting_number}
						onChange={handleChange("order_starting_number")}
					/>
					<Checkbox
						label={t("orders.settings.can_not_see_tb1_when_placing_order.label", "Kan se TB1 vid orderläggning")}
						checked={!form?.can_not_see_tb1_when_placing_order}
						onChange={(checked) => handleChange("can_not_see_tb1_when_placing_order")(!checked)}
					/>
					<Checkbox
						label={t("orders.settings.can_not_see_tb2_when_placing_order.label", "Kan se TB2 vid orderläggning")}
						checked={!form?.can_not_see_tb2_when_placing_order}
						onChange={(checked) => handleChange("can_not_see_tb2_when_placing_order")(!checked)}
					/>
				</FormLayout>
			</Layout>
		</Page>
	);
};
export default withTranslation(["orders", "common"])(OrderSettings);
