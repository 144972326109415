/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import FortnoxTableAutoCompleteColumn from "./FortnoxTableAutoCompleteColumn";
import FortnoxTableInputColumn from "./FortnoxTableInputColumn";
import FortnoxTableTextColumn from "./FortnoxTableTextColumn";
import FortnoxTableSummaryColumn from "./FortnoxTableSummaryColumn";

/**
 * @typedef {Object} ColumnProps
 * @param {String} [type] - properties of column
 * @param {ColumnProps} [column] - properties of column
 * @param {Object} data - data of the column
 */
export default class FortnoxTableColumn extends Component {
	getColumnType(props) {
		switch (props.type) {
			case "autoComplete":
				return <FortnoxTableAutoCompleteColumn {...props} />;
			case "input":
				return <FortnoxTableInputColumn {...props} />;
			case "text":
				return <FortnoxTableTextColumn {...props} />;
			case "summary":
				return <FortnoxTableSummaryColumn {...props} />;
			default:
				return <FortnoxTableTextColumn {...props} />;
		}
	}

	render() {
		return <td className="Fortnox__Table__Column">{this.getColumnType(this.props)}</td>;
	}
}
