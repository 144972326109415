import i18next from "i18next";

const Options = {
	label: i18next.t("fortnox.invoice.label", "Faktura"),
	value: "Fortnox.Invoice",
	options: [
		{ label: i18next.t("fortnox.invoice.fields.url.label", "URL"), value: "Fortnox.Invoice.@url" },
		{
			label: i18next.t("fortnox.invoice.fields.urlTaxReductionList.label", "URL för skattereduktion"),
			value: "Fortnox.Invoice.@urlTaxReductionList",
		},
		{
			label: i18next.t("fortnox.invoice.fields.administrationFee.label", "Administrationsavgift"),
			value: "Fortnox.Invoice.AdministrationFee",
		},
		{
			label: i18next.t("fortnox.invoice.fields.administrationFeeVAT.label", "Moms på administrationsavgift"),
			value: "Fortnox.Invoice.AdministrationFeeVAT",
		},
		{ label: i18next.t("fortnox.invoice.fields.address1.label", "Adress 1"), value: "Fortnox.Invoice.Address1" },
		{ label: i18next.t("fortnox.invoice.fields.address2.label", "Adress 2"), value: "Fortnox.Invoice.Address2" },
		{ label: i18next.t("fortnox.invoice.fields.balance.label", "Saldo"), value: "Fortnox.Invoice.Balance" },
		{
			label: i18next.t("fortnox.invoice.fields.basisTaxReduction.label", "Grund för skattereduktion"),
			value: "Fortnox.Invoice.BasisTaxReduction",
		},
		{ label: i18next.t("fortnox.invoice.fields.booked.label", "Bokförd"), value: "Fortnox.Invoice.Booked" },
		{ label: i18next.t("fortnox.invoice.fields.cancelled.label", "Annullerad"), value: "Fortnox.Invoice.Cancelled" },
		{ label: i18next.t("fortnox.invoice.fields.city.label", "Stad"), value: "Fortnox.Invoice.City" },
		{ label: i18next.t("fortnox.invoice.fields.comments.label", "Kommentarer"), value: "Fortnox.Invoice.Comments" },
		{ label: i18next.t("fortnox.invoice.fields.contractReference.label", "Kontraktsreferens"), value: "Fortnox.Invoice.ContractReference" },
		{ label: i18next.t("fortnox.invoice.fields.contributionPercent.label", "Bidragsprocent"), value: "Fortnox.Invoice.ContributionPercent" },
		{ label: i18next.t("fortnox.invoice.fields.contributionValue.label", "Bidragsvärde"), value: "Fortnox.Invoice.ContributionValue" },
		{ label: i18next.t("fortnox.invoice.fields.country.label", "Land"), value: "Fortnox.Invoice.Country" },
		{ label: i18next.t("fortnox.invoice.fields.costCenter.label", "Kostnadsställe"), value: "Fortnox.Invoice.CostCenter" },
		{ label: i18next.t("fortnox.invoice.fields.credit.label", "Kredit"), value: "Fortnox.Invoice.Credit" },
		{
			label: i18next.t("fortnox.invoice.fields.creditInvoiceReference.label", "Referens för kreditfaktura"),
			value: "Fortnox.Invoice.CreditInvoiceReference",
		},
		{ label: i18next.t("fortnox.invoice.fields.currency.label", "Valuta"), value: "Fortnox.Invoice.Currency" },
		{ label: i18next.t("fortnox.invoice.fields.currencyRate.label", "Valutakurs"), value: "Fortnox.Invoice.CurrencyRate" },
		{ label: i18next.t("fortnox.invoice.fields.currencyUnit.label", "Valutaenhet"), value: "Fortnox.Invoice.CurrencyUnit" },
		{ label: i18next.t("fortnox.invoice.fields.customerName.label", "Kundnamn"), value: "Fortnox.Invoice.CustomerName" },
		{ label: i18next.t("fortnox.invoice.fields.customerNumber.label", "Kundnummer"), value: "Fortnox.Invoice.CustomerNumber" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryAddress1.label", "Leveransadress 1"), value: "Fortnox.Invoice.DeliveryAddress1" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryAddress2.label", "Leveransadress 2"), value: "Fortnox.Invoice.DeliveryAddress2" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryCity.label", "Leveransstad"), value: "Fortnox.Invoice.DeliveryCity" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryCountry.label", "Leveransland"), value: "Fortnox.Invoice.DeliveryCountry" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryDate.label", "Leveransdatum"), value: "Fortnox.Invoice.DeliveryDate" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryName.label", "Leveransnamn"), value: "Fortnox.Invoice.DeliveryName" },
		{ label: i18next.t("fortnox.invoice.fields.deliveryZipCode.label", "Postnummer för leverans"), value: "Fortnox.Invoice.DeliveryZipCode" },
		{ label: i18next.t("fortnox.invoice.fields.documentNumber.label", "Dokumentnummer"), value: "Fortnox.Invoice.DocumentNumber" },
		{ label: i18next.t("fortnox.invoice.fields.dueDate.label", "Förfallodatum"), value: "Fortnox.Invoice.DueDate" },
		{ label: i18next.t("fortnox.invoice.fields.eUQuarterlyReport.label", "EU kvartalsrapport"), value: "Fortnox.Invoice.EUQuarterlyReport" },

		{
			label: i18next.t("fortnox.invoice.fields.emailInformation.label", "E-postinformation"),
			value: "Fortnox.Invoice.EmailInformation",
			options: [
				{
					label: i18next.t("fortnox.invoice.fields.emailInformation.emailAddressFrom.label", "E-postadress Från"),
					value: "Fortnox.Invoice.EmailInformation.EmailAddressFrom",
				},
				{
					label: i18next.t("fortnox.invoice.fields.emailInformation.emailAddressTo.label", "E-postadress Till"),
					value: "Fortnox.Invoice.EmailInformation.EmailAddressTo",
				},
				{
					label: i18next.t("fortnox.invoice.fields.emailInformation.emailAddressCC.label", "E-postadress CC"),
					value: "Fortnox.Invoice.EmailInformation.EmailAddressCC",
				},
				{
					label: i18next.t("fortnox.invoice.fields.emailInformation.emailAddressBCC.label", "E-postadress BCC"),
					value: "Fortnox.Invoice.EmailInformation.EmailAddressBCC",
				},
				{
					label: i18next.t("fortnox.invoice.fields.emailInformation.emailSubject.label", "E-postämne"),
					value: "Fortnox.Invoice.EmailInformation.EmailSubject",
				},
				{
					label: i18next.t("fortnox.invoice.fields.emailInformation.emailBody.label", "E-postkropp"),
					value: "Fortnox.Invoice.EmailInformation.EmailBody",
				},
			],
		},

		{
			label: i18next.t("fortnox.invoice.fields.externalInvoiceReference1.label", "Extern fakturareferens 1"),
			value: "Fortnox.Invoice.ExternalInvoiceReference1",
		},
		{
			label: i18next.t("fortnox.invoice.fields.externalInvoiceReference2.label", "Extern fakturareferens 2"),
			value: "Fortnox.Invoice.ExternalInvoiceReference2",
		},
		{ label: i18next.t("fortnox.invoice.fields.freight.label", "Frakt"), value: "Fortnox.Invoice.Freight" },
		{ label: i18next.t("fortnox.invoice.fields.freightVAT.label", "Moms på frakt"), value: "Fortnox.Invoice.FreightVAT" },
		{ label: i18next.t("fortnox.invoice.fields.gross.label", "Brutto"), value: "Fortnox.Invoice.Gross" },
		{ label: i18next.t("fortnox.invoice.fields.houseWork.label", "Hushållsarbete"), value: "Fortnox.Invoice.HouseWork" },
		{ label: i18next.t("fortnox.invoice.fields.invoiceDate.label", "Fakturadatum"), value: "Fortnox.Invoice.InvoiceDate" },
		{
			label: i18next.t("fortnox.invoice.fields.invoicePeriodStart.label", "Startdatum för fakturaperiod"),
			value: "Fortnox.Invoice.InvoicePeriodStart",
		},
		{
			label: i18next.t("fortnox.invoice.fields.invoicePeriodEnd.label", "Slutdatum för fakturaperiod"),
			value: "Fortnox.Invoice.InvoicePeriodEnd",
		},

		{ label: i18next.t("fortnox.invoice.fields.invoiceType.label", "Fakturatyp"), value: "Fortnox.Invoice.InvoiceType" },
		{ label: i18next.t("fortnox.invoice.fields.language.label", "Språk"), value: "Fortnox.Invoice.Language" },
		{
			label: i18next.t("fortnox.invoice.fields.lastRemindDate.label", "Datum för senaste påminnelse"),
			value: "Fortnox.Invoice.LastRemindDate",
		},
		{ label: i18next.t("fortnox.invoice.fields.net.label", "Netto"), value: "Fortnox.Invoice.Net" },
		{ label: i18next.t("fortnox.invoice.fields.notCompleted.label", "Ej slutförd"), value: "Fortnox.Invoice.NotCompleted" },
		{ label: i18next.t("fortnox.invoice.fields.oCR.label", "OCR"), value: "Fortnox.Invoice.OCR" },
		{ label: i18next.t("fortnox.invoice.fields.offerReference.label", "Anbudreferens"), value: "Fortnox.Invoice.OfferReference" },
		{ label: i18next.t("fortnox.invoice.fields.orderReference.label", "Orderreferens"), value: "Fortnox.Invoice.OrderReference" },
		{
			label: i18next.t("fortnox.invoice.fields.organisationNumber.label", "Organisationsnummer"),
			value: "Fortnox.Invoice.OrganisationNumber",
		},
		{ label: i18next.t("fortnox.invoice.fields.ourReference.label", "Vår referens"), value: "Fortnox.Invoice.OurReference" },
		{ label: i18next.t("fortnox.invoice.fields.paymentWay.label", "Betalningssätt"), value: "Fortnox.Invoice.PaymentWay" },
		{ label: i18next.t("fortnox.invoice.fields.phone1.label", "Telefon 1"), value: "Fortnox.Invoice.Phone1" },
		{ label: i18next.t("fortnox.invoice.fields.phone2.label", "Telefon 2"), value: "Fortnox.Invoice.Phone2" },
		{ label: i18next.t("fortnox.invoice.fields.priceList.label", "Prislista"), value: "Fortnox.Invoice.PriceList" },
		{ label: i18next.t("fortnox.invoice.fields.printTemplate.label", "Utskriftsmall"), value: "Fortnox.Invoice.PrintTemplate" },
		{ label: i18next.t("fortnox.invoice.fields.project.label", "Projekt"), value: "Fortnox.Invoice.Project" },
		{ label: i18next.t("fortnox.invoice.fields.remarks.label", "Anmärkningar"), value: "Fortnox.Invoice.Remarks" },
		{ label: i18next.t("fortnox.invoice.fields.reminders.label", "Påminnelser"), value: "Fortnox.Invoice.Reminders" },
		{ label: i18next.t("fortnox.invoice.fields.roundOff.label", "Avrundning"), value: "Fortnox.Invoice.RoundOff" },
		{ label: i18next.t("fortnox.invoice.fields.sent.label", "Skickad"), value: "Fortnox.Invoice.Sent" },
		{ label: i18next.t("fortnox.invoice.fields.taxReduction.label", "Skattereduktion"), value: "Fortnox.Invoice.TaxReduction" },
		{ label: i18next.t("fortnox.invoice.fields.termsOfDelivery.label", "Leveransvillkor"), value: "Fortnox.Invoice.TermsOfDelivery" },
		{ label: i18next.t("fortnox.invoice.fields.termsOfPayment.label", "Betalningsvillkor"), value: "Fortnox.Invoice.TermsOfPayment" },
		{ label: i18next.t("fortnox.invoice.fields.timeBasisReference.label", "Referenstid"), value: "Fortnox.Invoice.TimeBasisReference" },
		{ label: i18next.t("fortnox.invoice.fields.total.label", "Totalt"), value: "Fortnox.Invoice.Total" },
		{ label: i18next.t("fortnox.invoice.fields.totalToPay.label", "Totalt att betala"), value: "Fortnox.Invoice.TotalToPay" },
		{ label: i18next.t("fortnox.invoice.fields.totalVAT.label", "Total moms"), value: "Fortnox.Invoice.TotalVAT" },
		{ label: i18next.t("fortnox.invoice.fields.vATIncluded.label", "Moms inkluderad"), value: "Fortnox.Invoice.VATIncluded" },
		{ label: i18next.t("fortnox.invoice.fields.voucherNumber.label", "Vouchersnummer"), value: "Fortnox.Invoice.VoucherNumber" },
		{ label: i18next.t("fortnox.invoice.fields.voucherSeries.label", "Voucherserie"), value: "Fortnox.Invoice.VoucherSeries" },
		{ label: i18next.t("fortnox.invoice.fields.voucherYear.label", "Voucherår"), value: "Fortnox.Invoice.VoucherYear" },
		{ label: i18next.t("fortnox.invoice.fields.wayOfDelivery.label", "Leveranssätt"), value: "Fortnox.Invoice.WayOfDelivery" },
		{ label: i18next.t("fortnox.invoice.fields.yourOrderNumber.label", "Ditt ordernummer"), value: "Fortnox.Invoice.YourOrderNumber" },
		{ label: i18next.t("fortnox.invoice.fields.yourReference.label", "Din referens"), value: "Fortnox.Invoice.YourReference" },
		{ label: i18next.t("fortnox.invoice.fields.zipCode.label", "Postnummer"), value: "Fortnox.Invoice.ZipCode" },
		{ label: i18next.t("fortnox.invoice.fields.accountingMethod.label", "Redovisningsmetod"), value: "Fortnox.Invoice.AccountingMethod" },
		{
			label: i18next.t("fortnox.invoice.fields.taxReductionType.label", "Typ av skattereduktion"),
			value: "Fortnox.Invoice.TaxReductionType",
		},
		{ label: i18next.t("fortnox.invoice.fields.finalPayDate.label", "Slutligt betalningsdatum"), value: "Fortnox.Invoice.FinalPayDate" },
		{
			label: i18next.t("fortnox.invoice.fields.rows.label", "Rader"),
			value: "Fortnox.Invoice.InvoiceRows",
			type: "array",
			options: [
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.accountNumber.label", "Kontonummer"),
					value: "AccountNumber",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.articleNumber.label", "Artikelnummer"),
					value: "ArticleNumber",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.contributionPercent.label", "Bidragsprocent"),
					value: "ContributionPercent",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.contributionValue.label", "Bidragsvärde"),
					value: "ContributionValue",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.costCenter.label", "Kostnadsställe"),
					value: "CostCenter",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.deliveredQuantity.label", "Levererad kvantitet"),
					value: "DeliveredQuantity",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.description.label", "Beskrivning"),
					value: "Description",
				},
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.discount.label", "Rabatt"), value: "Discount" },
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.discountType.label", "Rabattyp"),
					value: "DiscountType",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.houseWork.label", "Hushållsarbete"),
					value: "HouseWork",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.houseWorkHoursToReport.label", "Timmar för hushållsarbete att rapportera"),
					value: "HouseWorkHoursToReport",
				},
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.houseWorkType.label", "Typ av hushållsarbete"),
					value: "HouseWorkType",
				},
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.price.label", "Pris"), value: "Price" },
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.project.label", "Projekt"), value: "Project" },
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.rowId.label", "Rad-ID"), value: "RowId" },
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.stockPointCode.label", "Lagerplatskod"),
					value: "StockPointCode",
				},
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.total.label", "Totalt"), value: "Total" },
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.unit.label", "Enhet"), value: "Unit" },
				{ label: i18next.t("fortnox.invoice.fields.invoiceRows.vAT.label", "Moms"), value: "VAT" },
				{
					label: i18next.t("fortnox.invoice.fields.invoiceRows.priceExcludingVAT.label", "Pris exklusive moms"),
					value: "PriceExcludingVAT",
				},
			],
		},
	],
};

export default Options;
