import React, { useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/sv"; // Import Swedish locale
import { useSelector } from "react-redux";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import Moment from "react-moment";
import API from "src/js/API";
import Toast from "src/js/components/Toast";
import Badge from "src/js/components/Badge";
import nl2br from "react-nl2br";
// import { useNotificationCenter } from "react-toastify/addons/use-notification-center";

type NotificationItemProps = {
	notification: NotificationType | Partial<NotificationType>;
	payload?: any;
	history?: any;
	markNotificationAsRead?: (id: number) => void;
	closeSheet?: () => void;
};

const NotificationItem = ({ notification, payload, history, markNotificationAsRead, closeSheet, ...rest }: NotificationItemProps) => {
	const { content, title, created_at: createdAt, creator } = notification || {};
	const fetchSingelNotification = useCallback(async (id) => {
		try {
			const result = await API.get(`/api/notifications/${id}.json`);
			return result.data.notification;
		} catch (e) {
			Toast.error(e);
		}
	}, []);

	const user = useSelector((state: any) => (creator ? state.users.find((u) => u.id == creator.id) || null : null));

	const getUrl = useCallback(
		async (notification) => {
			if (notification?.id) {
				return notification?.url;
			} else if (payload?.fcmOptions?.link) {
				return payload?.fcmOptions?.link;
			}
		},
		[payload?.fcmOptions?.link]
	);

	const onClick = useCallback(
		async (e) => {
			if (markNotificationAsRead) {
				e?.preventDefault();
				e?.stopPropagation();
			}
			const id = notification?.id;

			const fullNotification = await fetchSingelNotification(id);

			const url = await getUrl(fullNotification);
			const regex = /https:\/\/[\w.-]+?\.(sellfinity|acordo)\.com/;
			const relativUrl = url.replace(regex, "");
			const isSellfinityUrl = !!url.match(regex)?.length;

			const urlObject = (() => {
				try {
					return new URL(url);
				} catch (e) {
					return null;
				}
			})();

			const shouldMarkAsReadFromId = (fullNotification?.unread || !("unread" in notification)) && id && markNotificationAsRead;
			if (shouldMarkAsReadFromId) markNotificationAsRead(id as number);
			notification.unread = false;

			if (!isSellfinityUrl) {
				window.open(url, "_blank");
			} else if (relativUrl) {
				if (relativUrl.includes("/api/") && url) {
					window.open(url, "_blank");
					return;
				}

				if (urlObject?.pathname !== "/admin/" && urlObject?.pathname !== "/admin") {
					history.push(relativUrl);
				}
			}

			if (closeSheet) closeSheet();
		},
		[getUrl, history, fetchSingelNotification, markNotificationAsRead, notification, closeSheet]
	);

	if (!notification) return null;

	const formatDate = (date) => {
		// Set locale to Swedish
		moment.locale("sv");

		const createdDate = moment(date);
		const now = moment();

		// If the date is within the last 7 days, show relative time
		if (now.diff(createdDate, "days") <= 7) {
			return <Moment fromNow>{createdAt}</Moment>;
		}

		// Otherwise, display as 'YYYY-MM-DD'
		return createdDate.format("YYYY-MM-DD");
	};

	return (
		<Wrapper onClick={onClick}>
			<Title>
				{title}
				{notification?.unread && <Badge status="critical">Oläst</Badge>}
			</Title>
			<User>{user ? <ProfileAvatar user={user} size={20} /> : null}</User>
			<Content>{nl2br(content)}</Content>
			<Date>
				{formatDate(createdAt)}
				{/* <Moment fromNow>{createdAt}</Moment> */}
			</Date>
		</Wrapper>
	);
};
export default NotificationItem;

const Wrapper = styled.div`
	/* width: 250px; */
	display: grid;
	grid-template-areas: "title user" "content content" "date date";
	grid-template-columns: 1fr 20px;
	grid-template-rows: auto 1fr auto;
`;
const Title = styled.h3`
	grid-area: title;
	font-size: 0.95rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	color: var(--textColor);
`;
const Content = styled.div`
	grid-area: content;
	font-size: 0.85rem;
	color: var(--textColor);
	opacity: 0.95;
	white-space: wrap;
`;

const Date = styled.div`
	grid-area: date;
	font-size: 0.75rem;
	color: var(--textColor);
	opacity: 0.5;
	text-align: right;
	margin-top: 0.5rem;
`;
const User = styled.div`
	grid-area: user;

	.Polaris-Icon {
		height: 20px;
		width: 20px;
	}
`;
