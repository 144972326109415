import React from "react";

const SupportHeadIcon = ({ color = "var(--textColor)" }) => (
	<svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.14119 11.8588C7.25027 10.9679 6.75 9.75988 6.75 8.5C6.75 7.24012 7.25027 6.03212 8.14119 5.14119C9.03212 4.25027 10.2401 3.75 11.5 3.75C12.7599 3.75 13.9682 4.25031 14.8587 5.14111C15.7495 6.03214 16.25 7.24022 16.25 8.5C16.25 9.75995 15.7497 10.9682 14.8589 11.8587C13.9679 12.7495 12.7598 13.25 11.5 13.25C10.2401 13.25 9.03212 12.7497 8.14119 11.8588Z"
			stroke="white"
			strokeWidth="1.5"
		/>
		<path d="M20.4946 6.09216C20.379 6.09216 20.2638 6.09953 20.1495 6.11427C19.6118 4.35293 18.4758 2.80341 16.9139 1.70005C15.352 0.596685 13.4488 0 11.4921 0C9.53545 0 7.63263 0.596685 6.07035 1.70005C4.50848 2.80302 3.37251 4.35293 2.83476 6.11427C2.72373 6.10031 2.61188 6.09332 2.50043 6.09332C1.60723 6.09332 0.781968 6.53948 0.334951 7.26419C-0.11165 7.98852 -0.11165 8.88121 0.334951 9.60554C0.781553 10.3299 1.60681 10.7764 2.50043 10.7764C2.61229 10.7764 2.72415 10.7686 2.83476 10.7547C3.16329 10.7136 3.47939 10.6115 3.76525 10.4552C3.90487 10.3764 3.97447 10.2224 3.93718 10.0742C3.65422 8.91574 3.67577 7.71073 3.99974 6.56198C4.44137 5.01673 5.41577 3.65149 6.77091 2.6781C8.12604 1.7047 9.78651 1.17708 11.4946 1.17824C13.2027 1.17902 14.8628 1.70781 16.2167 2.68275C17.5706 3.65731 18.5433 5.02371 18.9837 6.56935C19.328 7.79337 19.328 9.07985 18.9837 10.3039C18.6336 11.5399 17.9393 12.6669 16.9715 13.569C16.8439 13.6884 16.7126 13.8029 16.5771 13.9127C16.4458 14.0186 16.273 14.0679 16.1007 14.0492C15.71 14.007 15.3172 14.1121 15.0094 14.3418C14.7016 14.5711 14.5044 14.9063 14.4613 15.2725C14.4182 15.6387 14.533 16.0057 14.7799 16.2924C15.0268 16.5791 15.3856 16.7623 15.7767 16.8003C16.1682 16.8383 16.5593 16.7289 16.8642 16.4961C17.1691 16.2633 17.3622 15.9262 17.4007 15.5596C17.414 15.4316 17.4082 15.302 17.3833 15.1751C17.3464 14.9804 17.4185 14.7817 17.5747 14.6483C18.8014 13.5977 19.6929 12.2495 20.1512 10.7535C20.838 10.8439 21.5345 10.6631 22.0734 10.2542C22.6124 9.8453 22.9459 9.24473 22.994 8.59684C23.042 7.94856 22.8001 7.31113 22.327 6.83627C21.8535 6.36179 21.1906 6.09255 20.4967 6.09332H20.495L20.4946 6.09216Z" />
	</svg>
);

export default SupportHeadIcon;
