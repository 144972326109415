import React, { useCallback } from "react";
import API from "src/js/API";
import Badge from "src/js/components/Badge";
import TimeLineRow from "src/js/components/TimeLineRow";
import Skeleton1Col from "src/js/components/skeleton_1col";
import styled, { css } from "styled-components";
import { LegacyCard } from "@shopify/polaris";
import { UseQueryResult } from "@tanstack/react-query";
import useQuery from "src/js/hooks/useQuery";
import { getHistoryStatus } from "../Utilities";

const Timeline = ({ document }) => {
	const fetch = useCallback(async () => {
		const res = await API.get(`/api/scrive/documents/${document.id}/history.json`);

		return res.data.history;
	}, [document?.id]);

	const {
		data,
		isFetching,
	}: UseQueryResult<{
		events: ScriveDocumentHistoryType[];
	}> = useQuery({
		queryKey: [`scrive_document_history-${document.id}`],
		queryFn: fetch,
		refetchOnWindowFocus: false,
	});

	return (
		<Wrapper loading={isFetching}>
			<LegacyCard sectioned>
				{isFetching && !data?.events?.length && <Skeleton1Col />}
				{data?.events?.length === 0 && <p>Ingen historik</p>}
				{data?.events?.map((historyItem, index) => {
					const { color, label } = getHistoryStatus(historyItem);
					return (
						<div key={index}>
							<TimeLineRow
								event={{
									title: (
										<>
											{historyItem.party}
											<Badge style={{ marginLeft: 10 }} color={color}>
												{label}
											</Badge>
										</>
									),
									date: historyItem.time,
									content: historyItem.text,
								}}
							/>
						</div>
					);
				})}
			</LegacyCard>
		</Wrapper>
	);
};
export default Timeline;

const Wrapper = styled.div<{ loading?: boolean }>`
	transition: opacity 250ms ease-in-out;

	${({ loading }) =>
		loading &&
		css`
			opacity: 0.5;
		`}
`;
