import styled from "styled-components";

export const MainWrapper = styled.div.attrs({ className: "contact_tabs" })``;

export const Wrapper = styled.div.attrs({ className: "contact_tabs_bar" })``;
export const Tab = styled.div.attrs<{ active?: boolean }>(({ active }) => ({ className: `contact_tab ${active ? "active" : ""}` }))``;
export const Icon = styled.div.attrs({ className: `contact_tab__icon` })``;
export const Count = styled.div.attrs<{ disabled?: boolean }>(({ disabled }) => ({
	className: `contact_tab__count ${disabled ? "disabled" : ""}`,
}))``;
