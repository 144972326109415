/* eslint-disable no-return-await */
import React, { useContext, useState } from "react";
import PdfViewer from "src/js/components/PdfViewer";
import styled from "styled-components";
import { LegacyCard, Icon } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import InfoView from "src/js/components/InfoView";
import { EditMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { getDocumentLabel, getPDFBlobURL } from "../Utilities";
import ScriveDocumentContext from "../ScriveDocumentContext";
import { CardAction } from "../styles";
import ScriveDocumentInfoModal from "./ScriveDocumentInfoModal";
import SkeletonPage from "src/js/components/Skeletons/Page";

const DocumentView = ({ t }) => {
	const { form: document, handleSave, isSaving } = useContext(ScriveDocumentContext);
	const [editModalIsOpen, setEditModalIsOpen] = useState(false);

	const handleSaveForm = async (documentForm: Partial<ScriveDocumentType>) => {
		try {
			await handleSave(documentForm, false);
		} catch (error) {
			console.error("error:", error);
		}
	};

	return (
		<Wrapper>
			{document?.blob ? <PdfViewer src={getPDFBlobURL(document)} /> : <SkeletonPage />}

			{document && (
				<LegacyCard
					title={t("scrive.titles.details", "Uppgifter") as string}
					sectioned
					actions={[
						{
							content: (
								<CardAction>
									<Icon source={EditMajor} />
									{t("common.actions.edit", "Redigera")}
								</CardAction>
							) as unknown as string,
							onAction: () => {
								setEditModalIsOpen(true);
							},
							enabled: document?.status === "preparation",
						},
					].filter((f) => !("enabled" in f) || f.enabled)}
				>
					<InfoView
						details={[
							{
								label: getDocumentLabel("timeout_time"),
								value: `${moment(document.timeout_time).format("YYYY-MM-DD")} (${moment(document.timeout_time).diff(moment(), "days")} dagar kvar)`,
								enabled: document.status !== "preparation",
							},
							{
								label: getDocumentLabel("days_to_sign"),
								value: document.days_to_sign + " dagar",
							},
							{
								label: getDocumentLabel("ctime"),
								value: new Date(document.ctime).toLocaleDateString("sv-SE", { hour: "2-digit", minute: "2-digit" }),
							},
							{
								label: getDocumentLabel("id"),
								value: document.id,
								// style: {
								// 	opacity: 0.5,
								// },
							},
							{
								label: getDocumentLabel("invitation_message"),
								value: document?.invitation_message,
								valueStyle: {
									overflow: "hidden",
									whiteSpace: "unset",
									wordWrap: "anywhere",
								},
							},
							{
								label: getDocumentLabel("sms_invitation_message"),
								value: document?.sms_invitation_message,
								valueStyle: {
									overflow: "hidden",
									whiteSpace: "unset",
									wordWrap: "anywhere",
								},
							},
						].filter((f) => !("enabled" in f) || f.enabled)}
					/>
				</LegacyCard>
			)}

			<ScriveDocumentInfoModal
				open={editModalIsOpen}
				onClose={() => setEditModalIsOpen(false)}
				document={document}
				onSave={handleSaveForm}
				isSaving={isSaving}
			/>
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(DocumentView);

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	gap: 0.625rem;

	.Polaris-LegacyCard {
		height: max-content;
	}
`;
