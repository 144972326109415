import React, { PureComponent } from "react";
import { Tooltip, Icon } from "@shopify/polaris";
import { ChevronUpMinor, ChevronDownMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";

class ColumnHeaderSortButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(props) {}

	haveSort(columnId) {
		for (let i = 0; i < this.props.sorting.length; i++) {
			if (this.props.sorting[i].column_id + "" == columnId + "") {
				return this.props.sorting[i].order;
			}
		}
		return null;
	}

	onClick(event) {
		event.preventDefault();
		const myOrder = this.haveSort(this.props.column_id);
		if (this.props.onClick) this.props.onClick();
		if (myOrder) {
			if (myOrder == "DESC") {
				let myIndex = null;
				for (let i = 0; i < this.props.sorting.length; i++) {
					if (this.props.sorting[i].column_id + "" == this.props.column_id + "") {
						myIndex = i;
						break;
					}
				}
				this.props.sorting.splice(myIndex, 1);
				this.props.onChangeSorting(this.props.sorting);
				// Remove sorting
				return;
			}
			for (let i = 0; i < this.props.sorting.length; i++) {
				if (this.props.sorting[i].column_id + "" == this.props.column_id + "") {
					this.props.sorting[i].order = this.props.sorting[i].order == "ASC" ? "DESC" : "ASC";
					this.props.onChangeSorting(this.props.sorting);
					return;
				}
			}
		} else {
			this.props.sorting.splice(0, this.props.sorting.length);
			this.props.sorting.push({ column_id: this.props.column_id, order: "ASC" });
			this.props.onChangeSorting(this.props.sorting);
		}
	}

	render() {
		let order = "ASC";

		const myOrder = this.haveSort(this.props.column_id);
		if (myOrder) {
			order = myOrder;
		}

		return (
			<div className={"sort-button" + (myOrder ? " active" : "") + (this.props.inline ? " inline" : "")}>
				<div className="container" onClick={this.onClick.bind(this)}>
					{this.props.showText &&
						(myOrder
							? order == "ASC"
								? this.props.t("column.header.sort.ASC", "Sortera stigande")
								: this.props.t("column.header.sort.remove", "Ta bort sortering")
							: "Sortera stigande")}
					{!this.props.inline && (
						<Tooltip
							content={
								myOrder
									? order == "ASC"
										? this.props.t("column.header.sort.ASC", "Sortera stigande")
										: this.props.t("column.header.sort.DESC", "Sorterar fallande")
									: this.props.t("column.header.sort.choose", "Klicka för att sortera")
							}
						>
							<span className="icon">
								<Icon source={order == "ASC" ? ChevronUpMinor : ChevronDownMinor} color="inkLighter" />
							</span>
						</Tooltip>
					)}
				</div>
			</div>
		);
	}
}
export default withTranslation(["column", "common"], { withRef: true })(ColumnHeaderSortButton);
