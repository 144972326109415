import React from "react";

const BoardIcon = ({ color = "var(--textColor)" }) => (
	<svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.43392 23.5518H17.5173V26.8018H6.43392V23.5518ZM6.43392 17.0518H22.2673V20.3018H6.43392V17.0518ZM6.43392 10.5518H22.2673V13.8018H6.43392V10.5518ZM25.4339 4.05176H18.8156C18.1506 2.16676 16.4089 0.801758 14.3506 0.801758C12.2923 0.801758 10.5506 2.16676 9.88559 4.05176H3.26725C3.04559 4.05176 2.83975 4.06801 2.63392 4.11676C2.01642 4.24676 1.46225 4.57176 1.03475 5.01051C0.749752 5.30301 0.512253 5.66051 0.353919 6.05051C0.195586 6.44051 0.100586 6.84676 0.100586 7.30176V30.0518C0.100586 30.4905 0.195586 30.9293 0.353919 31.3193C0.512253 31.7093 0.749752 32.0505 1.03475 32.3593C1.46225 32.798 2.01642 33.123 2.63392 33.253C2.83975 33.2855 3.04559 33.3018 3.26725 33.3018H25.4339C27.1756 33.3018 28.6006 31.8393 28.6006 30.0518V7.30176C28.6006 5.51426 27.1756 4.05176 25.4339 4.05176ZM14.3506 3.64551C14.9998 3.64551 15.5381 4.19801 15.5381 4.86426C15.5381 5.53051 14.9998 6.08301 14.3506 6.08301C13.7014 6.08301 13.1631 5.53051 13.1631 4.86426C13.1631 4.19801 13.7014 3.64551 14.3506 3.64551ZM25.4339 30.0518H3.26725V7.30176H25.4339V30.0518Z"
			fill={color}
		/>
	</svg>
);

export default BoardIcon;
