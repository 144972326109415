import React from "react";

const CheckmarkCircle = ({ color = "var(--textColor)" }) => (
	<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.85541 2.06277C4.54679 2.06277 1.86462 4.73146 1.86462 8.02345C1.86462 11.3154 4.54679 13.9841 7.85541 13.9841C11.164 13.9841 13.8462 11.3154 13.8462 8.02345C13.8462 4.73146 11.164 2.06277 7.85541 2.06277ZM0.775391 8.02345C0.775391 4.13291 3.94522 0.979004 7.85541 0.979004C11.7656 0.979004 14.9354 4.13291 14.9354 8.02345C14.9354 11.914 11.7656 15.0679 7.85541 15.0679C3.94522 15.0679 0.775391 11.914 0.775391 8.02345Z"
			fill={color}
		/>
	</svg>
);

export default CheckmarkCircle;
