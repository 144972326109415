import React, { Component } from "react";
import { FooterHelp, EmptyState, ExceptionList, Button, Link } from "@shopify/polaris";
import { MarketingMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../../store";
import Datepicker from "../../components/datepicker";
import AdAccountSelector from "../../components/AdAccountSelector.js";
import AdCampaignSelector from "../../components/AdCampaignSelector.js";
import AdStatusSelector from "../../components/AdStatusSelector.js";
import IndexTable from "src/js/components/IndexTable";
import styled from "styled-components";

class Ads extends Component {
	constructor(props) {
		super(props);
		const last30day = new Date();
		last30day.setDate(last30day.getDate() - 30);
		const to = new Date();
		this.state = {
			selectedItems: [],
			visibleItems: [],
			// currentContact: {
			// 	is_company: true,
			// 	tags: [],
			// },
			from: last30day,
			to,
			caption: this.props.t("common.dates.latest_30", "Senaste 30 dagarna"),
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	updateParams(params) {
		this.setState({});
	}

	gotoAd(item) {
		item.path = "/admin/ads/" + item.id;
		this.props.history.push("/admin/ads/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("ads.verb", "Annonsering"),
					item: { id: item.id, item, path: "/admin/ads/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	onItemsFetched(items) {
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/ads/" + items[i].id;
		}
		this.setState({ visibleItems: items });
	}

	getYmd(date) {
		let month = date.getMonth() + 1; // months from 1-12
		let day = date.getDate();
		const year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	getTypeTitle(item, type) {
		const typeOptions = [];
		if (item.ad_account.type == "adwords") {
			typeOptions.push({ label: this.props.t("ad_campaign.type.search", "Sökannons"), value: "search" });
		}
		for (let i = 0; i < typeOptions.length; i++) {
			if (typeOptions[i].value == type) {
				return typeOptions[i].label;
			}
		}
		return type;
	}

	changeRange(from, to, caption) {
		this.setState({ from, to, caption });
	}

	gotoCampaign(campaign) {
		let stacks = [];
		if (this.props.location.state && this.props.location.state.stacks) {
			stacks = this.props.location.state.stacks;
		} else {
			stacks = [];
		}
		stacks.push({
			parent_path: this.props.location.pathname + this.props.location.search,
			parent_title: this.props.t("ads.verb", "Annonsering"),
			item: campaign,
			items: [campaign],
		});
		this.props.history.push("/admin/ad_campaigns/" + campaign.id, { item: campaign, stacks });
	}

	render() {
		const filters = [
			{
				key: "status",
				label: this.props.t("ads.filters.status.label", "Status"),
				type: "select",
				options: [
					{ label: this.props.t("ads.filters.status.enabled", "Aktiv"), value: "ENABLED" },
					{ label: this.props.t("ads.filters.status.paused", "Pausad"), value: "PAUSED" },
				],
				shortcut: true,
			},
		];

		const columns = [
			{
				handle: "TITLE",
				label: this.props.t("ads.columns.name.label", "Namn"),
				sortable: false,
				render: (item) => {
					const exceptions = [];

					if (item.note) {
						exceptions.push({
							icon: "notes",
							description: item.note,
						});
					}

					const exceptionList = exceptions.length ? (
						<div className="CustomerListItem__Exceptions">
							<ExceptionList items={exceptions} />
						</div>
					) : null;

					return (
						<div>
							<div className="CustomerListItem__Profile">
								<h3 className="CustomerListItem__Title">{item.title}</h3>
								<span className="CustomerListItem__Location" />
							</div>
							{exceptionList}
						</div>
					);
				},
			},
			{
				handle: "STATUS",
				label: this.props.t("ads.columns.status.label", "Status"),
				sortable: false,
				render: (item) => <AdStatusSelector status={item.status} onChange={(newStatus) => {}} />,
			},
			{ handle: "KEYWORD", label: this.props.t("ads.columns.keyword.label", "Sökord"), sortable: false, render: (item) => item.keyword },
			{
				handle: "MAX_CPC",
				label: this.props.t("ads.columns.max_cpc.label", "Max CPC"),
				sortable: false,
				render: (item) => {
					const myBid = item.max_cpc ? (item.max_cpc / 100).toFixed(2) + " kr" : "–";
					if (item.last_stats && item.last_stats.metafields.first_page_cpc && item.last_stats.metafields.first_page_cpc > item.max_cpc) {
						return <div style={{ color: "red" }}>{myBid}</div>;
					}
					return <div>{myBid}</div>;
				},
			},
			{
				handle: "CONVERSIONS_COUNT",
				label: this.props.t("ads.columns.conversuins_count.label", "Konverteringar"),
				sortable: false,

				render: (item) => item.conversions_count,
			},
			{
				handle: "TYPE",
				label: this.props.t("ads.columns.type.label", "Annonstyp"),
				sortable: false,
				render: (item) => this.getTypeTitle(item, item.type),
			},
			{
				handle: "FIRST_PAGE_CPC",
				label: this.props.t("ads.columns.first_page_cpc.label", "Minsta bud"),
				sortable: false,
				render: (item) => {
					return item.last_stats && item.last_stats.metafields.first_page_cpc
						? (item.last_stats.metafields.first_page_cpc / 100).toFixed(2) + " kr"
						: "–";
				},
			},
			{
				handle: "QUALITY_SCORE",
				label: this.props.t("ads.columns.name.quality_score", "Kvalitetspoäng"),
				sortable: false,
				render: (item) => (item.last_stats && item.last_stats.metafields.quality_score ? item.last_stats.metafields.quality_score + "/10" : "–"),
			},
			{
				handle: "IMPRESSIONS",
				label: this.props.t("ads.columns.impressions.label", "Visningar"),
				sortable: false,
				render: (item) => item.impressions,
			},
			{
				handle: "COST",
				label: this.props.t("ads.columns.cost.label", "Kostnad"),
				sortable: false,
				render: (item) => (item.cost ? (item.cost / 100).toFixed(2) + " kr" : "–"),
			},
			{ handle: "CLICKS", label: this.props.t("ads.columns.clicks.label", "Klick"), sortable: false, render: (item) => item.clicks },
			{
				handle: "CAMPAIGN",
				label: this.props.t("ads.columns.campaign.label", "Kampanj"),
				sortable: false,
				render: (item) => (
					<AdCampaignSelector
						disabled
						link
						onClick={this.gotoCampaign.bind(this, item.ad_campaign)}
						ad_account={item.ad_account}
						campaign={item.ad_campaign}
						onChange={() => {}}
					/>
				),
			},
			{
				handle: "ACCOUNT",
				label: this.props.t("ads.columns.account.label", "Konto"),
				sortable: false,
				render: (item) => <AdAccountSelector disabled ad_account={item.ad_account} onChange={() => {}} />,
			},
		];

		return (
			<div>
				<IndexTable
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					history={this.props.history}
					title={this.props.t("ads.verb", "Annonsering")}
					secondaryActions={[
						{
							icon: MarketingMajor,
							content: this.props.t("ad_campaign.plural", "kampanjer"),
							onAction: () => {
								this.props.history.push("/admin/ad_campaigns");
							},
						},
					]}
					primaryAction={{ content: this.props.t("ads.actions.new", "Ny annons"), url: "/admin/ads/new" }}
					savedSearchHandle="ads"
					resourceUrl={"/api/anus.json?from=" + this.getYmd(this.state.from) + "&to=" + this.getYmd(this.state.to)}
					resourceHandle="ads"
					onItemsFetched={this.onItemsFetched.bind(this)}
					onSelectionChange={this.onSelectionChange.bind(this)}
					resourceName={{
						singular: this.props.t("ads.singular", "annonser"),
						plural: this.props.t("ads.plural", "annonser"),
					}}
					selector={
						<Datepicker plain onChange={this.changeRange.bind(this)} caption={this.state.caption} from={this.state.from} to={this.state.to} />
					}
					selectedItems={this.state.selectedItems}
					onUpdateParams={this.updateParams.bind(this)}
					filters={filters}
					columns={columns}
					defaultSort="SELLER_DESC"
					onClickRow={this.gotoAd.bind(this)}
					emptyState={
						<EmptyStateWrapper>
							<EmptyState
								// imageContained
								heading={this.props.t("ads.empty.title", "Inga annonser")}
								action={
									store.getState().ad_accounts.length ? { content: this.props.t("ads.action.create", "Skapa annons"), url: "/admin/ads/new" } : null
								}
								image="/assets/images/empty_state/Ads.png"
							>
								<p>
									{this.props.t("ads.empty.description_for", "Du har inga annonser att visa, koppla ditt konto till ett eller flera konton för")}{" "}
									<Link
										onClick={() => {
											this.props.history.push("/admin/google/accounts");
										}}
									>
										{this.props.t("ad_campaign.google.adwords", "Google Adwords")}
									</Link>{" "}
									{this.props.t("common.terms.or", "eller")}{" "}
									<Link
										onClick={() => {
											this.props.history.push("/admin/facebook/accounts");
										}}
									>
										{this.props.t("ad_campaign.facebook.adwords", "Facebook")}
									</Link>{" "}
									m.m.
								</p>
							</EmptyState>
							<FooterHelp>
								{this.props.t("ad_campaign.empty.footer", "Du kan lägga till fler annonskonto från exempelvis")}{" "}
								<Button
									onClick={() => {
										this.props.history.push("/admin/google/accounts");
									}}
									plain
								>
									{this.props.t("ad_campaign.google.adwords", "Google Adwords")}
								</Button>{" "}
								{this.props.t("common.terms.or", "eller")}{" "}
								<Button
									onClick={() => {
										this.props.history.push("/admin/facebook/accounts");
									}}
									plain
								>
									{this.props.t("ad_campaign.facebook.adwords", "Facebook")}
								</Button>
							</FooterHelp>
						</EmptyStateWrapper>
					}
				/>

				<FooterHelp>
					{this.props.t("ad_campaign.empty.footer", "Du kan lägga till fler annonskonto från exempelvis")}{" "}
					<Button
						onClick={() => {
							this.props.history.push("/admin/google/accounts");
						}}
						plain
					>
						{this.props.t("ad_campaign.google.adwords", "Google Adwords")}
					</Button>{" "}
					{this.props.t("common.terms.or", "eller")}{" "}
					<Button
						onClick={() => {
							this.props.history.push("/admin/facebook/accounts");
						}}
						plain
					>
						{this.props.t("ad_campaign.facebook.adwords", "Facebook")}
					</Button>
				</FooterHelp>
			</div>
		);
	}
}

export default withTranslation(["ads", "common"], { withRef: true })(Ads);

const EmptyStateWrapper = styled.div`
	img {
		height: 50vh;
	}
`;
