import React from "react";

const WarningIcon = ({ color = "#FFD66B" }) => (
	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.6306 0.828369C5.11062 0.828369 0.640625 5.30837 0.640625 10.8284C0.640625 16.3484 5.11062 20.8284 10.6306 20.8284C16.1606 20.8284 20.6406 16.3484 20.6406 10.8284C20.6406 5.30837 16.1606 0.828369 10.6306 0.828369ZM10.6406 18.8284C6.22062 18.8284 2.64062 15.2484 2.64062 10.8284C2.64062 6.40837 6.22062 2.82837 10.6406 2.82837C15.0606 2.82837 18.6406 6.40837 18.6406 10.8284C18.6406 15.2484 15.0606 18.8284 10.6406 18.8284ZM9.64062 13.8284H11.6406V15.8284H9.64062V13.8284ZM9.64062 5.82837H11.6406V11.8284H9.64062V5.82837Z"
			fill={color}
		/>
	</svg>
);

export default WarningIcon;
