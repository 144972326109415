export default (state = {}, action) => {
	switch (action.type) {
		case "UPDATE_COLUMN_SELECTION": {
			const newState = Object.assign({}, state);

			if (!(action.handle in newState)) {
				newState[action.handle] = [null, null, null, null, null, null, null];
			}

			newState[action.handle][action.index] = action.selection;

			return newState;
		}
		default:
			return state;
	}
};
