import React, { useContext } from "react";
import CustomfieldContext from "./context";
import { InfoWrapper } from "./styles";
import ResourceListManager from "../ResourceListManager";
import ItemCard from "src/js/components/ItemCard";
import Colors from "src/js/Colors";
import { withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import moment from "moment";

const Categories = ({ history, t }) => {
	const { id } = useContext(CustomfieldContext);

	const filters = [];

	const renderItem = (item: any) => {
		return (
			<ItemCard
				onClick={() => {
					history.push(`/admin/categories/${item.id}`, {
						data: item,
					});
				}}
				title={
					<>
						{item.title}
						{item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)}
					</>
				}
				leftColumn={[
					{
						label: t("categories.title", "Titel"),
						value: item.title,
					},
					{
						label: t("categories.articles.display", "Artiklar"),
						value: item.articles_display ?? "Inga artiklar",
					},
				]}
				rightColumn={[
					{
						label: t("categories.purchasePrice", "Skapad"),
						value: moment(item.created_at).format("YYYY-MM-DD"),
					},
					{
						label: t("categories.purchasePrice", "Uppdaterad"),
						value: moment(item.updated_at).format("YYYY-MM-DD"),
					},
				]}
			/>
		);
	};

	return (
		<InfoWrapper>
			<ResourceListManager
				onClick={(item) => {
					history.push(`/admin/categories/${item.id}`, {
						data: item,
					});
				}}
				params={{ custom_field_id: id }}
				resourceHandle={"categories"}
				history={history}
				renderItem={renderItem}
				filters={filters}
				resourceUrl="/api/categories.json"
				resourceName={{ singular: t("categories.singular", "Kategori"), plural: t("categories.plural", "Kategorier") }}
				addRemoveUrl={`/api/custom_fields/${id}/categories.json`}
			/>
		</InfoWrapper>
	);
};
export default withTranslation(["categories", "common"])(Categories);
