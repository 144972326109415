import { Icon, Tooltip } from "@shopify/polaris";
import { ArrowDownMinor, ArrowUpMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Money from "../../components/Money";

class GoalPercentage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const value = this.props.value;

		const content = (
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<>
				{value >= 0 ? (
					<div className="goal_percentage">
						<Icon source={ArrowUpMinor} />+{Math.round(value)}%
					</div>
				) : (
					<div className="goal_percentage under">
						<Icon source={ArrowDownMinor} />
						{Math.round(value)}%
					</div>
				)}
			</>
		);

		if (!this.props.tooltip) {
			return content;
		}

		return (
			<Tooltip
				content={
					<div>
						<p>
							{this.props.t("goals.terms.days", "Dagar")}:{" "}
							<b>
								{this.props.days + 1} av{" "}
								{moment(this.props.goal && this.props.goal.end_at).diff(moment(this.props.goal && this.props.goal.start_at), "days") + 1}
							</b>
						</p>
						<br />
						<p>
							{this.props.t("goals.terms.expected", "Förväntat")}:{" "}
							<b>
								<Money amount={this.props.forecasted_value && parseFloat(this.props.forecasted_value).toFixed()} currency="FIXED" />
							</b>{" "}
							{this.props.goal && this.props.goal.suffix}
						</p>
						<br />
						<p>
							{this.props.t("goals.terms.result", "Resultat")}:{" "}
							<b>
								<Money amount={this.props.sum} currency="FIXED" />
							</b>
							{this.props.goal && this.props.goal.suffix}
						</p>
						<br />
						<p>
							{this.props.t("goals.terms.diff", "Diff")}:{" "}
							<b>
								<Money amount={this.props.sum - this.props.forecasted_value} currency="FIXED" />
							</b>{" "}
							{this.props.goal && this.props.goal.suffix}
						</p>
					</div>
				}
			>
				{content}
			</Tooltip>
		);
	}
}

GoalPercentage.defaultProps = {
	tooltip: true,
};

export default withTranslation(["goals", "common"], { withRef: true })(GoalPercentage);
