import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "src/js/components/Button";
import TextField from "src/js/components/TextField";
import styled from "styled-components";
import MyActionList from "../../Workspaces/components/MyActionList";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";

const Options = ({ options, onChange, disabled, t }) => {
	const [label, setLabel] = useState("");
	const [value, setValue] = useState("");

	const handleAdd = () => {
		if (label && value) {
			onChange([...(options || []), { label, value }]);
			setLabel("");
			setValue("");
		}
	};

	return (
		<FormWrapper>
			<InputsWrapper>
				<TextField label={t("customfields.fields.options.options.label", "Namn")} value={label} onChange={setLabel} disabled={disabled} />
				<TextField label={t("customfields.fields.options.options.value", "Värde")} value={value} onChange={setValue} disabled={disabled} />
			</InputsWrapper>

			<Button primary onClick={handleAdd}>
				{t("customfields.fields.options.options.add", "Lägg till")}
			</Button>

			{options?.map((option, index) => (
				<InputsWrapper key={index}>
					<TextField
						label={index === 0 && t("customfields.fields.options.options.label", "Namn")}
						value={option.label}
						onChange={(v) => {
							const newOptions = [...options];
							newOptions[index].label = v;
							onChange(newOptions);
						}}
						disabled={disabled}
					/>
					<TextField
						label={index === 0 && t("customfields.fields.options.options.value", "Värde")}
						value={option.value}
						onChange={(v) => {
							const newOptions = [...options];
							newOptions[index].value = v;
							onChange(newOptions);
						}}
						disabled={disabled}
					/>
					<MyActionList
						activator={
							<div style={{ height: 36, display: "flex" }}>
								<Button plain icon={MobileVerticalDotsMajor} />
							</div>
						}
						items={[
							{
								content: t("common:delete", "Ta bort"),
								destructive: true,
								onAction: () => {
									const newOptions = [...options];
									newOptions.splice(index, 1);
									onChange(newOptions);
								},
							},
						]}
					/>
				</InputsWrapper>
			))}
		</FormWrapper>
	);
};

export default withTranslation(["customfields", "common"])(Options);

const InputsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr auto;
	grid-gap: 1rem;
	align-items: end;
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
`;
