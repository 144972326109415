import React, { useEffect, useState } from "react";
import { FormLayout, ChoiceList, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Banner from "./banner";
import Modal from "src/js/components/modal";
import useWorkspaces from "../hooks/useWorkspaces";

const WorkspaceModal = ({ ...props }) => {
	const [form, setForm] = useState<any>({ parent_id: props.parent ? props.parent.id : null, type: "private" });
	const { updateWorkspace, addWorkspace } = useWorkspaces();

	useEffect(() => {
		if (props.open) {
			setForm({ parent_id: props.parent ? props.parent.id : null, type: "private" });
		}
	}, [props.open, props.parent]);

	const updateForm = (field) => (v) => {
		const value = field === "type" ? v[0] : v;
		setForm((c) => ({ ...c, [field]: value }));
	};

	const saveForm = async () => {
		if (form.id) {
			const workspace = await updateWorkspace(form);
			if (workspace) props.onCreate(workspace);
		} else {
			const workspace = await addWorkspace(form);
			if (workspace) props.onCreate(workspace);
		}
	};

	const singular = props.resourceName?.singular || "mapp";
	// const plural = props.resourceName?.plural || "mappar";
	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			title={
				form.id
					? props.t("workspace.titles.update_folder_resource", "Uppdatera {{resource}}", {
							resource: singular,
					  })
					: props.t("worksapce.titles.new_folder_resource", "Ny {{resource}}", { resource: singular })
			}
			secondaryActions={[
				{
					content: props.t("common.actions.close", "Stäng"),
					onAction: props.onClose,
				},
			]}
			primaryAction={{
				content: form.id ? props.t("common.actions.update", "Uppdatera") : props.t("common.actions.create", "Skapa"),
				onAction: saveForm,
			}}
		>
			<Modal.Section>
				<FormLayout>
					<TextField
						label={props.t("workspace.folder.fields.title_resource", "Namn på {{resource}}", {
							resource: singular,
						})}
						value={form.title}
						autoComplete="off"
						onChange={updateForm("title")}
					/>
					<ChoiceList
						title=""
						choices={[
							{
								label: props.t("workspace.folder.type.options.public.label", "Publik"),
								value: "public",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner title={props.t("workspace.folder.type.options.public.title_resource", "Publik {{resource}}", { resource: singular })}>
											{props.t("workspace.folder.type.options.public.text_resource", "Alla som har ett konto kommer åt {{resource}}", {
												resource: singular,
											})}
										</Banner>
									) : null,
							},
							{
								label: props.t("workspace.folder.type.options.public_read.label", "Publik (endast läs)"),
								value: "public_read",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner
											title={props.t("workspace.folder.type.options.public_read.title_resource", "Publik {{resource}} (endast läs)", {
												resource: singular,
											})}
										>
											{props.t(
												"workspace.folder.type.options.public_read.text_resource",
												"Alla kan se {{resource}}, men endast kopplade medlemmar har skrivrättigheter",
												{
													resource: singular,
												}
											)}
										</Banner>
									) : null,
							},
							{
								label: props.t("workspace.folder.type.options.shareable.label", "Delad"),
								value: "shareable",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner
											title={props.t("workspace.folder.type.options.shareable.title_resource", "Delad {{resource}}", {
												resource: singular,
											})}
										>
											{props.t(
												"workspace.folder.type.options.shareable.text",
												"Det går att dela {{resource}} med medarbetare eller utomstående, men alla som ska se den måste vara inbjudna",
												{
													resource: singular,
												}
											)}
										</Banner>
									) : null,
							},
							{
								label: props.t("workspace.folder.type.options.private.label", "Privat"),
								value: "private",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner
											title={props.t("workspace.folder.type.options.private.title.resource", "Privat {{resource}}", {
												resource: singular,
											})}
										>
											{props.t("workspace.folder.type.options.private.text_resource", "Enbart ägaren (du) kan se {{resource}}", {
												resource: singular,
											})}
										</Banner>
									) : null,
							},
						]}
						selected={[form.type]}
						onChange={updateForm("type")}
					/>
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
};
export default withTranslation(["workspace", "common"], { withRef: true })(WorkspaceModal);
