import React, { Component } from "react";
import { Icon, Popover, Spinner, ActionList, Tooltip, KeypressListener } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import API from "../../API";
import Truncate from "../Truncate";

/**
 * @param {String} [resourceUrl] - url from were to fetch data
 * @param {Object} [params] - url query params
 * @param {Array} [items] - items option
 * @param {String} [value] - current value
 * @param {Function} [onChange] - handle onChange event
 * @param {Function} [onSelect] - handle when an item is selected
 */
export default class FortnoxTableAutoCompleteColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			resourceUrl: props.resourceUrl || null,
			items: props.options || [],
			active: false,
			value: props.value || "",
			loading: !props.options,
		};
		this.onClose = () => {
			this.setState({ active: false });
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value || "" });
	}

	fetch(updated) {
		if (this.props.options) {
			return;
		}
		if (updated) {
			this.updateValue();
		}
		this.setState({ loading: true });
		const params = Object.assign({}, {}, this.props.params);
		if (this.state.value && this.props.handle !== "Unit") {
			params[this.props.handle.toLowerCase()] = this.state.value;
		}
		API.get(this.state.resourceUrl, {
			params,
		})
			.then((result) => {
				let data = [];
				if (this.props.mapper && typeof this.props.mapper === "function") {
					data = this.props.mapper(result.data[this.props.resource]);
				} else {
					data = result.data[this.props.resource];
				}
				this.setState({ loading: false, items: data });
				data.forEach((value) => {
					if (this?.state?.value?.toLowerCase?.() === value?.[this.props.handle]?.toLowerCase?.()) {
						this.props?.onSelect?.(value);
					}
				});
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	}

	updateValue() {
		this.props?.onChange?.(this.state.value);
	}

	setValue(value, item) {
		if (this.props.onSelect) {
			this.props.onSelect(item);
		}
		this.setState({ value, active: false }, this.updateValue.bind(this));
		// setTimeout(() => {this.input.focus()}, 50);
	}

	getContent(item) {
		if (["ArticleNumber", "Description"].includes(this.props.handle)) {
			return <Truncate numberOfLines={2}>{item.ArticleNumber + " - " + item.Description}</Truncate>;
		}

		return item[this.props.handle] || "unknown";
	}

	render() {
		return (
			<Popover
				active={this.state.active}
				preventAutofocus
				fullWidth
				autofocusTarget="none"
				activator={
					<Tooltip content={this.state.value}>
						<div>
							<input
								onMouseDown={(e) => {
									if (e.button === 2) e.preventDefault();
								}}
								value={this.state.value}
								onChange={(e) => {
									this.setState({ value: e.target.value }, this.fetch.bind(this, true));
								}}
								onFocus={() => {
									this.setState({ active: true }, this.fetch.bind(this));
								}}
								onBlur={() => {
									this.setState({ active: false });
								}}
								type="text"
							/>
							<div style={{ position: "absolute", top: 5, right: 5 }}>
								<a
									tabIndex={-1}
									onClick={() => {
										this.setState({ active: !this.state.active });
										if (!this.state.active) {
											this.fetch();
										}
									}}
								>
									<Icon source={ChevronDownMinor} />
								</a>
							</div>
						</div>
					</Tooltip>
				}
				onClose={this.onClose}
			>
				<KeypressListener handler={this.onClose} keyCode={27} />
				{/* <KeypressListener handler={this.onClose} keyCode={9} /> */}

				{this.state.loading ? (
					<Popover.Section>
						<Spinner size="small" />
					</Popover.Section>
				) : null}
				<ActionList
					items={this.state.items
						.filter((item) => {
							if (!this.props.options || !this.state.value) {
								return true;
							}
							return true;
						})
						.map((item) => {
							return {
								content: this.getContent(item),
								onAction: this.setValue.bind(this, item[this.props.handle], item),
							};
						})}
				/>
			</Popover>
		);
	}
}
