import React from "react";

const MarketingIcon = ({ color = "var(--textColor)" }) => (
	<svg width="68" height="60" viewBox="0 0 68 60" fill="none" filltype="pathfill" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M57.0234 7.31806L17.6 24.3008C17.16 24.4904 16.6859 24.5882 16.2068 24.5882H10.2201C8.80629 24.5882 7.75502 25.7181 7.75502 26.9359V32.8051C7.75502 34.0229 8.80629 35.1528 10.2201 35.1528H16.2068C16.6799 35.1528 17.1482 35.2482 17.5837 35.4332L26.5847 39.2572L57.1166 52.4631L57.1703 52.4869C58.7683 53.2099 60.5784 51.9216 60.5784 50.4129V9.62158C60.5784 8.00018 58.8764 6.66304 57.0234 7.31806ZM54.4761 0.750677C60.3818 -1.47621 67.6215 2.45727 67.6215 9.62158V50.4129C67.6215 57.1104 60.4746 61.6874 54.2967 58.9171L23.7991 45.7261L15.4897 42.1959H10.2201C5.04862 42.1959 0.711914 38.0435 0.711914 32.8051V26.9359C0.711914 21.6975 5.04862 17.5451 10.2201 17.5451H15.4805L54.3253 0.81153C54.3751 0.790089 54.4254 0.769799 54.4761 0.750677Z"
			fill={color}
		/>
		<path
			d="M25.9509 42.7837V47.7726C25.9509 51.0007 22.3853 53.6418 18.0274 53.6418C15.6503 53.6418 13.6695 53.0549 12.481 51.8811C10.8963 50.7072 10.1039 49.2399 10.1039 47.7726V38.9687H14.0656L25.9509 42.7837Z"
			fill={color}
		/>
	</svg>
);

export default MarketingIcon;
