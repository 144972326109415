import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import TargetContext from "../Context/TargetContext";
import { ButtonGroup, Checkbox, Pagination } from "@shopify/polaris";
import Spinner from "src/js/components/Spinner";
import TargetValuesRow from "./TargetValuesRow";
import useTargetValuesBulkActions from "./useTargetValuesBulkActions";
import getTargetConnectionAvatar from "../Utilities/getTargetConnectionAvatar";
import getTargetConnectionLabel from "../Utilities/getTargetConnectionLabel";

const TargetValues = () => {
	const { setForm, form, target, setDate, isFetching, t, isLoading } = useContext(TargetContext);
	const { bulkActions, selectedTargetConnectionIds, selectedPeriodIndexs, onToggleSelectedTargetConnection, onToggleSelectedPeriod } =
		useTargetValuesBulkActions();

	const getChangeHandler = useCallback(
		(targetConnectionIndex: number) => (targetConnection: TargetConnectionType) => {
			setForm((c: Partial<TargetType>) => {
				const newForm = { ...c } as TargetType;
				newForm.target_connections[targetConnectionIndex] = targetConnection;
				return newForm;
			});
		},
		[setForm]
	);

	const columns = useMemo(
		() => [
			...(target?.periods || form?.periods || []),
			{
				label: t("common.total", "Totalt"),
				index: "total",
			},
		],
		[target, form, t]
	);

	const tableRows = useMemo(() => {
		return form.target_connections?.map((targetConnection, targetConnectionIndex) => {
			return (
				<TargetValuesRow
					form={form}
					columns={columns}
					key={targetConnection.id}
					targetConnection={targetConnection}
					targetConnectionIndex={targetConnectionIndex}
					getChangeHandler={getChangeHandler}
					isLoading={isLoading}
				/>
			);
		});
	}, [form, columns, getChangeHandler, isLoading]);

	const handleSetDate = useCallback(
		(date: string) => {
			setDate(date as string);

			const searchParams = new URLSearchParams(window.location.search);
			searchParams.set("group_start_at", date);
			window.history.replaceState({}, "", `${window.location.pathname}?${searchParams}`);
		},
		[setDate]
	);

	return (
		<MainWrapper>
			<Header>
				<Pagination
					label={
						<div style={{ position: "relative" }}>
							{isFetching && (
								<div style={{ position: "absolute", inset: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
									<Spinner size="small" />
								</div>
							)}
							<span>{`${target?.group_start_at || ""} - ${target?.group_end_at || ""}`}</span>
						</div>
					}
					hasNext={!!(!isFetching && target?.next_group_start_at)}
					hasPrevious={!!(!isFetching && target?.prev_group_start_at)}
					onNext={() => {
						handleSetDate(target?.next_group_start_at as string);
					}}
					onPrevious={() => {
						handleSetDate(target?.prev_group_start_at as string);
					}}
				/>
			</Header>
			<BulkActionsWrapper>
				<ButtonGroup segmented>{bulkActions}</ButtonGroup>
			</BulkActionsWrapper>

			<OuterWrapper>
				<UserTable>
					<thead>
						<tr>
							<th key={"user"}>{t("common.user", "Användare")}</th>
						</tr>
					</thead>

					<tbody>
						{form.target_connections?.map((targetConnection, targetConnectionIndex) => {
							return (
								<NameTR key={targetConnection.id}>
									<td key={"user"}>
										<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
											<Checkbox
												label=""
												labelHidden
												checked={selectedTargetConnectionIds.includes(targetConnection.id)}
												onChange={onToggleSelectedTargetConnection(targetConnection.id)}
											/>
											{getTargetConnectionAvatar(targetConnection)}
											{getTargetConnectionLabel(targetConnection)}
										</div>
									</td>
								</NameTR>
							);
						})}
					</tbody>
				</UserTable>
				<Wrapper>
					<table>
						<thead>
							<tr>
								{/* <th key={"user"}>{"Användare"}</th> */}

								{columns.map((col) => {
									return (
										<th key={col.index}>
											<div style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "center" }}>
												{typeof col.index === "number" && (
													<Checkbox
														label=""
														labelHidden
														checked={selectedPeriodIndexs.includes(col.index)}
														onChange={onToggleSelectedPeriod(col.index)}
													/>
												)}
												{col.label}
											</div>
										</th>
									);
								})}
							</tr>
						</thead>

						<tbody>{tableRows}</tbody>
					</table>
				</Wrapper>
			</OuterWrapper>
		</MainWrapper>
	);
};
export default TargetValues;

const MainWrapper = styled.div``;
const Header = styled.div`
	display: flex;
	justify-content: center;
`;
const OuterWrapper = styled.div`
	position: relative;
	display: flex;
	overflow: hidden;

	table {
		height: 100%;
	}

	tr {
		height: 50px;
	}

	td,
	th {
		padding: 0.5rem;
		min-width: 250px;
	}
`;
const UserTable = styled.table`
	td,
	th {
		&:first-child {
			min-width: max-content;
			white-space: nowrap;
		}
	}
`;
const Wrapper = styled.div`
	overflow-x: auto;
`;

const NameTR = styled.tr`
	.Polaris-Icon {
		margin: 0;
		height: 4rem;
		width: 4rem;
	}

	svg {
		height: 40px;
		width: 40px;
	}
`;

const BulkActionsWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;
