import React from "react";
import styled from "styled-components";
import ModalBox from "./ModalBox";

const ModalBoxes = ({ style = {}, items = [] }) => {
	return (
		<Wrapper style={style}>
			{items?.map((item, index) => {
				return <ModalBox key={index} item={item} />;
			})}
		</Wrapper>
	);
};
export default ModalBoxes;

const Wrapper = styled.div`
	display: flex;
	/* justify-content: space-evenly; */
	gap: 1.875rem;
	flex-wrap: wrap;
`;
