import React from "react";
import GroupEdit from "src/js/views/Groups/edit";

const GroupCreate = ({ t, history, match, ...rest }) => {
	return (
		<GroupEdit
			history={history}
			match={match}
			{...rest}
			defaultData={{
				name: "",
			}}
		/>
	);
};
export default GroupCreate;
