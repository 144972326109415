import { Button } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import API from "../../API";
import Miniboard from "./components/Miniboard";
import RowColumnForm from "./components/RowColumnForm";
import Toast from "src/js/components/Toast";
import RowModal from "./components/RowModal";
import Modal from "../../components/modal";

class ContractModal extends Component {
	constructor(props) {
		super(props);
		this.state = { rows: [] };
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.boardUploadRefs = [];
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			if (!props.open) {
				this.setState({ open: props.open, row: null });
			} else {
				this.fetchContracts(props);
				this.setState({ open: props.open });
			}
		}
	}

	fetchContracts(props) {
		if (props.board) {
			const params = {
				contact_id: props.contact?.id || null,
			};
			API.get("/api/boards/" + props.board.id + "/rows.json", { params })
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}

					this.setState({
						rows: result.data.rows,
					});
				})
				.catch((error) => {
					Toast.error(error);
				});
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateFile(index, fileIndex, file) {
		this.state.rows[index].column_values[this.props.column.id].value[fileIndex] = file;

		this.setState({ rows: this.state.rows });
	}

	render() {
		return (
			<React.Fragment>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={(this.props.column && this.props.column.title) || this.props.t("common.terms.document", "Dokument")}
					secondaryActions={
						this.props.onClose && [
							{
								content: this.props.t("common.terms.close", "Stäng"),
								onAction: this.props.onClose,
							},
						]
					}
				>
					<Modal.Section>
						<ModalInnerWrapper>
							{/* {this.state.loadingRows && <Spinner />} */}
							{!this.state.row ? (
								<div style={{ paddingLeft: 20, paddingRight: 20 }}>
									<h2>
										{`${this.props.t("common.terms.choose_one", "Välj en")} ${(this.props.board?.singular || "rad").toLowerCase()} att skapa ${
											this.props.column?.title || this.props.t("common.terms.document", "Dokument")
										} på.`}
									</h2>
									<Miniboard
										skipadd
										onClickRow={(row) => this.setState({ row })}
										handle={this.props.board && this.props.board.singular && this.props.board.singular.toLowerCase()}
										rows={this.state.rows}
										board={this.props.board}
										board_id={this.props.board && this.props.board.id}
										contact_id={this.props.contact && this.props.contact.id}
										rowRightButton={
											<div
												className="rowRightButton"
												style={{
													height: "100%",
													display: "flex",
													alignItems: "center",
													marginRight: "2px",
												}}
											>
												<Button primary>{this.props.t("common.actions.choose", "Välj")}</Button>
											</div>
										}
									/>

									{this.props.board && (
										<Button
											primary
											fullWidth
											onClick={() => {
												this.setState({
													createNewModalIsOpen: true,
													open: false,
												});
												if (this.props.onClose) this.props.onClose();
											}}
										>
											{this.props.t("common.terms.new", "Ny")}{" "}
											{(this.props.board && this.props.board.singular) || this.props.t("common.terms.row", "rad")}
										</Button>
									)}
								</div>
							) : null}
							{this.state.row ? (
								<Modal.Section>
									<RowColumnForm
										board={this.props.board}
										contact_id={this.props.contact && this.props.contact.id}
										column={this.props.column}
										row={this.state.row}
										onCreate={(row) => {
											if (this.props.onCreate) this.props.onCreate(row);
										}}
										history={this.props.history}
									/>
								</Modal.Section>
							) : null}
						</ModalInnerWrapper>
					</Modal.Section>
				</Modal>

				{this.props.board ? (
					<RowModal
						open={this.state.createNewModalIsOpen}
						board={this.props.board}
						contact={this.props.contact}
						onUpdateRow={() => {}}
						onUpdateValue={() => {}}
						defaultValues={[]}
						getContact={() => {}}
						onClose={() => {
							this.setState({ createNewModalIsOpen: false });
						}}
						onCreateRow={(row) => {
							this.setState({ row, open: true });
							if (this.props.onOpen) this.props.onOpen();
							if (this.props.onCreate) this.props.onCreate(row);
						}}
						onCreate={() => {}}
					/>
				) : null}
			</React.Fragment>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(ContractModal);

const ModalInnerWrapper = styled.div`
	.board-empty_screen {
		max-height: 200px !important;
	}
`;
