/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Button } from "@shopify/polaris";
import Section from "../Section";
import EditorView from "../../Pdf/Editor/EditorView";
import Sheet from "../../../components/sheet";

const PDFEditorViews = ({ history }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [templateId, setTemplateId] = useState(72);
	const [open, setOpen] = useState(false);

	// const handleChangeTemplateId = useCallback((v) => {
	// 	setTemplateId(v && parseInt(v));
	// }, []);

	const props = [
		{
			title: "No/Default props",
		},
		{
			title: "Bare props",
		},
		{
			title: "print is true",
			print: true,
		},
	];

	return (
		<>
			{/* <TextField value={String(templateId)} onChange={handleChangeTemplateId} placeholder="Template ID" /> */}

			{props.map(({ title, variables, ...rest }, index) => {
				return (
					<Section enableRefresh key={index} title={title} props={rest}>
						<EditorView history={history} onSave={() => {}} id={templateId} variables={variables || {}} {...rest} />
					</Section>
				);
			})}

			<Section enableRefresh title="In a <Sheet />" props={{}}>
				<Button
					onClick={() => {
						setOpen(true);
					}}
				>
					Open sheet
				</Button>
				<Sheet
					open={open}
					onClose={() => {
						setOpen(false);
					}}
				>
					<div
						style={{ paddingTop: 20, overflow: "auto", width: "100%", height: "100%" }}
						className="embedded-editor bg"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<EditorView onSave={() => {}} id={templateId} variables={{}} history={history} />
					</div>
				</Sheet>
			</Section>
		</>
	);
};

export default PDFEditorViews;
