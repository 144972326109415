import React from "react";
import { Wrapper, Date } from "./styles";
import CollapsibleCard from "src/js/views/CollapsibleCard";
import ProfileAvatar from "../ProfileAvatar";
import EntityLogText from "./EntityLogText";
import Badge from "../Badge";
import Spinner from "../Spinner";

type EntityLog = {
	id: number;
	entity_class: string;
	entity_id: number;
	title: string;
	changes: Record<string, { old: string; new: string }>;
	event: "preUpdate" | "postPersist" | "preRemove";
	created_at: string;
	user?: UserType;
};

type EntityLogProps = {
	data: EntityLog | null;
	children?: React.ReactNode;
	sectioned?: boolean;
};

const EntityLog = ({ data, sectioned = true, children = null }: EntityLogProps) => {
	if (!data)
		return (
			<Wrapper>
				<CollapsibleCard title={<Spinner size="small" />} />
			</Wrapper>
		);

	const badge = (() => {
		switch (data.event) {
			case "preUpdate":
				return <Badge status="info">Uppdaterad</Badge>;
			case "postPersist":
				return <Badge status="success">Skapad</Badge>;
			case "preRemove":
				return <Badge status="critical">Raderad</Badge>;
			default:
				return null;
		}
	})();

	return (
		<Wrapper>
			<CollapsibleCard
				open={["preUpdate"].includes(data.event)}
				title={
					<>
						<b>{data.title}</b> {badge}
					</>
				}
				sectioned={sectioned}
				actions={[
					{
						content: (
							<div style={{ display: "flex", alignItems: "center", gap: "0.35rem" }}>
								<ProfileAvatar size="24" user={data.user} />
								<Date>{data.created_at}</Date>
							</div>
						) as unknown as string,
						id: "user_and_date",
					},
				]}
			>
				<EntityLogText data={data} />
				{children}
			</CollapsibleCard>
		</Wrapper>
	);
};
export default EntityLog;
