import { Pagination, ResourceList, Spinner } from "@shopify/polaris";
import React, { useImperativeHandle, useRef, useState } from "react";
import API from "src/js/API";
import { isCancel } from "axios";
import { useDeepCompareCallback, useDeepCompareEffect } from "use-deep-compare";
import { WithTranslation, withTranslation } from "react-i18next";
import Toast from "src/js/components/Toast";

interface FortnoxResourceListWithPaginationProps extends WithTranslation {
	renderItem: (item: any) => React.ReactNode;
	// resouce from result.data[resource] and as fallback for url (t.ex ${resourceHandle || resource}.json)
	resource: string;
	// resouce in url (t.ex ${resourceHandle}.json)
	resourceHandle?: string;
	params?: any;
	customer: any;
	integration: any;
	resourceName?: { singular: string; plural: string };
}

const FortnoxResourceListWithPagination = React.forwardRef(
	(
		{ renderItem, resource, resourceHandle, params: propsParams, customer, integration, t, resourceName }: FortnoxResourceListWithPaginationProps,
		ref
	) => {
		const [offset, setOffset] = useState(0);
		const [loading, setLoading] = useState(false);
		const [items, setItems] = useState([]);
		const [metaInformation, setMetaInformation] = useState(false);
		const limit = 50;
		const controller = useRef(new AbortController());

		const fetch = useDeepCompareCallback(() => {
			const params = {
				customernumber: customer?.CustomerNumber,

				...(propsParams || {}),
				limit,
				offset: offset || 0,
			};

			if (integration?.id && integration?.integration_id && customer?.CustomerNumber) {
				controller.current.abort();
				controller.current = new AbortController();
				setLoading(true);

				API.get(`/api/integrations/${integration.integration_id}/${integration.id}/${(resourceHandle || resource).toLowerCase()}.json`, {
					params,
					signal: controller.current.signal,
				})
					.then((response) => {
						setMetaInformation(response?.data?.MetaInformation || {});
						setItems(response?.data?.[resource] || []);
						setLoading(false);
					})
					.catch((error) => {
						if (isCancel(error)) {
							console.debug("Request canceled", error.message);
						} else {
							Toast.error(error?.response?.data?.message || "Unknown error");
							setLoading(false);
						}
					})
					.finally(() => {});
			}
		}, [customer?.CustomerNumber, integration?.id, integration?.integration_id, offset, propsParams, resource]);

		const onNext = () => {
			setOffset(offset + limit);
		};

		const onPrevious = () => {
			setOffset(offset - limit);
		};

		useDeepCompareEffect(() => {
			fetch();
		}, [fetch]);

		useImperativeHandle(ref, () => ({
			refresh: () => {
				setOffset(0);
			},
		}));

		const pagination = (
			<Pagination
				label={
					<div style={{ position: "relative" }}>
						{loading && metaInformation && (
							<div style={{ position: "absolute", inset: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
								<Spinner size="small" />
							</div>
						)}
						<span>
							{t("fortnox.invoice.pagination.shows", "Visar {{counts}} av {{total}}", {
								counts: metaInformation?.["@TotalResources"] ? Math.min(offset + limit, metaInformation?.["@TotalResources"]) : "-",
								total: metaInformation?.["@TotalResources"] ? metaInformation?.["@TotalResources"] : "-",
							})}
						</span>
					</div>
				}
				hasNext={metaInformation && metaInformation?.["@TotalResources"] > offset + limit}
				hasPrevious={metaInformation && offset > 0 && metaInformation?.["@TotalResources"]}
				onNext={onNext}
				onPrevious={onPrevious}
			/>
		);

		return (
			<>
				<ResourceList
					loading={loading}
					items={items}
					renderItem={renderItem}
					idForItem={(item, index) => {
						return item?.DocumentNumber || index;
					}}
					emptyState={
						<p style={{ textAlign: "center", padding: "0.6250rem" }}>
							{t("fortnox.invoice.empty.text", "Hittar inga {{plural}}", { plural: resourceName?.plural })}
						</p>
					}
				/>

				<div style={{ display: "flex", justifyContent: "center" }}>{pagination}</div>
			</>
		);
	}
);
export default withTranslation(["fortnox"], { withRef: true })(FortnoxResourceListWithPagination);
