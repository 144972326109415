//in an option array like the one below, find the parent of a given value. So if the value is 3, the parent would be 2, and the grandparent would be 1. So the function should return [1, 2].
// const options = [
// 	{
// 		value: 1,
// 		options: [
// 			{
// 				value: 2,
// 				options: [
// 					{
// 						value: 3,
// 					},
// 				],
// 			},
// 		],
// 	},
// ];

const findParentOptions = (items, targetValue, path: any[] = []) => {
	return items.reduce((foundPath, item) => {
		if (foundPath) return foundPath;

		// If the current item's value matches the target, return the path including this item
		if (item.value === targetValue) {
			return [...path, item.value]; // Include the current item in the path
		}

		// If the item has options, recursively search its children
		if (item.options && item.options.length > 0) {
			return findParentOptions(item.options, targetValue, [...path, item.value]); // Add current item to path
		}

		return null;
	}, null);
};

export default findParentOptions;
