/* eslint-disable no-restricted-syntax */
import React, { useMemo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import Badge from "src/js/components/Badge";
import NestedChoiceList from "src/js/components/NestedChoiceList";
import Spinner from "src/js/components/Spinner";
import useQuery from "src/js/hooks/useQuery";
import findOptionPathLabel from "src/js/Utilities/findOptionPathLabel";
import styled, { css } from "styled-components";

type UserRolesProps = {
	onChange: (roles: string[]) => void;
	user: any;
	userId?: string;
	roles?: any[];
	disabled?: boolean;
	savedUser?: any;
} & WithTranslation;

const UserRoles = ({ onChange, user, userId, t, roles: propsRoles = [], savedUser, ...props }: UserRolesProps) => {
	const queryKey = ["roles", userId, propsRoles?.length ? "simple" : ""];
	const { data: roles = propsRoles || [], isFetching } = useQuery({
		queryKey,
		queryFn: async () => {
			try {
				const res = await API.get(`/api/roles/${userId}/options.json`);
				return res.data;
			} catch (error) {
				console.error("error:", error);
				throw error;
			}
		},
		initialData: propsRoles || [],
		enabled: !propsRoles?.length && !!userId,
	});

	const { addedRoles, addedLabels } = useMemo(() => {
		const addedRoles = savedUser?.all_permissions && user?.all_permissions?.filter((role: string) => !savedUser?.all_permissions?.includes(role));
		const addedLabels = addedRoles?.map((role) => findOptionPathLabel(roles, role));

		return { addedRoles, addedLabels };
	}, [savedUser, user, roles]);

	const { removedRoles, removedLabels } = useMemo(() => {
		const removedRoles = savedUser?.all_permissions && savedUser?.all_permissions?.filter((role: string) => !user?.all_permissions?.includes(role));
		const removedLabels = removedRoles?.map((role) => findOptionPathLabel(roles, role));

		return { removedRoles, removedLabels };
	}, [savedUser, user, roles]);

	return (
		<Wrapper disabled={!!("disabled" in props ? props.disabled : (isFetching && !roles?.length) || !user?.email)}>
			<div style={{ marginBottom: "1rem", display: "flex", gap: "1rem" }}>
				<Badge
					status="success"
					tooltip={
						(addedLabels?.length && (
							<ul style={{ listStyle: "none", padding: "0 0.5rem" }}>
								{addedLabels?.map((role) => (
									<li key={role}>+ {role}</li>
								))}
							</ul>
						)) as any
					}
				>
					+{addedRoles?.length || 0}
				</Badge>

				<Badge
					status="critical"
					tooltip={
						(removedLabels?.length && (
							<ul style={{ listStyle: "none", padding: "0 0.5rem" }}>
								{removedLabels?.map((role) => (
									<li key={role}>- {role}</li>
								))}
							</ul>
						)) as any
					}
				>
					-{removedRoles?.length || 0}
				</Badge>
			</div>
			{isFetching && <Spinner />}
			{!isFetching && user?.all_permissions && (
				<NestedChoiceList
					onChange={onChange}
					options={roles}
					selected={user?.all_permissions || []}
					openNestedIfHidden
					uncheckChildren
					openNestedWhenChecked
				/>
			)}
		</Wrapper>
	);
};
export default withTranslation(["roles", "user"])(UserRoles);

const Wrapper = styled.div<{ disabled?: boolean }>`
	${({ disabled }) => {
		if (disabled) {
			return css`
				opacity: 0.5;
				pointer-events: none;
			`;
		}
	}};

	.Polaris-ChoiceList__ChoiceChildren {
		margin-bottom: 0;
	}
`;
