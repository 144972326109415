import moment from "moment";
import { store } from "../store";

const getFiscalYearOrDefaultStartDate = (fiscalYearStartDate = store.getState().account.fiscal_year_start_date): string => {
	const fiscalDate = fiscalYearStartDate || moment().startOf("year").format("YYYY-MM-DD");

	const startDate = moment(fiscalDate).set("year", moment().year()).isSameOrAfter(moment())
		? moment(fiscalDate).set("year", moment().year()).subtract(1, "year").format("YYYY-MM-DD")
		: moment(fiscalDate).set("year", moment().year()).format("YYYY-MM-DD");

	return startDate;
};

export default getFiscalYearOrDefaultStartDate;
