import React, { Component } from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

class CircleGraph extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	shouldComponentUpdate(nextProps) {
		if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) return true;
		return false;
	}

	render() {
		const options = {
			chart: {
				type: "pie",
				width: this.props.size * 1.1,
				height: this.props.size * 1.1,
			},
			title: {
				text: this.props.title,
			},
			series: this.props.series,
			plotOptions: {
				pie: {
					borderWidth: 0,
					allowPointSelect: true,
					size: this.props.size,
					width: this.props.size,
					height: this.props.size,

					cursor: "pointer",
					...(this.props.pieOptions || {}),
					dataLabels: {
						enabled: true,
						// format: "<b>{point.name}</b>: {point.percentage:.1f} %",
						...(this.props.pieOptions?.dataLabels || {}),
					},
				},
				...(this.props.plotOptions || {}),
			},
			...(this.props.options || {}),
		};

		return (
			<div>
				<HighchartsReact highcharts={Highcharts} options={options} />
			</div>
		);
	}
}

CircleGraph.defaultProps = { size: 200 };

export default CircleGraph;
