import React, { Component } from "react";
import { Icon, LegacyStack, Button } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../../store";
import Upload from "../../components/Upload";
import Attachment from "../../components/Attachment";
import PdfTemplateSheet from "../../components/PdfTemplateSheet";
import BoardUploadSheet from "./components/BoardUploadSheet";
import MyPopover from "../../components/Popover";

class CellUpload extends Component {
	constructor(props) {
		super(props);
		this.state = { value: props.value || [] };
		this.boardUploadRefs = [];
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			if (store.getState().user.id == 419) {
				console.debug("props.value:", props.value);
			}
			this.setState({ value: [...(props.value || [])] });
		}
	}

	addFile(file) {
		if (!this.state.value) {
			this.state.value = [Object.assign(file, { user_id: store.getState().user.id })];
		} else {
			this.state.value.push(Object.assign(file, { user_id: store.getState().user.id }));
		}
		this.setState({ value: this.state.value }, () => {
			setTimeout(() => {
				const r = this.boardUploadRefs[this.boardUploadRefs.length - 1];
				if (r && r.handleOpen) r.handleOpen();
			}, 750);
		});
		if (this.props.onChange) this.props.onChange({ value: this.state.value });
		if (this.props.onComplete) this.props.onComplete({ value: this.state.value });
	}

	onChange(value, file) {
		this.setState({ value: value || [] });
		if (this.props.onChange) this.props.onChange({ value: value || [] });
	}

	render() {
		const popupWidth = Math.min(window.innerWidth * 0.8, this.props.width || 450);
		const content = (
			<div style={{ minWidth: popupWidth }}>
				{this.state.active || this.state.showPdfTemplateModal || this.props.expanded ? (
					<Upload
						open={this.state.openUpload}
						onClose={() => {
							this.setState({ openUpload: false });
						}}
						files={[]}
						width={this.state.value.length < 1 ? "auto" : 0}
						height={this.state.value.length < 1 ? "auto" : 0}
						onUpload={this.addFile.bind(this)}
					/>
				) : null}
				<div className="attachments_wrapper">
					{this.state.value &&
						Array.isArray(this.state.value) &&
						this.state.value.map((file, index, ar) => (
							<BoardUploadSheet
								onChange={this.onChange.bind(this)}
								file={file}
								values={this.state.value}
								// key={file.id}
								key={`${index}_${file?.created_at}`}
								row={this.props.row}
								column={this.props.column}
								board={this.props.board}
								ref={(ref) => {
									this.boardUploadRefs[index] = ref;
								}}
								small
								history={this.props.history}
							/>
						))}
				</div>
				{this.state.value.length > 0 ? (
					<div>
						<Button
							primary
							fullWidth
							onClick={() => {
								this.setState({ openUpload: true });
							}}
						>
							{this.props.t("cell.upload.actions.choose", "Välj fil att ladda upp")}
						</Button>
					</div>
				) : null}
				<div style={{ textAlign: "center", paddingTop: 5, paddingBottom: 5 }}>{this.props.t("common.terms.or", "eller")}</div>
				<Button
					primary
					fullWidth
					onClick={() => {
						this.setState({ showPdfTemplateModal: true });
					}}
				>
					{this.props.t("cell.upload.actions.send_from_pdf_template", "Skapa från PDF mall")}
				</Button>
				<PdfTemplateSheet
					contact={this.props.initialContact}
					onSave={this.addFile.bind(this)}
					save
					requireName
					open={this.state.showPdfTemplateModal}
					onClose={() => {
						this.setState({ showPdfTemplateModal: false });
					}}
					row={this.props.row}
					board={this.props.board}
				/>
			</div>
		);

		if (this.props.expanded) {
			return content;
		}
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active || this.state.showPdfTemplateModal}
				activator={
					<div
						style={{
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							padding: 3,
							height: this.props.height,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<LegacyStack spacing="none" wrap={false}>
							{!this.state.active ? (
								<div
									className="board-display-hover"
									onClick={(e) => {
										e.stopPropagation();
										this.setState({ openUpload: true });
									}}
									style={{ paddingRight: 3, paddingTop: 5 }}
								>
									<Icon source={CirclePlusMinor} color="blue" />
									<div style={{ position: "absolute", left: 100000 }}>
										<Upload
											open={this.state.openUpload}
											onClose={() => {
												this.setState({ openUpload: false });
											}}
											small
											files={[]}
											width={this.state.active ? 100 : 0}
											height={this.state.active ? 100 : 0}
											onUpload={this.addFile.bind(this)}
										/>
									</div>
								</div>
							) : null}
							<LegacyStack.Item fill>
								{this.state.value.length > 0 ? <Attachment hideDelete file={this.state.value[0]} clickThrough /> : null}
							</LegacyStack.Item>
							{this.state.value.length > 1 ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{this.state.value.length - 1}
								</div>
							) : null}
						</LegacyStack>
					</div>
				}
				onClose={() => this.setState({ active: false }, this.props.onBlur)}
			>
				<div style={{ width: popupWidth + 50, position: "relative", overflow: "hidden", padding: 15 }}>{content}</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellUpload);
