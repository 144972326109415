import React, { Component } from "react";
import { connect } from "react-redux";
import Modals from "./Modals";
import Action from "./Action";
import CustomizeLayout from "./Slides/CustomizeLayout";
import EnterUserInfo from "./Slides/EnterUserInfo";
import NextButton from "./NextButton";
import UserInviter from "../../components/UserInviter";

const Content = React.forwardRef((props, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { slide: { action } = {} } = props || {};

	if (action === "customize") {
		return <CustomizeLayout ref={ref} />;
	}
	if (action === "enterUserInfo") {
		return <EnterUserInfo ref={ref} />;
	}
	if (action === "userInviter") {
		return <UserInviter ref={ref} />;
	}

	return null;
});

class Slide extends Component {
	constructor(props) {
		super(props);
		this.state = { modalOpen: false };
		this.contentRef = React.createRef();
	}

	onOpenAction() {
		const slidContainerInner = document.querySelector(".IntroductionSlide-inner");
		if (slidContainerInner) {
			slidContainerInner.classList.add("open");
		}
		this.setState({ modalOpen: true });
	}

	onCloseAction() {
		const slidContainerInner = document.querySelector(".IntroductionSlide-inner");
		if (slidContainerInner) {
			slidContainerInner.classList.remove("open");
		}
		this.setState({ modalOpen: false });
	}

	next() {
		if (this.contentRef.current && this.contentRef.current.onSave) {
			this.contentRef.current.onSave();
		}
		this.props.next();
	}

	skip() {
		this.props.next();
	}

	onClose() {
		this.props.onClose();
		if (this.contentRef.current && this.contentRef.current.onSave) {
			this.contentRef.current.onSave();
		}
	}

	render() {
		const { slide: { title, text, action, navigate, image_url: imageUrl, id } = {}, slides, index, onClose } = this.props;
		const right = (() => {
			if (action === "asd") {
				return null;
			}

			return imageUrl ? <img alt="" className="image" src={imageUrl} /> : <div />;
		})();
		return (
			<div className={`slide${!imageUrl ? " no-image" : ""}`} key={id || index}>
				<div className="con">
					<div className="left">
						<h1 className="title">{title}</h1>
						<div className="content">
							<p className="text">{text}</p>
							<Content ref={this.contentRef} slide={this.props.slide} />
						</div>
					</div>
					<div className={`right ${action}`}>{right}</div>
				</div>
				<Modals modal={action} open={this.state.modalOpen} onClose={this.onCloseAction.bind(this)} />
				<div className="buttons">
					<NextButton index={index} slides={slides} onSkip={this.skip.bind(this)} onClose={this.onClose.bind(this)} />
					<Action
						action={action}
						// onSave={this.contentRef.current && this.contentRef.current.onSave && this.contentRef.current.onSave.bind(this)}
						skip={this.skip.bind(this)}
						next={this.next.bind(this)}
						history={this.props.history}
						navigate={navigate}
						onClose={onClose}
						onOpenAction={this.onOpenAction.bind(this)}
						onCloseAction={this.onCloseAction.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	user: state.user,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Slide);
