/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import FortnoxOffersContactList from "./FortnoxOffersContactList";

type FortnoxOffersListProps = {
	history: any;
} & WithTranslation;

const FortnoxOffersList = ({ ...props }: FortnoxOffersListProps) => {
	return (
		<Page title={props.t("fortnox.offers.title", "Offerter") as string} history={props.history}>
			<FortnoxOffersContactList {...props} history={props.history} />
		</Page>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOffersList);
