import React, { Component, ReactElement } from "react";
import styled from "styled-components";

type ModalOptionBoxProps = {
	icon: unknown;
	title: string | ReactElement;
	description?: string | ReactElement;
	children?: unknown;
	disabled?: boolean;
	style?: React.CSSProperties;
};
type ModalOptionBoxState = unknown;

class ModalOptionBox extends Component<ModalOptionBoxProps, ModalOptionBoxState> {
	constructor(props: ModalOptionBoxProps) {
		super(props);
		this.state = {};
	}

	render() {
		const { icon, title, description, disabled = false, children, style } = this.props;

		return (
			<StyledWrapper disabled={disabled} style={style}>
				<StyledWrapperInner>
					<StyledWrapperIcon>{icon}</StyledWrapperIcon>
					<StyledTitle>{title}</StyledTitle>
					<StyledDescrption>{description}</StyledDescrption>
				</StyledWrapperInner>
				<StyledButtonWrapper>{children}</StyledButtonWrapper>
			</StyledWrapper>
		);
	}
}
export default ModalOptionBox;

const StyledWrapper = styled.div.attrs({ className: "import_option_box" })<{ disabled?: boolean }>`
	// background-color: rgb(62, 73, 85);
	background-color: var(--main2);
	border-radius: 10px;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.25) 3px 4px 5px;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	gap: 0.7812rem;
	// width: 250px;
	flex: 1;
	font-size: 14px;
	line-height: 16px;
	min-height: 280px;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
	color: var(--textColor);
`;
const StyledWrapperInner = styled.div.attrs({ className: "import_option_box__inner" })`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;
	overflow: hidden;
`;
const StyledWrapperIcon = styled.div.attrs({ className: "import_option_box__icon" })`
	text-align: center;

	svg,
	img {
		height: 30px;
	}
`;
const StyledTitle = styled.h2.attrs({ className: "import_option_box__title" })`
	text-align: center;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
`;
const StyledDescrption = styled.div.attrs({ className: "import_option_box__description" })`
	text-align: center;
`;
const StyledButtonWrapper = styled.div.attrs({ className: "import_option_box__button" })`
	display: flex;
	justify-content: center;
	flex-direction: column;

	img,
	.Polaris-Icon,
	.Polaris-Spinner {
		height: 25px;
		width: 25px;
		object-fit: cover;
		margin-right: 10px;

		/* svg {
			fill: var(--textColor);
		} */
	}

	.Polaris-Select {
		.Polaris-Select__Content {
			background-color: rgba($green, 0.25);
			height: 25px;
			min-height: 25px;
			border-radius: 12.5px;
			min-width: 150px;
			text-align: center;

			.Polaris-Select__Icon .Polaris-Icon svg {
				fill: var(--textColor);
			}

			.Polaris-Select__SelectedOption {
				overflow: hidden;
				white-space: pre-line;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
			}
		}

		select.Polaris-Select__Input {
			background-color: transparent !important;
		}

		.Polaris-Select__Backdrop {
			box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.25) !important;
			border: none;
			border-radius: 12.5px;
			background-color: transparent !important;
			background: transparent !important;

			&::after {
				border-radius: 12.5px;
				background-color: transparent !important;
				background: transparent !important;
			}
		}
	}
`;
