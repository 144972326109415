import { Tooltip } from "@shopify/polaris";
import Button from "src/js/components/Button";
import styled from "styled-components";

const ToolTipButton = ({ tooltip, image, className = "", enabled, ...props }) => {
	return (
		<Wrapper>
			<Tooltip content={tooltip}>
				<span className={`${image ? "Polaris-Button--image-btn" : ""} ${className}`}>
					{/* eslint-disable-next-line react/jsx-props-no-spreading  */}
					<Button pressed={enabled} {...props}>
						{image}
					</Button>
				</span>
			</Tooltip>
		</Wrapper>
	);
};

export default ToolTipButton;

const Wrapper = styled.div`
	.Polaris-Button svg,
	.Polaris-Button:not(.Polaris-Button--destructive, .Polaris-Button--primary) path {
		fill: #637381;
	}

	button.Polaris-Button.Polaris-Button--pressed {
		filter: brightness(1.5);
	}
`;
