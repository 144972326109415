import React from "react";
import { withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import AdvancedStatsBoxesWrapper from "src/js/components/AdvancedStatsBoxes";
import { currencyFormatter } from "src/js/Utilities";

const SalesAdvancedStatsBoxes = ({ data, loading, fromdate, todate, t }) => {
	return (
		<AdvancedStatsBoxesWrapper
			perRow={2}
			loading={loading}
			items={[
				{
					title: t("sales.boxes.won.title", "Vunna affärer"),
					text: `${fromdate} - ${todate}`,
					tooltip: t("sales.boxes.won.tooltip", "Vunna affärer under valt datumintervall"),
					col1: {
						label: t("sales.boxes.won.col1.label", "Affärer"),
						value: data?.boxes?.won?.count,
					},
					col2: {
						label: t("sales.boxes.won.col2.label", "Totalt värde"),
						value: currencyFormatter({ maximumFractionDigits: 0, value: data?.boxes?.won?.total }),
						color: Colors.green,
					},
					error: data?.boxes?.won?.error,
				},
				{
					title: t("sales.boxes.open.title", "Öppna affärer"),
					tooltip: t(
						"sales.boxes.open.tooltip",
						"Affärer som inte ännu är förlorade eller vunna. Summan av affärernas omsättning med hänsyn till sannolikhet till affär: {{weighted_total}}",
						{ weighted_total: currencyFormatter({ maximumFractionDigits: 0, value: data?.boxes?.open?.weighted_total }) }
					),
					col1: {
						label: t("sales.boxes.open.col1.label", "Affärer"),
						value: data?.boxes?.open?.count,
					},
					col2: {
						label: t("sales.boxes.open.col2.label", "Totalt värde"),
						value: currencyFormatter({ maximumFractionDigits: 0, value: data?.boxes?.open?.total }),
						color: Colors.yellow,
					},
					error: data?.boxes?.open?.error,
				},
				{
					title: t("sales.boxes.hit_rate.title", "Hit rate") + (data?.boxes?.hit_rate?.ratio ? ` ${data?.boxes?.hit_rate?.ratio}` : ""),
					text: `${fromdate} - ${todate}`,
					tooltip: t("sales.boxes.hit_rate.tooltip", "Andel vunna/förlorade av alla avslutade affärer under valt datumintervall"),
					col1: {
						label: t("sales.boxes.hit_rate.col11.label", "Vunna"),
						value: data?.boxes?.hit_rate?.won,
						color: Colors.green,
					},
					col2: {
						label: t("sales.boxes.hit_rate.col22.label", "Förlorade"),
						value: data?.boxes?.hit_rate?.lost,
						color: Colors.red,
					},
					error: data?.boxes?.hit_rate?.error,
				},
				{
					title: t("sales.boxes.hot.title", "Heta affärer"),
					tooltip: t(
						"sales.boxes.hot.tooltip",
						`En affär är het när den inte är vunnen/förlorad, "Förväntad order" datum inte är passerat och "Offert skickad" är satt`
					),
					col1: {
						label: t("sales.boxes.hot.col1.label", "Affärer"),
						value: data?.boxes?.hot?.count,
					},
					col2: {
						label: t("sales.boxes.hot.col2.label2", "Totalt värde"),
						value: currencyFormatter({ maximumFractionDigits: 0, value: data?.boxes?.hot?.total }),
						color: Colors.green,
					},
					error: data?.boxes?.hot?.error,
				},
				{
					title: t("sales.boxes.lost.title", "Förlorade"),
					text: `${fromdate} - ${todate}`,
					tooltip: t("sales.boxes.lost.tooltip", "Förlorade affärer under valt datumintervall"),
					col1: {
						label: t("sales.boxes.lost.col1.label", "Affärer"),
						value: data?.boxes?.lost?.count,
					},
					col2: {
						label: t("sales.boxes.lost.col2.label2", "Totalt värde"),
						value: currencyFormatter({ maximumFractionDigits: 0, value: data?.boxes?.lost?.total }),
						color: Colors.red,
					},
					error: data?.boxes?.lost?.error,
				},
				{
					title: t("sales.boxes.cold.title", "Kalla affärer"),
					tooltip: t("sales.boxes.cold.tooltip", `En affär är kall när den inte är vunnen/förlorad, "Förväntad order" datum är passerat`),
					col1: {
						label: t("sales.boxes.cold.col1.label", "Affärer"),
						value: data?.boxes?.cold?.count,
					},
					col2: {
						label: t("sales.boxes.cold.col2.label2", "Totalt värde"),
						value: currencyFormatter({ maximumFractionDigits: 0, value: data?.boxes?.cold?.total }),
						color: Colors.blue,
					},
					error: data?.boxes?.cold?.error,
				},
			]}
		/>
	);
};
export default withTranslation(["sales"])(SalesAdvancedStatsBoxes);
