export default (state = {}, action) => {
	switch (action.type) {
		case "SET_DASHBOARDS":
			return action.dashboards;
		case "ADD_DASHBOARD": {
			if (!action.dashboard) return state;
			const dashboards = [].concat(state, [action.dashboard]);
			return dashboards;
		}
		case "UPDATE_DASHBOARD": {
			if (!action.dashboard) return state;
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.dashboard.id) {
					newState[i] = action.dashboard;
				}
			}
			return newState;
		}

		case "REMOVE_DASHBOARD": {
			if (!action.dashboard) return state;
			const dashboards = (state || []).filter((dashboard = {}) => dashboard.id != action.dashboard.id);
			return dashboards;
		}
		default:
			return state;
	}
};
