import React from "react";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import Modal from "./modal";
import MediumBoard from "./MediumBoard";

type BoardRowSelectModalProps = {
	open: boolean;
	onClose: () => void;
	board: BoardType;
	column?: BoardColumnType | null;
	contact?: ContactType | null;
	onSelect?: (row: BoardRowType) => void;
	t: any;
	children?: any;
};

const BoardRowSelectModal = ({ open, onClose, board, column, contact, onSelect, t, children = null }: BoardRowSelectModalProps) => {
	const handleSelect = (row) => {
		if (onSelect) onSelect(row);
	};
	return (
		<Modal
			open={open}
			onClose={onClose}
			title={t("board.actions.select_row", "Välj {{singular}}", { singular: board?.singular || "rad" })}
			sectioned
			large
			secondaryActions={[
				{
					content: t("common.actions.close", "Stäng"),
					onAction: onClose,
					accessibilityLabel: t("common.actions.close", "Stäng"),
				},
			]}
		>
			{children || (
				<MediumBoard
					board={store.getState().boards[board?.id] || board}
					boardId={board?.id}
					contactId={contact?.id}
					contact={contact}
					onCreateRow={handleSelect}
					onClickRow={handleSelect}
					params={{ sort: "CREATED_AT_DESC" }}
				/>
			)}
		</Modal>
	);
};
export default withTranslation(["common", "board"])(BoardRowSelectModal);
