import React from "react";

const People3Icon = ({ color = "var(--textColor)" }) => (
	<svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.22266 7.75757C5.32266 7.75757 6.22266 6.85757 6.22266 5.75757C6.22266 4.65757 5.32266 3.75757 4.22266 3.75757C3.12266 3.75757 2.22266 4.65757 2.22266 5.75757C2.22266 6.85757 3.12266 7.75757 4.22266 7.75757ZM5.35266 8.85757C4.98266 8.79757 4.61266 8.75757 4.22266 8.75757C3.23266 8.75757 2.29266 8.96757 1.44266 9.33757C0.702656 9.65757 0.222656 10.3776 0.222656 11.1876V12.7576H4.72266V11.1476C4.72266 10.3176 4.95266 9.53757 5.35266 8.85757ZM20.2227 7.75757C21.3227 7.75757 22.2227 6.85757 22.2227 5.75757C22.2227 4.65757 21.3227 3.75757 20.2227 3.75757C19.1227 3.75757 18.2227 4.65757 18.2227 5.75757C18.2227 6.85757 19.1227 7.75757 20.2227 7.75757ZM24.2227 11.1876C24.2227 10.3776 23.7427 9.65757 23.0027 9.33757C22.1527 8.96757 21.2127 8.75757 20.2227 8.75757C19.8327 8.75757 19.4627 8.79757 19.0927 8.85757C19.4927 9.53757 19.7227 10.3176 19.7227 11.1476V12.7576H24.2227V11.1876ZM16.4627 8.40757C15.2927 7.88757 13.8527 7.50757 12.2227 7.50757C10.5927 7.50757 9.15266 7.89757 7.98266 8.40757C6.90266 8.88757 6.22266 9.96757 6.22266 11.1476V12.7576H18.2227V11.1476C18.2227 9.96757 17.5427 8.88757 16.4627 8.40757ZM8.29266 10.7576C8.38266 10.5276 8.42266 10.3676 9.20266 10.0676C10.1727 9.68757 11.1927 9.50757 12.2227 9.50757C13.2527 9.50757 14.2727 9.68757 15.2427 10.0676C16.0127 10.3676 16.0527 10.5276 16.1527 10.7576H8.29266ZM12.2227 2.75757C12.7727 2.75757 13.2227 3.20757 13.2227 3.75757C13.2227 4.30757 12.7727 4.75757 12.2227 4.75757C11.6727 4.75757 11.2227 4.30757 11.2227 3.75757C11.2227 3.20757 11.6727 2.75757 12.2227 2.75757ZM12.2227 0.757568C10.5627 0.757568 9.22266 2.09757 9.22266 3.75757C9.22266 5.41757 10.5627 6.75757 12.2227 6.75757C13.8827 6.75757 15.2227 5.41757 15.2227 3.75757C15.2227 2.09757 13.8827 0.757568 12.2227 0.757568Z"
			fill={color}
		/>
	</svg>
);

export default People3Icon;
