import React from "react";

const StackIcon = ({ color = "var(--textColor)" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none">
		<path d="M4.13818 22.6919L16.1382 29.6919L28.1382 22.6919" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M4.13818 16.6919L16.1382 23.6919L28.1382 16.6919" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M4.13818 10.6919L16.1382 17.6919L28.1382 10.6919L16.1382 3.69189L4.13818 10.6919Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default StackIcon;
