/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import ContextMenu from "../ContextMenu";
import FortnoxTableRow from "./FortnoxTableRow";
import FortnoxTableSummaryRow from "./FortnoxTableSummaryRow";

class FortnoxTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// columns: props.columns || [],
			data: props.data || [],
			// clicked: false,
			// contextEvent: {
			// 	index: null,
			// 	item: null,
			// 	x: 0,
			// 	y: 0,
			// },
		};
		// this.handleContextMenu = this.onCloseContextMenu.bind(this);
		// this.menu = React.createRef();
	}

	componentDidMount() {
		window.addEventListener("click", this.handleContextMenu);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.handleContextMenu);
	}

	// onCloseContextMenu(e) {
	// 	const { pageX, pageY } = e;

	// 	if (this.state.clicked && menu) {
	// 		this.setState({ clicked: false, contextEvent: { index: null, item: null, x: 0, y: 0 } });
	// 	}
	// }

	// onShowContextMenu(item, index, e) {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	this.setState({ clicked: true, contextEvent: { x: e.pageX, y: e.pageY, index, item } });
	// }

	updateRow(index, row) {
		this.state.data[index] = row;
		const emptyRows = this.state.data.filter((i) => Object.keys(i).length === 0).length;
		if (emptyRows < 1) {
			this.state.data = [].concat(
				this.state.data,
				Array.from(Array(1 - emptyRows)).map(() => ({}))
			);
		}
		this.setState({ data: this.state.data }, () => this?.props?.onChange?.(this.state.data));
	}

	onRemoveRow(index) {
		const arr = [...(this.state.data || [])];
		arr.splice(index, 1);
		if (!arr.length) {
			arr.push({});
		}
		this.setState({ data: arr }, () => this?.props?.onChange?.(this.state.data));
	}

	render() {
		return (
			<div>
				<table className="fortnox">
					<thead className="Fortnox__Table__Header">
						<tr>
							{this.props.columns.reduce((acc, curr) => {
								acc.push(<th {...curr.props}>{curr.translationHandle ? this.props.t(curr.translationHandle, curr.label) : curr.label}</th>);
								return acc;
							}, [])}
						</tr>
					</thead>
					<tbody className="Fortnox__Table__Body">
						{this.state.data.map((row, index) => (
							<FortnoxTableRow
								// onContextMenu={this.onShowContextMenu.bind(this, row, index)}
								columns={this.props.columns}
								external={this.props.external}
								integration={this.props.integration}
								key={index}
								row={row}
								onChange={this.updateRow.bind(this, index)}
								onRemoveRow={this.onRemoveRow.bind(this, index)}
							/>
						))}
						<FortnoxTableSummaryRow columns={this.props.columns} integration={this.props.integration} />
					</tbody>
					{this.props.footer || null}
				</table>
				{/* <div>
					<ContextMenu className="Context__Menu" open={this.state.clicked} ref={this.menu} {...this.state.contextEvent} />
				</div> */}
			</div>
		);
	}
}

export default withTranslation(["fortnox", "table"], { withRef: true })(FortnoxTable);
