import React from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { store } from "../store";
import { ActivitiesMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import NavigationHeaderButton from "./NavigationHeaderButton";

const MyAcitivtyButtonLink = ({ t }) => {
	return (
		<Tooltip content={t("header.activity.tooltip", "Min aktivitet")}>
			<NavigationHeaderButton
				to={`/admin/users_activity/user/${store.getState().user.id}`}
				tooltip={t("header.activity.tooltip", "Min aktivitet")}
				icon={<Icon source={ActivitiesMajor} />}
			/>
		</Tooltip>
	);
};

export default withTranslation(["header"])(MyAcitivtyButtonLink);
