import React, { Component } from "react";
import { LegacyStack, LegacyCard, Select, TextField, Button, FormLayout } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import ResourcePicker from "../../components/resource_picker.js";
import ProjectTaskStatusSelector from "../../components/ProjectTaskStatusSelector.js";
import { store } from "../../store";

class Condition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: props.action,
		};
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
		this.props.onChange(this.state.form);
	}

	removeAction() {
		this.props.onRemove();
	}

	changeAction(action) {
		if (action == "project_task.set_status") {
			this.updateForm("status", store.getState().project_statuses[0]);
		}
		this.updateForm("action", action);
	}

	render() {
		const actions = [
			{ value: "notify", label: this.props.t("triggers.actions.notify", "Notifiera") },
			{ value: "contacts.update", label: this.props.t("triggers.actions.contacts.update", "Uppdatera kontakt") },
			{ value: "call_lists.add", label: this.props.t("triggers.actions.call_lists.add", "Lägg i ringlista") },
			{ value: "email_campaigns.add", label: this.props.t("triggers.actions.email_campaigns.add", "Lägg i e-postkampanj") },
			{ value: "deals.create", label: this.props.t("triggers.actions.deals.create", "Skapa deal") },
			{ value: "task.create", label: this.props.t("triggers.actions.task.create", "Skapa påminnelse") },
			{ value: "ticket.create", label: this.props.t("triggers.actions.ticket.create", "Skapa ärende") },
			{ value: "webhook.send", label: this.props.t("triggers.actions.webhook.send", "Skicka till Webhook") },
		];

		if (this.props.event == "tickets.create" || this.props.event == "tickets.update") {
			actions.push({ value: "ticket.set_status", label: this.props.t("triggers.actions.ticket_set_status", "Ändra ärendestatus") });
		}
		if (
			this.props.event == "projects.tasks.create" ||
			this.props.event == "projects.tasks.update" ||
			this.props.event == "projects.tasks.delegate" ||
			this.props.event == "projects.tasks.undelegate" ||
			this.props.event == "projects.tasks.complete" ||
			this.props.event == "projects.tasks.verified" ||
			this.props.event == "projects.tasks.billed" ||
			this.props.event == "projects.tasks.checkin" ||
			this.props.event == "projects.tasks.checkout" ||
			this.props.event == "projects.tasks.logs.create" ||
			this.props.event == "projects.tasks.logs.update"
		) {
			actions.push({ value: "project_task.set_status", label: this.props.t("triggers.actions.project_set_status", "Ändra uppdragsstatus") });
		}

		return (
			<LegacyCard.Section>
				<FormLayout>
					<LegacyStack>
						<LegacyStack.Item fill>
							<Select options={actions} onChange={this.changeAction.bind(this)} value={this.state.form.action} />
						</LegacyStack.Item>
						{this.state.default ? null : <Button icon={DeleteMajor} onClick={this.removeAction.bind(this)} />}
					</LegacyStack>
					{this.state.form.action == "email_campaigns.add" ? (
						<ResourcePicker
							label={this.props.t("triggers.fields.call_list.label", "Välj en ringlista")}
							caption={this.props.t("triggers.fields.call_list.caption", "Ingen ringlista är vald")}
							resource="call_lists"
							item={this.state.call_list}
							allowCreate
							onChange={(callList) => {
								if (!callList || !callList.id) {
									// eslint-disable-next-line react/no-unused-state
									this.setState({ title: callList.title, showCallListModal: true });
								} else {
									this.setState({ call_list: callList });
								}
							}}
							id_handle="id"
							label_handle="title"
							resourceName={{
								singular: this.props.t("triggers.call_list.singular", "ringlista"),
								plural: this.props.t("triggers.call_list.plural", "ringlistor"),
							}}
						/>
					) : null}
					{this.state.form.action == "ticket.set_status" ? (
						<Select
							label={this.props.t("triggers.fields.status.label", "Ärendestatus")}
							options={[
								{ value: "open", label: this.props.t("triggers.fields.status.option.open", "Öppen") },
								{ value: "pending", label: this.props.t("triggers.fields.status.option.pending", "Väntande") },
								{ value: "closed", label: this.props.t("triggers.fields.status.option.closed", "Avslutad") },
								{ value: "removed", label: this.props.t("triggers.fields.status.option.removed", "Borttagen") },
								{ value: "spam", label: this.props.t("triggers.fields.status.option.spam", "Markerat som spam") },
							]}
							onChange={this.updateForm.bind(this, "status")}
							value={this.state.form.status}
						/>
					) : null}
					{this.state.form.action == "project_task.set_status" ? (
						<ProjectTaskStatusSelector
							label={this.props.t("triggers.fields.project_status.label", "Status")}
							status={this.state.form.status}
							onChange={this.updateForm.bind(this, "status")}
						/>
					) : null}
					{this.state.form.action == "webhook.send" ? (
						<TextField
							label={this.props.t("triggers.fields.url.label", "Webbadress")}
							value={this.state.form.url}
							onChange={this.updateForm.bind(this, "url")}
						/>
					) : null}
					{this.state.form.action == "mail.send" ? (
						<TextField
							label={this.props.t("triggers.fields.subject.label", "Rubrik")}
							value={this.state.form.subject}
							onChange={this.updateForm.bind(this, "subject")}
						/>
					) : null}
					{this.state.form.action == "mail.send" ? (
						<TextField
							multiline
							label={this.props.t("triggers.fields.message.label", "Meddelande")}
							value={this.state.form.message}
							onChange={this.updateForm.bind(this, "message")}
						/>
					) : null}
				</FormLayout>
			</LegacyCard.Section>
		);
	}
}

export default withTranslation(["triggers", "common"], { withRef: true })(Condition);
