import React, { Component } from "react";

class LinkPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		if (!this.props.data) return null;
		const { cover, description, images, title, /* video, videoType, */ url, embed } = this.props.data || {};
		return (
			<div className="link_preview">
				{embed ? (
					<div dangerouslySetInnerHTML={{ __html: embed }} />
				) : (
					<React.Fragment>
						<a className="title" href={url} target="_blank" rel="noreferrer">
							{title}
						</a>
						<div className="description_wrapper">
							<p className="description">{description}</p>
							<img alt="" className="cover" src={cover} />
						</div>
						<a href={images && images[0]} target="_blank" rel="noreferrer">
							<img alt="" className="image" src={images && images[0]} />
						</a>
					</React.Fragment>
				)}
			</div>
		);
	}
}
export default LinkPreview;
