import i18next from "i18next";

const deliveryFields = {
	name: i18next.t("orders.delivery.fields.name.label", "Namn"),
	address: i18next.t("orders.delivery.fields.address.label", "Levaransadress"),
	address2: i18next.t("orders.delivery.fields.address2.label", "Levaransadress 2"),
	city: i18next.t("orders.delivery.fields.city.label", "Stad"),
	postalCode: i18next.t("orders.delivery.fields.postal_code.label", "Postnummer"),
	country: i18next.t("orders.delivery.fields.country.label", "Land"),
	deliveryType: i18next.t("orders.delivery.fields.delivery_type.label", "Leveranssätt"),
	deliveryDate: i18next.t("orders.delivery.fields.delivery_date.label", "Leveransdatum"),
};

export default deliveryFields;
