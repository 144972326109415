import React, { useContext } from "react";
import styled from "styled-components";
import NewFilter from "./NewFilter";
import TargetContext from "../Context/TargetContext";
import FiltersList from "./FiltersList";

const TargetFilters = () => {
	const { form, target, setForm, isLoading } = useContext(TargetContext);

	const handleChangeFilter =
		(index = null) =>
		(targetFilter) => {
			setForm((form) => {
				const newForm = { ...form };

				//!temp
				if (!newForm.target_sources) newForm.target_sources = [];

				if (index === null) {
					newForm.target_sources.push(targetFilter);
				} else {
					if (!targetFilter) {
						newForm.target_sources.splice(index, 1);
						return newForm;
					}
					newForm.target_sources[index] = targetFilter;
				}

				return newForm;
			});
		};

	return (
		<Wrapper isLoading={!!isLoading}>
			<NewFilter onSubmit={handleChangeFilter(null)} isLoading={isLoading} />

			<FiltersList form={form} target={target} handleChangeFilter={handleChangeFilter} isLoading={isLoading} />
		</Wrapper>
	);
};
export default TargetFilters;

const Wrapper = styled.div<{ isLoading?: boolean }>`
	transition: opacity 0.3s;

	${(props) => props.isLoading && `opacity: 0.5;`}
`;
