import React from "react";
import { Scrollable } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import RowSheetContent from "./RowSheetContent.js";
import Sheet from "../../../components/sheet";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors.js";

const RowSheet = (props) => {
	const { t } = useTranslation(["row", "common"]);

	return (
		<Sheet
			open={props.open}
			onClose={props.onClose}
			staus="info"
			title={
				props.row?.title ? (
					<div>
						{props.row?.title}
						{props.row?.archived && <Badge color={Colors.yellow}>{t("row.archived", "Arkiverad")}</Badge>}
					</div>
				) : (
					`${t("common.actions.create", "Skapa")} ${(props.board && props.board.singular) || t("row.singular", "Rad")}`
				)
			}
		>
			<div
				className="row_sheet"
				style={{
					display: "flex",
					flexDirection: "column",
					// height: "calc(100% - 56px)",
				}}
			>
				<Scrollable style={{ flex: 1 }}>
					<RowSheetContent
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...props}
						contact={props.contact}
						initialTabIndex={props.initialTabIndex != undefined ? props.initialTabIndex : 0}
						open={props.open}
						board={props.board}
						onClose={props.onClose}
						row={props.row}
						onUpdateRow={props.onUpdateRow}
					/>
				</Scrollable>
			</div>
		</Sheet>
	);
};
export default RowSheet;
