import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { store } from "../../store";

import Calendar from "../Agenda/Calendar/Calendar";
import LeaderboardSmall from "./Leaderboard_Small";
import WeeklyTasks from "../Agenda/WeeklyTasks";
import WeeklyTasksProvider from "../Agenda/WeeklyTasksContextProvider";
import TargetProgressBoxes from "../Targets/Widgets/TargetProgressBox";

class MyDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.source = axios.CancelToken.source();
	}

	cancelRequest() {
		if (this.source) {
			this.source.cancel();
		}
	}

	componentWillUnmount() {
		this.cancelRequest();
	}

	componentDidMount() {}

	refreshCalendar() {
		this.calendarRef?.refresh?.();
	}

	refreshTodo() {
		this.todoRef?.refresh?.();
	}

	render() {
		return (
			<WeeklyTasksProvider history={this.props.history} calendar={{ refresh: this.refreshCalendar.bind(this) }}>
				<div className="dashboardContainer">
					<div className="left">
						{store.getState().user.calendar_board && (
							<Calendar
								ref={(ref) => {
									this.calendarRef = ref;
								}}
								refreshTodo={this.refreshTodo.bind(this)}
							/>
						)}

						{store.getState().user.todo_board && (
							<div className="WeeklyTodoView">
								<WeeklyTasks
									history={this.props.history}
									ref={(ref) => {
										this.todoRef = ref;
									}}
									refreshCalendar={this.refreshCalendar.bind(this)}
								/>
							</div>
						)}
					</div>
					<div className="right">
						{!!store.getState().targets?.length && (
							<div>
								<h2 className="title" style={{ height: "3.125rem", lineHeight: "2.5rem" }}>
									{this.props.t("dashboard.targets.title", "Målsättningar")}
								</h2>
								<TargetProgressBoxes />
							</div>
						)}
						{store.getState().account.sales_board_id && (
							<LeaderboardSmall title={this.props.t("dashboard.leaderboard.title", "Top 5 Leaderboard")} history={this.props.history} />
						)}
					</div>
				</div>
			</WeeklyTasksProvider>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	goals: state.goals,
	user: state.user,
	account: state.account,
	targets: state.targets,
});

export default connect(mapStateToProps)(withTranslation(["dashboard", "common"], { withRef: true })(MyDashboard));
