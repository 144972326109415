import { Button } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

class ModalBox extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { center, icon, loading, title, text, onAction, actionText = this.props.t("common.actions.choose", "Välj") } = this.props?.item || {};
		return (
			<Wrapper>
				<Icon>{icon}</Icon>
				<Title>{title}</Title>
				<Text center={center}>{text}</Text>

				<StyledButton>
					<Button onClick={onAction} loading={loading} primary>
						{actionText}
					</Button>
				</StyledButton>
			</Wrapper>
		);
	}
}
export default withTranslation(["board", "contact", "common"])(ModalBox);

const Wrapper = styled.div`
	display: grid;
	grid-template-areas: "icon" "title" "text" "button";
	padding: 1.5625rem 1.25rem;
	background: var(--main3);
	border-radius: 0.625rem;
	height: 275px;
	width: 240px;
	grid-template-rows: min-content min-content auto min-content;
	gap: 0.625rem;
	box-shadow: 3px 4px 13px rgba(0, 0, 0, 0.25);
	color: var(--textColor);
`;

const Title = styled.p`
	text-align: center;
	font-size: 18px;
	line-height: 21px;
	padding-block: 0.3125rem;
`;
const Icon = styled.div`
	text-align: center;

	svg {
		width: 35px;
		height: 35px;
	}
`;
const Text = styled.p`
	text-align: ${({ center }) => (center ? "center" : "start")};
	font-size: 14px;
	line-height: 16px;
`;
const StyledButton = styled.div`
	display: flex;
	justify-content: center;

	button {
		min-width: 100px;
		min-height: 1.5625rem;
		transition: filter 250ms;

		&:hover {
			filter: brightness(1.2);
		}

		.Polaris-Button__Content {
			font-size: 0.7812rem;
		}
	}
`;
