export default (state = {}, action) => {
	switch (action.type) {
		case "ADD_INTEGRATION": {
			const newState = Object.assign({}, state);
			const integrations = newState.integrations.slice();
			for (let i = 0; i < integrations.length; i++) {
				if (integrations[i].id == action.integration.id) {
					return state;
				}
			}
			integrations.push(action.integration);
			newState.integrations = integrations;
			return newState;
		}
		case "UPDATE_INTEGRATION": {
			const newState = Object.assign({}, state);
			const integrations = newState.integrations.slice();
			for (let i = 0; i < integrations.length; i++) {
				if (integrations[i].id == action.integration.id) {
					integrations[i] = action.integration;
				}
			}
			newState.integrations = integrations;
			return newState;
		}
		case "REMOVE_INTEGRATION": {
			const newState = Object.assign({}, state);
			const integrations = [];
			for (let i = 0; i < newState.integrations.length; i++) {
				if (newState.integrations[i].id != action.id) {
					integrations.push(newState.integrations[i]);
				}
			}
			newState.integrations = integrations;
			return newState;
		}
		case "SET_SUBSCRIPTION":
			state.subscription = action.subscription;
			return state;
		case "SET_IFRAME_URL":
			state.iframe_url = action.iframe_url;
			return state;
		case "SET_DASHBOARD_URL":
			state.dashboard_url = action.dashboard_url;
			return state;
		case "SET_IGNORED_PATHS":
			state.ignored_paths = action.ignored_paths;
			return state;
		case "SET_FORCE_FOLLOWUP":
			state.force_followup = action.force_followup;
			return state;
		case "SET_AUTO_EMAIL_OPT":
			state.auto_email_opt = action.auto_email_opt;
			return state;
		case "SET_CHAT_COLOR_HSL":
			state.chat_color_hsl = action.chat_color_hsl;
			return state;
		case "SET_CHAT_AUTO_PHRASE":
			state.chat_auto_phrase = action.chat_auto_phrase;
			return state;
		case "SET_CHAT_ENABLED":
			state.chat_enabled = action.chat_enabled;
			return state;
		case "SET_ACCOUNT":
			if (JSON.stringify(state) != JSON.stringify(action.account)) {
				const newState = {
					...state,
					...action.account,
				};

				return newState;
			} else {
				return state;
			}
		default:
			return state;
	}
};
