import React, { Component } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import YoutubeVideoAdPreview from "./YoutubeVideoAdPreview";
import YoutubeVideoAdPreviewSmall from "./YoutubeVideoAdPreviewSmall";
import FacebookAdPreview from "./FacebookAdPreview";

const TOP = 60;

class AdPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
		this.scrollPositionRef = React.createRef();
		this.handleOnScroll = (e) => {
			if (this.ref.current && this.scrollPositionRef.current && props.fixed) {
				const rec = this.scrollPositionRef.current.getBoundingClientRect();
				if (rec.top <= TOP && !this.ref.current.classList.contains("fixed")) {
					this.ref.current.style.setProperty("width", this.ref.current.clientWidth + "px");
					this.ref.current.classList.add("fixed");
				} else if (rec.top > TOP) {
					this.ref.current.classList.remove("fixed");
					if (this.ref.current.style.removeProperty) {
						this.ref.current.style.removeProperty("width");
					} else {
						this.ref.current.style.removeAttribute("width");
					}
				}
			}
		};
		this.onScroll = this.handleOnScroll.bind(this);
	}

	componentDidMount() {
		if (this.props.fixed) window.addEventListener("scroll", this.onScroll);
	}

	componentWillUnmount() {
		if (this.props.fixed) window.removeEventListener("scroll", this.onScroll);
	}

	getMetafieldValue(key) {
		if (key in this.props.metafields) {
			return this.props.metafields[key];
		}
		return null;
	}

	getLocation(href) {
		const l = document.createElement("a");
		l.href = href;
		return l;
	}

	getHost(url) {
		const l = this.getLocation(url);

		return l.hostname;
	}

	getContent() {
		if (this.props.ad_account.type == "adwords") {
			if (this.props.type == "search") {
				return (
					<div>
						<div>{this.props.title || this.props.t("ads.adwords.search.title", "Här bestämmer du hur annonsen ska se ut")}</div>
						<div style={{ backgroundColor: "#fff", padding: 15 }}>
							<div style={{ color: "blue" }}>
								{this.getMetafieldValue("headline1")}
								{this.getMetafieldValue("headline2") ? " | " + this.getMetafieldValue("headline2") : null}
								{this.getMetafieldValue("headline3") ? " | " + this.getMetafieldValue("headline3") : null}
							</div>
							<div style={{ color: "green" }}>
								{this.getHost(this.props.url)}/{this.getMetafieldValue("path1")}
								{this.getMetafieldValue("path2") ? "/" + this.getMetafieldValue("path2") : null}
							</div>
							<div style={{ color: "#707070" }}>
								{this.getMetafieldValue("description1")}
								{" " + (this.getMetafieldValue("description2") ? this.getMetafieldValue("description2") : "")}
							</div>
						</div>
					</div>
				);
			}

			if (this.props.type == "display") {
				return (
					<FacebookAdPreview
						image={this.props.metafields.image}
						title2={this.props.metafields.description}
						description={this.props.metafields.business_name}
					/>
				);
			}

			if (this.props.type == "video") {
				const getChannelId = () => {
					try {
						return this.props.youtubeVideoDetails.channel.items[0].snippet.title;
					} catch (e) {
						return null;
					}
				};

				return (
					<React.Fragment>
						<YoutubeVideoAdPreview
							title={this.props.metafields.headline}
							backgroundImageUrl={`https://img.youtube.com/vi/${this.props.metafields.youtube_video_id}/hqdefault.jpg`}
							description={this.props.metafields.description}
							channel={this.props.metafields.business_name || getChannelId()}
							profileImageUrl={this.props.metafields.profile_image_url}
						/>
						<YoutubeVideoAdPreviewSmall
							title={this.props.metafields.long_headline}
							imageUrl={`https://img.youtube.com/vi/${this.props.metafields.youtube_video_id}/hqdefault.jpg`}
							description={this.props.metafields.description}
							channel={this.props.metafields.business_name}
							callToAction={this.props.metafields.call_to_action}
							profileImageUrl={this.props.metafields.profile_image_url}
						/>
					</React.Fragment>
				);
			}
		} else if (this.props.ad_account.type == "facebook") {
			if (this.props.type == "display") {
				return (
					<FacebookAdPreview
						ad_account={this.props.ad_account}
						image={this.props.metafields.image}
						video={this.props.metafields.video}
						header_title={this.props.metafields.primary_text}
						display_link={this.props.metafields.display_link}
						title={this.props.metafields.title}
						description={this.props.metafields.description}
						showFacebookFooter
						profileImageUrl={
							this.props.ad_account.options && this.props.ad_account.options.page_id
								? `https://graph.facebook.com/${this.props.ad_account.options.page_id}/picture?type=large`
								: null
						}
					/>
				);
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="scrollPosition" ref={this.scrollPositionRef} />
				<PreviewWrapper fixed={this.props.fixed} ref={this.ref}>
					{this.getContent()}
				</PreviewWrapper>
			</React.Fragment>
		);
	}
}
export default withTranslation(["ads", "common"], { withRef: true })(AdPreview);

const PreviewWrapper = styled.div`
	max-width: 800px;
	color: black;
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	height: ${({ fixed }) => (fixed ? "unset" : "calc(100vh - 60px)")};
	&&& {
		.Polaris-LegacyCard {
			background: #ffffff;
			color: inherit;
		}
	}

	&.fixed {
		top: ${TOP}px;
		position: fixed;
		margin-right: 2rem;
	}
`;
