/* eslint-disable camelcase */
import { Badge, LegacyCard, PageActions, ResourceItem } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Toast from "src/js/components/Toast";
import moment from "moment";
import API from "../../API";
import OrderRowInput from "../OrderRowInput";
import FortnoxTable from "./FortnoxTable";
import Banner from "../banner";
import Money from "../Money";
import OrderRowAutocomplete from "../OrderRowAutocomplete";
import BringModalToFront from "../BringModalToFront";
import Page from "../page";
import FortnoxResourceListWithPagination from "./FortnoxResourceListWithPagination";
import Modal from "src/js/components/modal";

class FortnoxContractView extends Component {
	constructor(props) {
		super(props);
		const { customer, form } = props;
		this.state = {
			integration: props.integration || {},
			customer: props.customer || {},
			form:
				{
					...form,
					CustomerNumber: form?.CustomerNumber || customer?.CustomerNumber,
					// ContractDate: form?.ContractDate || moment().format("YYYY-MM-DD"),
					// PeriodStart: form?.PeriodStart || moment().add(1, "months").startOf("month").format("YYYY-MM-DD"),
					YourReference: form?.YourReference || customer?.YourReference,
				} || {},
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.customers) {
			this.setState({
				customer: props.customer,
				form: {
					...this.state.form,
					CustomerNumber: props?.customer?.CustomerNumber,
					// ContractDate: moment().format("YYYY-MM-DD"),
					// PeriodStart: moment().add(1, "months").startOf("month").format("YYYY-MM-DD"),
					YourReference: props?.customer?.YourReference || "",
				},
			});
		}
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		if (field == "InvoiceInterval" && moment(this.state.form?.PeriodStart).isValid()) {
			this.state.form.PeriodEnd = moment(this.state.form.PeriodStart)
				.add(value - 1, "months")
				.endOf("month")
				.format("YYYY-MM-DD");
		}
		this.setState({ form: this.state.form });
	}

	viewContract(contract) {
		if (this?.state?.integration?.id && this?.state?.integration?.integration_id && contract.DocumentNumber) {
			API.get(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/contracts/${contract.DocumentNumber}.json`)
				.then((response) => {
					this.setState({
						form: {
							...(response.data || {}),
							...this.state.form,
							InvoiceRows: [].concat(response?.data?.InvoiceRows || [], [{}]),
							CustomerNumber: this.state.form.customer?.CustomerNumber,
						},
						manage: true,
					});
				})
				.catch((error) => {
					Toast.error("Error", error?.response?.data?.message || "Okänt error!");
				});
		}
	}

	saveContract() {
		this.setState({ saving: true });
		this.state.form = { ...this.state.form, InvoiceRows: (this?.state?.form?.InvoiceRows || []).filter((item) => Object.keys(item).length) };
		if (this?.state?.form?.DocumentNumber) {
			API.put(
				`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/contracts/${this.state.form.DocumentNumber}.json`,
				this.state.form
			)
				.then((response) => {
					this.setState({ manage: false, form: { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					Toast.error("Error", error?.response?.data?.message || "Okänt error!");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		} else {
			API.post(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/contracts.json`, this.state.form)
				.then((response) => {
					this.setState({ manage: false, form: { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					Toast.error("Fel", error?.response?.data?.message || "Okänt fel!");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		}
	}

	convertContract() {
		const { integration_id, id } = this?.state?.integration || {};
		const { DocumentNumber } = this?.state?.form || {};
		if (id && integration_id && DocumentNumber) {
			this.setState({ actionLoading: true });
			API.put(`/api/integrations/${integration_id}/${id}/contracts/${DocumentNumber}/create/invoice.json`)
				.then((response) => {
					this.setState({ showActionModal: false });
				})
				.catch((error) => {
					Toast.error("Error", error?.response?.data?.message || "Unknown error!");
				})
				.finally(() => {
					this.setState({ actionLoading: false });
				});
		}
	}

	render() {
		const { form } = this.state;
		const commonFields = ["ArticleNumber", "Description", (row, value) => ({ ...row, Price: value.SalesPrice || null }), "Unit"];
		// const created = form.OrderReference && form.OrderReference !== "0";
		return (
			<div style={{ paddingTop: 30 }}>
				<Page
					title={
						this.state.manage
							? !form.DocumentNumber
								? this.props.t("fortnox.contract.titles.new", "Fortnox - Nytt avtal")
								: this.props.t("fortnox.contract.title", "Fortnox - Avtal #") + form.DocumentNumber
							: this.props.t("fortnox.contract.titles.page", "Fortnox - Avtal")
					}
					primaryAction={
						!this.state.manage
							? {
									content: this.props.t("fortnox.contract.actions.create", "Skapa nytt avtal"),
									onAction: () => {
										const { customer } = this.props;
										this.setState({
											manage: true,
											form: {
												CustomerNumber: customer?.CustomerNumber,
												ContractDate: moment().format("YYYY-MM-DD"),
												PeriodStart: moment().add(1, "months").startOf("month").format("YYYY-MM-DD"),
												YourReference: customer?.YourReference,
												OurReference: form.OurReference || "",
												Continuous: false,
											},
										});
									},
									loading: this.state.saving,
							  }
							: {
									content: this.props.t("common.actions.save", "Spara"),
									loading: this.state.saving,
									onAction: this.saveContract.bind(this, true),
									disabled: this.state.saving,
							  }
					}
					backAction={
						this.state.manage
							? {
									content: this.props.t("fortnox.contract.breadcrumb", "Avtal"),
									onAction: () => {
										this.setState({ manage: false, form: { OurReference: form.OurReference || "" } });
									},
							  }
							: undefined
					}
				>
					{this.state.manage ? (
						<div>
							<div style={{ marginBottom: 20 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th style={{ width: 50 }}>{this.props.t("fortnox.contract.fields.contract_date.label", "Avtalsdatum")}</th>
											<th style={{ width: 50 }}>{this.props.t("fortnox.contract.fields.start_date.label", "Periodstart")}</th>
											<th style={{ minWidth: 150, width: 50 }}>{this.props.t("fortnox.contract.fields.period_ending.label", "Periodslut")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<input
													type="date"
													value={this.state.form.ContractDate}
													onChange={(e) => {
														this.setState({ form: { ...form, ContractDate: e.target.value } });
													}}
												/>
											</td>
											<td>
												<input
													type="date"
													value={this.state.form.PeriodStart}
													onChange={(e) => {
														this.setState({ form: { ...form, PeriodStart: e.target.value } });
													}}
												/>
											</td>
											<td>
												<input disabled type="date" value={this.state.form.PeriodEnd || "-"} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginBottom: 20 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th style={{ minWidth: 150, width: 50 }}>{this.props.t("fortnox.contract.fields.continues.label", "Löpande")}</th>
											<th style={{ minWidth: 150, width: 50 }}>{this.props.t("fortnox.contract.fields.lenght.label", "Avtalslängd")}</th>
											<th style={{ width: 50 }}>{this.props.t("fortnox.contract.fields.invoice_interval.label", "Fakturaintervall")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div className="Button_Group">
													<button
														onClick={() =>
															this.setState({ form: { ...this.state.form, Continuous: true, ContractLength: 0, InvoicesRemaining: null } })
														}
														disabled={this?.state?.form?.Continuous}
													>
														{this.props.t("common.yes", "Ja")}
													</button>
													<button
														onClick={() => this.setState({ form: { ...this.state.form, Continuous: false } })}
														disabled={!this?.state?.form?.Continuous}
													>
														{this.props.t("common.no", "Nej")}
													</button>
												</div>
											</td>

											<td>
												<OrderRowAutocomplete
													disabled={this?.state?.form?.Continuous}
													value={form.ContractLength}
													onChange={this.updateForm.bind(this, "ContractLength")}
													integration={this.props.integration}
													options={[
														{ label: "1 månad", value: 1 },
														{ label: "2 månader", value: 2 },
														{ label: "3 månader", value: 3 },
														{ label: "4 månader", value: 4 },
														{ label: "5 månader", value: 5 },
														{ label: "6 månader", value: 6 },
														{ label: "7 månader", value: 7 },
														{ label: "8 månader", value: 8 },
														{ label: "9 månader", value: 9 },
														{ label: "10 månader", value: 10 },
														{ label: "11 månader", value: 11 },
														{ label: "12 månader", value: 12 },
													]}
												/>
											</td>

											<td>
												<OrderRowAutocomplete
													value={form.InvoiceInterval}
													onChange={this.updateForm.bind(this, "InvoiceInterval")}
													integration={this.props.integration}
													options={[
														{ label: "1 månad", value: 1 },
														{ label: "2 månader", value: 2 },
														{ label: "3 månader", value: 3 },
														{ label: "4 månader", value: 4 },
														{ label: "5 månader", value: 5 },
														{ label: "6 månader", value: 6 },
														{ label: "7 månader", value: 7 },
														{ label: "8 månader", value: 8 },
														{ label: "9 månader", value: 9 },
														{ label: "10 månader", value: 10 },
														{ label: "11 månader", value: 11 },
														{ label: "12 månader", value: 12 },
													]}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginBottom: 20 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th style={{ width: 100 }}>{this.props.t("fortnox.contract.labels.our_reference", "Vår referens")}</th>
											<th style={{ width: 100 }}>{this.props.t("fortnox.contract.labels.your_reference", "Er referens")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<OrderRowInput value={form.OurReference} onChange={this.updateForm.bind(this, "OurReference")} />
											</td>
											<td>
												<OrderRowInput value={form.YourReference} onChange={this.updateForm.bind(this, "YourReference")} />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div>
								<FortnoxTable
									columns={[
										{
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
											resource: "Articles",
											translationHandle: "fortnox.contract.labels.artnr",
											label: "Artikelnr",
											type: "autoComplete",
											handle: "ArticleNumber",
											updateFields: commonFields,
											props: {
												style: { width: 140 },
											},
										},
										{
											translationHandle: "fortnox.contract.labels.term",
											label: "Benämning",
											type: "autoComplete",
											resource: "Articles",
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
											handle: "Description",
											updateFields: commonFields,
										},
										{
											translationHandle: "fortnox.contract.labels.count",
											label: "Antal",
											type: "input",
											handle: "DeliveredQuantity",
											props: {
												style: { width: 70 },
												className: "text-right",
											},
										},
										{
											translationHandle: "fortnox.common.labels.unit",
											label: "Enhet",
											type: "autoComplete",
											resource: "Units",
											handle: "Unit",
											mapper: (data) => data.map((value) => ({ ...value, Unit: value.Code })),
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/units.json`,
											props: {
												style: { width: 65 },
											},
										},
										{
											translationHandle: "fortnox.contract.labels.a_price",
											label: "À-Pris",
											handle: "Price",
											type: "input",
											props: {
												style: { width: 70 },
												className: "text-right",
											},
										},
										{
											translationHandle: "fortnox.invoice.labels.sum",
											label: "Rad summa",
											handle: (row) => {
												return row.Price * row.DeliveredQuantity;
											},
											type: "text",
											skuffix: this.state.form.currency || "SEK",
											props: {
												style: { width: 100 },
												className: "text-right",
											},
											summary: form.InvoiceRows?.reduce((acc, row) => {
												acc +=
													parseFloat(row.Total || 0) || parseFloat(row.Price || 0) * parseFloat(row.DeliveredQuantity || row.OrderedQuantity || 0);

												return acc;
											}, 0),
										},
									]}
									data={form.InvoiceRows || Array.from(Array(1)).map(() => ({}))}
									onChange={(data) => {
										this.state.form.InvoiceRows = data.reduce((acc, curr) => {
											if (Object.keys(curr).length) {
												acc.push({ ...curr });
											}
											return acc;
										}, []);
										this.setState({ form: this.state.form });
									}}
								/>
							</div>
							{form.Cancelled ? (
								<Banner title={this.props.t("fortnox.contract.statuses.shredded", "Ordern är makulerad")} />
							) : form.InvoiceReference && form.InvoiceReference != "" && form.InvoiceReference != "0" ? (
								<Banner
									status="success"
									title={this.props.t("fortnox.contract.statuses.shredded", "Faktura #{{invoiceReference}} skapad", {
										invoiceReference: form.InvoiceReference,
									})}
								/>
							) : (
								<PageActions
									primaryAction={
										form.DocumentNumber && {
											content: this.props.t("fortnox.common.create.invoice", "Skapa faktura"),
											onAction: () => this.setState({ showActionModal: true }),
										}
									}
								/>
							)}
						</div>
					) : (
						<div>
							{this.state.customer ? (
								<LegacyCard title={this.props.t("fortnox.contract.card.customer_info.title", "Kundinformation från fortnox")} sectioned>
									<p>
										{this.props.t("fortnox.contract.labels.customer_number", "Kundnummer")}: {this.state?.customer?.CustomerNumber || "-"}
									</p>
									<p>
										{this.props.t("fortnox.contract.labels.submit_invoice", "Leveranssätt faktura")}:{" "}
										{this?.props?.customer?.DefaultDeliveryTypes?.Invoice || "-"}
									</p>
									<p>
										{this.props.t("fortnox.contract.labels.invoice_template", "Fakturamall")}:{" "}
										{this?.props?.customer?.DefaultDeliveryTypes?.Invoice != "DEFAULTTEMPLATE"
											? this?.state?.customer?.DefaultTemplates?.Invoice
											: "Standard"}
									</p>
								</LegacyCard>
							) : null}
							<FortnoxResourceListWithPagination
								ref={(ref) => {
									this.resourceList = ref;
								}}
								resource="Contracts"
								customer={this.props?.customer}
								integration={this.state.integration}
								renderItem={(contract) => (
									<ResourceItem key={contract.DocumentNumber} onClick={this.viewContract.bind(this, contract)}>
										<h3>
											#{contract.DocumentNumber} - {contract.OrderDate}
										</h3>
										<Money amount={contract.Total} currency={contract.Currency} />
										{contract.Cancelled ? <Badge>{this.props.t("fortnox.statuses.shredded", "Makulerad")}</Badge> : null}
										{contract.InvoiceReference && contract.InvoiceReference != "" && contract.InvoiceReference != "0" ? (
											<Badge status="success">
												{this.props.t("fortnox.invoice.statuses.sent", "Faktura #{{invoiceReference}} skickad", {
													invoiceReference: contract.InvoiceReference,
												})}
											</Badge>
										) : null}
									</ResourceItem>
								)}
							/>
						</div>
					)}
				</Page>
				<Modal
					open={this.state.showActionModal}
					title="Bekräftelse"
					primaryAction={{ content: "Ja", onAction: this.convertContract.bind(this) }}
					secondaryActions={[
						{
							content: "Nej",
							onAction: () => this.setState({ showActionModal: false }),
						},
					]}
					onClose={() => this.setState({ showActionModal: false })}
					loading={this.state.actionLoading}
				>
					<BringModalToFront />
					<Modal.Section>
						<p>Är du säker på att du vill skapa en faktura ifrån denna offert?</p>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxContractView);
