import React from "react";

const SendIcon = ({ color = "#5AC57D" }) => (
	<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.697266 9.11156L7.53727 11.7616L10.1773 18.6016H11.1573L18.6973 0.601562L0.697266 8.13156V9.11156ZM14.9673 4.33156L10.7273 14.4616L9.40727 11.0416L9.08727 10.2116L8.26727 9.89156L4.83727 8.56156L14.9673 4.33156V4.33156Z"
			fill={color}
		/>
	</svg>
);

export default SendIcon;
