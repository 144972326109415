export const parseQuery = (queryString: string) => {
	const query = new Map();
	const params = new URLSearchParams(queryString);
	const regex = /\[\d+\]$/;

	params.forEach((value, key) => {
		const keyIsPartOfArray = regex.test(key);
		const modifiedKey = keyIsPartOfArray ? key.replace(regex, "") : key;

		if (keyIsPartOfArray) {
			if (query.has(modifiedKey)) {
				const existingValue = query.get(modifiedKey);

				if (Array.isArray(existingValue)) {
					if (!existingValue.includes(value)) {
						existingValue.push(value);
						return;
					}
				}

				query.set(modifiedKey, [existingValue, value]);
				return;
			}

			query.set(modifiedKey, [value]);
			return;
		}

		query.set(modifiedKey, value);
	});

	return Object.fromEntries(query);
};
