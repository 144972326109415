import { Icon, TextField, Tooltip } from "@shopify/polaris";
import React, { Component, useEffect } from "react";
import debounce from "lodash/debounce";
import axios from "axios";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../../API";
import { checkEmailValidity } from "../../../../Utilities";
import MemberAvatar from "../MemberAvatar";
import { store } from "../../../../store";
import CalendarHelper from "./CalendarHelper";
import Toast from "src/js/components/Toast";

class AttendeesInput extends Component {
	constructor(props) {
		super(props);
		this.state = { q: "", selectedIndex: 0 };

		this.onSearch = debounce(this.fetch.bind(this), 100, {
			leading: true,
			trailing: true,
			maxWait: 500,
		});
		this.ref = React.createRef();
		this.listRef = React.createRef();
		this.onKeyDown = this.handleKeyDown.bind(this);
	}

	componentDidMount() {
		if (this.ref.current) {
			const input = this.ref.current.querySelector("input");
			if (input) input.addEventListener("keydown", this.onKeyDown);
		}
	}

	componentWillUnmount() {
		if (this.ref.current) {
			const input = this.ref.current.querySelector("input");
			if (input) input.removeEventListener("keydown", this.onKeyDown);
		}
	}

	addAttendee(contact) {
		if (!this.props.attendees) this.props.attendees = [];

		if (!this.props.attendees.find((attendee) => attendee.email === contact.email)) {
			this.props.attendees.push(Object.assign(contact, { type: this.props.required && "required" }));
			this.props.onChange(this.props.attendees);
		}
		this.setState({ dropdownOpen: false, q: "" });
	}

	removeAttendee(email) {
		this.props.onChange(
			this.props.attendees.filter((attendee) => {
				const attendeeEmail = attendee?.email || (typeof attendee === "string" ? attendee : "");
				return attendeeEmail !== email;
			})
		);
		const input = this.ref.current && this.ref.current.querySelector("input");
		if (input) input.focus();
	}

	removeLatestAttendee() {
		this.props.attendees.pop();
		this.props.onChange(this.props.attendees);
	}

	handleKeyDown(e) {
		const list = this.listRef.current;
		if (e.key === "Enter") {
			const selected = list.querySelector(".selected");
			const data = selected ? { name: selected.dataset.name, email: selected.dataset.email } : { name: "", email: this.state.q };

			this.addAttendee(data);
		}

		if (e.key === "Backspace" && !this.state.q) {
			this.removeLatestAttendee();
		}

		if (e.key == "ArrowDown" && list) {
			e.preventDefault();
			this.setState(
				(c) => ({
					selectedIndex: Math.min(c.selectedIndex + 1, this.getItems().length - 1),
				}),
				() => {
					const next = list.querySelector(".selected");
					if (next)
						next.scrollIntoView({
							behavior: "smooth",
							block: "nearest",
							inline: "start",
						});
				}
			);
		}
		if (e.key == "ArrowUp" && list) {
			e.preventDefault();
			this.setState(
				(c) => ({ selectedIndex: Math.max(c.selectedIndex - 1, 0) }),
				() => {
					const next = list.querySelector(".selected");
					if (next)
						next.scrollIntoView({
							behavior: "smooth",
							block: "nearest",
							inline: "start",
						});
				}
			);
		}
	}

	fetch() {
		this.setState({ dropdownOpen: true });
		API.get("/api/all_contacts.json", {
			params: { q: this.state.q, ...({} || {}) },
		})
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					Toast.error(result.data.error);
					return;
				}

				this.setState({
					selectedIndex: 0,
					result: result.data.contacts,
					dropdownOpen: document.activeElement === (this.ref.current && this.ref.current.querySelector("input")),
				});
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				console.error("error:", error);
				Toast.error(error);
			});
	}

	onChange(q) {
		this.setState({ q, dropdownOpen: true });
		this.onSearch();
	}

	getItems() {
		const filterRegex = new RegExp(this.state.q && this.state.q.trimStart(), "i");

		return (this.state.result || []).filter((contact) => {
			const name = contact?.name || (typeof contact === "string" ? contact : "");
			const email = contact?.email || (typeof contact === "string" ? contact : "");

			return !this.state.q || filterRegex.test(name) || filterRegex.test(email);
		});
	}

	render() {
		// if (this.state.loading && !result.find((i) => i.loading)) result.unshift({ content: <Spinner size="small" />, loading: true });
		const items = this.getItems();
		const organizer = CalendarHelper.getOrganizer(this.props.form);

		return (
			<div
				className="attendees_list"
				style={Object.assign(
					{
						display: "flex",
						flexWrap: "wrap",
						gap: "0.3125rem",
						paddingTop: "0.6250rem",
					},
					this.props.style || {}
				)}
			>
				{this.props.attendees &&
					this.props.attendees
						?.filter(
							(attendee) =>
								attendee &&
								(this.props.required ? attendee.type === "required" || !attendee.optional : attendee.type !== "required" || attendee.optional) &&
								(!organizer || organizer.email !== attendee.email)
						)
						.map((contact, index) => {
							const name = contact?.name || (typeof contact === "string" ? contact : "");
							const email = contact?.email || (typeof contact === "string" ? contact : "");

							const validEmail = checkEmailValidity(email);
							const { response } = CalendarHelper.getAttendeesStatus(this.props.form, contact) || {};

							return (
								<Tooltip
									key={index}
									content={
										response ? (
											<span style={{ fontSize: 10 }}>
												{(name || email || typeof contact === "string" ? contact : "")?.capitalize?.()} har{" "}
												{CalendarHelper.getInlineStatusLabel(response)}
											</span>
										) : (
											<span style={{ fontSize: 10 }}>{(name || email).capitalize()}</span>
										)
									}
								>
									<span key={index} className={`attendee${validEmail ? "" : " invalid"} ${response}`}>
										{email || name}
										<span className="remove_btn" onClick={this.removeAttendee.bind(this, email)}>
											<Icon source={MobileCancelMajor} />
										</span>
									</span>
								</Tooltip>
							);
						})}
				<div ref={this.ref} style={{ position: "relative", flex: 1 }}>
					<TextField
						onKeyDown={this.onKeyDown.bind(this)}
						onChange={this.onChange.bind(this)}
						value={this.state.q}
						onBlur={() => {
							// this.setState({ dropdownOpen: false, resuslt: [] });
							this.onSearch.cancel();
						}}
						onFocus={() => {
							const selected = this.listRef.current && this.listRef.current.querySelector(".selected");
							if (selected) selected.classList.remove(".selected");
							if (this.state.q) this.fetch();
						}}
						autoComplete="off"
					/>
					{this.state.dropdownOpen && (
						<List
							style={this.props.required ? { zIndex: 99999 } : {}}
							ref={this.listRef}
							wrapperRef={this.ref}
							onClose={() => {
								this.setState({ dropdownOpen: false });
							}}
						>
							{items.map((contact, index) => {
								const name = contact?.name || (typeof contact === "string" ? contact : "");
								const email = contact?.email || (typeof contact === "string" ? contact : "");
								const found = store.getState().users.find((user) => user.email === email);

								return (
									<div
										key={index}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											const data = { name, email };

											if ("is_company" in contact) {
												data.contact_id = contact.id;
											} else {
												data.user_id = contact.id;
											}

											this.addAttendee(data);
										}}
										className={`item${this.state.selectedIndex === index ? " selected" : ""}`}
										data-name={name}
										data-email={email}
									>
										<MemberAvatar user={found || { name, email }} />
										<div>
											<p className="name">{name}</p>
											<p className="email">{email}</p>
										</div>
									</div>
								);
							})}
						</List>
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation(["calendar", "common"], { withRef: true })(AttendeesInput);

const List = React.forwardRef(({ children, onClose, wrapperRef, style }, ref) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			// 	event.preventDefault();
			// 	event.stopPropagation();

			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) onClose();
		};

		document.addEventListener("mouseup", handleClickOutside);
		return () => document.removeEventListener("mouseup", handleClickOutside);
	}, [wrapperRef, onClose]);

	return (
		<div ref={ref} className="list" style={style}>
			{children}
		</div>
	);
});
