import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { SocketContext } from "src/js/SocketProvider";
import { store } from "src/js/store";
import debugSocket from "src/js/Utilities/debugSocket";
import Toast from "../Toast";

const ProgressJobContext = React.createContext({
	progresJobs: [],
	refetch: () => {},
	history: null,
	open: false,
	isFetching: false,
	handleToggle: () => {},
	handleClose: () => {},
	removeProgressJob: (id: any) => {},
});

export const ProgressJobProvider = ({ children, history }) => {
	const { socket } = useContext(SocketContext);
	const queryClient = useQueryClient(); // Use the query client
	const [open, setOpen] = useState(false);

	const fetchProgressJobs = async (): Promise<ProgressJobType[]> => {
		const { data } = await API.get("/api/progress_jobs.json");

		return data?.progress_jobs;
	};

	const {
		data: progresJobs,
		refetch,
		isFetching,
	} = useQuery({
		queryKey: ["progress_jobs"],
		queryFn: fetchProgressJobs,
		initialData: [],
	});

	const removeProgressJob = useCallback(
		async (id) => {
			try {
				await API.delete(`/api/progress_jobs/${id}.json`);

				Toast.success("Tog bort jobb", { autoClose: 1000 });
				refetch();
			} catch (e) {
				Toast.error(e);
			}
		},
		[refetch]
	);

	useEffect(() => {
		const handleProgressJobUpdate = (updatedProgressJob) => {
			debugSocket("progress_job", updatedProgressJob);

			queryClient.setQueryData(["progress_jobs"], (oldQueryData: any) => {
				const jobIndex = oldQueryData.findIndex((job) => job.id === updatedProgressJob.id);

				if (jobIndex === -1) {
					if (updatedProgressJob.user?.id == store.getState().user.id) {
						setOpen(true);
					}
					return [...oldQueryData, { ...updatedProgressJob, unread: true }];
				}

				const updatedData = [...oldQueryData];
				updatedData[jobIndex] = {
					...updatedData[jobIndex],
					...updatedProgressJob,
				};
				return [...updatedData];
			});
		};

		socket?.on("progress_job", handleProgressJobUpdate);

		return () => socket?.off("progress_job", handleProgressJobUpdate);
	}, [socket, queryClient]);

	const handleToggle = useCallback(() => {
		setOpen((c) => {
			if (!c) refetch();
			return !c;
		});
	}, [refetch]);

	const handleClose = useCallback(() => setOpen(false), []);

	const value = useMemo(() => {
		return { progresJobs, refetch, history, open, handleToggle, handleClose, isFetching, removeProgressJob };
	}, [progresJobs, refetch, history, open, handleToggle, handleClose, isFetching, removeProgressJob]);

	return <ProgressJobContext.Provider value={value}>{children}</ProgressJobContext.Provider>;
};

export default ProgressJobContext;
