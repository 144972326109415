import React, { Component } from "react";
import { Button, Tooltip } from "@shopify/polaris";
import { FormsMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import AddVariableContent from "./AddVariableContent";
import Popover from "src/js/components/Popover";

class AddVariable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onFocus() {
		this.setState({ focused: true });
	}

	onBlur() {
		this.setState({ focused: false });
	}

	onOpen() {
		this.setState({ open: true });
	}

	onToggle() {
		this.setState((c) => ({ open: !c.open }));
	}

	onClose(force) {
		if (!this.state.focused || force) {
			this.setState({ open: false });
		}
	}

	addVariable(v, type) {
		this.props.addVariable(v, type);
		this.onClose(true);
	}

	render() {
		return (
			<Popover
				activator={
					<Tooltip
						content={
							this.props.element
								? this.props.t("pdf.editor.actions.variable.update", "Uppdatera dynamisk text")
								: this.props.t("pdf.editor.actions.variable.add", "Lägg till dynamisk text")
						}
					>
						<Button onClick={this.onToggle.bind(this)} icon={FormsMajor} />
					</Tooltip>
				}
				active={this.state.open}
				onClose={this.onClose.bind(this)}
			>
				<AddVariableContent
					element={this.props.element}
					focused={this.state.focused}
					onOpen={this.onOpen.bind(this)}
					onClose={this.onClose.bind(this)}
					addVariable={this.addVariable.bind(this)}
					onFocus={this.onFocus.bind(this)}
					onBlur={this.onBlur.bind(this)}
				/>
			</Popover>
		);
	}
}

export default withTranslation(["pdf", "common"], { withRef: true })(AddVariable);
