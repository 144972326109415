import { Button, ButtonGroup, Checkbox, FormLayout, Label, Labelled, Layout } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Select from "src/js/components/select/Select";
import getTermsOfPaymentOptions from "../Utilities/getTermsOfPaymentOptions";

type FortnoxContractInfoProps = {
	contract: Partial<FortnoxContractType>;
	onChange: (field: FortnoxContractTypeMutableKeys) => (value: string | boolean) => void;
	isSaving?: boolean;
	disabled?: boolean;
} & WithTranslation;

const FortnoxContractInfo = ({ contract, onChange, t, isSaving, disabled: propsDisabled }: FortnoxContractInfoProps) => {
	const disabled = isSaving || propsDisabled;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					<Labelled id="Active" label={t("fortnox.fields.Active.label", "Aktiv")}>
						<ButtonGroup fullWidth segmented>
							<Button primary={contract.Active} pressed={contract.Active} onClick={() => onChange("Active")(true)} disabled={disabled}>
								{t("fortnox.fields.Active.options.true", "Aktiv") as string}
							</Button>
							<Button primary={!contract.Active} onClick={() => onChange("Active")(false)} disabled={disabled}>
								{t("fortnox.fields.Active.options.false", "Ej aktiv") as string}
							</Button>
						</ButtonGroup>
					</Labelled>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.ContractDate.label", "Avtalsdatum")}
						value={contract.ContractDate}
						onChange={onChange("ContractDate")}
						type="date"
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.PeriodStart.label", "Periodstart")}
						value={contract.PeriodStart}
						onChange={onChange("PeriodStart")}
						type="date"
					/>

					<TextField
						disabled
						label={t("fortnox.fields.PeriodEnd.label", "Periodslut")}
						value={contract.PeriodEnd}
						onChange={onChange("PeriodEnd")}
						type="date"
					/>
					<Label id="Continuous">{t("fortnox.fields.Continuous.label", "Löpande")}</Label>
					<ButtonGroup fullWidth segmented>
						<Button primary={contract.Continuous} onClick={() => onChange("Continuous")(true)} disabled={disabled}>
							{t("common.yes", "Ja") as string}
						</Button>
						<Button primary={!contract.Continuous} onClick={() => onChange("Continuous")(false)} disabled={disabled}>
							{t("common.no", "Nej") as string}
						</Button>
					</ButtonGroup>
					<TextField
						disabled={disabled || contract.Continuous}
						placeholder="Månader.."
						label={t("fortnox.fields.ContractLength.label", "Avtalslängd (månader)")}
						value={contract.ContractLength ? String(contract.ContractLength || 0) : undefined}
						onChange={onChange("ContractLength")}
						pattern="[0-9]*"
					/>
					<TextField
						disabled={disabled}
						placeholder="Månader.."
						label={t("fortnox.fields.InvoiceInterval.label", "Fakturaintervall (månader)")}
						value={contract.InvoiceInterval ? String(contract.InvoiceInterval || 0) : undefined}
						onChange={onChange("InvoiceInterval")}
						pattern="[0-9]*"
						error={
							!contract.Continuous && contract.ContractLength && contract.InvoiceInterval && contract.InvoiceInterval > contract.ContractLength
								? "Fakturainvervall kan inte vara större än avtalslängd"
								: undefined
						}
					/>
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<Select
						label={t("fortnox.fields.TermsOfPayment.label", "Betalningsvillkor")}
						options={getTermsOfPaymentOptions()}
						value={contract.TermsOfPayment}
						onChange={onChange("TermsOfPayment")}
					/>
					<FormLayout>
						<Labelled id="VatIncluded" label={t("fortnox.fields.VatIncluded.label", "Priser inkl. moms")}>
							<Checkbox
								label={t("fortnox.fields.VatIncluded.label2", "Priser inkl. moms")}
								checked={contract.VatIncluded}
								onChange={onChange("VatIncluded")}
							/>
						</Labelled>
					</FormLayout>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourReference.label", "Er referens")}
						value={contract.YourReference}
						onChange={onChange("YourReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.OurReference.label", "Vår referens")}
						value={contract.OurReference}
						onChange={onChange("OurReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourOrderNumber.label", "Ert ordernummer")}
						value={contract.YourOrderNumber}
						onChange={onChange("YourOrderNumber")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.Comments.label", "Kommentarer")}
						value={contract.Comments}
						onChange={onChange("Comments")}
						multiline={3}
					/>
					{/* <TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryAddress1.label", "Leveransadress")}
						value={contract.DeliveryAddress1}
						onChange={onChange("DeliveryAddress1")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryAddress2.label", "Alternativ leveransadress")}
						value={contract.DeliveryAddress2}
						onChange={onChange("DeliveryAddress2")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryZipCode.label", "Postnummer")}
						value={contract.DeliveryZipCode}
						onChange={onChange("DeliveryZipCode")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryCity.label", "Ort")}
						value={contract.DeliveryCity}
						onChange={onChange("DeliveryCity")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryCountry.label", "Land")}
						value={contract.DeliveryCountry}
						onChange={onChange("DeliveryCountry")}
					/> */}
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxContractInfo);
