// eslint-disable-next-line max-classes-per-file
import { TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Modal from "src/js/components/modal";

class NameModal extends Component {
	constructor(props) {
		super(props);
		this.state = { name: props.name || "" };
	}

	async onSave() {
		return this.props.onSave(this.state.name);
	}

	render() {
		return (
			<Modal
				title={this.props.t("pdf.modals.name.title", "Ange namn för pdf")}
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					disabled: !this.state.name,
					loading: this.props.saving,
					onAction: this.onSave.bind(this),
				}}
			>
				<Content name={this.state.name} onChange={(name) => this.setState({ name })} />
			</Modal>
		);
	}
}

class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		$(".pdf-name__modal").closest("[data-portal-id]").addClass("modal-top");
	}

	render() {
		const { onChange, name } = this.props;
		return (
			<div className="pdf-name__modal">
				<Modal.Section>
					<TextField value={name} onChange={onChange} autoFocus />
				</Modal.Section>
			</div>
		);
	}
}

export default withTranslation(["pdf", "common"], { withRef: true })(NameModal);
