import React, { Component } from "react";
import { LegacyCard, LegacyStack, Text, Avatar } from "@shopify/polaris";
import moment from "moment";
import nl2br from "react-nl2br";
import MemberAvatar from "./MemberAvatar";

class RowActivity extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<LegacyCard sectioned>
				<LegacyStack wrap={false}>
					{this.props.activity.user ? <MemberAvatar user={this.props.activity.user} /> : <Avatar customer size="small" />}
					<LegacyStack.Item fill>
						<LegacyStack vertical spacing="none">
							<LegacyStack distribution="fill">
								<Text variant="headingMd" as="h2">
									{this.props.activity.user ? this.props.activity.user.name : "System"}
								</Text>
								<Text variation="subdued">{moment(this.props.activity.created_at).locale("sv").calendar()}</Text>
							</LegacyStack>
							<div style={{ marginTop: 15 }}>{nl2br(this.props.activity.title)}</div>
						</LegacyStack>
					</LegacyStack.Item>
				</LegacyStack>
			</LegacyCard>
		);
	}
}
export default RowActivity;
