const getSumPerPeriod = (targetConnections, property) => {
	const result = targetConnections?.reduce((acc, targetConnection) => {
		targetConnection.period_values.forEach((period, index) => {
			if (acc[index] === undefined) {
				acc[index] = 0;
			}
			acc[index] += period[property] || 0;
		});
		return acc;
	}, []);

	return result;
};
export default getSumPerPeriod;
