import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { currencyFormatter } from "src/js/Utilities";
import { WithTranslation, withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import { getArticlesFilters } from "../../Utilities";
import FortnoxContactResourceList from "../../FortnoxContactResourceList";
import Page from "src/js/components/page";

type FortnoxArticlesListProps = {
	contact?: ContactType;
	history: any;
	setCount?: any;
} & WithTranslation;

const FortnoxArticlesList = ({ contact, history, t, setCount }: FortnoxArticlesListProps) => {
	const renderItem = useCallback(
		(item: FortnoxArticleType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/fortnox/articles/${item.ArticleNumber}`, {
							data: item,
						});
					}}
					title={
						<>
							{item.Description}
							{item.Active ? (
								<Badge color={Colors.green}>{t("common.active", "Aktiv")}</Badge>
							) : (
								<Badge color={Colors.red}>{t("common.inactive", "Inaktiv")}</Badge>
							)}
							{item.Expired && <Badge color={Colors.red}>{t("common.expired", "Utgått")}</Badge>}
						</>
					}
					leftColumn={[
						{
							label: t("fortnox.orders.fields.ArticleNumber.label", "Artikelnummer"),
							value: item.ArticleNumber,
						},
						{
							label: t("fortnox.orders.fields.SalesPrice.label", "Utpris Prislista A"),
							value: currencyFormatter({ value: item.SalesPrice, removeCurrencySymbol: true }),
						},
						{
							label: t("fortnox.orders.fields.PurchasePrice.label", "Kalkylkostnad"),
							value: currencyFormatter({ value: item.PurchasePrice, removeCurrencySymbol: true }),
						},
						{
							label: t("fortnox.orders.fields.SupplierName.label", "Leverantör"),
							value: `${item.SupplierName} (${item.SupplierNumber})`,
							enabled: !!item.SupplierName,
						},
					]}
					rightColumn={[
						{
							label: t("fortnox.orders.fields.StockGoods.label", "Lagervara"),
							value: item.StockGoods ? t("common.yes", "Ja") : t("common.no", "Nej"),
						},
						{
							label: t("fortnox.orders.fields.QuantityInStock.label", "Antal i lager"),
							value: item.QuantityInStock,
						},
						{
							label: t("fortnox.orders.fields.DisposableQuantity.label", "Disponibelt"),
							value: item.DisposableQuantity,
						},
						{
							label: t("fortnox.orders.fields.ReservedQuantity.label", "Reserverat"),
							value: item.ReservedQuantity,
						},
					]}
				/>
			);
		},
		[history, t]
	);
	const filters = [
		{
			key: "articlenumber",
			label: t("fortnox.filters.articlenumber.label", "Artikelnummer"),
			type: "textField",
			shortcut: true,
		},
		{
			key: "description",
			label: t("fortnox.filters.description.label", "Beskrivning"),
			type: "textField",
			shortcut: true,
		},
		{
			key: "filter",
			label: t("fortnox.filters.status.label", "Status"),
			type: "select",
			options: getArticlesFilters(),
			shortcut: true,
		},
		{
			key: "ean",
			label: t("fortnox.filters.ean.label", "EAN"),
			type: "checkbox",
		},
		{
			key: "suppliernumber",
			label: t("fortnox.filters.suppliernumber.label", "Leverantörsnummer"),
			type: "textField",
		},
		{
			key: "manufacturer",
			label: t("fortnox.filters.manufacturer.label", "Tillverkare"),
			type: "textField",
		},
		{
			key: "manufacturerarticlenumber",
			label: t("fortnox.filters.manufacturerarticlenumber.label", "Tillverkarens artikelnummer"),
			type: "textField",
		},
		{
			key: "webshop",
			label: t("fortnox.filters.webshop.label", "Webshop"),
			type: "textField",
		},
		// {
		// 	key: "sent",
		// 	label: t("fortnox.filters.sent.label", "Skickad"),
		// 	type: "checkbox",
		// 	options: [
		// 		{
		// 			label: t("common.yes", "Ja"),
		// 			value: true,
		// 		},
		// 		{
		// 			label: t("common.no", "Nej"),
		// 			value: false,
		// 		},
		// 	],
		// },
		// {
		// 	key: "todate",
		// 	secondKey: "fromdate",
		// 	label: t("fortnox.filters.fromdate_todate.label", "Till och från datum"),
		// 	type: "date",
		// 	range: true,
		// },
		{
			key: "lastmodified",
			label: t("fortnox.filters.lastmodified.label", "Senast ändrad"),
			type: "date",
		},
	];
	return (
		<Page
			title={t("fortnox.articles.title", "Artiklar") as string}
			history={history}
			// primaryAction={{
			// 	content: t("fortnox.articles.new_article", "Ny artikel"),
			// 	url: "/admin/fortnox/articles/create",
			// }}
		>
			<FortnoxContactResourceList
				filters={filters}
				resource="Articles"
				resourceName={{
					singular: "artikel",
					plural: "artiklar",
				}}
				renderItem={renderItem}
			/>
		</Page>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxArticlesList);
