import { Button, Icon } from "@shopify/polaris";
import { NoteMajor, ImportMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import IntegrationModal from "../../components/IntegrationModal";
import ImportModal from "../Workspaces/components/ImportModal";
import Modal from "src/js/components/modal";

class ChooseImportChooce extends Component {
	constructor(props) {
		super(props);
		this.state = { page: 0 };
	}

	onClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	render() {
		const content = (() => {
			switch (this.state.page) {
				case 1:
					return {
						title: this.props.t("announcements.action.import_excel", "Importera ifrån excel"),
						content: (
							<ImportModal
								noPopup
								open={this.props.open}
								type="company"
								onComplete={this.onClose.bind(this)}
								onClose={() => this.setState({ page: 0 })}
							/>
						),
					};
				case 2:
					return {
						title: this.props.t("announcements.action.import_extern", "Importera ifrån extern service"),
						content: (
							<IntegrationModal
								noPopup
								open={this.props.open}
								category="accounting"
								onComplete={this.onClose.bind(this)}
								onCreate={(type) => {}}
								onClose={() => this.setState({ page: 0 })}
							/>
						),
					};

				case 0:
				default: {
					const btn = (title, page, icon) => (
						<Button onClick={() => this.setState({ page })}>
							<Icon source={icon} />
							{title}
						</Button>
					);
					return {
						title: this.props.t("announcements.import.chooice", "Välj import sätt"),
						content: (
							<div className="import-custom-register-choice">
								{btn("Excel", 1, NoteMajor)}
								{btn("Extern", 2, ImportMinor)}
							</div>
						),
					};
				}
			}
		})();

		return (
			<Modal
				open={this.props.open}
				onClose={this.onClose.bind(this)}
				title={content.title || this.props.t("announcements.import.chooice", "Välj import sätt")}
			>
				<Modal.Section>{content.content}</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["announcements", "common"], { withRef: true })(ChooseImportChooce);
