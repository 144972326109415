import React from "react";

const RoadSignIcon = ({ color = "#ffffff" }) => (
	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.20866 0.67044C8.6589 0.120686 7.69134 0.113356 7.13425 0.67044L0.537206 7.26749C-0.0345375 7.83923 -0.0345375 8.76282 0.537206 9.33456L7.13425 15.9316C7.42012 16.2175 7.79396 16.3567 8.16779 16.3567C8.54162 16.3567 8.91545 16.2175 9.20133 15.9316L15.791 9.34189C16.3628 8.78481 16.3701 7.85389 15.8057 7.27482L9.20866 0.67044ZM8.17512 14.8981L1.57807 8.30102L8.17512 1.70398L14.7722 8.30102L8.17512 14.8981ZM5.23577 7.57535V10.5074H6.70178V8.30835H9.6338V10.1409L12.1993 7.57535L9.6338 5.00983V6.84234H5.96877C5.56562 6.84234 5.23577 7.1722 5.23577 7.57535Z"
			fill={color}
		/>
	</svg>
);

export default RoadSignIcon;
