import React, { Component } from "react";
import { generateId } from "../Utilities";

class OrderRowInput extends Component {
	constructor(props) {
		super(props);
		this.id = generateId(40);
	}

	render() {
		const id = this.id;
		return [
			this.props.label && <label htmlFor={id}>{this.props.label}</label>,
			<input
				key={id}
				id={id}
				type="text"
				value={this.props.value}
				onChange={(e) => {
					this.props.onChange(e.target.value);
				}}
			/>,
		];
	}
}

export default OrderRowInput;
