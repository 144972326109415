import { Icon } from "@shopify/polaris";
import React from "react";
import NumberBadge from "../../../components/NumberBadge";
import Colors from "../../../Colors";
import { Wrapper, Count, Icon as TabIcon, Tab } from "./styles";
import { QuestionMarkMajor } from "@shopify/polaris-icons";

const ContactTabsBar = ({ tabs, activeTabId, onChange, children }) => {
	return (
		<Wrapper>
			{tabs.map((tab, index) => {
				const { title, icon, id, count } = tab;
				return (
					<Tab key={index} active={activeTabId === id} onClick={() => onChange(id)}>
						<div>
							<TabIcon>
								<div style={{ position: "relative" }}>
									<Count disabled={!count} style={{ visibility: !("count" in tab) ? "hidden" : "visible" }}>
										<NumberBadge color={count && count !== "-" ? Colors.green : "rgb(150,150,150)"}>{count || 0}</NumberBadge>
									</Count>
									{React.isValidElement(icon) ? icon : <Icon source={icon || QuestionMarkMajor} />}
								</div>
							</TabIcon>

							<h3>{title || "..."}</h3>
						</div>
					</Tab>
				);
			})}
			{children}
		</Wrapper>
	);
};
export default ContactTabsBar;
