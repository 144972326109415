import React, { Component } from "react";
import { Select, TextField, FormLayout, Spinner, ActionList, Button } from "@shopify/polaris";
import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Popover from "../../components/Popover.js";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

class WidgetCreator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: { type: "text", title: "", options: {} },
			saving: false,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ form: { type: "text", title: "", options: {} } });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });

		API.post(
			"/api" + (this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) + "/widgets.json",
			this.state.form,
			{
				params: {},
			}
		)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				Toast.success(this.props.t("widget.responses.created", "Skapade widget"));
				this.setState({ saving: false, showWidgetModal: false });
				this.props.onCreate(result.data.widget);
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	quickCreate(title, type) {
		this.setState({ active: false, form: { type, title, options: {} } }, this.saveForm.bind(this));
	}

	render() {
		return (
			<div style={{ textAlign: "center" }}>
				<Popover
					fixed
					active={this.state.active}
					activator={
						this.state.saving ? (
							<Spinner size="small" />
						) : (
							<Button
								onClick={() => {
									this.setState({ active: !this.state.active });
								}}
								outline
								icon={CirclePlusOutlineMinor}
							>
								{this.props.t("widget.actions.new", "Ny widget")}
							</Button>
						)
					}
					onClose={() => {
						this.setState({ active: false });
					}}
				>
					<ActionList
						items={[
							{
								content: this.props.t("widget.types.chart", "Diagram"),
								onAction: this.quickCreate.bind(this, "Diagram", "chart"),
							},
							{
								content: this.props.t("widget.types.iframe", "Iframe"),
								onAction: this.quickCreate.bind(this, "Iframe", "iframe"),
							},
							{
								content: this.props.t("widget.types.number", "Siffror"),
								onAction: this.quickCreate.bind(this, "Siffror", "number"),
							},
							{
								content: this.props.t("widget.types.list", "Lista"),
								onAction: this.quickCreate.bind(this, "Lista", "list"),
							},
							{
								content: this.props.t("widget.types.html", "HTML"),
								onAction: this.quickCreate.bind(this, "HTML", "html"),
							},
							{
								content: this.props.t("widget.types.summary", "Summering"),
								onAction: this.quickCreate.bind(this, "Summering", "summary"),
							},
						]}
					/>
				</Popover>

				<Modal
					open={this.state.showWidgetModal}
					onClose={() => {
						this.setState({ showWidgetModal: false });
					}}
					title={this.props.t("widget.modals.new.title", "Ny widget")}
					preventAutofocus
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showWidgetModal: false });
							},
						},
					]}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("widget.creator.fields.title.label", "Namn på widget")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							<Select
								label={this.props.t("widget.creator.fields.typ.label", "Typ")}
								options={[
									{
										value: "chart",
										label: this.props.t("widget.types.chart", "Diagram"),
									},
									{
										value: "iframe",
										label: this.props.t("widget.types.iframe", "Iframe"),
									},
									{
										value: "number",
										label: this.props.t("widget.types.number", "Siffror"),
									},
									{
										value: "list",
										label: this.props.t("widget.types.list", "Lista"),
									},
									{
										value: "html",
										label: this.props.t("widget.types.html", "HTML"),
									},
									{
										value: "summary",
										label: this.props.t("widget.types.summary", "Summering"),
									},
								]}
								value={this.state.form.type}
								onChange={this.updateForm.bind(this, "type")}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["widget", "common"], { withRef: true })(WidgetCreator);
