/* eslint-disable camelcase */
import { DeleteMajor } from "@shopify/polaris-icons";
import React, { useCallback, useMemo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectSearchField from "src/js/components/SelectSearchField";
import TextField from "src/js/components/TextField";
import styled from "styled-components";
import MyActionList from "../../Workspaces/components/MyActionList";
import moment from "moment";
import formatPriceListRow from "../formatPriceListRow";

type PriceListsRowProps = {
	pricelist?: ArticlePricelistType;
	columns: { header: string; handle: ArticlePricelistTypeMutableKeys; style?: React.CSSProperties }[];
	onChange: (articlePricelist: ArticlePricelistType | null) => void;
	disabled?: boolean;
	index?: number;
	usedPricelists?: ArticlePricelistType[];
} & WithTranslation;

const PriceListsRow = ({ pricelist, columns, onChange, disabled, index, t, usedPricelists }: PriceListsRowProps) => {
	const handleChange = useCallback(
		(field: ArticlePricelistTypeMutableKeys) => (value: string | boolean | PricelistType | null | number) => {
			const item = {
				...(pricelist || {
					pricelist: null,
					price: 0,
					tb1: 0,
					tb2: 0,
					price_in_cents: 0,
					tb1_in_cents: 0,
					tb2_in_cents: 0,
				}),
			} as ArticlePricelistType;

			item[field] = value as never;

			onChange(item);
		},
		[pricelist, onChange]
	);

	const handleFormatting = useCallback(() => {
		if (!pricelist) return;
		onChange(formatPriceListRow(pricelist));
	}, [pricelist, onChange]);

	const renderdColumns = useMemo(() => {
		const usedPricelistsIds = usedPricelists?.map((p) => p.pricelist_id || p.pricelist?.id) || [];

		return columns.map((column) => {
			if (column.handle === "pricelist") {
				return (
					<td key={column.handle}>
						<SelectSearchField
							filterResults={(data) => data.filter((item) => !usedPricelistsIds.includes(item.id))}
							required={!!pricelist}
							// error={pricelist && !pricelist[column.handle] && "123"}
							onClear={() => handleChange("pricelist")(null)}
							// disabled={disabled}
							resource="pricelists.json"
							onSelect={handleChange("pricelist")}
							idHandle="id"
							labelHandle="title"
							resourceHandle="pricelists"
							resourceName={{ plural: t("articles.plural", "artiklar"), singular: t("articles.singular", "artikel") }}
							value={pricelist?.pricelist}
							renderLabel={(item) => `${item?.title} - ${item?.description}`}
							renderValue={(item) => {
								return `${item?.title || ""}`;
							}}
							// suffix={
							// 	!disabled && (
							// 		<span style={{ cursor: "pointer" }}>
							// 			<Icon source={ChevronDownMinor} />
							// 		</span>
							// 	)
							// }
						/>
					</td>
				);
			}

			if (["price", "tb1", "tb2"].includes(column.handle)) {
				return (
					<td key={column.handle}>
						<TextField
							value={String(pricelist?.[column.handle] || "")}
							onChange={handleChange(column.handle)}
							// value={String(parseFloat(String(pricelist?.[column.handle])) / 100)}
							// onChange={(v) => handleChange(column.handle)(Number(v) * 100)}
						/>
					</td>
				);
			}

			if (["actions"].includes(column.handle)) {
				return (
					<td key={column.handle}>
						<MyActionList
							items={[
								{
									content: t("common.actions.remove", "Ta bort") as string,
									destructive: true,
									icon: DeleteMajor,
									onAction: () => index !== undefined && onChange(null),
								},
							]}
						/>
					</td>
				);
			}
			if (["updated_at"].includes(column.handle)) {
				return <td key={column.handle}>{pricelist?.[column.handle] && moment(pricelist[column.handle] as string).format("YYYY-MM-DD HH:mm")}</td>;
			}

			return <td key={column.handle}>{pricelist?.[column.handle]}</td>;
		});
	}, [
		columns,
		pricelist,
		// disabled,
		handleChange,
		index,
		onChange,
		t,
		usedPricelists,
	]);

	return <Wrapper onBlur={handleFormatting}>{renderdColumns}</Wrapper>;
};
export default withTranslation(["article", "common"])(PriceListsRow);

const Wrapper = styled.tr``;
