import React, { useContext, useState } from "react";
import SelectSearchField from "src/js/components/SelectSearchField";
import OrderContext from "../OrderContext";
import Modal from "src/js/components/modal";
import API from "src/js/API";
import { changeArticleOnRow } from "./OrderRow";
import Toast from "src/js/components/Toast";

const fetchArticle = async (articleId: number, contactId): Promise<ArticleType | null> => {
	try {
		const res = await API.get(`/api/articles/${articleId}.json`, {
			params: {
				contact_id: contactId,
			},
		});

		return res.data.article;
	} catch (e) {
		Toast.error(e);
		console.error("Error", e);

		return null;
	}
};

const SetCustomerCompoent = () => {
	const { form, setForm, disabled, t } = useContext(OrderContext);
	const [customerChangeModalIsOpen, setCustomerChangeModalIsOpen] = useState<{
		open: boolean;
		rowsWithOUTPricelist?: OrderRowType[];
		rowsWithPricelist?: OrderRowType[];
		newRows?: OrderRowType[];
		contact?: ContactType;
	}>({ open: false });

	const checkArticlesForNewCustomer = async (contact: ContactType) => {
		if (!form?.order_rows)
			return {
				newRows: [],
				rowsWithPricelist: [],
				rowsWithOUTPricelist: [],
			};

		const rowsWithIndex = form.order_rows?.map((row, index) => ({ ...row, index }));

		const rowsWithArticles = rowsWithIndex?.filter((row) => row.order_row_article);

		const rowsArticlePromises = rowsWithArticles.map((row) => {
			if (!row?.order_row_article?.id) return Promise.resolve({ row, article: null });

			return fetchArticle(row?.order_row_article?.article.id, contact.id).then((article) => ({
				...row,
				article,
			}));
		});

		const rowsWithPricelistInfo = await Promise.all(rowsArticlePromises);

		const newRows = rowsWithPricelistInfo.map((row) => changeArticleOnRow(row, row.article));

		const rowsWithPricelist = newRows.filter((row) => !!row.order_row_article?.article.filtered_article_pricelists?.length).map((row) => row);
		const rowsWithOUTPricelist = newRows.filter((row) => !row.order_row_article?.article.filtered_article_pricelists?.length).map((row) => row);

		return {
			newRows,
			rowsWithPricelist,
			rowsWithOUTPricelist,
		};
	};

	const setCustomer = async (contact: ContactType, replaceRows = false) => {
		const { rowsWithPricelist, rowsWithOUTPricelist, newRows } = await checkArticlesForNewCustomer(contact);

		if (rowsWithOUTPricelist.length && !replaceRows) {
			setCustomerChangeModalIsOpen({ open: true, rowsWithOUTPricelist, rowsWithPricelist, newRows, contact });
			return;
		}

		setForm((c: Partial<OrderType>) => {
			const newForm = { ...c };
			if (replaceRows) newForm.order_rows = rowsWithPricelist;

			if (!newForm.customer_fields) newForm.customer_fields = {};
			if (!newForm.delivery_fields) newForm.delivery_fields = {};

			newForm.customer_fields.customer = contact;
			newForm.customer_fields.name = contact.name;
			newForm.customer_fields.organisation_number = contact.orgnr;
			newForm.customer_fields.address = contact.address1;
			newForm.customer_fields.address2 = contact.address2;
			newForm.customer_fields.postal_code = contact.zip;
			newForm.customer_fields.city = contact.city;
			newForm.customer_fields.country = contact.country;
			newForm.customer_fields.phone = contact.phone;
			newForm.customer_fields.email = contact.email;

			newForm.delivery_fields.name = contact.name;
			newForm.delivery_fields.address = contact.address1;
			newForm.delivery_fields.address2 = contact.address2;
			newForm.delivery_fields.postal_code = contact.zip;
			newForm.delivery_fields.city = contact.city;
			newForm.delivery_fields.country = contact.country;

			return newForm;
		});

		setCustomerChangeModalIsOpen({ open: false });
		return true;
	};

	return (
		<>
			<SelectSearchField
				// prefix={isCheckingCustomerArticles ? <Spinner size={"small"} /> : undefined}
				required
				label={t("orders.customer.fields.customer.label", "Sök efter Kund") as string}
				value={form?.customer_fields?.customer}
				disabled={disabled}
				resourceName={{ singular: "Kund", plural: "Kunder" }}
				resource="contacts"
				resourceHandle="contacts"
				resourceURL="/api/contacts.json"
				labelHandle="name"
				idHandle="id"
				onSelect={setCustomer}
				renderValue={(v) => v?.name}
				renderLabel={(v) => v?.name}
				autoFocus={!form?.customer_fields?.customer}
			/>

			<Modal
				title={t("orders.customer.modal.title", "Byt kund")}
				open={!!customerChangeModalIsOpen?.open}
				onClose={() => setCustomerChangeModalIsOpen({ open: false })}
				sectioned
				primaryAction={{
					content: t("common.confirm", "Byt kund") as string,
					onAction: async () => {
						return await setCustomer(customerChangeModalIsOpen?.contact as ContactType, true);
					},
				}}
				secondaryActions={[
					{
						content: t("common.cancel", "Avbryt") as string,
						onAction: () => setCustomerChangeModalIsOpen({ open: false }),
					},
				]}
			>
				<p>
					Byter du kund kommer <b>{customerChangeModalIsOpen?.rowsWithOUTPricelist?.length}st</b> rader som saknar prislistor för nya kunden att tas
					bort.
					<p>Rad {customerChangeModalIsOpen?.rowsWithOUTPricelist?.map((r: any) => r.index + 1).join(",")}.</p>
				</p>

				<p>Vill du fortsätta?</p>
			</Modal>
		</>
	);
};
export default SetCustomerCompoent;
