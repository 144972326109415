import React, { Component } from "react";
// import AppsIcon from "../icons/AppsIcon";
import {
	AppsIcon,
	BagIcon,
	CalendarIcon,
	CheckmarkIcon,
	ClickIcon,
	ClockIcon,
	CogWheelIcon,
	CompanyIcon,
	DollarIcon,
	FavoriteBannerIcon,
	GraphIcon,
	HeartIcon,
	HomeIcon,
	InfoIcon,
	IntegrationsIcon,
	IntegrationsIcon2,
	LightningIcon,
	ListIcon,
	MarketingIcon,
	People2Icon,
	PeopleIcon,
	SadFaceIcon,
	SunIcon,
	SupportHeadIcon,
	SupportIcon,
	WarningIcon,
	SendIcon,
	ThumbsUpIcon,
	MailIcon,
} from "../icons";

class IconsOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="icons_test_overview_wrapper">
				<div>
					<AppsIcon />
					AppsIcon
				</div>
				<div>
					<BagIcon />
					BagIcon
				</div>
				<div>
					<CalendarIcon />
					CalendarIcon
				</div>
				<div>
					<CheckmarkIcon />
					CheckmarkIcon
				</div>
				<div>
					<ClickIcon />
					Click
				</div>
				<div>
					<ClockIcon />
					Clock
				</div>
				<div>
					<CogWheelIcon />
					CogWheelIcon
				</div>
				<div>
					<CompanyIcon />
					CompanyIcon
				</div>
				<div>
					<DollarIcon />
					DollarIcon
				</div>
				<div>
					<FavoriteBannerIcon />
					FavoriteBanner
				</div>
				<div>
					<GraphIcon />
					GraphIcon
				</div>
				<div>
					<HeartIcon />
					Heart
				</div>
				<div>
					<HomeIcon />
					Home
				</div>
				<div>
					<InfoIcon />
					Info
				</div>
				<div>
					<IntegrationsIcon />
					Integrations
				</div>
				<div>
					<IntegrationsIcon2 />
					IntegrationsIcon2
				</div>
				<div>
					<LightningIcon />
					Lightning
				</div>
				<div>
					<ListIcon />
					List
				</div>
				<div>
					<MarketingIcon />
					MarketingIcon
				</div>
				<div>
					<PeopleIcon />
					People
				</div>
				<div>
					<People2Icon />
					People2Icon
				</div>
				<div>
					<SadFaceIcon />
					SadFace
				</div>
				<div>
					<SunIcon />
					SunIcon
				</div>
				<div>
					<SupportHeadIcon />
					SupportHeadIcon
				</div>
				<div>
					<SupportIcon />
					SupportIcon
				</div>
				<div>
					<WarningIcon />
					WarningIcon
				</div>
				<div>
					<SendIcon />
					SendIcon
				</div>
				<div>
					<ThumbsUpIcon />
					ThumbsUpIcon
				</div>
				<div>
					<MailIcon />
					MailIcon
				</div>
			</div>
		);
	}
}
export default IconsOverview;
