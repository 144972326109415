import React from "react";

const NotContactedIcon = ({ color = "var(--textColor)" }) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19.425 12.6625C17.1932 11.8912 14.7665 11.8956 12.5375 12.675C12.3748 12.7316 12.2295 12.8292 12.1156 12.9584C12.0017 13.0876 11.9232 13.244 11.8875 13.4125L11.1625 17.1C11.1282 17.2636 11.0545 17.4163 10.9478 17.5448C10.841 17.6733 10.7045 17.7738 10.55 17.8375L4.63749 20.2C4.43637 20.2834 4.21336 20.2979 4.00312 20.2414C3.79288 20.1849 3.60722 20.0605 3.47499 19.8875C2.58182 18.7334 2.13989 17.2934 2.23192 15.8369C2.32396 14.3805 2.94365 13.0075 3.97499 11.975C7.16507 8.7874 11.4903 6.99683 16 6.99683C20.5097 6.99683 24.8349 8.7874 28.025 11.975C29.0563 13.0075 29.676 14.3805 29.7681 15.8369C29.8601 17.2934 29.4182 18.7334 28.525 19.8875C28.3928 20.0605 28.2071 20.1849 27.9969 20.2414C27.7866 20.2979 27.5636 20.2834 27.3625 20.2L21.45 17.8375C21.297 17.775 21.1614 17.6764 21.0548 17.5502C20.9481 17.4239 20.8736 17.2738 20.8375 17.1125L20.0625 13.4C20.0291 13.2324 19.9528 13.0764 19.8411 12.9471C19.7293 12.8179 19.586 12.7198 19.425 12.6625V12.6625Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M5 25H27" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export default NotContactedIcon;
