import { useState, useEffect } from "react";

//Returns the debounced value of the input value
//example: If you want to debounce the input value of a search bar, you can use this hook. The real input value is handled by the component, but the debounced value is used to make the API call.

function useDebounce<T>(value: T, delay: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export default useDebounce;
