import React from "react";

const PinIcon = ({ color = "var(--textColor)" }) => (
	<svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.94751 0.0957031C13.1475 0.0957031 16.9475 3.3157 16.9475 8.2957C16.9475 11.6157 14.2775 15.5457 8.94751 20.0957C3.61751 15.5457 0.94751 11.6157 0.94751 8.2957C0.94751 3.3157 4.74751 0.0957031 8.94751 0.0957031ZM14.9475 8.2957C14.9475 4.6657 12.2975 2.0957 8.94751 2.0957C5.59751 2.0957 2.94751 4.6657 2.94751 8.2957C2.94751 10.6357 4.89751 13.7357 8.94751 17.4357C12.9975 13.7357 14.9475 10.6357 14.9475 8.2957ZM8.94751 10.0957C7.84751 10.0957 6.94751 9.1957 6.94751 8.0957C6.94751 6.9957 7.84751 6.0957 8.94751 6.0957C10.0475 6.0957 10.9475 6.9957 10.9475 8.0957C10.9475 9.1957 10.0475 10.0957 8.94751 10.0957Z"
			fill={color}
		/>
	</svg>
);

export default PinIcon;
