import React from "react";

const StackingBarsIcon = ({ color = "var(--textColor)" }) => (
	<svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.14473 8.26005H30.9781V12.0934H4.14473V8.26005ZM0.311401 15.9267H27.1447V19.76H0.311401V15.9267ZM7.97807 0.593384H34.8114V4.42672H7.97807V0.593384Z"
			fill={color}
		/>
	</svg>
);

export default StackingBarsIcon;
