import React, { Component } from "react";
import { OptionList } from "@shopify/polaris";

class MemberRoles extends Component {
	constructor(props) {
		super(props);
		this.state = { selected: props.selected || [] };
		this.roles = props.roles || props.selected || [];
		this.rolesCanEdit = (() => {
			if (props.type === "board") return ["ROLE_BOARD_WRITE", "ROLE_BOARD_ADMIN"];
			if (props.type === "workspace") return ["ROLE_WORKSPACE_WRITE", "ROLE_WORKSPACE_ADMIN"];
			if (props.type === "dashboard") return ["ROLE_DASHBOARD_WRITE", "ROLE_DASHBOARD_ADMIN"];
			return [];
		})();
	}

	onChange(value) {
		this.setState({ selected: value });
		if (this.props.onChange) this.props.onChange(value);
	}

	formatLabel(label) {
		if (["ROLE_BOARD_ADMIN", "ROLE_WORKSPACE_ADMIN", "ROLE_DASHBOARD_ADMIN"].includes(label)) return "Admin";
		if (["ROLE_BOARD_WRITE", "ROLE_WORKSPACE_WRITE", "ROLE_DASHBOARD_WRITE"].includes(label)) return "Skrivrättigheter";
		const labelLowercase = label ? label.split("_").pop().toLowerCase() : "";
		return labelLowercase.charAt(0).toUpperCase() + labelLowercase.slice(1);
	}

	render() {
		return (
			<div style={{ padding: "10px 10px 10px 0" }}>
				<OptionList
					onChange={this.onChange.bind(this)}
					options={Array.from(new Set([].concat(this.roles).concat(this.rolesCanEdit)))
						.map((role) => ({
							value: role,
							label: this.formatLabel(role),
							disabled: !this.rolesCanEdit.includes(role),
						}))
						.sort((a, b) => b.disabled - a.disabled)}
					selected={this.state.selected}
					allowMultiple
				/>
			</div>
		);
	}
}

export default MemberRoles;
