import { Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalOptionBox from "src/js/components/ModalOptionBox";
import Modal from "src/js/components/modal";
import Sheet from "src/js/components/sheet";
import { BoardIcon, CalendarIcon } from "src/js/icons";
import { store } from "src/js/store";
import CalendarModalContent from "../Workspaces/components/CalendarModal/CalendarModalContent";
import TaskSheet from "./TaskSheet";

type OptionsType = {
	contact?: ContactType;

	[key: string]: any;
};

interface CalendarTaskModalProps extends WithTranslation {
	open: boolean;
	onClose?: () => void;
	onSuccess?: (row: BoardRowType) => any;
	options?: OptionsType;
	noModal?: boolean;
	row?: BoardRowType | null | undefined;
	title?: string;
	selected?: null | "task" | "calendar";

	disabled?: boolean;
	loading?: boolean;
	submitSuffix?: string;
	submitPrefix?: string;
}

const CalendarTaskModal = ({
	open: propsOpen,
	t,
	onClose,
	onSuccess,
	options = {},
	noModal,
	row: propsRow,
	title,
	selected: propsSelected,

	disabled,
	loading,
	submitSuffix,
	submitPrefix,
}: CalendarTaskModalProps) => {
	const [selected, setSelected] = useState<null | "task" | "calendar">(null);
	const [row, setRow] = useState<CalendarTaskModalProps["row"]>(propsRow || null);

	useEffect(() => {
		setRow(propsRow);
	}, [propsRow]);

	const handleCloseSheets = () => {
		setSelected(null);
		setRow(propsRow || null);

		if (propsSelected) {
			onClose?.();
		}
	};

	const handleSuccess = (row: BoardRowType) => {
		setSelected(null);
		onSuccess?.(row);
		setRow(propsRow || null);
	};

	const content = (
		<div className="boxes_wrapper">
			{[
				{
					label: t("contact.activities.choose_modal.options.task", "Uppgift"),
					type: "task",
					icon: <BoardIcon />,
					description: t("contact.activities.choose_modal.options.task_description", "Skapa en uppgift"),
				},
				{
					label: t("contact.activities.choose_modal.options.calendar", "Kalenderinlägg"),
					type: "calendar",
					icon: <CalendarIcon />,
					description: t("contact.activities.choose_modal.options.calendar_description", "Skapa ett kalenderinlägg"),
				},
			].map((item, index) => (
				<ModalOptionBox key={index} title={item.label} icon={item.icon} description={item.description}>
					<Button
						primary
						onClick={() => {
							setSelected(item.type as any);
						}}
						disabled={disabled}
						loading={loading}
					>
						Välj
					</Button>
				</ModalOptionBox>
			))}
		</div>
	);

	const seletedType = propsSelected || selected;

	return (
		<>
			{noModal ? (
				content
			) : (
				<Modal
					title={title === undefined ? t("contact.activities.choose_modal.title", "Välj typ av aktivitet") : title}
					open={propsOpen && !seletedType}
					onClose={() => {
						onClose?.();
						setRow(null);
						setSelected(null);
					}}
					secondaryActions={[
						{
							content: t("common.actions.close", "Stäng") as string,
							onAction: () => {
								onClose?.();
							},
						},
					]}
				>
					<Modal.Section>{content}</Modal.Section>
				</Modal>
			)}

			<TaskSheet
				submitSuffix={submitSuffix}
				submitPrefix={submitPrefix}
				open={seletedType === "task"}
				onComplete={handleSuccess}
				options={{
					contact: options?.contacts?.map((c) => c?.id || c) || [],
				}}
				optionsByTitle={{
					affär: options?.deal,
				}}
				onClose={handleCloseSheets}
				row={row}
			/>

			<Sheet open={seletedType === "calendar"} onClose={handleCloseSheets} title={t("calendar.actions.new", "Nytt kalenderinlägg")}>
				<CalendarModalContent
					submitSuffix={submitSuffix}
					submitPrefix={submitPrefix}
					attendees={options?.contacts?.filter((c) => c?.email) || []}
					contacts={options?.contacts || []}
					optionsByTitle={{
						affär: options?.deal,
					}}
					// date={this.state.date}
					board={store.getState().user.calendar_board}
					// updateCell={this.updateCell.bind(this, modalRow)}
					onClose={handleCloseSheets}
					onComplete={handleSuccess}
					row={row}
				/>
			</Sheet>
		</>
	);
};
export default withTranslation(["tasks", "calendar", "common"])(CalendarTaskModal);
