import moment from "moment";
import { store } from "src/js/store";

interface getDefaultFormProps {
	row?: DeadlineBoardRowType | BoardRowType | null;
	board: BoardType;
	date?: string;
	options?: Record<string, any>;
	optionsByTitle?: Record<string, any>;
}

const getDefaultForm = ({ row, board, date, options = {}, optionsByTitle = {} }: getDefaultFormProps) => {
	const columnValues = row?.column_values || {};

	const columnsByType: Record<string, BoardColumnType> = board?.columns.reduce((acc: Record<string, BoardColumnType>, column: BoardColumnType) => {
		if (!column?.connected_column_id && acc[column.type]) return acc;

		return { ...acc, [column.type?.toLowerCase()]: column };
	}, {});
	const columnsByTitle: Record<string, BoardColumnType> = board?.columns.reduce((acc: Record<string, BoardColumnType>, column: BoardColumnType) => {
		if (!column?.connected_column_id && acc[column.title]) return acc;

		return { ...acc, [column.title?.toLowerCase()]: column };
	}, {});

	if (options) {
		Object.entries(options).forEach(([key, value]) => {
			if (columnsByType?.[key] && value) {
				columnValues[columnsByType[key].id] = {
					column_id: columnsByType[key].id,
					row_id: row?.id || null,
					value,
				};
			}
		});
	}
	if (optionsByTitle) {
		Object.entries(optionsByTitle).forEach(([key, value]) => {
			if (columnsByTitle?.[key] && value) {
				columnValues[columnsByTitle[key].id] = {
					column_id: columnsByTitle[key].id,
					row_id: row?.id || null,
					value,
				};
			}
		});
	}

	if (columnsByType?.person && !columnValues[columnsByType.person.id]?.value) {
		columnValues[columnsByType.person.id] = {
			column_id: columnsByType.person.id,
			row_id: row?.id || null,
			value: [store.getState().user.id],
		};
	}

	if (columnsByType?.datetime && !columnValues[columnsByType.datetime.id]?.value) {
		columnValues[columnsByType.datetime.id] = {
			column_id: columnsByType.datetime.id,
			row_id: row?.id || null,
			value: {
				datetime: moment().format("YYYY-MM-DD"),
			},
		};
	}

	return {
		title: row?.title || "",
		board,
		board_id: board?.id,
		...(row || {}),
		group_id: null,
		column_values: columnValues,
	};
};

export default getDefaultForm;
