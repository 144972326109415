import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { Icon, Labelled } from "@shopify/polaris";
import { ListMinor, SelectMinor } from "@shopify/polaris-icons";
import Modal from "../modal/Modal";
import { findOptionByValue } from "src/js/views/Pdf/Editor/Table/getColumnOptions";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "../TextField";

type Option = {
	label: string;
	value: string;
	options?: Option[];

	type?: string;
};

type NestedOptionsListProps = {
	value: string;
	label?: string;
	options: Option[];
	onChange: (value: string) => void;
	closeOnSelectLast?: boolean;
} & WithTranslation;

const NestedOptionsList = ({ value = "", label, options, onChange, t, closeOnSelectLast = true }: NestedOptionsListProps) => {
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const handleToggle = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOptionSelect = (newValue) => {
		const selected = findOptionByValue(options, newValue);
		onChange(newValue);
		setSearch("");

		if (closeOnSelectLast && selected && !selected?.options?.length) {
			handleClose();
		}
	};

	const selected = findOptionByValue(options, value);

	const primaryAction = {
		content: t("common.actions.done", "Klar"),
		onAction: handleClose,
	};

	const secondaryActions = [
		{
			content: t("common.actions.set_null", "Rensa värde"),
			onAction: () => {
				onChange("");
			},
		},
		{
			content: t("common.actions.cancel", "Avbryt"),
			onAction: handleClose,
		},
	];

	return (
		<>
			<div>
				<Labelled id="slecetoptios" label={label} labelHidden={!label}>
					<SelectActivator onClick={handleToggle}>
						{selected?.label || "Välj"}

						<Icon source={SelectMinor} />
					</SelectActivator>
				</Labelled>
			</div>
			<Modal
				large={(value.split(".").length > 2 || value?.length > 30) && selected?.options?.length}
				onOverlayClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
				title={t("common.selectOption", "Välj alternativ")}
				open={open}
				primaryAction={primaryAction}
				onClose={handleClose}
				secondaryActions={secondaryActions}
				sectioned
			>
				<TextField label={t("common.search", "Sök")} value={search} onChange={setSearch} />
				<OptionList options={options} selectedValue={value} onOptionSelect={handleOptionSelect} level={0} search={search} />
			</Modal>
		</>
	);
	// return (
	// 	<Popover
	// 		preferredPosition="below"
	// 		open={open}
	// 		onClose={handleClose}
	//    sectioned
	// 		activator={
	// 			<>
	// 				{label && <Label id="slecetoptios">{label}</Label>}
	// 				<SelectActivator onClick={handleToggle}>
	// 					{/* {selected?.label || "Välj"} */}
	// 					asd
	// 					<Icon source={SelectMinor} />
	// 				</SelectActivator>
	// 			</>
	// 		}
	// 	>
	// 		<OptionList options={options} selectedValue={value} onOptionSelect={handleOptionSelect} level={0} />
	// 	</Popover>
	// );
};

const OptionList = ({ options, selectedValue, onOptionSelect, level, search, parentValue = "" }) => {
	// Function to determine if an option is selected based on the selectedValue
	const getSelected = useCallback(
		(option) => {
			const selectedValueKeys = selectedValue.split(".");
			const optionValueKeys = option.value.split(".");

			return (
				selectedValue &&
				selectedValue.startsWith(option.value) &&
				option.value &&
				optionValueKeys.every((key, index) => key === selectedValueKeys[index])
			);
		},
		[selectedValue]
	);

	// Function to render child options if the current option is selected
	const renderChildOptions = (option, index) => {
		const selected = getSelected(option);
		if (selected && option.options?.length) {
			return (
				<OptionList
					key={option.value || index}
					options={option.options}
					selectedValue={selectedValue}
					onOptionSelect={onOptionSelect}
					level={level + 1}
					search={search}
					parentValue={option.value}
				/>
			);
		}
		return null;
	};

	const selected = (level === 0 && selectedValue) || options.some((option) => getSelected(option));

	const handleOptionSelect = useCallback(
		(option) => () => {
			const selected = getSelected(option);
			if (selected) {
				onOptionSelect(parentValue);
			} else {
				onOptionSelect(option.value);
			}
		},
		[getSelected, onOptionSelect, parentValue]
	);

	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			{level != 0 && <VerticalLine />}
			<Ul>
				{options
					.filter((option) => selected || new RegExp(search, "i").test(option.label))
					.map((option, index) => {
						const selected = getSelected(option);
						return (
							<Item isSelected={selected} key={option.value || index} onClick={handleOptionSelect(option)}>
								{option.type === "array" && <Icon source={ListMinor} />}
								{option.label || option.title}
								{option.options && <Icon source={SelectMinor} />}
							</Item>
						);
					})}
			</Ul>
			{options.map((option, index) => renderChildOptions(option, index))}
		</div>
	);
};

const SelectActivator = styled.div`
	background-color: var(--modals-input-bg) !important;
	border-radius: 0.3125rem;
	height: 34px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	justify-content: space-between;

	.Polaris-Icon {
		margin: 0;
	}
`;

const Item = styled.li<{ isSelected: boolean }>`
	padding: 5px 10px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	display: flex;
	gap: 3px;

	.Polaris-Icon {
		margin: 0;
	}
	/* text-wrap: nowrap; */
	/* width: max-content; */

	${({ isSelected }) =>
		isSelected &&
		css`
			background-color: var(--main3);
		`}

	&:hover {
		background-color: var(--main3);
	}
`;

const Ul = styled.ul`
	list-style-type: none;
	padding: 0;
	max-height: 50vh;
	overflow-y: auto;
	padding: 5px 10px;
	width: max-content;
	scrollbar-color: transparent;

	&::-webkit-scrollbar-track {
		background: transparent;
	}
`;

const VerticalLine = styled.div`
	height: 100%;
	width: 2px;
	background-color: rgba(255, 255, 255, 0.1);
	margin: 0 10px;
`;

export default withTranslation(["common"])(NestedOptionsList);
