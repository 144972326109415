import { Checkbox, FormLayout, Labelled, Layout, LegacyCard } from "@shopify/polaris";
import { useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import PricelistContext from "./context";
import { InfoWrapper } from "../CustomFields/styles";

type PricelistInfoProps = {
	history: any;
} & WithTranslation;

const PricelistInfo = ({ history, t }: PricelistInfoProps) => {
	const { form, setForm, isSaving, isFetching } = useContext(PricelistContext);

	const getChangeHandler = (field: PricelistTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<CustomFieldType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			return newForm;
		});
	};

	const disabled = isSaving || isFetching;

	return (
		<InfoWrapper>
			<LegacyCard sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<Labelled id="type" label={t("pricelists.fields.disabled.label2", "Status")}>
								<Checkbox
									checked={!form?.disabled}
									disabled={disabled}
									label={t("pricelists.fields.disabled.label", "Aktiv")}
									onChange={(v) => getChangeHandler("disabled")(!v)}
								/>
							</Labelled>

							<TextField
								disabled={disabled}
								label={t("pricelists.fields.inPrice.label", "Titel")}
								value={form?.title}
								onChange={getChangeHandler("title")}
							/>
							<TextField
								disabled={disabled}
								label={t("pricelists.fields.description.label", "Beskrivning")}
								value={form?.description}
								onChange={getChangeHandler("description")}
								multiline
							/>

							<Labelled id="type" label={t("pricelists.fields.AddCustomersAutomatically.label", "Lägg till nya kunder i prislistan automatiskt")}>
								<Checkbox
									checked={form?.add_new_contacts_automatically}
									disabled={disabled}
									label={t("pricelists.fields.disabled.label1", "Ja")}
									onChange={getChangeHandler("add_new_contacts_automatically")}
								/>
							</Labelled>
							<Labelled id="type" label={t("pricelists.fields.AddUsersAutomatically.label", "Lägg till nya användare i prislistan automatiskt")}>
								<Checkbox
									checked={form?.add_new_users_automatically}
									disabled={disabled}
									label={t("pricelists.fields.disabled.label1", "Ja")}
									onChange={getChangeHandler("add_new_users_automatically")}
								/>
							</Labelled>
						</FormLayout>
					</Layout.Section>
				</Layout>
			</LegacyCard>
		</InfoWrapper>
	);
};
export default withTranslation(["pricelist", "common"])(PricelistInfo);
