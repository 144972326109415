/* eslint-disable no-redeclare */
const chatMessages = (state = {}, action) => {
	switch (action.type) {
		case "SET_CHAT_MESSAGES":
			return action.messages;
		case "SET_PER_CHAT_MESSAGES":
			state[action.chat_id] = action.messages;

			return state;
		case "ADD_CHAT_MESSAGE": {
			const chatId = String(action.message.chat_id);

			if (!state[chatId]) {
				state[chatId] = [];
			}
			const index = state[chatId].findIndex((msg) => {
				if (msg.ref) {
					return String(msg.user_id) === String(action.message.user_id) && msg?.ref && action?.ref && String(msg?.ref) === String(action?.ref);
				}
				return String(msg.id) === String(action.message.id);
			});

			if (index >= 0) {
				if (action?.ref) {
					// Lägg till image "data" så attachment inte använder urlen och behöver ladda om komponenten
					const files = action.message.files.map((file) => {
						return {
							...file,
							data: state[chatId][index].files.find((f) => String(f.id) === String(file.id))?.data,
						};
					});

					action.message.files = files;
				}

				state[chatId].splice(index, 1, action.message);
			} else {
				state[chatId].push(action.message);
				state[chatId] = [...state[chatId]];
			}
			return Object.assign({}, state);
		}
		case "ADD_MESSAGES": {
			const chatIds = [];

			state = action.messages.reduce((acc, message) => {
				const chatId = String(message.chat_id);

				if (!acc[chatId]) {
					acc[chatId] = [];
				}
				if (chatIds.indexOf(chatId) < 0) {
					chatIds.push(chatId);
				}
				const index = acc[chatId].findIndex((msg) => {
					return String(msg.id) === String(message.id);
				});

				if (index >= 0) {
					acc[chatId].splice(index, 1, message);
				} else {
					acc[chatId].push(message);
				}

				return acc;
			}, Object.assign({}, state));

			for (let i = 0; i < chatIds.length; i++) {
				const chatId = chatIds[i];
				state[chatId] = [...state[chatId]];
			}

			return Object.assign({}, state);
		}
		case "UPDATE_CHAT_MESSAGE": {
			const chatId = action.chat_id || action.message.chat_id;
			const msgIndex = state[chatId]?.findIndex((msg) => String(msg.id) === String(action.message.id));

			state[chatId].splice(msgIndex, 1, action.message);
			state[chatId] = [...state[chatId]];

			return Object.assign({}, state);
		}
		case "REMOVE_CHAT_MESSAGE": {
			const chatId = action.chat_id || action.message.chat_id;
			const msgIndex = state[chatId]?.findIndex((msg) => String(msg.id) === String(action.message.id));

			if (msgIndex >= 0) {
				state[chatId].splice(msgIndex, 1);
			}

			state[chatId] = [...state[chatId]];
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};

export default chatMessages;
