import React from "react";

const Send2Icon = ({ color = "var(--textColor)" }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.44 3.01774C12.5819 2.96275 12.736 2.94731 12.8859 2.97307C13.0359 2.99883 13.176 3.06483 13.2914 3.16402C13.4067 3.2632 13.493 3.39185 13.5409 3.53623C13.5889 3.68061 13.5967 3.83531 13.5636 3.9838L13.5631 3.98615L13.5631 3.98615L11.589 12.6072L11.5889 12.6074C11.5563 12.7494 11.4873 12.8805 11.3887 12.9878C11.2902 13.0951 11.1654 13.1749 11.0266 13.2194C10.8879 13.2639 10.7399 13.2715 10.5973 13.2415C10.455 13.2116 10.3229 13.1453 10.214 13.049C10.2138 13.0488 10.2135 13.0486 10.2133 13.0484L5.57871 8.97059L2.89061 8.43095L2.88888 8.4306C2.08147 8.26498 1.95739 7.13403 2.74652 6.82478L2.89977 7.21584L2.74623 6.82489L2.74652 6.82478L12.4383 3.01841L12.44 3.01774ZM3.05567 7.60588C3.05574 7.60638 3.05583 7.60685 3.05592 7.6073C3.05632 7.60739 3.05676 7.60749 3.05722 7.60759C3.05737 7.60762 3.05752 7.60765 3.05768 7.60769L5.85437 8.16912C5.92664 8.18363 5.99382 8.2169 6.04915 8.26559L10.7692 12.4186L10.7702 12.4194L12.7437 3.80099L3.05567 7.60588ZM3.05166 7.60598C3.05166 7.60598 3.05167 7.60598 3.05168 7.60599Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.2598 3.17388C13.3956 3.36193 13.3532 3.62449 13.1652 3.76031L6.0195 8.92138C5.83145 9.0572 5.5689 9.01486 5.43307 8.82681C5.29725 8.63875 5.33959 8.3762 5.52764 8.24038L12.6733 3.07931C12.8614 2.94349 13.1239 2.98583 13.2598 3.17388Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.77354 8.16089C6.00551 8.16089 6.19357 8.34894 6.19357 8.58091L6.19357 11.9982L6.19582 11.9959L7.83393 10.3578C7.99796 10.1938 8.26391 10.1938 8.42794 10.3578C8.59197 10.5218 8.59197 10.7878 8.42794 10.9518L6.79078 12.589C6.67409 12.7069 6.52508 12.7877 6.36253 12.8211C6.19936 12.8546 6.02991 12.8388 5.87572 12.7758C5.72152 12.7127 5.58955 12.6053 5.49657 12.4671C5.40359 12.3289 5.35379 12.1661 5.35352 11.9996L5.35352 8.58091C5.35352 8.34894 5.54157 8.16089 5.77354 8.16089Z"
			fill={color}
		/>
	</svg>
);

export default Send2Icon;
