import { LocationMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddressModal from "src/js/components/AddressModal";
import Button from "src/js/components/Button";

import useContact from "src/js/hooks/useContact";

const SelfContainedAddressModal = ({ contact: propsContact }) => {
	const [open, setOpen] = useState(false);
	const { contact, refreshContact, updateContact } = useContact(propsContact.id, propsContact);
	const { t } = useTranslation(["contact", "common"]);

	return (
		<>
			<Button icon={LocationMajor} plain onClick={() => setOpen(true)}>
				{t("contact.actions.workplace.add", "Lägg till nytt arbetsställe")}
			</Button>
			<AddressModal
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				contact={contact}
				onRemove={refreshContact}
				onUpdate={refreshContact}
				address={{ title: "", country: "SE" }}
				onCreate={(address) => {
					updateContact({ ...contact, addresses: [...contact.addresses, address] });
					refreshContact();
				}}
			/>
		</>
	);
};
export default SelfContainedAddressModal;
