const getSumPerPeriodAccumulated = (targetConnections, property) => {
	// First, get the sum for each period
	const sumPerPeriod = targetConnections?.reduce((acc, targetConnection) => {
		targetConnection.period_values.forEach((period, index) => {
			if (acc[index] === undefined) {
				acc[index] = 0;
			}
			acc[index] += period[property] || 0;
		});
		return acc;
	}, []);

	// Then, transform the sum to an accumulated sum
	const accumulatedSum = sumPerPeriod?.reduce((acc, current, index) => {
		if (index === 0) {
			acc.push(current);
		} else {
			acc.push(acc[index - 1] + current);
		}
		return acc;
	}, []);

	return accumulatedSum;
};

export default getSumPerPeriodAccumulated;
