import { withTranslation } from "react-i18next";
/* eslint-disable quotes */
import React, { useCallback, useState } from "react";
import { Button, LegacyStack } from "@shopify/polaris";
import Popover from "../Popover";

const ConfirmPopup = ({ activator, title, primaryButton = null, t }: any) => {
	const [open, setOpen] = useState(false);

	const handleOnClose = useCallback(() => {
		setOpen(false);
	}, []);
	const handleOnToggle = useCallback((e) => {
		e?.preventDefault?.();
		e?.stopPropagation?.();

		setOpen((c) => !c);
	}, []);

	const handleOnPrimaryAction = async () => {
		const button = primaryButton || activator;
		const clickAction = button?.props?.onClick || button?.onClick?.();
		const action = button?.props?.onAction || button?.onAction?.();
		await clickAction?.();
		await action?.();

		handleOnClose();
	};

	const activatorButton = React.cloneElement(activator, { onClick: handleOnToggle, onAction: () => {} });
	const activatorActionButton = React.cloneElement(primaryButton || activator, { onClick: handleOnPrimaryAction });

	return (
		<Popover showCloseButton={false} active={open} activator={activatorButton} onClose={handleOnClose} sectioned>
			{title}
			<LegacyStack>
				<Button onClick={handleOnClose}>{t("common.actions.cancel", "Avbryt")}</Button>
				<div>{activatorActionButton}</div>
			</LegacyStack>
		</Popover>
	);
};

export default withTranslation(["modal", "common"], { withRef: true })(ConfirmPopup);
