import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { currencyFormatter } from "src/js/Utilities";
import { WithTranslation, withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { store } from "src/js/store";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { getContractFilters, getFortnoxContractStatus } from "../../Utilities";
import FortnoxContactResourceList from "../../FortnoxContactResourceList";

type FortnoxBillingContractsContactListProps = {
	contact?: ContactType;
	history: any;
	setCount?: any;
} & WithTranslation;

const FortnoxBillingContractsContactList = ({ contact, history, t, setCount }: FortnoxBillingContractsContactListProps) => {
	const queryKey = [contact?.id && `contact_${contact?.id}_fortnox_contracts_count`].filter(Boolean);
	useQuery({
		queryKey,
		queryFn: async () => {
			try {
				return await API.get(`/api/fortnox/contacts/${contact?.id}/contracts.json`, { params: { limit: 1 } }).then(
					(response) => response?.data?.count
				);
			} catch (error) {
				console.error("error:", error);
				return null;
			}
		},
		refetchOnWindowFocus: false,
		enabled: !!(contact?.id && contact.fortnox_customer_id && store.getState().account.fortnox_integration && setCount),
		initialData: {
			data: [],
			count: 0,
		},
		onSuccess: (data) => {
			if (setCount) setCount(data);
		},
	});

	const renderItem = useCallback(
		(item: FortnoxContractType) => {
			const status = getFortnoxContractStatus(item);
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/fortnox/contracts/${item.DocumentNumber}`, {
							contract: item,
						});
					}}
					title={
						<>
							{item.DocumentNumber}
							<Badge color={status.color}>{status.label}</Badge>
						</>
					}
					leftColumn={[
						{
							label: t("fortnox.contracts.fields.ContractLength.label", "Avtalslängd"),
							value: item.Continuous ? t("fortnox.contracts.fields.Continuous.label", "löpande") : item.ContractLength,
						},

						{
							label: t("fortnox.contracts.fields.PeriodStart.label", "Periodstart"),
							value: item.PeriodStart,
						},
						{
							label: t("fortnox.contracts.fields.PeriodEnd.label", "Periodslut"),
							value: item.PeriodEnd,
						},
						{
							label: t("fortnox.contracts.fields.CustomerName.label", "Kund"),
							value: item.CustomerName,
							enabled: !contact,
						},
					]}
					rightColumn={[
						{
							label: "Total",
							value: currencyFormatter({
								value: item.Total,
								currency: item.Currency,
							}),
						},
						{
							label: t("fortnox.contracts.fields.InvoicesRemaining.label", "Fakturor kvar"),
							value: item.InvoicesRemaining,
						},
					]}
				/>
			);
		},
		[history, t, contact]
	);
	const filters = [
		{
			key: "filter",
			label: t("fortnox.filters.status.label", "Status"),
			type: "select",
			options: getContractFilters(),
			shortcut: true,
		},
	];
	return (
		<FortnoxContactResourceList
			filters={filters}
			contact={contact}
			resource="Contracts"
			resourceName={{
				singular: "Avtalsfakturering",
				plural: "avtalsfaktureringar",
			}}
			renderItem={renderItem}
			additionalAction={
				contact?.id && {
					content: t("fortnox.contracts.actions.create", "Skapa avtalsfakturering"),
					plain: true,
					icon: CirclePlusMajor,
					// primary: true,
					onAction: () => {
						history.push(`/admin/fortnox/contacts/${contact?.id}/contracts/create`, {
							contact,
						});
					},
				}
			}
		/>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxBillingContractsContactList);
