import React, { Component } from "react";
import { Icon, Popover, Spinner, ActionList } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import API from "../API";

class OrderRowAutocomplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: props.options || [],
			active: false,
			value: props.value || "",
			loading: !props.options,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value || "" });
	}

	fetch(updated) {
		if (this.props.options) {
			return;
		}
		if (updated) {
			this.updateValue();
		}
		this.setState({ loading: true });
		const params = Object.assign({}, {});
		params.q = this.state.value;
		API.get("/api/integrations/" + this.props.integration.id + "/search/" + this.props.resource + ".json", {
			params,
		})
			.then((result) => {
				this.setState({
					loading: false,
					items: result.data[this.props.resource],
				});

				for (let i = 0; i < result.data[this.props.resource].length; i++) {
					let value;

					if (this.props.resource == "units") {
						value = result.data[this.props.resource][i].Code;
					} else if (this.props.resource == "printtemplates") {
						value = result.data[this.props.resource][i].Template;
					} else if (this.props.resource == "articles") {
						value = result.data[this.props.resource][i].ArticleNumber;
					}

					if (value == this.state.value) {
						if (this.props.onSelect) {
							this.props.onSelect(result.data[this.props.resource][i]);
						}
					}
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	}

	updateValue() {
		this.props.onChange(this.state.value);
	}

	setValue(value, item) {
		if (this.props.onSelect) {
			this.props.onSelect(item);
		}
		this.setState({ value, active: false }, this.updateValue.bind(this));
		this.inputRef?.blur();
		// setTimeout(() => {this.input.focus()}, 50);
	}

	render() {
		return (
			<Popover
				active={this.state.active}
				preventAutofocus
				autofocusTarget="none"
				activator={
					<div>
						<input
							ref={(ref) => {
								this.inputRef = ref;
							}}
							disabled={this.props.disabled}
							value={this.state.value}
							onChange={(e) => {
								this.setState({ value: e.target.value }, this.fetch.bind(this, true));
							}}
							onFocus={() => {
								if (!this.props.disabled) this.setState({ active: true }, this.fetch.bind(this));
							}}
							onBlur={() => {
								this.setState({ active: false });
							}}
							type="text"
						/>
						{!this.props.disabled && (
							<div style={{ position: "absolute", top: 5, right: 5 }}>
								<a
									tabIndex={-1}
									onClick={() => {
										this.setState({ active: !this.state.active });
										if (!this.state.active) {
											this.fetch();
										}
									}}
								>
									<Icon source={ChevronDownMinor} />
								</a>
							</div>
						)}
					</div>
				}
				onClose={() => {
					this.setState({ active: false });
				}}
			>
				{this.state.loading ? (
					<Popover.Section>
						<Spinner size="small" />
					</Popover.Section>
				) : null}
				<div onMouseDown={(e) => e.preventDefault()} onTouchStart={(e) => e.preventDefault()}>
					<ActionList
						items={this.state.items
							.filter((item) => {
								if (!this.props.options || !this.state.value) {
									return true;
								}

								/*
								const filterRegex = new RegExp(this.state.value, "i");
								return item.label.match(filterRegex);
							*/

								return true;
							})
							.map((item) => {
								let value = item.Code;
								let content = item.Description;
								let label = "unknown";

								if (this.props.resource == "units") {
									value = item.Code;
									content = item.Description;
									label = value + " - " + content;
								} else if (this.props.resource == "printtemplates") {
									value = item.Template;
									content = item.Name;
									label = value + " - " + content;
								} else if (this.props.resource == "articles") {
									value = item.ArticleNumber;
									content = item.Description;
									label = value + " - " + content;
								} else {
									value = item.value;
									label = item.label;
								}
								return {
									content: label,
									onAction: this.setValue.bind(this, value, item),
								};
							})}
					/>
				</div>
			</Popover>
		);
	}
}

export default OrderRowAutocomplete;
