import React, { Component } from "react";
import { Button, FormLayout, TextField, Scrollable } from "@shopify/polaris";
import "reactflow/dist/style.css";
import "./index.scss";

import { hierarchy, tree } from "d3-hierarchy";
import { ReactFlow, Background, applyNodeChanges } from "reactflow";
import { withTranslation } from "react-i18next";
import MainNode from "./MainNode";
import API from "../../API";
import Sheet from "../../components/sheet";
import Toast from "src/js/components/Toast";
import SkeletonPage from "src/js/components/Skeletons/Page";

const nodeTypes = {
	main: MainNode,
};

class AutomationIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params.id,
			loading: true,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/automations/" + id + ".json")
			.then((result) => {
				this.setState({
					loading: false,
					form: result.data.automation,
				});
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	saveStep() {
		this.setState({ saving: true });
		if (!this.state.currentStep.id) {
			API.post("/api/automations/" + this.state.id + "/steps.json", this.state.currentStep)
				.then((result) => {
					this.setState({
						saving: false,
						sheetOpen: false,
						form: result.data.automation,
					});
				})
				.catch((error) => {
					Toast.error(error);
				});
		} else {
			API.put("/api/automations/" + this.state.id + "/steps/" + this.state.currentStep.id + ".json", this.state.currentStep)
				.then((result) => {
					this.setState({
						saving: false,
						sheetOpen: false,
						form: result.data.automation,
					});
				})
				.catch((error) => {
					Toast.error(error);
				});
		}
	}

	generateNode(step) {
		const arr = [];
		arr.push({
			id: step.data.id + "",
			label: step.data.id + "",
			position: { x: step.x, y: step.y },
			data: {
				have_children: !!step.children,
				have_parent: !!step.parent,
				onClick: () => {
					this.setState({ sheetOpen: true, currentStep: Object.assign({}, step.data) });
				},
				onCreate: () => {
					this.setState({ sheetOpen: true, currentStep: { parent_id: step.data.id } });
				},
				...step.data,
			},
			type: "main",
		});
		if (step.children) {
			for (let i = 0; i < step.children.length; i++) {
				const res = this.generateNode(step.children[i]);
				for (let s = 0; s < res.length; s++) {
					arr.push(res[s]);
				}
			}
		}
		return arr;
	}

	generateEdges() {
		return this.state.form.steps
			.filter((step) => step.parent_id)
			.map((step) => ({
				id: step.parent_id + "-" + step.id,
				source: step.parent_id + "",
				target: step.id + "",
				type: "smoothstep",
			}));
	}

	getStepWithChildren(step) {
		step.children = this.state.form.steps.filter((child) => child.parent_id == step.id).map((child) => this.getStepWithChildren(child));
		return step;
	}

	updateStep(field, value) {
		this.state.currentStep[field] = value;
		this.setState({ currentStep: this.state.currentStep });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonPage />;
		}

		const myHierarchy = hierarchy(this.getStepWithChildren(this.state.form.steps[0]));
		const myTree = tree()
			.separation((a, b) => (a.parent === b.parent ? 1.1 : 1.4))
			.nodeSize([150, 150])(myHierarchy);
		return (
			<>
				<ReactFlow
					nodes={this.generateNode(myTree)}
					edges={this.generateEdges()}
					onNodesChange={(nodeChange) => {
						return;
						// eslint-disable-next-line no-unreachable
						this.setState({ nodes: applyNodeChanges(nodeChange, this.state.nodes) });
					}}
					nodeTypes={nodeTypes}
					onEdgesChange={() => {}}
					onConnect={() => {}}
					fitView
				>
					<Background />
				</ReactFlow>
				<Sheet
					open={this.state.sheetOpen}
					onClose={() => {
						this.setState({ sheetOpen: false });
					}}
				>
					<div>
						<Scrollable>
							<div style={{ paddingLeft: 20, paddingRight: 20 }}>
								{this.state.currentStep && (
									<FormLayout>
										<TextField
											label={this.props.t("automation.fields.title.label", "Rubrik")}
											value={this.state.currentStep.title}
											onChange={this.updateStep.bind(this, "title")}
										/>
										<Button loading={this.state.saving} onClick={this.saveStep.bind(this)}>
											{this.state.currentStep.id ? this.props.t("common.actions.save", "Spara") : this.props.t("common.actions.create", "Skapa")}
										</Button>
									</FormLayout>
								)}
							</div>
						</Scrollable>
					</div>
				</Sheet>
			</>
		);
	}
}

export default withTranslation(["automation", "common"], { withRef: true })(AutomationIndex);
