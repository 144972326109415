/* eslint-disable no-underscore-dangle */
import { fabric } from "fabric";

function convertToLink(originalMarkup, linkUrl) {
	const parser = new DOMParser();
	const xmlDoc = parser.parseFromString(originalMarkup, "application/xml");

	const gElement = xmlDoc.getElementsByTagName("g")[0];
	const textElement = xmlDoc.getElementsByTagName("text")[0];

	const aElement = xmlDoc.createElement("a");
	aElement.setAttribute("xlink:href", linkUrl);
	aElement.setAttribute("target", "_blank");
	aElement.setAttribute("xlink:title", "");

	gElement.replaceChild(aElement, textElement);
	aElement.appendChild(textElement);

	const serializer = new XMLSerializer();
	const modifiedMarkup = serializer.serializeToString(xmlDoc);

	return modifiedMarkup;
}

const Link = fabric.util.createClass(fabric.Textbox, {
	type: "link",
	href: "",

	toObject() {
		return fabric.util.object.extend(this.callSuper("toObject"), {
			href: this.href,
		});
	},

	toSVG(reviver) {
		let markup = this.callSuper("toSVG", reviver);

		if (this.href) {
			markup = convertToLink(markup, this.href);
		}

		return markup;
	},
});

const fromObject = function (object, callback) {
	const fabricLink = new fabric.Link(object.text, object);
	if (callback) callback(fabricLink);

	return fabricLink;
};

Link.fromObject = fromObject;

export default Link;
