import { LegacyCard } from "@shopify/polaris";
import React, { useContext, useEffect, useMemo, useState } from "react";
import numberFormatter from "src/js/Utilities/numberFormatter";
import LineGraph from "src/js/components/Graphs/LineGraph";
import Select from "src/js/components/select/Select";
import styled from "styled-components";
import TargetDashboardContext from "../Context";
import getSumPerPeriodAccumulated from "../Utilities/getSumPerPeriodAccumulated";

const TargetLineGraph = () => {
	const { data, targetConnections, target, compareTargetConnections, compareData, compareDate, setCompareDate, isLoadingCompare } =
		useContext(TargetDashboardContext);
	const stats = useMemo(() => data?.compare_against_group, [data]);

	const [property, setProperty] = useState(stats?.options?.value_types?.[0]?.value || null);

	useEffect(() => {
		setProperty((c) => {
			if (stats?.options && !c) {
				return stats?.options?.value_types?.[0]?.value || null;
			}

			return c;
		});
	}, [stats]);

	const labels = stats?.labels || [];
	const serieData = getSumPerPeriodAccumulated(targetConnections || [], property);
	const compareSerieData = getSumPerPeriodAccumulated(compareTargetConnections || [], property);

	const series = [
		{
			name: `${data?.group_start_at}-${data?.group_end_at}`,
			data: serieData,
			total: serieData.at(-1),
		},
		{
			name: compareData?.group_start_at ? `${compareData?.group_start_at}-${compareData?.group_end_at}` : compareDate,
			loading: isLoadingCompare,
			data: compareSerieData,
			total: compareSerieData.at(-1),
		},
	];

	return (
		<Wrapper>
			<LegacyCard sectioned>
				<LineGraph
					overlayText={stats?.error}
					sort={false}
					data={{
						series,
						labels: labels.map((label: any) => label!.capitalize()),
					}}
					loading={false}
					formatter={(value) => {
						return `${numberFormatter({
							value,
							currency: "SEK",
							minimumFractionDigits: target?.number_of_decimals,
							maximumFractionDigits: target?.number_of_decimals,
							removeCurrencySymbol: true,
						})}${target?.suffix || ""}`;
					}}
					showSettings={false}
					headerRight={
						<div style={{ display: "flex", gap: "1rem" }}>
							{stats?.options?.compare_against?.length && (
								<Select
									options={stats?.options?.compare_against || []}
									value={compareDate}
									onChange={(date) => {
										setCompareDate(date);
									}}
								/>
							)}
							{stats?.options?.value_types?.length && (
								<Select
									options={stats?.options?.value_types || []}
									value={property}
									onChange={(property) => {
										setProperty(property);
									}}
								/>
							)}
						</div>
					}
				/>
			</LegacyCard>
		</Wrapper>
	);
};
export default TargetLineGraph;

const Wrapper = styled.div``;
