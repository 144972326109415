import { Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Select from "src/js/components/select/Select";
import getTermsOfPaymentOptions from "../Utilities/getTermsOfPaymentOptions";

const FortnoxInvoiceInfo = ({ invoice, onChange, t, isSaving }) => {
	const disabled = !!invoice?.InvoiceReference || isSaving || invoice.Cancelled;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					{invoice.OCR && <TextField disabled label={t("fortnox.fields.OCR.label", "OCR")} value={invoice.OCR} />}
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourReference.label", "Er referens")}
						value={invoice.YourReference}
						onChange={onChange("YourReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.OurReference.label", "Vår referens")}
						value={invoice.OurReference}
						onChange={onChange("OurReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.InvoiceDate.label", "Fakturadatum")}
						value={invoice.InvoiceDate}
						onChange={onChange("InvoiceDate")}
						type="date"
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DueDate.label", "Förfallodatum")}
						value={invoice.DueDate}
						onChange={onChange("DueDate")}
						type="date"
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourOrderNumber.label", "Ert ordernummer")}
						value={invoice.YourOrderNumber}
						onChange={onChange("YourOrderNumber")}
					/>
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<Select
						label={t("fortnox.fields.TermsOfPayment.label", "Betalningsvillkor")}
						options={getTermsOfPaymentOptions()}
						value={invoice.TermsOfPayment}
						onChange={onChange("TermsOfPayment")}
					/>
					<Labelled id="VATIncluded" label={t("fortnox.fields.VATIncluded.label", "Priser inkl. moms")}>
						<Checkbox
							label={t("fortnox.fields.VATIncluded.label2", "Priser inkl. moms")}
							checked={invoice.VATIncluded}
							onChange={onChange("VATIncluded")}
						/>
					</Labelled>
					<TextField
						disabled={disabled}
						label={t("fortnox.invoice.fields.Remarks.label", "Fakturatext")}
						value={invoice.Remarks}
						multiline={3}
						onChange={onChange("Remarks")}
					/>
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxInvoiceInfo);
