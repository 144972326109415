import styled, { css } from "styled-components";
import { LabelCell } from "../Details";

export const Wrapper = styled.div`
	/* & > .Polaris-Box {
		--pc-box-background: var(--tertiary) !important;
	} */

	padding-bottom: 1rem;

	.Polaris-Button.Polaris-Button--plain#user_and_date {
		text-decoration: none;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Date = styled.span`
	color: var(--textColor2);
	font-size: 0.8rem;
`;

export const EntityLogChange = styled.div`
	display: flex;
	gap: 0.35rem;
	align-items: center;

	.Polaris-Icon {
		margin: 0;
	}
`;
export const EntityLogChangeWrapper = styled.div`
	padding-top: 1rem;

	${LabelCell} {
		font-weight: 700;
		color: var(--textColor);
		opacity: 1;
	}
`;

export const Loadmore = styled.div<{ disabled?: boolean }>`
	align-items: center;
	font-weight: 500;
	cursor: pointer;

	padding: var(--p-space-5);
	background-color: var(--secondary);

	transition: background-color 0.3s;

	.Polaris-Icon {
		margin: 0;
	}

	&:hover {
		background-color: var(--tertiary);
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}
`;

export const LoadmoreInner = styled.div`
	display: flex;
	gap: 0.35rem;
	align-items: center;
	justify-content: center;
`;
