import React, { Component } from "react";
import { Button, LegacyStack, Popover } from "@shopify/polaris";
import { ProfileMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import MemberAvatar from "./MemberAvatar";
import MyPopover from "../../../components/Popover";

class PersonFilter extends Component {
	constructor(props) {
		super(props);
		this.state = { active: false };
	}

	onFocus() {
		this.setState({ active: true });
	}

	onBlur() {
		this.setState({ active: false });
	}

	toggleMember(member, event) {
		event.stopPropagation();
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == "person");
		let value = [];

		if (foundIndex >= 0) {
			value = this.props.filters[foundIndex].value;
		}

		const memberIndex = value?.indexOf(member.user_id);
		if (memberIndex >= 0) {
			value.splice(memberIndex, 1);
		} else {
			value.push(member.user_id);
		}

		if (value.length) {
			if (foundIndex >= 0) {
				this.props.filters[foundIndex].value = value;
			} else {
				this.props.filters.push({ column_id: "person", column_type: null, value });
			}
		} else {
			if (foundIndex >= 0) {
				this.props.filters.splice(foundIndex, 1);
			}
		}
		this.props.onChangeFilters(this.props.filters);
	}

	render() {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == "person");
		let value = [];

		if (foundIndex >= 0) {
			value = this.props.filters[foundIndex].value;
		}

		const selectedMembers = this.props.members.filter((m) => value?.includes(m.user_id));

		return (
			<MyPopover
				fixed
				active={this.state.active}
				activator={
					<Button icon={ProfileMajor} onClick={this.onFocus.bind(this)}>
						{this.props.t("board.filters.person.label", "Person")}
						{value && !!value.length && <div className="filterNrDot">{value.length}</div>}
					</Button>
				}
				onClose={this.onBlur.bind(this)}
			>
				<Popover.Section>
					<div style={{ width: 200 }}>
						<LegacyStack spacing="extraTight" style={{ borderBottom: "thing solid rgb(100,100,100)" }}>
							{selectedMembers.map((member) => (
								<a key={member.id} onClick={this.toggleMember.bind(this, member)}>
									<MemberAvatar key={member.id} active={value?.includes(member.user_id)} tooltip member={member} />
								</a>
							))}
						</LegacyStack>
						{!!selectedMembers.length && <hr style={{ backgroundColor: "rgb(75,75,75)", height: 1, border: "none", marginTop: 10 }} />}
						<LegacyStack spacing="extraTight">
							{this.props.members
								.filter((m) => !value?.includes(m.user_id))
								.map((member) => (
									<a key={member.id} onClick={this.toggleMember.bind(this, member)}>
										<MemberAvatar key={member.id} active={value?.includes(member.user_id)} tooltip member={member} />
									</a>
								))}
						</LegacyStack>
					</div>
				</Popover.Section>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(PersonFilter);
