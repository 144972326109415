import { Button, ButtonGroup, FormLayout, Labelled, Layout } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";

type FortnoxArticleWarehouseInfoProps = {
	article: Partial<FortnoxArticleType>;
	onChange: (field: FortnoxArticleTypeMutableKeys) => (value: string | boolean) => void;
	t: any;
	isSaving: boolean;
};

const FortnoxArticleWarehouseInfo = ({ article, onChange, t, isSaving }: FortnoxArticleWarehouseInfoProps) => {
	const disabled = !!isSaving;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.PurchasePrice.label", "Kalkylkostnad")}
						value={article.PurchasePrice}
						onChange={onChange("PurchasePrice")}
					/>

					<TextField
						disabled={disabled}
						label={t("fortnox.fields.StockValue.label", "Lagervärde")}
						value={article.StockValue}
						onChange={onChange("StockValue")}
					/>

					<TextField
						disabled={disabled}
						label={t("fortnox.fields.QuantityInStock.label", "Antal i lager")}
						value={article.QuantityInStock}
						onChange={onChange("QuantityInStock")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.ReservedQuantity.label", "Reserverat")}
						value={article.ReservedQuantity}
						onChange={onChange("ReservedQuantity")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DisposableQuantity.label", "Disponibelt")}
						value={article.DisposableQuantity}
						onChange={onChange("DisposableQuantity")}
					/>
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.StockPlace.label", "Lagerplats")}
						value={article.StockPlace}
						onChange={onChange("StockPlace")}
					/>
					<TextField disabled={disabled} label={t("fortnox.fields.Width.label", "Bredd")} value={article.Width} onChange={onChange("Width")} />
					<TextField disabled={disabled} label={t("fortnox.fields.Height.label", "Höjd")} value={article.Height} onChange={onChange("Height")} />
					<TextField disabled={disabled} label={t("fortnox.fields.Depth.label", "Djup")} value={article.Depth} onChange={onChange("Depth")} />
					<TextField disabled={disabled} label={t("fortnox.fields.Weight.label", "Vikt")} value={article.Weight} onChange={onChange("Weight")} />

					<Labelled id="Active" label={t("fortnox.fields.Bulky.label", "Skrymmande")}>
						<ButtonGroup fullWidth segmented>
							<Button primary={article.Bulky} pressed={article.Bulky} onClick={() => onChange("Bulky")(true)} disabled={disabled}>
								{t("fortnox.fields.Bulky.options.true", "Ja") as string}
							</Button>
							<Button primary={!article.Bulky} onClick={() => onChange("Bulky")(false)} disabled={disabled}>
								{t("fortnox.fields.Bulky.options.false", "Nej") as string}
							</Button>
						</ButtonGroup>
					</Labelled>
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxArticleWarehouseInfo);
