import { Badge, Icon, IconSource, TextField, TextFieldProps } from "@shopify/polaris";
import React, { useEffect, useImperativeHandle, useRef } from "react";

type InlineInputProps = TextFieldProps & {
	disabled?: boolean;
	icon?: IconSource | React.Component;
	values?: Array<string>;
	onChange: (value: string) => void;
	onKeyDown?: (e: KeyboardEvent) => void;
	ref?: any;
};

const InlineInput: React.FC<InlineInputProps> = React.forwardRef(
	({ label, disabled = false, icon, value, values, onChange, onKeyDown, type, autoFocus, autoComplete, ...rest }: InlineInputProps, ref) => {
		const inputRef = useRef<HTMLInputElement | null>(null);

		useImperativeHandle(ref, () => ({
			focus: () => {
				if (inputRef.current) {
					const inputElement = inputRef.current?.querySelector("input");
					if (inputElement) inputElement.focus();
				}
			},
		}));

		useEffect(() => {
			const handleKeyDown = (e: any) => {
				if (onKeyDown) onKeyDown(e);
			};

			const inputElement = inputRef.current?.querySelector("input");

			if (inputElement) {
				inputElement.addEventListener("keydown", handleKeyDown);

				return () => {
					inputElement.removeEventListener("keydown", handleKeyDown);
				};
			}
		}, [onKeyDown]);

		return (
			<div className={`text_field_inline${disabled ? " disabled" : ""}`}>
				{label && (
					<span className="text_field_inline__label">
						{!icon || React.isValidElement(icon) ? icon : <Icon source={icon as IconSource} />}
						{label}
					</span>
				)}
				<div className="text_field_inline__input" ref={inputRef}>
					<TextField
						prefix={
							values &&
							values.map((value, index) => (
								<Badge status="success" key={index}>
									{value}
								</Badge>
							))
						}
						type={type || "text"}
						label=""
						autoComplete={autoComplete || "off"}
						onChange={onChange}
						value={value}
						autoFocus={autoFocus}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...rest}
					/>
				</div>
			</div>
		);
	}
);

export default InlineInput;
