import React from "react";

const IntegrationsIcon = ({ color = "var(--textColor)" }) => (
	<svg width="22" height="20" viewBox="0 0 22 20" fill={color} xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_1026_539)">
			<path d="M17.4154 8.08333V4.41666C17.4154 3.40833 16.5904 2.58333 15.582 2.58333H11.9154C11.9154 1.31833 10.8887 0.291664 9.6237 0.291664C8.3587 0.291664 7.33203 1.31833 7.33203 2.58333H3.66536C2.65703 2.58333 1.8412 3.40833 1.8412 4.41666V7.9H2.10703C3.47286 7.9 4.58203 9.00916 4.58203 10.375C4.58203 11.7408 3.47286 12.85 2.10703 12.85H1.83203V16.3333C1.83203 17.3417 2.65703 18.1667 3.66536 18.1667H7.1487V17.8917C7.1487 16.5258 8.25786 15.4167 9.6237 15.4167C10.9895 15.4167 12.0987 16.5258 12.0987 17.8917V18.1667H15.582C16.5904 18.1667 17.4154 17.3417 17.4154 16.3333V12.6667C18.6804 12.6667 19.707 11.64 19.707 10.375C19.707 9.11 18.6804 8.08333 17.4154 8.08333ZM17.4154 10.8333H15.582V16.3333H13.6387C13.0154 14.7292 11.4479 13.5833 9.6237 13.5833C7.79953 13.5833 6.23203 14.7292 5.6087 16.3333H3.66536V14.39C5.26953 13.7667 6.41536 12.1992 6.41536 10.375C6.41536 8.55083 5.2787 6.98333 3.67453 6.36L3.66536 4.41666H9.16536V2.58333C9.16536 2.32666 9.36703 2.125 9.6237 2.125C9.88036 2.125 10.082 2.32666 10.082 2.58333V4.41666H15.582V9.91666H17.4154C17.672 9.91666 17.8737 10.1183 17.8737 10.375C17.8737 10.6317 17.672 10.8333 17.4154 10.8333Z" />
		</g>
		<defs>
			<filter
				id="filter0_d_1026_539"
				x="-2.16797"
				y="0.291664"
				width="25.875"
				height="25.875"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1026_539" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1026_539" result="shape" />
			</filter>
		</defs>
	</svg>
);

export default IntegrationsIcon;
