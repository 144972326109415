import React, { Component } from "react";
import { Popover, LegacyStack, Select, Text, Icon, Checkbox, ChoiceList, Collapsible, Tooltip } from "@shopify/polaris";
import { CircleCancelMinor, RefreshMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import MyPopover from "../../components/Popover";

import BoardHelper from "./BoardHelper";
import NewDatePicker from "../../components/NewDatePicker";

class CellDateTime extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			value: props.value ? Object.assign({}, props.value) : props.value,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value ? Object.assign({}, props.value) : props.value });
	}

	toggle() {
		if (this.props.readonly || this.props.disabled) return;

		if (!this.state.editing) {
			this.props.onFocus();
		} else {
			this.props.onBlur();
		}
		this.setState({ editing: !this.state.editing });
	}

	blur() {
		if (JSON.stringify(this.state.value) != JSON.stringify(this.props.value)) {
			this.props.onChange({ value: this.state.value });
		}
		this.props.onBlur();
	}

	setSelectedDate(start) {
		if (!this.state.value) {
			this.state.value = { datetime: start };
		} else {
			this.state.value.datetime = start;
		}

		this.setState(
			{
				value: this.state.value,
			},
			this.updateValue.bind(this)
		);
	}

	updateValue() {
		if (this.props.expanded) {
			this.props.onChange(this.state.value);
		}
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = null;
		this.props.onChange({ value: this.state.value });
		this.setState({ value: null });
	}

	getReminderText() {
		if (this.state.value.reminder_minutes == "0" || this.state.value.reminder_minutes) {
			let str = this.props.t("cell.datetime.fields.reminder_text.opitons.default", "En påminnelse skickas");

			const opts = {
				0: this.props.t("cell.datetime.fields.reminder_text.options.0", "vid tillfället"),
				15: this.props.t("cell.datetime.fields.reminder_text.options.15", "en kvart innan"),
				30: this.props.t("cell.datetime.fields.reminder_text.options.30", "en halvtimme innan"),
				60: this.props.t("cell.datetime.fields.reminder_text.options.60", "en timme innan"),
				1440: this.props.t("cell.datetime.fields.reminder_text.options.1440", "ett dygn innan"),
			};

			str += " " + opts[this.state.value.reminder_minutes];

			if (this.state.value.priority == "low") {
				// eslint-disable-next-line quotes
				str += " " + this.props.t("cell.datetime.fields.reminder_text.options.low", 'som en "tyst" notis i ditt notiscenter');
			} else if (this.state.value.priority == "high") {
				str += " " + this.props.t("cell.datetime.fields.reminder_text.options.high", "som en pushnotis till appen samt via mail");
			} else {
				str += " " + this.props.t("cell.datetime.fields.reminder_text.options.app", "som en pushnotis till appen");
			}
			return (
				<div style={{ fontSize: "0.8em", paddingTop: 10 }}>
					<Text variation="subdued">{str}</Text>
				</div>
			);
		}
		return null;
	}

	render() {
		const datepicker = <NewDatePicker optionalTime value={this.state.value?.datetime} onChange={this.setSelectedDate.bind(this)} />;

		const timepicker = this.state.value ? (
			<div>
				<LegacyStack distribution="fillEvenly">
					<Select
						label={this.props.t("cell.datetime.fields.reminder_minutes.label", "Skicka påminnelse")}
						options={[
							{ value: "", label: this.props.t("cell.datetime.fields.reminder_minutes.options.none", "Ingen påminnelse") },
							{ value: "0", label: this.props.t("cell.datetime.fields.reminder_minutes.options.0", "Vid tillfället") },
							{ value: "15", label: this.props.t("cell.datetime.fields.reminder_minutes.options.15", "En kvart innan") },
							{ value: "30", label: this.props.t("cell.datetime.fields.reminder_minutes.options.30", "En halvtimme innan") },
							{ value: "60", label: this.props.t("cell.datetime.fields.reminder_minutes.options.60", "En timme innan") },
							{ value: "1440", label: this.props.t("cell.datetime.fields.reminder_minutes.options.1440", "Ett dygn innan") },
						]}
						value={this.state.value.reminder_minutes}
						onChange={(v) => {
							this.state.value.reminder_minutes = v;
							this.state.value.priority = "normal";
							this.setState({ value: this.state.value }, this.updateValue.bind(this));
						}}
					/>
					<Select
						label={this.props.t("cell.datetime.fields.priority.label", "Prioritet")}
						disabled={!this.state.value.reminder_minutes}
						options={[
							{ value: "low", label: this.props.t("cell.datetime.fields.priority.low", "Låg") },
							{ value: "normal", label: this.props.t("cell.datetime.fields.priority.normal", "Normal") },
							{ value: "high", label: this.props.t("cell.datetime.fields.priority.high", "Hög") },
						]}
						value={!this.state.value.priority ? "normal" : this.state.value.priority}
						onChange={(v) => {
							this.state.value.priority = v;
							this.setState({ value: this.state.value }, this.updateValue.bind(this));
						}}
					/>
				</LegacyStack>
				{this.getReminderText()}
			</div>
		) : null;

		const intervalpicker = (
			<div>
				<Checkbox
					label={this.props.t("cell.datetime.fields.recurring_interval.label", "Återkommande uppgift")}
					checked={this.state.value && this.state.value.recurring_interval}
					onChange={(v) => {
						if (v) {
							this.state.value.recurring_interval = "weekly";
						} else {
							delete this.state.value.recurring_interval;
						}
						this.setState({ value: this.state.value }, this.updateValue.bind(this));
					}}
				/>

				<Collapsible
					open={this.state.value && this.state.value.recurring_interval}
					id="recurring_interval"
					transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
				>
					<div style={{ marginTop: 10 }}>
						<ChoiceList
							title={this.props.t("cell.datetime.fields.recurring_interval.title", "Intervall")}
							selected={this.state.value ? [this.state.value.recurring_interval] : []}
							choices={[
								{ label: this.props.t("cell.datetime.fields.recurring_interval.options.daily", "Dagligen"), value: "daily" },
								{ label: this.props.t("cell.datetime.fields.recurring_interval.options.weekly", "Veckovis"), value: "weekly" },
								{ label: this.props.t("cell.datetime.fields.recurring_interval.options.monthly", "Månadsvis"), value: "monthly" },
								{ label: this.props.t("cell.datetime.fields.recurring_interval.options.quarterly", "Kvartalsvis"), value: "quarterly" },
								{ label: this.props.t("cell.datetime.fields.recurring_interval.options.yearly", "Årligen"), value: "yearly" },
							]}
							onChange={(v) => {
								this.state.value.recurring_interval = v && v[0];
								this.setState({ value: this.state.value }, this.updateValue.bind(this));
							}}
						/>
					</div>
				</Collapsible>
			</div>
		);

		if (this.props.expanded) {
			return (
				<LegacyStack vertical>
					{datepicker}
					{timepicker}
					{intervalpicker}
				</LegacyStack>
			);
		}
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.editing}
				activator={
					<div
						className={
							"overflow-ellipsis board-editable cell-datetime" + (this.state.editing ? " focused" : "") + (this.props.disabled ? " disabled" : "")
						}
						style={{
							width: "100%",
							cursor: "text",
							textAlign: this.props.textAlign || "center",
							height: 29,
							backgroundColor: this.state.editing ? "transparent" : null,
						}}
						onClick={this.toggle.bind(this)}
					>
						{this.state.value ? this.state.value.datetime : null}
						{this.state.value && this.state.value.recurring_interval && (
							<Tooltip
								content={`${this.props.t("cell.datetime.tooltips.recurring", "Återkommande")} ${
									BoardHelper.getDateRecurringLabelAndNextDate(this.state.value).label
								} (${BoardHelper.getDateRecurringLabelAndNextDate(this.state.value).upcoming_date})`}
							>
								<Icon source={RefreshMajor} />
							</Tooltip>
						)}
						{this.state.value ? (
							<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
								<Icon source={CircleCancelMinor} />
							</div>
						) : null}
					</div>
				}
				onClose={() => {
					this.setState({ editing: false }, this.blur.bind(this));
				}}
			>
				<div
					onTouchStart={(e) => {
						e.stopPropagation();
						e.preventDefault();
					}}
				>
					<Popover.Section>
						<div style={{ minWidth: 300 }}>{datepicker}</div>
					</Popover.Section>
					<Popover.Section>
						<div style={{ minWidth: 300 }}>{timepicker}</div>
						<div style={{ minWidth: 300 }}>{intervalpicker}</div>
					</Popover.Section>
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellDateTime);
