import React, { useCallback, useMemo, useState } from "react";
import useContact from "src/js/hooks/useContact";
import { Icon, Layout, LegacyCard } from "@shopify/polaris";
import ContactDashboardContainer from "../dashboards/ContactDashboardContainer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DashboardIcon, DealingsIcon, DocumentIcon, HorizonalDots, People3Icon, SupportIcon } from "src/js/icons";
import { BlogMajor, CirclePlusMajor, JobsMajor, LegalMajor, MoneyMinor, NoteMajor, OrdersMajor, ReceiptMajor } from "@shopify/polaris-icons";
import ContactCard from "src/js/views/Contacts/ContactCard/ContactCard";
import AxiconGraphs from "../axiconGraphs/AxiconGraphs";
import ContactTabsBar from "./ContactTabsBar";
import { useSelector } from "react-redux";
import { checkUserRoles } from "src/js/Utilities";
import { MainWrapper } from "./styles";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import People from "../People";
import Miniboard2 from "../../Workspaces/components/Miniboard2";
import DocumentListsTabsWrapper from "../DocumentListsTabsWrapper";
import Badge from "src/js/components/Badge";
import FortnoxOffersContactList from "../../Fortnox/Offers/List/FortnoxOffersContactList";
import Colors from "src/js/Colors";
import DocumentListSheetContent from "src/js/components/DocumentListSheetContent";
import BoardHelper from "../../Workspaces/BoardHelper";
import FortnoxOrdersContactList from "../../Fortnox/Orders/List/FortnoxOrdersContactList";
// import { useEventListener } from "src/js/hooks/UseEventListener";
import OrdersList from "../../OrderFlow/Orders/List";
import Button from "src/js/components/Button";
import FortnoxInvoicesContactList from "../../Fortnox/Invoices/List/FortnoxInvoicesContactList";
import FortnoxBillingContractsContactList from "../../Fortnox/BillingContracts/List/FortnoxBillingContractsContactList";
import { StyledDocumentItem } from "../../Workspaces/components/BoardUploadSheet";
import ContactDocumentGroups from "../ContactDocumentGroups";
import ExpandableSection from "src/js/components/ExpandableSection";
import RelatedResources from "../RelatedResources";
import Activities from "../Views/Activities";
import AxiconInfo from "../axiconGraphs/AxiconInfo";
import AxiconExecutiveList from "../axiconGraphs/AxiconExecutiveList";

type ContactTabsProps = {
	external?: boolean;
	style?: any;
	children?: any;
};

const ContactTabs = ({ external, style, children }: ContactTabsProps) => {
	const { id }: any = useParams();
	const { contact, documentGroups, t, counts, isLoadingDocumentGroups, isLoadingCounts, refreshCounts, axiconData } = useContact(id);
	const account = useSelector((state: any) => state.account);
	const storeBoards = useSelector((state: any) => state.boards);
	const history = useHistory();
	const location = useLocation();

	// useEventListener(
	// 	"hashchange",
	// 	() => {
	// 		const hash = new URL(document.URL).hash?.replace("#", "");
	// 		console.log("hashchange:", hash);
	// 		if (hash) setSelectedTabId(hash);
	// 	},
	// 	window
	// );

	const setTab = useCallback(
		(id, nestedTab) => {
			if (!id) return;
			const url = new URL(document.URL);
			const params = new URLSearchParams(url.search);
			const hash = `#${id}`;

			// if (nestedTab) {
			// 	// params.set("tab", id);
			// 	params.set("nested_tab", nestedTab);
			// }

			const newLocation = `${url.pathname}?${params.toString()}${hash}`;
			history.replace(newLocation, { nested_tab: nestedTab });
		},
		[history]
	);

	const gotoContact = useCallback(
		(contact, state) => {
			history.push("/admin/contacts/" + contact.id, state);
		},
		[history]
	);

	const getBoard = (boardId) => {
		return BoardHelper.getBoard(boardId);
	};

	const getColumn = useCallback(
		(boardId, columnTilte) => {
			const board = getBoard(boardId);

			const group = documentGroups?.find((g) => g.column_title.toLowerCase() === columnTilte && g.board_id === boardId);
			if (board && !group) return null;

			return board && group && board.columns.find((column) => column.id === group.column_id);
		},
		[documentGroups]
	);

	const tabs = useMemo(() => {
		const haveSalesBoard = account?.sales_board_id && checkUserRoles([`ROLE_WORKSPACE_${account.sales_workspace_id}`]);
		const serviceDeskBoard = storeBoards[account.support_board_id];
		const haveServiceDesk = checkUserRoles([`ROLE_WORKSPACE_${account.support_workspace_id}`]) && serviceDeskBoard;
		const fortnoxScopes = account.fortnox_integration?.authentication.scope?.split(" ") || [];

		return [
			{
				title: t("contact.tabs.dashboard.label", "Dashboard"),
				id: "dashboard",
				icon: <DashboardIcon />,
				enabled: !!contact.is_company,
				content: <ContactDashboardContainer setTab={setTab} contact={contact} />,
			},
			{
				title: contact.is_company ? t("contact.tabs.company.info.label", "Företagsinfo") : t("contact.tabs.person.info.label", "Personinfo"),
				id: "info",
				icon: contact.is_company ? <Icon source={JobsMajor} /> : <ProfileAvatar user={contact} size={25} />,
				// icon: <PinIcon />,
				// enabled: contact.is_company,
				content: (
					<Layout>
						<Layout.Section>
							<ContactCard
								expanded
								contact={contact}
								onUpdate={(newContact) => {
									// if (!newContact.is_company && newContact.id != contact.id) {
									// 	history.push("/admin/contacts/" + newContact.id);
									// }
								}}
								gotoContact={gotoContact}
								external={external}
							/>

							{(contact?.is_company || axiconData) && <AxiconGraphs />}
						</Layout.Section>

						<Layout.Section secondary>
							<AxiconExecutiveList />
							<AxiconInfo />
						</Layout.Section>
					</Layout>
				),
			},
			{
				title: t("contact.tabs.contacts.label", "Kontakter"),
				id: "contacts",
				enabled: contact?.is_company,
				icon: <People3Icon />,
				count: counts.children,
				// count: this.props.children && this.props.children.length,
				content: (
					<div>
						<People history={history} location={location} company={contact} />
					</div>
				),
			},
			{
				title: t("contact.tabs.activities.label", "Aktiviteter"),
				id: "activities",
				icon: <DealingsIcon color="var(--textColor)" />,
				// count: this.state.counts.upcoming_deadline_rows,
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<Activities />
					</div>
				),
			},
			{
				title: t("contact.tabs.dealings.label", "Affärer"),
				id: "dealings",
				icon: MoneyMinor,
				enabled: haveSalesBoard,
				count: counts[String(account.sales_board_id)],
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<Miniboard2
							board={storeBoards[account.sales_board_id]}
							board_id={account.sales_board_id}
							contact_id={contact.id}
							contact={contact}
							// setCount={this.setCounts.bind(this, account.sales_board_id)}
							params={{ sort: "CREATED_AT_DESC" }}
						/>
					</div>
				),
			},
			{
				title: t("contact.tabs.service_desk.label", "Servicedesk"),
				id: "service_desk",
				icon: <SupportIcon color="var(--textColor)" />,
				enabled: haveServiceDesk,
				count: counts[String(serviceDeskBoard?.id)],
				content: (
					<div style={{ marginTop: "1.0000rem", position: "relative" }}>
						<Miniboard2
							board={storeBoards[serviceDeskBoard?.id]}
							board_id={serviceDeskBoard?.id}
							contact_id={contact.id}
							contact={contact}
							// setCount={this.setCounts.bind(this, serviceDeskBoard?.id)}
							params={{ sort: "CREATED_AT_DESC" }}
						/>
					</div>
				),
			},
			{
				title: t("contact.tabs.offers.label", "Offerter"),
				id: "offers",
				ids: ["offers", "fortnox_offers"],
				icon: BlogMajor,
				loading: isLoadingDocumentGroups,
				count: (() => {
					if (counts.fortnox?.offers) return counts.fortnox?.offers;
					return counts[`${account.sales_board_id}_offerter`];
				})(),
				enabled: haveSalesBoard || (account.fortnox_integration && contact?.fortnox_customer_id && fortnoxScopes.includes("order")),
				content: (
					<DocumentListsTabsWrapper
						tabs={[
							{
								title: t("contact.tabs.fortnox_offers.label", "Fortnox offerter"),
								badge: <Badge color={Colors.green}>{counts.fortnox?.offers}</Badge>,
								id: "fortnox_offers",
								loading: isLoadingCounts,
								icon: BlogMajor,
								content: <FortnoxOffersContactList contact={contact} history={history} />,
								enabled:
									account.fortnox_integration &&
									contact?.fortnox_customer_id &&
									fortnoxScopes.includes("order") &&
									!account.hidden_contact_tabs?.includes("fortnox_offers"),
							},
							{
								title: t("contact.tabs.offerts.label", "Offerter"),
								badge: <Badge color={Colors.green}>{counts[`${account.sales_board_id}_offerter`]}</Badge>,

								id: "offers",
								icon: BlogMajor,
								content: (
									<DocumentListSheetContent
										noHeader
										createButtonTitle={t("contact.tabs.actions.new_quote", "Ny offert")}
										column={getColumn(account.sales_board_id, "offerter")}
										contact={contact}
										board={getBoard(account.sales_board_id)}
										refresh={refreshCounts}
										noSheet
										loading={isLoadingDocumentGroups}
										history={history}
									/>
								),
								enabled: haveSalesBoard && getColumn(account.sales_board_id, "offerter"),
							},
						]}
					/>
				),
			},
			{
				title: t("contact.tabs.orders.label", "Ordrar"),
				id: "orders",
				ids: ["orders", "fortnox_orders"],
				icon: OrdersMajor,
				count: (counts.fortnox?.orders || 0) + (counts.orders || 0),

				content: (
					<DocumentListsTabsWrapper
						tabs={[
							{
								title: t("contact.tabs.fortnox_orders.label1", "Fortnox ordrar"),
								badge: <Badge color={Colors.green}>{counts.fortnox?.orders}</Badge>,
								id: "fortnox_orders",
								loading: isLoadingCounts,
								icon: BlogMajor,
								content: <FortnoxOrdersContactList contact={contact} history={history} />,
								enabled: contact?.fortnox_customer_id && fortnoxScopes.includes("order") && !account.hidden_contact_tabs?.includes("fortnox_orders"),
							},
							{
								title: t("contact.tabs.orders.label", "Ordrar"),
								badge: <Badge color={Colors.green}>{counts?.orders}</Badge>,
								loading: isLoadingCounts,
								id: "orders",
								icon: BlogMajor,
								content: (
									<OrdersList
										params={{ contact_id: contact.id }}
										titleHidden
										history={history}
										inlinePrimaryAction={
											<div>
												<Button
													icon={CirclePlusMajor}
													onClick={() => {
														history.push("/admin/orders/create", { contact, state: { contact } });
													}}
													plain
												>
													{t("contact.tabs.actions.new_order", "Ny order")}
												</Button>
											</div>
										}
									/>
								),
								roles: ["ROLE_ORDER_LIST", "ROLE_ORDER_ALL_LIST"],
								enabled: !account.hidden_contact_tabs?.includes("orders"),
							},
						]}
					/>
				),
			},
			{
				title: t("contact.tabs.fortnox_invoices.label", "Fakturor"),
				id: "fortnox_invoices",
				icon: ReceiptMajor,
				count: counts.fortnox?.invoices,
				loading: isLoadingCounts,
				enabled: !!account.fortnox_integration && contact?.fortnox_customer_id,
				content: <FortnoxInvoicesContactList contact={contact} history={history} />,
			},
			{
				title: t("contact.tabs.fortnox_contracts.label", "Avtalsfakturering"),
				id: "fortnox_contracts",
				icon: LegalMajor,
				count: counts.fortnox?.contracts,
				loading: isLoadingCounts,
				enabled: !!account.fortnox_integration && contact?.fortnox_customer_id,
				content: <FortnoxBillingContractsContactList contact={contact} history={history} />,
			},

			{
				title: t("contact.tabs.contracts.label", "Avtal"),
				id: "contracts",
				icon: NoteMajor,
				loading: isLoadingDocumentGroups,
				count: (() => {
					const group = documentGroups && documentGroups.find((g) => g.board_id === account.sales_board_id && g.column_title === "Avtal");

					return group && group.count;
				})(),
				enabled: haveSalesBoard && getColumn(account.sales_board_id, "avtal"),
				content: getColumn(account.sales_board_id, "avtal") ? (
					<DocumentListSheetContent
						noHeader
						createButtonTitle={t("contact.tabs.actions.new_deal", "Nytt avtal")}
						column={getColumn(account.sales_board_id, "avtal")}
						contact={contact}
						board={getBoard(account.sales_board_id)}
						refresh={refreshCounts}
						noSheet
						loading={isLoadingDocumentGroups}
						history={history}
					/>
				) : (
					<React.Fragment>
						<StyledDocumentItem style={{ height: "125px" }} />
						<StyledDocumentItem style={{ height: "100px" }} />
						<StyledDocumentItem style={{ height: "125px" }} />
					</React.Fragment>
				),
			},

			{
				title: t("contact.tabs.documents.label", "Dokument"),
				id: "documents",
				icon: <DocumentIcon />,
				loading: isLoadingDocumentGroups,
				count:
					documentGroups?.reduce((acc, g) => {
						if (g.board_id === account.sales_board_id && (g.column_title === "Avtal" || g.column_title === "Offerter")) {
							return acc;
						}

						return acc + g.count;
					}, 0) || 0,
				content: (
					<ContactDocumentGroups
						contact={contact}
						refresh={refreshCounts}
						documentGroups={
							documentGroups &&
							documentGroups.filter((g) => {
								if (g.board_id === account.sales_board_id && (g.column_title === "Avtal" || g.column_title === "Offerter")) return false;

								return true;
							})
						}
						loading={isLoadingDocumentGroups}
						history={history}
					/>
				),
			},
		];
	}, [
		setTab,
		contact,
		t,
		external,
		gotoContact,
		location,
		history,
		account,
		storeBoards,
		counts,
		documentGroups,
		isLoadingDocumentGroups,
		isLoadingCounts,
		refreshCounts,
		getColumn,
		axiconData,
	]);

	const enabledTabs = tabs.filter((tab: any) => {
		return checkUserRoles(tab.roles) && (!("enabled" in tab) || tab.enabled);
	});
	const { visibleTabs, hiddenTabs }: any = enabledTabs.reduce(
		(acc: any, tab: any) => {
			if (account?.hidden_contact_tabs?.includes(tab.id)) acc.hiddenTabs.push(tab);
			else acc.visibleTabs.push(tab);

			return acc;
		},
		{ visibleTabs: [], hiddenTabs: [] }
	);

	const [selectedTabId, setSelectedTabId] = useState<string>(
		(() => {
			const url = new URL(document.URL);
			const hash = url.hash?.replace("#", "");
			if (hash) return hash;

			return visibleTabs[0]?.id;
		})()
	);

	const restTab = useMemo(
		() => ({
			title: t("contact.tabs.rest.label", "Övrigt"),
			id: "rest",
			icon: <HorizonalDots />,
			content: (
				<>
					<div style={{ position: "relative" }}>
						<RelatedResources>
							{!!hiddenTabs?.length &&
								hiddenTabs?.map((tab) => {
									const count = typeof tab.count === "function" ? tab.count() : tab.count;
									return (
										<LegacyCard.Section key={tab.id}>
											<ExpandableSection
												key={tab.id}
												icon={tab.icon}
												// link={boardLink}
												title={tab.title}
												resourceName={{}}
												onExpand={() => {}}
												count={count && <Badge color={Colors.yellow}>{count}</Badge>}
												history={history}
											>
												{tab.content}
											</ExpandableSection>
										</LegacyCard.Section>
									);
								})}
						</RelatedResources>
					</div>
				</>
			),
		}),
		[hiddenTabs, history, t]
	);

	const visibleTabsAndRest = [...visibleTabs, restTab];
	const selectedTab = visibleTabsAndRest.find((tab) => tab.id === selectedTabId);

	history.listen(({ hash }) => {
		const newHash = hash?.replace("#", "");
		if (newHash && visibleTabsAndRest.find((tab) => tab.id === newHash)) setSelectedTabId(newHash);
	});

	return (
		<MainWrapper style={style}>
			<ContactTabsBar tabs={visibleTabsAndRest} activeTabId={selectedTabId} onChange={setTab}>
				{children}
			</ContactTabsBar>

			<div key={selectedTab?.id}>{selectedTab?.content}</div>

			{/* <div style={{ display: this.state.activeTabId === restTab.id ? "block" : "none" }}>{restTab.content}</div> */}
		</MainWrapper>
	);
};
export default ContactTabs;
