import { Tooltip } from "@shopify/polaris";
import React, { Component } from "react";
import moment from "moment";
import { ClockIcon } from "../icons";
import ProfileAvatar from "./ProfileAvatar";
import HeartbeatIndicator from "./HeartbeatIndicator";
import { store } from "../store";

moment.locale(store.getState().user.locale, {
	relativeTime: {
		future: "in %s",
		past: "%s",
		s: "sekunder",
		ss: "%ss",
		m: "en minut",
		mm: "%dm",
		h: "en timme",
		hh: "%dh",
		d: "en dag",
		dd: "%dd",
		M: "en månad",
		MM: "%dM",
		y: "ett år",
		yy: "%dY",
	},
});

class NotificationItem extends Component {
	onClick() {
		const { url } = this.props.notification;
		if (url) {
			if (url.includes("/api/")) {
				window.open(url, "_blank");
				return;
			}
			window.location.href = url;
		}
	}

	render() {
		const { /* history, */ style } = this.props;
		const { content, unread, updated_at, creator /* data, id, url */ } = this.props.notification;
		return (
			<div className="notification-item" onClick={this.onClick.bind(this)} style={style}>
				<Tooltip content={moment(updated_at).format("YYYY-MM-DD HH:mm")}>
					<div className="date">
						<ClockIcon />
						<span className="date-text oneline">{moment(updated_at).locale(store.getState().user.locale).fromNow()}</span>
					</div>
				</Tooltip>
				<ProfileAvatar user={creator}> {unread && <HeartbeatIndicator />}</ProfileAvatar>
				<span className="title">{content}</span>
			</div>
		);
	}
}

export default NotificationItem;
