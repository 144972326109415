import { CategoriesMajor, ListMajor, OrdersMajor, ProductsMajor, TroubleshootMajor } from "@shopify/polaris-icons";
import React from "react";
import { withTranslation } from "react-i18next";
import Settings from "src/js/components/Settings/Settings";
import Page from "src/js/components/page";

const ArticleSettings = ({ t }) => {
	const sections = [
		{
			items: [
				{
					title: t("orders.list.label", "Ordrar"),
					description: t("orders.list.description", "Visa och hantera ordrar"),
					icon: OrdersMajor,
					path: "/admin/orders",
				},
				{
					title: t("articles.list.label", "Artiklar"),
					description: t("articles.list.description", "Visa och hantera artiklar"),
					icon: ProductsMajor,
					path: "/admin/articles",
				},
				{
					title: t("articles.categories.label", "Kategorier"),
					description: t("articles.categories.description", "Visa och hantera kategorier"),
					icon: CategoriesMajor,
					path: "/admin/categories",
				},
				{
					title: t("articles.custom_fields.label", "Egna fält"),
					description: t("articles.custom_fields.description", "Visa och hantera egna fält"),
					icon: TroubleshootMajor,
					path: "/admin/customfields",
				},
				{
					title: t("articles.pricelists.label", "Prislistor"),
					description: t("articles.pricelists.description", "Visa och hantera prislistor"),
					icon: ListMajor,
					path: "/admin/pricelists",
				},
			],
		},
	];

	return (
		<Page title={t("articles.settings.label", "Artikelregister & inställningar")}>
			<Settings sections={sections} />
		</Page>
	);
};
export default withTranslation(["articles", "common"])(ArticleSettings);
