import React, { useEffect, useState } from "react";
import Modal from "../components/modal";
import TextField from "../components/TextField";
import BASE_URL from "../API/BASE_URL";
import axios from "axios";
import Colors from "../Colors";
import { validateEmail } from "../Utilities";
import Toast, { getErrorMessage } from "../components/Toast";

const FindAccountModal = ({ open, onClose, email }) => {
	const [search, setSearch] = useState(email || "");
	const [error, setError] = useState<any>(null);
	const [message, setMessage] = useState<any>(null);

	useEffect(() => {
		if (open) setSearch(email);
		else {
			setSearch("");
			setError(null);
			setMessage(null);
		}
	}, [email, open]);

	const handleSearch = async () => {
		try {
			setError(null);

			const res = await axios.post(`${BASE_URL}/api/authentication/account/find.json`, { email: search });
			const message = res.data.message;
			Toast.success(message);

			setMessage(message);

			return res;
		} catch (error) {
			console.error("error:", error);
			setMessage(null);
			setError(getErrorMessage(error));
		}
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			title="Hitta ditt företags login"
			primaryAction={{
				content: "Skicka inloggnings länk",
				onAction: handleSearch,
				disabled: !search || !validateEmail(search),
			}}
		>
			{message && <p style={{ color: Colors.green }}>{message}</p>}
			<TextField autoFocus label="E-postadress" value={search} onChange={setSearch} error={error} />
		</Modal>
	);
};
export default FindAccountModal;
