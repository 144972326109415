import React, { Component } from "react";
import { LegacyCard, TextContainer, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";

class SkeletonSimpleCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<LegacyCard sectioned>
				<TextContainer>
					<SkeletonDisplayText size="small" />
					<SkeletonBodyText lines={this.props.lines} />
				</TextContainer>
			</LegacyCard>
		);
	}
}

SkeletonSimpleCard.defaultProps = {
	lines: 3,
};

export default SkeletonSimpleCard;
