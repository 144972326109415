import { Button, Layout } from "@shopify/polaris";
import React, { useState } from "react";
import styled from "styled-components";

import { WithTranslation, withTranslation } from "react-i18next";
import ScriveParty from "./ScriveParty";
import ScrivePartyModal from "./ScrivePartyModal";
import { defaultPartyFields } from "../Utilities";

type ScrivePartyListProps = {
	document: ScriveDocumentType;
} & WithTranslation;

const ScrivePartyList = ({ document, t }: ScrivePartyListProps) => {
	const newEmptyParty: ScrivePartyType = {
		is_signatory: true,
		signatory_role: "signing_party",
		fields: defaultPartyFields,
		sign_order: 1,
		attachments: [],
	} as unknown as ScrivePartyType;
	const [open, setOpen] = useState(false);
	const handleOpenNewPartyModal = () => {
		setOpen(true);
	};
	const handleCloseNewPartyModal = () => {
		setOpen(false);
	};

	return (
		<Wrapper>
			<Layout>
				<Layout.Section>
					{document?.parties
						?.sort((a, b) => a.sign_order - b.sign_order)
						?.map((party, index) => (
							<ScriveParty document={document} key={party.id} party={party} />
						))}
				</Layout.Section>
				<Layout.Section secondary>
					{document.status === "preparation" && (
						<Button primary onClick={handleOpenNewPartyModal}>
							{t("scrive.actions.add_part", "Lägg till ny part") as string}
						</Button>
					)}
				</Layout.Section>
			</Layout>

			<ScrivePartyModal party={newEmptyParty} open={open} onClose={handleCloseNewPartyModal} />
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(ScrivePartyList);

const Wrapper = styled.div``;
