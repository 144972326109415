import styled from "styled-components";

export const HeaderWrapper = styled.div`
	margin-top: 30px;
	padding-bottom: 1rem;
`;
export const InfoWrapper = styled.div`
	--p-border-radius-base: 6px;
	--p-text-disabled: var(--textColor);

	.Polaris-Button {
		&,
		* {
			transition-property: background-color, color, box-shadow, filter;
			transition-duration: 250ms;
		}
	}

	[data-buttongroup-segmented="true"] > :first-child .Polaris-Button {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	[data-buttongroup-segmented="true"] > :last-child .Polaris-Button {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;
