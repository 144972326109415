import React, { Component } from "react";
import { Link, Labelled, LegacyStack, Icon } from "@shopify/polaris";
import styled from "styled-components";
import { CirclePlusMajor, MarketingMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import ResourcePicker from "./resource_picker.js";
import CampaignModal from "./AdCampaignModal.js";

class AdCampaignSelector extends Component {
	constructor(props) {
		super(props);
		this.state = { campaign: props.campaign || {} };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ campaign: props.campaign || {} });
	}

	handleOpenNewModal() {
		this.setState({ campaignModalOpen: true });
	}

	onChange(campaign) {
		if (!campaign) {
			this.props.onChange(campaign);
			this.setState({ campaign, campaignModalOpen: false });
		} else if (!campaign.id && !campaign.external_id) {
			this.setState({ campaignModalOpen: true });
		} else {
			this.setState({ campaign, campaignModalOpen: false });
			this.props.onChange(campaign);
		}
	}

	render() {
		return (
			<Labelled label={this.props.label} error={this.props.error}>
				<div style={{ display: "flex", gap: "1.2500rem", marginBottom: "0.6250rem" }}>
					<CampaignSelectWrapper disabled={this.props.disabled} onClick={(event) => event.stopPropagation()}>
						<LegacyStack wrap={false} spacing="tight">
							<Icon source={MarketingMajor} color="inkLightest" />
							{this.props.disabled || this.props.link ? (
								this.props.link ? (
									<Link onClick={this.props.onClick}>{this.state.campaign.title}</Link>
								) : (
									<span>{this.state.campaign.title}</span>
								)
							) : (
								this.props.ad_account && (
									<ResourcePicker
										caption={this.props.t("ad.campaign.caption", "Ingen kampanj")}
										resource={
											"ans_accounts/" +
											this.props.ad_account.id +
											"/ans_campaigns.json" +
											(this.props.contact ? "?contact_id=" + this.props.contact.id : "")
										}
										fullWidth={false}
										allowCreate
										item={this.state.campaign}
										onChange={this.onChange.bind(this)}
										plain
										clearOption={this.props.clearable ? this.props.t("ad.campaign.clear_option", "Ingen kampanj") : null}
										id_handle="external_id"
										resource_handle="ad_campaigns"
										label_handle="title"
										resourceName={{
											singular: this.props.t("ad.campaign.singular", "kampanj"),
											plural: this.props.t("ad.campaign.plural", "kampanjer"),
										}}
									/>
								)
							)}
						</LegacyStack>
					</CampaignSelectWrapper>
					{this.props.extraCreateNewButton && (
						<CampaignSelectWrapper disabled={this.props.disabled} onClick={this.handleOpenNewModal.bind(this)}>
							<LegacyStack wrap={false} spacing="tight">
								<Icon source={CirclePlusMajor} color="inkLightest" />
								<span>{this.props.t("ad.actions.create", "Skapa ny")}</span>
							</LegacyStack>
						</CampaignSelectWrapper>
					)}
				</div>
				<CampaignModal
					open={this.state.campaignModalOpen}
					campaign={{
						title: this.props.title,
					}}
					ad_account={this.props.ad_account}
					contact={this.props.contact}
					onClose={() => {
						this.setState({ campaignModalOpen: false });
					}}
					onCreate={this.onChange.bind(this)}
				/>
			</Labelled>
		);
	}
}
export default withTranslation(["ad", "common"], { withRef: true })(AdCampaignSelector);

export const CampaignSelectWrapper = styled.span`
	display: flex;
	width: max-content;
	box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
	padding: 0.625rem;
	cursor: pointer;
	pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;
