export default (state = [], action) => {
	switch (action.type) {
		case "SET_GOALS":
			return action.goals;
		case "REMOVE_GOAL":
			return state.filter((g) => String(g.id) !== String(action.goal.id));
		default:
			return state;
	}
};
