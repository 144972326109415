import i18next from "i18next";

const getOptions = () => ({
	label: i18next.t("user.label", "Användare"),
	value: "user",
	options: [
		{
			label: i18next.t("user.fields.id.label", "Id"),
			value: "user.id",
		},
		{
			label: i18next.t("user.fields.name.label", "Namn"),
			value: "user.name",
		},
		{
			label: i18next.t("user.fields.full_name.label", "fullständiga namn"),
			value: "user.fullname",
		},
		{
			label: i18next.t("user.fields.email.label", "E-post"),
			value: "user.email",
		},
		{
			label: i18next.t("user.fields.phone.label", "Telefonnummer"),
			value: "user.phone",
		},
		{
			label: i18next.t("user.fields.initials.label", "Initialer"),
			value: "user.initials",
		},
		{
			label: i18next.t("user.fields.profile.label", "Profil bild"),
			value: "user.avatar",
		},
	],
});

export default getOptions;
