import { WithTranslation, withTranslation } from "react-i18next";
import ItemCard from "src/js/components/ItemCard";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import truncateString from "src/js/Utilities/truncateString";
import { currencyFormatter } from "src/js/Utilities";
import { InfoWrapper } from "../CustomFields/styles";
import ResourceListManager from "../ResourceListManager";
import { useContext } from "react";
import PricelistContext from "./context";
import Banner from "src/js/components/banner";

type PricelistArticlesProps = {
	history: any;
} & WithTranslation;

const PricelistArticles = ({ history, t }: PricelistArticlesProps) => {
	const { id } = useContext(PricelistContext);

	const renderItem = (item: any) => {
		const articlePricelist = item.article_pricelists.find((p: any) => p.pricelist_id == id);
		return (
			<ItemCard
				title={
					<>
						{item.title}
						{item.connected && <Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>}
					</>
				}
				leftColumn={[
					{
						label: t("articles.status", "Status"),
						value: item.disabled ? t("common.inactive", "Inaktiv") : t("common.active", "Aktiv"),
					},
					{
						label: t("articles.articleNumber", "Artikelnummer"),
						value: item.number,
					},
					{
						label: t("articles.description", "Beskrivning"),
						value: truncateString(item.description ?? "", 20),
					},
					{
						label: t("articles.categories", "Kategorier"),
						value: item.categories_display,
					},
					{
						label: t("articles.pricelists", "Prislistor"),
						value: item.pricelists_display,
					},
				]}
				rightColumn={[
					/* {
						label: t("articles.purchasePrice", "Inköpspris"),
						value: currencyFormatter({ value: item.in_price ?? 0 }),
					}, */
					{
						label: t("articles.pricelistPrice", "Prislistpris"),
						value: currencyFormatter({ value: articlePricelist?.price ?? 0 }),
					},
					{
						label: t("articles.pricelistTB1", "TB1"),
						value: currencyFormatter({ value: articlePricelist?.tb1 ?? 0 }),
					},
					{
						label: t("articles.pricelistTB2", "TB2"),
						value: currencyFormatter({ value: articlePricelist?.tb2 ?? 0 }),
					},
				]}
			/>
		);
	};

	return (
		<InfoWrapper>
			<Banner type="info" title={t("pricelists.articles.title", "Artiklar i denna prislista")} hideIcon dismissible>
				<p>{t("pricelists.articles.description", "Här hittar du alla artiklar som är kopplade till denna prislista.")}</p>
				<p>
					{t(
						"pricelists.articles.description2",
						"För att koppla en artikel till denna prislista, gå till artikeln och lägg till en ny prislista rad."
					)}
				</p>
			</Banner>

			<ResourceListManager
				appliedFilters={[
					{
						key: "disabled",
						value: "0",
					},
				]}
				onClick={(item) => {
					history.push(`/admin/articles/${item.id}`, {
						data: item,
					});
				}}
				promotedBulkActions={[]}
				params={{ pricelist_id: id }}
				resourceHandle={"articles"}
				history={history}
				renderItem={renderItem}
				resourceUrl="/api/articles.json"
				resourceName={{ singular: t("articles.singular", "Artikel"), plural: t("articles.plural", "Artiklar") }}
				addRemoveUrl={`/api/categories/${id}/articles.json`}
			/>
		</InfoWrapper>
	);
};
export default withTranslation(["pricelist", "common"])(PricelistArticles);
