import moment from "moment";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import BarGraph from "src/js/components/Graphs/BarGraph";
import { currencyFormatter } from "src/js/Utilities";

type TicketsGraphProps = {
	data: any;
	t: any;
	loading?: boolean;
	onChangeYear?: (year: string) => void;
	selectedYear?: string;
	colors?: {
		[key: string]: string;
	};
};

const TicketsGraph = ({ data, t, loading, onChangeYear, selectedYear: propsSelectedYear, colors }: TicketsGraphProps) => {
	const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

	const labels = moment.monthsShort();
	const series = [{ name: "Inkommna ärenden", data: data?.[selectedYear] || [], color: colors?.new }];

	const yearOptions = (() => {
		const years = Object.keys(data || {}).filter((k) => !Number.isNaN(parseInt(k)));

		if (!years.includes(moment().format("YYYY"))) years.push(moment().format("YYYY"));
		// if (!years.includes(moment().subtract(1, "year").format("YYYY"))) years.push(moment().subtract(1, "year").format("YYYY"));

		return years.sort((a, b) => parseInt(b) - parseInt(a)).map((year) => ({ value: year, label: year }));
	})();

	useEffect(() => {
		if (propsSelectedYear) {
			setSelectedYear(propsSelectedYear);
		}
	}, [propsSelectedYear]);

	return (
		<BarGraph
			// key={`ticketsGraph-${selectedYear}`}
			showLegends={false}
			settings={{ grouped: true, stacked: false }}
			data={{
				series,
				labels,
			}}
			title={t("graph.tickets.title", "Ärenden")}
			loading={!!loading}
			headerRight={
				<Select
					value={selectedYear}
					onChange={(e) => {
						setSelectedYear(e);
						onChangeYear?.(e);
					}}
					options={yearOptions}
				/>
			}
			formatter={(value) => {
				return currencyFormatter({
					value,
					style: "decimal",
					maximumFractionDigits: 0,
				});
			}}
			numberOfLegendsToShow={5}
		/>
	);
};

export default withTranslation(["graph"])(TicketsGraph);
