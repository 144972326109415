import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Spinner } from "@shopify/polaris";
import { colorToRgba } from "src/js/Colors";
import { LeadsIcon, NoAnswerIcon, NotInterestedIcon, ReturnIcon } from "src/js/icons";
import styled from "styled-components";
import AdditionCommentAction from "src/js/components/Activities/AdditionCommentAction";

const StyledOutcomeButton = styled.button.attrs({
	className: "Polaris-Button",
})`
	&&&&&&&& {
		background: ${({ backgroundColor }) => backgroundColor};
		color: ${({ color }) => color || "var(--textColor)"};
		opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
		pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
		display: flex;
		gap: 0.5000rem;
		align-items: center;

		.spinner {
			/* margin-right: 0.5000rem; */
		}
		.Polaris-Icon {
			/* margin-right: 0.5000rem; */
			height: unset;
			aspect-ratio: 1/1;
		}
		.Polaris-Spinner {
			height: 100%;
			min-height: unset;
		}
	}
`;

const getIcon = (icon, color) => {
	switch (icon) {
		case "CircleMinusMajor":
			return <NoAnswerIcon color={color} />;
		case "CancelMajor":
			return <NotInterestedIcon color={color} />;
		case "PhoneOutMajor":
		case "PhoneOutMajorTwotone":
			return <ReturnIcon color={color} />;
		case "MobileAcceptMajor":
			return <LeadsIcon color={color} />;
		default:
			return null;
	}
};

const OutcomeButton = ({ onSubmit, key, t, icon: propsIcon, status = {}, comment, onClose, activity, onClick, contacts = [], loadingContact }) => {
	const [loading, setLoading] = useState();
	const [open, setOpen] = useState(false);

	const handleSubmit = async (form = comment) => {
		setOpen(false);
		setLoading(true);
		await onSubmit(status, form);
		setLoading(false);
	};

	const handleOpen = async () => {
		if (onClick) {
			setLoading(true);
			await onClick?.();
		}
		if (!activity) {
			handleSubmit();
			return;
		}

		setTimeout(() => setOpen(true), 0);
	};
	const handleClose = () => {
		setOpen(false);
		onClose?.();
		setLoading(false);
	};

	const icon = propsIcon || getIcon(activity?.icon, status?.color);

	return (
		<>
			<StyledOutcomeButton key={key} onClick={handleOpen} color={status?.color} backgroundColor={colorToRgba(status?.color, 0.45)} disabled={loading}>
				{loading ? <Spinner size="small" /> : null}
				{status?.label}
				{icon}
			</StyledOutcomeButton>

			{activity && (
				<AdditionCommentAction
					activity={activity}
					open={open}
					onSuccess={handleSubmit}
					onClose={handleClose}
					comment={comment}
					contacts={contacts}
					disabled={loadingContact}
					loading={loadingContact}
				/>
			)}
		</>
	);
};
export default withTranslation(["activities", "common"])(OutcomeButton);
