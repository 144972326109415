import React, { useState } from "react";
import Sheet from "src/js/components/sheet";
import TaskSheet from "../Agenda/TaskSheet";
import useContact from "src/js/hooks/useContact";
import BoardHelper from "../Workspaces/BoardHelper";
import CalendarModalContent from "../Workspaces/components/CalendarModal/CalendarModalContent";
import { Scrollable } from "@shopify/polaris";
import { useSelector } from "react-redux";
import Modal from "src/js/components/modal";
import RowColumnForm from "../Workspaces/components/RowColumnForm";
import { useHistory, useParams } from "react-router-dom";
import RowModal from "../Workspaces/components/RowModal";
import ColumnActionModal from "../Workspaces/components/ColumnActionModal";
import EmailListSheetContent from "src/js/components/Emails/EmailListSheetContent";
import CollapsibleCard from "../CollapsibleCard";
import styled from "styled-components";
import Button from "src/js/components/Button";

const Modals = ({ handleModalClose, handleModalOpen, isModalOpen, handleToggleModal, setModalsData, modalsData }) => {
	const { id }: any = useParams();
	const { t, contact, documentGroups, refetchDocumentGroups, refreshCounts } = useContact(id);
	const user = useSelector((state: any) => state.user);
	const account = useSelector((state: any) => state.account);
	const [recentlyUploadRestdocumentRow, setRecentlyUploadRestdocumentRow] = useState(null);
	const history = useHistory();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { board, boardRow, boardColumn } = modalsData;

	const row = {
		title: "",
		column_values: {},
	};

	const documentGroupsGroupedByBoard = documentGroups?.reduce((acc, group) => {
		if (!acc[group.board_id]) {
			acc[group.board_id] = {
				board: group.board,
				items: [],
			};
		}

		acc[group.board_id].items.push(group);

		return acc;
	}, {});

	const onClickDocumentGroup = (group) => () => {
		setModalsData({ board: group.board, boardRow: null, boardColumn: group.column });

		if (group.board_id === account.upload_board_id) {
			handleToggleModal("restDocument", group.board_id === account.upload_board_id);
		} else {
			handleToggleModal("columnModal", true);
		}

		handleToggleModal("document", false);
	};

	return (
		<>
			<TaskSheet
				open={isModalOpen("task")}
				onClose={() => {
					handleModalClose("task");
				}}
				options={{
					contact: [id],
				}}
				onComplete={() => {
					handleModalClose("task");
					const refreshEvent = new CustomEvent("refreshTasks", {});
					window.dispatchEvent(refreshEvent);
				}}
			/>
			<Sheet
				open={isModalOpen("calendar")}
				onClose={(row) => {
					handleModalClose("calendar");
				}}
				title={t("calendar.actions.new", "Nytt kalenderinlägg")}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "calc(100% - 56px)",
					}}
				>
					<Scrollable style={{ flex: 1 }}>
						<CalendarModalContent
							row={row}
							contact={contact}
							board={user.calendar_board}
							updateCell={(column, data) => {
								BoardHelper.onUpdateValue(row, column, data);
							}}
							// changeRowTitle={this.changeRowTitle.bind(this, row)}
							onClose={(row) => {
								handleModalClose("calendar");
							}}
							onComplete={(row) => {
								handleModalClose("calendar");
								const refreshEvent = new CustomEvent("refreshTasks", {});
								window.dispatchEvent(refreshEvent);
							}}
						/>
					</Scrollable>
				</div>
			</Sheet>

			{board && (
				<RowModal
					open={isModalOpen("rowModal")}
					board={board}
					contact={contact}
					onUpdateRow={() => {}}
					onUpdateValue={() => {}}
					defaultValues={[]}
					getContact={() => {}}
					onClose={() => {
						handleModalClose("rowModal");
					}}
					onCreate={() => {
						// this.fetchResources(this.state.id, true);
					}}
				/>
			)}
			{boardColumn && (
				<ColumnActionModal
					open={isModalOpen("columnModal")}
					column={boardColumn}
					board={board}
					contact={contact}
					onUpdateRow={() => {}}
					onUpdateValue={() => {}}
					defaultValues={[]}
					getContact={() => {}}
					onClose={() => {
						handleModalClose("columnModal");
					}}
				/>
			)}

			<Modal
				large
				open={isModalOpen("document")}
				onClose={() => {
					handleModalClose("document");
					setModalsData({ board: null, boardRow: null, boardColumn: null });
				}}
				title={t("common.related_resources.documents.title", "Nytt dokument")}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng") as string,
						onAction: () => {
							handleModalClose("document");
						},
					},
				]}
			>
				<Modal.Section>
					<Wrapper>
						{documentGroupsGroupedByBoard &&
							Object.entries(documentGroupsGroupedByBoard)?.map(([boardId, data]: any) => {
								const { board, items } = data || {};

								return (
									<CollapsibleCard key={boardId} title={board?.title} open={Object.keys(documentGroupsGroupedByBoard)?.length <= 3}>
										<InnerWrapper>
											{items?.map((group) => {
												return (
													<DocumentColumn key={group.column_id} onClick={onClickDocumentGroup(group)}>
														<h1>{group.column_title}</h1>

														<Button onClick={onClickDocumentGroup(group)} primary>
															{"Välj"}
														</Button>
													</DocumentColumn>
												);
											})}
										</InnerWrapper>
									</CollapsibleCard>
								);
							})}
					</Wrapper>
				</Modal.Section>
			</Modal>

			<Modal
				open={isModalOpen("restDocument")}
				onClose={() => {
					handleModalClose("restDocument");
					setRecentlyUploadRestdocumentRow(null);
				}}
				title={t("common.related_resources.titles.rest_documents.title", "Övriga dokument")}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng") as string,
						onAction: () => {
							handleModalClose("restDocument");
							setRecentlyUploadRestdocumentRow(null);
						},
					},
				]}
			>
				<Modal.Section>
					<RowColumnForm
						onCreate={(recentlyUploadRestdocumentRow) => {
							setRecentlyUploadRestdocumentRow(recentlyUploadRestdocumentRow);
							refetchDocumentGroups();
							refreshCounts();
						}}
						contact={contact}
						column={boardColumn}
						board={board}
						row={recentlyUploadRestdocumentRow}
						history={history}
					/>
				</Modal.Section>
			</Modal>

			{user?.integrations?.find((integration) => integration.type === "microsoft365") && contact?.email && (
				<EmailListSheetContent
					open={isModalOpen("emailsImport")}
					onClose={() => {
						handleModalClose("emailsImport");
					}}
					contact={contact}
					integration={user.integrations.find((integration) => integration.type === "microsoft365")}
				/>
			)}
		</>
	);
};
export default Modals;

const Wrapper = styled.div`
	.Polaris-LegacyCard {
		background-color: var(--primary);
		/* background-color: var(--tertiary); */
	}
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
`;

const DocumentColumn = styled.div`
	transition: all 0.3s;
	display: flex;
	justify-content: space-between;
	/* margin-bottom: 0.5rem;
	margin-inline: 0.5rem; */
	padding-block: 0.5rem;
	padding-inline: 1rem;
	background-color: var(--secondary);
	align-items: center;
	cursor: pointer;
	border-radius: 0.35rem;

	&:hover {
		filter: brightness(1.2);
	}

	h1 {
		font-size: 1rem;
		font-weight: 500;
	}
`;
