import { Button, OptionList } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Popover from "../../../components/Popover";
import { store } from "../../../store";

class UserFollowersSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	toggleOpen() {
		this.setState((c) => ({ open: !c.open }));
	}

	closePopover() {
		this.setState((c) => ({ open: false }));
	}

	render() {
		const { onChange, selected = [] } = this.props;
		const { open } = this.state;

		const activator = (
			<span className="user_followers_select_btn">
				<Button onClick={this.toggleOpen.bind(this)} disclosure fullWidth>
					{!selected || !selected.length
						? this.props.t("ticket.terms.add2", "Lägg till..")
						: selected
								.map((id) => {
									const user = store.getState().users.find((u) => u.id === id);
									if (user) return user.name;
									return null;
								})
								.join(", ")}
				</Button>
			</span>
		);
		return (
			<div>
				<label className="Polaris-Label__Text">{this.props.t("ticket.terms.follower", "Följare")}</label>
				<Popover active={open} activator={activator} onClose={this.closePopover.bind(this)}>
					<OptionList
						title=""
						onChange={onChange}
						options={store.getState().users.map((u) => ({
							value: u.id,
							label: u.name,
						}))}
						selected={selected || []}
						allowMultiple
					/>
				</Popover>
			</div>
		);
	}
}

export default withTranslation(["ticket", "common"], { withRef: true })(UserFollowersSelect);
