import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import API from "src/js/API";
import i18next from "i18next";
import Toast from "src/js/components/Toast";

const WorkspacesContext = React.createContext({} as any);

export const WorkspacesContextProvider = withTranslation(["workspaces", "common"])(({ children }) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const fetched = useRef(false);

	const workspaces = useSelector((state: any) => state.workspaces);
	const controller = useRef(new AbortController());

	const fetchWorkspaces = useCallback(
		async (silent = false) => {
			controller.current.abort();
			controller.current = new AbortController();

			try {
				if (!silent) setLoading(true);

				const result = await API.get("/api/workspaces.json", {
					params: {},
					signal: controller.current.signal,
				});

				dispatch({
					type: "SET_WORKSPACES",
					workspaces: result.data.workspaces,
				});

				fetched.current = true;

				return result.data.workspaces;
			} catch (error) {
				console.error(error);
				Toast.error(error);
			} finally {
				setLoading(false);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (!fetched.current) fetchWorkspaces();
	}, [fetchWorkspaces]);

	const removeWorkspace = useCallback(
		async (workspace) => {
			dispatch({ type: "REMOVE_WORKSPACE", id: workspace?.id });

			return await API.delete("/api/workspaces/" + workspace.id + ".json", {
				params: {},
			})
				.then((result) => {
					dispatch({
						type: "REMOVE_WORKSPACE",
						id: workspace?.id,
					});

					fetchWorkspaces();

					return result.data.workspace;
				})
				.catch((error) => {
					Toast.error(error);
				});
		},
		[fetchWorkspaces, dispatch]
	);

	const updateWorkspace = useCallback(
		async (workspace) => {
			return await API.put("/api/workspaces/" + workspace.id + ".json", workspace)
				.then((result) => {
					dispatch({ type: "UPDATE_WORKSPACE", workspace: result.data.workspace });
					Toast.success(i18next.t("workspace.responses.updated1", "Uppdaterade workspace"));

					fetchWorkspaces();

					return result.data.workspace;
				})
				.catch((error) => {
					Toast.error(error);
				});
		},
		[fetchWorkspaces, dispatch]
	);

	const addWorkspace = useCallback(
		async (workspace) => {
			return await API.post("/api/workspaces.json", workspace)
				.then((result) => {
					Toast.success(i18next.t("workspace.responses.created1", "Skapade workspace"));
					dispatch({ type: "ADD_WORKSPACE", workspace: result.data.workspace });

					fetchWorkspaces();

					return result.data.workspace;
				})
				.catch((error) => {
					Toast.error(error);
				});
		},
		[fetchWorkspaces, dispatch]
	);

	const uploadToWorkspace = useCallback(
		async (id, uploads) => {
			return await API.post("/api/workspaces/" + id + "/uploads.json", { uploads })
				.then((result) => {
					fetchWorkspaces();

					return result.data.workspace;
				})
				.catch((error) => {
					Toast.error(error);
				});
		},
		[fetchWorkspaces]
	);

	const value = useMemo(
		() => ({
			workspaces,
			loading,
			fetchWorkspaces,
			removeWorkspace,
			updateWorkspace,
			uploadToWorkspace,
			addWorkspace,
		}),
		[workspaces, loading, fetchWorkspaces, removeWorkspace, updateWorkspace, uploadToWorkspace, addWorkspace]
	);

	return <WorkspacesContext.Provider value={value}>{children}</WorkspacesContext.Provider>;
});

export default WorkspacesContext;
