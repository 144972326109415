import React from "react";

const Document2Icon = ({ color = "var(--textColor)" }) => (
	<svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M28.2219 3.17676H15.8886L12.8053 0.176758H3.55526C1.85942 0.176758 0.48734 1.52676 0.48734 3.17676L0.471924 21.1768C0.471924 22.8268 1.85942 24.1768 3.55526 24.1768H28.2219C29.9178 24.1768 31.3053 22.8268 31.3053 21.1768V6.17676C31.3053 4.52676 29.9178 3.17676 28.2219 3.17676ZM28.2219 21.1768H3.55526V3.17676H11.5257L14.609 6.17676H28.2219V21.1768ZM25.1386 12.1768H6.63859V9.17676H25.1386V12.1768ZM18.9719 18.1768H6.63859V15.1768H18.9719V18.1768Z"
			fill={color}
		/>
	</svg>
);

export default Document2Icon;
