import React from "react";

const PeopleIcon = ({ color }) => (
	<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.10156 7.40674C9.03156 7.40674 10.6016 5.83674 10.6016 3.90674C10.6016 1.97674 9.03156 0.406738 7.10156 0.406738C5.17156 0.406738 3.60156 1.97674 3.60156 3.90674C3.60156 5.83674 5.17156 7.40674 7.10156 7.40674ZM7.10156 2.40674C7.93156 2.40674 8.60156 3.07674 8.60156 3.90674C8.60156 4.73674 7.93156 5.40674 7.10156 5.40674C6.27156 5.40674 5.60156 4.73674 5.60156 3.90674C5.60156 3.07674 6.27156 2.40674 7.10156 2.40674ZM7.10156 9.15674C4.76156 9.15674 0.101562 10.3267 0.101562 12.6567V14.4067H14.1016V12.6567C14.1016 10.3267 9.44156 9.15674 7.10156 9.15674ZM2.44156 12.4067C3.28156 11.8267 5.31156 11.1567 7.10156 11.1567C8.89156 11.1567 10.9216 11.8267 11.7616 12.4067H2.44156ZM14.1416 9.21674C15.3016 10.0567 16.1016 11.1767 16.1016 12.6567V14.4067H20.1016V12.6567C20.1016 10.6367 16.6016 9.48674 14.1416 9.21674ZM13.1016 7.40674C15.0316 7.40674 16.6016 5.83674 16.6016 3.90674C16.6016 1.97674 15.0316 0.406738 13.1016 0.406738C12.5616 0.406738 12.0616 0.536738 11.6016 0.756738C12.2316 1.64674 12.6016 2.73674 12.6016 3.90674C12.6016 5.07674 12.2316 6.16674 11.6016 7.05674C12.0616 7.27674 12.5616 7.40674 13.1016 7.40674Z"
			fill={color}
		/>
	</svg>
);

export default PeopleIcon;
