import { Badge, LegacyCard, PageActions, ResourceItem } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Toast from "src/js/components/Toast";
import API from "../../API";
import OrderRowInput from "../OrderRowInput";
import FortnoxTable from "./FortnoxTable";
import Banner from "../banner";
import { store } from "../../store";
import Money from "../Money";
import Page from "../page";
import FortnoxResourceListWithPagination from "./FortnoxResourceListWithPagination";

class FortnoxInvoiceView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			integration: props.integration || {},
			customer: props.customer || {},
			form: props.form || {},
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.customer) {
			this.setState({ customer: props.customer, form: { ...this.state.form, CustomerNumber: props?.customer?.CustomerNumber } });
		}
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	viewInvoice(invoice) {
		if (this?.state?.integration?.id && this?.state?.integration?.integration_id && invoice.DocumentNumber) {
			API.get(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/invoices/${invoice.DocumentNumber}.json`)
				.then((response) => {
					this.setState({ form: { ...this.state.form, ...(response.data || {}) }, manage: true });
				})
				.catch((error) => {
					Toast.error("Error", error.response?.data?.message || "Unknown error");
				});
		}
	}

	saveInvoice() {
		this.state.form = { ...this.state.form, InvoiceRows: (this?.state?.form?.InvoiceRows || []).filter((item) => Object.keys(item).length) };
		this.setState({ saving: true });
		if (this?.state?.form?.DocumentNumber) {
			API.put(
				`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/invoices/${this.state.form.DocumentNumber}.json`,
				this.state.form
			)
				.then((response) => {
					this.setState({ manage: false, form: { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					Toast.error("Error", error.response?.data?.message || "Unknown error");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		} else {
			API.post(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/invoices.json`, this.state.form)
				.then((response) => {
					this.setState({ manage: false, form: { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					Toast.error("Error", error.response?.data?.message || "Unknown error");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		}
	}

	render() {
		const { form, integration } = this.state;
		const commonFields = ["ArticleNumber", "Description", (row, value) => ({ ...row, Price: value.SalesPrice || null }), "Unit"];

		return (
			<div className="fortnox_invoice_view" style={{ paddingTop: 30 }}>
				<Page
					title={
						this.state.manage
							? !form.DocumentNumber
								? this.props.t("fortnox.invoice.titles.new", "Fortnox - Ny faktura")
								: this.props.t("fortnox.invoice.title", "Fortnox - Faktura #") + form.DocumentNumber
							: this.props.t("fortnox.invoice.titles.invoices", "Fortnox - Fakturor")
					}
					primaryAction={
						this.state.manage
							? {
									content: this.props.t("fortnox.invoice.actions.create", "Spara"),
									loading: this.state.saving,
									onAction: this.saveInvoice.bind(this),
							  }
							: {
									content: this.props.t("fortnox.invoice.actions.create", "Skapa ny faktura"),
									onAction: () => {
										this.setState({ manage: true, form: { ...this.state.form, OurReference: store.getState().user.name } });
									},
									loading: this.state.saving,
							  }
					}
					backAction={
						this.state.manage
							? {
									content: this.props.t("fortnox.invoice.breadcrumb", "Fakturor"),
									onAction: () => {
										this.setState({ manage: false, form: { OurReference: this.state?.form?.OurReference || "" } });
									},
							  }
							: undefined
					}
				>
					{this.state.manage ? (
						<div>
							<div style={{ marginBottom: 20 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th style={{ width: 100 }}>{this.props.t("fortnox.invoice.labels.our_reference", "Vår referens")}</th>
											<th style={{ width: 100 }}>{this.props.t("fortnox.invoice.labels.your_reference", "Er referens")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<OrderRowInput
													value={form.OurReference}
													onChange={this.updateForm.bind(this, "OurReference")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													value={form.YourReference}
													onChange={this.updateForm.bind(this, "YourReference")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div>
								<FortnoxTable
									columns={[
										{
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
											resource: "Articles",
											translationHandle: "fortnox.invoice.labels.artnr",
											label: "Artikelnr",
											type: "autoComplete",
											handle: "ArticleNumber",
											updateFields: commonFields,
											props: {
												style: { width: 140 },
											},
										},
										{
											translationHandle: "fortnox.invoice.labels.term",
											label: "Benämning",
											type: "autoComplete",
											resource: "Articles",
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
											handle: "Description",
											updateFields: commonFields,
										},
										{
											translationHandle: "fortnox.invoice.labels.count",
											label: "Antal",
											type: "input",
											handle: "DeliveredQuantity",
											props: {
												style: { width: 70 },
												className: "text-right",
											},
										},
										{
											translationHandle: "fortnox.common.labels.unit",
											label: "Enhet",
											type: "autoComplete",
											resource: "Units",
											handle: "Unit",
											mapper: (data) => data.map((value) => ({ ...value, Unit: value.Code })),
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/units.json`,
											props: {
												style: { width: 65 },
											},
										},
										{
											translationHandle: "fortnox.invoice.labels.a_price",
											label: "À-Pris",
											handle: "Price",
											type: "input",
											props: {
												style: { width: 70 },
												className: "text-right",
											},
										},
										{
											translationHandle: "fortnox.invoice.labels.sum",
											label: "Rad summa",
											handle: (row) => {
												return row.Price * row.DeliveredQuantity;
											},
											type: "text",
											skuffix: this.state.form.currency || "SEK",
											props: {
												style: { width: 100 },
												className: "text-right",
											},
											summary: form.InvoiceRows?.reduce((acc, row) => {
												acc +=
													parseFloat(row.Total || 0) || parseFloat(row.Price || 0) * parseFloat(row.DeliveredQuantity || row.OrderedQuantity || 0);

												return acc;
											}, 0),
										},
									]}
									data={(form?.InvoiceRows?.length && form.InvoiceRows) || Array.from(Array(1)).map(() => ({}))}
									onChange={(data) => {
										this.state.form.InvoiceRows = data.reduce((acc, curr) => {
											acc.push({ ...curr });
											return acc;
										}, []);
										this.setState({ form: this.state.form });
									}}
								/>
							</div>
							{form.Cancelled ? (
								<Banner title={this.props.t("fortnox.invoice.statuses.shredded", "Ordern är makulerad")} />
							) : form.InvoiceReference && form.InvoiceReference != "" && form.InvoiceReference != "0" ? (
								<Banner
									status="success"
									title={this.props.t("fortnox.invoice.statuses.shredded", "Faktura #{{invoiceReference}} skapad", {
										invoiceReference: form.InvoiceReference,
									})}
								/>
							) : (
								<PageActions
									secondaryActions={
										form.DocumentNumber
											? [
													{
														content: "Förhandsgranska pdf",
														onAction: () =>
															window.open(
																`/api/integrations/${integration.integration_id}/${integration.id}/invoices/${form.DocumentNumber}/preview.pdf`,
																"_blank"
															),
													},
											  ]
											: []
									}
								/>
							)}
						</div>
					) : (
						<div>
							{this.state.customer ? (
								<LegacyCard title={this.props.t("fortnox.invoice.card.customer_info.title", "Kundinformation från fortnox")} sectioned>
									<p>
										{this.props.t("fortnox.invoice.labels.customer_number", "Kundnummer")}: {this.state?.customer?.CustomerNumber || "-"}
									</p>
									<p>
										{this.props.t("fortnox.invoice.labels.submit_invoice", "Leveranssätt faktura")}:{" "}
										{this?.props?.customer?.DefaultDeliveryTypes?.Invoice || "-"}
									</p>
									<p>
										{this.props.t("fortnox.invoice.labels.invoice_template", "Fakturamall")}:{" "}
										{this?.props?.customer?.DefaultDeliveryTypes?.Invoice != "DEFAULTTEMPLATE"
											? this?.state?.customer?.DefaultTemplates?.Invoice
											: "Standard"}
									</p>
								</LegacyCard>
							) : null}
							<FortnoxResourceListWithPagination
								ref={(ref) => {
									this.resourceList = ref;
								}}
								resourceName={{
									singular: this.props.t("fortnox.invoice.singular", "faktura"),
									plural: this.props.t("fortnox.invoice.plural", "fakturor"),
								}}
								resource="Invoices"
								customer={this.props?.customer}
								integration={this.state.integration}
								renderItem={(invoice) => (
									<ResourceItem key={invoice.DocumentNumber} onClick={this.viewInvoice.bind(this, invoice)}>
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<div>
												<h3>
													#{invoice.DocumentNumber} - {invoice.OrderDate}
												</h3>
												<Money amount={invoice.Total} currency={invoice.Currency} />
												{invoice.Cancelled ? <Badge>{this.props.t("fortnox.statuses.shredded", "Makulerad")}</Badge> : null}
												{invoice.InvoiceReference && invoice.InvoiceReference != "" && invoice.InvoiceReference != "0" ? (
													<Badge status="success">
														{this.props.t("fortnox.invoice.statuses.sent", "Faktura #{{invoiceReference}} skickad", {
															invoiceReference: invoice.InvoiceReference,
														})}
													</Badge>
												) : null}
											</div>
											<div>
												{invoice.InvoiceDate && (
													<p style={{ color: "var(--textColor2)" }}>
														{this.props.t("common.text.created", "Skapad")}: {invoice.InvoiceDate}
													</p>
												)}
											</div>
										</div>
									</ResourceItem>
								)}
							/>
						</div>
					)}
				</Page>
			</div>
		);
	}
}
export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxInvoiceView);
