import React, { useContext } from "react";
import { OverviewWrapper } from "../styles";
import TextField from "src/js/components/TextField";
import { LegacyCard, FormLayout, Layout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import InfoView from "src/js/components/InfoView";
import styled from "styled-components";
import OrderContext from "../OrderContext";
import OrderRows from "./OrderRows";
import SetCustomerCompoent from "./SetCustomerCompoent";

const OrderOverview = ({ t }) => {
	const { form, setForm, isSaving, disabled } = useContext(OrderContext);

	const getChangeHandler = (field: OrderTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<OrderType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			return newForm;
		});
	};

	return (
		<Wrapper>
			<LegacyCard sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<SetCustomerCompoent />
							<TextField
								requiredIndicator
								disabled={disabled}
								label={t("orders.fields.order_date.label", "Orderdatum")}
								value={form?.order_date}
								onChange={getChangeHandler("order_date")}
								type="date"
							/>
							<TextField
								disabled={disabled}
								label={t("orders.fields.our_reference.label", "Vår referens")}
								value={form?.our_reference}
								onChange={getChangeHandler("our_reference")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.fields.your_reference.label", "Er referens")}
								value={form?.your_reference}
								onChange={getChangeHandler("your_reference")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.fields.your_order_number.label", "Ert ordernummer")}
								value={form?.your_order_number}
								onChange={getChangeHandler("your_order_number")}
							/>
						</FormLayout>
					</Layout.Section>
					<Layout.Section oneHalf>
						<TextField
							disabled={disabled}
							label={t("orders.fields.note.label", "Notering")}
							value={form?.note || ""}
							onChange={getChangeHandler("note")}
							multiline={4}
						/>
					</Layout.Section>

					<Layout.Section fullWidth>
						<OrderRows order={form} onChange={getChangeHandler} isSaving={isSaving} disabled={disabled} />
					</Layout.Section>
				</Layout>

				<InfoView
					style={{ opacity: 0.5, display: "flex", justifyContent: "end", marginTop: "5rem" }}
					details={[
						{
							label: t("orders.fields.created_at.label", "Skapad"),
							value: form?.created_at,
						},
						{
							label: t("orders.fields.updated_at.label", "Uppdaterad"),
							value: form?.updated_at,
						},
					]}
				/>
			</LegacyCard>
		</Wrapper>
	);
};

export default withTranslation(["orders", "common"])(OrderOverview);

const Wrapper = styled(OverviewWrapper)`
	&&&& {
		.info-view__labels,
		.info-view__values {
			row-gap: 3px;
		}
	}
`;
