import i18next from "i18next";

export const getcontactTabsOptions = () => {
	const contactTabsOptions = [
		{
			label: i18next.t("contact.tabs.dashboard.label", "Dashboard"),
			value: "dashboard",
		},
		{
			label: i18next.t("contact.tabs.company_and_activity.label", "Företagsinfo & Aktivitet"),
			value: "info",
		},
		{
			label: i18next.t("contact.tabs.contacts.label", "Kontakter"),
			value: "contacts",
		},
		{
			label: i18next.t("contact.tabs.activities.label", "Aktiviteter"),
			value: "activities",
		},
		{
			label: i18next.t("contact.tabs.dealings.label", "Affärer"),
			value: "dealings",
		},
		{
			label: i18next.t("contact.tabs.service_desk.label", "Servicedesk"),
			value: "service_desk",
		},
		{
			label: i18next.t("contact.tabs.offerts.label", "Offerter"),
			value: "offers",
		},
		{
			label: i18next.t("contact.tabs.fortnox_offers.label", "Fortnox offerter"),
			value: "fortnox_offers",
		},
		{
			label: i18next.t("contact.tabs.fortnox_orders.label", "Fortnox ordrar"),
			value: "fortnox_orders",
		},
		{
			label: i18next.t("contact.tabs.fortnox_invoices.label", "Fortnox fakturor"),
			value: "fortnox_invoices",
		},
		{
			label: i18next.t("contact.tabs.fortnox_contracts.label", "Fortnox avtal"),
			value: "fortnox_contracts",
		},
		{
			label: i18next.t("contact.tabs.contracts.label", "Avtal"),
			value: "contracts",
		},
		{
			label: i18next.t("contact.tabs.documents.label", "Dokument"),
			value: "documents",
		},
		{
			label: i18next.t("contact.tabs.orders.label", "Ordrar"),
			value: "orders",
		},
	].filter((tab) => !("enabled" in tab) || tab.enabled);

	return contactTabsOptions;
};
