/* eslint-disable camelcase */
import React, { useContext } from "react";
import { HeaderNavigation, Wrapper, Header } from "./styles";
import LineGraph from "./Sections/LineGraph";
import BarGraph from "./Sections/BarGraph";
import Leaderboard from "./Sections/Leaderboard";
import { GraphWrapper, SectionWrapper } from "../styles";
import ValueAndIntendedValueBarGraph from "./Sections/ValueAndIntendedValueBarGraph";
import { EmptyState, ProgressBar } from "@shopify/polaris";
import Button from "src/js/components/Button";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import Colors from "src/js/Colors";
import StatsBoxes from "./Sections/StatsBoxes";
import Select from "src/js/components/select/Select";
import OptionList from "src/js/components/OptionList/OptionList";
import { withTranslation } from "react-i18next";
import TargetDashboardContext, { TargetDashboardContextProvider } from "./Context";
import { withRouter } from "react-router-dom";
import Spinner from "src/js/components/Spinner";
import { store } from "src/js/store";

const TargetsDashboard = ({ t, history }: any) => {
	const {
		target,
		data,
		isLoading,
		disabled,
		onChangeTarget,
		targetConnectionsOptions,
		targetConnectionsIds,
		targets,
		setTargetConnectionsIds,
		targetConnections,
		handleGoNext,
		handleGoPrevious,
	} = useContext(TargetDashboardContext);

	const targetOptions = targets.map((target) => ({
		label: target.title as string,
		value: String(target.id),
	}));

	const value = targetConnections.reduce((acc, targetConnection) => acc + targetConnection.value || 0, 0);
	const intendedValue = targetConnections.reduce((acc, targetConnection) => acc + targetConnection.intended_value || 0, 0);
	const percentage = parseFloat(((value / intendedValue) * 100 || 0).toFixed(target?.number_of_decimals || 0));

	if (!targets?.length && !isLoading)
		return (
			<EmptyState
				heading={t("targets.dashboard.empty.title", "Inga målsättningar hittades")}
				image="/assets/images/empty_state/People.png"
				action={
					store.getState().user.roles.includes("ROLE_ADMIN") && {
						content: t("targets.dashboard.empty.action", "Skapa målsättning"),
						onAction: () => {
							history.push("/admin/targets/create");
						},
					}
				}
				// secondaryAction={{
				// 	content: t("targets.dashboard.empty.secondaryAction1", "Se instruktionsvideo"),
				// 	onAction: () => {},
				// }}
			/>
		);

	return (
		<Wrapper color={Colors.green}>
			<SectionWrapper gridArea={"header"}>
				<Header>
					<Select
						label={t("targets:select_target", "Välj målsättning")}
						options={targetOptions}
						value={String(target?.id)}
						onChange={onChangeTarget}
						disabled={disabled}
					/>
					<OptionList
						style={{ height: 34 }}
						popover
						label={t("targets:select_target_connection", "Välj användare/grupp")}
						options={targetConnectionsOptions}
						selected={targetConnectionsIds}
						onChange={(value) => setTargetConnectionsIds(value)}
						allowMultiple
						disabled={disabled}
					/>

					<HeaderNavigation>
						<Button disabled={!data?.previous_group_start_at || isLoading} plain icon={ChevronLeftMinor} onClick={handleGoPrevious} />

						<span>
							{data?.group_start_at || target?.group_start_at} - {data?.group_end_at || target?.group_end_at}
						</span>

						<Button disabled={!data?.next_group_start_at || isLoading} plain icon={ChevronRightMinor} onClick={handleGoNext} />
						{isLoading && <Spinner size={14} />}
					</HeaderNavigation>
				</Header>
				<ProgressBar progress={percentage} size="large" />
			</SectionWrapper>

			<SectionWrapper gridArea={"boxes_header"}>
				<StatsBoxes />
			</SectionWrapper>

			<SectionWrapper title={t("targets.dashboard.compare_against_value.graph.title", "Överblick") as string} gridArea={"value_bar_graph"}>
				<GraphWrapper>
					<ValueAndIntendedValueBarGraph />
				</GraphWrapper>
			</SectionWrapper>

			<SectionWrapper
				title={t("targets.dashboard.compare_against_group.graph.title", "Jämförelse med annan period (ackumulerad)") as string}
				gridArea={"line_graph"}
			>
				<GraphWrapper>
					<LineGraph />
				</GraphWrapper>
			</SectionWrapper>

			<SectionWrapper
				title={t("targets.dashboard.compare_against_connections.graph.title", "Jämförelse med andra användare/grupper") as string}
				gridArea={"bar_graph"}
			>
				<GraphWrapper>
					<BarGraph />
				</GraphWrapper>
			</SectionWrapper>

			<SectionWrapper title={t("targets.dashboard.lederboard.graph.title", "Leaderboard") as string} gridArea={"leaderboard"}>
				<Leaderboard />
			</SectionWrapper>
		</Wrapper>
	);
};

export default withTranslation(["targets", "common"])(
	withRouter((props) => {
		return (
			<TargetDashboardContextProvider {...props}>
				<TargetsDashboard {...props} />
			</TargetDashboardContextProvider>
		);
	})
);
