import i18next from "i18next";

const getOptions = () => ({
	label: i18next.t("account.label", "Konto"),
	value: "account",
	options: [
		{
			label: i18next.t("account.fields.id.label", "Id"),
			value: "account.id",
		},
		{
			label: i18next.t("account.fields.company.label", "Namn"),
			value: "account.company",
		},
		{
			label: i18next.t("account.fields.orgNumber.label", "Organisationsnummer"),
			value: "account.orgnr",
		},

		{
			label: i18next.t("account.fields.email.label", "E-post"),
			value: "account.email",
		},
		{
			label: i18next.t("account.fields.support_mail.label", "Support e-post"),
			value: "account.support_mail",
		},
		{
			label: i18next.t("account.fields.newsletter_mail.label", "Nyhetsbrev e-post"),
			value: "account.newsletter_mail",
		},
		{
			label: i18next.t("account.fields.transactional_mail.label", "Transaktions e-post"),
			value: "account.transactional_mail",
		},

		{
			label: i18next.t("account.fields.phone.label", "Telefonnummer"),
			value: "account.phone",
		},

		{
			label: i18next.t("account.fields.handle.label", "handle"),
			value: "account.handle",
		},

		// {
		//   label: i18next.t("account.fields.country.label", "Land"),
		//   value: "account.country",
		// }
		{
			label: i18next.t("account.fields.street.label", "Adress"),
			value: "account.street",
		},
		{
			label: i18next.t("account.fields.zip.label", "Postnummer"),
			value: "account.zip",
		},
		{
			label: i18next.t("account.fields.city.label", "Stad"),
			value: "account.city",
		},
		{
			label: i18next.t("account.fields.fiscal_year_start_date.label", "Räkenskapsår"),
			value: "account.fiscal_year_start_date",
		},
	],
});

export default getOptions;
