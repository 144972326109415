import { LegacyCard, Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import React, { useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import { InfoWrapper } from "../CustomFields/styles";
import CategoryContext from "./context";

type CategoryInfoProps = {
	history: any;
} & WithTranslation;

const CategoryInfo = ({ history, t }: CategoryInfoProps) => {
	const { form, isFetching, setForm, isSaving } = useContext(CategoryContext);

	const getChangeHandler = (field: CategoryTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<CategoryType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			return newForm;
		});
	};

	const disabled = isSaving || isFetching;

	return (
		<InfoWrapper>
			<LegacyCard sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<Labelled id="Active" label={t("categories.fields.Active.label2", "Status")}>
								<Checkbox
									checked={!form?.disabled}
									disabled={disabled}
									label={t("categories.fields.Active.label", "Aktiv")}
									onChange={(v) => getChangeHandler("disabled")(!v)}
								/>
							</Labelled>
							<TextField disabled={disabled} label={t("categories.fields.title", "Titel")} value={form?.title} onChange={getChangeHandler("title")} />
						</FormLayout>
					</Layout.Section>
				</Layout>
			</LegacyCard>
		</InfoWrapper>
	);
};
export default withTranslation(["category", "common"])(CategoryInfo);
