import { Tag } from "@shopify/polaris";
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import ComboboxFilter from "src/js/components/Filters/ComboBoxFilter";

const BoardFiltersParentTags = ({ onChangeFilters, column_id, filters, board, t }) => {
	const key = useMemo(() => `parent_tagged-${board?.id}`, [board?.id]);
	const filter = useMemo(() => filters.find((filter) => filter.key === key), [filters, key]);

	const addContactTag = (tag) => {
		const newFilters = [...filters];
		const filter = newFilters.find((filter) => filter.key === key);
		if (filter) {
			filter.value = [...(filter.value || []), tag.title];
		} else {
			newFilters.push({ key, value: [tag.title], column_id, board_id: board.id });
		}
		onChangeFilters(newFilters);
	};

	const removeTag = (tagTitle) => () => {
		const newFilters = [...filters];
		const filter = newFilters.find((filter) => filter.key === key);
		if (filter) {
			filter.value = filter.value.filter((title) => title !== tagTitle);
			if (filter.value.length === 0) {
				newFilters.splice(newFilters.indexOf(filter), 1);
			}
			onChangeFilters(newFilters);
		}
	};

	return (
		<>
			<ComboboxFilter
				onSelect={addContactTag}
				value={[]}
				resource="contacts/tags.json?type=company"
				resourceName={{
					singular: t("board.filters.tag.singular", "tagg"),
					plural: t("board.filters.tag.plural", "taggar"),
				}}
				resource_handle="tags" // id_handle="title"
				label_handle="title"
			/>
			<div style={{ display: "flex", gap: "1rem" }}>
				{filter?.value?.map((tagTitle) => (
					<Tag key={tagTitle} onRemove={removeTag(tagTitle)}>
						{tagTitle}
					</Tag>
				))}
			</div>
		</>
	);
};

export default withTranslation(["board", "common"], { withRef: true })(BoardFiltersParentTags);
