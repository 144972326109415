import React, { Component } from "react";
import { LegacyStack, Icon } from "@shopify/polaris";

import { MentionMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import RemoveCircleButton from "./RemoveCircleButton";

class Mention extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<LegacyStack wrap={false} spacing="tight">
				{this.props.icon === null ? null : React.isValidElement(this.props.icon) ? (
					React.isValidElement(this.props.icon)
				) : (
					<Icon source={this.props.icon || MentionMajor} />
				)}
				<div className="mention_title" style={{ position: "relative" }}>
					{this.props.user.name}
					{this.props.onRemove && (
						<RemoveCircleButton
							title={this.props.t("board.mentions.actions.remove", "Ta bort {{name}}?", {
								name: this.props.user.name,
							})}
							onRemove={this.props.onRemove}
						/>
					)}
				</div>
			</LegacyStack>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(Mention);
