import { Icon } from "@shopify/polaris";
import { ChevronDownMinor, ChevronRightMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";

class SortableTableHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClick() {
		if (!this.props.disabled && this.props.column) this.props.changeSort?.();
	}

	render() {
		const { column, sortBy, title, disabled } = this.props;

		return (
			<th onClick={this.onClick.bind(this)} className={(sortBy?.column?.id || sortBy?.column) === (column?.id || column) ? "active" : ""}>
				<span style={{ cursor: !disabled && column ? "pointer" : "unset", ...(this.props.style || {}), padding: "0.6250rem" }}>
					{title || column?.title}{" "}
					{!disabled && column && (
						<Icon
							source={
								(sortBy?.column?.id || sortBy?.column) !== (column?.id || column)
									? ChevronRightMinor
									: sortBy?.direction === "DESC"
									? ChevronDownMinor
									: ChevronUpMinor
							}
						/>
					)}
				</span>
			</th>
		);
	}
}
export default SortableTableHeader;
