/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import * as pdfjs from "pdfjs-dist";
import pdfJsWorker from "pdfjs-dist/build/pdf.worker.js";

type PdfViewProps = {
	src: string | null;
	width?: string;
};

const PdfViewer = ({ src, width }: PdfViewProps) => {
	const ref = useRef(null);
	pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

	useEffect(() => {
		const renderPdf = async () => {
			if (!src) return;
			try {
				const pdfData = await fetch(src).then((res) => res.arrayBuffer());
				const pdf = await pdfjs.getDocument(pdfData).promise;

				const container = ref.current as any;
				if (!container) return;

				container.innerHTML = "";

				for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
					const page = await pdf.getPage(pageNum);
					const scale = 1.5;
					const viewport = page.getViewport({ scale });

					const canvas = document.createElement("canvas");
					canvas.width = viewport.width;
					canvas.height = viewport.height;

					container.appendChild(canvas);

					const renderContext = {
						canvasContext: canvas.getContext("2d") as any,
						viewport,
					};
					await page.render(renderContext).promise;
				}
			} catch (error) {
				console.error("Error rendering PDF:", error);
			}
		};

		renderPdf();
	}, [src]);

	return <Wrapper width={width} ref={ref} />;
};

export default PdfViewer;

const Wrapper = styled.div<{ width?: any }>`
	width: ${({ width }) => width || "100%"};
	display: flex;
	gap: 0.6250rem;
	flex-wrap: wrap;
	justify-content: center;
`;
