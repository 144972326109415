import React from "react";
import { TableRow, Wrapper, Spacer, Overlay } from "./styles";
import { TableVirtuoso } from "react-virtuoso";
import { InlineError } from "@shopify/polaris";

type LeaderboardProps = {
	style?: any;
	virtuoseStyle?: any;
	data: any[];
	renderRow: (index: number, row: any) => React.ReactNode;
	headers: any[];
	loading: boolean;
	computeItemKey?: (index: number, row: any) => string | number;
	error?: string;
};

const Leaderboard = ({ style = null, virtuoseStyle, data, renderRow, headers, loading, computeItemKey, error = "" }: LeaderboardProps) => {
	const renderHeader = (header, index) => {
		if (React.isValidElement(header)) {
			return header;
		}

		if (typeof header === "string") {
			return <th key={index}>{header}</th>;
		}

		const { title, style, width } = header;

		return (
			<th key={header.key || index} style={{ width, ...style }}>
				{title}
			</th>
		);
	};

	const renderHeaders = () => {
		return headers.map((header, index) => renderHeader(header, index));
	};

	return (
		<Wrapper style={style || {}} loading={!!loading}>
			{error && (
				<Overlay>
					<InlineError fieldID="error" message={error} />
				</Overlay>
			)}
			<TableVirtuoso
				totalListHeightChanged={(height) => {
					// if (!myMessage.current) handleScroll();
				}}
				initialTopMostItemIndex={0}
				firstItemIndex={0}
				// atTopThreshold={0}
				style={{ flex: 1, width: "100%", ...(virtuoseStyle || {}) }}
				data={data}
				increaseViewportBy={{ top: 250, bottom: 250 }}
				computeItemKey={(index, row) => {
					return computeItemKey ? computeItemKey(index, row) : row?.key || row?.id || row?.user_id || row?.user?.id || index;
				}}
				fixedHeaderContent={() => (headers?.length ? <tr className="">{renderHeaders()}</tr> : undefined)}
				itemContent={renderRow}
				components={{
					TableRow: ({ children, item, style, ...rest }) => {
						return (
							<>
								<TableRow
									key={item?.id || rest["data-index"]}
									data-index={rest["data-index"]}
									data-position={rest["data-index"] + 1}
									data-item-index={rest["data-item-index"]}
									data-known-size={rest["data-known-size"]}
									// className={String(this.state.selectedRowId) === String(item?.id) ? "selected" : ""}
									// onClick={() => {
									// 	this.setState((c) => {
									// 		const selectedRow = item;
									// 		return { selectedRow, selectedRowId: selectedRow?.id, index: selectedRow ? rest["data-index"] : null };
									// 	});
									// }}
									style={{ ...style, cursor: "pointer" }}
								>
									{children}
								</TableRow>
								<Spacer>
									<td />
									<td />
									<td />
								</Spacer>
							</>
						);
					},
				}}
			/>
		</Wrapper>
	);
};
export default Leaderboard;
