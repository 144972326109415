import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import RowSheet from "./RowSheet.js";
import RowModalForm from "./RowModalForm.js";
import Modal from "../../../components/modal";

class RowModal extends Component {
	constructor(props) {
		super(props);

		this.state = { open: props.open };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, saving: false });
		}
	}

	render() {
		if (!this.props.editingDefaultValues) {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <RowSheet initialTabIndex={0} {...this.props} />;
		}

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={
					this.props.editingDefaultValues
						? this.props.t("row.terms.defualt_values", "Standardvärden")
						: !this.props.row?.id
						? `${(this.props.board && this.props.board.singular) || "rad"} i ` + this.props.board.title
						: `${this.props.t("row.terms.update", "Uppdatera")} ${
								(this.props.board && this.props.board.singular) || this.props.t("row.singular", "rad")
						  }`
				}
				primaryAction={
					!this.props.row?.id
						? {
								content: this.props.t("common.actions.save", "Spara"),
								loading: this.state.saving,
								onAction: () => {
									this.setState({ saving: true });
									this.form.saveForm().catch(() => this.setState({ saving: false }));
								},
						  }
						: null
				}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<RowModalForm
						ref={(f) => {
							this.form = f;
						}}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...this.props}
						row={this.props.row}
					/>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(RowModal);
