import Moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { getColorFromIndex } from "src/js/Colors";
import LineGraph from "src/js/components/Graphs/LineGraph";
import OptionList from "src/js/components/OptionList/OptionList";
import Select from "src/js/components/select/Select";
import numberFormatter from "src/js/Utilities/numberFormatter";
import styled from "styled-components";
import { extendMoment } from "moment-range";
import { deepMergeReplaceKey, getLocalStorage, setLocalStorage } from "src/js/Utilities";
import { Icon, Tooltip } from "@shopify/polaris";
import { AppsMajor } from "@shopify/polaris-icons";

const moment = extendMoment(Moment as any);

const SalesLineGraph = ({ data, loading, t, fromdate, todate }) => {
	const [yearType, setYearType] = useState<"calendar" | "fiscal" | null>(null); // fiscal

	const yearTypeOptions = [
		{
			label: t("sales.dashboard.year_type.calendar", "Kalenderår"),
			value: "calendar",
		},
		{
			label: t("sales.dashboard.year_type.fiscal", "Räkenskapsår"),
			value: "fiscal",
			enabled: data?.revenue?.fiscal,
		},
	].filter((opt) => !("enabled" in opt) || opt.enabled);

	useEffect(() => {
		if (!yearType && data?.revenue?.fiscal) {
			setYearType("fiscal");
		}
	}, [data?.revenue?.fiscal, yearType]);

	const { labels = [], ...sections } = useMemo(
		() => data?.revenue?.[yearType || "calendar"] || { labels: [], sections: {} },
		[data?.revenue, yearType]
	);

	const getYears = useCallback(() => {
		const range = moment.range(moment(fromdate).startOf("year"), moment(todate).endOf("year"));
		const years = [...new Set([...range.by("year")].map((day) => day.year().toString()))];
		return years;
		// return years?.filter((year) => sections[year]);
	}, [fromdate, todate]);

	const [selectedYearsUnsorted, setSelectedYears] = useState<string[]>(getYears());
	const selectedYears = selectedYearsUnsorted.sort((a, b) => Number(a) - Number(b));

	useEffect(() => {
		setSelectedYears(getYears());
	}, [getYears]);

	const yearOptions = (Object.keys(sections || {}) || [])?.map((year) => ({
		label: year,
		value: year,
	}));

	const propertyOptions = useMemo(() => {
		if (!sections) return [];
		const years = sections;

		const allOptions = Object.entries(years).flatMap(([key, data]: any) =>
			Object.entries(data)?.map(([propertyKey, p]: any) => ({
				...(p || {}),
				label: (
					<p style={{ display: "flex", gap: "0.3125rem" }}>
						{p.label}
						{p.grouped && (
							<Tooltip content={t("sales.dashboard.terms.grouped", "Grupperad")}>
								<Icon source={AppsMajor} />
							</Tooltip>
						)}
					</p>
				),
				value: propertyKey,
			}))
		);
		const uniqueOptions = [...new Map(allOptions.map((option) => [option.value, option])).values()];

		return uniqueOptions.sort((a, b: any) => (b.grouped ? 1 : -1));
	}, [sections, t]);

	const getDefaultProperties = useCallback(() => {
		return propertyOptions
			?.filter((opt) => opt.default)
			?.map((opt) => opt.value)
			.filter(Boolean);
	}, [propertyOptions]);

	const [properties, setProperties] = useState(getLocalStorage("dashboards")?.sales?.revenue_line_graph?.properties || getDefaultProperties() || []);

	useEffect(() => {
		if (propertyOptions?.length > 0 && !properties?.length) {
			const defaultProperties = getDefaultProperties();
			if (defaultProperties?.length) {
				setProperties(defaultProperties);
			} else if (propertyOptions?.[0]?.value) {
				setProperties([propertyOptions?.[0]?.value]);
			}
		}
	}, [propertyOptions, properties, getDefaultProperties]);

	const sumArraysByIndex = (arrays) => {
		if (arrays.length === 0) return [];

		// Initialize a new array with the same length as the first array and filled with 0
		const length = arrays[0].length;
		const result = new Array(length).fill(0);

		// Sum values by index
		arrays.forEach((arr) => {
			arr.forEach((value, index) => {
				result[index] += value;
			});
		});

		return result;
	};

	const series = useMemo(
		() =>
			selectedYears.flatMap((year) => {
				const data = sumArraysByIndex(properties?.map((property) => sections?.[year]?.[property]?.data || []));

				return {
					name: year,
					data,

					color: getColorFromIndex(yearOptions.findIndex((opt) => opt.value === year)),
					total: data?.at(-1),
				};
			}),
		[sections, properties, selectedYears, yearOptions]
	);

	const handleChangeProperties = (value) => {
		setProperties(value);

		setLocalStorage("dashboards", (c) =>
			deepMergeReplaceKey(c || {}, {
				sales: {
					revenue_line_graph: {
						properties: value,
					},
				},
			})
		);
	};

	return (
		<>
			<OptionList
				preferredAlignment="left"
				style={{ marginBottom: "0.6250rem" }}
				popover
				allowMultiple
				options={propertyOptions}
				selected={properties}
				onChange={handleChangeProperties}
			/>

			<LineGraph
				error={data?.revenue?.error}
				sort={false}
				data={{
					series,
					labels: labels.map((label) => label.capitalize()),
				}}
				headerRight={
					<OptionsWrapper>
						<Select
							options={yearTypeOptions}
							value={yearType || "calendar"}
							onChange={(value: any) => {
								setYearType(value);
							}}
						/>

						<OptionList
							popover
							label={t("sales.dashboard.line_graph.year_button", "Välj år")}
							allowMultiple
							options={yearOptions}
							selected={selectedYears}
							onChange={setSelectedYears}
						/>
					</OptionsWrapper>
				}
				loading={!!loading}
				formatter={(value) => {
					return numberFormatter({
						value,
						currency: "SEK",
						maximumFractionDigits: 0,
					});
				}}
				showSettings={false}
			/>
		</>
	);
};
export default withTranslation(["sales"])(SalesLineGraph);

const OptionsWrapper = styled.div`
	display: flex;
	gap: 0.6250rem;

	.Polaris-Button {
		border-radius: 0.3125rem;
	}
`;
