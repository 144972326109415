import i18next from "i18next";

const Options = {
	label: i18next.t("fortnox.contract.label", "Avtal"),
	value: "Fortnox.Contract",
	options: [
		{ label: i18next.t("fortnox.contract.fields.url.label", "URL"), value: "Fortnox.Contract.@url" },
		{
			label: i18next.t("fortnox.contract.fields.urlTaxReductionList.label", "URL för skattereduktion"),
			value: "Fortnox.Contract.@urlTaxReductionList",
		},
		{ label: i18next.t("fortnox.contract.fields.active.label", "Aktiv"), value: "Fortnox.Contract.Active" },
		{
			label: i18next.t("fortnox.contract.fields.administrationFee.label", "Administrationsavgift"),
			value: "Fortnox.Contract.AdministrationFee",
		},
		{
			label: i18next.t("fortnox.contract.fields.basisTaxReduction.label", "Grund för skattereduktion"),
			value: "Fortnox.Contract.BasisTaxReduction",
		},
		{ label: i18next.t("fortnox.contract.fields.comments.label", "Kommentarer"), value: "Fortnox.Contract.Comments" },
		{ label: i18next.t("fortnox.contract.fields.continuous.label", "Kontinuerlig"), value: "Fortnox.Contract.Continuous" },
		{ label: i18next.t("fortnox.contract.fields.contractDate.label", "Avtalsdatum"), value: "Fortnox.Contract.ContractDate" },
		{ label: i18next.t("fortnox.contract.fields.contractLength.label", "Avtalslängd"), value: "Fortnox.Contract.ContractLength" },
		{
			label: i18next.t("fortnox.contract.fields.contributionPercent.label", "Bidragsprocent"),
			value: "Fortnox.Contract.ContributionPercent",
		},
		{ label: i18next.t("fortnox.contract.fields.contributionValue.label", "Bidragsvärde"), value: "Fortnox.Contract.ContributionValue" },
		{ label: i18next.t("fortnox.contract.fields.costCenter.label", "Kostnadsställe"), value: "Fortnox.Contract.CostCenter" },
		{ label: i18next.t("fortnox.contract.fields.currency.label", "Valuta"), value: "Fortnox.Contract.Currency" },
		{ label: i18next.t("fortnox.contract.fields.customerName.label", "Kundnamn"), value: "Fortnox.Contract.CustomerName" },
		{ label: i18next.t("fortnox.contract.fields.customerNumber.label", "Kundnummer"), value: "Fortnox.Contract.CustomerNumber" },
		{ label: i18next.t("fortnox.contract.fields.documentNumber.label", "Dokumentnummer"), value: "Fortnox.Contract.DocumentNumber" },
		{
			label: i18next.t("fortnox.contract.fields.emailInformation.label", "E-postinformation"),
			value: "Fortnox.Contract.EmailInformation",
			options: [
				{
					label: i18next.t("fortnox.contract.fields.emailInformation.emailAddressFrom.label", "E-postadress Från"),
					value: "Fortnox.Contract.EmailInformation.EmailAddressFrom",
				},
				{
					label: i18next.t("fortnox.contract.fields.emailInformation.emailAddressTo.label", "E-postadress Till"),
					value: "Fortnox.Contract.EmailInformation.EmailAddressTo",
				},
				{
					label: i18next.t("fortnox.contract.fields.emailInformation.emailAddressCC.label", "E-postadress CC"),
					value: "Fortnox.Contract.EmailInformation.EmailAddressCC",
				},
				{
					label: i18next.t("fortnox.contract.fields.emailInformation.emailAddressBCC.label", "E-postadress BCC"),
					value: "Fortnox.Contract.EmailInformation.EmailAddressBCC",
				},
				{
					label: i18next.t("fortnox.contract.fields.emailInformation.emailSubject.label", "E-postämne"),
					value: "Fortnox.Contract.EmailInformation.EmailSubject",
				},
				{
					label: i18next.t("fortnox.contract.fields.emailInformation.emailBody.label", "E-postkropp"),
					value: "Fortnox.Contract.EmailInformation.EmailBody",
				},
			],
		},
		{
			label: i18next.t("fortnox.contract.fields.externalInvoiceReference1.label", "Extern fakturareferens 1"),
			value: "Fortnox.Contract.ExternalInvoiceReference1",
		},
		{
			label: i18next.t("fortnox.contract.fields.externalInvoiceReference2.label", "Extern fakturareferens 2"),
			value: "Fortnox.Contract.ExternalInvoiceReference2",
		},
		{ label: i18next.t("fortnox.contract.fields.freight.label", "Frakt"), value: "Fortnox.Contract.Freight" },
		{ label: i18next.t("fortnox.contract.fields.gross.label", "Brutto"), value: "Fortnox.Contract.Gross" },
		{ label: i18next.t("fortnox.contract.fields.houseWork.label", "Hushållsarbete"), value: "Fortnox.Contract.HouseWork" },
		{ label: i18next.t("fortnox.contract.fields.invoiceDiscount.label", "Fakturarabatt"), value: "Fortnox.Contract.InvoiceDiscount" },
		{ label: i18next.t("fortnox.contract.fields.invoiceInterval.label", "Fakturaintervall"), value: "Fortnox.Contract.InvoiceInterval" },
		{
			label: i18next.t("fortnox.contract.fields.invoicesRemaining.label", "Återstående fakturor"),
			value: "Fortnox.Contract.InvoicesRemaining",
		},

		{ label: i18next.t("fortnox.contract.fields.language.label", "Språk"), value: "Fortnox.Contract.Language" },
		{
			label: i18next.t("fortnox.contract.fields.lastInvoiceDate.label", "Datum för senaste faktura"),
			value: "Fortnox.Contract.LastInvoiceDate",
		},
		{ label: i18next.t("fortnox.contract.fields.net.label", "Netto"), value: "Fortnox.Contract.Net" },
		{ label: i18next.t("fortnox.contract.fields.ourReference.label", "Vår referens"), value: "Fortnox.Contract.OurReference" },
		{ label: i18next.t("fortnox.contract.fields.periodEnd.label", "Periodens slut"), value: "Fortnox.Contract.PeriodEnd" },
		{ label: i18next.t("fortnox.contract.fields.periodStart.label", "Periodens start"), value: "Fortnox.Contract.PeriodStart" },
		{ label: i18next.t("fortnox.contract.fields.priceList.label", "Prislista"), value: "Fortnox.Contract.PriceList" },
		{ label: i18next.t("fortnox.contract.fields.printTemplate.label", "Utskriftsmall"), value: "Fortnox.Contract.PrintTemplate" },
		{ label: i18next.t("fortnox.contract.fields.project.label", "Projekt"), value: "Fortnox.Contract.Project" },
		{ label: i18next.t("fortnox.contract.fields.remarks.label", "Anmärkningar"), value: "Fortnox.Contract.Remarks" },
		{ label: i18next.t("fortnox.contract.fields.roundOff.label", "Avrundning"), value: "Fortnox.Contract.RoundOff" },
		{ label: i18next.t("fortnox.contract.fields.taxReduction.label", "Skattereduktion"), value: "Fortnox.Contract.TaxReduction" },
		{ label: i18next.t("fortnox.contract.fields.templateName.label", "Mallnamn"), value: "Fortnox.Contract.TemplateName" },
		{ label: i18next.t("fortnox.contract.fields.templateNumber.label", "Mallnummer"), value: "Fortnox.Contract.TemplateNumber" },
		{ label: i18next.t("fortnox.contract.fields.termsOfDelivery.label", "Leveransvillkor"), value: "Fortnox.Contract.TermsOfDelivery" },
		{ label: i18next.t("fortnox.contract.fields.termsOfPayment.label", "Betalningsvillkor"), value: "Fortnox.Contract.TermsOfPayment" },
		{ label: i18next.t("fortnox.contract.fields.total.label", "Totalt"), value: "Fortnox.Contract.Total" },
		{ label: i18next.t("fortnox.contract.fields.totalToPay.label", "Totalt att betala"), value: "Fortnox.Contract.TotalToPay" },
		{ label: i18next.t("fortnox.contract.fields.totalVAT.label", "Total moms"), value: "Fortnox.Contract.TotalVAT" },
		{ label: i18next.t("fortnox.contract.fields.vATIncluded.label", "Moms inkluderad"), value: "Fortnox.Contract.VatIncluded" },
		{ label: i18next.t("fortnox.contract.fields.wayOfDelivery.label", "Leveranssätt"), value: "Fortnox.Contract.WayOfDelivery" },
		{ label: i18next.t("fortnox.contract.fields.yourOrderNumber.label", "Ditt ordernummer"), value: "Fortnox.Contract.YourOrderNumber" },
		{ label: i18next.t("fortnox.contract.fields.yourReference.label", "Din referens"), value: "Fortnox.Contract.YourReference" },
		{
			label: i18next.t("fortnox.contract.fields.taxReductionType.label", "Typ av skattereduktion"),
			value: "Fortnox.Contract.TaxReductionType",
		},
		{ label: i18next.t("fortnox.contract.fields.status.label", "Status"), value: "Fortnox.Contract.Status" },

		{
			label: i18next.t("fortnox.contract.fields.rows.label", "Rader"),
			value: "Fortnox.Contract.InvoiceRows",
			type: "array",
			options: [
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.accountNumber.label", "Kontonummer"),
					value: "AccountNumber",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.articleNumber.label", "Artikelnummer"),
					value: "ArticleNumber",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.contributionPercent.label", "Bidragsprocent"),
					value: "ContributionPercent",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.contributionValue.label", "Bidragsvärde"),
					value: "ContributionValue",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.costCenter.label", "Kostnadsställe"),
					value: "CostCenter",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.deliveredQuantity.label", "Levererad kvantitet"),
					value: "DeliveredQuantity",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.description.label", "Beskrivning"),
					value: "Description",
				},
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.discount.label", "Rabatt"), value: "Discount" },
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.discountType.label", "Rabattyp"),
					value: "DiscountType",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.houseWork.label", "Hushållsarbete"),
					value: "HouseWork",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.houseWorkHoursToReport.label", "Timmar för hushållsarbete att rapportera"),
					value: "HouseWorkHoursToReport",
				},
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.houseWorkType.label", "Typ av hushållsarbete"),
					value: "HouseWorkType",
				},
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.price.label", "Pris"), value: "Price" },
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.project.label", "Projekt"), value: "Project" },
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.rowId.label", "Rad-ID"), value: "RowId" },
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.stockPointCode.label", "Lagerplatskod"),
					value: "StockPointCode",
				},
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.total.label", "Totalt"), value: "Total" },
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.unit.label", "Enhet"), value: "Unit" },
				{ label: i18next.t("fortnox.contract.fields.invoiceRows.vAT.label", "Moms"), value: "VAT" },
				{
					label: i18next.t("fortnox.contract.fields.invoiceRows.priceExcludingVAT.label", "Pris exklusive moms"),
					value: "PriceExcludingVAT",
				},
			],
		},
	],
};

export default Options;
