import { store } from "../store";

/**
 *
 * @param roles
 * @param user
 * @returns true if the user has any of the roles, or if the roles array is empty. Otherwise, false if user is missing any required role.
 */
const checkUserRoles = (roles?: string[], user = store.getState().user): boolean => {
	if (!roles?.length) return true;

	const userRoles = user.all_permissions || user.roles;
	const userRolesSet = new Set(userRoles);
	//Checks if the user lacks all of the required roles. Aka, if the user has none of the required roles.
	// User only needs one of the roles to pass the check.
	if (roles.every((r) => !userRolesSet.has(r))) return false;
	return true;
};
export default checkUserRoles;
