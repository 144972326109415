import React, { Component } from "react";
import { Icon } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";

class CellPhone extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, value: props.value };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value });
	}

	toggle() {
		if (this.props.readonly || this.props.disabled) return;

		if (!this.state.editing) {
			this.props.onFocus();
		} else {
			if (this.state.value != this.props.value) {
				this.props.onChange({ value: this.state.value });
			}
			this.props.onBlur();
		}
		this.setState({ editing: !this.state.editing });
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = "";
		this.props.onChange({ value: this.state.value });
		this.setState({ value: "" });
	}

	render() {
		return (
			<div
				className={
					"overflow-ellipsis board-tabbable board-editable" + (this.state.editing ? " focused" : "") + (this.props.disabled ? " disabled" : "")
				}
				style={{
					height: "100%",
					width: "100%",
					cursor: "text",
					textAlign: "center",
					lineHeight: this.props.height ? this.props.height + "px" : null,
					backgroundColor: this.state.editing ? "transparent" : null,
				}}
				onClick={this.toggle.bind(this)}
			>
				{this.state.editing ? (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
						onDragStart={(event) => {
							event.stopPropagation();
							event.preventDefault();
						}}
						draggable
					>
						<input
							ref={(input) => {
								if (input) {
									input.focus();
								}
							}}
							onChange={(event) => {
								this.setState({ value: event.target.value });
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									this.toggle();
								} else if (event.key === "Escape") {
									this.setState({ value: this.props.value }, this.toggle.bind(this));
								}
							}}
							onBlur={this.toggle.bind(this)}
							type="text"
							value={this.state.value}
							style={{
								textAlign: "center",
								lineHeight: this.props.height ? this.props.height - 1 + "px" : null,
								display: "block",
								width: "100%",
								border: 0,
							}}
						/>
					</div>
				) : !this.props.value && this.props.placeholder ? (
					this.props.placeholder
				) : (
					this.props.value
				)}
				{this.state.value && !this.props.noclear && !this.props.disabled ? (
					<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
						<Icon source={CircleCancelMinor} />
					</div>
				) : null}
			</div>
		);
	}
}
export default CellPhone;
