import { ButtonGroup, FormLayout, Icon } from "@shopify/polaris";
import { MobileVerticalDotsMajor, TextAlignmentCenterMajor, TextAlignmentLeftMajor, TextAlignmentRightMajor } from "@shopify/polaris-icons";
import React, { useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "src/js/components/Button/Button";
import Popover from "src/js/components/Popover";
import TextField from "src/js/components/TextField/TextField";
import getColumnOptions, { findOptionOptionsByValue } from "./getColumnOptions";
import SelectOptions from "./SelectOptions";
import ToolTipButton from "../ToolTipButton";
import { ButtonGroupWrapper } from "../styles";
import FontSizeChanger from "../FontSizeChanger";
import Select from "src/js/components/select/Select";
import getFontFamilies from "../getFontFamilies";
import ColorPicker from "src/js/components/ColorPicker2";
import Group from "src/js/components/Styles/Group";

const TableHeaderColumn = ({ t, onChange, column, index, onRemove, rowsProperty }) => {
	const [open, setOpen] = useState(false);
	const options = findOptionOptionsByValue(getColumnOptions(), rowsProperty);

	const handleColumnChange =
		(field, formatter = (v: any) => v) =>
		(value) => {
			const option = options?.find((o) => o.value === value);
			const v = formatter ? formatter(value) : value;
			column[field] = v;
			if (option) column.label = option.label;

			onChange(index)(column);

			if (field === "value") {
				setOpen(false);
			}
		};

	const handleRowsChange =
		(field, formatter = (v: any) => v) =>
		(value) => {
			const v = formatter ? formatter(value) : value;

			const rowsSettings = column?.rows || {};
			rowsSettings[field] = v;

			handleColumnChange("rows")(rowsSettings);
		};

	const fonts = useMemo(() => getFontFamilies({ fontSize: column.fontSize }), [column.fontSize]);

	return (
		<TextField
			label={t("pdf.editor.table.columns.title	", "Kolumn {{i}}", { i: index + 1 })}
			value={column.label}
			onChange={handleColumnChange("label")}
			suffix={
				<Popover
					active={open}
					onClose={() => setOpen(false)}
					activator={<Button plain icon={MobileVerticalDotsMajor} onClick={() => setOpen(!open)} />}
					sectioned
				>
					<FormLayout>
						<Group
							sectioned
							labelBackgroundColor="var(--main3)"
							label={t("pdf.editor.table.columns.options.title", "Kolumn {{i}} inställningar", { i: index + 1 })}
						>
							<SelectOptions
								label={t("pdf.editor.table.columns.options.value.label", "Värde", { i: index + 1 })}
								options={options}
								value={column.value.replace(/{{|}}/g, "")}
								onChange={handleColumnChange("value")}
							/>

							<ButtonGroupWrapper>
								<ButtonGroup>
									<ColorPicker
										// label={t("pdf.editor.table.cellBackgroundColor", "Cell backgrounds färg")}
										value={column.fill}
										onChange={handleColumnChange("fill")}
									/>
									<FontSizeChanger value={column.fontSize} onChange={handleColumnChange("fontSize")} />

									<ToolTipButton
										enabled={column.fontWeight == "bold"}
										tooltip="Bold"
										image={<img alt="bold" src="/assets/svg/format_bold.svg" />}
										onClick={handleColumnChange("fontWeight", () => (column.fontWeight == "bold" ? "" : "bold"))}
									/>
									<ToolTipButton
										enabled={column.fontStyle == "italic"}
										image={<img alt="italic" src="/assets/svg/format_italic.svg" />}
										tooltip="Italic"
										onClick={handleColumnChange("fontStyle", () => (column.fontStyle == "italic" ? "" : "italic"))}
									/>
									{!!fonts?.length && <Select value={column.fontFamily} options={fonts} onChange={(v) => handleColumnChange("fontFamily")(v)} />}
									<ToolTipButton
										enabled={column.textAlign == "left"}
										tooltip="Text align left"
										image={<Icon source={TextAlignmentLeftMajor} />}
										onClick={handleColumnChange("textAlign", () => "left")}
									/>
									<ToolTipButton
										enabled={column.textAlign == "center"}
										tooltip="Text align center"
										image={<Icon source={TextAlignmentCenterMajor} />}
										onClick={handleColumnChange("textAlign", () => "center")}
									/>
									<ToolTipButton
										enabled={column.textAlign == "right"}
										tooltip="Text align right"
										image={<Icon source={TextAlignmentRightMajor} />}
										onClick={handleColumnChange("textAlign", () => "right")}
									/>
								</ButtonGroup>
							</ButtonGroupWrapper>
							<TextField
								label={t("pdf.editor.table.columns.options.width.label", "Kolumn bredd")}
								value={String(column.width)}
								onChange={handleColumnChange("width", (v) => parseInt(v))}
							/>
						</Group>
						<Group sectioned labelBackgroundColor="var(--main3)" label={t("pdf.editor.table.columns.options.rows.title", "Rad inställningar")}>
							<ButtonGroupWrapper>
								<ButtonGroup>
									<ColorPicker value={column?.rows?.fill} onChange={handleRowsChange("fill")} />
									<FontSizeChanger value={column?.rows?.fontSize} onChange={handleRowsChange("fontSize")} />
									<ToolTipButton
										enabled={column?.rows?.fontWeight == "bold"}
										tooltip="Bold"
										image={<img alt="bold" src="/assets/svg/format_bold.svg" />}
										onClick={handleRowsChange("fontWeight", () => (column?.rows?.fontWeight == "bold" ? "" : "bold"))}
									/>
									<ToolTipButton
										enabled={column?.rows?.fontStyle == "italic"}
										image={<img alt="italic" src="/assets/svg/format_italic.svg" />}
										tooltip="Italic"
										onClick={handleRowsChange("fontStyle", () => (column?.rows?.fontStyle == "italic" ? "" : "italic"))}
									/>
									<ToolTipButton
										enabled={column?.rows?.textAlign == "left"}
										tooltip="Text align left"
										image={<Icon source={TextAlignmentLeftMajor} />}
										onClick={handleRowsChange("textAlign", () => "left")}
									/>
									<ToolTipButton
										enabled={column?.rows?.textAlign == "center"}
										tooltip="Text align center"
										image={<Icon source={TextAlignmentCenterMajor} />}
										onClick={handleRowsChange("textAlign", () => "center")}
									/>
									<ToolTipButton
										enabled={column?.rows?.textAlign == "right"}
										tooltip="Text align right"
										image={<Icon source={TextAlignmentRightMajor} />}
										onClick={handleRowsChange("textAlign", () => "right")}
									/>
								</ButtonGroup>
							</ButtonGroupWrapper>
						</Group>

						<Button
							fullWidth
							destructive
							onClick={() => {
								onRemove(index);
								setOpen(false);
							}}
						>
							{t("common.remove", "Ta bort")}
						</Button>
					</FormLayout>
				</Popover>
			}
		/>
	);
};
export default withTranslation(["pdf", "common"])(TableHeaderColumn);
