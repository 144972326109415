import React from "react";
import { Icon } from "@shopify/polaris";
import { ArrowRightMinor } from "@shopify/polaris-icons";
import { EntityLogChange, EntityLogChangeWrapper } from "./styles";
import translations from "./Utilities/translations";
import Details from "../Details";

const EntityLogText = React.memo(({ data }: any) => {
	const { changes } = data || {};
	return (
		<EntityLogChangeWrapper>
			<Details
				gap={{
					horizontal: "0.5rem",
				}}
				items={Object.entries(changes)?.map(([key, change]: any, index) => {
					const tranlsatedKey = translations[data.entity_class]?.[key] || key;

					const oldValue = typeof change.old === "boolean" ? (change.old ? "Ja" : "Nej") : change.old;
					const newValue = typeof change.new === "boolean" ? (change.new ? "Ja" : "Nej") : change.new;

					return {
						label: tranlsatedKey,
						value: (
							<EntityLogChange>
								{oldValue} {!!(oldValue && newValue) && <Icon source={ArrowRightMinor} />} {newValue}
							</EntityLogChange>
						),
					};
				})}
			/>
		</EntityLogChangeWrapper>
	);
});

export default EntityLogText;
