import moment from "moment";
import currencyFormatter from "./numberFormatter";

export const getRefreshToken = () => {
	return getSessionStorage("refresh_token") || getLocalStorage("refresh_token");
};

export const getLocalStorage = (name) => {
	const item = localStorage.getItem(name);
	try {
		const itemParsed = JSON.parse(item);

		if (itemParsed === "null") return null;
		if (itemParsed === "NaN") return NaN;
		if (itemParsed === "undefined") return undefined;
		return itemParsed;
	} catch (error) {
		if (item === "null") return null;
		if (item === "NaN") return NaN;
		if (item === "undefined") return undefined;
		return item;
	}
};

export const setLocalStorage = (name, data) => {
	if (typeof data === "function") data = data(getLocalStorage(name));

	try {
		localStorage.setItem(name, typeof data === "string" ? data : JSON.stringify(data));
		return data;
	} catch (error) {
		console.warn(`setLocalStorage - ${name}: `, error);
	}
};

export const getSessionStorage = (name) => {
	const item = sessionStorage.getItem(name);
	try {
		const itemParsed = JSON.parse(item);

		if (itemParsed === "null") return null;
		if (itemParsed === "NaN") return NaN;
		if (itemParsed === "undefined") return undefined;
		return itemParsed;
	} catch (error) {
		if (item === "null") return null;
		if (item === "NaN") return NaN;
		if (item === "undefined") return undefined;
		return item;
	}
};

export const setSessionStorage = (name, data) => {
	try {
		sessionStorage.setItem(name, typeof finallValue === "string" ? data : JSON.stringify(data));
		return data;
	} catch (error) {
		console.warn(`setSessionStorage - ${name}: `, error);
	}
};

export const msToHMS = (
	ms,
	config = {
		months: true,
		days: true,
		hours: true,
		minutes: true,
		seconds: true,
	}
) => {
	if (!ms) return "-";

	const duration = moment.duration(ms);

	// const hm = moment
	// 	.duration(ms)
	// 	.format("h[h] m[m] s[s]")
	// 	//removes suffixes if there are 0 or nothing
	// 	.replace(" h", "h")
	// 	.replace(" m", "m")
	// 	.replace(" 0m", "")
	// 	.replace(" 0s", "");
	// if (!hm) return duration.format("s [s]");
	// return hm;
	// console.log('duration:', duration);
	const months = config?.months && duration.months() ? `${duration.months()}M` : "";
	const days = config?.days && duration.days() ? `${duration.days()}d` : "";
	const hours = config?.hours && duration.hours() ? `${duration.hours()}h` : "";
	// console.log("hours:", hours);
	const minutes = config?.minutes && duration.minutes() && duration.minutes() ? ` ${duration.minutes()}m` : "";
	// console.log("minutes:", minutes);
	const seconds = config?.seconds && duration.seconds() ? ` ${duration.seconds()}s` : "";
	// console.log("seconds:", seconds);
	const HMS = `${months}${days}${hours}${minutes}${seconds}`;
	return HMS;
};

export const chunk = (array, size) => {
	const chunks = [];
	for (let i = 0; i < array.length; i += size) {
		const chunk = array.slice(i, i + size);
		chunks.push(chunk);
	}

	return chunks;
};
export const checkEmailValidity = (email) => {
	if (
		!email ||
		!String(email.trim())
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	) {
		// eslint-disable-next-line no-console
		console.debug("Invalid email", email);
		return false;
	}
	return true;
};
export const getUniqueListBy = (arr, key, alternativeKey) => [
	...new Map(arr.map((item) => [item[key] || (alternativeKey && item[alternativeKey]), item])).values(),
];

export const getUniqueListByAndMerge = (arr, key) => {
	if (!arr) return [];
	return arr.reduce((acc, item) => {
		const foundIndex = acc.findIndex((i) => i[key] === item[key]);
		if (foundIndex >= 0 && item) {
			acc[foundIndex] = { ...acc[foundIndex], ...item };
		} else {
			acc.push({ ...item });
		}
		return acc;
	}, []);
};

export const generateId = (len = 40) => {
	const timestamp = Date.now().toString();
	const randomValues = new Uint8Array(len || 40 - timestamp.length);
	window.crypto.getRandomValues(randomValues);
	const numericValues = Array.from(randomValues, (value) => value % 10).join("");
	return String(timestamp + numericValues).slice(0, len) * 1;
};

export const RemoveCookie = (cookieName) => {
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax`;
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const AddCookie = (cookieName, value, options = {}) => {
	const optionsEntries = Object.entries(options);
	const cookie = `${cookieName}=${value}; ${optionsEntries?.map((i) => `${i[0]}=${i[1]}`)?.join("; ")}${
		optionsEntries && optionsEntries.length ? ";" : ""
	}path=${"path" in options ? options.path : "/"};SameSite=${"sameSite" in options ? options.sameSite : "Lax"}`;

	document.cookie = cookie;
};

export const getCookie = (cname) => {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			if (c.substring(name.length, c.length) === "null") {
				return null;
			} else if (c.substring(name.length, c.length) === "false") {
				return false;
			} else if (c.substring(name.length, c.length) === "true") {
				return true;
			}

			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export const getPortalsMaxZIndex = () => {
	// const elements = document.querySelectorAll("#PolarisPortalsContainer *[data-portal-id]");
	const sheets = document.querySelectorAll("#PolarisPortalsContainer .Polaris-Sheet__Container");
	const modals = document.querySelectorAll("#PolarisPortalsContainer .Polaris-Modal-Dialog__Container");
	const popovers = document.querySelectorAll("#PolarisPortalsContainer .Polaris-Popover__PopoverOverlay--open");

	const maxZ = Math.max(
		...Array.from([...sheets, ...modals, ...popovers], (el) => parseFloat(window.getComputedStyle(el).zIndex)).filter(
			(zIndex) => !Number.isNaN(zIndex)
		),
		400
	);

	return maxZ;
};
export const getElementIndex = (element) => {
	return Array.from(element.parentNode.children).indexOf(element);
};

// export const getSheetPortalsIndex = () => {
// 	const elements = document.querySelectorAll("#PolarisPortalsContainer *[data-portal-id^=sheet-Polarisportal]");
// 	const maxZ = Math.max(
// 		...Array.from(elements, (el) => {
// 			return parseInt(el.attributes["data-portal-id"]?.value?.replace("sheet-Polarisportal", ""))}).filter(
// 			(zIndex) => !Number.isNaN(zIndex)
// 		),
// 		0
// 	);

// 	return maxZ;
// };
export { currencyFormatter };
