import React from "react";

const CalendarDayIcon = ({ color = "var(--textColor)" }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.28418 4.63641C3.28418 3.8425 3.92777 3.19891 4.72168 3.19891H19.0967C19.8906 3.19891 20.5342 3.8425 20.5342 4.63641V19.0114C20.5342 19.8053 19.8906 20.4489 19.0967 20.4489H4.72168C3.92777 20.4489 3.28418 19.8053 3.28418 19.0114V4.63641ZM19.0967 4.63641H4.72168V19.0114H19.0967V4.63641Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.2217 1.76141C16.6186 1.76141 16.9404 2.08321 16.9404 2.48016V5.35516C16.9404 5.75212 16.6186 6.07391 16.2217 6.07391C15.8247 6.07391 15.5029 5.75212 15.5029 5.35516V2.48016C15.5029 2.08321 15.8247 1.76141 16.2217 1.76141Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.59668 1.76141C7.99363 1.76141 8.31543 2.08321 8.31543 2.48016V5.35516C8.31543 5.75212 7.99363 6.07391 7.59668 6.07391C7.19973 6.07391 6.87793 5.75212 6.87793 5.35516V2.48016C6.87793 2.08321 7.19973 1.76141 7.59668 1.76141Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.28418 8.23016C3.28418 7.83321 3.60598 7.51141 4.00293 7.51141H19.8154C20.2124 7.51141 20.5342 7.83321 20.5342 8.23016C20.5342 8.62712 20.2124 8.94891 19.8154 8.94891H4.00293C3.60598 8.94891 3.28418 8.62712 3.28418 8.23016Z"
			fill={color}
		/>
		<path d="M5.44604 9.84229H7.59668V11.9929H5.44604V9.84229Z" fill={color} />
	</svg>
);

export default CalendarDayIcon;
