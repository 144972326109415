import { useCallback, useState } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ResetFunction<T> = () => void;

function useStateCustom<T>(initialState: T): [T, React.Dispatch<React.SetStateAction<T>>, ResetFunction<T>] {
	const initial = useDeepCompareMemo(() => initialState, [initialState]);
	const [state, setState] = useState<T>(initialState);

	const resetState: ResetFunction<T> = useCallback(() => setState(initial), [initial]);
	return [state, setState, resetState];
}

export default useStateCustom;
