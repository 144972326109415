import React, { useState } from "react";
import nl2br from "react-nl2br";
import Popover from "src/js/components/Popover";
import Truncate from "src/js/components/Truncate";
import styled from "styled-components";

const CommentContentPopover = ({ children }) => {
	const [open, setOpen] = useState(false);

	const handleClose = () => setOpen(false);
	const handleToggle = () => setOpen((c) => !c);

	return (
		<Popover
			activator={
				<Truncate style={{ cursor: "pointer" }} onClick={handleToggle} numberOfLines={2} maxWidth={300}>
					{nl2br(children)}
				</Truncate>
			}
			sectioned
			active={open}
			onClose={handleClose}
			showCloseButton={false}
		>
			<ContentWrapper>{nl2br(children)}</ContentWrapper>
		</Popover>
	);
};
export default CommentContentPopover;

const ContentWrapper = styled.div``;
