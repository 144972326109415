import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import StatsBox from "./StatsBox";
import type { StatsBoxWrapperProps } from "./types";

const MIN_WIDTH = 200;
const MAX_WIDTH = "1fr";

const StatsBoxWrapper = ({
	children,
	items,
	onClick,
	loading,
	loadingNrOfBoxes = items?.length,
	minWidth,
	maxWidth,
	style,
	perRow,
}: StatsBoxWrapperProps) => {
	const [quantity, setQuantity] = React.useState<number | string>(perRow || "auto-fit");
	const boxes = items?.length ? items : Array.from(Array(loadingNrOfBoxes));

	const onWrapperResize = useMemo(
		() =>
			debounce(
				(e) => {
					const width = e[0].contentRect.width;
					const minimumWidth = (minWidth || MIN_WIDTH) as number;
					const boxesWidth = (boxes?.length || 0) * minimumWidth;
					const boxesGap = ((boxes?.length || 1) - 1) * 30;

					const boxesTotalWidth = boxesWidth + boxesGap;

					if (boxesTotalWidth > width) {
						// setQuantity(Math.floor(width / (minimumWidth + 30)));
						setQuantity("auto-fit");
					} else {
						setQuantity(perRow || "auto-fit");
					}
				},
				250,
				{ leading: true, trailing: true, maxWait: 1000 }
				// [items?.length, minWidth, perRow]
			),
		[boxes?.length, minWidth, perRow]
	);
	const resizeObserver = useMemo(() => new ResizeObserver(onWrapperResize), [onWrapperResize]);

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (wrapperRef.current && perRow !== "auto-fit") {
			resizeObserver.observe(wrapperRef.current);
		}
		return () => resizeObserver?.disconnect();
	}, [perRow, resizeObserver]);

	// if ((!items || !items.length) && !children && !loading) return null;

	return (
		<Wrapper
			className="statsBoxWrapper"
			ref={wrapperRef}
			style={style}
			minWidth={minWidth || MIN_WIDTH}
			maxWidth={maxWidth || MAX_WIDTH}
			perRow={quantity}
		>
			{boxes.map((item, index) => {
				if (loading && !("loading" in item)) {
					item.className = "loading";
					item.loading = true;
				}

				return (
					<StatsBox key={index} item={item} onClick={onClick ? () => onClick(item, index) : null}>
						{item.children}
					</StatsBox>
				);
			})}
			{children}
		</Wrapper>
	);
};

export default StatsBoxWrapper;

const Wrapper = styled.div<{ minWidth: number | string; maxWidth: number | string; perRow?: number | string }>`
	display: grid;
	gap: 30px;
	margin-bottom: 1.2500rem;
	transition: 250ms;

	grid-template-columns: ${({ perRow, minWidth, maxWidth }) =>
		`repeat(${perRow || "auto-fit"}, minmax(${typeof minWidth === "number" ? `${minWidth}px` : minWidth}, ${
			typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth
		}))`};
`;
