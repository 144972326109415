import React, { Component } from "react";
import { LegacyStack, Button, Checkbox, FormLayout, Labelled, ChoiceList, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import Toast from "src/js/components/Toast";
import RemoveModal from "./remove_modal.js";
import Modal from "src/js/components/modal";

class MetafieldModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, form: { ...(props.metafield || {}) }, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: { ...(props.metafield || {}) } });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/contact_metafields/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(this.props.t("board.metafield.responses.updated", "Uppdaterade extrafält"));
					this.setState({ saving: false });
					store.dispatch({ type: "UPDATE_CONTACT_METAFIELD", contact_metafield: result.data.contact_metafield });
					this.props.onCreate(result.data.contact_metafield);
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		} else {
			API.post("/api/contact_metafields.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(this.props.t("board.metafield.responses.created", "Skapade extrafält"));
					this.setState({ saving: false });
					store.dispatch({ type: "ADD_CONTACT_METAFIELD", contact_metafield: result.data.contact_metafield });
					this.props.onCreate(result.data.contact_metafield);
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		}
	}

	updateOption(index, value) {
		this.state.form.options[index].label = value;
		this.state.form.options[index].value = value;
		this.setState({ form: this.state.form });
	}

	removeOption(index) {
		this.state.form.options.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	doRemove() {
		this.setState({ removing: true });
		API.delete("/api/contact_metafields/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });
				store.dispatch({ type: "REMOVE_CONTACT_METAFIELD", id: this.state.form.id });
				Toast.success(this.props.t("board.metafield.responses.removed", "Extrafält borttaget"));
				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove();
				}
			})
			.catch((error) => {
				this.setState({ removing: false });
				Toast.error(error);
			});
	}

	render() {
		const secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (this.state.form.id && store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		return (
			<div>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={
						this.state.form.id
							? this.props.t("board.metafield.title.change", "Ändra fält")
							: this.props.t("board.metafield.title.create", "Skapa fält")
					}
					secondaryActions={secondaryActions}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						disabled: !!(!this.state.form.title || (this.state.form.type == "options" && !this.state.form.options.length) || !this.state.form.for),
						onAction: this.saveForm.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("board.metafield.fields.title.label", "Namn")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							<LegacyStack vertical spacing="none">
								<Checkbox
									label={this.props.t("board.metafield.fields.required.label", "Obligatoriskt")}
									checked={this.state.form.required}
									onChange={this.updateForm.bind(this, "required")}
								/>
								<Checkbox
									label={this.props.t("board.metafield.fields.searchable.label", "Sökbar")}
									// helpText={this.props.t("board.metafield.fields.searchable.helptext", "Gör fältet sökbart i kontakter och huvudsöken")}
									checked={this.state.form.searchable}
									onChange={this.updateForm.bind(this, "searchable")}
								/>
							</LegacyStack>
							<ChoiceList
								label={this.props.t("board.metafield.fields.for.label", "Synligt för")}
								choices={[
									{ label: this.props.t("board.metafield.fields.for.options.company", "Företag"), value: "company" },
									{ label: this.props.t("board.metafield.fields.for.options.person", "Kontaktpersoner"), value: "person" },
								]}
								selected={[this.state.form.for]}
								onChange={(values) => {
									this.updateForm("for", values[0]);
								}}
							/>
							<ChoiceList
								label={this.props.t("board.metafield.fields.type.label", "Typ av fält")}
								choices={[
									{ label: this.props.t("board.metafield.fields.type.options.input", "Textfält"), value: "input" },
									{ label: this.props.t("board.metafield.fields.type.options.boolean", "Checkbox"), value: "boolean" },
									{ label: this.props.t("board.metafield.fields.type.options.options", "Dropdown"), value: "options" },
								]}
								selected={[this.state.form.type]}
								onChange={(values) => {
									this.updateForm("type", values[0]);
								}}
							/>
							{this.state.form.type == "options" ? (
								<Labelled label={this.props.t("board.metafield.fields.options.label", "Alternativ")}>
									<LegacyStack vertical>
										{this.state.form.options.map((option, index) => (
											<LegacyStack key={index} distribution="fill">
												<LegacyStack.Item fill>
													<TextField value={option.value} onChange={this.updateOption.bind(this, index)} />
												</LegacyStack.Item>
												<Button destructive onClick={this.removeOption.bind(this, index)}>
													X
												</Button>
											</LegacyStack>
										))}
									</LegacyStack>
								</Labelled>
							) : null}
							{this.state.form.type == "options" ? (
								<Button
									plain
									onClick={() => {
										this.state.form.options.push({ value: "", label: "" });
										this.setState({ form: this.state.form });
									}}
								>
									{this.props.t("board.metafield.fields.options.actions.add_new", "Lägg till nytt alternativ")}
								</Button>
							) : null}
						</FormLayout>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.doRemove.bind(this)}
					numItems={1}
					objectName={this.state.form.title}
					resourceName={{
						singular: this.props.t("board.metafields.singular", "fält"),
						plural: this.props.t("board.metafields.plural", "fält"),
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(MetafieldModal);
