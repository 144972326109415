// See color variables in variables.scss
// import Colors from "./../css/variables.scss";

const Colors = {
	blue: "#4991cc",
	lightblue: "#6eafe4",
	darklightblue: "#5a7e9c",
	green: "#5ac57d",
	accent: "#02f4c4",
	orange: "#edab51",
	red: "#ff6b6b",
	pink: "#e477b9",
	yellow: "#ffcb00",
	yellow2: "#ffd66b",
	purple: "#af78f4",
	grey: "#a4a4a433",
	white: "#f0f0f0",
	main1: "rgb(0, 5, 34)",
	main2: "rgb(20, 24, 48)",
	main3: "rgb(33, 37, 61)",
};

if (window.location.href.includes("acordo.se") || window.location.href.includes("nollettnollnoll.se")) {
	Colors.accent = "#00CCF2";
}

Colors.random = () => {
	const keys = Object.keys(Colors).filter((key) => typeof Colors[key] === "string");
	const key = keys[Math.floor(Math.random() * keys.length)];
	return Colors[key];
};

export default Colors;

export const RGBToHex = (rgb) => {
	// Choose correct separator
	if (rgb.includes("#")) return rgb;
	const sep = rgb.indexOf(",") > -1 ? "," : " ";
	// Turn "rgb(r,g,b)" into [r,g,b]
	rgb = rgb.substr(4).split(")")[0].split(sep);

	let r = (+rgb[0]).toString(16);
	let g = (+rgb[1]).toString(16);
	let b = (+rgb[2]).toString(16);

	if (r.length == 1) r = "0" + r;
	if (g.length == 1) g = "0" + g;
	if (b.length == 1) b = "0" + b;

	return "#" + r + g + b;
};

export const colorToRgba = (color = "rgb(255,255,255)", alpha = 1) => {
	if (!color || typeof color !== "string") return color;
	if (!color.includes("a") && color.includes("rgb")) {
		return color.replace(")", `, ${alpha})`).replace("rgb", "rgba");
	}
	if (color.includes("#")) {
		const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16));
		return `rgba(${r},${g},${b},${alpha})`;
	}
	return color;
};

export const getColorFromIndex = (index) => {
	const baseColors = [
		Colors.blue,
		Colors.green,
		Colors.orange,
		Colors.accent,
		Colors.red,
		Colors.pink,
		Colors.yellow,
		Colors.purple,
		// Colors.grey,
		Colors.white,
		Colors.lightblue,
	];

	if (index < baseColors.length) return baseColors[index];

	const color = `hsl(${(index * 137.508) % 360}, 100%, 50%)`;
	return color;
};
