import React, { useContext, useState } from "react";
import OrderContext from "../../OrderContext";
import IndexTable from "src/js/components/IndexTable";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import OrderNoteModal from "./OrderNoteModal";
import nl2br from "react-nl2br";
import { useTranslation } from "react-i18next";

const OrderNotes = () => {
	const { order, id } = useContext(OrderContext);
	const [currentNote, setCurrentNote] = useState<any>(null);
	const indexRef = React.useRef<any>(null);
	const { t } = useTranslation(["order", "common"]);

	const handleSaveNote = async (note) => {
		setCurrentNote(null);
		if (indexRef.current) indexRef.current.refresh();
	};
	const handleDeleteNote = async () => {
		setCurrentNote(null);
		if (indexRef.current) indexRef.current.refresh();
	};

	return (
		<>
			<IndexTable
				inline
				defaultSort="CREATED_AT_DESC"
				enabled={!!id}
				ref={indexRef}
				primaryAction={{
					content: t("order.note.create_note", "Skapa notering"),
					onAction: () => setCurrentNote({}),
					primary: true,
				}}
				filterKey="order_notes"
				columns={[
					{
						label: t("order.note.fields.note.label", "Notering"),
						handle: "NOTE",
						render: (note: OrderNote) => nl2br(note.note?.length > 100 ? note.note.substring(0, 100) + "..." : note.note),
					},
					{
						label: t("order.note.fields.created_by.label", "Skapad av"),
						handle: "CREATAED_BY",
						render: (note: OrderNote) => <ProfileAvatar user={note.created_by}>{note.created_by.name}</ProfileAvatar>,
					},
					{
						label: t("order.note.fields.created_at.label", "Skapad"),
						handle: "CREATED_AT",
						render: (note: OrderNote) => note.created_at,
						sortable: true,
					},
					{
						label: t("order.note.fields.edited_at.label", "Uppdaterad"),
						handle: "UPDATED_AT",
						render: (note: OrderNote) => note.edited_at,
						sortable: true,
					},
				]}
				resourceUrl={`/api/orders/${id}/notes.json`}
				resourceHandle={"order_notes"}
				resourceName={{
					singular: t("order.note.singular.label", "Notering"),
					plural: t("order.note.plural.label", "Notering"),
				}}
				onClick={(note: OrderNote) => setCurrentNote(note)}
			/>
			<OrderNoteModal
				order={order}
				note={currentNote}
				onClose={() => {
					setCurrentNote(null);
				}}
				onPrimaryAction={handleSaveNote}
				onDestructiveAction={handleDeleteNote}
				open={!!currentNote}
			/>
		</>
	);
};
export default OrderNotes;
