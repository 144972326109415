import { useCallback, useContext } from "react";
import SheetSettingsContext from "./SheetContext";

const useSheetSettings = (): {
	sheetSettings: SheetSettingsType;
	updateSheetSettings: (newSettings: Partial<SheetSettingsType>) => void;
	updateSheetSettingsKey: (key: keyof SheetSettingsType, value: SheetSettingsType[keyof SheetSettingsType]) => void;
} => {
	const { sheetSettings, setSheetSettings } = (useContext(SheetSettingsContext) || {});

	const updateSheetSettings = useCallback(
		(updatedSettings: Partial<SheetSettingsType>) => {
			setSheetSettings((c) => {
				const newSettings = { ...c, ...updatedSettings };
				return newSettings;
			});
		},
		[setSheetSettings]
	);

	const updateSheetSettingsKey = useCallback(
		(key: keyof SheetSettingsType, value: SheetSettingsType[keyof SheetSettingsType]) => {
			updateSheetSettings({ [key]: value });
		},
		[updateSheetSettings]
	);

	return {
		sheetSettings: { ...sheetSettings },
		updateSheetSettings,
		updateSheetSettingsKey,
	};
};

export default useSheetSettings;
