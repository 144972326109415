import i18next from "i18next";

const contactField = {
	name: i18next.t("contact.contact_field.fields.relationship.label", "Relation"),
	user: i18next.t("contact.contact_field.fields.user.label", "Användare"),
	field: i18next.t("contact.contact_field.fields.field.label", "Fält"),
	contact: i18next.t("contact.contact_field.fields.contact.label", "Kontakt"),
};

export default contactField;
