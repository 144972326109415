import { Scrollable } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Sheet from "../../../../components/sheet";
import { store } from "../../../../store";
import BoardHelper from "../../BoardHelper";
import CalendarModalContent from "./CalendarModalContent";

class CalendalSheet extends Component {
	constructor(props) {
		super(props);
		this.state = { form: {} };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.props.open !== props.open) {
			if (props.open) {
				this.setState({ form: props.row || {} });
			}
		}
	}

	updateCell(column, data) {
		BoardHelper.onUpdateVpalue(this.state.form, column, data);
	}

	render() {
		return (
			<Sheet open={this.props.open} onClose={this.props.onClose} title={this.props.t("calendar.actions.new", "Nytt kalenderinlägg")}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "calc(100% - 56px)",
					}}
				>
					<Scrollable style={{ flex: 1 }}>
						<CalendarModalContent
							row={this.state.form}
							attendees={(this.props.attendees || [])?.filter?.((c) => c?.email) || []}
							onClose={this.props.onClose}
							options={this.props.options}
							contact={this.props.contact}
							board={store.getState().user.calendar_board}
							updateCell={this.updateCell.bind(this)}
							onComplete={this.props.onComplete}
						/>
					</Scrollable>
				</div>
			</Sheet>
		);
	}
}
export default withTranslation(["calendar", "common"], { withRef: true })(CalendalSheet);
