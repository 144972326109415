import { Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Select from "src/js/components/select/Select";
import getTermsOfPaymentOptions from "../Utilities/getTermsOfPaymentOptions";

const FortnoxOrderInfo = ({ order, onChange, t, isSaving }) => {
	const disabled = !!order?.InvoiceReference || isSaving || order?.Cancelled;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourReference.label", "Er referens")}
						value={order.YourReference}
						onChange={onChange("YourReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.OurReference.label", "Vår referens")}
						value={order.OurReference}
						onChange={onChange("OurReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourOrderNumber.label", "Ert ordernummer")}
						value={order.YourOrderNumber}
						onChange={onChange("YourOrderNumber")}
					/>
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<Select
						label={t("fortnox.fields.TermsOfPayment.label", "Betalningsvillkor")}
						options={getTermsOfPaymentOptions()}
						value={order.TermsOfPayment}
						onChange={onChange("TermsOfPayment")}
					/>
					<Labelled id="VATIncluded" label={t("fortnox.fields.VATIncluded.label", "Priser inkl. moms")}>
						<Checkbox
							label={t("fortnox.fields.VATIncluded.label2", "Priser inkl. moms")}
							checked={order.VATIncluded}
							onChange={onChange("VATIncluded")}
						/>
					</Labelled>
					<TextField
						disabled={disabled}
						label={t("fortnox.order.fields.Remarks.label", "Ordertext")}
						value={order.Remarks}
						onChange={onChange("Remarks")}
						multiline={3}
						helpText={
							<Checkbox
								label={t("fortnox.order.fields.CopyRemarks.label", "Använd som fakturatext")}
								checked={order.CopyRemarks}
								onChange={onChange("CopyRemarks")}
							/>
						}
					/>
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOrderInfo);
