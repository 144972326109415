import React from "react";
import IntegrationProgress from "src/js/components/IntegrationProgress";
import { getPartyStatusesWithActive } from "../Utilities";

const ScrivePartyProgress = ({ party }) => {
	const statuses = getPartyStatusesWithActive(party).reverse();



	return <IntegrationProgress dontFill title="Scrive" statuses={statuses} />;
};
export default ScrivePartyProgress;
