import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import API from "src/js/API";
import EntityLogs from "src/js/components/EntityLogs";
import Page from "src/js/components/page";
import useQuery from "src/js/hooks/useQuery";

const ContactLogs = () => {
	const { id } = useParams<any>();
	const contactQueryKey = useMemo(() => [id && `contact_${id}`], [id]);

	const { data: contact, isInitialLoading } = useQuery({
		queryKey: contactQueryKey,
		queryFn: async () => {
			try {
				const response = await API.get("/api/contacts/" + id + ".json");
				if (!response.data.contact) return null;

				return response.data.contact;
			} catch (error) {
				console.error("error:", error);
			}
		},
		enabled: !!id,
	});

	return (
		<Page title={`Kontaktloggs för "${contact?.name || id}"`} loading={isInitialLoading}>
			{contact?.id && <EntityLogs url={`/api/contacts/${id}/logs.json`} />}
		</Page>
	);
};
export default ContactLogs;
