import { LegacyCard } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { TableVirtuoso } from "react-virtuoso";
import { colorToRgba } from "../../../../Colors";
import CircleGraph from "../../../../components/CircleGraph";
import BoardHelper from "../../BoardHelper";
import BoardEmptyScreen from "./BoardEmptyScreen";
import Row from "./Row";
import Modal from "src/js/components/modal";

class StatsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	closeStatusModal(status) {
		this.setState({ statusModalIsOpen: false, status: null });
	}

	sortRows(a, b) {
		if (this.state.sortBy?.column) {
			if (this.state.sortBy.direction === "DESC") {
				if (this.state.sortBy?.column === "row_title") {
					return a.title?.localeCompare(b.title);
				}

				const value1 = BoardHelper.getColumnValues(a, this.state.sortBy?.column)?.[0]?.value;
				const value2 = BoardHelper.getColumnValues(b, this.state.sortBy?.column)?.[0]?.value;

				if (value1?.localeCompare && value2?.localeCompare) {
					return value1?.localeCompare(value2);
				} else {
					return value1 - value2;
				}
			} else {
				if (this.state.sortBy?.column === "row_title") {
					return b.title?.localeCompare(a.title);
				}

				const value1 = BoardHelper.getColumnValues(b, this.state.sortBy?.column)?.[0]?.value;
				const value2 = BoardHelper.getColumnValues(a, this.state.sortBy?.column)?.[0]?.value;

				if (value1?.localeCompare && value2?.localeCompare) {
					return value1?.localeCompare(value2);
				} else {
					return value1 - value2;
				}
			}
		}

		const aIndex = this.props.view.options?.order?.indexOf?.(a.id);
		const bIndex = this.props.view.options?.order?.indexOf?.(b.id);

		const posa = aIndex < 0 ? 100000 : aIndex;
		const posb = bIndex < 0 ? 100000 : bIndex;

		return posa - posb;
	}

	openStatusModal(status) {
		const statusColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.status_column));
		const phoneColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.phone_column));
		const contactColumn = this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.contact_column));
		const titleColumn =
			this.props.board.columns.find((column) => String(column.id) === String(this.props.view.options.title_column)) ||
			this.props.view.options.title_column;

		const filters = [
			{
				board_id: this.props.board.id,
				column_id: statusColumn?.id,
				key: `${statusColumn?.id}-${this.props.board.id}`,
				value: [status.value],
			},
		];

		const statusRows = BoardHelper.filterRows([...(this.props.rows || [])].sort(this.sortRows.bind(this)), filters);

		this.setState({ statusModalIsOpen: true, statusColumn, status, statusRows, phoneColumn, contactColumn, titleColumn });
	}

	renderRow(index, row) {
		return (
			<Row
				board={this.props.board}
				row={row}
				phoneColumn={this.state.phoneColumn}
				statusColumn={this.state.statusColumn}
				titleColumn={this.state.titleColumn}
				contactColumn={this.state.contactColumn}
			/>
		);
	}

	render() {
		const { rows, statusColumn } = this.props;
		const statusColorsOrders = statusColumn?.options?.statuses?.map(({ color }) => color);

		const data = Object.values(
			rows.reduce(
				(acc, row) => {
					if (!statusColumn) return acc;

					const statusValue =
						row.column_values[statusColumn?.id]?.value ?? statusColumn?.options?.statuses.findIndex((status) => status.color === "#c4c4c4");

					const status = statusColumn?.options?.statuses?.[statusValue] || {};
					const { label, color } = status;

					return {
						...acc,
						[color || "#c4c4c4"]: {
							count: (acc[color]?.count || 0) + 1,
							color: colorToRgba(color || "#c4c4c4", 0.75),
							label,
							value: statusValue,
							status: {
								...status,
								value: statusValue,
							},
						},
					};
				},
				statusColumn?.options?.statuses?.reduce((acc, status, index) => {
					const { color, label } = status;
					return {
						...acc,
						[color]: {
							status: {
								...status,
								value: index,
							},
							label,
							color,
							count: 0,
						},
					};
				}, {}) || {}
			)
		).sort((a, b) => {
			const aIndex = statusColorsOrders?.indexOf?.(a.color);
			const bIndex = statusColorsOrders?.indexOf?.(b.color);

			const posa = aIndex < 0 ? 100000 : aIndex;
			const posb = bIndex < 0 ? 100000 : bIndex;

			return posa - posb;
		});

		const pieTotal = Object.values(data).reduce((acc, data) => acc + data.count, 0);

		const pieData = data?.some(({ count }) => count)
			? data
					.map(({ count, color, label, status }) => ({
						label,
						name: label,
						y: count,
						color,
						status,
						sliced: this.state.selectedPieColor === color,
						selected: this.state.selectedPieColor === color,
					}))
					.sort((a, b) => {
						const aColorIndex = statusColorsOrders?.indexOf?.(a.color);
						const bColorIndex = statusColorsOrders?.indexOf?.(b.color);

						const posa = aColorIndex < 0 ? 100000 : aColorIndex;
						const posb = bColorIndex < 0 ? 100000 : bColorIndex;

						return posa - posb;
					})
			: [
					{
						label: this.props.t("board.calllist.stats_card.pie.no_company", "Inga företag"),
						name: this.props.t("board.calllist.stats_card.pie.no_company", "Inga företag"),
						y: 1,
						color: "#c4c4c4",
					},
			  ];

		return (
			<div className="board_view_callist_graph">
				<LegacyCard sectioned>
					<div className="board_view_callist_graph_inner">
						<CircleGraph
							size={175}
							plotOptions={{
								series: {
									events: {
										click: (event) => {
											if (event.point.status) this.openStatusModal(event.point.status);
										},
									},
								},
							}}
							options={{
								tooltip: {
									formatter() {
										return `${this.point.name}: ${this.point.percentage.toFixed()}% (${this.y})`;
									},
									percentageDecimals: 0,
								},
							}}
							pieOptions={{
								dataLabels: {
									enabled: pieData?.length > 1,
									distance: -30,
									// filter: {
									// 	property: "percentage",
									// 	operator: ">",
									// 	value: 9,
									// },
									verticalAlign: "top",
									connectorWidth: 1,
									formatter() {
										if (this.percentage < 10) return null;
										return this.percentage.toFixed() + "%";
									},
									percentageDecimals: 0,
								},
							}}
							series={[
								{
									colorByPoint: true,
									innerSize: "35%",
									data: pieData,
								},
							]}
						/>
						<div>
							{/* <h2>{this.props.t("board.calllist.stats_card.title", "Lista med totalt {{count}} kontakter", { count: rows.length })}</h2> */}
							<table>
								<thead>
									<tr>
										<th>{this.props.t("board.calllist.smalltable.labels.name.label", "Utfall")}</th>
										<th>{this.props.t("board.calllist.smalltable.labels.percentage.label", "Procent")}</th>
										<th>{this.props.t("board.calllist.smalltable.labels.count.label", "Antal")}</th>
									</tr>
								</thead>
								<tbody>
									{data.map((status) => {
										const { count, color, label } = status;
										const percentage = ((count || 0) / (pieTotal || 0)) * 100;

										return (
											<tr
												key={label}
												onClick={this.openStatusModal.bind(this, status)}
												onMouseEnter={() => {
													this.setState({ selectedPieColor: color });
												}}
												onMouseLeave={() => {
													this.setState({ selectedPieColor: null });
												}}
											>
												<td>
													<div style={{ width: 20, height: 20, borderRadius: "50%", backgroundColor: color }} />
													{label}
												</td>
												<td>{!Number.isNaN(percentage) ? percentage.toFixed() : 0}%</td>
												<td>{count || 0}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</LegacyCard>

				<Modal title={this.state.status?.label} large open={this.state.statusModalIsOpen} onClose={this.closeStatusModal.bind(this)}>
					<Modal.Section>
						<div className="stats-card-table-modal board_view_callist_list">
							<TableVirtuoso
								width="100%"
								ref={this.virtuoso}
								totalListHeightChanged={(height) => {
									// if (!myMessage.current) handleScroll();
								}}
								initialTopMostItemIndex={0}
								firstItemIndex={0}
								// atTopThreshold={0}
								style={{ flex: 1 }}
								data={this.state.statusRows}
								increaseViewportBy={{ top: 250, bottom: 250 }}
								computeItemKey={(_, row) => {
									return row.id;
								}}
								fixedHeaderContent={(index, user) => (
									<tr className="">
										<th>
											<span style={{ padding: "0.6250rem", justifyContent: "start" }}>
												{this.props.t("board.calllist.labels.name.label", "Namn")}
											</span>
										</th>
										<th>
											<span style={{ padding: "0.6250rem", justifyContent: "center" }}>{this.state.statusColumn?.title}</span>
										</th>
										<th>
											<span style={{ padding: "0.6250rem", justifyContent: "center" }}>{this.state.phoneColumn?.title}</span>
										</th>
									</tr>
								)}
								itemContent={this.renderRow.bind(this)}
								components={{
									TableRow: ({ children, item, style, ...rest }) => {
										return (
											<tr
												key={item.id}
												data-index={rest["data-index"]}
												data-item-index={rest["data-item-index"]}
												data-known-size={rest["data-known-size"]}
												className={String(this.state.selectedRow?.id) === String(item?.id) ? "selected" : ""}
												onClick={() => {
													this.props.setSelectorRow(item);
												}}
												style={{ ...style, cursor: "pointer" }}
											>
												{children}
											</tr>
										);
									},
									EmptyPlaceholder: () => {
										return <BoardEmptyScreen board={this.props.board} noheader />;
									},
								}}
							/>
						</div>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["board", "common"], {
	withRef: true,
})(StatsCard);
