import React, { Component } from "react";

class HeartbeatIndicator extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="unreadIndicator" style={this.props.style}>
				<div className="unreadIndicator-heartbeat" />
				<div className="unreadIndicator-inner" />
			</div>
		);
	}
}
export default HeartbeatIndicator;
