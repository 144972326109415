export default (state = {}, action) => {
	switch (action.type) {
		case "SET_BOARD_VISITORS": {
			const visitors = state;
			for (let i = 0; i < action.visitors.length; i++) {
				const row = action.visitors[i];
				const rowId = row.id;
				visitors[rowId] = row;
			}
			return visitors;
		}
		case "UPDATE_BOARD_VISITORS": {
			state[action.visitor.id] = action.visitor;
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};
