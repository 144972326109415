import React from "react";

const SadFaceIcon = ({ color = "D1505F" }) => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			opacity="0.3"
			d="M10.7402 3.60034C6.87273 3.60034 3.74023 6.73284 3.74023 10.6003C3.74023 14.4678 6.87273 17.6003 10.7402 17.6003C14.6077 17.6003 17.7402 14.4678 17.7402 10.6003C17.7402 6.73284 14.6077 3.60034 10.7402 3.60034ZM13.8027 7.10034C14.529 7.10034 15.1152 7.68659 15.1152 8.41284C15.1152 9.13909 14.529 9.72534 13.8027 9.72534C13.0765 9.72534 12.4902 9.13909 12.4902 8.41284C12.4902 7.68659 13.0765 7.10034 13.8027 7.10034ZM7.67773 7.10034C8.40398 7.10034 8.99023 7.68659 8.99023 8.41284C8.99023 9.13909 8.40398 9.72534 7.67773 9.72534C6.95148 9.72534 6.36523 9.13909 6.36523 8.41284C6.36523 7.68659 6.95148 7.10034 7.67773 7.10034ZM6.26898 14.9753C6.96898 13.1903 8.70148 11.9128 10.7402 11.9128C12.779 11.9128 14.5115 13.1903 15.2115 14.9753H6.26898Z"
			fill={color}
		/>
		<path
			d="M7.67773 9.72534C8.40261 9.72534 8.99023 9.13772 8.99023 8.41284C8.99023 7.68797 8.40261 7.10034 7.67773 7.10034C6.95286 7.10034 6.36523 7.68797 6.36523 8.41284C6.36523 9.13772 6.95286 9.72534 7.67773 9.72534Z"
			fill={color}
		/>
		<path
			d="M10.7315 1.85034C5.90148 1.85034 1.99023 5.77034 1.99023 10.6003C1.99023 15.4303 5.90148 19.3503 10.7315 19.3503C15.5702 19.3503 19.4902 15.4303 19.4902 10.6003C19.4902 5.77034 15.5702 1.85034 10.7315 1.85034ZM10.7402 17.6003C6.87273 17.6003 3.74023 14.4678 3.74023 10.6003C3.74023 6.73284 6.87273 3.60034 10.7402 3.60034C14.6077 3.60034 17.7402 6.73284 17.7402 10.6003C17.7402 14.4678 14.6077 17.6003 10.7402 17.6003ZM10.7402 11.9128C8.70148 11.9128 6.96898 13.1903 6.26898 14.9753H15.2115C14.5115 13.1903 12.779 11.9128 10.7402 11.9128Z"
			fill={color}
		/>
		<path
			d="M13.8027 9.72534C14.5276 9.72534 15.1152 9.13772 15.1152 8.41284C15.1152 7.68797 14.5276 7.10034 13.8027 7.10034C13.0779 7.10034 12.4902 7.68797 12.4902 8.41284C12.4902 9.13772 13.0779 9.72534 13.8027 9.72534Z"
			fill={color}
		/>
	</svg>
);

export default SadFaceIcon;
