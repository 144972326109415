import React from "react";
import DocumentListsTabsWrapper from "../DocumentListsTabsWrapper";
import useContact from "src/js/hooks/useContact";
import Badge from "src/js/components/Badge";
import { DealingsIcon } from "src/js/icons";
import Colors from "src/js/Colors";
import UpcomingActivity from "../UpcomingActivity";
import LateActivity from "../LateActivity";
import TimeLine from "src/js/components/TimeLine";
import { useHistory, useParams } from "react-router-dom";

const Activities = () => {
	const { id }: any = useParams();
	const { t, contact, counts, isLoadingCounts } = useContact(id);
	const history = useHistory();
	const nestedTab = (history.location.state as any)?.nested_tab;

	const tabs = [
		{
			title: t("contact.tabs.activities.label", "Aktiviteter"),
			id: "activities",
			loading: isLoadingCounts,
			icon: <DealingsIcon color="var(--textColor)" />,
			content: <TimeLine expanded disabled={contact?.removed} history={history} contact={contact} isCompany={contact.is_company} />,
		},
		{
			title: t("contact.tabs.upcoming_tasks.label", "Kommande aktiviteter"),
			badge: <Badge color={Colors.green}>{counts.upcoming_deadline_rows}</Badge>,
			id: "upcoming_tasks",
			loading: isLoadingCounts,
			icon: <DealingsIcon color="var(--textColor)" />,
			content: <UpcomingActivity contact={contact} />,
		},
		{
			title: t("contact.tabs.late_tasks.label", "Försenade aktiviteter"),
			badge: <Badge color={Colors.red}>{counts.late_deadline_rows}</Badge>,
			id: "late_tasks",
			loading: isLoadingCounts,
			icon: <DealingsIcon color="var(--textColor)" />,
			content: <LateActivity contact={contact} />,
		},
	];

	return <DocumentListsTabsWrapper defaultTabIndex={nestedTab ? tabs.findIndex((t) => t.id === nestedTab) : 0} tabs={tabs} />;
};
export default React.memo(Activities);
