export default (state = {}, action) => {
	switch (action.type) {
		case "SET_FAVORITE_BOARDS":
			if (!action.boards) return state;
			return action.boards;
		case "ADD_FAVORITE_BOARD": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.board.id) {
					return newState;
				}
			}
			newState.push(action.board);
			return newState;
		}
		case "UPDATE_FAVORITE_BOARD": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.board.id) {
					newState[i] = action.board;
				}
			}
			return newState;
		}
		case "REMOVE_FAVORITE_BOARD": {
			const newState = [];
			for (let i = 0; i < state.length; i++) {
				if (state[i].id != action.id) {
					newState.push(state[i]);
				}
			}
			return newState;
		}
		default:
			return state;
	}
};
