import React, { Component } from "react";
import { Button, Icon } from "@shopify/polaris";
import { CirclePlusMajor, EditMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import Page from "../../components/page";
import GoalModal from "../../components/GoalModal.js";
import GoalView from "./GoalView.js";
import BuildingIcon from "../../icons/BuildingIcon.js";
import { store } from "../../store/index.js";
import IndexTable from "src/js/components/IndexTable";

class Goals extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentGoal: {
				type: "",
			},
			selectedGoalId: props.match.params.id,
		};
	}

	renderItem(item) {
		return (
			<div
				className={`goal-list-item${String(this.state.selectedGoalId) === String(item.id) ? " active" : ""}`}
				onClick={this.selectGoal.bind(this, item.id)}
			>
				<div className="goal_title">
					<h3>{item.title}</h3>
					{store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && <Button plain icon={EditMajor} onClick={() => this.editGoal({ id: item.id })} />}
				</div>
			</div>
		);
	}

	onItemsFetched(items) {
		if (items && items[0] && !this.state.selectedGoalId) {
			this.selectGoal(items[0].id);
		}
	}

	editGoal(item) {
		this.setState({ goalModalOpen: true, currentGoal: item });
	}

	selectGoal(selectedGoalId) {
		this.setState({ selectedGoalId });
		if (selectedGoalId) {
			this.props.history.push(`/admin/goals/${selectedGoalId}`);
		} else {
			this.props.history.push("/admin/goals");
		}
	}

	render() {
		return (
			<Page title="" titleHidden>
				<div className="goals_wrapper">
					<div className="goals_section-1">
						<div className="section_header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<h1 className="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
								{this.props.t("goals.title", "Målsättningar")} <BuildingIcon />
							</h1>
						</div>

						<IndexTable
							limit={10}
							history={this.props.history}
							setRefreshHandler={(refreshHandler) => {
								this.refresh = refreshHandler;
							}}
							// title="Målsättningar"
							extraHeader={
								<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.6250rem" }}>
									{this.props.t("goals.title2", "Alla målsättningar")}

									{store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && (
										<Button plain onClick={() => this.setState({ goalModalOpen: true, currentGoal: {} })}>
											<div className="inner_button">
												<Icon source={CirclePlusMajor} />
											</div>
										</Button>
									)}
								</div>
							}
							onItemsFetched={this.onItemsFetched.bind(this)}
							// primaryAction={{ content: "Ny målsättning", onAction: this.editGoal.bind(this, { type: "" }) }}
							savedSearchHandle="goals"
							resourceUrl="/api/goals.json"
							resourceHandle="goals"
							resourceName={{
								singular: this.props.t("goals.singular", "målsättning"),
								plural: this.props.t("goals.plural", "målsättningar"),
							}}
							columns={[{ render: this.renderItem.bind(this) }]}
							filters={[]}
							// filters={filters}
							sortOptions={[]}
							promotedBulkActions={[]}
							bulkActions={[]}
						/>
					</div>
					<div className="goals_section-2">
						{this.state.selectedGoalId && (
							<GoalView
								history={this.props.history}
								updateState={(state) => {
									setTimeout(() => this.setState(state), 0);
								}}
								ref={(ref) => {
									this.goalViewRef = ref;
								}}
								id={this.state.selectedGoalId}
							/>
						)}
					</div>

					<GoalModal
						onRemove={() => {
							// this.refresh();
							this.selectGoal(null);
						}}
						open={this.state.goalModalOpen}
						goal={this.state.currentGoal}
						onClose={() => {
							this.setState({ goalModalOpen: false, currentGoal: {} });
						}}
						onCreate={(goal) => {
							this.setState({ goalModalOpen: false, currentGoal: {} });
							this.refresh();
							if (this.goalViewRef) this.goalViewRef.fetchItem(goal.id, false, true);

							this.selectGoal(goal.id);
						}}
					/>
				</div>
			</Page>
		);
	}
}

export default withTranslation(["goals", "common"], { withRef: true })(Goals);
