import { Checkbox, Select } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Modal from "src/js/components/modal";
import styled from "styled-components";
import Searchfield from "src/js/components/search_field";
import ScriveDocumentContext from "../ScriveDocumentContext";
import { defaultPartyFields, getPartyFieldEditableContent, getPartyName, sortFields } from "../Utilities";

type ScrivePartyModalProps = {
	party: ScrivePartyType;
	open: boolean;
	onClose: () => void;
} & WithTranslation;

const ScrivePartyModal = ({ party, t, open, onClose }: ScrivePartyModalProps) => {
	const [form, setForm] = useState(party);
	const [fieldErrors, setFieldErrors] = useState({});
	const { form: document, handleSave, isSaving } = useContext(ScriveDocumentContext);
	const wrapperRef = React.useRef(null);
	const [removing, setRemoving] = useState(false);

	const handleRemoveParty = async () => {
		const doc = { ...document } as ScriveDocumentType;
		setRemoving(true);
		try {
			await handleSave({ id: doc.id, parties: doc.parties.filter((p) => p.id !== form.id) }, false);
			handleClose();
		} catch (error) {
			console.error("error:", error);
		}
		setRemoving(false);
	};

	const handleSelectContact = (contact: ContactType) => {
		// ? Istället för att använda våra kontakt uppgifter, ska vi kanske göra en fetch emot scrive och söka efter samma email address etc.. och använda deras uppgifer istället?
		// ? Eller ska vi kanske spara scrivec parrt id i våra kontakter?
		const company = (contact.parent || (contact.is_company ? contact : null)) as ContactType;
		const newParty = { ...party };
		newParty.title = contact.name;
		// "name" | "email" | "company" | "mobile" | "personal_number" | "full_name" | "company_number";
		const fields = newParty?.fields?.map((f) => {
			const field = { ...f };
			const [firstName, lastName] = contact.last_name ? [contact.first_name, contact.last_name] : contact.name.split(" ", 2);
			if (field.type === "name" && field.order === 1) field.value = `${firstName || ""}`;
			if (field.type === "name" && field.order === 2) field.value = `${lastName || ""}`;
			if (field.type === "email") field.value = `${contact.email}`;
			if (field.type === "mobile") field.value = (contact.phone || contact.mobile_phone) + "";
			if (field.type === "company_number") field.value = company?.orgnr || "";
			if (field.type === "company") field.value = company?.name || "";
			// if (field.type === "personal_number") field.value = contact.
			return field;
		});

		newParty.fields = fields;
		setForm(newParty);
		setFieldErrors({});
	};

	const getFieldChangeHandler =
		(field: ScriveFieldType, index: number, property = "value") =>
		(value: string | boolean) => {
			setForm((c: ScrivePartyType) => {
				setFieldErrors((c) => ({ ...c, [field.type]: "" }));
				const newForm = { ...c };
				const newFields = [...newForm.fields];
				const foundIndex = newFields.findIndex((f, fIndex) => f.type === field.type && fIndex === index);

				const newObject = { ...newFields[foundIndex], [property]: value };
				// if (field.type === "personal_number" && property === "value" && value) {
				// 	(newObject as SignatoryFieldStandard).is_obligatory = true;
				// }

				newFields[foundIndex] = newObject;
				newForm.fields = newFields;
				return newForm;
			});
		};

	const getChangeHandler = (field: ScrivePartyTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: ScrivePartyType) => {
			const newForm = { ...c };

			if (field === "delivery_method") {
				newForm.delivery_method = value ? "email_mobile" : "email";
				newForm.confirmation_delivery_method = value ? "email_mobile" : "email";
			} else if (field === "authentication_method_to_sign") {
				const newFields = [...newForm.fields];
				const foundIndex = newFields.findIndex((f, fIndex) => f.type === "personal_number");

				newForm.authentication_method_to_sign = value ? "se_bankid" : "standard";
				newForm.authentication_method_to_view = "standard";
				// newForm.authentication_method_to_view = value ?  "standard" : newFields[foundIndex]?.value ? "se_bankid" : "standard";

				if (value) {
					const newObject = {
						...newFields[foundIndex],
						editable_by_signatory: true,
						should_be_filled_by_sender: false,
					};
					newFields[foundIndex] = newObject;
				} else {
					const newObject = {
						...newFields[foundIndex],
						editable_by_signatory: true,
						should_be_filled_by_sender: false,
					};
					newFields[foundIndex] = newObject;
				}

				newForm.fields = newFields;
			} else if (field === "sign_order") {
				newForm.sign_order = parseInt(value as string);
			} else if (field == "signatory_role") {
				newForm.signatory_role = value as string;
				newForm.is_signatory = value == "signing_party";
				newForm.is_visible = value == "signing_party" ? null : c.is_visible;
			} else {
				newForm[field as string] = value;
			}

			return newForm;
		});
	};

	const name = getPartyName(party);

	const roleOptions = [
		{ value: "viewer", label: t("scrive.party.fields.options.viewer.label", "Granskare") },
		{ value: "signing_party", label: t("scrive.party.fields.options.signing_party.label", "Signerare") },
	];

	const orderOptions = document?.parties?.map((p, index) => ({ value: index + 1 + "", label: index + 1 + "" }));

	form.fields = defaultPartyFields?.reduce((acc: ScriveFieldType[], f: ScriveFieldType) => {
		if (!f.type) return acc;
		const found = acc.find((a) => a.type === f.type);
		if (found) return acc;
		return [...acc, f];
	}, form.fields);

	const sortedFields = sortFields(form.fields);

	const getFieldErrors = () => {
		const errors = form.fields?.reduce((acc: any, field: ScriveFieldType) => {
			if ("is_obligatory" in field && field.is_obligatory && "value" in field && !field.value && field.should_be_filled_by_sender) {
				acc[field.type] = t("scrive.party.fields.errors.required", "Fältet är obligatoriskt");
			}
			if (field.type === "mobile") {
				// if (field.value && !field.value.match(/^(\+46|0)7[02369]\d{7}$/)) {
				// 	acc[field.type] = t("scrive.party.fields.errors.mobile", "Mobilnumret är inte giltigt");
				// }

				if (form.delivery_method === "email_mobile" && !field.value) {
					acc[field.type] = t("scrive.party.fields.errors.mobile", "Mobilnumret är inte giltigt");
				}
			}

			if (field.type === "email" && (form.delivery_method === "email" || form.delivery_method === "email_mobile")) {
				if (
					!field.value ||
					!field.value.match(
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					)
				)
					acc[field.type] = t("scrive.party.fields.errors.email", "E-postadressen är inte giltigt");
			}

			return acc;
		}, {});

		return errors;
	};

	const handleSaveForm = async () => {
		const doc = { ...document } as ScriveDocumentType;
		const parties = [...doc.parties];

		if (form.id) {
			const index = parties.findIndex((p) => p.id === form.id);
			if (index >= 0) parties[index] = form;
		} else {
			parties.push(form);
		}

		const errors = getFieldErrors();
		if (Object.values(errors)?.length) {
			setFieldErrors(errors);
			return;
		}
		try {
			await handleSave({ id: doc.id, parties }, false);
			handleClose();
		} catch (error) {
			console.error("error:", error);
		}
	};
	const handleClose = () => {
		onClose();
	};

	useEffect(() => {
		if (!open) {
			setForm(party);
			setFieldErrors({});
		}
	}, [open, party]);

	// useEffect(() => {
	// 	if (wrapperRef.current && open) {
	// 		console.debug("wrapperRef.current:", wrapperRef.current);
	// 		const wrapper = wrapperRef.current as HTMLElement;
	// 		const suffixes = wrapper.querySelectorAll(".Polaris-TextField__Suffix");
	// 		console.debug("suffixes:", suffixes);
	// 		suffixes.forEach((suffix) => {
	// 			suffix.setAttribute("tabindex", "-1");
	// 		});
	// 	}
	// }, [open]);

	return (
		<Modal
			large
			title={name || t("scrive.party.modal.title", "Ny deltagare")}
			sectioned
			open={open}
			onClose={handleClose}
			primaryAction={{
				content: t("common.save", "Spara") as string,
				onAction: handleSaveForm,
				loading: isSaving,
				disabled: document?.status !== "preparation",
			}}
			secondaryActions={[
				{
					content: t("common.actions.cancel", "Avbryt") as string,
					onAction: handleClose,
				},
				{
					content: t("common.actions.remove", "Ta bort") as string,
					onAction: handleRemoveParty,
					destructive: true,
					disabled: document?.status !== "preparation",
					loading: removing,
					enabled: !!form.id && document?.status === "preparation" && !form.is_author,
				},
			].filter((a) => !("enabled" in a) || a.enabled)}
		>
			{!form.id && (
				<SearchfieldWrapper>
					<Searchfield
						label={t("scrive.fields.contact.label2", "Välj kontakt ifrån systemet")}
						placeholder="kontakt.."
						value={null}
						resource="contacts.json"
						onSelect={handleSelectContact}
						blurOnSelect
						id_handle="id"
						params={{
							removed: 0,
							is_visitor: 0,
							is_company: 0,
						}}
						label_handle="fullname"
						resource_handle="contacts"
						resourceName={{
							singular: t("contacts.singular", "Kontakt"),
							plural: t("contacts.plural", "Kontakter"),
						}}
					/>
				</SearchfieldWrapper>
			)}

			<Wrapper ref={wrapperRef}>
				{sortedFields.map((field, index) => {
					return getPartyFieldEditableContent(field, {
						key: index,
						onChange: getFieldChangeHandler(field, index),
						error: fieldErrors[field.type],
						disabled: ("should_be_filled_by_sender" in field && field.should_be_filled_by_sender) || undefined,
						required: "is_obligatory" in field && field.is_obligatory,
						helpText: field.description,
						// suffix: field.type === "personal_number" && (
						// 	<DropDown activatorStyle={{ opacity: 0.5 }}>
						// 		<FormLayout>
						// 			{"is_obligatory" in field && (
						// 				<Checkbox
						// 					label={t("scrive.party.fields.field.is_obligatory.label", "Ska fyllas i av mottagaren")}
						// 					checked={!field.is_obligatory}
						// 					onChange={getFieldChangeHandler(field, index, "is_obligatory")}
						// 				/>
						// 			)}
						// 		</FormLayout>
						// 	</DropDown>
						// ),
					});

					// return (
					// 	<TextField
					// 		error={fieldErrors[field.type]}
					// 		key={index}
					// 		label={getPartyFieldLabel(field)}
					// 		value={field.value}
					// 		onChange={getFieldChangeHandler(field, index)}
					// 		helpText={field.description}
					// 		disabled={field.should_be_filled_by_sender || undefined}
					// 		suffix={
					// 			<DropDown>
					// 				<FormLayout>
					// 					<Checkbox
					// 						label={t("scrive.party.fields.field.should_be_filled_by_sender.label", "Ska fyllas i av sändaren")}
					// 						checked={field.should_be_filled_by_sender}
					// 						onChange={getFieldChangeHandler(field, index, "should_be_filled_by_sender")}
					// 					/>
					// 				</FormLayout>
					// 			</DropDown>
					// 		}
					// 	/>
					// );
				})}
				<Select
					label={t("scive.party.fields.role.label", "Roll")}
					options={roleOptions}
					value={form.signatory_role}
					onChange={getChangeHandler("signatory_role")}
				/>
				<Select
					label={t("scive.party.fields.sign_order.label", "Inbjudningsordning")}
					options={orderOptions}
					value={typeof form.sign_order === "number" ? String(form.sign_order) : ""}
					onChange={getChangeHandler("sign_order")}
				/>
			</Wrapper>
			<CheckboxContainer>
				<Checkbox
					label={t("scrive.fields.delivery_method.label", "Skicka även SMS")}
					checked={form.delivery_method == "email_mobile"}
					onChange={getChangeHandler("delivery_method")}
				/>
				<Checkbox
					label={t("scrive.fields.authentication_method_to_sign.label", "Kräv BankID")}
					checked={form.authentication_method_to_sign == "se_bankid"}
					onChange={getChangeHandler("authentication_method_to_sign")}
				/>
				{form.signatory_role === "viewer" && !form.is_author && (
					<Checkbox
						label={t("scrive.fields.is_visible.label", "Synglig i dokumentet")}
						checked={!!form.is_visible}
						onChange={getChangeHandler("is_visible")}
					/>
				)}
			</CheckboxContainer>
		</Modal>
	);
};
export default withTranslation(["scrive", "common"])(ScrivePartyModal);

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.625rem;
`;
const CheckboxContainer = styled.div`
	margin-top: 0.625rem;
	display: flex;
	gap: 0.625rem;
`;

const SearchfieldWrapper = styled.div`
	margin-bottom: 1.875rem;
`;
