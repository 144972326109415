import i18next from "i18next";

const getContactActivityTypes = () => {
	return [
		{ value: "comment", label: i18next.t("contacts.activities.fields.type.options.comment", "Kommentar") },
		{ value: "email", label: i18next.t("contacts.activities.fields.type.options.email", "E-post") },
		{ value: "phone", label: i18next.t("contacts.activities.fields.type.options.phone", "Telefonsamtal") },
		{ value: "contact", label: i18next.t("contacts.activities.fields.type.options.contact", "Annan kontakt") },
		{ value: "meeting", label: i18next.t("contacts.activities.fields.type.options.meeting", "Möte") },
		{ value: "no_answer", label: i18next.t("contacts.activities.fields.type.options.no_answer", "Inget svar") },
		{ value: "new_deal", label: i18next.t("contacts.activities.fields.type.options.new_deal", "Ny affär") },
		{ value: "follow_up", label: i18next.t("contacts.activities.fields.type.options.follow_up", "Uppföljning") },
	];
};
export default getContactActivityTypes;
