import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledSettings = styled.div``;
export const StyledSettingsInnerCard = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	padding: 0.6250rem;
	gap: 0.1562rem;
	/* display: flex;
	flex-wrap: wrap;
	align-items: stretch; */
`;

const StyledSettingsItem = styled(NavLink)<{
	backgroundColor?: string;
	disabled?: boolean;
}>`
	height: 100%;
	background-color: ${({ backgroundColor }) => backgroundColor || "transparent"};
	transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, filter 0.2s ease-in-out;
	flex: 1;
	padding: 1.2500rem;
	font-size: 0.8750rem;
	font-weight: 400;
	line-height: 1.2500rem;
	color: var(--textColor);
	cursor: pointer;
	border-radius: 6px;
	text-decoration: none;
	column-gap: 0.6250rem;
	row-gap: 0.1562rem;
	position: relative;

	display: grid;
	grid-template-columns: 2.5000rem 1fr;
	grid-template-areas: "icon title" "icon description";
	grid-template-rows: max-content;

	/* min-width: 300px;   */
	/* max-width: 500px; */

	&:hover {
		color: var(--textColor);
	}

	${({ backgroundColor }) =>
		backgroundColor
			? css`
					&:hover {
						filter: brightness(1.5);
					}
			  `
			: css`
					&:hover {
						background-color: rgba(0, 0, 0, 0.25);
					}
			  `}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
			opacity: 0.5;
		`}
`;

const StyledSettingsItemTooltip = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
`;

const StyledSettingsItemTitle = styled.p`
	grid-area: title;
	font-weight: 700;
`;
const StyledSettingsItemDescription = styled.p`
	grid-area: description;
	color: #637381;
	font-size: 0.95em;
`;
const StyledSettingsItemIcon = styled.div`
	grid-area: icon;
	width: 2.5000rem;
	height: 2.5000rem;
	fill: #919eab;

	display: flex;
	justify-content: center;

	.Polaris-Icon,
	.Polaris-Spinner {
		margin: 0;
		margin-top: 0.3125rem;
	}
`;

interface StyledSettingsItemType {
	Title: typeof StyledSettingsItemTitle;
	Description: typeof StyledSettingsItemDescription;
	Icon: typeof StyledSettingsItemIcon;
	Tooltip: typeof StyledSettingsItemTooltip;
}

const SettingsItem: StyledSettingsItemType | any = StyledSettingsItem;
SettingsItem.Title = StyledSettingsItemTitle;
SettingsItem.Description = StyledSettingsItemDescription;
SettingsItem.Icon = StyledSettingsItemIcon;
SettingsItem.Tooltip = StyledSettingsItemTooltip;
export { SettingsItem };
