import i18next from "i18next";

const options = {
	label: i18next.t("fortnox.customer.label", "Kund"),
	value: "Fortnox.Customer",
	options: [
		{ label: i18next.t("fortnox.customer.fields.address1.label", "Adress 1"), value: "Fortnox.Customer.Address1" },
		{ label: i18next.t("fortnox.customer.fields.address2.label", "Adress 2"), value: "Fortnox.Customer.Address2" },
		{ label: i18next.t("fortnox.customer.fields.city.label", "Stad"), value: "Fortnox.Customer.City" },
		{ label: i18next.t("fortnox.customer.fields.country.label", "Land"), value: "Fortnox.Customer.Country" },
		{ label: i18next.t("fortnox.customer.fields.comments.label", "Kommentarer"), value: "Fortnox.Customer.Comments" },
		{ label: i18next.t("fortnox.customer.fields.currency.label", "Valuta"), value: "Fortnox.Customer.Currency" },
		{ label: i18next.t("fortnox.customer.fields.costCenter.label", "Kostnadsställe"), value: "Fortnox.Customer.CostCenter" },
		{ label: i18next.t("fortnox.customer.fields.countryCode.label", "Landskod"), value: "Fortnox.Customer.CountryCode" },
		{ label: i18next.t("fortnox.customer.fields.active.label", "Aktiv"), value: "Fortnox.Customer.Active" },
		{ label: i18next.t("fortnox.customer.fields.customerNumber.label", "Kundnummer"), value: "Fortnox.Customer.CustomerNumber" },
		{
			label: i18next.t("fortnox.customer.fields.defaultDeliveryTypes.label", "Standardleveranstyper"),
			value: "Fortnox.Customer.DefaultDeliveryTypes",
			options: [
				{
					label: i18next.t("fortnox.customer.fields.defaultDeliveryTypes.invoice.label", "Faktura"),
					value: "Fortnox.Customer.DefaultDeliveryTypes.Invoice",
				},
				{
					label: i18next.t("fortnox.customer.fields.defaultDeliveryTypes.order.label", "Order"),
					value: "Fortnox.Customer.DefaultDeliveryTypes.Order",
				},
				{
					label: i18next.t("fortnox.customer.fields.defaultDeliveryTypes.offer.label", "Erbjudande"),
					value: "Fortnox.Customer.DefaultDeliveryTypes.Offer",
				},
			],
		},
		{
			label: i18next.t("fortnox.customer.fields.defaultTemplates.label", "Standardmallar"),
			value: "Fortnox.Customer.DefaultTemplates",
			options: [
				{
					label: i18next.t("fortnox.customer.fields.defaultTemplates.cashInvoice.label", "Kontantfaktura"),
					value: "Fortnox.Customer.DefaultTemplates.CashInvoice",
				},
				{ label: i18next.t("fortnox.customer.fields.defaultTemplates.invoice.label", "Faktura"), value: "Fortnox.Customer.DefaultTemplates.Invoice" },
				{ label: i18next.t("fortnox.customer.fields.defaultTemplates.offer.label", "Erbjudande"), value: "Fortnox.Customer.DefaultTemplates.Offer" },
				{ label: i18next.t("fortnox.customer.fields.defaultTemplates.order.label", "Order"), value: "Fortnox.Customer.DefaultTemplates.Order" },
			],
		},
		{ label: i18next.t("fortnox.customer.fields.deliveryAddress1.label", "Leveransadress 1"), value: "Fortnox.Customer.DeliveryAddress1" },
		{ label: i18next.t("fortnox.customer.fields.deliveryAddress2.label", "Leveransadress 2"), value: "Fortnox.Customer.DeliveryAddress2" },
		{ label: i18next.t("fortnox.customer.fields.deliveryCity.label", "Leveransstad"), value: "Fortnox.Customer.DeliveryCity" },
		{ label: i18next.t("fortnox.customer.fields.deliveryCountry.label", "Leveransland"), value: "Fortnox.Customer.DeliveryCountry" },
		{ label: i18next.t("fortnox.customer.fields.deliveryCountryCode.label", "Leveranslandskod"), value: "Fortnox.Customer.DeliveryCountryCode" },
		{ label: i18next.t("fortnox.customer.fields.deliveryFax.label", "Leveransfax"), value: "Fortnox.Customer.DeliveryFax" },
		{ label: i18next.t("fortnox.customer.fields.deliveryName.label", "Leveransnamn"), value: "Fortnox.Customer.DeliveryName" },
		{ label: i18next.t("fortnox.customer.fields.deliveryPhone1.label", "Leverans Telefon 1"), value: "Fortnox.Customer.DeliveryPhone1" },
		{ label: i18next.t("fortnox.customer.fields.deliveryPhone2.label", "Leverans Telefon 2"), value: "Fortnox.Customer.DeliveryPhone2" },
		{ label: i18next.t("fortnox.customer.fields.deliveryZipCode.label", "Leverans postnummer"), value: "Fortnox.Customer.DeliveryZipCode" },
		{ label: i18next.t("fortnox.customer.fields.email.label", "E-post"), value: "Fortnox.Customer.Email" },
		{ label: i18next.t("fortnox.customer.fields.emailInvoice.label", "E-postfaktura"), value: "Fortnox.Customer.EmailInvoice" },
		{ label: i18next.t("fortnox.customer.fields.emailInvoiceBCC.label", "E-postfaktura BCC"), value: "Fortnox.Customer.EmailInvoiceBCC" },
		{ label: i18next.t("fortnox.customer.fields.emailInvoiceCC.label", "E-postfaktura CC"), value: "Fortnox.Customer.EmailInvoiceCC" },
		{ label: i18next.t("fortnox.customer.fields.emailOffer.label", "E-post erbjudande"), value: "Fortnox.Customer.EmailOffer" },
		{ label: i18next.t("fortnox.customer.fields.emailOfferBCC.label", "E-post erbjudande BCC"), value: "Fortnox.Customer.EmailOfferBCC" },
		{ label: i18next.t("fortnox.customer.fields.emailOfferCC.label", "E-post erbjudande CC"), value: "Fortnox.Customer.EmailOfferCC" },
		{ label: i18next.t("fortnox.customer.fields.emailOrder.label", "E-postorder"), value: "Fortnox.Customer.EmailOrder" },
		{ label: i18next.t("fortnox.customer.fields.emailOrderBCC.label", "E-postorder BCC"), value: "Fortnox.Customer.EmailOrderBCC" },
		{ label: i18next.t("fortnox.customer.fields.emailOrderCC.label", "E-postorder CC"), value: "Fortnox.Customer.EmailOrderCC" },
		{ label: i18next.t("fortnox.customer.fields.externalReference.label", "Extern referens"), value: "Fortnox.Customer.ExternalReference" },
		{ label: i18next.t("fortnox.customer.fields.fax.label", "Fax"), value: "Fortnox.Customer.Fax" },
		{ label: i18next.t("fortnox.customer.fields.gln.label", "GLN"), value: "Fortnox.Customer.GLN" },
		{ label: i18next.t("fortnox.customer.fields.glnDelivery.label", "GLN Leverans"), value: "Fortnox.Customer.GLNDelivery" },
		{
			label: i18next.t("fortnox.customer.fields.invoiceAdministrationFee.label", "Faktura administrationsavgift"),
			value: "Fortnox.Customer.InvoiceAdministrationFee",
		},
		{ label: i18next.t("fortnox.customer.fields.invoiceDiscount.label", "Fakturarabatt"), value: "Fortnox.Customer.InvoiceDiscount" },
		{ label: i18next.t("fortnox.customer.fields.invoiceFreight.label", "Fakturafrakt"), value: "Fortnox.Customer.InvoiceFreight" },
		{ label: i18next.t("fortnox.customer.fields.invoiceRemark.label", "Faktura anmärkning"), value: "Fortnox.Customer.InvoiceRemark" },
		{ label: i18next.t("fortnox.customer.fields.name.label", "Namn"), value: "Fortnox.Customer.Name" },
		{ label: i18next.t("fortnox.customer.fields.organisationNumber.label", "Organisationsnummer"), value: "Fortnox.Customer.OrganisationNumber" },
		{ label: i18next.t("fortnox.customer.fields.ourReference.label", "Vår referens"), value: "Fortnox.Customer.OurReference" },
		{ label: i18next.t("fortnox.customer.fields.phone1.label", "Telefon 1"), value: "Fortnox.Customer.Phone1" },
		{ label: i18next.t("fortnox.customer.fields.phone2.label", "Telefon 2"), value: "Fortnox.Customer.Phone2" },
		{ label: i18next.t("fortnox.customer.fields.priceList.label", "Prislista"), value: "Fortnox.Customer.PriceList" },
		{ label: i18next.t("fortnox.customer.fields.project.label", "Projekt"), value: "Fortnox.Customer.Project" },
		{ label: i18next.t("fortnox.customer.fields.salesAccount.label", "Försäljningskonto"), value: "Fortnox.Customer.SalesAccount" },
		{
			label: i18next.t("fortnox.customer.fields.showPriceVATIncluded.label", "Visa pris inklusive moms"),
			value: "Fortnox.Customer.ShowPriceVATIncluded",
		},
		{ label: i18next.t("fortnox.customer.fields.termsOfDelivery.label", "Leveransvillkor"), value: "Fortnox.Customer.TermsOfDelivery" },
		{ label: i18next.t("fortnox.customer.fields.termsOfPayment.label", "Betalningsvillkor"), value: "Fortnox.Customer.TermsOfPayment" },
		{ label: i18next.t("fortnox.customer.fields.type.label", "Typ"), value: "Fortnox.Customer.Type" },
		{ label: i18next.t("fortnox.customer.fields.vatNumber.label", "Momsnummer"), value: "Fortnox.Customer.VATNumber" },
		{ label: i18next.t("fortnox.customer.fields.vatType.label", "Momstyp"), value: "Fortnox.Customer.VATType" },
		{ label: i18next.t("fortnox.customer.fields.visitingAddress.label", "Besöksadress"), value: "Fortnox.Customer.VisitingAddress" },
		{ label: i18next.t("fortnox.customer.fields.visitingCity.label", "Besöksstad"), value: "Fortnox.Customer.VisitingCity" },
		{ label: i18next.t("fortnox.customer.fields.visitingCountry.label", "Besöksland"), value: "Fortnox.Customer.VisitingCountry" },
		{ label: i18next.t("fortnox.customer.fields.visitingCountryCode.label", "Besökslandskod"), value: "Fortnox.Customer.VisitingCountryCode" },
		{ label: i18next.t("fortnox.customer.fields.visitingZipCode.label", "Besöks postnummer"), value: "Fortnox.Customer.VisitingZipCode" },
		{ label: i18next.t("fortnox.customer.fields.wayOfDelivery.label", "Leveranssätt"), value: "Fortnox.Customer.WayOfDelivery" },
		{ label: i18next.t("fortnox.customer.fields.www.label", "Webbsida"), value: "Fortnox.Customer.WWW" },
		{ label: i18next.t("fortnox.customer.fields.yourReference.label", "Din referens"), value: "Fortnox.Customer.YourReference" },
		{ label: i18next.t("fortnox.customer.fields.zipCode.label", "Postnummer"), value: "Fortnox.Customer.ZipCode" },
	],
};
export default options;
