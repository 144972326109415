/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable quotes */
import React, { Component } from "react";
import { fabric } from "fabric";
import { withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import API from "../../../API";
import Toolbar from "./Toolbar";
import Canvas from "./Canvas";
import Skeleton1Col from "../../../components/skeleton_1col";

import Table from "./Elements/Table";
import Link from "./Elements/Link";
import Toast from "src/js/components/Toast";

fabric.Object.NUM_FRACTION_DIGITS = 10;
fabric.Textbox.prototype._wordJoiners = /[\n|" "]/;

//Custom Elements
fabric.Table = Table;
fabric.Link = Link;

class EditorView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: { pages: [] },
			id: props.id || props.match?.params?.id,
			height: 1122,
			width: 794,
			originalHeight: 1122,
			originalWidth: 794,
			zoom: 1,
			activePage: this.getParamPage(props),
		};
		this.canvases = [];
		this.positions = { left: this.state.height / 2, top: this.state.width / 2, originX: "center", originY: "center" };
		this.pasteEvent = this.handlePasteEvent.bind(this);
		this.copyEvent = this.handleCopyEvent.bind(this);
	}

	componentDidMount() {
		this.fetch();
		document.addEventListener("paste", this.pasteEvent);
		document.addEventListener("copy", this.copyEvent);

		this.fixSize();
	}

	componentWillUnmount() {
		document.removeEventListener("paste", this.pasteEvent);
		document.removeEventListener("copy", this.copyEvent);
	}

	fixSize() {
		if (this.props.dynamicWidth) {
			const extraPadding = this.props.extraPadding || 0;

			const padding =
				parseInt(window.getComputedStyle(document.querySelector(".editor-scroll__inner"), null).getPropertyValue("padding-left")) +
				parseInt(window.getComputedStyle(document.querySelector(".editor-scroll__inner"), null).getPropertyValue("padding-right"));
			const width = document.querySelector(".editor-scroll").clientWidth - 2 - padding - extraPadding;
			const height = width * 1.1411;
			this.setState({ width, height, originalHeight: height, originalWidth: width });
			this.positions = Object.assign(this.positions, { left: height / 2, top: width / 2 });
		}
	}

	copy() {
		const copies = [];
		if (this.state.activeObjects) {
			this.state.activeObjects.forEach((object) => {
				copies.push(object);
			});
		}

		this.setState({ copies });
	}

	handlePasteEvent(e) {
		if (!this.state.activeObjects || !this.state.activeObjects.length) {
			if (navigator?.clipboard?.readText) {
				navigator.clipboard.readText().then((clipText) => {
					if (clipText) this.addText(clipText);
				});
			} else {
				console.warn("navigator.clipboard.readText is not supported in this browser");
			}
		}
	}

	handleCopyEvent(e) {
		// navigator.clipboard.readText().then((clipText) => {
		// 	console.debug("copy tet: ", clipText);
		// });
		// e.preventDefault();
		// e.stopPropagation();
		this.copy();
	}

	duplicateObject() {
		const objects = this.state.activeObjects && this.state.activeObjects.length ? this.state.activeObjects : this.state.copies;

		if (objects && objects.length) {
			if (objects.length > 1) {
				const clones = [];
				objects.forEach((object) => {
					object.clone((obj) => {
						obj.set({
							left: obj.left + 10,
							top: obj.top + 10,
							evented: true,
						});

						clones.push(obj);
					});
				});
				const group = new fabric.Group(clones);
				this.canvases[this.state.activePage].canvas.add(group);
				this.canvases[this.state.activePage].canvas.setActiveObject(group);
				this.setActiveObject([group]);
			} else if (objects && objects[0]) {
				objects[0].clone((obj) => {
					obj.set({
						left: obj.left + 10,
						top: obj.top + 10,
						evented: true,
					});

					this.canvases[this.state.activePage].canvas.add(obj);
					obj.setCoords();
					this.canvases[this.state.activePage].canvas.setActiveObject(obj);
					this.setActiveObject([obj]);
				});
			}
			this.setState({ copies: objects });
		}
	}

	getParamPage(props = this.props) {
		if (props.location) {
			const params = new URLSearchParams(props.location.search);
			if (params.get("page") !== null && params.get("page") !== undefined) return Math.max(params.get("page") - 1, 0);
		}
		return 0;
	}

	fetch() {
		this.setState({ loading: true });
		// API.get(`/api/pdf_templates/${this.state.id}.json`, { params: { variables: this.props.variables || null, rowId: this.props.rowId } })
		API.post(`/api/pdf_templates/${this.state.id}.json`, { variables: this.props.variables || null, rowId: this.props.rowId })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				if (result.data.pdf_template) {
					this.setState({ form: result.data.pdf_template, lastFontFamily: result.data.pdf_template.last_font_family, loading: false }, () => {
						const scrollTo = `cw${this.state.activePage}`;
						this.scrollTo(scrollTo);
					});
				}
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	onClickoutside(e) {
		if (e.target.className === "editor-scroll") {
			this.canvases?.[this.state.activePage]?.canvas?.discardActiveObject?.();
		}
	}

	addPage() {
		if (!this.state.form.pages) {
			this.state.form.pages = [{ data: null, tempId: Date.now() }];
		} else {
			this.state.form.pages.push({ data: null, tempId: Date.now() });
		}
		this.setState({ form: this.state.form });
	}

	addTable(data) {
		const ele = new fabric.Table(data);

		this.canvases[this.state.activePage].canvas.add(ele);
		this.canvases[this.state.activePage].canvas.setActiveObject(ele);
		this.setActiveObject([ele]);
		// ele.enterEditing();
		// ele.selectAll();
	}

	addLink(text, href) {
		const ele = new fabric.Link(text || "Link", {
			left: 2,
			right: 2,
			top: 2,
			fontSize: this.state.lastFontSize || 20,
			fontFamily: this.state.lastFontFamily || "Times New Roman",
			// splitByGrapheme: true,
			dynamicMinWidth: this.state.originalWidth - 4,
			minWidth: this.state.originalWidth - 4,
			width: this.state.originalWidth - 4,
			lockScalingY: true,
			lockSkewingY: true,
			underline: true,
			fill: Colors.blue,
		});

		ele.href = href;

		this.canvases[this.state.activePage].canvas.add(ele);
		this.canvases[this.state.activePage].canvas.setActiveObject(ele);
		this.setActiveObject([ele]);
		ele.enterEditing();
		ele.selectAll();
	}

	addText(text) {
		const ele = new fabric.Textbox(text || "Text", {
			left: 2,
			right: 2,
			top: 2,
			fontSize: this.state.lastFontSize || 20,
			fontFamily: this.state.lastFontFamily || "Times New Roman",
			// splitByGrapheme: true,
			dynamicMinWidth: this.state.originalWidth - 4,
			minWidth: this.state.originalWidth - 4,
			width: this.state.originalWidth - 4,
			lockScalingY: true,
			lockSkewingY: true,
		});
		this.canvases[this.state.activePage].canvas.add(ele);
		this.canvases[this.state.activePage].canvas.setActiveObject(ele);
		this.setActiveObject([ele]);
		ele.enterEditing();
		ele.selectAll();
	}

	addLine() {
		const width = 300;
		const height = 20;
		const ele = new fabric.Line(
			[width, 0, height, 0],
			Object.assign(this.positions, {
				stroke: "rgb(0,0,0)",
				strokeWidth: 2,
			})
		);
		this.canvases[this.state.activePage].canvas.add(ele);
	}

	addRect() {
		const width = 30;
		const height = 30;
		const ele = new fabric.Rect(
			Object.assign(this.positions, {
				height,
				width,
				fill: "transparent",
				stroke: "rgb(0,0,0)",
				strokeWidth: 5,
			})
		);
		this.canvases[this.state.activePage].canvas.add(ele);
	}

	addCircle() {
		const width = 50;
		const height = 50;
		const ele = new fabric.Circle(
			Object.assign(this.positions, {
				radius: 50,
				height,
				width,
				fill: "transparent",
				stroke: "rgb(0,0,0)",
				strokeWidth: 5,
			})
		);
		this.canvases[this.state.activePage].canvas.add(ele);
	}

	zoom(direction = "in") {
		this.setState(
			({ height, width, zoom }) => {
				if (typeof direction == "number") {
					return { height: this.state.originalHeight * direction, width: this.state.originalWidth * direction, zoom: direction };
				}
				const v = 1.2;
				if (direction === "out") {
					return { height: height / v, width: width / v, zoom: zoom / v };
				}
				if (direction === "in") {
					return { height: height * v, width: width * v, zoom: zoom * v };
				}
			},
			() => {
				this.canvases?.forEach?.((c) => {
					const { canvas } = c || {};
					canvas?.setZoom(this.state.zoom);
					canvas?.setHeight(this.state.height);
					canvas?.setWidth(this.state.width);
				});
			}
		);
	}

	addImage(urls, style) {
		if (!urls || !Array.isArray(urls) || !urls.length) return null;
		urls.forEach((url) => {
			// eslint-disable-next-line no-new, new-cap
			new fabric.Image.fromURL(url, (img) => {
				if (style && typeof style === "function") {
					const s = style(img);
					if (s) img.set(style(img));
				} else if (style && typeof style === "object") {
					img.set(style);
				} else {
					const { height, width } = img.getOriginalSize() || {};
					const aspectRatio = width / height;

					if ((height > this.state.height * 0.8 || width > this.state.width * 0.8) && aspectRatio === 1) {
						img.scaleToWidth(this.state.width * 0.8);
					} else if (height > this.state.height * 0.8 && aspectRatio < 1) {
						img.scaleToHeight(this.state.height * 0.8);
					} else if (width > this.state.width * 0.8 && aspectRatio > 1) {
						img.scaleToWidth(this.state.width * 0.8);
					}
				}
				this.canvases[this.state.activePage].canvas.add(img);
				// this.canvases[this.state.activePage].canvas.renderAll();
			});
		});
	}

	setActiveObject(activeObjects) {
		const uniqueId = Date.now();

		this.setState({ activeObjects, uniqueId });
	}

	setActivePage(v) {
		this.setState({ activePage: v });
	}

	scrollTo(scrollTo) {
		if (scrollTo) {
			setTimeout(() => {
				const element = document.getElementById(scrollTo);
				const yOffset = -175;
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: "smooth" });
			}, 1);
		}
	}

	goToPage(page) {
		if (page >= this.canvases.length) return null;
		if (page < 0) return null;

		this.setState({ activePage: page }, () => {
			const scrollTo = `cw${this.state.activePage}`;
			this.scrollTo(scrollTo);
		});
	}

	gotoNextPage() {
		this.goToPage(this.state.activePage + 1);
	}

	gotoPrevPage() {
		this.goToPage(this.state.activePage - 1);
	}

	removePage(index) {
		setTimeout(() => {
			this.state.form.pages.splice(index, 1);
			this.setState({ form: this.state.form });
		}, 100);
	}

	clearCanvas() {
		this.canvases[this.state.activePage].canvas.getObjects().forEach((obj) => {
			this.canvases[this.state.activePage].canvas.remove(obj);
		});
	}

	duplicatePage(index) {
		const canvas = JSON.stringify(this.canvases[index].canvas);
		this.state.form.pages.splice(index, 0, { raw_data: canvas });
		this.setState({ form: this.state.form });
	}

	movePageUp(index) {
		if (index === 0) return null;
		const curr = this.state.form.pages[index];
		this.state.form.pages[index] = this.state.form.pages[index - 1];
		this.state.form.pages[index - 1] = curr;

		const currCanvas = this.canvases[index];
		this.canvases[index] = this.canvases[index - 1];
		this.canvases[index - 1] = currCanvas;

		this.setState({ form: this.state.form });
	}

	movePageDown(index) {
		if (index >= this.state.form.pages.length - 1) return null;

		const curr = this.state.form.pages[index];
		this.state.form.pages[index] = this.state.form.pages[index + 1];
		this.state.form.pages[index + 1] = curr;

		const currCanvas = this.canvases[index];
		this.canvases[index] = this.canvases[index + 1];
		this.canvases[index + 1] = currCanvas;

		this.setState({ form: this.state.form });
	}

	async save(name) {
		const pages = this.state.form.pages.map((page = {}, index) => {
			try {
				const { canvas } = this.canvases[index] || {};

				const canvasJSON = JSON.stringify(canvas);
				// const canvasJSONObject = JSON.stringify(canvas.toObject());
				// const canvasJSONObject2 = JSON.stringify(canvas.toObject(["left", "top", "scaleX", "scaleY", "angle", "width", "height"]));
				const canvasSVG = canvas.toSVG();

				return Object.assign(page, { data: canvasSVG.toString(), raw_data: canvasJSON });
			} catch (error) {
				console.error("error:", error);
				return null;
			}
		});

		if (this.props.onSave) {
			return this.props.onSave(pages, name);
		}

		this.setState({ saving: true });

		return await API.put(`/api/pdf_templates/${this.state.id}.json`, { pages, lastFontFamily: this.state.lastFontFamily })
			.then((result) => {
				this.setState({ saving: false });

				if (result.data.error) {
					this.setState({ saving: false });

					Toast.error(result.data.error);
					return;
				}

				Toast.success(this.props.t("pdf.responses.updated", "Uppdaterade PDF"));
			})
			.catch((error) => {
				this.setState({ saving: false });

				Toast.error(error);
			});
	}

	render() {
		if (this.state.loading) return <Skeleton1Col />;
		return (
			<div className="editor">
				<Toolbar
					locked={this.props.locked}
					history={this.props.history}
					id={this.state.id}
					print={this.props.print}
					setZoom={this.zoom.bind(this)}
					addPage={this.addPage.bind(this)}
					addText={this.addText.bind(this)}
					addLink={this.addLink.bind(this)}
					addTable={this.addTable.bind(this)}
					addImage={this.addImage.bind(this)}
					addLine={this.addLine.bind(this)}
					addRect={this.addRect.bind(this)}
					addCircle={this.addCircle.bind(this)}
					zoomIn={this.zoom.bind(this, "in")}
					zoomOut={this.zoom.bind(this, "out")}
					zoom={this.state.zoom}
					activeObjects={this.state.activeObjects}
					activePageNr={this.state.activePage}
					canvas={this.canvases[this.state.activePage]}
					goToPage={this.goToPage.bind(this)}
					gotoNextPage={this.gotoNextPage.bind(this)}
					gotoPrevPage={this.gotoPrevPage.bind(this)}
					form={this.state.form}
					canvases={this.canvases}
					onSave={this.save.bind(this)}
					requireName={this.props.requireName}
					name={this.props.name}
					saving={this.state.saving || this.props.saving}
					addLastFontSize={(v) => {
						this.setState({ lastFontSize: v });
					}}
					addLastFontFamily={(v) => {
						this.setState({ lastFontFamily: v });
					}}
					setActiveObject={this.setActiveObject.bind(this)}
					duplicateObject={this.duplicateObject.bind(this)}
					fontSize={this.state.lastFontSize}
					uniqueId={this.state.uniqueId}
				/>

				<div
					className="editor-scroll"
					style={this.props.dynamicWidth ? { height: this.state.originalHeight } : {}}
					onClick={this.onClickoutside.bind(this)}
				>
					<div className="editor-scroll__inner">
						{this.state.form.pages &&
							this.state.form.pages.map((page, index) => (
								<Canvas
									locked={this.props.locked}
									activeObjects={this.state.activeObjects}
									setActiveObject={this.setActiveObject.bind(this)}
									height={this.state.height}
									width={this.state.width}
									ref={(ref) => {
										this.canvases[index] = ref;
									}}
									key={page.id || page.tempId}
									index={index}
									page={page}
									setActivePage={this.setActivePage.bind(this, index)}
									removePage={this.removePage.bind(this, index)}
									clearCanvas={this.clearCanvas.bind(this)}
									duplicatePage={this.duplicatePage.bind(this, index)}
									movePageUp={this.movePageUp.bind(this, index)}
									movePageDown={this.movePageDown.bind(this, index)}
									setZoom={this.zoom.bind(this)}
								/>
							))}
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation(["pdf", "common"], { withRef: true })(EditorView);
