import React from "react";
import { Link } from "react-router-dom";

type LinkComponentProps = {
	children?: React.ReactNode;
	url: string;
	className?: string;
};

const LinkComponent = ({ children, url, className }: LinkComponentProps): JSX.Element => {
	if (url.substring(0, 4) == "http") {
		if (url.indexOf("gotoadmin.php") >= 0 || url.indexOf("/admin") >= 0) {
			return (
				<a href={url} className={className}>
					{children}
				</a>
			);
		}
		return (
			<a href={url} className={className} target="_blank" rel="noreferrer">
				{children}
			</a>
		);
	}
	return (
		<Link to={url} className={className}>
			{children}
		</Link>
	);
};

export default LinkComponent;
