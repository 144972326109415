import React, { Component } from "react";
import { Avatar, Button, Popover, Tooltip } from "@shopify/polaris";
import { CircleCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { RemoveButton } from "../../../components/Attachment";

class MemberAvatar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	removeMention() {
		this.setState({ removeModalOpen: false });
		if (this.props.removeMention) this.props.removeMention();
	}

	render() {
		const user = this.props.user || (this.props.member && this.props.member.user) || this.props.member || {};
		const initialsArray = (() => {
			if (!user) return ["-", "-"];
			try {
				return String(user.name || user.title || "")
					.replaceAll(/[!"#€%&/(),[\];.:\-_'*¨^§°´`+©@£$∞§|≈±=/<>]/g, "")
					.split(" ");
			} catch (e) {
				console.error("error", e);
				try {
					return String(user.name || user.title || "")
						.match(/[a-zA-Z"  *"0-9]/g)
						.join("")
						.split(" ");
				} catch (error) {
					console.error("error2:", error);
					return ["-", "-"];
				}
			}
		})() || ["-", "-"];
		const initials = (((initialsArray?.shift() || "").charAt(0) || "") + ((initialsArray?.pop() || "").charAt(0) || "")).toUpperCase();

		const avatar = (
			<span className={this.props.active ? "activeAvatar" : ""} style={Object.assign({ position: "relative" }, this.props.style || {})}>
				<Avatar initials={initials} name={user.name} size="small" source={user.avatar} />
				{this.props.showName && `  ${user.title || user.name}`}
				{this.props.removeMention && (
					<Popover
						active={this.state.removeModalOpen}
						activator={
							<RemoveButton>
								<Button plain destructive icon={CircleCancelMajor} onClick={() => this.setState({ removeModalOpen: true })} />
							</RemoveButton>
						}
						onClose={(e) => this.setState({ removeModalOpen: false })}
					>
						<div
							className="popup-confirm"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<h1>
								{this.props.t("board.members.confirm_remove", "Ta bort mention")} {user.title || user.name}?
							</h1>
							<div className="popup-confirm_buttons">
								<Button destructive onClick={this.removeMention.bind(this)} size="slim">
									{this.props.t("common.actions.remove", "Ta bort")}
								</Button>
								<Button
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										this.setState({ removeModalOpen: false });
									}}
									size="slim"
								>
									{this.props.t("common.actions.cancel", "Avbryt")}
								</Button>
							</div>
						</div>
					</Popover>
				)}
			</span>
		);

		if (this.props.tooltip) {
			return (
				<Tooltip
					content={
						<div style={{ display: "flex", gap: "0.6250rem", alignItems: "center" }}>
							<Avatar initials={initials} name={user.name} size="large" source={user.avatar} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								{user.name}
								<span style={{ opacity: 0.75, fontSize: "0.9em" }}>{user.email}</span>
							</div>
						</div>
					}
					dismissOnMouseOut
				>
					{avatar}
				</Tooltip>
			);
		} else {
			return avatar;
		}
	}
}
export default withTranslation(["board", "common"], { withRef: true })(MemberAvatar);
