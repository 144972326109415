import React, { useEffect, useState } from "react";
import { FormLayout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Modal from "src/js/components/modal";
import useWorkspaces from "src/js/hooks/useWorkspaces";
import TextField from "src/js/components/TextField/TextField";
import Toast from "src/js/components/Toast";

const RenameWorkspaceModal = ({ ...props }) => {
	const [title, setTitle] = useState(props.workspace.title);
	const { updateWorkspace } = useWorkspaces();

	useEffect(() => {
		if (props.open) {
			setTitle(props.workspace.title);
		}
	}, [props.open, props.workspace.title]);

	const saveForm = async () => {
		await updateWorkspace({ id: props.workspace.id, title });
		Toast.success(props.t("workspace.folders.responses.renamed", "Döpte om mapp"));
		props.onComplete?.();
	};

	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			title={props.t("workspace.folders.action.rename", "Döp om mapp")}
			primaryAction={{ content: props.t("common.actions.save", "Spara"), onAction: saveForm }}
			secondaryActions={[
				{
					content: props.t("common.actions.close", "Stäng"),
					onAction: props.onClose,
				},
			]}
		>
			<Modal.Section>
				<FormLayout>
					<TextField label={props.t("workspace.folders.fields.new_name.label", "Nytt namn")} value={title} onChange={(v) => setTitle(v)} />
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
};
export default withTranslation(["workspace", "common"], { withRef: true })(RenameWorkspaceModal);
