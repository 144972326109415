import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import $ from "jquery";
import BoardRow from "./BoardRow.js";
import ColumnHeaderRow from "./ColumnHeaderRow.js";

class BoardListRow extends Component {
	constructor(props) {
		super(props);
		this.toggleSelection = this.updateSelection.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const item = nextProps.item;
		const oldItem = this.props.item;

		const itemKey = nextProps.id;

		const oldItemKey = this.props.id;

		if (item.color && nextProps.sorting != this.props.sorting) {
			return true;
		}
		if (nextProps.isDragging != this.props.isDragging) {
			return true;
		}

		if (nextProps.selected != this.props.selected) {
			return true;
		}

		if (String(nextProps.boardString) != String(this.props.boardString)) {
			return true;
		}

		if (nextProps.mode != this.props.mode) {
			if (item.type != "blank" && item.type != "quickadd" && !item.color) {
				if (itemKey != nextProps.id || this.props.mode == "placeholder") {
					return true;
				}
			}
		}

		if (item !== oldItem) {
			return true;
		}

		if (oldItemKey == itemKey) {
			// eslint-disable-next-line no-restricted-syntax
			for (const i in nextProps.style) {
				if (nextProps.style[i] != this.props.style[i]) {
					return true;
				}
			}
			return false;
		}
		return true;
	}

	updateSelection() {
		this.props.toggleSelection(this.props.index, !this.props.selected);
	}

	render() {
		const { item, mode, style, index, onDragGroupStart, /* onDragEnd, */ className, dakey, openRow } = this.props;

		const { connectDragSource, isDragging, connectDropTarget } = this.props;

		if (item.type == "summary") {
			return (
				<div className="board-item" style={style} data-key={dakey}>
					<div className="board-item" data-index={index}>
						<BoardRow
							boardClass={this.props.boardClass}
							summary
							mode={mode}
							board_id={item.board_id}
							rows={item.rows}
							openRow={openRow}
							history={this.props.history}
						/>
					</div>
				</div>
			);
		} else if (item.type == "blank" || this.props.blank) {
			return <div data-key={dakey} className="board-item" data-index={index} style={style} />;
		} else if (item.color) {
			const group = (
				<div className={className || "fixed-row"} data-top={style.top} data-position={item.position} style={style} data-key={dakey}>
					<div className="board-item" data-index={dakey || index}>
						<ColumnHeaderRow
							group={item}
							sorting={this.props.sorting}
							onChangeSorting={this.props.onChangeSorting}
							selected={this.props.selected}
							toggleSelection={this.toggleSelection}
							onDragGroupStart={onDragGroupStart}
							count={item.count}
							board_id={item.board_id}
							openCalendarModal={this.props.openCalendarModal}
						/>
					</div>
				</div>
			);

			if (item.collapsed) {
				return connectDropTarget(group);
			}
			return group;
		} else if (item.type == "quickadd") {
			return connectDropTarget(
				<div style={style} data-key={dakey}>
					<div className="board-item" data-index={index}>
						<BoardRow
							openTicketModal={this.props.openTicketModal}
							boardClass={this.props.boardClass}
							quickadd
							row={item}
							board_id={item.board_id}
							openRow={openRow}
							history={this.props.history}
						/>
					</div>
				</div>
			);
		} else {
			// drag(drop(ref));
			return connectDropTarget(
				connectDragSource(
					<div style={style} data-key={dakey}>
						<div className="board-item" data-index={index}>
							{isDragging ? null : (
								<BoardRow
									boardClass={this.props.boardClass}
									mode={mode}
									selected={this.props.selected}
									toggleSelection={this.toggleSelection}
									onClick={() => {}}
									board_id={item.board_id}
									row={item}
									openRow={openRow}
									onUpdateValue={this.props.onUpdateValue}
									history={this.props.history}
								/>
							)}
						</div>
					</div>
				)
			);
		}
	}
}

const dragSpec = {
	canDrag(props) {
		return true;
	},

	isDragging(props, monitor) {
		return monitor.getItem().id === props.id;
	},

	beginDrag(props, monitor, component) {
		// Return the data describing the dragged item
		const item = { id: props.id, index: props.index };
		$(".board-item[data-index]")
			.filter(() => {
				return $(this).attr("data-index") >= props.index;
			})
			.addClass("below-drag");
		return item;
	},

	endDrag(props, monitor, component) {
		if (!monitor.didDrop()) {
			// You can check whether the drop was successful
			// or if the drag ended but nobody handled the drop
			return;
		}

		// // When dropped on a compatible target, do something.
		// // Read the original dragged item from getItem():
		// const item = monitor.getItem();

		// // You may also read the drop result from the drop target
		// // that handled the drop, if it returned an object from
		// // its drop() method.
		// const dropResult = monitor.getDropResult();

		// if ($(".board-item.hover").length > 0) {
		// 	let newIndex = $(".board-item.hover").attr("data-index");
		// }
		$(".board-item").removeClass("below-hover").removeClass("below-drag").removeClass("hover");
	},
};

function dragCollect(connect, monitor) {
	return {
		// Call this function inside render()
		// to let React DnD handle the drag events:
		connectDragSource: connect.dragSource(),
		// You can ask the monitor about the current drag state:
		isDragging: monitor.isDragging(),
	};
}

const dropSpec = {
	canDrop(props, monitor) {
		// You can disallow drop based on props or item
		// const item = monitor.getItem();
		return true;
	},

	hover(props, monitor, component) {
		// This is fired very often and lets you perform side effects
		// in response to the hover. You can't handle enter and leave
		// here—if you need them, put monitor.isOver() into collect() so you
		// can use componentDidUpdate() to handle enter/leave.

		// You will receive hover() even for items for which canDrop() is false
		// const canDrop = monitor.canDrop();

		$(".board-item")
			.removeClass("hover")
			.filter(() => {
				return $(this).attr("data-index") == props.index;
			})
			.addClass("hover");
		if (props.item.color) {
			// Hover group
			$(".board-item").removeClass("below-hover");
		} else {
			$(".board-item")
				.filter(() => {
					return $(this).attr("data-index") < props.index;
				})
				.removeClass("below-hover");

			$(".board-item")
				.filter(() => {
					return $(this).attr("data-index") >= props.index;
				})
				.addClass("below-hover");
		}
	},

	drop(props, monitor, component) {
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}

		// Obtain the dragged item
		const item = monitor.getItem();

		// You can do something with it
		props.onDragEnd(item.index, props.index);
		// You can also do nothing and return a drop result,
		// which will be available as monitor.getDropResult()
		// in the drag source's endDrag() method
		return { moved: true };
	},
};
function dropCollect(connect, monitor) {
	return {
		// Call this function inside render()
		// to let React DnD handle the drag events:
		connectDropTarget: connect.dropTarget(),
		// You can ask the monitor about the current drag state:
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop(),
		itemType: monitor.getItemType(),
	};
}

export default DropTarget("ROW", dropSpec, dropCollect)(DragSource("ROW", dragSpec, dragCollect)(BoardListRow));
