import React from "react";

const ClickIcon = ({ color = "#5AC57D" }) => (
	<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.0494 12.0416L11.8094 10.4216C13.0994 9.42156 13.9294 7.86156 13.9294 6.10156C13.9294 3.07156 11.4594 0.601562 8.42937 0.601562C5.39937 0.601562 2.92938 3.07156 2.92938 6.10156C2.92938 8.23156 4.14937 10.0816 5.92937 10.9916V14.2516C3.84937 13.8116 3.91937 13.8116 3.66937 13.8116C3.13937 13.8116 2.63938 14.0216 2.25938 14.4016L0.859375 15.8216L5.94938 20.9116C6.37938 21.3516 6.97937 21.6016 7.59937 21.6016H13.8994C14.8794 21.6016 15.7094 20.9016 15.8694 19.9316L16.6694 15.2216C16.8894 13.9216 16.2394 12.6416 15.0494 12.0416ZM4.92937 6.10156C4.92937 4.17156 6.49937 2.60156 8.42937 2.60156C10.3594 2.60156 11.9294 4.17156 11.9294 6.10156C11.9294 7.05156 11.5494 7.91156 10.9294 8.54156V6.10156C10.9294 4.72156 9.80937 3.60156 8.42937 3.60156C7.04937 3.60156 5.92937 4.72156 5.92937 6.10156V8.54156C5.30937 7.91156 4.92937 7.05156 4.92937 6.10156ZM14.6994 14.8916L13.8994 19.6016H7.59937C7.50937 19.6016 7.42938 19.5616 7.35938 19.5016L3.67938 15.8216L7.92937 16.7116V6.10156C7.92937 5.82156 8.14937 5.60156 8.42937 5.60156C8.70937 5.60156 8.92937 5.82156 8.92937 6.10156V12.1016H10.6894L14.1494 13.8316C14.5494 14.0316 14.7694 14.4616 14.6994 14.8916Z"
			fill={color}
		/>
	</svg>
);

export default ClickIcon;
