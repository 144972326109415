import React, { Component } from "react";
import { store } from "../store";

export const money = (amt, moneyFormat) => {
	let amount = amt;
	if (Number.isNaN(amount) || !amt) {
		amount = 0;
	}
	let result;
	const pattern = /\{\{\s*(\w+)\s*\}\}/;

	function addSpaces(money) {
		money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
		money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
		money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
		return money;
	}

	switch (moneyFormat.match(pattern)[1]) {
		case "detailed": {
			result = addSpaces(parseInt(amount).toString());
			const dec = parseFloat(amount).toString();
			if (dec.indexOf(".") >= 0) {
				const pcs = dec.split(".", 2);
				result = result + "." + pcs[1];
			}
			break;
		}
		case "amount":
			result = addSpaces(parseFloat(amount).toFixed(2).toString());
			break;
		case "fixed_amount":
		default:
			result = addSpaces(parseFloat(amount).toFixed(0).toString());
			break;
	}
	return moneyFormat.replace(pattern, result);

	// const formated = money_format.replace(pattern, result);
	// if ((!formated && formated != 0) || ["NaN", "undefined", "null"].some((i) => i === formated)) return "...";
	// return formated;
};

class Money extends Component {
	constructor(props) {
		super(props);
		this.state = { amount: props.amount, currency: props.currency || store.getState().account.currency };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState((c) => ({ amount: props.amount, currency: props.currency || c.currency }));
	}

	// myCurrency() {
	// 	return store.getState().account.currency;
	// }

	// myRate() {
	// 	return store.getState().currency_rates[this.state.currency] / store.getState().currency_rates[this.myCurrency()];
	// }

	getMoneyFormatFromCurrency(currency) {
		let moneyFormat = "{{amount}} " + (currency || "");

		if (currency == "SEK") {
			moneyFormat = "{{fixed_amount}}kr";
		} else if (currency == "USD") {
			// eslint-disable-next-line no-template-curly-in-string
			moneyFormat = "${{amount}}";
		} else if (currency == "EUR") {
			moneyFormat = "€{{amount}}";
		} else if (currency == "GBP") {
			moneyFormat = "£{{amount}}";
		} else if (currency == "FIXED") {
			moneyFormat = "{{fixed_amount}}";
		}

		return moneyFormat;
	}

	render() {
		const moneyFormat = this.getMoneyFormatFromCurrency(this.state.currency);

		return <span>{money(this.state.amount, moneyFormat)}</span>;
	}
}
export default Money;
