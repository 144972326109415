import React from "react";

const CircleCancel = ({ color = "#B75757" }) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="6.7901" cy="7.19269" r="6.43719" fill={color} />
		<path d="M8.81311 5.16907L4.76718 9.21633" stroke="white" strokeLinecap="round" />
		<path d="M4.76715 5.16907L8.81308 9.21633" stroke="white" strokeLinecap="round" />
	</svg>
);

export default CircleCancel;
