import React, { Component } from "react";
import { FormLayout, Labelled, ColorPicker, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import RemoveModal from "./remove_modal.js";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

class FieldModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, form: props.field, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: props.field });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/fields/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(this.props.t("sales.responses.field.updated", "Uppdaterade affärsområde"));
					this.setState({ saving: false });
					store.dispatch({ type: "UPDATE_FIELD", field: result.data.field });
					this.props.onCreate(result.data.field);
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		} else {
			API.post("/api/fields.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(this.props.t("sales.responses.field.created", "Skapade affärsområde"));
					this.setState({ saving: false });
					store.dispatch({ type: "ADD_FIELD", field: result.data.field });
					this.props.onCreate(result.data.field);
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		}
	}

	doRemove() {
		this.setState({ removing: true });
		API.delete("/api/fields/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });
				store.dispatch({ type: "REMOVE_FIELD", id: this.state.form.id });
				Toast.success(this.props.t("sales.responses.field.removed", "Skapade borttagen"));
				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove();
				}
			})
			.catch((error) => {
				this.setState({ removing: false });
				Toast.error(error);
			});
	}

	render() {
		const secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (this.state.form.id && store.getState().user.roles.indexOf("ROLE_ADMIN")) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		return (
			<div>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={
						this.state.form.id
							? this.props.t("sales.field.titles.change", "Ändra affärsområde")
							: this.props.t("sales.field.titles.create", "Skapa affärsområde")
					}
					secondaryActions={secondaryActions}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						disabled: !this.state.form.title,
						onAction: this.saveForm.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("sales.field.fields.title.label", "Affärsområdets namn")}
								disabled={this.state.form.id}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
								helpText={this.props.t("sales.field.fields.title.helptext", "Namnge ett affärsområde, t.ex. telefoni")}
							/>
							<Labelled label={this.props.t("sales.field.fields.color.label", "Färg")}>
								<ColorPicker
									onChange={(color) => {
										this.state.form.color_hsl = color;
										this.setState({ form: this.state.form });
									}}
									color={this.state.form.color_hsl}
								/>
							</Labelled>
						</FormLayout>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.doRemove.bind(this)}
					numItems={1}
					objectName={this.state.form.title}
					resourceName={{
						singular: this.props.t("sales.field.singular", "affärsområde"),
						plural: this.props.t("sales.field.plural", "affärsområden"),
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["sales", "common"], { withRef: true })(FieldModal);
