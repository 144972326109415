import React from "react";

const DocumentIcon = ({ color = "var(--textColor)" }) => (
	<svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.5977 2.24969H10.5977L8.59766 0.249695H2.59766C1.49766 0.249695 0.607656 1.14969 0.607656 2.24969L0.597656 14.2497C0.597656 15.3497 1.49766 16.2497 2.59766 16.2497H18.5977C19.6977 16.2497 20.5977 15.3497 20.5977 14.2497V4.24969C20.5977 3.14969 19.6977 2.24969 18.5977 2.24969ZM18.5977 14.2497H2.59766V2.24969H7.76766L9.17766 3.65969L9.76766 4.24969H18.5977V14.2497Z"
			fill={color}
		/>
	</svg>
);

export default DocumentIcon;
