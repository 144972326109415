export default (state = [], action) => {
	switch (action.type) {
		case "SET_ACCOUNTS":
			return action.accounts;
		case "ADD_ACCOUNT": {
			const newState = state.slice();
			newState.push(action.account);
			return newState;
		}
		case "UPDATE_ACCOUNT": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.account.id) {
					newState[i] = action.account;
				}
			}
			return newState;
		}
		default:
			return state;
	}
};
