import React from "react";
import { withTranslation } from "react-i18next";

const CellStatusOption = ({ column, status, onClick, t }) => {
	const content = (() => {
		return (
			<div
				key={status.id}
				onClick={onClick}
				className={"status-option" + (column.values_locked ? " disabled" : "")}
				style={{
					backgroundColor: status.color,
					color: status.color === "#ffffff" ? "rgb(15,15,15)" : "#ffffff",

					...(column.values_locked
						? {
								pointerEvents: "none",
								opacity: 0.5,
						  }
						: {}),
				}}
			>
				<span>{status.label}</span>
			</div>
		);
	})();
	return content;
};
export default withTranslation(["board", "common"], { withRef: true })(CellStatusOption);
