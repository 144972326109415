import React from "react";

const ArrowBendUpRight = ({ color = "var(--textColor)" }) => (
	<svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.5126 5.26435C16.8055 4.99587 17.2804 4.99587 17.5733 5.26435L22.0733 9.38935C22.3662 9.65784 22.3662 10.0931 22.0733 10.3616L17.5733 14.4866C17.2804 14.7551 16.8055 14.7551 16.5126 14.4866C16.2197 14.2181 16.2197 13.7828 16.5126 13.5144L20.4823 9.87549L16.5126 6.23662C16.2197 5.96814 16.2197 5.53284 16.5126 5.26435Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.543 10.563C10.3549 10.563 8.25651 11.3597 6.70934 12.778C5.16216 14.1962 4.29297 16.1198 4.29297 18.1255C4.29297 18.5052 3.95718 18.813 3.54297 18.813C3.12876 18.813 2.79297 18.5052 2.79297 18.1255C2.79297 15.7551 3.8202 13.4818 5.64868 11.8057C7.47716 10.1296 9.95711 9.18799 12.543 9.18799H21.543C21.9572 9.18799 22.293 9.49579 22.293 9.87549C22.293 10.2552 21.9572 10.563 21.543 10.563H12.543Z"
			fill={color}
		/>
	</svg>
);

export default ArrowBendUpRight;
