const transformToContact = (data) => {
	const contact: Partial<ContactType> = {};
	contact.is_company = data.is_company;

	contact.name = data.name;
	contact.orgnr = data.org_number;
	contact.zip = data.postal_code;
	contact.city = data.municipality;
	contact.city = data.city;
	contact.note = data.description;
	contact.street = data.address;
	contact.address1 = data.address;
	contact.phone = data.phone_numbers?.[0]?.phone_number || null;

	contact.childs = data.executives.map((executive, index) => {
		return {
			// name: executive.name,
			name: `${executive.first_name || ""} ${executive.last_name || ""}`.trim(),
			first_name: executive.first_name,
			last_name: executive.last_name,
			title: executive.formatted_title,
			id: index,
			selected: false,
			// selected: !["CIVIL_AUDITOR", "AUDITOR"].some((role) => executive.roles?.includes(role)) && executive.roles?.length === 1,
		};
	});

	return contact;
};
export default transformToContact;
