import React, { Component } from "react";

class BringModalToFront extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	componentDidMount() {
		if (this.ref.current) {
			const modal = this.ref.current.closest("[data-portal-id]");
			if (modal) {
				modal.classList.add("modal-top");
				if (modal?.querySelector(".Polaris-Backdrop"))
					modal.querySelector(".Polaris-Backdrop").click = (e) => {
						e.stopPropagation();
						modal.querySelector(".Polaris-Backdrop")?.click();
					};
			}
		}
	}

	render() {
		return <div className={`BringModalToFront ${this.props.className || ""}`} id={this.props.id || ""} ref={this.ref} />;
	}
}
export default BringModalToFront;
