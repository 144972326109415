/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from "react";
import styled from "styled-components";
import SearchField2 from "src/js/components/search_field";

interface SearchFieldProps {
	onBlur?: () => void;
	onChange?: (item: any) => void;
	[key: string]: any;
}

const SearchField = ({ onBlur: onBlurProps, onChange, readOnly, onSelect: propsOnSelect, ...rest }: SearchFieldProps) => {
	const onBlur = useCallback(() => {
		onBlurProps?.();
	}, [onBlurProps]);

	const onSelect = useCallback(
		(item) => {
			onChange?.(item);
			propsOnSelect?.(item);
		},
		[onChange, propsOnSelect]
	);

	return (
		<Wrapper>
			<SearchField2
				className="searchField"
				{...rest}
				required={false} // TEMOPORARY, causes the popup to close otherwise if true
				// autoFocus
				onChange={() => {}}
				onBlur={onBlur}
				onSelect={onSelect}
				prefix={readOnly ? null : undefined}
				readOnly={readOnly}
			/>

			{/* {(this.state.error || this.props.error) && !this.state.showSearch ? <InlineError message={this.props.error} /> : null} */}
		</Wrapper>
	);
};
export default SearchField;

const Wrapper = styled.div``;
