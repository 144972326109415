import i18next from "i18next";

const order = {
	note: i18next.t("orders.fields.note.label", "Notering"),
	createdAt: i18next.t("orders.fields.created_at.label", "Skapad"),
	updatedAt: i18next.t("orders.fields.updated_at.label", "Uppdaterad"),
	orderNumber: i18next.t("orders.fields.order_number.label", "Ordernummer"),
	orderDate: i18next.t("orders.fields.order_date.label", "Orderdatum"),
	ourReference: i18next.t("orders.fields.our_reference.label", "Vår referens"),
	yourReference: i18next.t("orders.fields.your_reference.label", "Er referens"),
	yourOrderNumber: i18next.t("orders.fields.your_order_number.label", "Ert ordernummer"),
	createdByUser: i18next.t("orders.fields.created_by_user.label", "Skapad av"),
	orderRows: i18next.t("orders.fields.order_rows.label", "Order rader"),
	isLocked: i18next.t("orders.fields.is_locked.label", "Är låst"),
	preparedForSigningAt: i18next.t("orders.fields.prepared_for_signing_at.label", "Förberedd för signering"),
	signedAt: i18next.t("orders.fields.signed_at.label", "Signerad"),
	markedAsSignedAt: i18next.t("orders.fields.marked_as_signed_at.label", "Markerad som signerad"),
	cancelledAt: i18next.t("orders.fields.cancelled_at.label", "Makulerad"),
	isSigned: i18next.t("orders.fields.is_signed.label", "Är signerad"),
	markedAsSignedBy: i18next.t("orders.fields.marked_as_signed_by.label", "Markerad som signerad av"),
	cancelledBy: i18next.t("orders.fields.cancelled_by.label", "Makulerad av"),
	preparedForSigningBy: i18next.t("orders.fields.prepared_for_signing_by.label", "Förberedd för signering av"),
};

export default order;
