import { getCookie } from "../Utilities";

const refreshToken = (state = null, action) => {
	switch (action?.type) {
		case "SET_REFRESH_TOKEN": {
			const rememberMeChecked = getCookie("rememberMeChecked");

			if (rememberMeChecked) {
				localStorage.setItem("refresh_token", action.refresh_token);
			} else {
				sessionStorage.setItem("refresh_token", action.refresh_token);
			}

			return action.refresh_token;
		}
		case "REMOVE_REFRESH_TOKEN": {
			localStorage.removeItem("refresh_token");
			sessionStorage.removeItem("refresh_token");
			return null;
		}
		default:
			return state;
	}
};
export default refreshToken;
