import React from "react";
import { GridWrapper, ItemCardWrapper } from "./styles";
import { Thumbnail } from "@shopify/polaris";
import Details from "../Details";

type ItemCardColumn = {
	[key: string]: unknown;
};

type ItemCardProps = {
	onClick?: () => void;
	children?: React.ReactNode;
	title: string | React.ReactNode;
	leftColumn?: ItemCardColumn[];
	rightColumn?: ItemCardColumn[];
	media?: string | React.ReactNode;
};

// ResourseLists warning that childs must be a <ResourceItem />, so maybe refactor this to be a <ResourceItem />?
// Probably so the resourlists checkboxes etc..works
const ItemCard = ({ onClick, children, title, leftColumn, rightColumn, media }: ItemCardProps) => {
	const leftColumnDetails = leftColumn?.filter((i) => !("enabled" in i) || i.enabled);
	const rightColumnDetails = rightColumn?.filter((i) => !("enabled" in i) || i.enabled);
	return (
		<ItemCardWrapper onClick={onClick}>
			<GridWrapper media={media !== undefined}>
				{typeof media === "string" ? <Thumbnail source={media} alt="" size="large" /> : media}
				<div>
					<h3 style={{ marginBottom: 3 }}>{title}</h3>
					{leftColumnDetails?.length ? <Details gap={{ horizontal: 10 }} items={leftColumnDetails} /> : <div />}
				</div>
				{rightColumnDetails?.length ? <Details gap={{ horizontal: 10 }} items={rightColumnDetails} /> : <div />}
			</GridWrapper>

			{children}
		</ItemCardWrapper>
	);
};
export default ItemCard;
