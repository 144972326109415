import i18next from "i18next";
import { store } from "src/js/store";

const getOptions = () => ({
	label: i18next.t("contact.label", "Kontakt"),
	value: "contact",
	options: [
		{ label: i18next.t("contact.fields.id.label", "Id"), value: "contact.id" },
		{ label: i18next.t("contact.fields.name.label", "Namn"), value: "contact.name" },
		{ label: i18next.t("contact.fields.firstName.label", "Förnamn"), value: "contact.first_name" },
		{ label: i18next.t("contact.fields.lastName.label", "Efternamn"), value: "contact.last_name" },
		{ label: i18next.t("contact.fields.fullName.label", "Namn med företag"), value: "contact.fullname" },
		{ label: i18next.t("contact.fields.email.label", "E-post"), value: "contact.email" },
		{ label: i18next.t("contact.fields.orgNumber.label", "Organisationsnummer"), value: "contact.orgnr" },
		{ label: i18next.t("contact.fields.phoneNumber.label", "Telefonnummer"), value: "contact.phone" },
		{ label: i18next.t("contact.fields.country.label", "Land"), value: "contact.country" },
		{ label: i18next.t("contact.fields.city.label", "Stad"), value: "contact.city" },
		{ label: i18next.t("contact.fields.zip.label", "Postnummer"), value: "contact.zip" },
		{ label: i18next.t("contact.fields.streetAddress.label", "Adress"), value: "contact.street" },

		{
			label: i18next.t("contact.fields.parent.label", "Företag"),
			value: "contact.parent",
			options: [
				{ label: i18next.t("contact.fields.parent.fields.id.label", "Kontaktens företags id"), value: "contact.parent.id" },
				{ label: i18next.t("contact.fields.parent.fields.name.label", "Kontaktens företags Namn"), value: "contact.parent.name" },
				{ label: i18next.t("contact.fields.parent.fields.firstName.label", "Kontaktens företags namn"), value: "contact.parent.first_name" },
				{ label: i18next.t("contact.fields.parent.fields.email.label", "Kontaktens företags e-post"), value: "contact.parent.email" },
				{
					label: i18next.t("contact.fields.parent.fields.orgNumber.label", "Kontaktens företags organisationsnummer"),
					value: "contact.parent.orgnr",
				},
				{
					label: i18next.t("contact.fields.parent.fields.phoneNumber.label", "Kontaktens företags telefonnummer"),
					value: "contact.parent.phone",
				},
				{ label: i18next.t("contact.fields.parent.fields.country.label", "Kontaktens företags land"), value: "contact.parent.country" },
				{ label: i18next.t("contact.fields.parent.fields.city.label", "Kontaktens företags stad"), value: "contact.parent.city" },
				{ label: i18next.t("contact.fields.parent.fields.zip.label", "Kontaktens företags postnummer"), value: "contact.parent.zip" },
				{ label: i18next.t("contact.fields.parent.fields.streetAddress.label", "Kontaktens företags adress"), value: "contact.parent.street" },
			],
		},

		{
			label: i18next.t("contact.fields.metaFields.label", "Metafält"),
			value: "contact.metafields",
			options: store.getState().contact_metafields.map((metafield) => {
				return {
					label: metafield.title,
					value: `contact.metafields.${metafield.handle}.value`,
				};
			}),
		},
	],
});

export default getOptions;
