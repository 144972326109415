import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

class CircleDiagramHalf extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { text, percentage, color, /* backgroundColor, */ strokeWidth = 4, /* loading, empty, */ size = 200 } = this.props;

		return (
			<Wrapper fill={color} percentage={Math.min(100, percentage)} size={size} strokeWidth={strokeWidth}>
				<Text>{text}</Text>
			</Wrapper>
		);
	}
}
export default withTranslation(["diagram"], { withRef: true })(CircleDiagramHalf);

const Text = styled.span`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 10px;
`;

const Wrapper = styled.div`
	width: ${({ size }) => size}px;
	height: ${({ size }) => size / 2}px;
	position: relative;
	text-align: center;
	color: var(--textColor);
	font-size: 22px;
	font-weight: 600;
	border-radius: ${({ size }) => size / 2}px ${({ size }) => size / 2}px 0 0;
	overflow: hidden;
	/* color: ${({ fill }) => fill}; */
	display: flex;
	align-items: flex-end;
	justify-content: center;
	box-sizing: border-box;
	&:before,
	&:after {
		content: "";
		width: ${({ size }) => size}px;
		height: ${({ size }) => size / 2}px;
		border: ${({ strokeWidth }) => strokeWidth}px solid ${({ fill }) => fill};
		border-top: none;
		position: absolute;
		transform-origin: 50% 0% 0;
		border-radius: 0 0 ${({ size }) => size}px ${({ size }) => size}px;
		box-sizing: border-box;
		left: 0;
		top: 100%;
	}
	&:before {
		border-color: var(--diagramBackground);
		transform: rotate(180deg);
	}
	&:after {
		z-index: 3;
		animation: 1s fillGraphAnimation ease-in;
		transform: rotate(calc(1deg * (${({ percentage }) => percentage} * 1.8)));
	}
	&:hover {
		&:after {
			/* opacity: 0.8; */
			/* cursor: pointer; */
		}
	}
`;
