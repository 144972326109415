import Colors, { colorToRgba } from "src/js/Colors";
import styled from "styled-components";

export const OuterWrapper = styled.div`
	/* background-color: ${({ contact }: any) => (contact?.is_company ? colorToRgba(Colors.accent, 0.35) : colorToRgba(Colors.purple, 0.35))}; */
	position: relative;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
`;
export const Wrapper = styled.div.attrs<{ contact?: ContactType | null }>({ className: "contact-card" })<{ contact?: ContactType | null }>`
	/* background-color: ${({ contact }: any) => (contact?.is_company ? colorToRgba(Colors.accent, 0.35) : colorToRgba(Colors.purple, 0.35))}; */
	&&& {
		position: relative;
		box-shadow: unset;
		border-radius: 0;
	}
`;

export const ContactTypeIndicator = styled.div<{
	contact?: ContactType | null;
}>`
	background-color: ${({ contact }: any) => (contact?.is_company ? colorToRgba(Colors.accent, 0.35) : colorToRgba(Colors.purple, 0.35))};
	height: 15px;
	width: 100%;
	/* position: absolute; */
	top: 0;
	left: 0;
	right: 0;
`;
