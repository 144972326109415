import React, { useEffect, useMemo, useState } from "react";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import EntityLog from "./EntityLog";
import { uniqBy } from "lodash";
import { Icon } from "@shopify/polaris";
import { Loadmore, LoadmoreInner } from "./styles";
import Spinner from "../Spinner";
import { ChevronDownMinor } from "@shopify/polaris-icons";

const LIMIT_OFFSET = 10;

const EntityLogs = ({ url }) => {
	const [offset, setOffset] = useState(0);
	const [logs, setLogs] = useState<any[]>([]);

	const params = useMemo(() => {
		return {
			offset,
			limit: LIMIT_OFFSET,
		};
	}, [offset]);

	const queryKey = [url, "logs", params];
	const { data, isFetching } = useQuery({
		queryKey,
		queryFn: async () => {
			const result = await API.get(url, { params });
			return result?.data;
		},
		enabled: !!url,
	});

	const count = data?.count;

	useEffect(() => {
		if (!offset) setLogs(data?.logs || []);
		else setLogs((logs) => uniqBy([...(logs || []), ...(data?.logs || [])], "id"));
	}, [data?.logs, offset]);

	return (
		<div>
			{/* {isInitialLoading && <EntityLog data={null} />} */}

			{logs?.map((log, index) => {
				return <EntityLog key={index} data={log} />;
			})}

			<Loadmore
				disabled={isFetching || logs?.length >= count}
				onClick={() => {
					setOffset((offset) => offset + LIMIT_OFFSET);
				}}
			>
				<LoadmoreInner>
					Ladda fler
					{isFetching ? <Spinner size="small" /> : <Icon source={ChevronDownMinor} />}
				</LoadmoreInner>
				{!!count && (
					<LoadmoreInner style={{ opacity: 0.5 }}>
						Visar {logs?.length} av {count}
					</LoadmoreInner>
				)}
			</Loadmore>
		</div>
	);
};
export default EntityLogs;
