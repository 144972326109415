import { LegacyCard, Layout } from "@shopify/polaris";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import FortnoxOfferContext from "./FortnoxOfferContext";
import FortnoxOfferRows from "./FortnoxOfferRows";
import FortnoxOfferInfo from "./FortnoxOfferInfo";
import { InfoWrapper } from "../styles";
import { formatRowNumberToWrite, formatRowToRead } from "../Utilities/formatRows";
import SkeletonPage from "src/js/components/Skeletons/Page";

const FortnoxOfferInfoView = ({ history, t }) => {
	const { form, isFetching, setForm, isSaving } = useContext(FortnoxOfferContext);

	const getChangeHandler = (field: FortnoxOfferTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<FortnoxOfferType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			if (field === "VATIncluded") {
				newForm.OfferRows = newForm.OfferRows?.map((row) => {
					const VAT = row.VAT;
					const price = formatRowNumberToWrite(row.Price);

					if (newForm.VATIncluded) {
						row.Price = price * (1 + VAT / 100);
					} else {
						row.Price = price / (1 + VAT / 100);
					}

					return formatRowToRead(row);
				});
			}
			return newForm;
		});
	};

	return (
		<InfoWrapper>
			<LegacyCard sectioned>
				{!form || isFetching ? (
					<SkeletonPage />
				) : (
					<Layout>
						<Layout.Section>
							<FortnoxOfferInfo isSaving={isSaving} offer={form} onChange={getChangeHandler} />
						</Layout.Section>

						<Layout.Section>
							<div style={{ marginTop: "3.1250rem" }}>
								<FortnoxOfferRows isSaving={isSaving} offer={form} onChange={getChangeHandler} />
							</div>
						</Layout.Section>
					</Layout>
				)}
			</LegacyCard>
		</InfoWrapper>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOfferInfoView);
