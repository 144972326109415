import { BlogMajor } from "@shopify/polaris-icons";
import React, { useContext } from "react";
import { Trans, withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import IconWithBackround from "src/js/components/IconWithBackround";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import TargetDashboardContext from "../Context";
import { PercentageIcon } from "src/js/icons";
import { numberFormatter } from "src/js/Utilities";

const StatsBoxes = () => {
	const { target, targetConnections, t } = useContext(TargetDashboardContext);

	const value = targetConnections.reduce((acc, targetConnection) => acc + targetConnection.value || 0, 0);
	const intendedValue = targetConnections.reduce((acc, targetConnection) => acc + targetConnection.intended_value || 0, 0);
	const intendedValueToDate = targetConnections.reduce((acc, targetConnection) => acc + targetConnection.intended_value_to_date || 0, 0);
	const valueDiff = value - intendedValueToDate || 0;

	const percentageToDateDiff = ((value - intendedValueToDate || 0) / intendedValueToDate) * 100 || 0;

	return (
		<StatsBoxWrapper
			loading={false}
			items={[
				{
					title: t("targets.dashboard.boxes.percentage_to_date", "Diff per dagens datum") as string,
					text: `${percentageToDateDiff >= 0 ? "+" : "-"}${percentageToDateDiff.toFixed(target?.number_of_decimals)} %`,
					icon: (
						<IconWithBackround
							icon={<PercentageIcon color={percentageToDateDiff >= 0 ? Colors.green : Colors.red} />}
							color={percentageToDateDiff >= 0 ? Colors.green : Colors.red}
						/>
					),
					helpText: (
						<>
							<p>
								<Trans
									i18nKey="targets.target_comparison"
									values={{
										intendedValue: numberFormatter({
											value: intendedValueToDate,
											minimumFractionDigits: target?.number_of_decimals,
											maximumFractionDigits: target?.number_of_decimals,
											removeCurrencySymbol: true,
										}),
										suffix: target?.suffix,
									}}
									components={{
										b: <b />,
									}}
									defaults="Enligt målsättning borde detta värde vara <b>{{intendedValue}} {{suffix}}</b> vid denna tidpunkt."
								/>
							</p>

							<p>
								<Trans
									i18nKey="targets.target_comparison2"
									values={{
										value: numberFormatter({
											value,
											minimumFractionDigits: target?.number_of_decimals,
											maximumFractionDigits: target?.number_of_decimals,
											removeCurrencySymbol: true,
										}),
										suffix: target?.suffix,
										valueDiff: valueDiff.toFixed(0),
									}}
									components={{
										b: <b />,
									}}
									defaults="Värdet är <b>{{value}} {{suffix}}</b>, vilket motsvarar en diff på <b>{{valueDiff}} {{suffix}}</b>"
								/>
							</p>
						</>
					),
				},
				{
					title: t("targets.dashboard.boxes.value", "Värde / Mål") as string,
					text: `${numberFormatter({
						value,
						minimumFractionDigits: target?.number_of_decimals,
						maximumFractionDigits: target?.number_of_decimals,
					})} / ${numberFormatter({
						value: intendedValue,
						removeCurrencySymbol: true,
						minimumFractionDigits: target?.number_of_decimals,
						maximumFractionDigits: target?.number_of_decimals,
					})} ${target?.suffix}`,
					icon: <IconWithBackround icon={BlogMajor} color={Colors.darklightblue} />,
					helpText: (
						<>
							<p>
								<Trans
									i18nKey="targets.target_value"
									values={{
										intendedValue: numberFormatter({
											value: intendedValue,
											minimumFractionDigits: target?.number_of_decimals,
											maximumFractionDigits: target?.number_of_decimals,
											removeCurrencySymbol: true,
										}),
										suffix: target?.suffix,
										date: target?.group_end_at,
									}}
									components={{
										b: <b />,
									}}
									defaults="Detta är värdet för hela perioden. Enligt målsättning borde detta värde vara <b>{{intendedValue}} {{suffix}}</b> vid {{date}}."
								/>
							</p>
						</>
					),
				},
			]}
		/>
	);
};
export default withTranslation(["sales"])(StatsBoxes);
