import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import Colors from "../../../Colors";
import IconWithBackround from "../../../components/IconWithBackround";
import { DollarIcon } from "../../../icons";
import { store } from "../../../store";
import useQuery from "src/js/hooks/useQuery";
import { UseQueryResult } from "@tanstack/react-query";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import styled from "styled-components";
// import ContractGraph from "src/js/views/Contacts/dashboards/economy/ContractGraph1";
// import InvoiceGraph from "src/js/views/Contacts/dashboards/economy/InvoiceGraph";
import MyFilters from "src/js/components/MyFilters";
import ContractGraph from "../../Contacts/dashboards/economy/ContractGraph";
import InvoiceGraph from "../../Contacts/dashboards/economy/InvoiceGraph";
import { currencyFormatter } from "src/js/Utilities";
import { BlogMajor, LegalMajor, OrdersMajor, ReceiptMajor, RefreshMajor } from "@shopify/polaris-icons";
import Select from "src/js/components/select/Select";
import Button from "src/js/components/Button";
import Toast from "src/js/components/Toast";

const EconomyDashboard = ({ t, history, params: propsParams = null }) => {
	const [appliedFilters, setAppliedFilters] = useState([]);
	const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

	const handleFiltersChange = (appliedFilters) => {
		setAppliedFilters(appliedFilters);
	};

	const boardId = store.getState().account.sales_board_id;

	const invoiceParams: any = useMemo(() => {
		const p: any = {
			also_archived: 1,
			...appliedFilters.reduce((acc: any, f: any) => {
				if (f.key === "user_id") {
					acc.assigned_user_id = f.value;
				} else {
					acc[f.key] = f.value;
				}
				return acc;
			}, {}),
		};

		return p;
	}, [appliedFilters]);

	const fetchInvoiceData = useCallback(async () => {
		try {
			const res = await API.get(`/api/standard_dashboards/economy/stats.json`, { params: invoiceParams });
			return res.data.stats;
		} catch (error) {
			Toast.error(error);
			return null;
		}
	}, [invoiceParams]);

	const invoiceQueryKey = [`economy_dashboard`, Object.keys(invoiceParams).length && `board_${boardId}_rows_${JSON.stringify(invoiceParams)}`].filter(
		Boolean
	);
	const {
		data: stats = null,
		isFetching: IsLoadingStats,
		refetch,
	}: UseQueryResult<any | null> = useQuery({
		queryKey: invoiceQueryKey,
		queryFn: fetchInvoiceData,
		refetchOnWindowFocus: false,
	});

	const filters = [
		{
			key: "contact",
			label: t("dashboards.filters.contact_id.label", "Kontakt"),
			type: "contact",
			shortcut: true,
		},
		{
			key: "user_id",
			label: t("dashboards.filters.user_id.label", "Användare"),
			type: "user",
			shortcut: true,
		},

		// {
		// 	key: "user_id",
		// 	label: t("dashboards.filters.user_id.label", "Användare"),
		// 	type: "select",
		// 	options: store.getState().users.map((u) => ({ label: u.fullname, value: u.id })),
		// 	shortcut: true,
		// },

		// {
		// 	key: "todate",
		// 	label: t("fortnox.filters.todate.label", "Till datum"),
		// 	type: "date",
		// },
		// {
		// 	key: "fromdate",
		// 	label: t("fortnox.filters.fromdate.label", "Från datum"),
		// 	type: "date",
		// },
	];

	const showDiff = !!stats?.invoices?.[moment(selectedYear).subtract(1, "year").format("YYYY")];
	const invoiceTotalDiff =
		(stats?.invoices?.[selectedYear]?.data?.total || 0) -
		(stats?.invoices?.[moment(selectedYear).subtract(1, "year").format("YYYY")]?.data?.compare_total || 0);

	const yearOptions = (() => {
		const invoiceYears = Object.keys(stats?.invoices || {}).filter((k) => !Number.isNaN(parseInt(k)));

		if (!invoiceYears.includes(moment().format("YYYY"))) invoiceYears.push(moment().format("YYYY"));
		// if (!invoiceYears.includes(moment().subtract(1, "year").format("YYYY"))) invoiceYears.push(moment().subtract(1, "year").format("YYYY"));

		return invoiceYears.sort((a, b) => parseInt(b) - parseInt(a)).map((year) => ({ value: year, label: year }));
	})();

	return (
		<>
			<FilterWrapper>
				<Button loading={IsLoadingStats} icon={RefreshMajor} onClick={refetch} />

				<Select
					value={selectedYear}
					onChange={(e) => {
						setSelectedYear(e);
					}}
					options={yearOptions}
				/>

				<MyFilters filters={filters} appliedFilters={appliedFilters} onFiltersChange={handleFiltersChange} hideQueryField />
			</FilterWrapper>

			<Wrapper>
				<StatsBoxWrapper
					style={{ gridArea: "invoices", gridTemplateColumns: "1fr" }}
					loading={IsLoadingStats}
					items={[
						{
							title: t("contact.dashboards.invoice.total_invoiced", "Totalt fakturerat {{year}}", { year: selectedYear }),
							text: (
								<p>
									{currencyFormatter({ value: stats?.invoices?.[selectedYear]?.data?.total || 0, currency: "SEK", maximumFractionDigits: 0 })}

									{showDiff && (
										<>
											{invoiceTotalDiff > 0 ? (
												<span style={{ color: Colors.green }}>
													{" "}
													+{currencyFormatter({ value: invoiceTotalDiff, currency: "SEK", maximumFractionDigits: 0 })}
												</span>
											) : (
												<span style={{ color: Colors.red }}>
													{" "}
													{currencyFormatter({ value: invoiceTotalDiff, currency: "SEK", maximumFractionDigits: 0 })}
												</span>
											)}
										</>
									)}
								</p>
							),
							icon: <IconWithBackround icon={<DollarIcon color={Colors.green} />} color={Colors.green} />,
						},
						{
							title: t("contact.dashboards.invoice.latest_invoice", "Senaste faktura"),
							text: stats?.invoices?.data?.latest_invoice ? moment(stats?.invoices?.data?.latest_invoice.date).format("YYYY-MM-DD") : "-",
							icon: <IconWithBackround icon={ReceiptMajor} color={Colors.green} />,
						},
					]}
				/>

				<StatsBoxWrapper
					style={{ gridArea: "contracts", gridTemplateColumns: "1fr" }}
					loading={IsLoadingStats}
					items={[
						{
							title: t("contact.dashboards.invoices.invoices_count", "Antal fakturor {{year}}", { year: selectedYear }),
							text: stats?.invoices?.[selectedYear]?.data?.count,
							icon: <IconWithBackround icon={<DollarIcon color={Colors.green} />} color={Colors.green} />,
						},
						{
							title: t("contact.dashboards.contracts.invoices_remaining_value1", "Avtalsfaktureringar kvar  {{year}}", {
								year: moment().format("YYYY"),
							}),
							text: currencyFormatter({
								value: stats?.contracts?.[moment().format("YYYY")]?.data?.invoices_remaining_value || 0,
								currency: "SEK",
								maximumFractionDigits: 0,
							}),
							icon: <IconWithBackround icon={LegalMajor} color={Colors.purple} />,
						},
					]}
				/>
				<StatsBoxWrapper
					style={{ gridArea: "orders", gridTemplateColumns: "1fr" }}
					loading={IsLoadingStats}
					items={[
						{
							title: t("contact.dashboards.order.not_completed1", "Ej fakturerade ordrar"),
							text: (
								<p>
									{stats?.orders?.data?.not_completed_count || 0}st (
									{currencyFormatter({ value: stats?.orders?.data?.not_completed_value || 0, currency: "SEK", maximumFractionDigits: 0 })})
								</p>
							),
							icon: <IconWithBackround icon={OrdersMajor} color={Colors.blue} />,
						},
						{
							title: t("contact.dashboards.order.lastest", "Senaste order"),
							text: stats?.orders?.data?.latest_order && (
								<p>
									{moment(stats?.orders?.data?.latest_order?.date).format("YYYY-MM-DD")} (
									{currencyFormatter({ value: stats?.orders?.data?.latest_order?.total || 0, currency: "SEK", maximumFractionDigits: 0 })})
								</p>
							),
							icon: <IconWithBackround icon={OrdersMajor} color={Colors.blue} />,
						},
					]}
				/>
				<StatsBoxWrapper
					style={{ gridArea: "offers", gridTemplateColumns: "1fr" }}
					loading={IsLoadingStats}
					items={[
						{
							title: t("contact.dashboards.offer.not_completed1", "Öppna offerter"),
							text: (
								<p>
									{stats?.offers?.data?.not_completed_count || 0}st (
									{currencyFormatter({ value: stats?.offers?.data?.not_completed_value || 0, currency: "SEK", maximumFractionDigits: 0 })})
								</p>
							),
							icon: <IconWithBackround icon={BlogMajor} color={Colors.darklightblue} />,
						},
						{
							title: t("contact.dashboards.offer.latest_offer", "Senaste offert"),
							text: stats?.offers?.data?.latest_offer && (
								<p>
									{moment(stats?.offers?.data?.latest_offer?.date).format("YYYY-MM-DD")} (
									{currencyFormatter({ value: stats?.offers?.data?.latest_offer?.total || 0, currency: "SEK", maximumFractionDigits: 0 })})
								</p>
							),
							icon: <IconWithBackround icon={BlogMajor} color={Colors.darklightblue} />,
						},
					]}
				/>

				<GraphWrapper style={{ gridArea: "invoice_graph" }}>
					<InvoiceGraph data={stats?.invoices} loading={IsLoadingStats} selectedYear={selectedYear} />
				</GraphWrapper>

				<GraphWrapper style={{ gridArea: "contracts_graph" }}>
					<ContractGraph data={stats?.contracts} loading={IsLoadingStats} selectedYear={selectedYear} />
				</GraphWrapper>
			</Wrapper>
		</>
	);
};
export default withTranslation(["contact", "common"], { withRef: true })(EconomyDashboard);

const Wrapper = styled.div`
	display: grid;
	gap: 1.875rem;
	grid-template-columns: repeat(4, 1fr);

	grid-template-areas:
		"invoices contracts orders offers"
		"invoice_graph invoice_graph contracts_graph contracts_graph";

	.statsBoxWrapper {
		margin: 0;

		.statsBox {
			min-height: 100px;
		}
	}
`;

const GraphWrapper = styled.div`
	flex: 1;
	position: relative;
	height: 100%;
`;

const FilterWrapper = styled.div`
	width: max-content;
	margin-bottom: 1.25rem;
	display: flex;
	gap: 10px;

	button:first-of-type {
		height: max-content;
	}

	.Polaris-Tag {
		background: var(--main2);
	}

	.Polaris-Filters-ConnectedFilterControl__Wrapper {
		width: max-content;
	}

	/* position: absolute;
	top: 0;
	right: 1.5625rem;
	z-index: 1; */
`;
