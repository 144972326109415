import React, { Component } from "react";
import SyncCalendarModal from "../Workspaces/components/SyncCalendarModal";
import { store } from "../../store/index";
import ChooseImportChooce from "./ChooseImportChooce";

class Modals extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClose() {
		this.setState({ open: false });
		if (this.props.onClose) this.props.onClose();
	}

	render() {
		const sharedProps = {
			open: this.props.open || this.state.open,
			onClose: this.onClose.bind(this),
			key: this.props.modal,
		};

		if (this.props.modal === "calendar" && this.props.open) {
			const calendarBoard = store.getState().user.calendar_board || {};
			const columns = calendarBoard.columns || [];
			const column = columns.find((col) => col.type === "timeline");
			return (
				<SyncCalendarModal
					onOpen={() => this.setState({ open: true })}
					column={column}
					board={store.getState().user.calendar_board}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...sharedProps}
					noRedirect
				/>
			);
		}

		if (this.props.modal === "import_company_register" && this.props.open) {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <ChooseImportChooce {...sharedProps} />;
		}

		return null;
	}
}
export default Modals;
