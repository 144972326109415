import React, { Component } from "react";
import { Icon } from "@shopify/polaris";

import { MobileAcceptMajor } from "@shopify/polaris-icons";

class CellCheckbox extends Component {
	constructor(props) {
		super(props);
		this.state = { checked: 0, total_rows: 0, value: props.value };
		if (props.summary) {
			let checked = 0;
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value == 1 || props.values[i].value == "1") {
					checked++;
				}
			}
			this.state = { checked, total_rows: props.values.length };
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.summary) {
			let checked = 0;
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value == 1 || props.values[i].value == "1") {
					checked++;
				}
			}
			this.setState({ checked, total_rows: props.values.length });
		} else {
			this.setState({ value: props.value });
		}
	}

	toggle() {
		if (this.props.readonly || this.props.disabled) return;

		this.state.value = this.state.value == 1 || this.state.value == "1" ? 0 : 1;
		this.props.onChange({ value: this.state.value });
		this.setState({ value: this.state.value });
	}

	render() {
		if (this.props.summary) {
			const str = this.state.checked + "/" + this.state.total_rows;
			return <div style={{ height: "100%", width: "100%", fontWeight: 600, cursor: "text", textAlign: "center", lineHeight: "35px" }}>{str}</div>;
		}
		return (
			<div
				className={"cell-clickable" + (this.props.disabled ? " disabled" : "")}
				style={{ height: "100%", width: "100%", textAlign: "center", paddingTop: 7 }}
				onClick={this.toggle.bind(this)}
			>
				{this.state.value == 1 || this.state.value == "1" ? (
					<div>
						<Icon source={MobileAcceptMajor} color="green" />
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}
export default CellCheckbox;
