import React from "react";

const GraphIcon = ({ color = "#ffffff" }) => (
	<svg width="25" height="14" viewBox="0 0 25 14" filltype="pathfill" fill="none">
		<path
			d="M24.0859 2.6048C24.0859 3.78365 23.1213 4.74816 21.9425 4.74816C21.7496 4.74816 21.5674 4.72673 21.3959 4.67315L17.5807 8.47763C17.6343 8.6491 17.6557 8.842 17.6557 9.0349C17.6557 10.2138 16.6912 11.1783 15.5124 11.1783C14.3335 11.1783 13.369 10.2138 13.369 9.0349C13.369 8.842 13.3904 8.6491 13.444 8.47763L10.7112 5.74483C10.5398 5.79842 10.3469 5.81985 10.154 5.81985C9.96105 5.81985 9.76814 5.79842 9.59667 5.74483L4.72051 10.6317C4.77409 10.8032 4.79553 10.9854 4.79553 11.1783C4.79553 12.3571 3.83101 13.3216 2.65216 13.3216C1.47331 13.3216 0.508789 12.3571 0.508789 11.1783C0.508789 9.99942 1.47331 9.0349 2.65216 9.0349C2.84506 9.0349 3.02725 9.05634 3.19872 9.10992L8.0856 4.23376C8.03201 4.06229 8.01058 3.86938 8.01058 3.67648C8.01058 2.49763 8.9751 1.53311 10.154 1.53311C11.3328 1.53311 12.2973 2.49763 12.2973 3.67648C12.2973 3.86938 12.2759 4.06229 12.2223 4.23376L14.9551 6.96655C15.1266 6.91297 15.3195 6.89153 15.5124 6.89153C15.7053 6.89153 15.8982 6.91297 16.0696 6.96655L19.8741 3.15135C19.8205 2.97989 19.7991 2.7977 19.7991 2.6048C19.7991 1.42594 20.7636 0.461426 21.9425 0.461426C23.1213 0.461426 24.0859 1.42594 24.0859 2.6048Z"
			fill={color}
		/>
	</svg>
);
export default GraphIcon;
