import React, { Component } from "react";
import { Icon, Popover, LegacyStack, Labelled, Button, TextField, FormLayout } from "@shopify/polaris";
import moment from "moment";
import { PlayCircleMajor, PauseCircleMajor, CirclePlusMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import MemberAvatar from "./components/MemberAvatar";
import Toast from "src/js/components/Toast";
import { store } from "../../store";
import BoardHelper from "./BoardHelper";
import MyPopover from "../../components/Popover";

class CellTimetrack extends Component {
	constructor(props) {
		super(props);
		this.interval = null;
		this.state = { time_tracking_logs: [], value: props.value || { duration: 0, running: false } };
		this.state.current_duration = this.state.value.duration;

		if (props.summary) {
			let numActive = 0;
			let totalDuration = 0;
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value) {
					if (props.values[i].value.running) {
						numActive++;
						let seconds = 0;
						if (props.values[i].value.runnings) {
							// eslint-disable-next-line no-restricted-syntax
							for (const k in props.values[i].value.runnings) {
								const duration = moment.duration(moment().diff(moment(props.values[i].value.runnings[k].started_at)));
								seconds += duration.asSeconds();
							}
						}

						totalDuration += (props.values[i].value.duration ? parseInt(props.values[i].value.duration) : 0) + parseInt(seconds);
					} else {
						if (props.values[i].value.duration) {
							totalDuration += parseInt(props.values[i].value.duration);
						}
					}
				}
			}

			// eslint-disable-next-line react/no-unused-state
			this.state = { time_tracking_logs: [], num_active: numActive, total_duration: totalDuration };
		}
	}

	componentDidMount() {
		if (this.props.summary) {
			for (let i = 0; i < this.props.values.length; i++) {
				if (this.props.values[i].value) {
					if (this.props.values[i].value.running) {
						// console.debug("start summary timer");
						this.interval = setInterval(this.tick.bind(this), 1000);
						break;
					}
				}
			}
		} else {
			if (this.state.value.running) {
				this.interval = setInterval(this.tick.bind(this), 100);
			}
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			// console.debug("stop summary timer");
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.summary != this.props.summary) {
			if (this.interval) {
				clearInterval(this.interval);
				this.interval = null;
			}
		}
		if (props.summary) {
			let numActive = 0;
			let totalDuration = 0;
			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value) {
					if (props.values[i].value.running) {
						numActive++;
						let seconds = 0;
						if (props.values[i].value.runnings) {
							// eslint-disable-next-line no-restricted-syntax
							for (const k in props.values[i].value.runnings) {
								const duration = moment.duration(moment().diff(moment(props.values[i].value.runnings[k].started_at)));
								seconds += duration.asSeconds();
							}
						}
						totalDuration += (props.values[i].value.duration ? parseInt(props.values[i].value.duration) : 0) + parseInt(seconds);
					} else {
						if (props.values[i].value.duration) {
							totalDuration += parseInt(props.values[i].value.duration);
						}
					}
				}
			}

			// eslint-disable-next-line react/no-unused-state
			this.setState({ num_active: numActive, total_duration: totalDuration });

			for (let i = 0; i < props.values.length; i++) {
				if (props.values[i].value) {
					if (props.values[i].value.running) {
						if (!this.interval) {
							this.interval = setInterval(this.tick.bind(this), 1000);
						}
						return;
					}
				}
			}
			if (this.interval) {
				clearInterval(this.interval);
				this.interval = null;
			}
		} else {
			const st = { value: props.value || { duration: 0, running: false } };
			if (st.value.running) {
				let seconds = 0;
				if (st.value.runnings) {
					// eslint-disable-next-line no-restricted-syntax
					for (const k in st.value.runnings) {
						const duration = moment.duration(moment().diff(moment(st.value.runnings[k].started_at)));
						seconds += duration.asSeconds();
					}
				}
				const currentDuration = (this.state.value && parseInt(this.state.value.duration)) + parseInt(seconds);
				this.setState({ current_duration: currentDuration });
				this.setState(st);
				if (!this.interval) {
					this.interval = setInterval(this.tick.bind(this), 100);
				}
			} else {
				if (this.interval) {
					clearInterval(this.interval);
					this.interval = null;
				}
				this.setState({ current_duration: st.value.duration });
				this.setState(st);
			}
		}
	}

	tick() {
		if (this.props.summary) {
			let numActive = 0;
			let totalDuration = 0;
			for (let i = 0; i < this.props.values.length; i++) {
				if (this.props.values[i].value) {
					if (this.props.values[i].value.running) {
						numActive++;
						let seconds = 0;
						if (this.props.values[i].value.runnings) {
							// eslint-disable-next-line no-restricted-syntax
							for (const k in this.props.values[i].value.runnings) {
								const duration = moment.duration(moment().diff(moment(this.props.values[i].value.runnings[k].started_at)));
								seconds += duration.asSeconds();
							}
						}
						totalDuration += (this.props.values[i].value.duration ? parseInt(this.props.values[i].value.duration) : 0) + parseInt(seconds);
					} else {
						if (this.props.values[i].value.duration) {
							totalDuration += parseInt(this.props.values[i].value.duration);
						}
					}
				}
			}
			// eslint-disable-next-line react/no-unused-state
			this.setState({ num_active: numActive, total_duration: totalDuration });
		} else {
			if (this.state.value.running) {
				let seconds = 0;
				if (this.state.value.runnings) {
					// eslint-disable-next-line no-restricted-syntax
					for (const k in this.state.value.runnings) {
						const duration = moment.duration(moment().diff(moment(this.state.value.runnings[k].started_at)));
						seconds += duration.asSeconds();
					}
				}
				const currentDuration = parseInt(this.state.value.duration) + parseInt(seconds);
				this.setState({ current_duration: currentDuration });
			}
		}
	}

	stopTimer(id) {
		API.post(
			"/" +
				"api" +
				"/boards/" +
				this.props.column.board_id +
				"/rows/" +
				this.props.row.id +
				"/columns/" +
				this.props.column.id +
				"/time_tracking_logs/" +
				id +
				"/stop.json",
			{},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false, active: false });
				if (result.data.error) {
					Toast.error(result.data.error);
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	startTimer() {
		API.post(
			"/api/boards/" + this.props.column.board_id + "/rows/" + this.props.row.id + "/columns/" + this.props.column.id + "/start.json",
			{},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false, active: false });
				if (result.data.error) {
					Toast.error(result.data.error);
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	toggle() {
		if (this.state.value.runnings && store.getState().user.id in this.state.value.runnings) {
			this.stopTimer(this.state.value.runnings[store.getState().user.id].id);
		} else {
			this.startTimer();
		}

		if (this.state.active) {
			this.setState({ active: false }, this.props.onBlur);
		}
	}

	getDuration(seconds, skipseconds) {
		const hours = Math.floor(seconds / 3600);
		seconds -= hours * 3600;
		const minutes = Math.floor(seconds / 60);
		seconds -= minutes * 60;

		if (!hours && !minutes && !seconds) return "";
		if (hours > 0) {
			return hours + "h " + minutes + "m" + (!skipseconds ? " " + seconds + "s" : "");
		} else if (minutes > 0) {
			return minutes + "m" + (!skipseconds ? " " + seconds + "s" : "");
		}
		return seconds + "s";
	}

	fetch() {
		this.setState({ time_tracking_logs: [] });
		API.get(
			"/" +
				"api" +
				"/boards/" +
				this.props.column.board_id +
				"/rows/" +
				this.props.row.id +
				"/columns/" +
				this.props.column.id +
				"/time_tracking_logs.json",
			{ params: {} }
		)
			.then((result) => {
				this.setState({ time_tracking_logs: result.data.time_tracking_logs });
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	editTimelog(log, event) {
		event.stopPropagation();
		const editingLog = Object.assign({}, log);
		if (editingLog.started_at) {
			editingLog.started_at = moment(editingLog.started_at).format("YYYY-MM-DD HH:mm");
		} else {
			editingLog.started_at = moment().add(-1, "hours").format("YYYY-MM-DD HH:mm");
		}
		if (editingLog.stopped_at) {
			editingLog.stopped_at = moment(editingLog.stopped_at).format("YYYY-MM-DD HH:mm");
		} else {
			editingLog.stopped_at = moment().format("YYYY-MM-DD HH:mm");
		}
		this.setState({ editingTimeLog: editingLog });
	}

	removeTimelog() {
		this.setState({ saving: true });
		API.delete(
			"/" +
				"api" +
				"/boards/" +
				this.props.column.board_id +
				"/rows/" +
				this.props.row.id +
				"/columns/" +
				this.props.column.id +
				"/time_tracking_logs/" +
				this.state.editingTimeLog.id +
				".json",
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false, active: false });
				if (result.data.error) {
					Toast.error(result.data.error);
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	saveTimelog() {
		const editingLog = Object.assign({}, this.state.editingTimeLog);
		editingLog.started_at = moment(editingLog.started_at).format();
		editingLog.stopped_at = moment(editingLog.stopped_at).format();

		this.setState({ saving: true });

		if (editingLog.id) {
			API.put(
				"/" +
					"api" +
					"/boards/" +
					this.props.column.board_id +
					"/rows/" +
					this.props.row.id +
					"/columns/" +
					this.props.column.id +
					"/time_tracking_logs/" +
					editingLog.id +
					".json",
				editingLog,
				{ params: {} }
			)
				.then((result) => {
					this.setState({ saving: false, active: false });
					if (result.data.error) {
						Toast.error(result.data.error);
					}
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		} else {
			API.post(
				"/" +
					"api" +
					"/boards/" +
					this.props.column.board_id +
					"/rows/" +
					this.props.row.id +
					"/columns/" +
					this.props.column.id +
					"/time_tracking_logs.json",
				editingLog,
				{ params: {} }
			)
				.then((result) => {
					this.setState({ saving: false, active: false });
					if (result.data.error) {
						Toast.error(result.data.error);
					}
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		}
	}

	getRunnings() {
		const arr = [];
		if (this.state.value.runnings) {
			// eslint-disable-next-line no-restricted-syntax
			for (const i in this.state.value.runnings) {
				arr.push(this.state.value.runnings[i]);
			}
		}
		return arr;
	}

	render() {
		if (this.props.summary) {
			return (
				<div
					style={{
						height: "100%",
						width: "100%",
						fontWeight: 600,
						cursor: "text",
						textAlign: "center",
						lineHeight: "35px",
					}}
				>
					{this.getDuration(this.state.total_duration, true)}
				</div>
			);
		}

		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						style={{
							height: "100%",
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							lineHeight: "35px",
						}}
						onClick={() => {
							if (!this.state.active) {
								this.fetch();
								this.setState({ active: true, editingTimeLog: null }, this.props.onFocus);
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<span
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<a
								onClick={this.toggle.bind(this)}
								style={{
									cursor: "pointer",
									display: "block",
									position: "absolute",
									top: 7,
									left: 10,
								}}
							>
								{!this.state.value.runnings || !(store.getState().user.id in this.state.value.runnings) ? (
									<Icon color="blue" source={PlayCircleMajor} />
								) : (
									<Icon color="red" source={PauseCircleMajor} />
								)}
							</a>
						</span>
						<div>{this.state.current_duration ? this.getDuration(this.state.current_duration, false) : null}</div>
						<div style={{ position: "absolute", right: 5, top: 3 }}>
							<div className="smallerAvatars">
								{this.getRunnings().map((running, index) => {
									if (running.user_id != store.getState().user.id) {
										const user = BoardHelper.getUser(running.user_id);
										if (user) {
											return <MemberAvatar key={user.id || index} user={user} />;
										}
									}
									return null;
								})}
							</div>
						</div>
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				{!this.state.editingTimeLog ? (
					<div>
						{this.state.value.running ? (
							<Popover.Section>
								<div style={{ textAlign: "center" }}>{this.props.t("cell.timetrack.terms.active_members", "Aktiva tidtagare")}</div>
								{this.getRunnings().map((running, index) => {
									const user = BoardHelper.getUser(running.user_id);

									const duration = moment.duration(moment().diff(moment(running.started_at)));
									const seconds = Math.round(duration.asSeconds());

									return (
										<div key={user ? user.id : index} style={{ textAlign: "center", fontSize: 18 }}>
											<LegacyStack distribution="center">
												<MemberAvatar user={user} />
												<div style={{ paddingTop: 6, width: 130, textAlign: "center" }}>{this.getDuration(seconds, false)}</div>
												<a
													className="timelog-row"
													onClick={this.stopTimer.bind(this, running.id)}
													style={{
														display: "block",
														cursor: "pointer",
														padding: 5,
													}}
												>
													<Icon source={PauseCircleMajor} color="red" />
												</a>
											</LegacyStack>
										</div>
									);
								})}
							</Popover.Section>
						) : null}
						<Popover.Section>
							<a
								onClick={this.editTimelog.bind(this, {})}
								className="timelog-row"
								style={{
									display: "block",
									border: "1px dashed #777",
									cursor: "pointer",
									padding: 5,
								}}
							>
								<LegacyStack spacing="extraTight" distribution="center">
									<Icon source={CirclePlusMinor} color="blue" />
									<div>{this.props.t("cell.timetrack.actions.add_manually", "Lägg till manuellt")}</div>
								</LegacyStack>
							</a>
						</Popover.Section>
						<Popover.Pane>
							<div style={{ width: 300, padding: 15 }}>
								<FormLayout>
									{this.state.time_tracking_logs.map((log, index) => (
										<div
											key={log.id || log.started_by_name || index}
											onClick={this.editTimelog.bind(this, log)}
											className="timelog-row"
											style={{ cursor: "pointer" }}
										>
											<LegacyStack spacing="extraTight">
												<MemberAvatar user={{ name: log.started_by_name }} />
												<LegacyStack.Item fill>
													<div>
														<span style={{ fontSize: 12, fontWeight: "bold" }}>{moment(log.started_at).format("YYYY-MM-DD")}</span> -{" "}
														<span style={{ fontSize: 12 }}>
															{moment(log.started_at).format("HH:mm")} -{moment(log.stopped_at).format("HH:mm")}
														</span>
													</div>
												</LegacyStack.Item>
												<div style={{ fontSize: 12 }}>{this.getDuration(log.duration, false)}</div>
											</LegacyStack>
										</div>
									))}
								</FormLayout>
							</div>
						</Popover.Pane>
					</div>
				) : null}
				{this.state.editingTimeLog ? (
					<div>
						<Popover.Section>
							<FormLayout>
								<TextField
									label={this.props.t("cell.timetrack.fields.start_at.label", "Starttid")}
									type="datetime-local"
									value={moment(this.state.editingTimeLog.started_at).format("YYYY-MM-DD HH:mm").replace(" ", "T")}
									onChange={(v) => {
										this.state.editingTimeLog.started_at = v;
										this.setState({ editingTimeLog: this.state.editingTimeLog });
									}}
								/>
								<TextField
									label={this.props.t("cell.timetrack.fields.stopped_at.label", "Sluttid")}
									type="datetime-local"
									value={moment(this.state.editingTimeLog.stopped_at).format("YYYY-MM-DD HH:mm").replace(" ", "T")}
									onChange={(v) => {
										this.state.editingTimeLog.stopped_at = v;
										this.setState({ editingTimeLog: this.state.editingTimeLog });
									}}
								/>
								<Labelled label={this.props.t("cell.timetrack.fields.time.label", "Tid")}>
									<div>
										{!this.state.editingTimeLog.started_at ||
										!this.state.editingTimeLog.stopped_at ||
										moment(this.state.editingTimeLog.stopped_at).unix() < moment(this.state.editingTimeLog.started_at).unix()
											? "–"
											: this.getDuration(moment(this.state.editingTimeLog.stopped_at).unix() - moment(this.state.editingTimeLog.started_at).unix())}
									</div>
								</Labelled>
								<Button
									disabled={
										!this.state.editingTimeLog.started_at ||
										!this.state.editingTimeLog.stopped_at ||
										moment(this.state.editingTimeLog.stopped_at).unix() < moment(this.state.editingTimeLog.started_at).unix()
									}
									fullWidth
									primary
									loading={this.state.saving}
									onClick={this.saveTimelog.bind(this)}
								>
									{this.state.editingTimeLog.id ? this.props.t("common.actions.save", "Spara") : this.props.t("common.actions.create", "Skapa")}
								</Button>
								{this.state.editingTimeLog.id ? (
									<Button plain destructive loading={this.state.saving} onClick={this.removeTimelog.bind(this)}>
										{this.props.t("common.actions.remove", "Ta bort")}
									</Button>
								) : null}
							</FormLayout>
						</Popover.Section>
					</div>
				) : null}
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellTimetrack);
