import React, { useContext } from "react";
import TargetContext from "../Context/TargetContext";
import { Avatar, Checkbox } from "@shopify/polaris";
import ItemCard from "src/js/components/ItemCard";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import IndexTable from "src/js/components/IndexTable";

//!TODO: quick actions to mass edit all/selected users. Should we make this same as orderflow, with bulkactions that do not need to "save"?
const TargeUserPermissions = () => {
	const { id, t, setForm, form, history } = useContext(TargetContext);
	const ref = React.useRef<any>();

	const onChange = (item: any, field: string) => (checked: boolean, id: string) => {
		setForm((prevForm) => {
			const newForm = { ...prevForm } as TargetType;

			//!TEMP
			// if (!newForm.target_permissions) {
			// 	newForm.target_permissions = [];
			// }

			const permissionIndex = newForm.target_permissions.findIndex((p) => p.user?.id === item.id);
			if (permissionIndex < 0) {
				newForm.target_permissions.push({
					user: item,
					[field]: checked,
				});
			} else {
				newForm.target_permissions[permissionIndex][field] = checked;
			}

			newForm[field] = checked;
			return newForm;
		});
	};

	const renderItem = (item: any) => {
		const permission = form?.target_permissions?.find((p) => p.user?.id === item.id);

		return (
			<ItemCard
				media={<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />}
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						{item.name}

						{item.connected && <Badge color={Colors.green}>{t("common.contributes", "bidrar")}</Badge>}
					</div>
				}
				leftColumn={[
					{
						label: t("contacts.field.email", "Mailadress"),
						value: <a href={`mailto:${item.email}`}>{item.email}</a>,
					},
					{
						label: t("contacts.field.phone", "Telefon"),
						value: <a href={`tel:${item.phone}`}>{item.phone}</a>,
					},
				]}
				rightColumn={[
					{
						label: t("targets.permissions.fields.read.label", "Läs"),
						value: <Checkbox labelHidden label="" checked={permission?.read_permission} onChange={onChange(item, "read_permission")} />,
					},
					{
						label: t("targets.permissions.fields.edit.label", "Redigera"),
						value: <Checkbox labelHidden label="" checked={permission?.edit_permission} onChange={onChange(item, "edit_permission")} />,
					},
					{
						label: t("targets.permissions.fields.read_summary.label", "Se allt"),
						value: <Checkbox labelHidden label="" checked={permission?.read_all_permission} onChange={onChange(item, "read_all_permission")} />,
					},
				]}
			/>
		);
	};

	const handleBulkEdit = async (field: TargetPermissionKeysBooleans, value: boolean) => {
		const ids = ref.current?.getSelectedItems();

		const newForm = { ...form } as TargetType;
		//!TEMP
		// if (!newForm.target_permissions) {
		// 	newForm.target_permissions = [];
		// }

		ids.forEach((id) => {
			const permissionIndex = newForm.target_permissions.findIndex((p) => p.user?.id === id);
			if (permissionIndex < 0) {
				newForm.target_permissions.push({
					user: { id } as UserType,
					[field]: value,
				});
			} else {
				newForm.target_permissions[permissionIndex][field] = value;
			}
		});

		setForm(newForm);

		return false;
	};

	const bulkActions = [
		{
			title: t("targets.permissions.bulkActions.add.title", "Lägg till behörigheter") as unknown as string,
			actions: [
				{
					content: t("targets.permissions.bulkActions.read", "läs") as unknown as string,
					onAction: () => handleBulkEdit("read_permission", true),
				},
				{
					content: t("targets.permissions.bulkActions.edit", "redigera") as unknown as string,
					onAction: () => handleBulkEdit("edit_permission", true),
				},
				{
					content: t("targets.permissions.bulkActions.read_all", "se allt") as unknown as string,
					onAction: () => handleBulkEdit("read_all_permission", true),
				},
			],
		},
		{
			title: t("targets.permissions.bulkActions.remove.title", "Ta bort behörigheter") as unknown as string,
			actions: [
				{
					content: t("targets.permissions.bulkActions.read", "läs") as unknown as string,
					onAction: () => handleBulkEdit("read_permission", false),
				},
				{
					content: t("targets.permissions.bulkActions.edit", "redigera") as unknown as string,
					onAction: () => handleBulkEdit("edit_permission", false),
				},
				{
					content: t("targets.permissions.bulkActions.read_all", "se allt") as unknown as string,
					onAction: () => handleBulkEdit("read_all_permission", false),
				},
			],
		},
	];

	const filters = [
		{
			key: "target_permissions",
			label: t("targets.permissions.fields.read.label", "Rättighet"),
			type: "options",
			shortcut: true,
			allowMultiple: true,
			options: [
				{
					label: t("targets.permissions.fields.read.label", "Läs"),
					value: "read_permission",
				},
				{
					label: t("targets.permissions.fields.edit.label", "Redigera"),
					value: "edit_permission",
				},
				{
					label: t("targets.permissions.fields.read_all.label", "Se allt"),
					value: "read_all_permission",
				},
			],
		},
	];

	return (
		<IndexTable
			// useDefaultRenderKey
			history={history}
			filters={filters}
			// renderKey={JSON.stringify(
			// 	form.target_permissions?.map((p) => {
			// 		return {
			// 			...p,
			// 			user: p.user?.id || p.user_group?.id,
			// 		};
			// 	})
			// )}
			defaultFilters={[
				{
					key: "enabled",
					value: "1",
				},
			]}
			ref={ref}
			params={{
				target_id: id,
			}}
			// onClick={(item) => {
			// 	history.push(`/admin/users/${item.id}`, {
			// 		data: item,
			// 	});
			// }}
			// filters={filters}
			resourceUrl={`/api/users.json`}
			resourceName={{ singular: t("common.user", "Användare"), plural: t("common.users", "Användare") }}
			resourceHandle={"users"}
			columns={[
				{
					handle: "",
					label: "",
					render: renderItem,
				},
			]}
			promotedBulkActions={bulkActions}
		/>
	);
};
export default TargeUserPermissions;
