import React, { Component } from "react";
import { LegacyStack, LegacyCard } from "@shopify/polaris";
import API from "../../../API";

import Toast from "src/js/components/Toast";

class RowVisitorForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hits: [],
			session_id: props.session_id,
		};
	}

	componentDidMount() {
		this.fetchSession();
	}

	fetchSession() {
		API.get("/api/visitors/sessions/" + this.state.session_id + "/hits.json", {
			params: {},
		})
			.then((result) => {
				this.setState({
					hits: result.data.hits,
				});
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	render() {
		return (
			<LegacyCard>
				{this.state.hits.map((hit, index) => (
					<LegacyCard.Section key={index} title={hit.title}>
						<LegacyStack>
							<LegacyStack.Item fill>{hit.url}</LegacyStack.Item>
							<div>{hit.created_at}</div>
						</LegacyStack>
					</LegacyCard.Section>
				))}
			</LegacyCard>
		);
	}
}
export default RowVisitorForm;
