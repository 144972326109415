/* eslint-disable react/jsx-props-no-spreading */
import { LegacyCard, Collapsible, Icon, Text } from "@shopify/polaris";
import type { LegacyCardProps } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import React, { useState, useCallback } from "react";
import { generateId } from "src/js/Utilities";
import styled from "styled-components";

type CollapsibleCardProps = {
	open?: boolean;
} & LegacyCardProps;

const CollapsibleCard = ({ children, open: propsOpen = true, actions: propsActions, title, sectioned, ...props }: CollapsibleCardProps) => {
	const [open, setOpen] = useState(propsOpen);
	const id = generateId();

	const handleToggle = useCallback(() => setOpen((c) => !c), []);
	const actions = [
		...(propsActions || []),
		{
			content: (
				<IconWrapper open={open}>
					<Icon source={ChevronDownMinor} />
				</IconWrapper>
			),
			onAction: handleToggle,
		},
	] as LegacyCardProps["actions"];

	const content = sectioned ? (
		<CardSection open={!!open}>
			<Collapsible id={String(id)} open={open}>
				{children}
			</Collapsible>
		</CardSection>
	) : (
		<Collapsible id={String(id)} open={open}>
			{children}
		</Collapsible>
	);

	return (
		<LegacyCard
			title={
				<TitleWrapper onClick={handleToggle}>
					<Text fontWeight="bold" as="h3" variant="headingSm">
						{title}
					</Text>
				</TitleWrapper>
			}
			actions={actions}
			{...props}
		>
			{content}
		</LegacyCard>
	);
};
export default CollapsibleCard;

const IconWrapper = styled.div<{ open: boolean }>`
	transition: transform 0.3s ease;
	transform: rotate(${(p) => (p.open ? "180deg" : "0deg")});
`;
const TitleWrapper = styled.div`
	cursor: pointer;

	padding-bottom: var(--p-space-5);
	.Polaris-Text--root {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
`;

const CardSection = styled.div.attrs({ className: "Polaris-LegacyCard__Section" })<{ open?: boolean }>`
	padding-top: 0;
	padding-bottom: ${(p) => (p.open ? "var(--p-space-5)" : "0")};
`;
