import React, { Component } from "react";

class RecycledList extends Component {
	constructor(props) {
		super(props);

		this.numItems = null;
		this.firstIndex = null;
		this.lastIndex = null;

		this.visibleIds = {};
		this.visibleGroups = [];
		this.overscan = props.overscan;
		this.state = {};
	}

	UNSAFE_componentWillMount() {
		this.updateWindow(0);
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	scrollTo(scrollTop) {
		this.updateWindow(scrollTop);
	}

	updateWindow(scrollTop) {
		const numItems = Math.ceil(this.props.viewportHeight / this.props.itemHeight);
		const firstIndex = Math.floor(scrollTop / this.props.itemHeight);
		const lastIndex = firstIndex + numItems;

		if (this.firstIndex != firstIndex || this.numItems != numItems || this.lastIndex != lastIndex) {
			// Only recaculate if change is big enough
			this.firstIndex = firstIndex;
			this.numItems = numItems;
			this.lastIndex = lastIndex;
			this.renderItems();
		}
	}

	renderItems() {
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: 1 });
	}

	getItems() {
		const items = [];
		const visibleIds = {};

		let fromIndex = this.firstIndex - this.overscan;
		let toIndex = this.lastIndex + this.overscan; // Add exceeding so we use all keys
		if (fromIndex < 0) {
			toIndex += fromIndex * -1;
			fromIndex = 0;
		}
		if (toIndex >= this.props.itemCount) {
			fromIndex -= toIndex - (this.props.itemCount - 1); // Add exceeding so we use all keys
			if (fromIndex < 0) {
				fromIndex = 0;
			}
			toIndex = this.props.itemCount - 1;
		}

		const rowsToRender = 1 + toIndex - fromIndex;

		const usedKeys = [];
		const usedIndexes = [];
		const visibleGroups = [];

		for (let index = fromIndex; index <= toIndex; index++) {
			// const key = index % rowsToRender;
			const id = this.props.keyGetter(index);
			const groupId = this.props.itemGetter(index).group_id;

			if (groupId && visibleGroups.indexOf(groupId) < 0) {
				visibleGroups.push(groupId);
			}
			this.visibleGroups = visibleGroups;
			if (id in this.visibleIds && this.visibleIds[id].index >= fromIndex && this.visibleIds[id].index <= toIndex) {
				if (this.visibleIds[id].key < rowsToRender) {
					usedKeys.push(this.visibleIds[id].key);
					usedIndexes.push(this.visibleIds[id].index);
				}
			}
		}

		const availableIndexes = [];
		for (let index = fromIndex; index <= toIndex; index++) {
			if (usedIndexes.indexOf(index) < 0) {
				availableIndexes.push(index);
			}
		}

		for (let index = fromIndex; index <= toIndex; index++) {
			let className;
			const key = index % rowsToRender;
			let myIndex;
			let id;
			if (usedKeys.indexOf(key) < 0) {
				if (availableIndexes.length) {
					myIndex = availableIndexes.shift();
				} else {
					myIndex = index;
				}
				id = this.props.keyGetter(myIndex);
			} else {
				// eslint-disable-next-line no-restricted-syntax
				for (const x in this.visibleIds) {
					if (this.visibleIds[x].key == key) {
						myIndex = this.visibleIds[x].index;
						id = this.props.keyGetter(myIndex);
						break;
					}
				}
			}

			visibleIds[id] = { key, index: myIndex };

			const style = {
				position: "absolute",
				left: 0,
				height: this.props.itemHeight,
				width: "100%",
				top: myIndex * this.props.itemHeight,
			};
			const item = this.props.itemGetter(myIndex);
			items.push({
				key,
				index: key,
				title: item.title || "",
				created_at: item.created_at || "",
				component: this.props.renderItem(key, style, myIndex, id, className, !!item.color),
			});
		}

		return items.sort((a, b) => a.index - b.index).map((item) => item.component);
	}

	render() {
		let height = this.props.viewportHeight * 1;
		if (this.props.style.height !== null && this.props.style.height != undefined) {
			height = this.props.style.height;
		}
		return (
			<div style={{ position: "relative", height }}>
				{/* <div style={{position:'fixed', top:70, left:0, zIndex:50000}}>first: {this.firstIndex}, last: {this.lastIndex}</div> */}
				{this.getItems()}
				{this.props.groupHeaders
					.filter((g) => this.visibleGroups.includes(g.id))
					.map((g, index) =>
						this.props.renderItem(
							this.props.itemCount + index,
							{
								position: "absolute",
								left: 0,
								height: this.props.itemHeight,
								width: "100%",
								top: g.index * this.props.itemHeight,
							},
							g.index,
							g.id,
							"fixed-row",
							false
						)
					)}
			</div>
		);
	}
}
export default RecycledList;
