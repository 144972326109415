import React from "react";

const CalendarWeekIcon = ({ color = "var(--textColor)" }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.49512 4.63641C3.49512 3.8425 4.13871 3.19891 4.93262 3.19891H19.3076C20.1015 3.19891 20.7451 3.8425 20.7451 4.63641V19.0114C20.7451 19.8053 20.1015 20.4489 19.3076 20.4489H4.93262C4.13871 20.4489 3.49512 19.8053 3.49512 19.0114V4.63641ZM19.3076 4.63641H4.93262V19.0114H19.3076V4.63641Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.4326 1.76141C16.8296 1.76141 17.1514 2.08321 17.1514 2.48016V5.35516C17.1514 5.75212 16.8296 6.07391 16.4326 6.07391C16.0357 6.07391 15.7139 5.75212 15.7139 5.35516V2.48016C15.7139 2.08321 16.0357 1.76141 16.4326 1.76141Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.80762 1.76141C8.20457 1.76141 8.52637 2.08321 8.52637 2.48016V5.35516C8.52637 5.75212 8.20457 6.07391 7.80762 6.07391C7.41066 6.07391 7.08887 5.75212 7.08887 5.35516V2.48016C7.08887 2.08321 7.41066 1.76141 7.80762 1.76141Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.49512 8.23016C3.49512 7.83321 3.81691 7.51141 4.21387 7.51141H20.0264C20.4233 7.51141 20.7451 7.83321 20.7451 8.23016C20.7451 8.62712 20.4233 8.94891 20.0264 8.94891H4.21387C3.81691 8.94891 3.49512 8.62712 3.49512 8.23016Z"
			fill={color}
		/>
		<path d="M5.6377 9.97314H7.78833V12.1238H5.6377V9.97314Z" fill={color} />
		<path d="M8.34082 9.97314H10.4915V12.1238H8.34082V9.97314Z" fill={color} />
		<path d="M11.0439 9.97314H13.1946V12.1238H11.0439V9.97314Z" fill={color} />
		<path d="M13.7471 9.97314H15.8977V12.1238H13.7471V9.97314Z" fill={color} />
		<path d="M16.4502 9.97314H18.6008V12.1238H16.4502V9.97314Z" fill={color} />
	</svg>
);

export default CalendarWeekIcon;
