/* eslint-disable no-continue */
/* eslint-disable no-return-await */
import API from "src/js/API";
import { store } from "src/js/store";
import BoardHelper from "../BoardHelper";

const LIMIT = 1000;

type FetchRowsParams = {
	boardId: string | number;
	params?: any;
	options?: any;
	cancelToken?: any;
	signal?: any;
};

const updateRows = (rows) => {
	const boards = store.getState().boards;

	for (let i = 0; i < rows.length; i++) {
		if (!rows[i] || rows[i].removed) continue;
		const board = boards[rows[i].board_id];

		if (rows[i].values) {
			for (let x = 0; x < rows[i].values.length; x++) {
				rows[i].values[x] = addConnectedEntities(rows[i].values[x]);
			}
		}

		BoardHelper.setRowMetaData(rows[i], board);
	}
};

const addConnectedEntities = (value) => {
	if ("linked_rows" in value) {
		for (let i = 0; i < value.linked_rows.length; i++) {
			store.dispatch({ type: "UPDATE_BOARD_LINKED_ROW", linked_row: value.linked_rows[i] });
		}
		delete value.linked_rows;
	}
	if ("contacts" in value) {
		for (let i = 0; i < value.contacts.length; i++) {
			store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: value.contacts[i] });
		}
		delete value.contacts;
	}
	if ("tickets" in value) {
		for (let i = 0; i < value.tickets.length; i++) {
			store.dispatch({ type: "UPDATE_BOARD_TICKET", ticket: value.tickets[i] });
		}
		delete value.tickets;
	}
	if ("tags" in value) {
		for (let i = 0; i < value.tags.length; i++) {
			store.dispatch({ type: "ADD_BOARD_TAG", tag: value.tags[i] });
		}
		delete value.tags;
	}
	return value;
};

const handleResponse = async ({ response, request, signal }) => {
	const counts = Math.ceil((response?.data?.count || 0) / request.params.limit);

	const promises = Array.from({ length: counts }, (_, index) => {
		const params = {
			...response.config.params,
			limit: request.params.limit,
			offset: index * request.params.limit,
		};

		return API.get(response.config.url, { params, signal }).then((response) => {
			return response.data[request.type];
		});
	});

	try {
		const results = await Promise.all(promises);
		return results.flat().filter(Boolean);
	} catch (error) {
		console.error(`Error handling response for ${response.config.url}:`, error);
		return [];
	}
};

const fetchRows = async ({ boardId, params: propsParams = {}, options = {}, cancelToken, signal }: FetchRowsParams) => {
	try {
		const params = {
			limit: LIMIT,
			offset: 0,
			...propsParams,
		};

		const requests = [
			{
				url: `/api/boards/${boardId}/rows.json`,
				params,
				type: "rows",
				options,
			},
		];

		if (!options?.light) {
			requests.push({
				url: `/api/boards/${boardId}/linked_rows.json`,
				params,
				type: "linked_rows",
				options,
			});
			requests.push({
				url: `/api/boards/${boardId}/linked_from_rows.json`,
				params,
				type: "linked_from_rows",
				options,
			});
			requests.push({
				url: `/api/boards/${boardId}/row_contacts.json`,
				params,
				type: "contacts",
				options,
			});
			requests.push({
				url: `/api/boards/${boardId}/tickets.json`,
				params,
				type: "tickets",
				options,
			});
			requests.push({
				url: `/api/boards/${boardId}/visitors.json`,
				params,
				type: "visitors",
				options,
			});
		}

		const resultsArray = await Promise.allSettled(
			requests.map(async (request) => {
				return await API.get(request.url, { params: request.params, cancelToken, signal }).then(async (response) => {
					return {
						[request.type]: await handleResponse({ response, request, signal }),
					};
				});
			})
		);

		const results: any = resultsArray.reduce((acc, result: any) => ({ ...acc, ...(result?.value || {}) }), {});

		const { linked_from_rows = [], linked_rows = [], contacts = [], tickets = [], visitors = [], rows = [] } = results;

		store.dispatch({ type: "SET_BOARD_LINKED_FROM_ROWS", linked_from_rows });
		store.dispatch({ type: "SET_BOARD_LINKED_ROWS", linked_rows });
		store.dispatch({ type: "SET_BOARD_CONTACTS", contacts });
		store.dispatch({ type: "SET_BOARD_TICKETS", tickets });
		store.dispatch({ type: "SET_BOARD_VISITORS", visitors });

		if (!options?.skipRedux && !options?.light) {
			updateRows(rows);
		}

		store.dispatch({ type: "SET_BOARD_ROWS", rows });

		return rows;
	} catch (error) {
		console.error(`Error fetching rows for board ${boardId}:`, error);
		return [];
	}
};

export default fetchRows;
