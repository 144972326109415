type OneOf<T, K extends keyof T> = {
	[P in K]-?: Required<Pick<T, P>> & Partial<Record<Exclude<K, P>, never>>;
}[K];

type FortnoxForm = OneOf<
	{
		invoice: FortnoxInvoiceType | null;
		order: FortnoxOrderType | null;
		offer: FortnoxOfferType | null;
	},
	"invoice" | "order" | "offer"
>;

const getFilledDeliveryFields = (customer: FortnoxCustomerType, form: FortnoxForm | any) => {
	return {
		DeliveryName: customer?.DeliveryName || "",
		DeliveryAddress1: customer?.DeliveryAddress1 || "",
		DeliveryAddress2: customer?.DeliveryAddress2 || "",
		DeliveryZipCode: customer?.DeliveryZipCode || "",
		DeliveryCity: customer?.DeliveryCity || "",
		DeliveryCountry: customer?.DeliveryCountry || "",
		DeliveryPhone1: customer?.DeliveryPhone1 || "",
		DeliveryPhone2: customer?.DeliveryPhone2 || "",
		TermsOfDelivery: customer?.TermsOfDelivery || "",
		WayOfDelivery: customer?.WayOfDelivery || "",

		EmailInformation: {
			...(form?.EmailInformation || {}),
			EmailAddressTo: customer?.Email || "",
		},
	};
};

export default getFilledDeliveryFields;
