import React, { useEffect, useMemo } from "react";
import { Collapsible, Icon, ChoiceList as PropsChoiceList } from "@shopify/polaris";
import { ChevronDownMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import findParentOptions from "src/js/Utilities/findParentOptions";
import flattenOptions from "./flattenOptions";
import findOption from "src/js/Utilities/findOption";

const ChoiceList = ({ title = "", choices, selected, ...props }) => {
	return <PropsChoiceList title={title} choices={choices} selected={selected} {...props} />;
};

type NestedChoiceListProps = {
	onChange: (selected: string[]) => void;
	options: any[];
	selected: string[];
	openNested?: boolean;
	openNestedWhenChecked?: boolean;
	openNestedIfHidden?: boolean;
	uncheckChildren?: boolean;
	showCounts?: boolean;
};

const NestedChoiceList = ({
	onChange,
	options,
	selected,
	openNested = false,
	openNestedWhenChecked = false,
	openNestedIfHidden = true,
	uncheckChildren = false,
	showCounts = true,
}: NestedChoiceListProps) => {
	const [roleCollapseStates, setRoleCollapseStates] = React.useState({});
	const mounted = React.useRef(false); // maybe use this if render problems
	const prevSelected = React.useRef(selected);

	const optionCounts = useMemo(() => {
		if (!showCounts) return {};

		return options?.reduce((acc, option) => {
			const allOptionsIncludingChildren = [option, ...flattenOptions(option?.options || [])];
			const allSelectedOptionsIncludingChildren = allOptionsIncludingChildren.filter((option) => selected.includes(option.value));

			return {
				...acc,
				[option.value]: {
					selected: allSelectedOptionsIncludingChildren.length,
					total: allOptionsIncludingChildren.length,
					label: option.label,
					text: ` (${allSelectedOptionsIncludingChildren.length}/${allOptionsIncludingChildren.length})`,
				},
			};
		}, {});
	}, [options, selected, showCounts]);

	useEffect(() => {
		if (!openNestedIfHidden && !openNested && !openNestedWhenChecked) return;

		const newlyCheckedOptions = selected.filter((role) => !prevSelected.current.includes(role));

		selected.forEach((optionValue) => {
			const parentOptions = findParentOptions(options, optionValue);

			if (
				(parentOptions?.length &&
					((openNestedIfHidden && parentOptions?.some((option) => !selected.includes(option))) ||
						(openNestedWhenChecked && newlyCheckedOptions.includes(optionValue)))) ||
				openNested
			) {
				parentOptions.forEach((parentOptionValue) => {
					setRoleCollapseStates((prev) => ({ ...prev, [parentOptionValue]: true }));
				});
			}
		});

		mounted.current = true;
		prevSelected.current = selected;
	}, [selected, openNested, openNestedIfHidden, options, openNestedWhenChecked]);

	const handleChange = (selected) => {
		if (!uncheckChildren) {
			onChange(selected);
			return;
		}

		const checkedOptionsWithChildrensUncheckedIfParentUnchecked = [...selected].filter((role) => {
			const option = findOption(options, role);
			if (option?.disabled) return true;

			const parentOptions = findParentOptions(options, role) || [];
			return parentOptions.every((parentRole) => selected.includes(parentRole));
		});

		onChange(checkedOptionsWithChildrensUncheckedIfParentUnchecked);
	};

	const renderChildren = (role) => (isSelected) => {
		if (!role.options || role.options.length === 0) return null;
		// if (!isSelected && !roleCollapseStates?.[role.value]) return null; // this might be good for performance, to not render the children if the parent is not selected, BUT this remwoes the collapsable animation

		// Recursively render ChoiceList for nested options
		return (
			<ChoiceList
				onChange={handleChange}
				choices={role.options.map((childRole) => {
					// const allOptionsIncludingChildren = [role, ...flattenOptions(childRole?.options || [])];
					// const allSelectedOptionsIncludingChildren = allOptionsIncludingChildren.filter((option) => selected.includes(option.value));

					return {
						value: childRole.value,
						disabled: childRole.disabled,
						helpText: childRole.helpText,
						label: (
							<div style={{ display: "flex", gap: "2rem", alignContent: "center" }}>
								{childRole.label}

								{!!childRole.options?.length && optionCounts?.[childRole.value]?.text}

								{(selected?.includes(childRole.value) || true) && !!childRole?.options?.length && (
									<PlainButton
										// className="Polaris-Button Polaris-Button--plain Polaris-Button--iconOnly"
										onMouseDown={(e) => {
											// if (e.button !== 0) return;

											e.preventDefault();
											e.stopPropagation();
										}}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setRoleCollapseStates((prev) => ({ ...prev, [childRole.value]: !prev[childRole.value] }));
										}}
										// icon={roleCollapseStates?.[role.value] ? ChevronDownMinor : ChevronRightMinor}
										// plain
									>
										<Icon source={roleCollapseStates?.[childRole.value] ? ChevronDownMinor : ChevronRightMinor} />
									</PlainButton>
								)}
							</div>
						),
						renderChildren: (isSelected) => (
							<Collapsible id={childRole.value} open={roleCollapseStates?.[childRole.value]}>
								{renderChildren(childRole)(isSelected)}
							</Collapsible>
						),
					};
				})}
				selected={selected || []}
				allowMultiple
			/>
		);
	};

	const nestedOptions =
		options?.map((role) => {
			if (!role.options?.length) {
				return {
					value: role.value,
					label: role.label,
					disabled: role.disabled,
					helpText: role.helpText,
				};
			}

			// const allOptionsIncludingChildren = [role, ...flattenOptions(role.options)];
			// const allSelectedOptionsIncludingChildren = allOptionsIncludingChildren.filter((option) => selected.includes(option.value));

			return {
				value: role.value,
				disabled: role.disabled,
				helpText: role.helpText,
				label: (
					<div style={{ display: "flex", gap: "2rem", alignContent: "center" }}>
						{role.label}

						{optionCounts?.[role.value]?.text}

						{(selected?.includes(role.value) || true) && (
							<PlainButton
								// className="Polaris-Button Polaris-Button--plain Polaris-Button--iconOnly"
								onMouseDown={(e) => {
									// if (e.button !== 0) return;

									e.preventDefault();
									e.stopPropagation();
								}}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setRoleCollapseStates((prev) => ({ ...prev, [role.value]: !prev[role.value] }));
								}}
								// icon={roleCollapseStates?.[role.value] ? ChevronDownMinor : ChevronRightMinor}
								// plain
							>
								<Icon source={roleCollapseStates?.[role.value] ? ChevronDownMinor : ChevronRightMinor} />
							</PlainButton>
						)}
					</div>
				),
				renderChildren: (isSelected) => (
					<Collapsible id={role.value} open={roleCollapseStates?.[role.value]}>
						{renderChildren(role)(
							true //makes the Collapsible determine if the children should be open, this is so the collapsible animation works on the close action aswell
						)}
					</Collapsible>
				),
			};
		}) || [];

	return <ChoiceList onChange={handleChange} choices={nestedOptions} selected={selected} allowMultiple />;
};
export default NestedChoiceList;

const PlainButton = styled.button`
	&&& {
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;
		margin: 0;
		/* transition: color 0.3s ease; */

		&:hover {
			svg {
				transition: all 0.3s ease;
				color: var(--actionColor);
				fill: var(--actionColor);
			}
		}
	}
`;
