import { Icon } from "@shopify/polaris";
import { ChatMajor } from "@shopify/polaris-icons";
import React from "react";
import styled from "styled-components";

const CommentCountBadge = ({ onClick, count, color = "var(--textColor)" }) => {
	return (
		<Wrapper
			count={count}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick?.();
			}}
		>
			<Icon source={ChatMajor} color="base" />
			{!!count && <Count backgroundColor={color}>{count + ""}</Count>}
		</Wrapper>
	);
};
export default CommentCountBadge;

const Wrapper = styled.div<{ count?: number }>`
	cursor: pointer;
	padding: 5px;
	display: block;
	position: relative;
	${({ count }) => !count && "opacity: 0.5;"}
`;
const Count = styled.div<{ backgroundColor?: string }>`
	position: absolute;
	top: 7px;
	left: 7px;
	width: 16px;
	height: 16px;
	border-radius: 8px;
	line-height: 16px;
	color: #ffffff;
	font-size: 9px;
	text-align: center;
	font-weight: 600;
	background-color: ${({ backgroundColor }) => backgroundColor || "var(--textColor)"};
`;
