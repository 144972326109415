import React from "react";
import { withTranslation } from "react-i18next";
import Modal from "src/js/components/modal";
import TimeLineRow from "src/js/components/TimeLineRow";

const RowCommentsModal = ({ open, onClose, t, comments }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			title="Kommentarer"
			sectioned
			large
			secondaryActions={[
				{
					content: t("common.actions.close", "Stäng"),
					onAction: onClose,
					accessibilityLabel: t("common.actions.close", "Stäng"),
				},
			]}
		>
			{!comments?.length && <div style={{ textAlign: "center" }}>Inga kommentarer</div>}
			{comments?.map((comment, index) => {
				// return <RowComment index={index} key={comment.id + "" + index} comment={comment} />;
				return (
					<TimeLineRow
						key={comment.id + "" + index}
						event={{
							title: comment.user.name,
							date: comment.created_at,
							content: comment.content,
							user: comment.user,
						}}
					/>
				);
			})}
		</Modal>
	);
};
export default withTranslation(["common"])(RowCommentsModal);

// <RowComment onUpdate={this.props.onUpdate} index={index} key={comment.id + "" + index} comment={comment} onRemove={this.props.onRemove} />;
