/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { Spinner } from "@shopify/polaris";
import CategoryContext, { CategoryContextProvider } from "./context";
import Header from "./header";
import Info from "./Info";
import CategoriesArticles from "./Articles";
import CategoriesCustomFields from "./CustomFields";

const Category = ({ id: propsId, match, history, t }) => {
	const { form, handleSave, isFetching, isSaving } = useContext(CategoryContext);
	const id = propsId || match.params.id;

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "info",
					content: t("categories.tabs.info", "Info"),
					render: <Info history={history} />,
				},
				{
					id: "articles",
					content: t("categories.tabs.articles", "Artiklar"),
					render: <CategoriesArticles history={history} />,
					enabled: !!id,
				},
				{
					id: "customfields",
					content: t("categories.tabs.customfields", "Egna fält"),
					render: <CategoriesCustomFields history={history} />,
					enabled: !!id,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, t, id]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as CategoryType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);

	return (
		<Page
			history={history}
			title={
				isFetching
					? ((<Spinner size="small" />) as any)
					: id
					? t("categories.title1", "Redigera kategori: {{title}}", { title: form?.title || "-" })
					: t("categories.new_title", "Ny Kategori")
			}
			primaryAction={{
				content: !propsId ? t("categories.actions.save", "Spara") : t("categories.actions.create", "Skapa"),
				onAction: handleSaveForm,
				disabled: !form,
				loading: isSaving,
			}}
		>
			<Header category={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["fortnox", "common"])((props) => {
	return (
		<CategoryContextProvider {...props}>
			<Category {...props} />
		</CategoryContextProvider>
	);
});
