import React, { Component } from "react";
import { Button } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import Toast from "src/js/components/Toast";

class ExportToExcelButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	exportExcel() {
		this.setState({ loading: true });
		API({
			url: `/api/spreadsheets/${this.props.spreadsheet.id}/export.xls`,
			method: "POST",
			responseType: "blob",
			data: { positions: this.props.data },
		})
			// .post(`/admin/spreadsheets/${this.state.spreadsheet.id}/export.xls`, { ids })
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					Toast.error(result.data.error);
					return;
				}

				const type = result.headers["content-type"];
				const blob = new Blob([result.data], { type, encoding: "UTF-8" });
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = `export_${this.props.spreadsheet.id}.xlsx`;
				link.click();
				window.URL.revokeObjectURL(blob);
			})
			.catch((error) => {
				console.error("error:", error);
				Toast.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	render() {
		return (
			<span className="excel_export_button">
				<Button loading={this.state.loading} onClick={this.exportExcel.bind(this)}>
					<div className="button_inner">
						<img alt="excel" src="/assets/Excel.png" />
						{this.props.t("board.actions.export", "Exportera")}
					</div>
				</Button>
			</span>
		);
	}
}

export default withTranslation(["board"], { withRef: true })(ExportToExcelButton);
