import { Avatar } from "@shopify/polaris";
import { WithTranslation, withTranslation } from "react-i18next";
import { InfoWrapper } from "../CustomFields/styles";
import ResourceListManager from "../ResourceListManager";
import PricelistContext from "./context";
import { useContext } from "react";
import ItemCard from "src/js/components/ItemCard";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";

type PricelistUsersProps = {
	history: any;
} & WithTranslation;

const PricelistUsers = ({ history, t }: PricelistUsersProps) => {
	const { id } = useContext(PricelistContext);

	const renderItem = (item: any) => {
		return (
			<ItemCard
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />
						{item.name}
						{item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)}
					</div>
				}
				leftColumn={[
					{
						label: t("contacts.field.email", "Mailadress"),
						value: <a href={`mailto:${item.email}`}>{item.email}</a>,
					},
					{
						label: t("contacts.field.phone", "Telefon"),
						value: <a href={`tel:${item.phone}`}>{item.phone}</a>,
					},
				]}
				rightColumn={[]}
			/>
		);
	};
	return (
		<InfoWrapper>
			<ResourceListManager
				appliedFilters={[
					{
						key: "enabled",
						value: "1",
					},
				]}
				onClick={(item) => {
					history.push(`/admin/users/${item.id}`, {
						data: item,
					});
				}}
				params={{ pricelist_id: id }}
				resourceHandle={"users"}
				history={history}
				renderItem={renderItem}
				resourceUrl="/api/users.json"
				resourceName={{ singular: t("users.singular", "Användare"), plural: t("users.plural", "Användare") }}
				addRemoveUrl={`/api/pricelists/${id}/users.json`}
			/>
		</InfoWrapper>
	);
};
export default withTranslation(["pricelist", "common"])(PricelistUsers);
