import contact from "./contacts/contact";
import contactField from "./contacts/contactField";
import customerFields from "./orders/customerFields";
import deliveryFields from "./orders/deliveryFields";
import order from "./orders/order";
import orderNote from "./orders/orderNote";
import orderRow from "./orders/orderRow";

const translations = {
	"App\\OrderFlowBundle\\Entity\\Order": order,
	"App\\OrderFlowBundle\\Entity\\OrderRow": orderRow,
	"App\\OrderFlowBundle\\Entity\\OrderCustomerFields": customerFields,
	"App\\OrderFlowBundle\\Entity\\OrderDeliveryFields": deliveryFields,
	"App\\OrderFlowBundle\\Entity\\OrderNote": orderNote,
	"App\\CrmBundle\\Entity\\Contact": contact,
	"App\\CrmBundle\\Entity\\ContactField": contactField,
};

export default translations;
