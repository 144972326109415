/* eslint-disable max-classes-per-file */
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
	AnalyticsMajor,
	AppsMajor,
	ChecklistAlternateMajor,
	SettingsMajor,
	CustomerPlusMajor,
	EmailNewsletterMajor,
	ProfileMajor,
	CircleChevronRightMinor,
	LogOutMinor,
	ProductsMajor,
	CodeMajor,
	BalanceMajor,
} from "@shopify/polaris-icons";
import { Icon, Navigation } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import WorkspaceNavigation from "./WorkspaceNavigation";
// import WorkspaceNavigation from "./Workspaces/WorkspaceNavigation";
// import { getIconByString } from "../views/Workspaces/components/IconSelector.js";
import { store } from "../store";
import IntersectionObserverElement from "./IntersectionObserverElement.js";
import MainMenuSection from "./MainMenuSection";
import DollarIcon from "../icons/DollarIcon.js";
import CogWheelIcon from "../icons/CogWheelIcon.js";
import AppsIcon from "../icons/AppsIcon.js";
import SupportIcon from "../icons/SupportIcon.js";
import MarketingIcon from "../icons/MarketingIcon.js";
import IntegrationsIcon2 from "../icons/IntegrationsIcon2.js";
import MainMenuSectionSearch from "./MainMenuSectionSearch";
import MainSectionFavorites from "./MainSectionFavorites.js";
import CompanyIcon from "../icons/CompanyIcon.js";
import IntegrationModal from "./IntegrationModal.js";
import WorkspaceModal from "./WorkspaceModal";
import BoardTemplateModal from "./BoardTemplateModal.js";
import "../redirectToMobileStore.js";
import LogoIcon from "../icons/LogoIcon.js";
import CallListIcon from "../icons/CallListIcon.js";
import ContactPeopleIcon from "../icons/ContactPeopleIcon.js";
import BoardMembers from "../views/Workspaces/components/BoardMembers.js";
import People3Icon from "../icons/People3Icon.js";
import TargetArrowIcon3 from "../icons/TargetArrowIcon3.js";
import { routesAsObject } from "../routes.js";
import checkUserRoles from "../Utilities/checkUserRoles";
import useWorkspaces from "../hooks/useWorkspaces";
import { useQueryClient } from "@tanstack/react-query";

const MainMenu = (props) => {
	const { t, location, history } = props;
	const queryClient = useQueryClient();

	const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
	const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
	const [boardModalOpen, setBoardModalOpen] = useState(false);
	const [showMembersModal, setShowMembersModal] = useState(false);
	const [parentWorkspace, setParentWorkspace] = useState(null);
	const [workspaceState, setWorkspaceState] = useState(null);

	const ref = useRef<any>(null);
	const animatingTimer = useRef<any>(null);
	const animationFrame = useRef<any>(null);

	const dispatch = store.dispatch;

	const account = useSelector((state: any) => state.account);
	const user = useSelector((state: any) => state.user);
	const contacts = useSelector((state: any) => state.board_contacts);
	const { workspaces, loading: loadingWorkspaces, fetchWorkspaces } = useWorkspaces();

	const closeDrawerAndDeselct = () => {
		document.querySelector("body")!.classList.remove("nav-active");
		document.querySelector("body")!.classList.remove("nav-selected");
		const elements = Array.from(document.querySelectorAll(".mainmenu-item"));
		elements.forEach((e) => e.classList.remove("active"));
	};

	const addAnimating = (id?: any) => {
		if (id) {
			document.querySelector(`#${id}`)!.classList.add("animating");

			clearTimeout(animatingTimer.current);
			animatingTimer.current = setTimeout(() => {
				document.querySelector(`#${id}`)!.classList.remove("animating");
			}, 250);
		} else {
			const activeEle = document.querySelector(".mainmenu-item.active");
			if (activeEle) activeEle.classList.add("animating");
			clearTimeout(animatingTimer.current);
			animatingTimer.current = setTimeout(() => {
				const animatingEle = document.querySelector(".mainmenu-item.animating");
				if (animatingEle) animatingEle.classList.remove("animating");
			}, 250);
		}
	};

	const onSelect = ({ id, url }: any = {}, workspace?: any) => {
		document.querySelector("#AppFrameNav")!.classList.remove("have_active");
		if (url && url === location.pathname) {
			closeDrawerAndDeselct();
			return null;
		}

		if (url) {
			addAnimating();
			closeDrawerAndDeselct();
			// history.push(url);

			// Open menu even if main button is a link
			if (!workspace || (workspace && workspace.title !== "Servicedesk")) {
				return;
			}
		}

		addAnimating();

		if (document.querySelector(`#${id}`)!.classList.contains("active") && document.querySelector("body")!.classList.contains("nav-active")) {
			cancelAnimationFrame(animationFrame.current);
			animationFrame.current = requestAnimationFrame(() => {
				document.querySelector("body")!.classList.remove("nav-active");
				document.querySelector("body")!.classList.remove("nav-selected");
				document.querySelector(`#${id}`)!.classList.remove("active");
			});
			return;
		}

		document.querySelector("body")!.classList.add("nav-active");
		document.querySelector("body")!.classList.add("nav-selected");
		const elements = Array.from(document.querySelectorAll(".mainmenu-item"));
		elements.forEach((e) => e.classList.remove("active"));

		cancelAnimationFrame(animationFrame.current);
		animationFrame.current = requestAnimationFrame(() => {
			document.querySelector(`#${id}`)!.classList.add("active");
			document.querySelector("#AppFrameNav")!.classList.add("have_active");
		});
	};

	const toggleMenu = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		addAnimating();
		document.querySelector("body")!.classList.toggle("nav-active");
	};

	const openModal = (name: string, parentWorkspace?: any) => {
		if (name === "integration") setIntegrationModalOpen(true);
		else if (name === "workspace") setWorkspaceModalOpen(true);
		else if (name === "board") setBoardModalOpen(true);
		setParentWorkspace(parentWorkspace);
	};

	const closeModal = (name) => {
		if (name === "integration") setIntegrationModalOpen(false);
		else if (name === "workspace") setWorkspaceModalOpen(false);
		else if (name === "board") setBoardModalOpen(false);
		setParentWorkspace(null);
	};

	const onUpdateWorkspace = (workspace) => {
		dispatch({ type: "UPDATE_WORKSPACE", workspace });
		fetchWorkspaces();
	};

	const integrationsWithFullscreen: any[] = [];

	if (account.integrations) {
		account.integrations.forEach((integration: any) => {
			if ((integration.fullscreen_url || integration.type !== "web") && user.roles.indexOf("ROLE_INTEGRATION_" + integration.id) >= 0) {
				integrationsWithFullscreen.push(integration);
			}
		});
	}

	const marketingItems: any[] = [];

	if (user.roles.indexOf("ROLE_MARKETING_EMAIL_CAMPAIGNS") >= 0) {
		marketingItems.push({
			label: t("mainmenu.section.marketing.items.email_campaigns", "E-postkampanjer"),
			icon: EmailNewsletterMajor,
			url: "/admin/email_campaigns",
		});
	}

	marketingItems.push({
		label: t("mainmenu.section.marketing.items.audiences", "Målgrupper"),
		icon: CustomerPlusMajor,
		url: "/admin/audiences",
	});

	const integrationsLinks = integrationsWithFullscreen
		?.filter((integration: any) => {
			return integration.integration_id !== "fortnox";
		})
		.map((integration: any) => {
			if (integration.type !== "web") {
				return {
					label: integration.title,
					icon: (() => {
						if (integration.title === "Scrive") return <img src="/assets/scrive.png" alt="scrive" />;
						if (integration.title?.toLowerCase().includes("fortnox")) return <img src="/assets/fortnox-logo.png" alt="Fortnox" />;
						return AppsMajor;
					})(),
					url: `/admin/integrations/${integration.id}/${Math.random()}`,
					activeUrl: `/admin/integrations/${integration.id}`,
					badge: integration.badge_count ? integration.badge_count + "" : null,
				};
			}
			return {
				label: integration.title,
				icon: (() => {
					if (integration.title === "Scrive") return <img src="/assets/scrive.png" alt="scrive" />;
					if (integration.title?.toLowerCase().includes("fortnox")) return <img src="/assets/fortnox-logo.png" alt="Fortnox" />;
					return AppsMajor;
				})(),
				url: `/admin/integrations/${integration.id}/${Math.random()}`,
				activeUrl: `/admin/integrations/${integration.id}`,
				badge: integration.badge_count ? integration.badge_count + "" : null,
			};
		});

	const integrationsItems = [
		{
			icon: <img src="/assets/scrive.png" alt="scrive" />,
			label: "Scrive",
			url: "/admin/scrive/documents",
			closeOnClick: true,
		},
		{
			icon: <img src="/assets/fortnox-logo.png" alt="Fortnox" />,
			label: "Fortnox",
			url: "/admin/fortnox/settings",
			closeOnClick: true,
			enabled: !!account.fortnox_integration,
		},
		...integrationsLinks,
	].filter((i) => !("enabled" in i) || i.enabled);

	// const forsaljning = workspaces && workspaces.find((i) => i.title === "Försäljning");
	// const forsaljningItems =
	// 	forsaljning &&
	// 	forsaljning.boards.map((b) => ({
	// 		label: b.title,
	// 		icon: getIconByString(b.icon),
	// 		url: `/admin/workspaces/${b.workspace_id || forsaljning.id}/boards/${b.id}`,
	// 	}));
	// if (forsaljning && forsaljning.dashboards) {
	// 	forsaljning.dashboards.forEach((d) => {
	// 		forsaljningItems.unshift({
	// 			label: d.title,
	// 			icon: getIconByString(d.icon),
	// 			url: `/admin/dashboard/${d.id}`,
	// 		});
	// 	});
	// }

	const adminItems = [
		{
			id: "users",
			label: t("mainmenu.section.admin.items.users", "Användare", { count: 0 }),
			icon: ProfileMajor,
			url: "/admin/users?enabled=1",
			closeOnClick: true,
		},
		{
			id: "groups",
			label: t("mainmenu.section.admin.items.groups", "Grupper", { count: 0 }),
			icon: People3Icon,
			url: "/admin/groups",
			closeOnClick: true,
		},
		{
			id: "licenses",
			label: t("mainmenu.section.admin.items.licenses", "Licenser"),
			icon: BalanceMajor,
			url: "/admin/licenses",
			closeOnClick: true,
		},
		{
			id: "targets",
			label: t("mainmenu.section.admin.items.tagets.title", "Målsättningar"),
			icon: <TargetArrowIcon3 />,
			url: "/admin/targets",
			enabled: user.roles.includes("ROLE_ADMIN"),
		},
		{
			id: "admin-goals",
			label: t("mainmenu.section.admin.items.goals_old", "Målsättningar (utgående)"),
			icon: AnalyticsMajor,
			url: "/admin/goals",
			closeOnClick: true,
		},
		{
			id: "pdfs",
			label: t("mainmenu.section.admin.items.", "PDF Mallar"),
			icon: ChecklistAlternateMajor,
			url: "/admin/pdf_templates",
			closeOnClick: true,
		},
		{
			id: "tags",
			label: t("mainmenu.section.admin.items.tags", "Taggar"),
			icon: ProductsMajor,
			url: "/admin/tags",
			closeOnClick: true,
		},
		{
			id: "articles",
			label: t("mainmenu.section.admin.items.article_register", "Artikelregister"),
			icon: ProductsMajor,
			url: "/admin/articles/settings",
			closeOnClick: true,
		},
		{
			id: "user_activity",
			label: t("mainmenu.section.admin.items.overview", "Användare aktivitet"),
			icon: SettingsMajor,
			url: "/admin/users_activity",
			closeOnClick: true,
			enabled: user?.roles?.some((role) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(role)),
		},
		{
			id: "api_user_tokens",
			label: t("mainmenu.section.api_user_tokens.items.settings", "Användar API nycklar"),
			icon: SettingsMajor,
			url: "/admin/api_user_tokens",
			closeOnClick: true,
			enabled: account.api_enabled && user.roles.includes("ROLE_ADMIN"),
		},
		{
			id: "settings",
			label: t("mainmenu.section.admin.items.settings", "Inställningar"),
			icon: SettingsMajor,
			url: "/admin/settings",
			closeOnClick: true,
		},
		{
			id: "logout",
			label: t("mainmenu.section.admin.items.logout", "Logga ut"),
			icon: LogOutMinor,
			onClick: () => {
				window.location.href = "/admin/authentication/login";
			},
		},
	].filter((i) => !("enabled" in i) || i.enabled);

	if (["419", "2", "418"].includes(String(user.id))) {
		adminItems.push({
			id: "components",
			label: "Components overview",
			icon: CodeMajor,
			url: "/admin/components",
			closeOnClick: true,
		});
	}

	const getContact = (id) => {
		if (!id) return null;
		if (!/^[0-9]+$/.test(id)) return null;
		return queryClient.getQueryData([`contact_${id}`]) || contacts[id];
	};

	const match = history.location.pathname.match(/\/admin\/contacts\/([^/]+)(\/|$)/);
	const activeContact = getContact(match?.[1]);

	const sections = [
		{
			id: "logo",
			icon: <LogoIcon />,
			url: "/admin/dashboard/mine",
			className: "logo",
		},
		{
			id: "companies",
			title: t("mainmenu.section.companies.title", "Företag"),
			icon: <CompanyIcon />,
			url: "/admin/contacts/companies",
			active: activeContact && activeContact?.is_company,
		},
		{
			id: "contacts",
			title: t("mainmenu.section.people.title", "Kontakter"),
			icon: <ContactPeopleIcon />,
			url: "/admin/contacts/people",
			active: activeContact && !activeContact?.is_company,
		},
		{
			id: "deals",
			title: t("mainmenu.section.sales.title", "Affärer"),
			icon: <DollarIcon />,
			url: `/admin/workspaces/${"none"}/boards/${account.sales_board_id}`,
			loading: loadingWorkspaces,
			roles: [`ROLE_BOARD_${account.sales_board_id}`],
			// enabled:checkUserRoles([`ROLE_WORKSPACE_${account.sales_workspace_id}`]),
		},
		{
			id: "call_lists",
			title: t("mainmenu.section.calllists.title", "Ringlistor"),
			icon: <CallListIcon />,
			url: "/admin/call_lists",
			roles: [`ROLE_WORKSPACE_${account.sales_workspace_id}`],
		},
		{
			id: "marketing",
			title: t("mainmenu.section.marketing.title", "Marknadsföring"),
			icon: <MarketingIcon />,
			items: marketingItems,
			enabled: user.roles.includes("ROLE_MARKETING"),
			secondaryItemsStyle: { marginTop: "-0.4688rem" },
		},
		{
			id: "support",
			title: t("mainmenu.section.support.title", "Servicedesk"),
			icon: <SupportIcon />,
			loading: loadingWorkspaces,
			workspace: workspaces && workspaces.find((w) => w.title === "Servicedesk"),
			url: (() => {
				const workspace = workspaces && workspaces.find((w) => w.title === "Servicedesk");
				if (workspace && workspace.title === "Servicedesk" && workspace.boards.length === 1) {
					return "/admin/workspaces/" + workspace.id + "/boards/" + workspace.boards[0].id;
				}
			})(),
			children: (() => {
				const workspace = workspaces && workspaces.find((w) => w.title === "Servicedesk");
				if (!workspace) return null;

				return (
					<MainMenuWorkspaceNavigation
						depth={-1}
						id={`${workspace.handle}-workspace`}
						location={location}
						key={workspace.handle}
						workspace={workspace}
						history={history}
						showSearch
						actions={[
							{
								content: t("mainmenu.section.support.actions.new_queue", "Ny kö"),
								onAction: () => openModal("support"),
							},
						]}
					/>
				);
			})(),
			enabled: checkUserRoles([`ROLE_WORKSPACE_${account.support_workspace_id}`]),
		},
		{
			id: "workspaces",
			title: t("mainmenu.section.workspaces.title", "Workspaces"),
			icon: <AppsIcon />,
			loading: loadingWorkspaces,
			children: (
				<WorkspaceNavigation
					location={location}
					history={history}
					actions={[
						{
							content: t("mainmenu.section.admin.actions.new_projekt_folder", "Nytt workspace"),
							onAction: () => openModal("workspace"),
						},
					]}
				/>
			),
		},
		{
			id: "targets",
			title: t("mainmenu.section.targets.title", "Målsättningar"),
			icon: <TargetArrowIcon3 />,
			url: "/admin/dashboard/targets",
			enabled: user.roles.includes("ROLE_ADMIN"),
		},
		{
			id: "integrations",
			title: t("mainmenu.section.integrations.title", "Integrationer"),
			icon: <IntegrationsIcon2 />,
			items: integrationsItems,
			actions: [
				{
					content: t("mainmenu.section.admin.actions.new", "Ny integration"),
					onAction: () => openModal("integration"),
				},
			],
			secondaryItemsStyle: { marginTop: "-0.4688rem" },
			children: !integrationsItems.length && (
				<p>{t("mainmenu.integrations.empty", "Kontakta Sellfinity för assistans med att koppla igång dina integrationer")}</p>
			),
		},
	]
		.filter((i) => i && (!("enabled" in i) || i.enabled) && (!i.url || checkUserRoles(routesAsObject[i.url]?.roles)))
		?.map((section: any) => {
			const filteredSectionItems = section.items?.filter((item) => {
				if (item.roles) return checkUserRoles(item.roles);
				if (item.url) return checkUserRoles(routesAsObject[item.url]?.roles);
				return true;
			});

			return {
				...section,
				items: filteredSectionItems,
			};
		});

	const bottomSections = [
		{
			title: t("mainmenu.section.admin.title", "Admin"),
			icon: <CogWheelIcon />,
			items: adminItems?.filter((i) => !("enabled" in i) || i.enabled),
			enabled: !!user.roles.includes("ROLE_ADMIN"),
		},
	];

	const navigationMarkup = (
		<Navigation location={location.pathname}>
			<div ref={ref} className="navigation-sidebar">
				<div className="menu-items noscroll">
					<div>
						{sections.map((section: any) => {
							const id = section.id || section.title;
							const className = `${section.active || (section.url && location.pathname.indexOf(section.url) >= 0) ? "url-active" : ""} ${
								section.className
							}`;

							return (
								<MainMenuSection
									key={id}
									id={id}
									loading={section.loading}
									actions={section.actions}
									title={section.title}
									url={section.url}
									icon={section.icon}
									items={section.items}
									className={className}
									history={history}
									location={location}
									onClick={() => onSelect({ id, url: section.url }, section.workspace)}
									toggleMenu={toggleMenu}
								>
									{section.children}
								</MainMenuSection>
							);
						})}
					</div>
					{bottomSections
						.filter((section) => !("enabled" in section) || section.enabled)
						.map((section: any) => {
							const id = section.id || section.title;
							return (
								<MainMenuSection
									key={id}
									id={id}
									loading={section.loading}
									actions={section.actions}
									title={section.title}
									url={section.url}
									icon={section.icon}
									items={section.items}
									history={history}
									location={location}
									onClick={() => onSelect({ id, url: section.url })}
									toggleMenu={toggleMenu}
								>
									{section.children}
								</MainMenuSection>
							);
						})}
				</div>
				<IntersectionObserverElement
					hideShadow
					onChange={(intersecting) => {
						const shadow = document.querySelector("#mainmenu-shadow");
						if (shadow) {
							if (intersecting) {
								shadow.classList.remove("visible");
								shadow.classList.add("invisible");
							} else {
								shadow.classList.remove("invisible");
								shadow.classList.add("visible");
							}
						}
					}}
					parent={ref.current}
					shadowStyle={{ width: ref.current && ref.current.clientWidth }}
				/>
			</div>
		</Navigation>
	);

	const modals = (
		<>
			<IntegrationModal open={!!integrationModalOpen} type="all" onClose={() => closeModal("integration")} />

			<WorkspaceModal
				resourceName={{
					singular: t("mainmenu.sections.workspace.singular1", "Workspace"),
					plural: t("mainmenu.sections.workspace.plural1", "Workspaces"),
				}}
				open={workspaceModalOpen}
				parent={parentWorkspace || {}}
				onCreate={(workspace) => {
					closeModal("workspace");
					if (workspace.type === "shareable") {
						setShowMembersModal(true);
						setWorkspaceState(workspace);
					}
				}}
				onClose={() => closeModal("workspace")}
			/>

			{showMembersModal && (
				<BoardMembers
					hideTrigger
					show={showMembersModal}
					onClose={() => {
						setShowMembersModal(false);
						setWorkspaceState(null);
					}}
					workspace={workspaceState}
					type="workspace"
					onUpdateWorkspace={onUpdateWorkspace}
					hideNewExternal
				/>
			)}
			<BoardTemplateModal
				open={boardModalOpen}
				workspace={parentWorkspace}
				onCreate={(board, type) => {
					if (board.type === "shareable" || type === "shareable") {
						history.push(`/admin/workspaces/${board.workspace_id}/boards/${board.id}`, {
							openBoardMembers: true,
						});
					} else {
						history.push(`/admin/workspaces/${board.workspace_id}/boards/${board.id}`);
					}
					fetchWorkspaces();
					closeModal("board");
				}}
				onClose={() => closeModal("board")}
			/>
		</>
	);

	return (
		<>
			{navigationMarkup}
			{modals}
			{ReactDOM.createPortal(
				<div className="mainmenu-item__secondary__collapse-btn" onClick={toggleMenu}>
					<Icon source={CircleChevronRightMinor} />
				</div>,
				document.querySelector("body")!
			)}
			<div id="mainmenu-shadow" className="bottom-scroll-shadow" style={{ width: "100px", pointerEvents: "none" }} />
		</>
	);
};

export default withTranslation(["mainmenu", "common"], { withRef: true })(MainMenu);

const MainMenuWorkspaceNavigation = (props) => {
	const { actions, workspace, history, location } = props;
	const [search, setSearch] = useState("");

	const favorites =
		workspace &&
		workspace.boards.filter(
			(wb) => store.getState().favorite_boards.find((b) => b.id === wb.id) && wb.title.toLowerCase().includes(search.toLowerCase())
		);

	return (
		<>
			<MainMenuSectionSearch value={search} onChange={(e) => setSearch(e)} actions={actions} />

			{favorites && !!favorites.length && <MainSectionFavorites history={history} location={location} items={favorites} />}

			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<WorkspaceNavigation {...props} search={search} style={{ marginTop: "-0.4688rem" }} />
		</>
	);
};
