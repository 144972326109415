import React from "react";

const BagIcon = ({ color = "black" }) => (
	<svg width="16" height="18" viewBox="0 0 16 18" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.4388 4.83274C12.4653 4.77368 12.4777 4.70932 12.4751 4.64468H12.4969C12.4037 2.421 10.5676 0.666077 8.33432 0.666077C6.10103 0.666077 4.26497 2.421 4.17177 4.64468C4.16079 4.70689 4.16079 4.77053 4.17177 4.83274H4.10663C3.03872 4.83274 1.89715 5.53772 1.56573 7.4328L0.917621 12.595C0.387347 16.3852 2.33905 17.3327 4.88731 17.3327H11.7956C14.3365 17.3327 16.2293 15.9607 15.7579 12.595L15.1172 7.4328C14.7268 5.59078 13.6221 4.83274 12.5689 4.83274H12.4388ZM11.2412 4.83274C11.218 4.7727 11.2057 4.70902 11.2049 4.64468C11.2049 3.04676 9.90508 1.7514 8.30166 1.7514C6.69823 1.7514 5.3984 3.04676 5.3984 4.64468C5.40939 4.70689 5.40939 4.77053 5.3984 4.83274H11.2412ZM5.91103 9.12322C5.50428 9.12322 5.17454 8.78384 5.17454 8.36519C5.17454 7.94654 5.50428 7.60715 5.91103 7.60715C6.31778 7.60715 6.64752 7.94654 6.64752 8.36519C6.64752 8.78384 6.31778 9.12322 5.91103 9.12322ZM9.99856 8.36519C9.99856 8.78384 10.3283 9.12322 10.735 9.12322C11.1418 9.12322 11.4715 8.78384 11.4715 8.36519C11.4715 7.94654 11.1418 7.60715 10.735 7.60715C10.3283 7.60715 9.99856 7.94654 9.99856 8.36519Z"
			fill={color}
		/>
	</svg>
);

export default BagIcon;
