const getFirstRelationship = (contact) => {
	if (!contact) return null;

	const order = ["customer", "contacted", "in_progress", "ex_customer", "none"];

	const fieldsSorted = contact.fields?.sort((a, b) => {
		const aindex = order.findIndex((i) => i === (a && a.relationship));
		const bindex = order.findIndex((i) => i === (b && b.relationship));

		return aindex - bindex;
	});

	return fieldsSorted?.[0];
};

export default getFirstRelationship;
