type obj = {
	[key: string]: any;
};

const setValueByPath = (obj: obj, path: string, value: any) => {
	const keys = path.split(".");
	let current = obj;

	keys.slice(0, -1).forEach((key) => {
		if (!(key in current)) {
			current[key] = {};
		}
		current = current[key];
	});

	current[keys[keys.length - 1]] = value;
	return obj;
};

export default setValueByPath;
