import React from "react";
import Select from "../select/Select";
import { FormLayout, Icon } from "@shopify/polaris";
import { ArrowRightMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

type OriginMapp = {
	label: string;
	title?: string;
	value: string;

	required?: boolean;

	helpText?: string;
	options?: any[];
	mappStyle?: React.CSSProperties;
};

const Mappings = ({ t, title, onChange, mappings, sourceMappings, fileMappings, alternateBackgrundColors = [], mappStyle = {} }) => {
	const handleChangeMapp = (origin) => (remoteValue) => {
		const newMappings = { ...mappings };
		newMappings[origin] = remoteValue;
		onChange(newMappings);
	};

	const usedRemoteValues = Object.values(mappings);
	const remoteOptions =
		fileMappings?.map((m) => ({
			...m,
			value: String(m.value),
			disabled: usedRemoteValues.includes(String(m.value)),
		})) || [];

	return (
		<Wrapper>
			<FormLayout>
				{title && <h3>{title}</h3>}
				{sourceMappings?.map((mapping: OriginMapp, index) => {
					const remoteValue = mappings[mapping.value];
					// const remoteValue = mappings.find((m: any) => m.origin === mapping.value)?.remote;
					const backgroundColor = alternateBackgrundColors?.length ? alternateBackgrundColors[index % 2 ? 1 : 0] : "transparent";

					return (
						<MappingRow key={mapping.value} backgroundColor={backgroundColor} style={mappStyle}>
							<div>
								<p>
									{mapping.label || mapping.title}
									{mapping.required && "*"}
								</p>
								{mapping.helpText && <p style={{ color: "var(--textColor2)", fontSize: "0.9em" }}>{mapping.helpText}</p>}
							</div>

							<Icon source={ArrowRightMinor} color={remoteValue ? "success" : undefined} />
							{/* {remoteValue ? <Icon source={ArrowRightMinor} color="success" /> : <Icon source={CancelSmallMinor} />} */}

							<Select
								required={mapping.required}
								options={[
									{
										label: mapping.required
											? (t("import.articles.mapping.select", "Välj excel kolumn") as string)
											: (t("import.articles.mapping.empty", "Importera ej") as string),
										value: "",
									},
									...(mapping.options || remoteOptions),
								]}
								value={remoteValue}
								onChange={handleChangeMapp(mapping.value)}
							/>
						</MappingRow>
					);
				})}
			</FormLayout>
		</Wrapper>
	);
};
export default withTranslation(["mappings", "common"])(Mappings);

const Wrapper = styled.div`
	h3 {
		font-size: 1.5rem;
		font-weight: 600;
	}
`;

const MappingRow = styled.div<{ backgroundColor?: string }>`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	/* padding: 10px 0; */
	gap: 10px;

	${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;
