import React from "react";

const DollarIcon = ({ color = "var(--textColor)" }) => (
	<svg width="10" height="19" viewBox="0 0 10 19" fill="none" filltype="pathfill">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 6.175C0 4.08105 1.73751 2.37635 4.00521 1.98475L4.00117 1.94319L4 0.95C4 0.425329 4.44772 0 5 0C5.55228 0 6 0.425329 6 0.95V1.9L5.99578 1.98492C8.263 2.37685 10 4.08136 10 6.175C10 6.69967 9.55228 7.125 9 7.125C8.48716 7.125 8.06449 6.75826 8.00673 6.28579L8 6.175C8 4.89363 6.68435 3.8 5 3.8C3.31565 3.8 2 4.89363 2 6.175C2 7.40891 3.22 8.46873 4.81442 8.54555L5 8.55C7.73393 8.55 10 10.4337 10 12.825C10 14.9171 8.26555 16.6206 6.00078 17.0142L6 18.05C6 18.5747 5.55228 19 5 19C4.44772 19 4 18.5747 4 18.05L4.00022 17.0144C1.73496 16.6212 0 14.9174 0 12.825C0 12.3003 0.447715 11.875 1 11.875C1.55228 11.875 2 12.3003 2 12.825C2 14.1064 3.31565 15.2 5 15.2C6.68435 15.2 8 14.1064 8 12.825C8 11.5911 6.78 10.5313 5.18558 10.4545L5 10.45C2.26607 10.45 0 8.56633 0 6.175Z"
			fill={color}
		/>
	</svg>
);

export default DollarIcon;
