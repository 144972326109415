import React from "react";
import { HeaderWrapper } from "./styles";
import Tabs from "src/js/components/Tabs";

const Header = ({ customfield, tabs, selected, onSelect }) => {
	return (
		<HeaderWrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</HeaderWrapper>
	);
};
export default Header;
