/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

type TruncateProps = {
	numberOfLines?: number;
	maxWidth?: number | string | null;
	children: React.ReactNode;
	[key: string]: any;
};

const Truncate = ({ numberOfLines = 1, children, maxWidth = null, ...rest }: TruncateProps) => {
	return (
		<Wrapper numberOfLines={numberOfLines} maxWidth={maxWidth} {...rest}>
			{children}
		</Wrapper>
	);
};
export default Truncate;

const Wrapper = styled.span<{ numberOfLines: number; maxWidth?: number | string | null }>`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
	line-clamp: ${({ numberOfLines }) => numberOfLines};
	-webkit-box-orient: vertical;
	max-width: ${({ maxWidth }) => (typeof maxWidth === "number" ? maxWidth + "px" : maxWidth || "unset")};
`;
