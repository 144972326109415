import { Component } from "react";

class OnMount extends Component<{ onMount?: () => void; onUnmount?: () => void }> {
	componentDidMount(): void {
		const { onMount } = this.props;
		if (onMount) onMount();
	}

	componentWillUnmount(): void {
		const { onUnmount } = this.props;
		if (onUnmount) onUnmount();
	}

	render() {
		return null;
	}
}
export default OnMount;
