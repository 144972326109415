import React, { Component } from "react";
import { ActionList, Icon, LegacyStack, Badge } from "@shopify/polaris";
import { CircleTickMajor, CircleDisabledMajor, DeleteMinor } from "@shopify/polaris-icons";

import { withTranslation } from "react-i18next";
import Popover from "./Popover";

class AdStatusSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			statusPopoverActive: false,
			status: props.status,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ status: props.status });
	}

	toggleStatusPopover() {
		this.setState({ statusPopoverActive: !this.state.statusPopoverActive });
	}

	changeStatus(value) {
		this.setState({ status: value });
		this.toggleStatusPopover();
		this.props.onChange(value);
	}

	render() {
		const progress = null;
		let status;
		let icon;
		let statusTitle;

		if (this.state.status == "ENABLED") {
			icon = <Icon source={CircleTickMajor} />;
			statusTitle = this.props.t("ad.statuses.enabled", "Aktiv");
			status = "success";
		} else if (this.state.status == "PAUSED") {
			icon = <Icon source={CircleDisabledMajor} />;
			statusTitle = this.props.t("ad.statuses.paused", "Pausad");
			status = "attention";
		} else if (this.state.status == "REMOVED") {
			icon = <Icon source={DeleteMinor} />;
			statusTitle = this.props.t("ad.statuses.removed", "Borttagen");
			status = "critical";
		}
		const button = (
			<span
				style={{ cursor: "pointer", color: "var(--textColor)", pointerEvents: this.props.disabled ? "none" : "auto" }}
				onClick={this.toggleStatusPopover.bind(this)}
			>
				<LegacyStack wrap={false} spacing="extraTight">
					<span>{this.props.t("ad.terms.status", "Status")}:</span> {icon}{" "}
					<Badge status={status} progress={progress}>
						{statusTitle}
					</Badge>
				</LegacyStack>
			</span>
		);

		if (this.props.disabled) return button;
		return (
			<LegacyStack>
				<span
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<Popover active={this.state.statusPopoverActive} activator={button} onClose={this.toggleStatusPopover.bind(this)}>
						<ActionList
							items={[
								{ content: this.props.t("ad.statuses.enabled", "Aktiv"), icon: CircleTickMajor, onAction: this.changeStatus.bind(this, "ENABLED") },
								{
									content: this.props.t("ad.statuses.paused", "Pausad"),
									icon: CircleDisabledMajor,
									onAction: this.changeStatus.bind(this, "PAUSED"),
								},
								{ content: this.props.t("ad.statuses.removed", "Borttagen"), icon: DeleteMinor, onAction: this.changeStatus.bind(this, "REMOVED") },
							]}
						/>
					</Popover>
				</span>
			</LegacyStack>
		);
	}
}
export default withTranslation(["ad", "common"], { withRef: true })(AdStatusSelector);
