import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Modal from "src/js/components/modal";

const DeleteModal = ({ open, onClose, document, onDelete, children = null, t }) => {
	const [loading, setLoading] = useState(false);
	const handleRemove = async () => {
		setLoading(true);
		try {
			await onDelete();
			onClose();
		} catch (error) {
			console.error("error:", error);
		}

		setLoading(false);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			sectioned
			title={t("scrive.document.remove.title", "Ta bort dokument")}
			primaryAction={{
				content: t("common.actions.remove", "Ta bort"),
				onAction: handleRemove,
				destructive: true,
				loading,
			}}
			secondaryActions={[
				{
					content: t("common.actions.cancel", "Avbryt"),
					onAction: onClose,
				},
			]}
		>
			{children}
		</Modal>
	);
};
export default withTranslation(["scrive", "common"])(DeleteModal);
