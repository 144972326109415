/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from "@shopify/polaris";
import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";

const EntrySerchfield = ({ t, onChange, value, ...props }) => {
	const [search, setSearch] = useState(value);

	const handleOnChange = debounce(onChange, 1000, {
		leading: false,
		trailing: true,
	});

	const handleChange = useCallback(
		(v) => {
			setSearch(v);
			handleOnChange(v);
		},
		[handleOnChange]
	);

	return (
		<TextField
			{...props}
			label={t("common.language.keyword.label", "Sök på fras")}
			placeholder={t("common.language.keyword.placeholder", "sökord...")}
			value={search}
			onChange={handleChange}
		/>
	);
};

export default withTranslation(["common"])(EntrySerchfield);
