type Color = string;

const getContrastColor = (color: Color): "black" | "white" | null => {
	// Check if the input color is in hex, rgb, or rgba format
	const hexRegex = /^#?([0-9a-f]{3}|[0-9a-f]{6})$/i;
	const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
	const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)$/;

	const match = color.match(hexRegex) || color.match(rgbRegex) || color.match(rgbaRegex);

	if (!match) {
		// Invalid color format
		console.error("Invalid color format");
		return null;
	}

	let [, hex, r, g, b]: any[] = match.map((group) => group || undefined);

	if (hex) {
		// Handle shorthand hex and convert to full hex
		hex = hex.length === 3 ? hex.replace(/./g, (char) => char + char) : hex;
		[r, g, b] = (hex.match(/.{2}/g) || []).map((channel) => parseInt(channel, 16)) || [];
	}

	// Ensure values are defined
	if ([r, g, b].some((channel) => typeof channel !== "number" || Number.isNaN(channel))) {
		console.error("Invalid color values");
		return null;
	}

	// Calculate relative luminance (per ITU-R BT.709)
	const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

	// Determine if text should be black or white based on luminance
	const textColor: "black" | "white" = luminance > 128 ? "black" : "white";

	return textColor;
};

export default getContrastColor;
