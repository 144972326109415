import React from "react";

const ReturnIcon = ({ color = "var(--textColor)" }) => (
	<svg width="24" height="24" viewBox="0 0 32 32" fill="none" style={{ fill: "none" }} xmlns="http://www.w3.org/2000/svg">
		<path d="M9.97498 12.4625H3.97498V6.46252" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M23.775 8.22499C22.7545 7.20301 21.5425 6.39224 20.2084 5.83907C18.8743 5.28589 17.4442 5.00116 16 5.00116C14.5557 5.00116 13.1256 5.28589 11.7915 5.83907C10.4574 6.39224 9.24547 7.20301 8.22498 8.22499L3.97498 12.4625"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M22.025 19.5375H28.025V25.5375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M8.22498 23.775C9.24547 24.797 10.4574 25.6077 11.7915 26.1609C13.1256 26.7141 14.5557 26.9988 16 26.9988C17.4442 26.9988 18.8743 26.7141 20.2084 26.1609C21.5425 25.6077 22.7545 24.797 23.775 23.775L28.025 19.5375"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ReturnIcon;
