import React, { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";
import styled from "styled-components";

type SemiCircleGraphProps = {
	percentage: number;
	label?: string;
	subLabel?: string;
	color?: string;
	backgroundColor?: string;
	boxBackgroundColor?: string | null;
	radius?: number;
	strokeWidth?: number;
	style?: React.CSSProperties;
	isLoading?: boolean;
	text?: string | number | React.ReactNode;
	duration?: number;
};

const SemiCircleGraph = ({
	percentage,
	label,
	subLabel,
	color = "var(--accent)",
	backgroundColor = "var(--main1)",
	boxBackgroundColor = null,
	radius = 75,
	strokeWidth = 12,
	style,
	isLoading,
	text = `${percentage.toFixed(0)}%`,
	duration = 1000,
}: SemiCircleGraphProps) => {
	const circumference = Math.PI * radius; // Half circle circumference
	const progress = (percentage / 100) * circumference;
	const viewBoxWidth = radius * 2 + strokeWidth;
	const viewBoxHeight = radius + strokeWidth;

	const calculateDotPosition = useCallback(
		(percentage: number) => {
			const angle = Math.PI * (percentage / 100);
			const dotPositionX = radius + strokeWidth / 2 + radius * Math.cos(Math.PI + angle);
			const dotPositionY = radius + strokeWidth / 2 + radius * Math.sin(Math.PI + angle);
			return { cx: dotPositionX, cy: dotPositionY };
		},
		[radius, strokeWidth]
	);

	const pathRef = useRef<SVGPathElement>(null);
	const dotRef = useRef<SVGCircleElement>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [dotPosition, setDotPosition] = useState(() => calculateDotPosition(0));
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!initialized) {
			setDotPosition(calculateDotPosition(0));
			setInitialized(true);
		} else {
			const { cx, cy } = calculateDotPosition(percentage);

			if (pathRef.current) {
				pathRef.current.style.transition = `stroke-dasharray ${duration}ms ease, stroke ${duration}ms ease`;
				pathRef.current.style.strokeDasharray = `${progress} ${circumference}`;
			}

			if (dotRef.current) {
				dotRef.current.style.transition = `cx ${duration}ms, cy ${duration}ms`;
				dotRef.current.setAttribute("cx", cx.toString());
				dotRef.current.setAttribute("cy", cy.toString());
			}
		}
	}, [progress, circumference, percentage, calculateDotPosition, initialized, duration]);

	return (
		<Wrapper
			style={{ textAlign: "center", backgroundColor: boxBackgroundColor || undefined, padding: "10px", borderRadius: "10px", ...(style || {}) }}
		>
			<svg width={viewBoxWidth} height={viewBoxHeight} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
				<path
					d={`M ${strokeWidth / 2} ${radius + strokeWidth / 2} a ${radius} ${radius} 0 0 1 ${radius * 2} 0`}
					fill="none"
					stroke={backgroundColor}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					ref={pathRef}
					d={`M ${strokeWidth / 2} ${radius + strokeWidth / 2} a ${radius} ${radius} 0 0 1 ${radius * 2} 0`}
					fill="none"
					stroke={color}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray={`0 ${circumference}`}
				/>
				{/* <circle
					ref={dotRef}
					cx={dotPosition.cx}
					cy={dotPosition.cy}
					r={(strokeWidth / 2) * 1.5} // Slightly larger than the path width
					fill={color}
					style={{ transition: "cx 1s, cy 1s" }}
				/> */}
				<text x={radius + strokeWidth / 2} y={radius / 1.5 + strokeWidth / 2 + radius / 5} textAnchor="middle" fontSize="1.2em" fill="#fff">
					{/* <text x={radius + strokeWidth / 2} y={radius / 1.5 + strokeWidth / 2} textAnchor="middle" fontSize="1.2em" fill="#fff"> */}
					{isLoading ? "" : text}
				</text>
			</svg>
			{isLoading && (
				<Spinner
					size={20}
					style={{
						position: "absolute",
						inset: 0,
						transform: `translate(0%, calc(50% + ${radius / 5}px))`,
						// opacity: initialized ? 0 : 1,
					}}
				/>
			)}
			<div style={{ marginTop: "10px", color: "#fff" }}>
				<div style={{ fontSize: "14px", color: "#888" }}>{label}</div>
				<div style={{ fontSize: "12px", color: "#aaa" }}>{subLabel}</div>
			</div>
		</Wrapper>
	);
};

export default SemiCircleGraph;

const Wrapper = styled.div`
	position: relative;
`;
