import { FormLayout } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import { getDocumentLabel } from "../Utilities";

const ScriveDocumentInfoModal = ({ open, onClose, document, onSave, t, isSaving }) => {
	const [form, setForm] = useState<ScriveDocumentType>(document);

	const handleClose = () => {
		setForm(document);
		onClose();
	};

	const handleSave = async () => {
		await onSave(form);
		onClose();
	};

	const getChangeHandler = (field: ScriveDocumentTypeKeys) => (value: string | boolean) => {
		setForm((c: ScriveDocumentType) => {
			const newForm = { ...c };
			if (field === "days_to_sign") {
				newForm.days_to_sign = parseInt(value as string);
			} else {
				newForm[field as string] = value;
			}

			return newForm;
		});
	};

	useEffect(() => {
		setForm(document);
	}, [open, document]);

	return (
		<Modal
			title={document.title}
			open={open}
			onClose={handleClose}
			primaryAction={{
				content: t("common.actions.save", "Spara"),
				onAction: handleSave,
				loading: isSaving,
			}}
			secondaryActions={[
				{
					content: t("common.actions.cancel", "Avbryt"),
					onAction: handleClose,
				},
			]}
			sectioned
		>
			<FormLayout>
				<TextField label={getDocumentLabel("title")} value={form.title} onChange={getChangeHandler("title")} />
				<TextField
					label={getDocumentLabel("days_to_sign")}
					value={String(form.days_to_sign)}
					onChange={getChangeHandler("days_to_sign")}
					type="number"
				/>
				<TextField
					label={`${getDocumentLabel("invitation_message")} (valfritt)`}
					value={String(form.invitation_message)}
					onChange={getChangeHandler("invitation_message")}
					showCharacterCount
					multiline={3}
				/>
				<TextField
					label={`${getDocumentLabel("sms_invitation_message")} (valfritt)`}
					value={String(form.sms_invitation_message)}
					onChange={getChangeHandler("sms_invitation_message")}
					showCharacterCount
					multiline={3}
				/>
			</FormLayout>
		</Modal>
	);
};
export default withTranslation(["scrive", "common"])(ScriveDocumentInfoModal);
