import Modal from "src/js/components/modal";
import React, { Component } from "react";
import styled from "styled-components";
import introJs from "intro.js";
import { withTranslation } from "react-i18next";
import Colors, { colorToRgba } from "../../../Colors";
import { LightBulb, QuestionMark, SendIcon } from "../../../icons";
import TabsBar from "../TabsBar";
import QRCode from "./QRCode";
import HelpDeskSheetContent from "./HelpDeskSheetContent";
import { getSessionStorage, setSessionStorage } from "../../../Utilities";
import Sheet from "../../../components/sheet";

class HelpBar extends Component {
	constructor(props) {
		super(props);
		this.state = { mount: !getSessionStorage("helpbar_seen") };
		this.onTransitionend = this.handleTransitionend.bind(this);
	}

	handleTransitionend() {
		const bar = document.querySelector(".helpbar");
		if (bar && bar.classList.contains("hidden")) {
			this.setState({ mount: false });
			setSessionStorage("helpbar_seen", true);
		}
	}

	componentDidMount() {
		const bar = document.querySelector(".helpbar .DashboardTabs");
		if (bar) {
			bar.addEventListener("transitionend", this.onTransitionend);
		}

		this.setState({ mount: !getSessionStorage("helpbar_seen") }, () => {
			const intro = introJs();
			if (!this.state.mount && !introJs().isActive()) {
				intro.setOptions({
					tooltipClass: "introjs_tooltip",
					showProgress: true,
					// dontShowAgain: true,
					// showButtons: false,
					showBullets: false,
					exitOnOverlayClick: true,
					disableInteraction: false,
					overlayOpacity: 0,
				});
				intro.start(".dashboard_mine");
				intro.oncomplete(() => {
					// eslint-disable-next-line no-console
					console.debug("INTROJS COMPLETE");
					// intro.setDontShowAgain(true);
				});
				intro.onexit(() => {
					// eslint-disable-next-line no-console
					console.debug("INTROJS EXIT");
					// intro.setDontShowAgain(true);
				});
			}
		});
	}

	componentWillUnmount() {
		const bar = document.querySelector(".helpbar");
		bar?.removeEventListener("transitionend", this.onTransitionend);
	}

	openModal(modal) {
		this.setState({ [modal]: true });
	}

	closeModal(modal) {
		this.setState({ [modal]: false });
	}

	render() {
		const tabs = [
			{
				content: this.props.t("help.tabs.helpcenter", "Hjälpcenter"),
				onAction: () => this.props.history.push("/admin/help"),
				icon: <QuestionMark />,
			},
			{
				content: this.props.t("help.tabs.contact_us", "Kontakta oss"),
				onAction: this.openModal.bind(this, "helpDeskForm"),
				icon: <SendIcon />,
			},
			{
				content: this.props.t("help.tabs.install_app", "Installera app"),
				onAction: this.openModal.bind(this, "installApp"),
			},
		];

		if (!this.state.mount) return null;
		return (
			<Wrapper className="helpbar" theme={this.context && this.context.theme}>
				<TabsBar
					backgroundColor={this.context && this.context.theme === "dark" ? "#243837" : colorToRgba(Colors.green, 0.2)}
					tabs={tabs}
					selected={0}
					renderItem={(item) => (
						<HelpBarButton>
							{item.content}
							{item.icon}
						</HelpBarButton>
					)}
					onDismiss={() => {
						const bar = document.querySelector(".helpbar");
						if (bar) {
							bar.classList.add("hidden");
						}
						setSessionStorage("helpbar_seen", true);
						introJs().start();
					}}
				>
					<Bulb className="item visible">
						<LightBulb />
					</Bulb>
				</TabsBar>

				<Modal
					open={this.state.installApp}
					onClose={this.closeModal.bind(this, "installApp")}
					primaryAction={{
						content: this.props.t("common.actions.done", "Klar"),
						onAction: this.closeModal.bind(this, "installApp"),
					}}
				>
					<Modal.Section>
						<QRCode />
					</Modal.Section>
				</Modal>

				<Sheet open={this.state.helpDeskForm} onClose={this.closeModal.bind(this, "helpDeskForm")}>
					<HelpDeskSheetContent />
				</Sheet>
			</Wrapper>
		);
	}
}

export default withTranslation(["help", "common"], { withRef: true })(HelpBar);

export const HelpBarButton = ({ children, onClick, disabled, className, type, style }) => {
	return (
		<StyledHelpBarButton onClick={onClick} disabled={disabled} className={className} type={type} style={style}>
			{children}
		</StyledHelpBarButton>
	);
};

const StyledHelpBarButton = styled.div.attrs({ className: "button" })`
	border: none;
	padding: 5px 20px;
	font-weight: bold;
	color: #ffffff;
	border-radius: 50px;
	height: 100%;
	align-items: center;
	display: flex;
	transition: filter 250ms;
	/* background: ${({ theme }) => (theme === "dark" ? "#29463e" : Colors.green)}; */
	background: #141830;

	&:not(:disabled) {
		cursor: pointer;
	}

	svg {
		margin-left: 0.625rem;
		height: 18px;
		width: 18px;

		path {
			fill: #ffffff;
		}
	}

	&:hover {
		filter: brightness(1.1);
	}
`;

const Bulb = styled.div`
	&&& {
		border-radius: 100%;
		min-width: 45px;
		min-height: 45px;
		justify-content: center;
		margin: 0 1.875rem;
		pointer-events: none;

		svg path {
			fill: #5ac57d;
		}
	}
`;
const Wrapper = styled.div`
	.DashboardTabs .item {
		margin: 0 0.9375rem;
	}

	&.hidden .DashboardTabs {
		height: 0px;
		pointer-events: none;
		visibility: hidden;
		margin: 0;
		opacity: 0;

		* {
			pointer-events: none;
		}
		.dismiss_button {
			display: none;
		}
	}

	${Bulb} {
		background: "#29463e";
		/* background: ${({ theme }) => (theme === "dark" ? "#29463e" : colorToRgba(Colors.green, 0.2))}; */
	}
`;
