import React from "react";

const DealingsIcon = ({ color = "black" }) => (
	<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.4258 2.69647V11.6965H11.4258V16.6965H2.42578V2.69647H16.4258ZM16.4258 0.696472H2.42578C1.32578 0.696472 0.425781 1.59647 0.425781 2.69647V16.6965C0.425781 17.7965 1.32578 18.6965 2.42578 18.6965H12.4258L18.4258 12.6965V2.69647C18.4258 1.59647 17.5258 0.696472 16.4258 0.696472ZM9.42578 11.6965H4.42578V9.69647H9.42578V11.6965ZM14.4258 7.69647H4.42578V5.69647H14.4258V7.69647Z"
			fill={color}
		/>
	</svg>
);

export default DealingsIcon;
