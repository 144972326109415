import React, { Component } from "react";
import { LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import MyPopover from "../../components/Popover";

class CellBadges extends Component {
	constructor(props) {
		super(props);
		this.state = { value: props.value || [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			this.setState({ value: props.value || [] });
		}
	}

	render() {
		const overflowingOptions = 0;
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						className={this.state.active ? "expandedCell" : ""}
						style={{
							height: this.state.active ? "auto" : this.props.height,
							minHeight: this.props.height,
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							paddingTop: 5,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										this.input.focus();
									}, 100);
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<LegacyStack spacing="none" wrap={false}>
							<LegacyStack.Item fill>
								<LegacyStack spacing="extraTight" vertical={this.state.active} distribution="center">
									{this.state.value.map((title, index) => (
										<span key={index} className="Polaris-Badge">
											<span className="Polaris-Badge__Content">{title}</span>
										</span>
									))}
								</LegacyStack>
							</LegacyStack.Item>
							{overflowingOptions ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{overflowingOptions}
								</div>
							) : null}
						</LegacyStack>
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div style={{ width: 250 }} />
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellBadges);
