import React from "react";

const HouseIcon = ({ color = "var(--textColor)" }) => (
	<svg width="16" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.1433 10.771V0.941711H5.85667V5.85636H0.942017V23.0576H10.7713V18.143H13.2286V23.0576H23.0579V10.771H18.1433ZM5.85667 20.6003H3.39934V18.143H5.85667V20.6003ZM5.85667 15.6857H3.39934V13.2283H5.85667V15.6857ZM5.85667 10.771H3.39934V8.31368H5.85667V10.771ZM10.7713 15.6857H8.31399V13.2283H10.7713V15.6857ZM10.7713 10.771H8.31399V8.31368H10.7713V10.771ZM10.7713 5.85636H8.31399V3.39904H10.7713V5.85636ZM15.686 15.6857H13.2286V13.2283H15.686V15.6857ZM15.686 10.771H13.2286V8.31368H15.686V10.771ZM15.686 5.85636H13.2286V3.39904H15.686V5.85636ZM20.6006 20.6003H18.1433V18.143H20.6006V20.6003ZM20.6006 15.6857H18.1433V13.2283H20.6006V15.6857Z"
			fill={color}
		/>
	</svg>
);

export default HouseIcon;
