import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Colors from "../Colors";
import { LegacyStack } from "@shopify/polaris";
import Spinner from "./Spinner";

type DetailsviewProps = {
	items: any[] | undefined;
	// items: {
	// 	label?: string;
	// 	value: any;
	// 	type?: string;
	// 	href?: string;
	// 	component?: any;
	// 	className?: string;
	// 	color?: string;
	// 	height?: string;
	// 	loading?: boolean;
	// 	duplicates?: any[];
	// 	style?: React.CSSProperties;
	// 	valueStyle?: React.CSSProperties;
	// }[];
	style?: React.CSSProperties;
};

const Detailsview = ({ items, style }: DetailsviewProps) => {
	const { t } = useTranslation(["common"]);
	const getValueComponent = (
		{ label, value, type, href, component, className, color, height, loading, duplicates, style: propsStyle = {}, valueStyle = {} },
		key
	) => {
		const style: React.CSSProperties = Object.assign({}, propsStyle, valueStyle);
		const h = height;
		if (h) style.height = h;
		if (color) style.color = color;

		if (component)
			return (
				<span style={style} className="info-view__compoment" key={key}>
					{component}
				</span>
			);

		if (href && value) {
			return (
				<a href={href} target="_blank" key={key} style={style} rel="noreferrer">
					{value}
				</a>
			);
		}

		if (typeof value === "string" && (value?.startsWith("http") || value?.startsWith("www"))) {
			return (
				<a href={value} target="_blank" key={key} style={style} rel="noreferrer">
					{value}
				</a>
			);
		}

		if (type === "email" && value) {
			return (
				<a href={`mailto:${value}`} key={key} style={style}>
					{value}
				</a>
			);
		}
		if (type === "phone" && value) {
			return (
				<a href={`tel:${value}`} key={key} style={style}>
					{value}
				</a>
			);
		}
		if ((type === "orgnr" || type === "company_number") && value) {
			return (
				<LegacyStack>
					<a
						href={`https://www.allabolag.se/${value?.number?.replace("-", "") || value?.replace("-", "")}`}
						target="_blank"
						key={key}
						style={style}
						rel="noreferrer"
					>
						{value?.number || value}
					</a>
					{loading && <Spinner size="small" />}
					{!loading && duplicates?.length > 0 && <div style={{ color: Colors.red }}>{t("contact.errors.exists", "Företag finns redan")}</div>}
				</LegacyStack>
			);
		}
		return (
			<span key={key} style={style}>
				{typeof value !== "number" && !value ? "-" : value}
			</span>
		);
	};

	const anyLabel = items?.some((item) => item.label);

	return (
		<Wrapper style={style}>
			{items?.map((item, index) => (
				<Item key={index} anyLabel={anyLabel}>
					{anyLabel && <Label>{item.label}:</Label>}
					{getValueComponent(item, index)}
				</Item>
			))}
		</Wrapper>
	);
};
export default Detailsview;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	a {
		color: var(--textColor);
		text-decoration: underline;
	}
`;

const Item = styled.div<{ anyLabel?: boolean }>`
	display: grid;
	grid-template-columns: ${({ anyLabel }) => (anyLabel ? "1fr 1fr" : "1fr")};

	font-size: 1em;
	line-height: 15px;
`;

const Label = styled.span`
	font-weight: bold;
`;
