import { format } from "./formatRow";

const getOrderRowTB = (orderRow?: OrderRowType | null) => {
	if (!orderRow) {
		return {
			tb1: 0,
			tb2: 0,
		};
	}

	const tb1 = orderRow.article_pricelist_tb1_in_cents || 0;
	const tb2 = orderRow.article_pricelist_tb2_in_cents || 0;
	const price = orderRow.price_in_cents || 0;
	const quantity = orderRow.quantity || 0;
	const discount = orderRow.discount || 0;

	const articlePriceInCents = orderRow?.article_pricelist_price_in_cents || 0;

	const priceValue = price * quantity;
	const discountInCents = orderRow.discount_type === "PERCENT" ? (discount ? priceValue * (discount / 100) : 0) : discount * 100;

	const getTb = (tbInCents) => {
		const perArticleCostInCentsTB = articlePriceInCents - tbInCents;
		const perArticlePriceInCentsTB = price - perArticleCostInCentsTB;

		return perArticlePriceInCentsTB * quantity - discountInCents;
	};

	const TB1totalInCents = orderRow.order_row_article?.disable_tb1_when_placing_order ? getTb(tb1) : orderRow.tb1_in_cents || 0;
	const TB2totalInCents = orderRow.order_row_article?.disable_tb1_when_placing_order ? getTb(tb2) : orderRow.tb2_in_cents || 0;

	const TB1totalPretty = orderRow.order_row_article?.disable_tb1_when_placing_order ? format(TB1totalInCents / 100) : orderRow.tb1 || 0;
	const TB2totalPretty = orderRow.order_row_article?.disable_tb2_when_placing_order ? format(TB2totalInCents / 100) : orderRow.tb2 || 0;

	return {
		tb1: TB1totalPretty,
		tb2: TB2totalPretty,
		tb1_in_cents: TB1totalInCents,
		tb2_in_cents: TB2totalInCents,
	};
};
export default getOrderRowTB;
