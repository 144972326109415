import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import Toast from "src/js/components/Toast";

type SetFormFunction = (form: Partial<FortnoxArticleType> | null | ((prevForm: Partial<FortnoxArticleType>) => Partial<FortnoxArticleType>)) => void;

type FortnoxArticleContextValue = {
	article: FortnoxArticleType | null;
	form: Partial<FortnoxArticleType> | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	contact: ContactType | null;
	customer: FortnoxCustomerType | null;
	isSaving: boolean;
	handleSave: (article: FortnoxArticleType) => any;
	isNew: boolean;
} & WithTranslation;

const FortnoxArticleContext = React.createContext({} as FortnoxArticleContextValue);

export const FortnoxArticleContextProvider = withTranslation(["fortnox", "common"])(
	({ id: propsId, match, history, children, t, defaultData = null }) => {
		const [form, setForm] = useState<Partial<FortnoxArticleType> | null>(defaultData);
		const id = propsId || match.params.id;
		const [isSaving, setIsSaving] = useState(false);
		const isNew = !id;

		const queryClient = useQueryClient();

		// Article
		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/articles/${id}.json`);

				return res.data;
			} catch (error) {
				Toast.error(error);
				return defaultData || null;
			}
		}, [id, defaultData]);
		const queryKey = [id && `fortnox_article-${id}`].filter(Boolean);
		const { data: article = null, isFetching }: UseQueryResult<FortnoxArticleType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.data || defaultData || null,
			enabled: !!id,
		});

		useEffect(() => {
			if (article) setForm(article);
		}, [article]);

		const saveFunction = useCallback(
			async (article: FortnoxArticleType) => {
				const articleId = article.ArticleNumber || id;
				if (articleId) {
					article.ArticleNumber = articleId;
				}

				const endpoint = !isNew ? `/api/fortnox/articles/${articleId}.json` : `/api/fortnox/articles.json`;

				const res = await (!isNew ? API.put(endpoint, { ...article }) : API.post(endpoint, { ...article }));

				const successMessage = !isNew
					? t("fortnox.responses.article_saved", "Articlen uppdaterad")
					: t("fortnox.responses.article_created", "Articlen skapad");

				Toast.success(successMessage);

				if (isNew) {
					history.replace(`/admin/fortnox/articles/${res.data.ArticleNumber}`, {
						data: res.data,
					});
				}

				return res;
			},
			[id, t, history, isNew]
		);

		const update = async ({ article }) => {
			const response = await saveFunction(article);
			return response?.data;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			},
		});

		const handleSave = useCallback(
			// async (document: Partial<ScriveDocumentType>, send = false) => {
			async (article: FortnoxArticleType) => {
				try {
					if (!article) {
						throw new Error("no article");
					}

					setIsSaving(true);
					return await mutation.mutateAsync({ article });
				} catch (e: any) {
					if (!e?.response) throw e;
					Toast.error(e);
				} finally {
					setIsSaving(false);
				}
			},
			[mutation]
		);

		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				t,
				id,
				handleSave,
				article,
				isSaving,
				isNew,
			}),
			[isFetching, t, id, form, handleSave, setForm, article, isSaving, isNew]
		);

		return useMemo(() => <FortnoxArticleContext.Provider value={value}>{children}</FortnoxArticleContext.Provider>, [value, children]);
	}
);
export default FortnoxArticleContext;
