import { Text } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import Colors, { colorToRgba } from "../../../Colors";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { LightBulb } from "../../../icons";
import { store } from "../../../store";

class SheetWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
		this.headerRef = React.createRef();
	}

	componentDidMount() {
		if (this.ref.current) {
			this.ref.current.closest(".Polaris-Sheet__Container").classList.add("sheet-medium");
		}
	}

	render() {
		const scrollHeight = (() => {
			if (!this.ref.current && !this.headerRef.current) return "unset";
			const h =
				(this.ref &&
					this.ref.current &&
					this.ref.current.closest(".Polaris-Sheet__Container") &&
					this.ref.current.closest(".Polaris-Sheet__Container").getBoundingClientRect().height) ||
				0;
			// const h2 = (headerRef && headerRef.current && headerRef.current.getBoundingClientRect().height) || 0;

			return h - 20;
		})();
		const { children, text, title, icon } = this.props;
		return (
			<StyledSheetWrapper ref={this.ref}>
				<MeHeader />
				<ScrollWrapper scrollHeight={scrollHeight}>
					<StyledSecondHeader>
						<LightBulbWrapper>{icon || <LightBulb color={Colors.green} />}</LightBulbWrapper>
						<TextSecondHeader>
							<Text variant="headingMd" as="h2">
								{title}
							</Text>
							<p>{text}</p>
						</TextSecondHeader>
					</StyledSecondHeader>
					{children}
				</ScrollWrapper>
			</StyledSheetWrapper>
		);
	}
}
export default SheetWrapper;

export const MeHeader = React.forwardRef(({ button } = {}, ref) => (
	<MeHeaderWrapper ref={ref}>
		<MeHeaderProfile>
			<ProfileAvatar user={store.getState().user} />
			<MeHeaderContent>
				<MeHeaderName>{store.getState().user.name}</MeHeaderName>
				<MeHeaderEmail>{store.getState().user.email}</MeHeaderEmail>
			</MeHeaderContent>
		</MeHeaderProfile>
		{button}
	</MeHeaderWrapper>
));

const MeHeaderWrapper = styled.div`
	display: flex;
	gap: 0.625rem;
	align-items: center;
	justify-content: space-between;
`;
const MeHeaderProfile = styled.div`
	display: flex;
	gap: 0.625rem;
	align-items: center;
`;
const MeHeaderContent = styled.div`
	display: flex;
	flex-direction: column;
`;
const MeHeaderName = styled.h1`
	font-weight: 500;
`;
const MeHeaderEmail = styled.span`
	opacity: 0.5;
`;

const StyledSecondHeader = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1.875rem;
	margin-bottom: 1.875rem;
`;

const TextSecondHeader = styled.div`
	h2 {
		margin-bottom: 0.625rem;
	}
`;

const LightBulbWrapper = styled.div`
	height: 65px;
	min-height: 65px;
	width: 65px;
	min-width: 65px;
	border-radius: 100px;
	background-color: ${colorToRgba(Colors.green, 0.2)};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.625rem;

	svg {
	}
`;

const StyledSheetWrapper = styled.div`
	padding: 1.875rem;
`;
const ScrollWrapper = styled.div`
	overflow: auto;
	height: ${({ scrollHeight }) => scrollHeight}px;
	padding: 1px;
`;
