/* eslint-disable react/static-property-placement */
import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import SellfinityLogo from "./icons/SellfinityLogo.js";
import Colors from "./Colors.js";

type SplashLoadingScreenProps = { show?: boolean; fadeOutDuration?: number; onClose?: () => void };
type SplashLoadingScreenState = any;

class SplashLoadingScreen extends Component<SplashLoadingScreenProps, SplashLoadingScreenState> {
	static defaultProps: { show: boolean };

	loader: any;

	constructor(props: SplashLoadingScreenProps) {
		super(props);
		this.state = {};
		this.loader = (
			<Loader>
				<LogoCenterWrapper>
					<SellfinityLogo />
				</LogoCenterWrapper>
				<CircleLoader viewBox="25 25 50 50">
					<Circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke={Colors.accent} strokeWidth="3" />
				</CircleLoader>
			</Loader>
		);
	}

	render() {
		return (
			<BackgroundWrapper
				show={this.props.show}
				fadeOutDuration={this.props.fadeOutDuration}
				onTransitionEnd={(e) => {
					if (e.propertyName === "opacity" && e.type === "transitionend" && !this.props.show) {
						if (this.props.onClose) this.props.onClose();
					}
				}}
			>
				<Wrapper>
					{this.loader} {this.props.children}
				</Wrapper>
			</BackgroundWrapper>
		);
	}
}
export default SplashLoadingScreen;

SplashLoadingScreen.defaultProps = {
	show: true,
};

const rotate = keyframes`
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
`;
const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`;

const color = keyframes`
 0% {
    stroke: ${Colors.accent};
  }
  40% {
    stroke: ${Colors.accent};
  }
  66% {
    stroke: ${Colors.accent};
  }
  80%, 90% {
    stroke: ${Colors.accent};
  }
`;

const Loader = styled.div`
	position: relative;
	margin: 0px auto;
	width: 200px;
	height: 200px;

	&::before {
		content: "";
		display: block;
		padding-top: 100%;
	}
`;

const Wrapper = styled.div`
	left: 50% !important;
	position: fixed !important;
	top: 50% !important;
	z-index: 9999999 !important;
	transform: translate(-50%, -50%);
`;

type BackgroundWrapperProps = {
	show?: boolean | undefined;
	fadeOutDuration?: number;
};

const BackgroundWrapper = styled.div.attrs<BackgroundWrapperProps>({ className: "splash-loading-screen" })`
	position: fixed;
	inset: 0;
	/* background-color: #082945; */
	background-image: url(${process.env.PUBLIC_URL}/assets/sellfinity/login_background.png);
	background-size: cover;
	z-index: 9999999 !important;
	opacity: 1;
	transition-property: opacity;
	transition-duration: ${({ fadeOutDuration = 0.5 }: BackgroundWrapperProps) => fadeOutDuration}ms;

	${Wrapper} {
		opacity: 1;
		transition-property: opacity;
		transition-duration: ${({ fadeOutDuration = 0.5 }: BackgroundWrapperProps) => fadeOutDuration / 2}ms;

		${(props: BackgroundWrapperProps) =>
			!props.show &&
			css`
				opacity: 0;
			`}
	}

	${(props: BackgroundWrapperProps) =>
		!props.show &&
		css`
			opacity: 0;
			pointer-events: none;
		`}
`;

const CircleLoader = styled.svg`
	-webkit-animation: ${rotate} 4s linear infinite;
	animation: ${rotate} 4s linear infinite;
	height: 100%;
	-webkit-transform-origin: center center;
	-ms-transform-origin: center center;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
`;

const Circle = styled.circle`
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	-webkit-animation: ${dash} 2.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
	animation: ${dash} 2.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
	stroke-linecap: round;
`;

const LogoCenterWrapper = styled.div`
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
