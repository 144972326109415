import React from "react";
import styled from "styled-components";

const HeartBeat = ({ active = false, color = "#e46a76" }) => {
	if (!active) return null;

	return (
		<HeartBeatWrapper>
			<HeartBeatBit color={color} />
			<HeartBeatIndicator color={color} />
		</HeartBeatWrapper>
	);
};

export default HeartBeat;

const HeartBeatBit = styled.div<{ color?: string }>`
	position: absolute;
	top: -11px;
	right: -11px;
	height: 24px;
	width: 24px;
	z-index: 10;
	border: ${(props) => `5px solid ${props.color}`};
	border-radius: 70px;
	animation: heartbit 1s ease-out;
	animation-iteration-count: infinite;
	-moz-animation: heartbit 1s ease-out;
	-moz-animation-iteration-count: infinite;
	-o-animation: heartbit 1s ease-out;
	-o-animation-iteration-count: infinite;
	-webkit-animation: heartbit 1s ease-out;
	-webkit-animation-iteration-count: infinite;
`;
const HeartBeatIndicator = styled.div<{ color?: string }>`
	width: 6px;
	height: 6px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background-color: ${(props) => props.color};
	position: absolute;
	right: -2px;
	top: -2px;
`;

const HeartBeatWrapper = styled.div`
	position: relative;
`;
