import { Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Select from "src/js/components/select/Select";
import getTermsOfPaymentOptions from "../Utilities/getTermsOfPaymentOptions";

type FortnoxOfferInfoProps = {
	offer: Partial<FortnoxOfferType>;
	onChange: (field: FortnoxOfferTypeMutableKeys) => (value: string | boolean) => void;
	isSaving?: boolean;
} & WithTranslation;

const FortnoxOfferInfo = ({ offer, onChange, t, isSaving }: FortnoxOfferInfoProps) => {
	const disabled = !!offer?.OrderReference || isSaving || offer?.Cancelled;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourReference.label", "Er referens")}
						value={offer.YourReference}
						onChange={onChange("YourReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.OurReference.label", "Vår referens")}
						value={offer.OurReference}
						onChange={onChange("OurReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.OfferDate.label", "Offertdatum")}
						value={offer.OfferDate}
						onChange={onChange("OfferDate")}
						type="date"
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.ExpireDate.label", "Giltig till")}
						value={offer.ExpireDate}
						onChange={onChange("ExpireDate")}
						type="date"
					/>{" "}
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourReferenceNumber.label1", "Ert referensnummer")}
						value={offer.YourReferenceNumber}
						onChange={onChange("YourReferenceNumber")}
					/>
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<Select
						label={t("fortnox.fields.TermsOfPayment.label", "Betalningsvillkor")}
						options={getTermsOfPaymentOptions()}
						value={offer.TermsOfPayment}
						onChange={onChange("TermsOfPayment")}
					/>
					<Labelled id="VATIncluded" label={t("fortnox.fields.VATIncluded.label", "Priser inkl. moms")}>
						<Checkbox
							label={t("fortnox.fields.VATIncluded.label2", "Priser inkl. moms")}
							checked={offer.VATIncluded}
							onChange={onChange("VATIncluded")}
						/>
					</Labelled>
					<TextField
						disabled={disabled}
						label={t("fortnox.offer.fields.Remarks.label", "Offertext")}
						value={offer.Remarks}
						onChange={onChange("Remarks")}
						multiline={3}
						helpText={
							<Checkbox
								label={t("fortnox.offer.fields.CopyRemarks.label", "Använd som order/fakturatext")}
								checked={offer.CopyRemarks}
								onChange={onChange("CopyRemarks")}
							/>
						}
					/>
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOfferInfo);
