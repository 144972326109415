import { store } from "../store";
import { RemoveCookie } from "../Utilities";

const clearLogin = () => {
	console.debug("clearLogin");

	RemoveCookie("JWTTOKEN");
	try {
		store.dispatch({ type: "CLEAR_ME" });
		store.dispatch({ type: "REMOVE_TOKEN" });
		store.dispatch({ type: "REMOVE_REFRESH_TOKEN" });
	} catch (e) {
		console.error("Error", e);
	}

	localStorage.removeItem("refresh_token");
	sessionStorage.removeItem("refresh_token");

	// deprecated
	localStorage.removeItem("accountId");
	sessionStorage.removeItem("accountId");
	localStorage.removeItem("userToken");
	sessionStorage.removeItem("userToken");
	localStorage.removeItem("jwttoken");
	localStorage.removeItem("token");
	sessionStorage.removeItem("jwttoken");
	sessionStorage.removeItem("token");
	localStorage.removeItem("jwt_token");
	sessionStorage.removeItem("jwt_token");
	return true;
};
export default clearLogin;
