/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Button } from "@shopify/polaris";
import Modal from "../../../components/modal";
import Section from "../Section";

const Modals = () => {
	const [opened, setOpened] = useState({});
	const props = [
		{
			sectionTitle: "With Modal.section",
			title: "With Modal.section",
			onClose: () => {
				handleClose(0);
			},
		},
		{
			sectionTitle: "With Modal.section and buttons",
			title: "With Modal.section and buttons",
			onClose: () => {
				handleClose(0);
			},
			primaryAction: {
				content: "Spara",
				onAction: () => {},
			},
			secondaryActions: [
				{
					content: "Avbryt",
					onAction: () => {
						handleClose(1);
					},
				},
				{
					content: "Loading",
					loading: true,
					onAction: () => {
						handleClose(1);
					},
				},
				{
					content: "Disabled",
					disabled: true,
					onAction: () => {
						handleClose(1);
					},
				},
			],
		},
	];

	const handleOpen = (index) => {
		setOpened((c) => ({ ...c, [index]: { ...(c?.[index] || {}), open: true } }));
	};
	const handleClose = (index) => {
		setOpened((c) => ({ ...c, [index]: { ...(c?.[index] || {}), open: false } }));
	};

	return (
		<>
			{/* <TextField value={String(templateId)} onChange={handleChangeTemplateId} placeholder="Template ID" /> */}
			{props.map(({ sectionTitle, ...rest }, index) => {
				return (
					<Section enableRefresh key={index} title={sectionTitle} props={rest}>
						<Button
							onClick={() => {
								handleOpen(index);
							}}
						>
							Open
						</Button>
						<Modal open={opened?.[index]?.open} {...rest}>
							<Modal.Section>
								<p>
									Irure consectetur sint dolor irure ex. Et non cillum commodo quis excepteur in anim dolor. Laboris exercitation fugiat labore
									ullamco pariatur. Culpa pariatur laboris aliqua sint duis veniam dolore velit nisi aliquip ullamco ipsum. Ex enim et sit aliqua ut
									qui ea. Sit excepteur nostrud excepteur pariatur in dolor in veniam. Lorem id et eiusmod elit laborum consectetur occaecat
									consectetur reprehenderit incididunt non officia. Incididunt voluptate enim amet pariatur laborum ut aliquip in.
								</p>
							</Modal.Section>
						</Modal>
					</Section>
				);
			})}
			<Section
				enableRefresh
				title="Modal with <Modal.Section />"
				props={{ title: "Modal with <Modal.Section />", body: "<Modal.Section>{children}</Modal.Section>" }}
			>
				<Button
					onClick={() => {
						handleOpen("sectioned");
					}}
				>
					Open
				</Button>
				<Modal open={opened?.sectioned?.open} title="Modal with <Modal.Section />" onClose={() => handleClose("sectioned")}>
					<p>
						Irure consectetur sint dolor irure ex. Et non cillum commodo quis excepteur in anim dolor. Laboris exercitation fugiat labore ullamco
						pariatur. Culpa pariatur laboris aliqua sint duis veniam dolore velit nisi aliquip ullamco ipsum. Ex enim et sit aliqua ut qui ea. Sit
						excepteur nostrud excepteur pariatur in dolor in veniam. Lorem id et eiusmod elit laborum consectetur occaecat consectetur reprehenderit
						incididunt non officia. Incididunt voluptate enim amet pariatur laborum ut aliquip in.
					</p>
				</Modal>
			</Section>
		</>
	);
};

export default Modals;
