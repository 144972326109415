import { ActionList, Icon, Popover } from "@shopify/polaris";
import { SelectMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";

class CalendarSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { icon, label, items, value, onChange } = this.props;
		const selected = items.find((option) => option.value == value);
		const valueLabel = (selected && selected.label) || "ingen vald";
		return (
			<Popover
				active={this.state.open}
				activator={
					<div
						onClick={() => this.setState((c) => ({ open: !c.open }))}
						className="calendar_modal__reminder_button"
						style={Object.assign({ display: "flex", gap: "0.6250rem", cursor: "pointer" }, this.props.style || {})}
					>
						{!icon || React.isValidElement(icon) ? icon : <Icon source={icon} />}
						{label && <div>{label}</div>}
						<div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
							{valueLabel && <div>{valueLabel}</div>}
							<Icon source={SelectMinor} />
						</div>
					</div>
				}
				onClose={() => this.setState({ open: false })}
			>
				<ActionList
					items={items.map((i) => ({
						content: i.label,
						onAction: () => onChange(i.value),
					}))}
					onActionAnyItem={() => this.setState({ open: false })}
				/>
			</Popover>
		);
	}
}

export default CalendarSelect;
