import React from "react";

const FavoriteBannerIcon = ({ color = "var(--textColor)", size = 20 }) => (
	<svg width="17" height="20" viewBox="0 0 17 20" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 2.27187C0 1.01715 1.01715 0 2.27187 0H14.3885C15.6432 0 16.6604 1.01715 16.6604 2.27187V18.1139C16.6604 19.3839 15.1913 20.0899 14.1996 19.2966L8.33018 14.601L2.46073 19.2966C1.46904 20.0899 0 19.3839 0 18.1139V2.27187ZM2.27187 1.51458C1.85363 1.51458 1.51458 1.85363 1.51458 2.27187V18.1139L8.33018 12.6614L15.1458 18.1139V2.27187C15.1458 1.85363 14.8067 1.51458 14.3885 1.51458H2.27187Z"
		/>
	</svg>
);
export default FavoriteBannerIcon;
