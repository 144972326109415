import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const StyledComboBoxWrapper = styled.div`
	/* height: ${({ height }) => height || "225px"}; */
	overflow-y: auto;
`;

export const StyledComboBoxTagsWrapper = styled.div`
	display: flex;
	gap: 0.6250rem;
	margin-block: 0.6250rem;
`;
