import React, { Component } from "react";
import { Button } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import RTE from "../../components/rte.js";
import Toast from "src/js/components/Toast";

class WidgetHtml extends Component {
	constructor(props) {
		super(props);
		this.state = { html: props.widget.options.html };
	}

	saveHtml() {
		this.props.widget.options = { html: this.state.html };
		this.setState({ saving: true });
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
				this.setState({ saving: false }, this.props.onCloseSettings);
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		if (this.props.editing) {
			return (
				<div style={{ height: "100%" }} className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--noWrap Polaris-Stack--spacingNone">
					<div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
						<RTE
							skipWordCount
							fullHeight
							value={this.state.html}
							onChange={(html) => {
								this.setState({ html });
							}}
						/>
					</div>
					<div>
						<Button loading={this.state.saving} primary onClick={this.saveHtml.bind(this)}>
							{this.props.t("common.actions.save", "Spara")}
						</Button>
					</div>
				</div>
			);
		}
		return (
			<div
				className="rte"
				style={{
					height: "100%",
					width: "100%",
					overflow: "auto",
					paddingLeft: 10,
					paddingRight: 10,
				}}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{ __html: this.state.html }}
			/>
		);
	}
}
export default withTranslation(["widget", "common"], { withRef: true })(WidgetHtml);
