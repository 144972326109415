import React, { Component } from "react";
import { FormLayout, TextField, LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Upload from "../../components/Upload";
import { H2 } from "./AdCreate";

class AdDesigner extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getMetafieldValue(key) {
		if (key in this.props.metafields) {
			return this.props.metafields[key];
		}
		return null;
	}

	updateMetafield(key, value) {
		this.props.onUpdateMetafields(key, value);
	}

	getLocation(href) {
		const l = document.createElement("a");
		l.href = href;
		return l;
	}

	getHost(url) {
		const l = this.getLocation(url);

		return l.hostname;
	}

	getMetafieldError(field) {
		if (!this.props.invalidMetaFields) return "";
		return this.props.invalidMetaFields[field];
	}

	render() {
		if (this.props.ad_account.type == "adwords") {
			if (this.props.type == "search") {
				return (
					<FormLayout>
						<H2>{this.props.t("ad_campaign.type.search", "Sökannons")}</H2>
						<TextField
							disabled={this.props.disabled}
							label={this.props.t("ad_design.fields.keyword.label", "Sökord")}
							value={this.props.keyword}
							onChange={this.props.onUpdateKeyword}
						/>
						<TextField
							label={this.props.t("ad_design.fields.headline1.label", "Rubrikrad 1")}
							value={this.getMetafieldValue("headline1")}
							onChange={this.updateMetafield.bind(this, "headline1")}
							maxLength={30}
							showCharacterCount
							required
							error={this.getMetafieldError("headline1")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.headline2.label", "Rubrikrad 2")}
							value={this.getMetafieldValue("headline2")}
							onChange={this.updateMetafield.bind(this, "headline2")}
							maxLength={30}
							showCharacterCount
							error={this.getMetafieldError("headline2")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.headline3.label", "Rubrikrad 3")}
							value={this.getMetafieldValue("headline3")}
							onChange={this.updateMetafield.bind(this, "headline3")}
							maxLength={30}
							helpText={this.props.t("ad_design.fields.headline3.help_text", "Rubrikrad 3 syns inte på alla enheter")}
							showCharacterCount
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.description1.label", "Beskrivningsrad 1")}
							value={this.getMetafieldValue("description1")}
							onChange={this.updateMetafield.bind(this, "description1")}
							maxLength={90}
							showCharacterCount
							error={this.getMetafieldError("description1")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.description2.label", "Beskrivningsrad 2")}
							value={this.getMetafieldValue("description2")}
							onChange={this.updateMetafield.bind(this, "description2")}
							maxLength={90}
							helpText={this.props.t("ad_design.fields.description2.help_text", "Beskrivningsrad 2 syns inte på alla enheter")}
							showCharacterCount
							disabled={this.props.disabled}
						/>
						<LegacyStack distribution="fill">
							<div>{this.getHost(this.props.url)}</div>
							<span>/</span>
							<TextField
								label={this.props.t("ad_design.fields.address1.label", "Address 1")}
								value={this.getMetafieldValue("path1")}
								onChange={this.updateMetafield.bind(this, "path1")}
								maxLength={15}
								showCharacterCount
								disabled={this.props.disabled}
							/>
							<span>/</span>
							<TextField
								label={this.props.t("ad_design.fields.address2.label", "Address 1")}
								value={this.getMetafieldValue("path2")}
								onChange={this.updateMetafield.bind(this, "path2")}
								maxLength={15}
								showCharacterCount
								disabled={this.props.disabled}
							/>
						</LegacyStack>
					</FormLayout>
				);
			}

			if (this.props.type == "display") {
				return (
					<FormLayout>
						<H2>{this.props.t("ad_design.types.display.title", "Display annons")}</H2>

						<Upload
							label="Bild (600x315)"
							helpText="Bör vara 600x315"
							files={this.getMetafieldValue("image") ? [this.getMetafieldValue("image")] : []}
							width={570}
							onUpload={(upload) => {
								this.updateMetafield("image", upload);
							}}
							removeFile={() => {
								this.updateMetafield("image", null);
							}}
							showFiles
							error={this.getMetafieldError("image")}
							disabled={this.props.disabled}
						/>
						<Upload
							label="Rektangulär bild (300x300)"
							helpText="Bör vara 300x300"
							files={this.getMetafieldValue("square_image") ? [this.getMetafieldValue("square_image")] : []}
							width={570}
							onUpload={(upload) => {
								this.updateMetafield("square_image", upload);
							}}
							removeFile={() => {
								this.updateMetafield("square_image", null);
							}}
							showFiles
							error={this.getMetafieldError("square_image")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.headline.label", "Rubrikrad")}
							value={this.getMetafieldValue("headline")}
							onChange={this.updateMetafield.bind(this, "headline")}
							maxLength={30}
							helpText={this.props.t("ad_design.fields.headline.help_text", "Kort sammanfattande annonstext, visas på mindre enheter")}
							showCharacterCount
							error={this.getMetafieldError("headline")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.long_headline.label", "Lång rubriksrad")}
							value={this.getMetafieldValue("long_headline")}
							onChange={this.updateMetafield.bind(this, "long_headline")}
							helpText={this.props.t("ad_design.fields.long_headline.help_text", "Visas i större format, annars visas vanliga rubrikraden")}
							maxLength={90}
							showCharacterCount
							error={this.getMetafieldError("long_headline")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.description.label", "Beskrivning")}
							value={this.getMetafieldValue("description")}
							onChange={this.updateMetafield.bind(this, "description")}
							maxLength={90}
							helpText={this.props.t("ad_design.fields.description.help_text", "Kan kortas ner på mindre enheter")}
							showCharacterCount
							error={this.getMetafieldError("description")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.business_name.label", "Företagsnamn")}
							value={this.getMetafieldValue("business_name")}
							onChange={this.updateMetafield.bind(this, "business_name")}
							maxLength={25}
							helpText={this.props.t("ad_design.fields.business_name.help_text", "Företagsnamnet syns inte på alla annonser")}
							showCharacterCount
							error={this.getMetafieldError("business_name")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.call_to_action.label", "Call to action")}
							value={this.getMetafieldValue("call_to_action")}
							onChange={this.updateMetafield.bind(this, "call_to_action")}
							maxLength={10}
							helpText={this.props.t("ad_design.fields.call_to_action.help_text", "Text på knappen")}
							showCharacterCount
							error={this.getMetafieldError("call_to_action")}
							disabled={this.props.disabled}
						/>
					</FormLayout>
				);
			}

			if (this.props.type == "video") {
				return (
					<FormLayout>
						<H2>{this.props.t("ad_design.types.video.title", "Videoannons")}</H2>

						<TextField
							label={this.props.t("ad_design.fields.youtube_video_id.label", "Youtube Video ID")}
							value={this.getMetafieldValue("youtube_video_id")}
							onChange={this.updateMetafield.bind(this, "youtube_video_id")}
							error={this.getMetafieldError("youtube_video_id")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.headline", "Rubrikrad")}
							value={this.getMetafieldValue("headline")}
							onChange={this.updateMetafield.bind(this, "headline")}
							maxLength={30}
							helpText="Kort sammanfattande annonstext, visas på mindre enheter"
							showCharacterCount
							error={this.getMetafieldError("headline")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.long_headline.label", "Lång rubriksrad")}
							value={this.getMetafieldValue("long_headline")}
							onChange={this.updateMetafield.bind(this, "long_headline")}
							helpText={this.props.t("ad_design.fields.long_headline.help_text", "Visas i större format, annars visas vanliga rubrikraden")}
							maxLength={90}
							showCharacterCount
							error={this.getMetafieldError("long_headline")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.description.label", "Beskrivning")}
							value={this.getMetafieldValue("description")}
							onChange={this.updateMetafield.bind(this, "description")}
							maxLength={90}
							helpText={this.props.t("ad_design.fields.description.help_text", "Kan kortas ner på mindre enheter")}
							showCharacterCount
							error={this.getMetafieldError("description")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.business_name.label", "Företagsnamn")}
							value={this.getMetafieldValue("business_name")}
							onChange={this.updateMetafield.bind(this, "business_name")}
							maxLength={25}
							helpText={this.props.t("ad_design.fields.business_name.help_text", "Företagsnamnet syns inte på alla annonser")}
							showCharacterCount
							error={this.getMetafieldError("business_name")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.call_to_action.label", "Call to action")}
							value={this.getMetafieldValue("call_to_action")}
							onChange={this.updateMetafield.bind(this, "call_to_action")}
							maxLength={10}
							helpText={this.props.t("ad_design.fields.call_to_action.help_text", "Text på knappen")}
							showCharacterCount
							error={this.getMetafieldError("call_to_action")}
							disabled={this.props.disabled}
						/>
					</FormLayout>
				);
			}
		} else if (this.props.ad_account.type == "facebook") {
			if (this.props.type == "display") {
				return (
					<FormLayout>
						<H2>{this.props.t("ad_design.types.facebook.title", "Annons")}</H2>

						<Upload
							label={this.props.t("ad_design.fields.video.label", "Video")}
							files={this.getMetafieldValue("video") ? [this.getMetafieldValue("video")] : []}
							// width={"max-content"}
							showFiles
							width={570}
							onUpload={(upload) => {
								this.updateMetafield("video", upload);
							}}
							removeFile={() => {
								this.updateMetafield("video", null);
							}}
							disabled={this.props.disabled}
						/>
						<Upload
							label={this.props.t("ad_design.fields.image.label", "Bild")}
							files={this.getMetafieldValue("image") ? [this.getMetafieldValue("image")] : []}
							// width={"max-content"}
							showFiles
							width={570}
							onUpload={(upload) => {
								this.updateMetafield("image", upload);
							}}
							removeFile={() => {
								this.updateMetafield("image", null);
							}}
							error={this.getMetafieldError("image")}
							disabled={this.props.disabled}
						/>

						{/* <div>
							<Upload
								label="Video"
								files={this.getMetafieldValue("video") ? [{ url: this.getMetafieldValue("video") }] : []}
								// width={"max-content"}
								showFiles
								open={this.state.openVideoDialog}
								height={"auto"}
								onUpload={(upload) => {
									this.updateMetafield("video", upload.url);
								}}
								onClose={() => {
									this.setState({ openVideoDialog: false });
								}}
								removeFile={() => {
									this.updateMetafield("video", null);
								}}
							/>
							<Button
								onClick={() => {
									this.setState({ openVideoDialog: true });
								}}
							>
								Ladda upp video
							</Button>
						</div>
						<div>
							<Upload
								label="Bild"
								files={this.getMetafieldValue("image") ? [{ url: this.getMetafieldValue("image") }] : []}
								// width={"max-content"}
								showFiles
								open={this.state.openImageDialog}
								height={"auto"}
								onUpload={(upload) => {
									this.updateMetafield("image", upload.url);
								}}
								onClose={() => {
									this.setState({ openImageDialog: false });
								}}
								removeFile={() => {
									this.updateMetafield("image", null);
								}}
							/>
							<Button
								onClick={() => {
									this.setState({ openImageDialog: true });
								}}
							>
								Ladda upp Bild
							</Button>
						</div> */}
						{/* <Upload
							label="Bild"
							helpText="Bör vara 600x315"
							files={this.getMetafieldValue("image") ? [{ url: this.getMetafieldValue("image") }] : []}
							width={570}
							height={315}
							onUpload={(upload) => {
								this.updateMetafield("image", upload.url);
							}}
						/> */}
						<TextField
							label={this.props.t("ad_design.fields.primary_text.label", "Primär text")}
							value={this.getMetafieldValue("primary_text")}
							onChange={this.updateMetafield.bind(this, "primary_text")}
							showCharacterCount
							error={this.getMetafieldError("primary_text")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.title.label", "Rubrikrad")}
							value={this.getMetafieldValue("title")}
							onChange={this.updateMetafield.bind(this, "title")}
							showCharacterCount
							error={this.getMetafieldError("title")}
							disabled={this.props.disabled}
						/>
						<TextField
							label={this.props.t("ad_design.fields.description.label", "Beskrivning")}
							value={this.getMetafieldValue("description")}
							onChange={this.updateMetafield.bind(this, "description")}
							showCharacterCount
							error={this.getMetafieldError("description")}
							disabled={this.props.disabled}
						/>
					</FormLayout>
				);
			}
		}

		return <div />;
	}
}

export default withTranslation(["ad_design", "common"], { withRef: true })(AdDesigner);
