import React from "react";

const TagIcon = ({ color = "var(--textColor)" }) => (
	<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.3472 1.12174C19.6572 0.431737 18.6989 0.0100708 17.6447 0.0100708H4.22805C2.11971 0.0100708 0.394714 1.73507 0.394714 3.8434V17.2601C0.394714 18.3142 0.816381 19.2726 1.52555 19.9817L18.7755 37.2317C19.4655 37.9217 20.4239 38.3434 21.478 38.3434C22.5322 38.3434 23.4905 37.9217 24.1805 37.2126L37.5972 23.7959C38.3064 23.1059 38.728 22.1476 38.728 21.0934C38.728 20.0392 38.2872 19.0617 37.5972 18.3717L20.3472 1.12174ZM21.478 34.5292L4.22805 17.2601V3.8434H17.6447V3.82424L34.8947 21.0742L21.478 34.5292V34.5292Z"
			fill={color}
		/>
	</svg>
);

export default TagIcon;
