import React from "react";
import ReactSwitch from "react-switch";
import Colors from "../Colors";
import { Labelled } from "@shopify/polaris";

// eslint-disable-next-line react/jsx-props-no-spreading
const Switch = (props) => {
	const content = <ReactSwitch height={20} width={50} uncheckedIcon={null} checkedIcon={null} onColor={Colors.accent} {...props} />;

	if (props.label)
		return (
			<Labelled id={props.id} label={props.label}>
				{content}
			</Labelled>
		);

	return content;
};
export default Switch;
