import React, { Component } from "react";
import { Icon, LegacyStack, Button, ActionList } from "@shopify/polaris";
import { CirclePlusMinor, CircleCancelMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Toast from "src/js/components/Toast";

import BoardHelper from "./BoardHelper";
import MyPopover from "../../components/Popover";
import CellDropdownOption from "./CellDropdownOption.js";

class CellDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = { query: "", value: props.value || [], editingIndex: null };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			this.setState({ value: props.value || [] });
		}
	}

	addOption(index) {
		if (this.state.value.indexOf(index) < 0) {
			this.state.value.push(index);
			this.props.onChange({ value: this.state.value });
			this.setState({ value: this.state.value });
		}
		this.setState({ active: false }, this.props.onBlur);
	}

	removeOption(idx) {
		const index = this.state.value.indexOf(idx);
		if (index >= 0) {
			this.state.value.splice(index, 1);
			this.setState({ value: this.state.value });
			this.props.onChange({ value: this.state.value });
		}
	}

	createOption() {
		if (!this.state.query || this.state.saving) {
			return;
		}
		this.state.value.push(this.props.column.options.choices.length);
		this.props.column.options.choices.push(this.state.query);
		this.setState({ saving: true, query: "" });
		API.put(
			"/api/boards/" + this.props.column.board_id + "/columns/" + this.props.column.id + ".json",
			{ options: this.props.column.options },
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				this.props.onUpdateColumn(result.data.column);
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	getOption(index) {
		return this.props.column.options.choices[index];
	}

	removeChoiceOption(index, e) {
		e.preventDefault();
		e.stopPropagation();

		if (!this.props.column.options.removed_choices || !this.props.column.options.removed_choices.includes(index)) {
			if (Array.isArray(this.props.column.options.removed_choices)) {
				this.props.column.options.removed_choices.push(index);
			} else {
				this.props.column.options.removed_choices = [index];
			}

			BoardHelper.updateColumnOptions(this.props.column, this.props.column.options);
			if (this.props.onUpdateColumn) this.props.onUpdateColumn(this.props.column);
		}
	}

	getUnusedOptions() {
		const tags = [];
		const filterRegex = new RegExp(this.state.query, "i");

		if (this.props.column.options.choices) {
			this.props.column.options.choices.forEach((choice, i) => {
				if (this.props.column.options.removed_choices && this.props.column.options.removed_choices.includes(i)) return null;
				if (this.state.value.indexOf(choice) < 0 && choice.match(filterRegex)) {
					tags.push({
						content: (
							<CellDropdownOption
								key={i}
								choice={choice}
								index={i}
								editing={i === this.state.editingIndex}
								column={this.props.column}
								onUpdateColumn={this.props.onUpdateColumn}
								removeChoiceOption={this.removeChoiceOption.bind(this, i)}
								// setOneInputFocused={(v) => this.setState({ oneInputFocus: v })}
								onFocus={(v) =>
									this.setState((c) => {
										if (v !== undefined) return { editingIndex: v };
										if (c.editingIndex == i) return { editingIndex: null };
										return { editingIndex: i };
									})
								}
							/>
						),
						onAction: this.addOption.bind(this, i),
					});
				}
			});
		}

		return tags;
	}

	render() {
		const options =
			this.state.value &&
			this.state.value.map((index) => {
				const option = { label: this.getOption(index), index };
				return option;
			});

		const overflowingOptions = 0;
		return (
			<MyPopover
				disabled={this.props.disabled}
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						className={this.state.active ? "expandedCell" : ""}
						style={{
							height: this.state.active ? "auto" : this.props.height,
							minHeight: this.props.height,
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							paddingTop: 5,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										this.input.focus();
									}, 100);
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<LegacyStack spacing="none" wrap={false}>
							{!overflowingOptions && !this.state.active ? (
								<div className="board-display-hover" style={{ paddingRight: 3 }}>
									<Icon source={CirclePlusMinor} color="blue" />
								</div>
							) : null}
							<LegacyStack.Item fill>
								<LegacyStack spacing="extraTight" vertical={this.state.active} distribution="center">
									{options.map((tag, index) => (
										<span key={index} className="Polaris-Badge">
											<span className="Polaris-Badge__Content">{tag.label}</span>
											{this.state.active ? (
												<span className="clear-button-inline" onClick={this.removeOption.bind(this, tag.index)}>
													<Icon source={CircleCancelMinor} />
												</span>
											) : null}
										</span>
									))}
								</LegacyStack>
							</LegacyStack.Item>
							{overflowingOptions ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{overflowingOptions}
								</div>
							) : null}
						</LegacyStack>
					</div>
				}
				onClose={() => {
					// if (typeof this.state.editingIndex !== "number") {
					this.setState({ active: false, editingIndex: null }, this.props.onBlur);
					// }
				}}
			>
				<div style={{ width: 250 }}>
					<input
						ref={(input) => {
							this.input = input;
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								this.createOption();
							} else if (event.key === "Escape") {
								this.setState({ active: false, query: "" }, this.props.onBlur);
							}
						}}
						className="bgInput"
						placeholder={this.props.t("cell.dropdown.placeholder", "Lägg till märke")}
						type="text"
						value={this.state.query}
						onChange={(event) => {
							this.setState({ query: event.target.value });
						}}
					/>
				</div>
				{this.state.active ? (
					<div
						className="cellDropdown-actionlist"
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<ActionList items={this.getUnusedOptions()} />
					</div>
				) : null}
				<div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
					<Button fullWidth loading={this.state.saving} disabled={!this.state.query} primary onClick={this.createOption.bind(this)}>
						{this.props.t("cell.dropdown.action", "Skapa märke")}
					</Button>
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellDropdown);
