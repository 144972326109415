/**
 * Deep merges two objects, replacing values in the original object with values from the new object.
 * @param {Object} original - The original object to be merged into.
 * @param {Object} newObj - The new object to merge from.
 * @returns {Object} - The merged object.
 *
 */

type AnObject = {
	[key: string]: AnObject | any;
};

function deepMergeReplaceKey(original: AnObject, newObj: AnObject) {
	function isObject(item) {
		return item && typeof item === "object" && !Array.isArray(item);
	}

	function merge(target, source) {
		Object.keys(source).forEach((key) => {
			if (isObject(source[key])) {
				if (!target[key]) {
					target[key] = {};
				}
				merge(target[key], source[key]);
			} else {
				target[key] = source[key];
			}
		});
		return target;
	}

	// Create a deep copy of the original object to avoid mutating it
	const mergedObject = JSON.parse(JSON.stringify(original));

	return merge(mergedObject, newObj);
}

export default deepMergeReplaceKey;
