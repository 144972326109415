import React from "react";

class RTEStyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = (e) => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		let icon = this.props.label;
		if (this.props.style == "BOLD") {
			icon = (
				<div style={{ width: 12, height: 12 }}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 3 12 12" enableBackground="new -1 3 12 12">
						<title>rte-bold</title>
						<desc>Created with Sketch.</desc>
						<path d="M9.7 9.8c-.3-.5-.4-.6-.7-.8-.3-.3-.7-.4-1.1-.6-.4-.1-.8-.2-1.1-.2v-.2l1-.3c.3-.2.6-.3.8-.5.3-.2.5-.5.6-.8.2-.3.2-.6.2-1 0-.8-.3-1.4-.9-1.8-.6-.4-1.6-.6-3-.6h-5.5v.7c.2.1.4.1.5.2.2.1.4.2.4.3.1.1.1.3.1.5v7.7c0 .2 0 .4-.1.5-.1.1-.2.2-.4.3-.1.1-.2.1-.4.1h-.1v.7h5.3c.7 0 1.4 0 2-.1.5-.1 1-.3 1.4-.6.4-.2.8-.5 1-.9.2-.3.3-.8.3-1.4 0-.4 0-.7-.3-1.2zm-6.2-6.1h1.3c.6 0 1.1.2 1.4.5.3.4.5.8.5 1.4 0 .7-.2 1.2-.6 1.6-.4.4-.9.6-1.7.6h-.9v-4.1zm3.1 9c-.3.4-.8.6-1.4.6-.3 0-1-.1-1.3-.2-.2-.1-.3-.3-.4-.5v-4h.7c.8 0 1.9.2 2.3.6.5.4.7 1 .7 1.7 0 .8-.2 1.4-.6 1.8z" />
					</svg>
				</div>
			);
		} else if (this.props.style == "ITALIC") {
			icon = (
				<div style={{ width: 12, height: 12 }}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 3 12 12" enableBackground="new -1 3 12 12">
						<title>rte-italic</title>
						<desc>Created with Sketch.</desc>
						<path d="M9 3l-.3 1c-.1 0-.4 0-.6.1-.3 0-.5.1-.6.1-.3.1-.5.2-.6.3l-.2.5-1.7 7v.2c0 .1 0 .2.1.3.1.1.2.2.3.2.1 0 .3.1.5.1.3.2.5.2.6.2l-.2 1h-5.3l.2-1h.7s.5-.1.6-.1c.2-.1.4-.2.5-.3.1-.1.2-.3.2-.5l1.8-7v-.30000000000000004c0-.1 0-.2-.1-.3 0-.1-.1-.1-.3-.2-.1-.1-.3-.1-.6-.2-.2 0-.4-.1-.5-.1l.2-1h5.3z" />
					</svg>
				</div>
			);
		} else if (this.props.style == "UNDERLINE") {
			icon = (
				<div style={{ width: 12, height: 12 }}>
					<svg xmlns="http://www.w3.org/2000/svg" width="10px" height="11px" viewBox="0 0 10 11" version="1.1">
						<title>Artboard 1 Copy 3</title>
						<desc>Created with Sketch.</desc>
						<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g id="Artboard-1-Copy-3" fill="#000000">
								<path
									d="M1.09070796,0.96716515 L0.25,0.802178153 L0.25,0 L4.45353982,0 L4.45353982,0.802178153 L3.36061947,0.972854356 L3.36061947,5.27389467 C3.36061947,6.1841723 3.53414848,6.85644018 3.88121171,7.29071847 C4.22827494,7.72499675 4.74886197,7.94213264 5.44298843,7.94213264 C5.79651867,7.94213264 6.10154172,7.87955199 6.35806671,7.75438882 C6.61459171,7.62922564 6.82584442,7.4519138 6.99183118,7.22244798 C7.15781794,6.99298216 7.27961159,6.7161102 7.35721579,6.3918238 C7.43481999,6.06753739 7.47362151,5.70437999 7.47362151,5.3023407 L7.47362151,0.96716515 L6.3613002,0.802178153 L6.3613002,0 L9.75,0 L9.75,0.802178153 L8.87695711,0.96716515 L8.87695711,5.20562419 C8.87695711,5.84281853 8.78210896,6.38613234 8.5924098,6.83558192 C8.40271065,7.28503151 8.14187822,7.65103348 7.8099047,7.93359883 C7.47793117,8.21616418 7.08452689,8.42286996 6.62968005,8.55372237 C6.17483321,8.68457478 5.68011386,8.75 5.14550715,8.75 C4.46000338,8.75 3.86289113,8.6817302 3.35415248,8.54518856 C2.84541384,8.40864691 2.42398624,8.19340741 2.08985705,7.89946359 C1.75572785,7.60551977 1.50567362,7.2281395 1.33968686,6.76731144 C1.1737001,6.30648339 1.09070796,5.75368766 1.09070796,5.10890767 L1.09070796,0.96716515 Z M8.71353026,11 L9.75,11 L9.75,9.70588235 L8.71353026,9.70588235 L0.901040789,9.70588235 L0.25,9.70588235 L0.25,11 L0.901040789,11 L8.71353026,11 Z"
									id="Combined-Shape"
								/>
							</g>
						</g>
					</svg>
				</div>
			);
		} else if (this.props.style == "ordered-list-item") {
			icon = (
				<div style={{ width: 12, height: 12 }}>
					<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
						<title>rte-list-ordered</title>
						<desc>Created with Sketch.</desc>
						<g fill="#000">
							<rect width="9" height="2" rx="1" transform="translate(3 5)" />
							<path d="M0 2.479h.697v-1.943l-.648.152v-.533l.653-.148h.602v2.472h.697v.521h-2v-.521z" />
							<rect x="3" y="1" width="9" height="2" rx="1" />
							<path d="M.685 6.481h1.35v.512h-2.035v-.496l.343-.335.743-.734c.107-.113.185-.213.232-.3.047-.087.071-.174.071-.259 0-.131-.043-.233-.13-.304-.087-.072-.209-.107-.368-.107-.113 0-.24.021-.381.062-.141.041-.288.101-.441.18v-.541c.153-.051.303-.09.45-.117.147-.027.287-.04.422-.04.339 0 .606.073.8.218.194.145.291.343.291.594 0 .116-.021.224-.063.325-.042.101-.115.213-.218.337-.076.089-.284.29-.625.603l-.44.406z" />
							<rect x="3" y="9" width="9" height="2" rx="1" />
							<path d="M.88 9.646h-.328v-.502h.328c.152 0 .27-.028.354-.084.084-.056.126-.135.126-.237 0-.107-.042-.19-.126-.25-.084-.06-.202-.09-.354-.09-.116 0-.241.014-.374.041-.133.027-.271.066-.413.118v-.518c.143-.04.283-.071.421-.092.138-.021.271-.032.399-.032.327 0 .581.066.764.199.183.133.274.316.274.549 0 .171-.053.311-.158.42-.105.109-.255.18-.45.214.221.036.39.118.506.246.116.128.174.297.174.505 0 .28-.096.493-.289.64-.192.147-.472.22-.839.22-.156 0-.31-.012-.462-.037-.152-.024-.296-.06-.435-.106v-.529c.13.061.271.107.421.138.151.032.309.047.475.047.166 0 .298-.036.394-.107.097-.071.145-.168.145-.289 0-.148-.048-.262-.145-.343-.097-.081-.234-.121-.411-.121z" />
						</g>
					</svg>
				</div>
			);
		} else if (this.props.style == "unordered-list-item") {
			icon = (
				<div style={{ width: 12, height: 12 }}>
					<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
						<title>rte-list</title>
						<desc>Created with Sketch.</desc>
						<g fill="#000">
							<rect width="9" height="2" rx="1" transform="translate(3 5)" />
							<rect x="3" y="1" width="9" height="2" rx="1" />
							<rect x="3" y="9" width="9" height="2" rx="1" />
							<circle cx="1" cy="2" r="1" />
							<circle cx="1" cy="6" r="1" />
							<circle cx="1" cy="10" r="1" />
						</g>
					</svg>
				</div>
			);
		} else if (this.props.style == "LINK") {
			icon = (
				<div style={{ width: 12, height: 12 }}>
					<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20">
						<path
							d="M6.534 18a4.507 4.507 0 0 1-3.208-1.329 4.54 4.54 0 0 1 0-6.414l1.966-1.964a.999.999 0 1 1 1.414 1.414L4.74 11.671a2.54 2.54 0 0 0 0 3.586c.961.959 2.631.958 3.587 0l1.966-1.964a1 1 0 1 1 1.415 1.414l-1.966 1.964A4.503 4.503 0 0 1 6.534 18zm7.467-6a.999.999 0 0 1-.707-1.707l1.966-1.964a2.54 2.54 0 0 0 0-3.586c-.961-.959-2.631-.957-3.587 0L9.707 6.707a1 1 0 1 1-1.415-1.414l1.966-1.964A4.503 4.503 0 0 1 13.466 2c1.211 0 2.351.472 3.208 1.329a4.541 4.541 0 0 1 0 6.414l-1.966 1.964a.997.997 0 0 1-.707.293zm-6.002 1a.999.999 0 0 1-.707-1.707l4.001-4a1 1 0 1 1 1.415 1.414l-4.001 4a1 1 0 0 1-.708.293z"
							fill="#000"
						/>
					</svg>
				</div>
			);
		} else if (this.props.style == "HTML") {
			icon = (
				<div style={{ width: 20, height: 20 }}>
					<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="20" height="20" focusable="false">
						<path d="M9 8.406L5.24 10.01 9 11.6V13l-5-2.334V9.34L9 7v1.406zm2 0V7l5 2.34v1.326L11 13v-1.4l3.76-1.59L11 8.405z" />
					</svg>
				</div>
			);
		} else if (this.props.style == "image") {
			icon = (
				<div style={{ width: 20, height: 20 }}>
					<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="20" height="20" focusable="false">
						<path d="M16 14c0 .553-.456 1-1.002 1H5.003c-.266.002-.52-.103-.71-.29S4 14.265 4.002 14h12zm0-9c0-.55-.456-1-1.002-1H5.003c-.266 0-.52.105-.71.293S4 4.736 4.002 5h12z" />
						<path d="M16 5.003v8.995c0 .266-.104.52-.292.71S15.265 15 15 15V4c.552 0 1 .456 1 1.002zm-12 0v8.995c0 .266.104.52.292.71S4.735 15 5 15V4c-.552 0-1 .456-1 1.002zM9 7.5a1.5 1.5 0 11-2.999.001A1.5 1.5 0 019 7.5z" />
						<path d="M9.463 11.952L8 10.002l-3 4h10V9.8L13 7z" />
					</svg>
				</div>
			);
		}

		/*
    Creates annoying tabindex
    <Tooltip content={this.props.label}>
        {icon}
    </Tooltip>
    */
		return (
			<span
				className={
					"Polaris-Button Polaris-Button--sizeSlim" +
					(this.props.active ? " Polaris-Button--pressed" : "") +
					(this.props.disabled ? " Polaris-Button--disabled" : "")
				}
				onMouseDown={this.onToggle}
			>
				{icon}
			</span>
		);
	}
}

export default RTEStyleButton;
