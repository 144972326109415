import React from "react";
import styled from "styled-components";
import BoardUploadSheet, { StyledDocumentItem } from "../Workspaces/components/BoardUploadSheet";

type FortnoxPDFUploadsListProps = {
	data: any;
	history: any;
	isLoading: boolean;
};

const FortnoxPDFUploadsList = ({ data, history, isLoading }: FortnoxPDFUploadsListProps) => {
	return (
		<Wrapper isLoading={!!isLoading}>
			{data?.files?.map(({ file, values, row, column }, index) => {
				const board = data.boards.find((b) => b.id === row.board_id);

				return (
					<BoardUploadSheet
						// onChange={this.onChange.bind(this, row, this.props.column)}
						file={file}
						values={values}
						key={file ? file.id : index}
						row={row}
						column={column}
						board={board}
						showRow
						history={history}
					/>
				);
			})}
		</Wrapper>
	);
};
export default FortnoxPDFUploadsList;

const Wrapper = styled.div<{ isLoading: boolean }>`
	transition: opacity 0.3s;
	${StyledDocumentItem} {
		background-color: var(--main2);
		width: "100%";
	}

	${(props) => props.isLoading && `opacity: 0.5;`}
`;
