import React from "react";

const DocumentCheckIcon = ({ color = "var(--textColor)" }) => (
	<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.44305 1.43126C2.59543 1.26799 2.80209 1.17627 3.01758 1.17627H7.89258C8.00032 1.17627 8.10365 1.22213 8.17984 1.30377L11.0236 4.35088C11.0998 4.43251 11.1426 4.54323 11.1426 4.65868V9.00069C11.1426 9.2411 10.9607 9.43599 10.7363 9.43599C10.512 9.43599 10.3301 9.2411 10.3301 9.00069V4.83899L7.7243 2.04687L3.01758 2.04687L3.01758 11.6235H7.71174C7.9361 11.6235 8.11799 11.8184 8.11799 12.0588C8.11799 12.2992 7.9361 12.4941 7.71174 12.4941H3.01758C2.80209 12.4941 2.59543 12.4024 2.44305 12.2391C2.29068 12.0759 2.20508 11.8544 2.20508 11.6235V2.04687C2.20508 1.81597 2.29068 1.59453 2.44305 1.43126Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.89258 1.17627C8.11694 1.17627 8.29883 1.37116 8.29883 1.61157V4.22338H10.7363C10.9607 4.22338 11.1426 4.41827 11.1426 4.65868C11.1426 4.89909 10.9607 5.09399 10.7363 5.09399H7.89258C7.66821 5.09399 7.48633 4.89909 7.48633 4.65868V1.61157C7.48633 1.37116 7.66821 1.17627 7.89258 1.17627Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.64258 7.27051C4.64258 7.0301 4.82446 6.83521 5.04883 6.83521H8.29883C8.52319 6.83521 8.70508 7.0301 8.70508 7.27051C8.70508 7.51092 8.52319 7.70581 8.29883 7.70581H5.04883C4.82446 7.70581 4.64258 7.51092 4.64258 7.27051Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.64258 9.01172C4.64258 8.77131 4.82446 8.57642 5.04883 8.57642H8.29883C8.52319 8.57642 8.70508 8.77131 8.70508 9.01172C8.70508 9.25213 8.52319 9.44702 8.29883 9.44702H5.04883C4.82446 9.44702 4.64258 9.25213 4.64258 9.01172Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7499 11.1093C11.8301 11.1994 11.8269 11.342 11.7429 11.4279L10.1999 13.0053C10.1186 13.0884 9.99066 13.0883 9.90943 13.0051L9.13922 12.2165C9.05526 12.1305 9.05224 11.9878 9.13248 11.8979C9.21272 11.8079 9.34584 11.8047 9.4298 11.8906L10.0548 12.5307L11.4526 11.1018C11.5366 11.0159 11.6697 11.0193 11.7499 11.1093Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.4409 9.46239C9.16329 9.46239 8.12762 10.5721 8.12762 11.941C8.12762 13.31 9.16329 14.4197 10.4409 14.4197C11.7184 14.4197 12.7541 13.31 12.7541 11.941C12.7541 10.5721 11.7184 9.46239 10.4409 9.46239ZM7.70703 11.941C7.70703 10.3232 8.93101 9.01172 10.4409 9.01172C11.9507 9.01172 13.1747 10.3232 13.1747 11.941C13.1747 13.5589 11.9507 14.8704 10.4409 14.8704C8.93101 14.8704 7.70703 13.5589 7.70703 11.941Z"
			fill={color}
		/>
	</svg>
);

export default DocumentCheckIcon;
