export default (state = {}, action) => {
	switch (action.type) {
		case "SET_CONTACT_METAFIELDS":
			return action.contact_metafields;
		case "ADD_CONTACT_METAFIELD": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.contact_metafield.id) {
					return newState;
				}
			}
			newState.push(action.contact_metafield);
			return newState;
		}
		case "UPDATE_CONTACT_METAFIELD": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.contact_metafield.id) {
					newState[i] = action.contact_metafield;
				}
			}
			return newState;
		}
		case "REMOVE_CONTACT_METAFIELD": {
			const newState = [];
			for (let i = 0; i < state.length; i++) {
				if (state[i].id != action.id) {
					newState.push(state[i]);
				}
			}
			return newState;
		}
		default:
			return state;
	}
};
