import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Button, ButtonGroup, Modal as PolarisModal } from "@shopify/polaris";
import type { ComplexAction } from "@shopify/polaris";
import { store } from "../../store";
import ConfirmPopup from "./ConfirmPopup";
import ActionButton from "../page/ActionButton";
import { checkUserRoles, getPortalsMaxZIndex } from "src/js/Utilities";
import { createPortal } from "react-dom";
import useMountTransition from "src/js/hooks/useMountTransition";
import { type DefaultTFuncReturn } from "i18next";
import ActionGroup from "../page/ActionGroup";
import { MenuActionDescriptor } from "../page/Page";

const DEFAULT_ZINDEX = 550;
const ANIMATION_IN = 100;
const ANIMATION_OUT = 200;

type SecondaryAction = ComplexAction & {
	confirm?: boolean;
	confirmText?: string | React.ReactNode;

	confirmation?: {
		title?: string | null;
		content?: string | null | React.ReactNode;
		confirm?: string | null;
		cancel?: string | null;
	};

	enabled?: boolean | any;
} & MenuActionDescriptor;

export type PrimaryAction = ComplexAction & {
	content: ComplexAction["content"] | DefaultTFuncReturn;
	enabled?: boolean;
	confirm?: boolean;
	confirmText?: string | React.ReactNode;
};

interface ModalProps {
	open: boolean;
	onClose: () => void;
	title?: any;
	children?: React.ReactNode;
	primaryAction?: PrimaryAction;
	secondaryActions?: SecondaryAction[];
	large?: boolean;
	footer?: React.ReactNode;
	sectioned?: boolean;
	onOverlayClick?: (e: React.MouseEvent<HTMLElement>) => void;
	titleHidden?: boolean;
	activator?: any;
}

interface ModalComponent extends React.FC<ModalProps> {
	// Section: React.FC<{ children?: React.ReactNode }>;
	Section: typeof PolarisModal.Section;
}

const Modal: ModalComponent = ({
	children,
	onClose,
	open,
	title,
	primaryAction,
	secondaryActions: propsSecondaryActions,
	large,
	footer,
	sectioned,
	onOverlayClick,
	titleHidden,
	activator,
}: ModalProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const zIndex = useRef<any>(DEFAULT_ZINDEX);
	const hasTransitionedIn = useMountTransition(open, ANIMATION_OUT);
	const id = useRef<number | undefined>();

	const handleConditionalOnClose = useCallback(
		(e?: any) => {
			e?.stopPropagation();
			e?.preventDefault();

			if ([2, 419, 418].includes(store.getState().user.id)) {
				onClose?.();
			}
		},
		[onClose]
	);

	const onKeyDown = useCallback(
		(event: any) => {
			if (event.key === "Escape") handleConditionalOnClose(event);
		},
		[handleConditionalOnClose]
	);

	const getElementIndex = () => {
		if (!ref.current) return 0;
		return Math.max(Array.from(document.querySelector("#PolarisPortalsContainer")?.children || []).indexOf(ref.current), 0);
	};

	useEffect(() => {
		const dataId = getElementIndex();
		id.current = dataId;
	}, []);

	useEffect(() => {
		if (!open) zIndex.current = DEFAULT_ZINDEX;
		else zIndex.current = Math.max(DEFAULT_ZINDEX, getPortalsMaxZIndex()) + 3;
	}, [open]);

	const secondaryActions = useMemo(
		() =>
			(propsSecondaryActions ? (Array.isArray(propsSecondaryActions) ? propsSecondaryActions : [propsSecondaryActions]) : []).filter(
				(s: any) =>
					(!("enabled" in s) || s.enabled) &&
					checkUserRoles(s.roles) &&
					(!s.actions || s.actions.filter((a: any) => (!("enabled" in a) || a.enabled) && checkUserRoles(a.roles)).length)
			),
		[propsSecondaryActions]
	);

	const container: any = document.querySelector("#PolarisPortalsContainer") || document.querySelector("body");
	if (!container) return null;

	const show = open || hasTransitionedIn;

	return (
		<>
			{activator}
			{createPortal(
				<div data-portal-id={`modalmodal-:${id.current}:`} ref={ref} onKeyDown={onKeyDown} onClick={onOverlayClick}>
					<div>
						{show && (
							<ModalDialogContainer zIndex={zIndex.current} hasTransitionedIn={hasTransitionedIn} open={open}>
								<div>
									<div
										role="dialog"
										aria-modal="true"
										aria-label={title}
										aria-labelledby="modal-title"
										tabIndex={-1}
										className="Polaris-Modal-Dialog"
									>
										<div className={`Polaris-Modal-Dialog__Modal ${large ? "Polaris-Modal-Dialog--sizeLarge" : ""}`}>
											{/* Modal Header */}
											<ModalHeaderBox style={{ paddingBottom: 0 }}>
												<ModalHeaderGrid>
													<ModalHeaderStack>
														<h2 className="Polaris-Text--root Polaris-Text--headingLg Polaris-Text--break" id="modal-title">
															{title}
														</h2>
													</ModalHeaderStack>
													<button className="Polaris-Modal-CloseButton" aria-label="Close" onClick={onClose}>
														<span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
															<span className="Polaris-Text--root Polaris-Text--visuallyHidden">Close</span>
															{/* Close Icon SVG */}
															<svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
																<path d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z" />
															</svg>
														</span>
													</button>
												</ModalHeaderGrid>
											</ModalHeaderBox>
											{/* Modal Body */}
											<ModalBody>{sectioned ? <Modal.Section>{children}</Modal.Section> : children}</ModalBody>
											{/* Modal Footer */}
											<ModalFooterStack>
												<ModalFooterBox>
													<Footer>
														<FooterWrapperContent>
															{footer && <CustomStack>{footer}</CustomStack>}

															<CustomStack style={{ justifyContent: "space-between" }}>
																<ButtonGroup>
																	{secondaryActions?.map((secondaryAction: any, index) => {
																		const { confirmation, content, onAction, onClick, loading, disabled, destructive, icon, confirm, confirmText } =
																			secondaryAction;

																		if (secondaryAction.isGroup || secondaryAction.actions) {
																			return <ActionGroup {...secondaryAction} key={index} />;
																		}

																		if (confirmation) {
																			return <ActionButton key={index} item={secondaryAction} plain={false} />;
																		}

																		if (confirm) {
																			return (
																				<ConfirmPopup
																					title={confirmText}
																					key={index}
																					activator={
																						<ModalActionButton
																							key={index}
																							onAction={onAction}
																							onClick={onClick}
																							icon={icon}
																							loading={loading}
																							disabled={disabled}
																							destructive={destructive}
																						>
																							{content}
																						</ModalActionButton>
																					}
																				/>
																			);
																		}

																		return (
																			<ModalActionButton
																				key={index}
																				onAction={onAction}
																				onClick={onClick}
																				icon={icon}
																				loading={loading}
																				disabled={disabled}
																				destructive={destructive}
																			>
																				{content}
																			</ModalActionButton>
																		);
																	})}
																</ButtonGroup>
																<ButtonGroup>
																	{primaryAction &&
																		(!("enabled" in primaryAction) || primaryAction.enabled) &&
																		(primaryAction?.confirm ? (
																			<ConfirmPopup
																				title={primaryAction.confirmText}
																				activator={
																					<ModalActionButton
																						primary={!primaryAction.destructive}
																						onAction={primaryAction.onAction}
																						onClick={(primaryAction as any).onClick}
																						icon={primaryAction.icon}
																						loading={primaryAction.loading}
																						disabled={primaryAction.disabled}
																						destructive={primaryAction.destructive}
																					>
																						{primaryAction.content}
																					</ModalActionButton>
																				}
																			/>
																		) : (
																			<ModalActionButton
																				primary={!primaryAction.destructive}
																				onAction={primaryAction.onAction}
																				onClick={(primaryAction as any).onClick}
																				icon={primaryAction.icon}
																				loading={primaryAction.loading}
																				disabled={primaryAction.disabled}
																				destructive={primaryAction.destructive}
																			>
																				{primaryAction.content}
																			</ModalActionButton>
																		))}
																</ButtonGroup>
															</CustomStack>
														</FooterWrapperContent>
													</Footer>
												</ModalFooterBox>
											</ModalFooterStack>
										</div>
									</div>
								</div>
							</ModalDialogContainer>
						)}
						{/* Backdrop */}
						{open && <ModalBackdrop zIndex={zIndex.current - 1} onClick={handleConditionalOnClose} />}
					</div>
				</div>,
				container
			)}
		</>
	);
};

// Define the Section component
// Modal.Section = ({ children }: { children?: React.ReactNode }) => <div className="Polaris-Modal-Section">{children}</div>;
Modal.Section = PolarisModal.Section;

export default Modal;

// ModalActionButton Component
const ModalActionButton = ({ children, onAction, loading, onClick = async () => {}, ...props }) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleAction = async () => {
		try {
			setIsLoading(true);
			await onClick?.();
			await onAction?.();
		} catch (error) {
			console.error("error:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button {...props} onClick={handleAction} loading={loading || isLoading}>
			{children}
		</Button>
	);
};

// Styled Components
const ModalDialogContainer = styled.div.attrs((props: { zIndex: number }) => ({
	className: "Polaris-Modal-Dialog__Container",
	"data-polaris-layer": "true",
	"data-polaris-overlay": "true",
}))<{
	zIndex: number;
	hasTransitionedIn?: boolean;
	open?: boolean;
}>`
	z-index: ${(props) => props.zIndex};

	transition: ${({ open }) => {
		if (open) {
			return `transform ${ANIMATION_IN}ms, opacity ${ANIMATION_IN / 2}ms, filter ${ANIMATION_IN / 2}ms;`;
		}
		return `transform ${ANIMATION_OUT}ms, opacity ${ANIMATION_OUT * 2}ms, filter ${ANIMATION_OUT * 2}ms;`;
	}};
	transform: ${({ hasTransitionedIn, open }) => (hasTransitionedIn && open ? "translateY(0%)" : "translateY(20%)")};
	opacity: ${({ hasTransitionedIn, open }) => (hasTransitionedIn && open ? 1 : 0)};
	filter: ${({ hasTransitionedIn, open }) => (hasTransitionedIn && open ? "none" : "blur(2px)")};
`;

const ModalHeaderBox = styled.div.attrs({
	className: "Polaris-Box",
})`
	--pc-box-border-color: var(--p-color-border-subdued);
	--pc-box-border-style: solid;
	--pc-box-border-block-end-width: var(--p-border-width-1);
	--pc-box-padding-block-end-xs: var(--p-space-4);
	--pc-box-padding-block-start-xs: var(--p-space-4);
	--pc-box-padding-inline-start-xs: var(--p-space-5);
	--pc-box-padding-inline-end-xs: var(--p-space-5);
`;

const ModalHeaderGrid = styled.div.attrs({
	className: "Polaris-HorizontalGrid",
})`
	--pc-horizontal-grid-grid-template-columns-xs: 1fr auto;
	--pc-horizontal-grid-gap-xs: var(--p-space-4);
`;

const ModalHeaderStack = styled.div.attrs({
	className: "Polaris-HorizontalStack",
})`
	--pc-horizontal-stack-block-align: center;
	--pc-horizontal-stack-wrap: wrap;
	--pc-horizontal-stack-gap-xs: var(--p-space-4);
`;

const ModalBody = styled.div.attrs({
	className: "Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal",
	"data-polaris-scrollable": "true",
})`
	padding: 1rem;

	.Polaris-Tabs__Outer {
		& > .Polaris-Box {
			padding-top: 0;
			padding-inline: 0;
		}
	}
`;

const ModalFooterStack = styled.div.attrs({
	className: "Polaris-HorizontalStack",
})`
	--pc-horizontal-stack-block-align: center;
	--pc-horizontal-stack-wrap: wrap;
	--pc-horizontal-stack-gap-xs: var(--p-space-4);
`;

const ModalFooterBox = styled.div.attrs({
	className: "Polaris-Box",
})`
	width: 100%;
	--pc-box-border-color: var(--p-color-border-subdued);
	--pc-box-border-style: solid;
	--pc-box-border-block-start-width: var(--p-border-width-1);
	--pc-box-min-height: var(--p-space-16);
	--pc-box-padding-block-end-xs: var(--p-space-4);
	--pc-box-padding-block-start-xs: var(--p-space-4);
	--pc-box-padding-inline-start-xs: var(--p-space-5);
	--pc-box-padding-inline-end-xs: var(--p-space-5);
`;

const ModalBackdrop = styled.div.attrs((props: { zIndex: number; onClick: any }) => ({
	className: "Polaris-Backdrop",
	onClick: props.onClick,
}))<{ zIndex: number }>`
	z-index: ${(props) => props.zIndex};
`;

const FooterWrapper = styled.div.attrs({ className: "Polaris-Modal-Footer" })`
	margin-top: 1rem;
`;
const FooterWrapperContent = styled.div.attrs({ className: "Polaris-Modal-Footer__FooterContent" })`
	.Polaris-Button:not(.Polaris-Button--primary) {
		svg path {
			fill: var(--textColor);
		}
	}
`;

const CustomStack = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const Footer = ({ children }: { children?: React.ReactNode }) => {
	const ref = useRef<HTMLDivElement>(null);

	const fixFooter = useCallback(() => {
		const refElement = ref.current;
		if (refElement && refElement.closest(".Polaris-Box")) (refElement.closest(".Polaris-Box") as HTMLElement).style.width = "100%";
	}, []);

	useEffect(() => {
		fixFooter();
	}, [fixFooter]);

	return <FooterWrapper ref={ref}>{children}</FooterWrapper>;
};
