import React from "react";
import styled from "styled-components";
import { Badge } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { store } from "../../store/index.js";

const Tag = ({ tag: { title, id, created_at, updated_at, cnt, is_company }, onClick, t }) => {
	const isAdmin = store.getState().user.roles.includes("ROLE_ADMIN") || store.getState().user.roles.includes("ROLE_SUPER_ADMIN");

	return (
		<Wrapper onClick={isAdmin && onClick}>
			<h1>{title}</h1>
			{parseInt(is_company) ? (
				<Badge status="success">{t("tags.terms.company_tag", "företagstag")}</Badge>
			) : (
				<Badge status="info">{t("tags.terms.person_tag", "persontag")}</Badge>
			)}
			<Badge>{cnt}</Badge>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 0.6250rem 1.2500rem;
	cursor: pointer;
	transition: background 250ms;
	display: flex;
	gap: 1.2500rem;

	h1 {
		min-width: 250px;
	}

	&:hover {
		background-color: var(--rowHoverBg);
	}
`;

export default withTranslation(["tags", "common"], { withRef: true })(Tag);
