import React, { Component } from "react";
import { Form, Select, TextField } from "@shopify/polaris";
import styled from "styled-components";

class FontSizeChanger extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { value, onChange, label, style } = this.props;

		const size = this.state.fontSize === null || this.state.fontSize === undefined ? value : this.state.fontSize;

		return (
			<Wrapper className="font-size__wrapper" style={style}>
				<Form onSubmit={() => onChange(this.state.fontSize)}>
					<TextField
						onBlur={() => onChange(this.state.fontSize)}
						label={label}
						name="font-size"
						value={String(size)}
						onChange={(v) => this.setState({ fontSize: v })}
						inputMode="numeric"
						prefix={<img alt="font-size" src="/assets/svg/format_size.svg" />}
						// type="number"
						connectedRight={
							<Select
								value={String(size)}
								onChange={onChange}
								placeholder={String(size)}
								options={["7", "8", "9", "10", "11", "12", "13", "14", "18", "24", "36", "48", "64", "72", "96"].map((v) => ({
									label: v,
									value: v,
								}))}
							/>
						}
					/>
				</Form>
			</Wrapper>
		);
	}
}

export default FontSizeChanger;

const Wrapper = styled.div`
	width: 200px;
`;
