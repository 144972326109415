import React, { Component } from "react";

export default class SimpleCellText extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, value: props.value };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value });
	}

	toggle(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ editing: !this.state.editing });
		if (this.state.value != this.props.value) {
			this.props.onChange(this.state.value);
		}
	}

	onBlur(e) {
		this.toggle(e);
	}

	render() {
		if (this.state.editing) {
			return (
				<input
					ref={(input) => {
						if (input) {
							input.focus();
						}
					}}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							this.toggle(event);
						} else if (event.key === "Escape") {
							this.setState({ value: this.props.value }, this.toggle.bind(this, event));
						}
					}}
					onBlur={this.onBlur.bind(this)}
					className="simple-input"
					type="text"
					onClick={(e) => {
						e.stopPropagation();
					}}
					value={this.state.value}
					onChange={(e) => {
						this.setState({ value: e.target.value });
					}}
				/>
			);
		}
		return <span onClick={this.toggle.bind(this)}>{this.state.value}</span>;
	}
}
