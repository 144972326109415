import { Checkbox, DatePicker, Icon, LegacyStack, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { CalendarMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import MyPopover from "./Popover";

class DatePickerCalendarTextField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			year: moment(props.date).format("YYYY") * 1 || moment().format("YYYY"),
			month: moment(props.date).format("M") - 1 || moment().format("M") - 1,
			date: props.date,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.date)
			this.setState({
				year: moment(props.date).format("YYYY") * 1 || moment().format("YYYY"),
				month: moment(props.date).format("M") - 1 || moment().format("M") - 1,
				date: props.date,
			});
	}

	setSelectedDate(date) {
		this.state.date = (moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : date) + (this.haveTime() ? " " + this.getTime() : "");
		this.setState(
			{
				date: this.state.date,
			},
			() => (moment(date).isValid() ? this.props.onChange && this.props.onChange(this.state.date) : null)
		);
	}

	// toggleShowTime() {
	// 	this.setState(
	// 		(c) => {
	// 			const v = !c.showTime;
	// 			if (v) {
	// 				this.state.date = moment(`${moment(this.state.date).format("YYYY-MM-DD")} 09:00`).format("YYYY-MM-DD HH:mm");
	// 			} else {
	// 				this.state.date = moment(`${this.state.date}`).format("YYYY-MM-DD");
	// 			}

	// 			return { showTime: v, date: this.state.date };
	// 		},
	// 		() => this.props.onChange && this.props.onChange(this.state.date)
	// 	);
	// }

	setTime(time) {
		this.state.date = (moment(this.getDate()).isValid() ? moment(this.getDate()).format("YYYY-MM-DD") : this.getDate()) + " " + time;
		this.setState({ date: this.state.date }, () => {
			if (this.props.onChange) this.props.onChange(this.state.date);
		});
	}

	getDate() {
		return this.state.date?.split(" ")[0];
	}

	getTime() {
		const pcs = this.state.date?.split(" ");
		if (pcs?.length > 1) {
			return this.state.date?.split(" ")[1];
		}
		return null;
	}

	haveTime() {
		if (this.getTime() !== null) {
			return true;
		}
		return false;
	}

	render() {
		const date = this.state.date || moment().format("YYYY-MM-DD");

		const datepicker = (
			<React.Fragment>
				<DatePicker
					weekStartsOn={1}
					month={this.state.month || moment().format("M") - 1}
					year={this.state.year || moment().format("YYYY")}
					selected={this.state.date ? { start: new Date(this.state.date), end: new Date(this.state.date) } : null}
					onChange={({ start, end } = {}) => this.setSelectedDate(start)}
					onMonthChange={(month, year) => this.setState({ month, year })}
				/>
				<TextField
					label=""
					type="date"
					// type={"text"}
					value={this.state.date && this.getDate()}
					onChange={this.setSelectedDate.bind(this)}
					max="9999-12-31"
				/>
				<LegacyStack>
					<Checkbox
						label={this.props.t("date.terms.clock_time", "Klockslag")}
						checked={this.haveTime()}
						onChange={(v) => {
							if (v) {
								this.setTime("09:00");
							} else {
								this.state.date = moment(this.state.date).format("YYYY-MM-DD");
								this.setState({ date: this.state.date }, () => this.props.onChange && this.props.onChange(this.state.date));
							}
						}}
					/>
					{this.haveTime() ? <TextField type="time" value={moment(this.state.date).format("HH:mm")} onChange={this.setTime.bind(this)} /> : null}
				</LegacyStack>
			</React.Fragment>
		);
		return (
			<MyPopover
				// fixed
				// fluidContent
				// fullHeight
				active={this.state.open}
				activator={
					<Activator disabled={this.props.disabled} onClick={() => this.setState((c) => ({ open: !c.open }))} style={this.props.style}>
						{this.props.icon !== undefined ? this.props.icon : <Icon source={CalendarMajor} />}
						{date}
					</Activator>
				}
				onClose={() => {
					this.setState({ open: false });
					if (this.props.onBlur) this.props.onBlur();
				}}
			>
				<Wrapper>{datepicker}</Wrapper>
			</MyPopover>
		);
	}
}
export default withTranslation(["date"], { withRef: true })(DatePickerCalendarTextField);

const Activator = styled.div`
	padding: 2px;
	cursor: pointer;
	pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
	display: flex;
	gap: 0.3125rem;
	width: max-content;
	align-items: center;
	cursor: pointer;

	.Polaris-Icon {
		margin: 0;
	}

	&:hover {
		text-decoration: underline;
	}
`;

const Wrapper = styled.div`
	padding: 0.625rem;
`;
