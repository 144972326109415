/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import FortnoxInvoicesContactList from "./FortnoxInvoicesContactList";

type FortnoxInvoicesListProps = {
	history: any;
} & WithTranslation;

const FortnoxInvoicesList = ({ ...props }: FortnoxInvoicesListProps) => {
	return (
		<Page title={props.t("fortnox.invoices.title", "Fakturor") as string} history={props.history}>
			<FortnoxInvoicesContactList {...props} history={props.history} />
		</Page>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxInvoicesList);
