import { store } from "../store";

interface transformRowDataToDifferentBoardProps {
	row: BoardRowType;
	board?: BoardType;
	toBoard: BoardType;
}

// This uses colum title and type to match columns between boards
// Maybe try to use column type instead and match first column with same type and no value (so id doesn't rewrite the same first type column)
const transformRowDataToDifferentBoard = ({
	row,
	board = row.board || store.getState().boards[row.board_id],
	toBoard,
}: transformRowDataToDifferentBoardProps) => {
	return {
		...row,
		board_id: toBoard.id,
		board: toBoard,
		column_values: Object.values(row.column_values)
			.filter((i) => i)
			.reduce((acc: Record<number, BoardColumnValueType>, currentValue) => {
				const typedColumnValue = currentValue as BoardColumnValueType; // Type assertion
				const column = board.columns.find((c) => c.id === typedColumnValue?.column_id);
				const toColumn = toBoard.columns.find((c) => c.title === column.title && c.type === column.type);

				if (!toColumn) return acc;
				const columnValue = {
					column_id: toColumn.id,
					value: typedColumnValue.value,
					row_id: row.id,
				};
				if (!columnValue.row_id) {
					delete columnValue.row_id;
				}

				return {
					...(acc || {}),
					[toColumn.id]: columnValue,
				};
			}, {} as Record<number, BoardColumnValueType>),
		values:
			row.values
				?.map((value) => {
					const column = board.columns.find((c) => c.id === value.column_id);
					const toColumn = toBoard.columns.find((c) => c.title === column.title && c.type === column.type);

					if (!toColumn) return null;
					const columnValue = {
						column_id: toColumn.id,
						value: value.value,
						row_id: row.id,
					};
					if (!columnValue.row_id) {
						delete columnValue.row_id;
					}
					return columnValue;
				})
				?.filter((v) => v) || [],
	};
};

export default transformRowDataToDifferentBoard;
