import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getColorFromIndex } from "src/js/Colors";
import numberFormatter from "src/js/Utilities/numberFormatter";
import BarGraph from "src/js/components/Graphs/BarGraph";
import styled from "styled-components";
import TargetDashboardContext from "../Context";
import getSumPerPeriod from "../Utilities/getSumPerPeriod";
import OptionList from "src/js/components/OptionList/OptionList";

const ValueAndIntendedValueBarGraph = () => {
	const { data, targetConnections, target } = useContext(TargetDashboardContext);
	const stats = useMemo(() => data?.compare_against_value, [data]);

	const [properties, setProperties] = useState(stats?.options?.value_types.filter((opt) => opt.default).map((opt) => opt.value) || []);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setProperties((c) => {
			if (stats?.options?.value_types && !c?.length) {
				return stats?.options?.value_types.filter((opt) => opt.default).map((opt) => opt.value) || [];
			}

			return c;
		});
	}, [stats]);

	const labels = stats?.labels || [];

	const series = properties.map((property) => {
		return {
			name: stats?.options?.value_types?.find((o) => o.value === property)?.label || "",
			data: getSumPerPeriod(targetConnections || [], property),
			color: getColorFromIndex(stats?.options?.value_types.findIndex((opt) => opt.value === property)),
		};
	});

	const getPointWidth = () => {
		if (ref.current) {
			const width = ref.current.clientWidth;
			const pointWidth = Math.max(
				Math.min(
					width / (labels.length * 2), // space
					20
				),
				2
			);
			return pointWidth;
		}

		return 20;
	};

	return (
		<Wrapper ref={ref}>
			<BarGraph
				// key={`invoiceGraph-${selectedYear}`}
				overlayText={stats?.error}
				stacked
				data={{
					series,
					labels,
				}}
				loading={false}
				formatter={(value) => {
					return `${numberFormatter({
						value,
						currency: "SEK",

						minimumFractionDigits: target?.number_of_decimals,
						maximumFractionDigits: target?.number_of_decimals,

						removeCurrencySymbol: true,
					})}${target?.suffix || ""}`;
				}}
				showLegends
				pointWidth={getPointWidth()}
				settings={{
					stacked: false,
					dataLabels: false,
				}}
				headerRight={
					stats?.options.value_types?.length && (
						<OptionList
							popover
							allowMultiple
							options={stats?.options.value_types || []}
							selected={properties}
							onChange={(properties) => {
								setProperties(properties);
							}}
						/>
					)
				}
			/>
		</Wrapper>
	);
};
export default ValueAndIntendedValueBarGraph;
const Wrapper = styled.div``;
