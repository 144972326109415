import React, { useState } from "react";
import { Spinner } from "@shopify/polaris";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import ResourcePicker from "./resource_picker.js";
import { checkUserRoles } from "../Utilities";

const TagSelector = ({ onCreate, loading = false, contact = null, caption = "" }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation(["tags", "common"]);

	const addTag = async (tag) => {
		try {
			setIsLoading(true);

			await onCreate(tag);
		} catch (e) {
			console.error("Error", e);
		} finally {
			setIsLoading(false);
		}
	};

	if (loading || isLoading) {
		return <Spinner size="small" />;
	}
	return (
		<ResourcePicker
			plain
			tags={checkUserRoles(["ROLE_CREATE_TAG"])}
			resource="contacts/tags"
			caption={caption === undefined ? t("tags.caption", "Lägg till tagg") : caption || ""}
			onChange={addTag}
			resourceName={{ singular: t("tags.singular", "tagg"), plural: t("tags.plural", "taggar") }}
			resource_handle="tags"
			id_handle="title"
			label_handle="title"
			icon={CirclePlusMajor}
			params={{
				type: contact.is_company !== null && contact.is_company ? "company" : "person",
				group_by: "title",
				sort: "TITLE_ASC",
			}}
		/>
	);
};
export default TagSelector;
