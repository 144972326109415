import React, { useCallback, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { currencyFormatter } from "src/js/Utilities";
import Leaderboard from "src/js/components/Leaderboard";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import Select from "src/js/components/select/Select";
import { store } from "src/js/store";

type ServiceLeaderboardProps = {
	style?: any;
	data: any[];
	loading: boolean;
} & WithTranslation;

const ServiceLeaderboard = ({ style, data, loading, t }: ServiceLeaderboardProps) => {
	const [property, setProperty] = useState("closed_count");
	const history = useHistory();

	const getOptionLabel = useCallback(
		(value) => {
			switch (value) {
				case "closed_count":
					return t("dashboards.service_desk.leaderboard.closed_count", "Avslutade");
				case "open_count":
					return t("dashboards.service_desk.leaderboard.open_count", "Öppna");
				case "pending_count":
					return t("dashboards.service_desk.leaderboard.pending_count2", "Väntande");
				case "total_count":
					return t("dashboards.service_desk.leaderboard.total_count", "Totalt");
				case "event_count":
					return t("dashboards.service_desk.leaderboard.event_count", "Antal svar");
				default:
					return value;
			}
		},
		[t]
	);

	const options = useMemo(() => {
		const e = Object.values(data || {})?.[0];
		const keys = e && Object.keys(e);
		return keys?.filter((k) => k.endsWith("_count")).map((key) => ({ value: key, label: getOptionLabel(key) }));
	}, [data, getOptionLabel]);

	const rows = useMemo(() => {
		if (!data?.length && loading) return Array.from({ length: 5 });

		return Object.entries(data)
			?.map(([userId, stats]) => {
				return {
					...(stats || {}),
					userId,
					data: stats[property]?.value || 0,
					url: stats[property]?.url,
				};
			})

			?.sort((a, b) => b.data - a.data)
			?.map((row) => {
				return {
					url: row?.url,
					id: row.userId,
					userId: row.userId,
					user: store.getState().users.find((u) => u.id == row.userId),
					data: currencyFormatter({ value: row.data, style: "decimal" }),
				};
			});
	}, [data, property, loading]);

	const renderRow = (index, row) => {
		return (
			<>
				<td
					style={{ display: "flex", gap: "0.6250rem", alignItems: "center" }}
					onClick={() => {
						if (row?.url) history.push(row.url);
					}}
				>
					<ProfileAvatar size={35} user={row?.user} />
					{row?.user?.name || "-"}
				</td>
				<td>{row?.data || "-"}</td>
			</>
		);
	};

	return (
		<Leaderboard
			loading={loading}
			style={style}
			virtuoseStyle={{
				minHeight: 300,
			}}
			data={rows}
			renderRow={renderRow}
			// headers={[{ title: "", width: "50px" }, { title: "Användare", width: "50%" }, "Antal"]}
			headers={[
				{ title: "Användare", width: "75%" },
				{ title: <Select label="" labelHidden options={options} value={property} onChange={setProperty} /> },
			]}
			// title={t("dashboards.service_desk.leaderboard.title", "Topplista")}
			// data={stats?.data.leaderboard}
		/>
	);
};
export default withTranslation(["dashboards", "leaderboard"])(ServiceLeaderboard);
