export default (state = { locale: "sv" }, action) => {
	switch (action.type) {
		case "SET_DASHBOARD":
			state.dashboard = action.dashboard;
			return { ...state };
		case "SET_USER":
			// careful to not override data that's fetched at preApp/initialState from JWT
			return { ...action.user };
		case "ADD_USER_ROLE":
			state.roles.push(action.role);
			return { ...state };
		case "SET_PROJECT_TASK_LOG":
			state.project_task_log = action.project_task_log;
			return { ...state };
		case "SET_USER_EXCLUDED_BOARDS":
			state.excluded_boards = action.excluded_boards;
			return { ...state };
		case "TOGGLE_SALES_DASHBOARD_FAVORITE":
			state.sales_dashboard_is_favorite = action.favorite;
			return { ...state };
		case "UPDATE_ME":
			if (Object.keys(action.user)?.length === 1 && Object.keys(action.user)?.[0] === "online") {
				state.online = action.user.online;
				return state;
			}

			return { ...state, ...action.user };
		case "CLEAR_ME":
			return {};
		default:
			return state;
	}
};
