const circularReferenceReplacer = () => {
	const seen = new WeakSet();
	return (key: any, value: any) => {
		if (typeof value === "object" && value !== null) {
			if (seen.has(value)) {
				return "[Circular Reference]";
			}
			seen.add(value);
		}
		return value;
	};
};

export default circularReferenceReplacer;
