export default (state = {}, action) => {
	switch (action.type) {
		case "ADD_TASK_TYPE": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.task_type.id) {
					return newState;
				}
			}
			newState.push(action.task_type);
			return newState;
		}
		case "UPDATE_TASK_TYPE": {
			const newState = state.slice();
			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id == action.task_type.id) {
					newState[i] = action.task_type;
				}
			}
			return newState;
		}
		case "REMOVE_TASK_TYPE": {
			const newState = [];
			for (let i = 0; i < state.length; i++) {
				if (state[i].id != action.id) {
					newState.push(state[i]);
				}
			}
			return newState;
		}
		default:
			return state;
	}
};
