import React, { Component } from "react";
import { Popover, TextField, Icon, FormLayout } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import MyPopover from "../../components/Popover";

class CellLink extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, value: props.value };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value });
	}

	toggle() {
		if (!this.state.editing) {
			this.props.onFocus();
		} else {
			if (JSON.stringify(this.state.value) != JSON.stringify(this.props.value)) {
				this.props.onChange({ value: this.state.value });
			}
			this.props.onBlur();
		}
		this.setState({ editing: !this.state.editing });
	}

	blur() {
		if (this.state.value != this.props.value) {
			this.props.onChange({ value: this.state.value });
		}
		this.props.onBlur();
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = "";
		this.props.onChange({ value: this.state.value });
		this.setState({ value: "" });
	}

	updateValue(field, value) {
		if (!this.state.value && value) {
			this.state.value = { caption: "", url: "" };
		}
		this.state.value[field] = value;

		if (!this.state.value.caption && !this.state.value.url) {
			this.state.value = null;
		}
		this.setState({ value: this.state.value });
	}

	render() {
		let url = null;
		let caption = "";
		if (this.props.value && this.props.value.caption) {
			caption = this.props.value.caption;
		} else if (this.props.value && this.props.value.url) {
			caption = this.props.value.url;
		}
		if (this.props.value && this.props.value.url) {
			url = this.props.value.url;
		}
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.editing}
				activator={
					<div
						className={"overflow-ellipsis board-editable" + (this.state.editing ? " focused" : "")}
						style={{
							width: "100%",
							cursor: "text",
							textAlign: "center",
							height: 29,
							backgroundColor: this.state.editing ? "transparent" : null,
						}}
						onClick={this.toggle.bind(this)}
					>
						{caption || url ? (
							<a
								onClick={(e) => {
									e.stopPropagation();
								}}
								href={url}
								target="_blank"
								rel="noreferrer"
							>
								{caption}
							</a>
						) : null}
						{this.state.value ? (
							<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
								<Icon source={CircleCancelMinor} />
							</div>
						) : null}
					</div>
				}
				onClose={() => {
					this.setState({ editing: false }, this.blur.bind(this));
				}}
			>
				<Popover.Section>
					<FormLayout>
						<TextField
							label={this.props.t("cell.link.fields.url.label", "Webbadress")}
							value={this.state.value ? this.state.value.url : ""}
							onChange={this.updateValue.bind(this, "url")}
						/>
						<TextField
							label={this.props.t("cell.link.fields.caption.label", "Text att visa")}
							value={this.state.value ? this.state.value.caption : ""}
							onChange={this.updateValue.bind(this, "caption")}
						/>
					</FormLayout>
				</Popover.Section>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellLink);
