import React from "react";

const HomeIcon = ({ color = "var(--textColor)", size = 20 }) => (
	<svg width={size} height={size} viewBox="0 0 23 21" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.48812 5.75784L4.88377 17.3124C4.9003 17.7952 4.91163 18.1068 4.93913 18.3434C4.96551 18.5702 4.99994 18.6535 5.02245 18.6942C5.10812 18.8489 5.2406 18.9727 5.40089 19.0475C5.44301 19.0672 5.52842 19.0959 5.75652 19.1067C5.99442 19.1179 6.30614 19.1079 6.78888 19.0914L16.5014 18.7588C16.9842 18.7423 17.2958 18.7309 17.5324 18.7034C17.7592 18.6771 17.8425 18.6426 17.8832 18.6201C18.038 18.5344 18.1617 18.402 18.2365 18.2417C18.2562 18.1996 18.2849 18.1141 18.2957 17.886C18.3069 17.6481 18.2969 17.3364 18.2804 16.8537L17.8847 5.29911L19.5593 5.24177L19.9561 16.8286C19.9712 17.27 19.9842 17.6508 19.9694 17.9653C19.9536 18.2972 19.9052 18.6285 19.7546 18.9508C19.53 19.4317 19.1589 19.8291 18.6945 20.0861C18.3832 20.2584 18.056 20.3294 17.7259 20.3678C17.4132 20.4041 17.0324 20.4172 16.5911 20.4323L6.81393 20.767C6.37257 20.7822 5.99181 20.7952 5.67731 20.7803C5.34536 20.7646 5.01408 20.7162 4.69174 20.5656C4.21086 20.341 3.81343 19.9699 3.55643 19.5055C3.38416 19.1942 3.31317 18.867 3.27479 18.5369C3.23843 18.2242 3.2254 17.8434 3.2103 17.402L2.81354 5.81518L4.48812 5.75784Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.2455 13.1051C10.2544 13.1048 10.2633 13.1045 10.2723 13.1042L12.6167 13.0239C12.6256 13.0236 12.6345 13.0233 12.6434 13.023C12.8547 13.0157 13.0605 13.0087 13.2353 13.0169C13.4272 13.026 13.6543 13.0552 13.8855 13.1632C14.2061 13.313 14.4711 13.5604 14.6424 13.87C14.766 14.0933 14.8106 14.3178 14.8328 14.5087C14.853 14.6825 14.86 14.8883 14.8672 15.0996C14.8675 15.1085 14.8678 15.1174 14.8682 15.1263L15.0516 20.485L8.35334 20.7143L8.16985 15.3557C8.16955 15.3468 8.16924 15.3379 8.16893 15.329C8.16167 15.1177 8.1546 14.9119 8.16287 14.7371C8.17196 14.5452 8.20112 14.3181 8.30913 14.0868C8.45889 13.7662 8.7063 13.5013 9.01589 13.33C9.23919 13.2064 9.46377 13.1617 9.65463 13.1396C9.82843 13.1193 10.0342 13.1123 10.2455 13.1051ZM9.83714 14.8052C9.83694 14.8087 9.83674 14.8124 9.83656 14.8163C9.83193 14.9142 9.83593 15.0501 9.84443 15.2984L9.97058 18.9824L13.3197 18.8677L13.1936 15.1837C13.1851 14.9354 13.1798 14.7996 13.1685 14.7022C13.168 14.6983 13.1676 14.6947 13.1671 14.6912C13.1636 14.691 13.1599 14.6908 13.156 14.6906C13.0582 14.686 12.9223 14.69 12.674 14.6985L10.3296 14.7788C10.0813 14.7873 9.94549 14.7926 9.84815 14.8039C9.84427 14.8044 9.8406 14.8048 9.83714 14.8052Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.487525 7.99374C0.734203 8.3852 1.25151 8.50256 1.64296 8.25588L11.0763 2.31136L20.8941 7.59668C21.3015 7.816 21.8096 7.66353 22.0289 7.25612C22.2482 6.84871 22.0957 6.34065 21.6883 6.12132L11.0099 0.372701L0.74966 6.83831C0.358209 7.08499 0.240847 7.60229 0.487525 7.99374Z"
		/>
	</svg>
);

export default HomeIcon;
