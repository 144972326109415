/* eslint-disable no-nested-ternary */
import { Loading } from "@shopify/polaris";
import React, { Component } from "react";
import Money from "../../components/Money";
import ProfileAvatar from "../../components/ProfileAvatar";
import { store } from "../../store";

class LeaderboardSmallRow extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { className, row, index, loading, onClick } = this.props;
		const { user, values, types } = row || {};

		return (
			<tr
				data-position={index + 1}
				className={`leaderboard_small_row ${className || ""} ${loading ? " loading" : ""}`}
				onClick={onClick}
				style={{ cursor: onClick ? "pointer" : "initial" }}
			>
				{/* <span className="leaderboard_small_position">{index + 1}</span> */}
				{row ? (
					<React.Fragment>
						<td>
							{loading && !user ? (
								<div style={{ height: 40, width: 40, background: "var(--main2)", borderRadius: "50%" }} />
							) : (
								<ProfileAvatar size={35} user={user} />
							)}
						</td>

						<td className="leaderboard_small_row_title">{user && user.name}</td>
						{values.map((v, valueIndex) => (
							<td key={valueIndex} className={`leaderboard_small_row_value index-${valueIndex}`}>
								{types && types[valueIndex] === "money" ? <Money amount={v} currency={store.getState().account.currency} /> : v}
							</td>
						))}
						{/* <td className="leaderboard_small_row_value">{value1}</td>
						<td className="leaderboard_small_row_value">{value2}</td> */}
					</React.Fragment>
				) : Loading ? (
					<React.Fragment>
						<td>
							<div style={{ height: 40, width: 40, background: "var(--main2)", borderRadius: "50%" }} />
						</td>
						<td />
						<td />
						<td />
					</React.Fragment>
				) : null}
			</tr>
		);
	}
}
export default LeaderboardSmallRow;
