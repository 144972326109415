import React, { Component } from "react";
import { LegacyStack, FormLayout, DropZone, TextField } from "@shopify/polaris";
import { EditMajor, SendMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../API";
import { store } from "../store";
import ContactSelector from "./ContactSelector.js";
import Attachment from "./Attachment.js";
import ProjectTaskStatusSelector from "./ProjectTaskStatusSelector.js";
import ProjectTaskTypeSelector from "./ProjectTaskTypeSelector.js";
import Tabs from "./Tabs";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

class TicketModal extends Component {
	constructor(props) {
		super(props);
		if (props.contact) {
			props.ticket.contact = Object.assign({}, props.contact);
			props.ticket.contact.tickets = [];
		}
		if (!props.ticket.contact || !props.ticket.contact.email) {
			props.ticket.internal = true;
		}
		if (!props.ticket.files) {
			props.ticket.files = [];
		}
		if (!props.origin) {
			props.ticket.origin = "ticket";
		}

		props.ticket.user = store.getState().user;
		this.state = {
			open: props.open,
			form: props.ticket,
			saving: false,
			randomid: "rndticketcommentinp" + Math.round(Math.random() * 1000),
		};
		this.onPasteEvent = this.onPaste.bind(this);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			if (props.contact) {
				props.ticket.contact = Object.assign({}, props.contact);
				props.ticket.contact.tickets = [];
			}
			if (!props.ticket.contact || !props.ticket.contact.email) {
				props.ticket.internal = true;
			}
			if (!props.ticket.files) {
				props.ticket.files = [];
			}
			if (!props.origin) {
				props.ticket.origin = "ticket";
			}

			props.ticket.user = store.getState().user;
			this.setState({ open: props.open, form: props.ticket }, () => {
				if (this.state.open) {
					this.addPasteEventToInput();
				}
			});
		}
	}

	addPasteEventToInput() {
		const parentEle = document.querySelector(`#${this.state.randomid}`);
		if (parentEle && parentEle.querySelector(".Polaris-TextField__Input")) {
			parentEle.querySelector(".Polaris-TextField__Input").addEventListener("paste", this.onPasteEvent);
		}
	}

	componentDidMount() {
		$(".ticket-modal").closest("[data-portal-id]").addClass("modal-top");
		this.addPasteEventToInput();
	}

	componentWillUnmount() {
		const parentEle = document.querySelector(`#${this.state.randomid}`);
		if (parentEle && parentEle.querySelector(".Polaris-TextField__Input")) {
			parentEle.querySelector(".Polaris-TextField__Input").removeEventListener("paste", this.onPasteEvent);
		}
	}

	onPaste(e) {
		if (!e.clipboardData) {
			return;
		}
		const items = e.clipboardData.items;

		if (items == undefined) {
			return;
		}

		for (let i = 0; i < items.length; i++) {
			// Skip content if not image
			if (items[i].type.indexOf("image") >= 0) {
				// Retrieve image on clipboard as blob
				const blob = items[i].getAsFile();

				if (blob) {
					e.preventDefault();
					const reader = new FileReader();
					reader.onload = this.addFile.bind(this, blob);
					reader.readAsDataURL(blob);
				}
			}
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });

		API.post("/api/tickets.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}

				Toast.success(this.props.t("ticket.responses.created", "Skapade ärende") + " " + result.data.ticket.number);
				this.setState({ saving: false });
				if (this.props.onCreate) {
					if (result.data.parent_ticket) {
						this.props.onCreate(result.data.ticket, result.data.parent_ticket);
						return;
					}
					this.props.onCreate(result.data.ticket);
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				Toast.error(error);
			});
	}

	addFile(file, event) {
		const data = event.target.result;
		const files = this.state.form.files;
		files.push({ name: file.name, size: file.size, data });
		this.state.form.files = files;
		this.setState({ form: this.state.form });
	}

	updateFile(index, file) {
		this.state.form.files[index] = file;
		this.setState({ form: this.state.form });
	}

	removeFile(index, file) {
		this.state.form.files.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	render() {
		let haveEmail = false;
		let actionText;
		let tabs = [];
		if (this.state.form.contact && this.state.form.contact.email) {
			haveEmail = true;
			if (this.state.form.internal) {
				actionText = this.props.t("ticket.text.inter_comment", "En intern kommentar skapas, inget skickas till kontakt");
			} else {
				actionText = this.props.t("ticket.text.not_internal", "Ett mail kommer att skickas till") + ": " + this.state.form.contact.email;
			}
			tabs = [
				{
					id: "visible",
					icon: SendMajor,
					content: this.props.t("ticket.tabs.visible", "Meddelande till kund"),
					panelID: "visible",
				},
				{
					id: "internal",
					icon: EditMajor,
					content: this.props.t("ticket.tabs.internal", "Intern kommentar"),
					panelID: "internal",
				},
			];
		} else {
			actionText = this.props.t("ticket.text.no_email_only_internal", "Ingen e-post finns, endast intern kommentar kan skapas till denna kontakt");
			tabs = [
				{
					id: "internal",
					icon: EditMajor,
					content: this.props.t("ticket.tabs.internal", "Intern kommentar"),
					panelID: "internal",
				},
			];
		}

		if (this.state.form.origin != "ticket") {
			tabs = [
				{
					id: "visible",
					icon: SendMajor,
					content: this.props.t("ticket.tabs.visible", "Meddelande till kund"),
					panelID: "visible",
				},
			];
		}

		const originOptions = [{ label: this.props.t("ticket.actions.create", "Skapa ett ärende"), value: "ticket" }];

		for (let i = 0; i < store.getState().mailboxes.length; i++) {
			const mailbox = store.getState().mailboxes[i];
			if (haveEmail) {
				originOptions.push({
					label: this.props.t("ticket.options.mail_from", "Mail från {{title}}", {
						title: mailbox.title,
					}),
					value: mailbox.id,
				});
			}
		}

		if (store.getState().account.id != 15) {
			if (this.state.form.project) {
				originOptions.push({ label: this.props.t("ticket.options.create_ticket_order", "Skapa uppdrag & ärende"), value: "project_task" });
			}
		}

		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={!this.state.form.id ? this.props.t("ticket.titles.new", "Nytt ärende") : this.props.t("ticket.titles.complete", "Avsluta ärende")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: !this.state.form.id ? this.props.t("common.actions.create", "Skapa") : this.props.t("common.actions.end", "Avsluta"),
					disabled: !this.state.form.message || !this.state.form.title || !this.state.form.contact,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				<div className="ticket-modal" />
				<Modal.Section>
					<FormLayout>
						<ContactSelector
							label={this.props.t("ticket.fields.contact_company.label", "Kontakt/Företag")}
							contact={this.state.form.contact}
							onChange={(contact) => {
								if (!contact || !contact.email) {
									this.state.form.internal = true;
								}
								this.state.form.contact = contact;
								this.setState({ form: this.state.form });
							}}
							lock={!!this.props.contact}
						/>

						<TextField
							label={this.props.t("ticket.fields.title.label", "Rubrik")}
							value={this.state.form.title}
							multiline
							onChange={this.updateForm.bind(this, "title")}
						/>
						{this.state.form.origin == "project_task" ? (
							<LegacyStack>
								<ProjectTaskStatusSelector
									label={this.props.t("ticket.fields.project_task_status.label", "Status på uppdrag")}
									status={this.state.form.project_task_status}
									onChange={(status) => {
										this.state.form.project_task_status = status;
										this.setState({ form: this.state.form });
									}}
								/>
								<ProjectTaskTypeSelector
									label={this.props.t("ticket.fields.project_task_type.label", "Uppdragstyp")}
									type={this.state.form.project_task_type}
									onChange={(type) => {
										this.state.form.project_task_type = type;
										this.setState({ form: this.state.form });
									}}
								/>
							</LegacyStack>
						) : null}
					</FormLayout>
				</Modal.Section>
				<Modal.Section>
					<div className="ticket">
						<Tabs
							useNative
							renderChildrenInside
							tabs={tabs}
							selected={this.state.form.internal ? (!haveEmail ? 0 : 1) : 0}
							fitted
							onSelect={(tabIndex) => {
								if (this.state.form.contact && this.state.form.contact.email) {
									this.state.form.internal = !!tabIndex;
								} else {
									this.state.form.internal = true;
								}
								this.setState({ form: this.state.form });
							}}
						>
							<div className="textfield-wrapper">
								<TextField
									value={this.state.form.message}
									multiline
									/*
								suffix={
									<Tooltip content="Ladda upp fil">
										<Button
											plain
											size="slim"
											onClick={() => {
												this.setState({ openFileDialog: true });
											}}
										>
											<Icon color="skyDark" source={ImportMinor} />
										</Button>
									</Tooltip>
								}
								*/
									onChange={this.updateForm.bind(this, "message")}
								/>
								<div style={{ paddingTop: 5 }}>
									{this.state.form.files.map((file, index) => (
										<Attachment
											editable
											onRemove={this.removeFile.bind(this, index)}
											onChange={this.updateFile.bind(this, index)}
											key={index}
											file={file}
										/>
									))}
								</div>
							</div>
							<div style={{ width: 50, height: 50, display: "none" }}>
								<DropZone
									openFileDialog={this.state.openFileDialog}
									onFileDialogClose={() => {
										this.setState({ openFileDialog: false });
									}}
									onDrop={(files, acceptedFiles, rejectedFiles) => {
										for (let i = 0; i < acceptedFiles.length; i++) {
											const reader = new FileReader();
											reader.onload = this.addFile.bind(this, acceptedFiles[i]);
											reader.readAsDataURL(acceptedFiles[i]);
										}
									}}
								>
									<DropZone.FileUpload
										actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
										actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
									/>
								</DropZone>
							</div>

							<div className="ticket-action">{actionText}</div>
						</Tabs>
					</div>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["ticket", "common", "upload"], { withRef: true })(TicketModal);
