import React, { useState } from "react";
import Popover from "../Popover";
import { Button, Checkbox } from "@shopify/polaris";
import { SettingsMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

type SettingsPopupProps = {
	settings: any;
	setSettings: (settings: any) => void;
};

const SettingsPopup = ({ settings, setSettings }: SettingsPopupProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const options = Object.entries(settings).map(([key, value]: [string, any]) => {
		return {
			value,
			key,
			label: getLabel(key),
			checked: value,
			disabled: key === "stacked" && settings.grouped,
		};
	});

	const handleOnChange = (key: string) => (checked: boolean) => {
		const newSettings = {
			...settings,
			[key]: checked,
		};

		if (key === "grouped" && checked) {
			newSettings.stacked = false;
		}

		setSettings(newSettings);
	};

	return (
		<Popover
			sectioned
			active={isOpen}
			activator={<Button onClick={() => setIsOpen(!isOpen)} icon={SettingsMinor} />}
			onClose={() => setIsOpen(false)}
		>
			{options.map((option, index) => {
				return (
					<Item key={index}>
						<Checkbox checked={option.checked} label={option.label} onChange={handleOnChange(option.key)} disabled={option.disabled} />
					</Item>
				);
			})}
		</Popover>
	);
};
export default SettingsPopup;

const getLabel = (key): string => {
	switch (key) {
		case "stacked":
			return "staplad";
		case "grouped":
			return "Grupperad";
		case "dataLabels":
			return "Dataetiketter";
		default:
			return key;
	}
};

const Item = styled.div``;
