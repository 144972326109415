import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";

class FrontendExceptionLogger extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
		};
		this.blacklist = ["127.0.0.1", "localhost", "dev.sellfinity.com"];
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error: true };
	}

	componentDidCatch(error, errorInfo) {
		if (this.blacklist.includes(window.location.hostname)) return;
		const errorObject = {};
		if (error) {
			Object.assign(errorObject, { error: error.stack });
		}
		if (errorInfo) {
			Object.assign(errorObject, errorInfo);
		}
		if (window.location && window.location.href) {
			Object.assign(errorObject, { location: window.location.href });
		}
		if (window.location && window.location.hostname) {
			Object.assign(errorObject, { hostname: window.location.hostname });
		}
		Object.assign(errorObject, { date: new Date().toLocaleString("sv-SE"), type: "frontend" });
		this.sendError(errorObject);
	}

	sendError(errorObject) {
		API.post("/api/frontend_exception.json", errorObject).catch((error) => console.error("error", error));
	}

	render() {
		return this.state.error ? (
			this.props.errorPage ? (
				this.props.errorPage
			) : (
				<div
					style={{
						position: "fixed",
						display: "flex",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						textAlign: "center",
						justifyContent: "center",
						alignItems: "center",
						color: "var(--textColor)",
					}}
				>
					{this.props.t("common.status.error", "Något gick fel, felet rapporterades")}
				</div>
			)
		) : (
			this.props.children
		);
	}
}

export default withTranslation(["common"], { withRef: true })(FrontendExceptionLogger);
