import React, { Component } from "react";
import {
	LegacyCard,
	Text,
	ExceptionList,
	ProgressBar,
	TextField,
	ResourceItem,
	Layout,
	Icon,
	Banner,
	Checkbox,
	Select,
	Button,
	FormLayout,
	InlineError,
	LegacyStack,
} from "@shopify/polaris";
import moment from "moment";
import { CircleTickMajor, CancelSmallMinor, CircleAlertMajor, ChevronRightMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { store } from "../../../store";
import Upload from "../../../components/Upload.js";
import UsagePackageModal from "../../../components/UsagePackageModal.js";
import ContactDataIcon from "../../../icons/ContactDataIcon.js";
import TagIcon from "../../../icons/TagIcon";
import StackingBarsIcon from "../../../icons/StackingBarsIcon";
import { HorizontalConnectionIcon, NoteIcon } from "../../../icons";
import NumberBadge from "../../../components/NumberBadge";
import Colors from "../../../Colors";
import InfoView from "../../../components/InfoView";
import ExportToExcelButton from "./ExportToExcelButton";
import ModalOptionBox from "../../../components/ModalOptionBox";
import styled from "styled-components";
import Spinner from "src/js/components/Spinner";
import Modal from "src/js/components/modal";
import Mappings from "src/js/components/Mappings/Mappings";
import Toast from "src/js/components/Toast";

class ImportModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			importType: null,
			duplicate_rule: this.props.asVisitor ? "update" : "create",
			show_duplicate_settings: false,
			open: false,
			first_row_is_header: true,
			file: null,
			limits: null,
			sheets: [],
			audiences: [],
			mappings: {},
			tag_name: "Import " + moment().format("YYYY-MM-DD HH:mm"),
			skipped: [],
			created: [],
			updated: [],
			title: props.title || "",
			board_view: props.board_view || "table",
			template_board: props.template_board,
			error: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({
				importType: null,
				importing: false,
				duplicate_rule: this.props.asVisitor ? "update" : "create",
				show_duplicate_settings: false,
				open: props.open,
				first_row_is_header: true,
				file: null,
				sheets: [],
				mappings: {},
				tag_name: "Import " + moment().format("YYYY-MM-DD HH:mm"),
				spreadsheet_col_index: null,
				spreadsheet_col_date: null,
				spreadsheet_col_user_id: null,
				showResult: false,
				skipped: [],
				updated: [],
				created: [],
				template_board: props.template_board,
				error: null,
			});
		}
	}

	uploadFile(file) {
		this.setState({ file, loading: true, error: null });
		API.post("/api/spreadsheets/analyze.json", { data: file.data }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ loading: false, file: null });
					return;
				}

				this.setState({
					sheets: result.data.sheets,
					spreadsheet_id: result.data.spreadsheet_id /* num_rows: result.data.num_rows */,
				});

				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false, file: null });
				Toast.error(error);
			});
	}

	fetchLimits() {
		API.get("/api/account/limits.json", {})
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.setState({ limits: result.data.limits });
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	getColumnIndexFromBoardColumn(boardColumnId) {
		// eslint-disable-next-line no-restricted-syntax
		for (const spreadsheetColumnIndex in this.state.mappings) {
			if (this.state.mappings[spreadsheetColumnIndex] == boardColumnId) {
				return spreadsheetColumnIndex;
			}
		}
		return null;
	}

	getSpreadsheetColumns() {
		const columns = [];
		if (this.state.sheets && this.state.sheets[0] && this.state.sheets[0].headers) {
			for (let i = 0; i < this.state.sheets[0].headers.length; i++) {
				if (this.state.first_row_is_header) {
					columns.push({
						title: this.state.sheets[0].headers[i],
						example: this.state.sheets[0].example[i],
						helpText: this.state.sheets[0].example[i] || "",
					});
				} else {
					columns.push({
						title: "Kolumn " + (i + 1),
						example: this.state.sheets[0].headers[i],
						helpText: this.state.sheets[0].headers[i] || "",
					});
				}
			}
		}
		return columns;
	}

	getMappingTargets() {
		if (this.props.type == "board" && this.props.board_view === "call_list" && !this.props.template_board) {
			return [
				{
					label: this.props.t("import.fields.new_title.label", "Rubrik"),
					value: "title",
					required: true,
				},
				{
					label: this.props.t("import.fields.phone.label", "Telefon"),
					value: "call_list_phone",
					required: true,
				},
				{
					label: this.props.t("import.fields.orgnr.label", "Organisationsnummer"),
					value: "call_list_company_number",
				},
				{
					label: this.props.t("import.fields.email.label", "Epost"),
					value: "call_list_email",
				},
				{
					label: this.props.t("import.fields.status.label", "status"),
					value: "call_list_status",
				},
				// This could be assigned to anything (string etc.) and contact columns values can only be a contact id (number)
				// {
				// 	label: this.props.t("import.fields.contact.label", "kontakt"),
				// 	value: "call_list_contact",
				// },
				{
					label: this.props.t("import.fields.contact_person.label", "Kontaktperson"),
					value: "call_list_contact_person",
				},
				{
					label: this.props.t("import.fields.address.label", "Gatuadress"),
					value: "call_list_address",
				},
				{
					label: this.props.t("import.fields.zip.label", "Postnummer"),
					value: "call_list_zip",
				},
				{
					label: this.props.t("import.fields.county.label", "Ort"),
					value: "call_list_county",
				},
				{
					label: this.props.t("import.fields.branch.label", "Bransch"),
					value: "call_list_bransch",
				},
				{
					label: this.props.t("import.fields.employees.label", "Antal anställda"),
					value: "call_list_employees",
				},
				// {
				// 	label: this.props.t("import.fields.county.label", "Personkolumn"),
				// 	value: "call_list_person",
				// },
				{
					label: this.props.t("import.fields.date.label", "Datum"),
					value: "call_list_date",
				},
			];
		}
		if (this.props.type == "board" && !this.props.board && !this.state.template_board) {
			return [
				{
					label: this.props.t("import.fields.new_title.label", "Rubrik"),
					value: "title",
					required: true,
				},
				{
					label: this.props.t("import.fields.new_text.label", "Ny textkolumn"),
					value: "text",
					new: true,
				},
				{
					label: this.props.t("import.fields.new_number.label", "Ny nummerkolumn"),
					value: "number",
					new: true,
				},
				{
					label: this.props.t("import.fields.new_phone.label", "Ny telefonkolumn"),
					value: "phone",
					new: true,
				},
				{
					label: this.props.t("import.fields.new_datetime.label", "Ny datumkolumn"),
					value: "datetime",
					new: true,
				},
				{
					label: this.props.t("import.fields.new_status.label", "Ny statuskolumn"),
					value: "status",
					new: true,
				},
				{
					label: this.props.t("import.fields.new_dropdown.label", "Ny dropdownkolumn"),
					value: "dropdown",
					new: true,
				},
				{
					label: this.props.t("import.fields.new_company_number.label", "Ny organisationnummerkolumn"),
					value: "company_number",
					new: true,
				},
			];
		} else if (this.props.type == "board" && (this.props.board || this.state.template_board)) {
			// TODO: Hämta kolumner från board
			const board = this.props.board || this.state.template_board;
			const targets = [
				{
					label: this.props.t("import.fields.title.label2", "Rubrik"),
					value: "title",
					required: true,
				},
			];
			for (let i = 0; i < board.columns.length; i++) {
				if (
					board.columns[i].type == "text" ||
					board.columns[i].type == "number" ||
					board.columns[i].type == "datetime" ||
					board.columns[i].type == "company_number" ||
					board.columns[i].type == "dropdown" ||
					board.columns[i].type == "status" ||
					board.columns[i].type == "phone"
				) {
					targets.push({
						label: board.columns[i].title,
						value: board.columns[i].id + "",
					});
				}
			}
			return targets;
		} else if (this.props.type == "company") {
			const targets = [
				{
					label: this.props.t("import.fields.name", "Företagsnamn"),
					value: "name",
					required: true,
				},
				{
					label: this.props.t("import.fields.email", "E-post"),
					value: "email",
				},
				{
					label: this.props.t("import.fields.phone", "Telefon"),
					value: "phone",
				},
				{ label: this.props.t("import.fields.orgnr", "Orgnr"), value: "orgnr" },
				{
					label: this.props.t("import.fields.address1", "Adressrad 1"),
					value: "address1",
				},
				{
					label: this.props.t("import.fields.address2", "Adressrad 2"),
					value: "address2",
				},
				{
					label: this.props.t("import.fields.zip", "Postnummer"),
					value: "zip",
				},
				{ label: this.props.t("import.fields.city", "Ort"), value: "city" },
				{
					label: this.props.t("import.fields.reference", "Eget ID"),
					value: "reference",
				},
				{
					label: this.props.t("import.fields.account_manager", "Kundansvarig"),
					value: "account_manager",
					enabled: store.getState().fields?.length == 1,
				},
			].filter((i) => !("enabled" in i) || i.enabled);

			for (let i = 0; i < store.getState().contact_metafields.length; i++) {
				if (store.getState().contact_metafields[i].for == "company") {
					targets.push({
						label: store.getState().contact_metafields[i].title,
						value: "metafield_" + store.getState().contact_metafields[i].id,
					});
				}
			}

			return targets;
		} else if (this.props.type == "people") {
			let targets = [];
			if (this.props.asVisitor) {
				targets = [
					{
						label: this.props.t("import.contact.fields.first_name.label", "Förnamn"),
						value: "first_name",
					},
					{
						label: this.props.t("import.contact.fields.last_name.label", "Efternamn"),
						value: "last_name",
					},
					{
						label: this.props.t("import.contact.fields.email.label", "E-post"),
						value: "email",
						required: true,
					},
					{
						label: this.props.t("import.contact.fields.title.label", "Befattning/Roll"),
						value: "title",
					},
					{
						label: this.props.t("import.contact.fields.phone.label", "Telefon"),
						value: "phone",
					},
					{
						label: this.props.t("import.contact.fields.mobile_phone.label", "Mobilnummer"),
						value: "mobile_phone",
					},
					{
						label: this.props.t("import.contact.fields.company.label", "Företagsnamn"),
						value: "company",
					},
					{
						label: this.props.t("import.contact.fields.company_reference.label", "Företag eget ID"),
						value: "company_reference",
					},
					{
						label: this.props.t("import.contact.fields.company_orgnr.label", "Företag orgnr"),
						value: "company_orgnr",
					},
					{
						label: this.props.t("import.contact.fields.reference.label", "Eget ID"),
						value: "reference",
					},
				];
			} else {
				targets = [
					{
						label: this.props.t("import.contact.fields.first_name.label", "Förnamn"),
						value: "first_name",
						required: true,
					},
					{
						label: this.props.t("import.contact.fields.last_name.label", "Efternamn"),
						value: "last_name",
						required: true,
					},
					{
						label: this.props.t("import.contact.fields.email.label", "E-post"),
						value: "email",
					},
					{
						label: this.props.t("import.contact.fields.title.label", "Befattning/Roll"),
						value: "title",
					},
					{
						label: this.props.t("import.contact.fields.phone.label", "Telefon"),
						value: "phone",
					},
					{
						label: this.props.t("import.contact.fields.mobile_phone.label", "Mobilnummer"),
						value: "mobile_phone",
					},
					{
						label: this.props.t("import.contact.fields.company.label", "Företagsnamn"),
						value: "company",
					},
					{
						label: this.props.t("import.contact.fields.company_reference.label", "Företag eget ID"),
						value: "company_reference",
					},
					{
						label: this.props.t("import.contact.fields.company_orgnr.label", "Företag orgnr"),
						value: "company_orgnr",
					},
					{
						label: this.props.t("import.contact.fields.reference.label", "Eget ID"),
						value: "reference",
					},
				];
			}

			for (let i = 0; i < store.getState().contact_metafields.length; i++) {
				if (store.getState().contact_metafields[i].for == "person") {
					targets.push({
						label: store.getState().contact_metafields[i].title,
						value: "metafield_" + store.getState().contact_metafields[i].id,
					});
				}
			}

			return targets;
		}
	}

	haveAllRequiredMappings() {
		const targets = this.getMappingTargets();

		return targets.reduce((acc, t) => {
			if (t.required && this.getColumnIndexFromBoardColumn(t.value) === null) {
				acc.push(t);
			}

			return acc;
		}, []);

		// for (let i = 0; i < targets.length; i++) {
		// 	if (targets[i].required && this.getColumnIndexFromBoardColumn(targets[i].value) === null) {
		// 		return false;
		// 	}
		// }
		// return true;
	}

	getMappedColumnOptions() {
		const spreadsheetColumns = this.getSpreadsheetColumns();
		const options = [];
		// eslint-disable-next-line no-restricted-syntax
		for (const spreadsheetColumnIndex in this.state.mappings) {
			options.push({
				label: spreadsheetColumns[spreadsheetColumnIndex].title,
				value: spreadsheetColumnIndex + "",
			});
		}
		return options;
	}

	getImportTypeSelector() {
		const types = [
			{
				value: "data",
				icon: <ContactDataIcon />,
				label: this.props.t("import.fields.data.label", "Kunddata"),
				description: this.props.t("import.fields.data.description", "Uppdatera informationen på era kontakter, så som namn, adress med mera."),
			},
			{
				value: "tags",
				icon: <TagIcon />,
				label: "Taggar",
				description: "Importera taggar till era kontakter",
			},
			{
				value: "comments",
				icon: <StackingBarsIcon />,
				label: this.props.t("import.fields.comments.label", "Aktiviteter"),
				description: this.props.t(
					"import.fields.comments.description",
					"Aktiviteter visas på kundkortet. Här visas t.ex. senaste mötet, samtalet eller besöket."
				),
			},
		];

		return (
			<Modal.Section>
				<div className="boxes_wrapper">
					{types.map((type, index) => (
						<ModalOptionBox key={index} title={type.label} icon={type.icon} description={type.description}>
							<Button onClick={() => this.setState({ importType: type.value })}>Importera</Button>
						</ModalOptionBox>
					))}
				</div>
			</Modal.Section>
		);
	}

	importFile() {
		this.setState({ saving: true });
		const data = {
			type: this.props.type,
			workspace: this.props.workspace,
			board: this.props.board,
			template_board: this.props.template_board,
			title: this.state.title,
			data: this.state.file.data,
			first_row_is_header: this.state.first_row_is_header,
			mappings: this.state.mappings,
			duplicate_rule: this.state.duplicate_rule,
			duplicate_column: this.state.duplicate_column,
			as_visitor: this.props.asVisitor ? 1 : 0,
			tag_name: this.state.tag_name,
			import_type: this.state.importType,
			spreadsheet_col_index: this.state.spreadsheet_col_index,
			spreadsheet_col_date: this.state.spreadsheet_col_date,
			spreadsheet_col_user_id: this.state.spreadsheet_col_user_id,
			board_view: this.state.board_view,
		};

		API.post("/api/spreadsheets/" + this.state.spreadsheet_id + "/start_import.json", data, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ saving: false });
					return;
				}

				this.setState((c) => ({
					spreadsheet: result.data.spreadsheet,
					importing: true,
				}));
				if (this.props.onComplete && !result.data.spreadsheet) {
					this.props.onComplete();
				}
				if (result.data.spreadsheet) this.importRows(result.data.spreadsheet.id);

				this.setState({ saving: false });
			})
			.catch((error) => {
				this.setState({ saving: false, error: Toast.error(error) });
			});
	}

	fetchAudiences() {
		API.get("/api/audiences.json", {}, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.setState({ audiences: result.data.audiences });
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	importRows(id) {
		API.post("/api/spreadsheets/" + id + "/import.json", {}, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				this.setState((c) => ({
					spreadsheet: result.data.spreadsheet,
					showResult: !!result.data.spreadsheet.complete_at,
					skipped: [].concat(c.skipped, result.data.skipped),
					updated: [].concat(c.updated, result.data.updated),
					created: [].concat(c.created, result.data.created),
				}));
				if (!result.data.spreadsheet.complete_at) {
					this.importRows(result.data.spreadsheet.id);
				} else {
					if (this.props.onComplete) {
						this.props.onComplete(result.data.spreadsheet);
					}
				}
			})
			.catch((error) => {
				this.setState({ saving: false, error: Toast.error(error) });
			});
	}

	getMappingTargetOptions(spreadsheetColumnIndex) {
		const targets = this.getMappingTargets();
		const options = [
			{
				label: this.props.t("import.terms.no_import", "-- importeras inte --"),
				value: "",
			},
		];
		for (let i = 0; i < targets.length; i++) {
			let disabled = true;
			if (targets[i].new) {
				disabled = false;
			} else if (this.getColumnIndexFromBoardColumn(targets[i].value) === null) {
				disabled = false;
			} else if (this.getColumnIndexFromBoardColumn(targets[i].value) === spreadsheetColumnIndex + "") {
				disabled = false;
			}
			options.push({
				label: targets[i].label + (targets[i].required ? "*" : ""),
				value: targets[i].value,
				disabled,
			});
		}
		return options;
	}

	selectAudience(audience) {
		if (audience.filters.length != 1 || (audience.filters.length > 0 && audience.filters[0].key != "tagged")) {
			return;
		}
		this.setState({ tag_name: audience.filters[0].value });
	}

	getAdjectiveName() {
		if (this.state.importType === "tags")
			return {
				singular: this.props.t("import.box.excel.terms.tag", "Tagg"),
				plural: this.props.t("import.box.excel.terms.tags", "Taggar"),
				bestamd_singular: this.props.t("import.box.excel.terms.the_tag", "Taggen"),
				bestamd_plural: this.props.t("import.box.excel.terms.the_tags", "Taggarna"),
			};
		if (this.state.importType === "comments")
			return {
				singular: this.props.t("import.box.excel.terms.comment", "Kommentar"),
				plural: this.props.t("import.box.excel.terms.comments", "Kommentarer"),
				bestamd_singular: this.props.t("import.box.excel.terms.the_comment", "kommentaren"),
				bestamd_plural: this.props.t("import.box.excel.terms.the_comments", "kommentarerna"),
			};

		return {};
	}

	render() {
		let title = "";

		if (this.props.type == "company") {
			if (this.state.importType === "tags") {
				title = this.props.t("import.company.tags.title", "Importera taggar till företag");
			} else if (this.state.importType === "comments") {
				title = this.props.t("import.company.comments.title", "Importera kommentarer till företag");
			} else {
				title = this.props.t("import.company.company.title", "Importera företag");
			}
		} else if (this.props.type == "people") {
			if (this.state.importType === "tags") {
				title = this.props.t("import.people.tags.title", "Importera taggar till kontaktpersoner");
			} else if (this.state.importType === "comments") {
				title = this.props.t("import.people.comments.title", "Importera kommentarer till kontaktpersoner");
			} else {
				title = this.props.t("import.people.people.title", "Importera kontaktpersoner");
			}
		} else if (this.props.type == "board") {
			if (this.props.board) {
				title = this.props.t("import.board.board.title", "Importera till board");
			} else if (this.props.template_board) {
				title = this.props.t("import.board.template_board.title", "Importera till ny board med mall {{boardTitle}}", {
					boardTitle: this.props.template_board.title,
				});
			} else {
				title = this.props.t("import.board.new_board.title", "Importera till ny board");
			}
		}

		let primaryAction = null;
		const missingRequiredTargets = this.haveAllRequiredMappings();
		const disabled = missingRequiredTargets.length;
		if (this.props.type == "board" && !this.props.board && !this.state.title) {
			primaryAction = {
				content: this.props.t("common.actions.next", "Nästa"),
				disabled: !this.state.temp_title,
				onAction: () => {
					this.setState({ title: this.state.temp_title, temp_title: null });
				},
			};
		} else if (this.state.importType === "tags" || this.state.importType === "comments") {
			primaryAction = {
				content: this.props.t("common.actions.import", "Importera"),
				disabled:
					!this.state.mappings ||
					!Object.entries(this.state.mappings) ||
					!Object.entries(this.state.mappings).every((ar) => ar.every((i) => i !== "undefined" && i !== "null" && i)),
				loading: this.state.saving,
				onAction: this.importFile.bind(this),
			};
		} else if (this.state.sheets.length && !this.state.show_duplicate_settings && !["tags", "comments"].includes(this.state.importType)) {
			primaryAction = {
				content: this.props.t("common.actions.next", "Nästa"),
				disabled,
				loading: this.state.saving,
				onAction: () => {
					this.setState({ show_duplicate_settings: true });
					if (this.props.asVisitor) {
						this.fetchAudiences();
						this.fetchLimits();
					}
				},
			};
		}

		if (this.state.sheets.length && ((!this.props.board && this.props.type == "board") || this.state.show_duplicate_settings)) {
			primaryAction = {
				content: this.props.t("common.actions.import", "Importera"),
				disabled,
				loading: this.state.saving,
				onAction: this.importFile.bind(this),
			};
		}

		const missingFields = (() => {
			if (!missingRequiredTargets || !missingRequiredTargets.length || !primaryAction) {
				return null;
			}
			return (
				<div className="import-fields-missing-wrapper" title="Obligatoriska columner">
					<Icon source={CircleAlertMajor} />
					{missingRequiredTargets.map((t, index) => (
						<span key={index}>{t.label}</span>
					))}
				</div>
			);
		})();

		const rowsCount =
			this.state.importing && this.state.spreadsheet
				? this.state.spreadsheet.options && this.state.spreadsheet.options.first_row_is_header
					? this.state.spreadsheet.num_rows - 1
					: this.state.spreadsheet.num_rows
				: 0;
		const offset =
			this.state.importing && this.state.spreadsheet
				? this.state.spreadsheet.options && this.state.spreadsheet.options.first_row_is_header
					? this.state.spreadsheet.current_offset - 1
					: this.state.spreadsheet.current_offset
				: 0;

		const content = (() => {
			if (this.props.type == "board" && !this.props.board && !this.state.title) {
				return (
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("board.calllist.fields.name.label", "Namn för lista")}
								placeholder={this.props.t("board.calllist.fields.name.placeholder", "Namn..")}
								value={this.state.temp_title}
								onChange={(v) => {
									this.setState({ temp_title: v });
								}}
							/>
						</FormLayout>
					</Modal.Section>
				);
			}
			return (
				<React.Fragment>
					{this.state.importing ? (
						<Modal.Section>
							<ProgressBar progress={Math.round((this.state.spreadsheet.current_offset / this.state.spreadsheet.num_rows) * 100)} size="large" />

							{this.state.error && (
								<div style={{ padding: "0.6250rem" }}>
									<InlineError message={this.state.error} />
								</div>
							)}

							<div>
								{offset < rowsCount ? offset : rowsCount} / {rowsCount}
							</div>

							{this.state.showResult && this.state.spreadsheet && (
								<div className="infoview_result_count">
									<InfoView
										details={[
											{
												label: this.props.t("import.details.updated.label", "Uppdaterade"),
												component: (
													<div
														style={{
															display: "flex",
															alignItems: "center",
															gap: "0.6250rem",
														}}
													>
														<NumberBadge color={Colors.green}> {(this.state.updated && this.state.updated.length) || 0}</NumberBadge>
														{this.state.updated && !!this.state.updated.length && (
															<ExportToExcelButton spreadsheet={this.state.spreadsheet} data={this.state.updated} />
														)}
													</div>
												),
											},
											{
												label: this.props.t("import.details.created.label", "Skapade"),
												component: (
													<div
														style={{
															display: "flex",
															alignItems: "center",
															gap: "0.6250rem",
														}}
													>
														<NumberBadge color={Colors.lightblue}> {(this.state.created && this.state.created.length) || 0}</NumberBadge>
														{this.state.created && !!this.state.created.length && (
															<ExportToExcelButton spreadsheet={this.state.spreadsheet} data={this.state.created} />
														)}
													</div>
												),
											},
											{
												label: this.props.t("import.details.skipped.label", "Skippade"),
												component: (
													<div
														style={{
															display: "flex",
															alignItems: "center",
															gap: "0.6250rem",
														}}
													>
														<NumberBadge color={Colors.red}> {(this.state.skipped && this.state.skipped.length) || 0}</NumberBadge>{" "}
														{this.state.skipped && !!this.state.skipped.length && (
															<ExportToExcelButton spreadsheet={this.state.spreadsheet} data={this.state.skipped} />
														)}
													</div>
												),
											},
										]}
									/>
								</div>
							)}
						</Modal.Section>
					) : null}
					{(this.state.importType === "tags" || this.state.importType === "comments") && this.state.file && !this.state.importing && (
						<Modal.Section>
							<div className="boxes_wrapper">
								<ModalOptionBox
									title={this.getAdjectiveName().plural}
									icon={<NoteIcon />}
									description={this.props.t(
										"import.box.excel.description",
										`Vilken kolumn ska vi hämta ${this.getAdjectiveName().bestamd_plural} ifrån?`
									)}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "0.9375rem",
										}}
									>
										<VerticalFlex>
											{this.state.loading ? <Spinner wrap size={16} /> : <div>{this.getAdjectiveName().bestamd_singular.capitalize()}</div>}
											<Select
												options={[
													{
														label: this.props.t("import.terms.choose2", "--välj--"),
														value: "",
													},
												].concat(
													this.getSpreadsheetColumns().map((c, index) => ({
														label: c.title,
														value: String(index),
													}))
												)}
												onChange={(v) =>
													this.setState({
														spreadsheet_col_index: v,
														duplicate_rule: "update_only",
													})
												}
												value={this.state.spreadsheet_col_index ?? ""}
												disabled={!this.getSpreadsheetColumns().length}
											/>
										</VerticalFlex>
										{this.state.importType === "comments" && (
											<>
												<VerticalFlex>
													{this.state.loading ? <Spinner wrap size={16} /> : <div>Datumet</div>}
													<Select
														options={[
															{
																label: this.props.t("import.terms.choose2", "--välj--"),
																value: "",
															},
														].concat(
															this.getSpreadsheetColumns().map((c, index) => ({
																label: c.title,
																value: String(index),
															}))
														)}
														onChange={(v) => this.setState({ spreadsheet_col_date: v })}
														value={this.state.spreadsheet_col_date ?? ""}
														disabled={!this.getSpreadsheetColumns().length}
													/>
												</VerticalFlex>
												<VerticalFlex>
													{this.state.loading ? <Spinner wrap size={16} /> : <div>Person (ID)</div>}
													<Select
														options={[
															{
																label: this.props.t("import.terms.choose2", "--välj--"),
																value: "",
															},
														].concat(
															this.getSpreadsheetColumns().map((c, index) => ({
																label: c.title,
																value: String(index),
															}))
														)}
														onChange={(v) => this.setState({ spreadsheet_col_user_id: v })}
														value={this.state.spreadsheet_col_user_id ?? ""}
														disabled={!this.getSpreadsheetColumns().length}
													/>
												</VerticalFlex>
											</>
										)}
									</div>
								</ModalOptionBox>
								<div className="box_arrow">
									<Icon source={ChevronRightMinor} />
								</div>
								<ModalOptionBox
									title={this.props.t("import.box.contact.title", "Kontakt")}
									icon={<HorizontalConnectionIcon />}
									description={this.props.t(
										"import.box.contact.description",
										`Vad ska vi matcha ${this.props.type === "company" ? "företaget" : "kontakten"} mot?`
									)}
									style={{ justifyContent: "start" }}
								>
									<VerticalFlex style={{ marginTop: 16 }}>
										<div style={{ display: "flex", justifyContent: "center" }}>
											{this.state.loading ? <Spinner wrap size={16} /> : <img alt="excel" src="/assets/Excel.png" />}
											<SelectWrapper>
												<Select
													options={[
														{
															label: this.props.t("import.terms.choose2", "--välj--"),
															value: "",
														},
													].concat(
														this.getSpreadsheetColumns().map((c, index) => ({
															label: c.title,
															value: String(index),
														}))
													)}
													onChange={(v) => {
														this.setState({
															mappings: {
																[v]: (this.state.mappings && this.state.mappings[v]) || "",
															},
															duplicate_column: String(v),
														});
													}}
													value={(Object.keys(this.state.mappings) && Object.keys(this.state.mappings)[0]) ?? ""}
													disabled={!this.getSpreadsheetColumns().length || !this.state.spreadsheet_col_index}
												/>
											</SelectWrapper>
										</div>
										<div style={{ marginLeft: "35px", textAlign: "center" }}>{this.props.t("import.terms.against", "Mot")}</div>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<img alt="sellfinity" src="/assets/s_logo.png" />
											<SelectWrapper>
												<Select
													options={[
														{
															label: this.props.t("import.terms.choose2", "--välj--"),
															value: "",
														},
													].concat(
														this.getMappingTargets().filter(({ value }) =>
															this.props.type === "people"
																? ["email", "phone", "reference"].includes(value)
																: ["reference", "name", "email", "phone", "orgnr"].includes(value)
														)
													)}
													disabled={!Object.keys(this.state.mappings).length}
													onChange={(v) => {
														const key = Object.keys(this.state.mappings)[0];
														this.state.mappings[key] = v;
														this.setState({
															mappings: this.state.mappings,
														});
													}}
													value={(Object.values(this.state.mappings) && Object.values(this.state.mappings)[0]) ?? ""}
												/>
											</SelectWrapper>
										</div>
									</VerticalFlex>
								</ModalOptionBox>
							</div>
						</Modal.Section>
					)}
					{this.state.importing && this.state.spreadsheet.blocked ? (
						<Modal.Section>
							<div>
								<Banner
									title={this.props.t("import.terms.all_contacts_not_imported", "Alla kontakter importerades ej")}
									action={{
										content: this.props.t("import.actions.upgrade_package", "Uppgradera paket"),
										onAction: () => {
											this.setState({ showPackageModal: true, type: "emails" });
										},
									}}
									status="warning"
								>
									<p>
										{`

									${this.state.spreadsheet.blocked} ${this.props.t("import.errors.limit_reached", "kontakter importerades ej, ert konto stödjer högst")} ${
											this.state.limits?.emails_limit || "-"
										} ${this.props.t("import.terms.email_contacts", "e-postkontakter")}
										`}
									</p>
								</Banner>
								<UsagePackageModal
									open={this.state.showPackageModal}
									onClose={() => this.setState({ showPackageModal: false })}
									type={this.state.type}
									limits={this.state.limits}
								/>
							</div>
						</Modal.Section>
					) : null}
					{this.state.show_duplicate_settings && !this.state.importing ? (
						<Modal.Section>
							<Layout>
								<Layout.Section oneHalf>
									<Text variant="headingMd" as="h2">
										{this.props.t("import.terms.what_tags", "Vad vill du tagga kontakterna med?")}
									</Text>
									<TextField label="Namn på tagg" value={this.state.tag_name} onChange={(v) => this.setState({ tag_name: v })} />
								</Layout.Section>
								{this.props.asVisitor ? (
									<Layout.Section oneHalf>
										<Text variant="headingMd" as="h2">
											{this.props.t("import.terms.choose_audience_group", "Välj målgrupp")}
										</Text>
										{this.state.audiences.map((audience, index) => {
											const exceptions = [];
											if (audience.filters.length != 1 || (audience.filters.length > 0 && audience.filters[0].key != "tagged")) {
												exceptions.push({
													icon: CancelSmallMinor,
													description: this.props.t("import.terms.other_filter_than_tags", "Har andra filter än taggar"),
												});
											}

											const exceptionList = exceptions.length ? (
												<div className="CustomerListItem__Exceptions">
													<ExceptionList items={exceptions} />
												</div>
											) : null;

											return (
												<ResourceItem key={audience.id || index} onClick={this.selectAudience.bind(this, audience)}>
													<div className="CustomerListItem__Profile">
														<h3 className="CustomerListItem__Title">{audience.title}</h3>
														<span className="CustomerListItem__Location">{audience.count}</span>
													</div>
													{exceptionList}
												</ResourceItem>
											);
										})}
									</Layout.Section>
								) : null}
							</Layout>
						</Modal.Section>
					) : null}
					{this.state.show_duplicate_settings && !this.state.importing && !this.props.asVisitor ? (
						<Modal.Section>
							<Text variant="headingMd" as="h2">
								{this.props.t("import.fields.duplicate_heading", "Hur ska dubletter hanteras?")}
							</Text>
							<Layout>
								<Layout.Section oneThird>
									<div style={{ position: "relative" }} onClick={() => this.setState({ duplicate_rule: "create" })}>
										<LegacyCard title={this.props.t("import.fields.add_as_new.title", "Skapa nya")}>
											<LegacyCard.Section>
												{this.state.duplicate_rule == "create" ? (
													<div style={{ position: "absolute", top: 0, right: 0 }}>
														<Icon source={CircleTickMajor} color="green" />
													</div>
												) : null}
												{this.props.t("import.fields.add_as_new.text", "Allting kommer att läggas till som nya")}
											</LegacyCard.Section>
										</LegacyCard>
									</div>
								</Layout.Section>
								<Layout.Section oneThird>
									<div
										style={{ position: "relative" }}
										onClick={() =>
											this.setState({
												duplicate_rule: "skip",
												duplicate_column: this.getMappedColumnOptions()[0].value,
											})
										}
									>
										<LegacyCard title={this.props.t("import.fields.duplicate_rule_skip.title", "Hoppa över")}>
											<LegacyCard.Section>
												{this.state.duplicate_rule == "skip" ? (
													<div style={{ position: "absolute", top: 0, right: 0 }}>
														<Icon source={CircleTickMajor} color="green" />
													</div>
												) : null}
												{this.props.t("import.fields.duplicate_rule_skip.text", "Hoppa över dubbletter baserat kolumn")}
											</LegacyCard.Section>
											{this.state.duplicate_rule == "skip" ? (
												<LegacyCard.Section>
													<span
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
														}}
													>
														<Select
															requiredIndicator
															options={this.getMappedColumnOptions()}
															onChange={(v) => this.setState({ duplicate_column: v })}
															value={this.state.duplicate_column ?? ""}
														/>
													</span>
												</LegacyCard.Section>
											) : null}
										</LegacyCard>
									</div>
								</Layout.Section>
								<Layout.Section oneThird>
									<div
										style={{ position: "relative" }}
										onClick={() =>
											this.setState({
												duplicate_rule: "update",
												duplicate_column: this.getMappedColumnOptions()[0].value,
											})
										}
									>
										<LegacyCard title={this.props.t("import.fields.duplicate_rule_update.title", "Uppdatera befintliga")}>
											<LegacyCard.Section>
												{this.state.duplicate_rule == "update" ? (
													<div style={{ position: "absolute", top: 0, right: 0 }}>
														<Icon source={CircleTickMajor} color="green" />
													</div>
												) : null}
												{this.props.t("import.fields.duplicate_rule_update.text", "Uppdatera dubbletter baserat kolumn")}
											</LegacyCard.Section>
											{this.state.duplicate_rule == "update" ? (
												<LegacyCard.Section>
													<span
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
														}}
													>
														<Select
															options={this.getMappedColumnOptions()}
															onChange={(v) => this.setState({ duplicate_column: v })}
															value={this.state.duplicate_column ?? ""}
														/>
													</span>
												</LegacyCard.Section>
											) : null}
										</LegacyCard>
									</div>
								</Layout.Section>
							</Layout>
						</Modal.Section>
					) : null}
					{this.state.sheets.length &&
					!this.state.show_duplicate_settings &&
					!this.state.importing &&
					!["tags", "comments"].includes(this.state.importType) ? (
						<React.Fragment>
							<Modal.Section>
								<Checkbox
									label={this.props.t("import.fields.first_row_is_header.label", "Använd första raden som rubrik")}
									checked={this.state.first_row_is_header}
									onChange={(v) => this.setState({ first_row_is_header: v })}
								/>
							</Modal.Section>
							<div style={{ padding: "1.2500rem" }}>
								<LegacyStack distribution="fillEvenly">
									<Text variant="headingSm" el="h3">
										Extern kolumn
									</Text>
									<div />
									<Text variant="headingSm" el="h3">
										Board kolumn
									</Text>
								</LegacyStack>
							</div>
							<Modal.Section>
								<Mappings
									alternateBackgrundColors={["var(--tertiary)", "transparent"]}
									mappings={this.state.mappings}
									mappStyle={{ padding: 3 }}
									sourceMappings={this.getSpreadsheetColumns()?.map((m, index) => ({
										...m,
										value: index,
										options: this.getMappingTargetOptions(index),
									}))}
									onChange={(mappings) => {
										const newMappings = Object.entries(mappings)?.reduce((acc, [spreadsheetColumnIndex, boardColumnId]) => {
											if (this.props.asVisitor && boardColumnId == "email") {
												this.setState({ duplicate_column: spreadsheetColumnIndex + "" });
											}

											if (boardColumnId !== "" && boardColumnId !== null) {
												acc[spreadsheetColumnIndex + ""] = boardColumnId;
											} else if (spreadsheetColumnIndex + "" in this.state.mappings) {
												delete acc[spreadsheetColumnIndex + ""];
											}

											return acc;
										}, {});

										this.setState({ mappings: newMappings });
									}}
								/>
							</Modal.Section>
						</React.Fragment>
					) : null}
					{this.state.loading && !["tags", "comments"].includes(this.state.importType) ? (
						<Modal.Section>
							<Spinner wrap size="large" />
						</Modal.Section>
					) : null}
					{!this.state.file ? (
						<Modal.Section>
							<Upload width="100%" height={300} size="large" type="spreadsheet" onUpload={this.uploadFile.bind(this)} />
						</Modal.Section>
					) : null}
					{!["tags", "comments"].includes(this.state.importType) && <Modal.Section>{missingFields}</Modal.Section>}
				</React.Fragment>
			);
		})();

		if (this.props.noPopup || this.props.noModal) {
			return (
				<div>
					{content}

					<Button onClick={this.props.onClose}>{this.props.t("common.actions.close", "Stäng")}</Button>
					{!this.state.importing && primaryAction && (
						<Button onClick={primaryAction.onAction} disabled={primaryAction.disabled} loading={primaryAction.loading}>
							{primaryAction.content}
						</Button>
					)}
				</div>
			);
		}

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				large
				title={title}
				primaryAction={!this.state.importing ? primaryAction : null}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				{(this.props.type == "company" || this.props.type == "people") && !this.state.importType ? this.getImportTypeSelector() : content}
			</Modal>
		);
	}
}
export default withTranslation(["import", "common"], { withRef: true })(ImportModal);

const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.3125rem;
`;

const SelectWrapper = styled.div`
	&,
	& > div {
		width: 100%;
	}
`;
