import React, { useMemo, useState } from "react";
import { ActionList, ButtonGroup, Button as PolarisButton } from "@shopify/polaris";
import Modal from "../modal";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { checkUserRoles } from "src/js/Utilities";
import Popover from "../Popover";
import { ChevronDownMinor } from "@shopify/polaris-icons";

const Button = withTranslation(["common"])(
	withRouter(({ children, connectedDisclosure: propsConnectedDisclosure, history, t, roles, ...props }: any) => {
		if (!checkUserRoles(roles)) return null;

		const [item, setItem] = useState<any>(null);
		const [confirmIsLoading, setConfirmIsLoading] = useState(false);
		const [isLoading, setIsLoading] = useState(false);

		const connectedDisclosure = useMemo(() => {
			if (!propsConnectedDisclosure) return undefined;

			return {
				...propsConnectedDisclosure,
				actions: propsConnectedDisclosure?.actions
					?.filter((item) => !("enabled" in item) || item.enabled)
					?.map((item) => ({
						...item,
						onAction: () => {
							if (item.confirmation) {
								setItem(item);
								return;
							}

							item.onAction();
						},
					})),
			};
		}, [propsConnectedDisclosure]);

		const handleAction = async () => {
			try {
				setConfirmIsLoading(true);

				if (item.url) {
					if (item.url?.startsWith("http") || item.url?.startsWith("www")) {
						window.open(item.url, "_blank");
					} else {
						history.push(item.url);
					}
				} else {
					if (item.onClick) await item.onClick();
					if (item.onAction) await item.onAction();
				}
			} catch (error) {
				console.error("error:", error);
			} finally {
				setConfirmIsLoading(false);
			}

			setItem(null);
		};

		const confirm = {
			showButtons: true,
			sectioned: true,
			...(item?.confirmation || item?.confirm || {}),
		};

		const text = confirm?.content === undefined ? `${item?.content}?` : confirm?.content || confirm?.text || confirm?.message;

		const mainButtonProps = {
			...props,
		};

		const handleMainAction = async () => {
			if (props?.confirmation || props?.confirm) {
				setItem(props);
				return;
			}

			try {
				setIsLoading(true);
				await props.onClick?.();
				await props.onAction?.();
			} catch (e) {
				console.error("Button handleMainAction error", e);
			} finally {
				setIsLoading(false);
			}
		};

		if (props.onClick || props.onAction) {
			mainButtonProps.onClick = handleMainAction;
		}

		const button = (
			<PolarisButton {...mainButtonProps} loading={mainButtonProps.loading || isLoading}>
				{children}
			</PolarisButton>
		);

		return (
			<>
				{!connectedDisclosure ? (
					button
				) : (
					<ButtonGroup segmented>
						{button}

						<Popover
							preferredAlignment="right"
							activator={<PolarisButton primary={mainButtonProps.primary} destructive={mainButtonProps.destructive} icon={ChevronDownMinor} />}
						>
							<ActionList items={connectedDisclosure.actions} actionRole="menuitem" />
						</Popover>
					</ButtonGroup>
				)}

				<Modal
					open={!!item}
					onClose={() => setItem(null)}
					title={confirm?.title || item?.content}
					primaryAction={
						!confirm?.showButtons
							? undefined
							: {
									...item,
									content: confirm?.confirm || item?.content,
									disabled: confirm?.disabled,
									onAction: handleAction,
									loading: item?.loading || confirmIsLoading,
							  }
					}
					secondaryActions={[
						{
							content: confirm?.cancel || t("common.actions.cancel", "Avbryt"),
							onAction: () => setItem(null),
						},
					]}
					sectioned={confirm?.sectioned}
				>
					{text}
				</Modal>
			</>
		);
	})
);
export default Button;
