const getTargetConnectionsWithData = (targetConnections) => {
	if (!targetConnections) return [];
	return targetConnections.map((targetConnection, index) => ({
		...targetConnection,
		id: (() => {
			if (targetConnection.id) return targetConnection.id;

			if (targetConnection.user) return `user_${targetConnection.user.id}`;
			if (targetConnection.user_group) return `user_group_${targetConnection.user_group.id}`;
			return `targetConnection_is_value_${index}`;
		})(),
	}));
};

export default getTargetConnectionsWithData;
