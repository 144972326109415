import React, { useContext } from "react";
import { OverviewWrapper } from "../styles";
import TextField from "src/js/components/TextField";
import { LegacyCard, FormLayout, Layout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import OrderContext from "../OrderContext";
import Select from "src/js/components/select/Select";
import { store } from "src/js/store";

type OrderTypeCustomerKeys = keyof OrderType["customer_fields"];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type OrderTypeCustomerMutableKeys = Exclude<OrderTypeCustomerKeys, "readonly">;

const CustomerOverview = ({ t }) => {
	const { form, setForm, disabled } = useContext(OrderContext);

	const getChangeHandler = (field: any) => (value: string | boolean) => {
		setForm((c: Partial<OrderType>) => {
			const newForm = { ...c };
			if (!newForm.customer_fields) newForm.customer_fields = {};
			newForm.customer_fields[field as string] = value;

			return newForm;
		});
	};

	return (
		<Wrapper>
			<LegacyCard sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<TextField
								required
								disabled={disabled}
								label={t("orders.customer.fields.name.label", "Namn")}
								value={form?.customer_fields?.name}
								onChange={getChangeHandler("name")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.organisation_number.label", "Organisationsnummer")}
								value={form?.customer_fields?.organisation_number}
								onChange={getChangeHandler("organisation_number")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.address.label", "Adress")}
								value={form?.customer_fields?.address}
								onChange={getChangeHandler("address")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.address2.label", "Adress2")}
								value={form?.customer_fields?.address2}
								onChange={getChangeHandler("address2")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.postal_code.label", "Postnummer")}
								value={form?.customer_fields?.postal_code}
								onChange={getChangeHandler("postal_code")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.city.label", "Stad")}
								value={form?.customer_fields?.city}
								onChange={getChangeHandler("city")}
							/>
							<Select
								disabled={disabled}
								label={t("orders.customer.fields.country.label", "Land")}
								options={[{ label: "Välj land", value: "" }, ...store.getState().countries.map((c) => ({ label: c.label, value: c.label }))]}
								value={form?.customer_fields?.country}
								onChange={getChangeHandler("country")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.phone.label", "Telefon")}
								value={form?.customer_fields?.phone}
								onChange={getChangeHandler("phone")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.customer.fields.email.label", "E-post")}
								value={form?.customer_fields?.email}
								onChange={getChangeHandler("email")}
							/>
						</FormLayout>
					</Layout.Section>
				</Layout>
			</LegacyCard>
		</Wrapper>
	);
};

export default withTranslation(["orders", "common"])(CustomerOverview);

const Wrapper = styled(OverviewWrapper)`
	&&&& {
		.info-view__labels,
		.info-view__values {
			row-gap: 3px;
		}

		.Polaris-TextField {
			.Polaris-Button,
			.Polaris-Spinner {
				display: flex;
			}
		}
	}
`;
