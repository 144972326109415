import React, { Component } from "react";
import { Checkbox } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import moment from "moment";
import DatepickerHelper from "./DatepickerHelper";

class TimeCheckbox extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getStartDate() {
		const v = DatepickerHelper.getDate(this.props.value.start);
		return v && v.split(" ")[0];
	}

	getEndDate() {
		const v = DatepickerHelper.getDate(this.props.value.end);
		return v && v.split(" ")[0];
	}

	render() {
		return (
			<div className="time_checkbox">
				<Checkbox
					label={this.props.t("date.terms.whole_day", "Heldag")}
					checked={!DatepickerHelper.haveTime(this.props.value)}
					onChange={(v) => {
						if (this.props.value && typeof this.props.value === "object" && "start" in this.props.value) {
							this.props.onChange({
								start: this.getStartDate() + (v ? "" : ` ${moment().format("HH:mm")}`),
								end: this.getEndDate() + (v ? "" : ` ${moment().add(1, "h").format("HH:mm")}`),
							});
						} else {
							this.props.onChange(
								(DatepickerHelper.getDate(this.props.value) || moment().format("YYYY-MM-DD")) + (v ? "" : ` ${moment().format("HH:mm")}`)
							);
						}
					}}
				/>
			</div>
		);
	}
}

export default withTranslation(["date"], { withRef: true })(TimeCheckbox);
