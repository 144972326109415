import { Collapsible, Icon } from "@shopify/polaris";
import React, { Component } from "react";

class Field extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { label, component, collapsed, disabled, className, icon } = this.props.field || {};
		const content = (
			<div className={`calendar_modal__body__field ${collapsed ? "collapsed" : ""} ${disabled ? "disabled" : ""} ${className || ""}`}>
				{label && (
					<span className="calendar_modal__body__field__label">
						{!icon || React.isValidElement(icon) ? icon : <Icon source={icon} />}
						{label}
					</span>
				)}
				{component}
			</div>
		);

		if ("collapsed" in this.props.field) {
			return (
				<Collapsible open={!collapsed}>{content}</Collapsible>
			);
		}

		return content;
	}
}
export default Field;
