import React, { Component } from "react";
import { Badge, Text, LegacyStack, ResourceList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import IndexTable from "src/js/components/IndexTable/IndexTable";

class GoogleIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderItem(item) {
		return (
			<ResourceList.Item
				id={item.id}
				onClick={() => {
					window.location.href = "/api/google/accounts/" + item.id + ".json?name=" + item.name;
				}}
			>
				<LegacyStack>
					<div>{item.name}</div>
					{item.mcc ? <Badge>mcc-konto</Badge> : null}
				</LegacyStack>
				<div>
					<Text variation="subdued">{item.id}</Text>
				</div>
			</ResourceList.Item>
		);
	}

	render() {
		return (
			<div>
				<IndexTable
					history={this.props.history}
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					title={this.props.t("ad_accounts.facebook.title", "Annonskonton på google")}
					resourceUrl="/api/google/accounts.json"
					resourceHandle="accounts"
					resourceName={{
						singular: this.props.t("ad_accounts.google.singular", "annonskonto"),
						plural: this.props.t("ad_accounts.google.plural", "annonskonton"),
					}}
					noHeader
					columns={[{ render: this.renderItem.bind(this) }]}
					onSelectionChange={this.onSelectionChange.bind(this)}
				/>
			</div>
		);
	}
}

export default withTranslation(["google", "common"], { withRef: true })(GoogleIndex);
