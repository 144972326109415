import { IncomingMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "src/js/components/Button";
import EmailListSheetContent from "src/js/components/Emails/EmailListSheetContent";

const SelfContainedEmailImportModal = ({ contact }) => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation(["contact", "common"]);
	const user = useSelector((state: any) => state.user);

	const integration = user.integrations.find((i) => i.type === "microsoft365");
	if (!integration || !contact?.email) return null;

	return (
		<>
			<Button icon={IncomingMajor} plain onClick={() => setOpen(true)}>
				{t("contact.integration.microsoft.links.import", "Importera")}
			</Button>
			<EmailListSheetContent
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				contact={contact}
				integration={user.integrations.find((integration) => integration.type === "microsoft365")}
			/>
		</>
	);
};
export default SelfContainedEmailImportModal;
