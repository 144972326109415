import React, { Component } from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { Handle } from "reactflow";
import { MobilePlusMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";

class MainNode extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { data } = this.props;
		return (
			<div className={"inner_wrapper" + (!data.have_parent ? " main" : "") + (!data.have_children ? " leaf" : "")} onClick={data.onClick}>
				{data.title}
				{data.have_parent && <Handle type="target" id="top" position="top" />}
				{data.have_children && <Handle type="source" id="bottom" position="bottom" />}
				<span
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Tooltip content={this.props.t("automation.node.actions.create", "Skapa ny")}>
						<div className="add-btn" onClick={data.onCreate}>
							<Icon source={MobilePlusMajor} />
						</div>
					</Tooltip>
				</span>
			</div>
		);
	}
}

export default withTranslation(["automation", "common"], { withRef: true })(MainNode);
