/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import FortnoxBillingContractsContactList from "./FortnoxBillingContractsContactList";

type FortnoxBillingContractsListProps = {
	history: any;
} & WithTranslation;

const FortnoxBillingContractsList = ({ ...props }: FortnoxBillingContractsListProps) => {
	return (
		<Page title={props.t("fortnox.billingContracts.title", "Avtalsfaktureringar") as string} history={props.history}>
			<FortnoxBillingContractsContactList {...props} history={props.history} />
		</Page>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxBillingContractsList);
