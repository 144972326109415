import React, { Component } from "react";
import { Collapsible, Icon } from "@shopify/polaris";
import {
	GrammarMajor,
	CircleAlertMajor,
	ChatMajor,
	CircleDotsMajor,
	CircleTickMajor,
	CircleDisabledMajor,
	CircleCancelMajor,
} from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { getIconByString } from "../views/Workspaces/components/IconSelector.js";
import { store } from "../store";

class BoardWithGroups extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getLinks() {
		return Object.values(store.getState().board_groups)
			.filter((grp) => grp.board_id == this.props.board.id)
			.map((group) => this.createLink(group));
	}

	createLink(group) {
		const filter = [{ key: "group-" + group.id, value: [group.id], board_id: this.props.board.id, column_id: "group" }];
		const url = "/admin/workspaces/" + this.props.workspace.id + "/boards/" + this.props.board.id + "#" + JSON.stringify(filter);
		let icon = GrammarMajor;
		if (group.title == "Nya ärenden") {
			icon = CircleAlertMajor;
		} else if (group.title == "Öppna ärenden") {
			icon = ChatMajor;
		} else if (group.title == "Väntande ärenden") {
			icon = CircleDotsMajor;
		} else if (group.title == "Avslutade ärenden") {
			icon = CircleTickMajor;
		} else if (group.title == "Markerat som spam") {
			icon = CircleDisabledMajor;
		} else if (group.title == "Borttagna ärenden") {
			icon = CircleCancelMajor;
		}

		const count = Object.values(store.getState().board_rows).filter((row) => row.group_id == group.id).length;
		let active = false;
		if (window.location.hash.length > 0) {
			const loc = this.props.location.pathname + decodeURIComponent(window.location.hash);
			active = loc.indexOf(url) >= 0;
		}
		return (
			<li key={group.id} className="Polaris-Navigation__ListItem">
				<div
					onClick={() => {
						this.props.history.push(url);
					}}
					className={"mainmenu-item__secondary__item " + (active ? " Polaris-Navigation__Item--selected" : "")}
				>
					<div>
						<Icon source={icon} />

						{group.title}
					</div>
					{count && count > 0 ? (
						<div className="Polaris-Navigation__Badge">
							<span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">
								<span className="Polaris-VisuallyHidden" />
								<span className="Polaris-Badge__Content">{count}</span>
							</span>
						</div>
					) : null}
				</div>
			</li>
		);
	}

	expand() {
		if (!this.isActive()) {
			const url = "/admin/workspaces/" + this.props.workspace.id + "/boards/" + this.props.board.id;
			this.props.history.push(url);
		}
	}

	isExpanded() {
		if (this.props.expanded) {
			return true;
		}
		return this.isActive();
	}

	isActive() {
		const url = "/admin/workspaces/" + this.props.workspace.id + "/boards/" + this.props.board.id;
		const active = url.indexOf(this.props.location.pathname) >= 0;
		return active;
	}

	render() {
		if (this.props.expanded) {
			return (
				<React.Fragment>
					{this.getLinks()}
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<div {...this.props.dragHandleProps} />
				</React.Fragment>
			);
		}

		const icon = getIconByString(this.props.board.icon);

		return (
			<React.Fragment>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<li className="Polaris-Navigation__ListItem" {...this.props.dragHandleProps}>
					<div
						onClick={this.expand.bind(this)}
						className={"mainmenu-item__secondary__item" + (this.isExpanded() ? " Polaris-Navigation__Item--selected" : "")}
					>
						<div>
							<Icon source={icon} />

							{this.props.board.title}
						</div>
						{!this.isExpanded() && this.props.board.count && this.props.board.count > 0 ? (
							<div className="Polaris-Navigation__Badge">
								<span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">
									<span className="Polaris-VisuallyHidden" />
									<span className="Polaris-Badge__Content">{this.props.board.count}</span>
								</span>
							</div>
						) : null}
					</div>
				</li>
				<Collapsible open={this.isExpanded()}>
					<div style={{ paddingLeft: 10 }}>{this.getLinks()}</div>
				</Collapsible>
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		workspaces: state.workspaces,
		rows: state.board_rows,
		groups: state.board_groups,
		favorite_boards: state.favorite_boards,
	}),
	{}
)(BoardWithGroups);
