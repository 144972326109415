import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import NumberBadge from "../../components/NumberBadge";
import IndexTable from "src/js/components/IndexTable";

const LanguagesIndex = ({ t, history }) => {
	return (
		<IndexTable
			backAction={{ content: t("common.actions.back", "Tillbaka"), onAction: () => history.goBack() }}
			title={t("common.language.title", "Språk")}
			savedSearchHandle="languages"
			resourceUrl="/api/translations.json"
			resourceHandle="languages"
			resourceName={{
				singular: t("common.language.singluar", "språk"),
				plural: t("common.language.plural", "språk"),
			}}
			onClick={(item) => {
				const url = "/admin/languages/" + item.locale;
				history.push(url);
			}}
			columns={[
				{
					handle: "TITLE",
					label: t("common.language.columns.title", "Titel"),
					render: (item) => `${item.title} - ${item.locale}`,
				},
				{
					handle: "COUNT",
					label: t("common.language.columns.count", "Antal översättningar"),
					render: (item) => <NumberBadge>{item.count}</NumberBadge>,
				},
				{
					handle: "CREATED_AT",
					label: t("common.language.columns.created_at", "skapad"),
					render: (item) => moment(item.created_at).format("YYYY-MM-DD"),
				},
			]}
			history={history}
			// defaultSavedSearches={[
			// 	{
			// 		title: "Verifierade",
			// 		filters: [
			// 			{
			// 				key: "verified",
			// 				value: 1,
			// 			},
			// 		],
			// 		sorting: "ID_DESC",
			// 		search: "",
			// 		id: "default_active",
			// 	},
			// ]}
		/>
	);
};
export default withTranslation(["common"])(LanguagesIndex);
