import { useState, useEffect, useRef } from "react";

const useAnimatedLiveNumber = (value: number, duration = 1000, enabled = true) => {
	const [displayValue, setDisplayValue] = useState(value);
	const previousValue = useRef(value);
	const animationTimer = useRef<any | null>(null);

	useEffect(() => {
		if (!enabled) setDisplayValue(value);
		if (enabled && previousValue.current !== value) {
			const stepTime = 100;
			const totalSteps = Math.round(duration / stepTime);
			const valueDifference = value - previousValue.current;
			const stepValue = valueDifference / totalSteps;

			let currentStep = 0;

			const animateValue = () => {
				setDisplayValue((prevValue) => prevValue + stepValue);
				currentStep += 1;

				if (currentStep < totalSteps) {
					animationTimer.current = setTimeout(animateValue, stepTime);
				} else {
					setDisplayValue(value); // Ensure final value is set correctly
					previousValue.current = value;
				}
			};

			clearTimeout(animationTimer.current);
			animateValue();

			return () => clearTimeout(animationTimer.current);
		}
	}, [value, duration, enabled]);

	return Math.round(displayValue);
};

export default useAnimatedLiveNumber;
