import React, { useContext } from "react";
import { NotificationMajor } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import Colors from "../../Colors.js";
import NotificationContext from "../../ContextProviders/NotificationContext";
import NavigationHeaderButton from "../../components/NavigationHeaderButton.js";

const Notifications = () => {
	const { toggleNotificationSheet, unreadCount } = useContext(NotificationContext);
	const { t } = useTranslation(["notifications", "common"]);

	return (
		<React.Fragment>
			<NavigationHeaderButton
				tooltip={t("common.terms.notifications", "Notifikationer")}
				onClick={toggleNotificationSheet(true)}
				number={unreadCount}
				color={Colors.red}
				icon={NotificationMajor}
			/>
		</React.Fragment>
	);
};

export default Notifications;
