import React, { Component } from "react";
import { ExceptionList, ResourceList } from "@shopify/polaris";
import moment from "moment";
import { NoteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import IndexTable from "src/js/components/IndexTable/IndexTable";

class Triggers extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderItem(item) {
		const url = "/admin/triggers/" + item.id;
		const exceptions = [];

		if (item.content) {
			exceptions.push({
				icon: NoteMajor,
				description: item.content,
			});
		}

		const exceptionList = exceptions.length ? (
			<div className="CustomerListItem__Exceptions">
				<ExceptionList items={exceptions} />
			</div>
		) : null;

		return (
			<ResourceList.Item id={item.id} url={url}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						<span className="CustomerListItem__Location" />
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				{moment(item.created_at).format("YYYY-MM-DD")}
				{exceptionList}
			</ResourceList.Item>
		);
	}

	render() {
		const filters = [
			{
				key: "due",
				label: this.props.t("triggers.automations.filters.due.label", "Aktiv"),
				type: "select",
				options: [
					{ value: "1", label: this.props.t("common.terms.yes", "Ja") },
					{ value: "0", label: this.props.t("common.terms.no", "Nej") },
				],
			},
			{
				key: "complete",
				label: this.props.t("triggers.automations.filters.complete.label", "Färdig"),
				type: "select",
				options: [
					{ value: "1", label: this.props.t("common.terms.yes", "Ja") },
					{ value: "0", label: this.props.t("common.terms.no", "Nej") },
				],
			},
		];
		return (
			<div>
				<IndexTable
					backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
					history={this.props.history}
					title={this.props.t("triggers.automations.title", "Automationer")}
					primaryAction={{ content: this.props.t("triggers.automations.actions.new", "Ny automation"), url: "/admin/triggers/new" }}
					savedSearchHandle="triggers"
					resourceUrl="/api/triggers.json"
					resourceHandle="triggers"
					resourceName={{
						singular: this.props.t("triggers.automations.singular", "automation"),
						plural: this.props.t("triggers.automations.plural", "automationer"),
					}}
					columns={[
						{
							handle: "",
							label: "",
							render: this.renderItem.bind(this),
						},
					]}
					filters={filters}
				/>
			</div>
		);
	}
}

export default withTranslation(["triggers", "common"], { withRef: true })(Triggers);
