import { Checkbox, FormLayout } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import TextField from "../TextField";
import Modal from "../modal";

function SavedSearchModal({ open, view: propsView, onClose, onSave, appliedFilters, search }) {
	const [view, setView] = useState(propsView);
	const [includeCurrentFilters, setIncludeCurrentFilters] = useState(!propsView?.id);
	// const [overwriteOtherSavedSearch, setOverwriteOtherSavedSearch] = useState(false);

	const handleSave = async () => {
		const newView = {
			...view,
		};

		if (includeCurrentFilters) {
			newView.filters = appliedFilters;
			newView.search = search;
		}

		const res = await onSave(newView);
		setView(newView);
		return res;
	};

	const handleChangeTitle = useCallback((value) => {
		setView((view: any) => ({ ...view, title: value }));
	}, []);

	useEffect(() => {
		if (open) {
			setView(propsView);
			setIncludeCurrentFilters(!propsView?.id);
		} else {
			setView(null);
			setIncludeCurrentFilters(false);
		}
	}, [propsView, open]);

	return (
		<Modal
			title={`Redigera sparad sökning "${propsView?.title}"`}
			open={open}
			onClose={onClose}
			primaryAction={{ content: "Spara", onAction: handleSave }}
			secondaryActions={[{ content: "Avbryt", onAction: onClose }]}
			sectioned
		>
			<FormLayout>
				<TextField placeholder="Title.." onChange={handleChangeTitle} value={view?.title} />
				<Checkbox label="Inkludera nuvarande filters och sökning" checked={includeCurrentFilters} onChange={setIncludeCurrentFilters} />

				{/* <Checkbox label="Skriv över befintlig sökning" checked={overwriteOtherSavedSearch} onChange={setOverwriteOtherSavedSearch} />
					{overwriteOtherSavedSearch && <p>asd</p>} */}
			</FormLayout>
		</Modal>
	);
}
export default SavedSearchModal;
