/* eslint-disable no-underscore-dangle */
import { fabric } from "fabric";

const Table = fabric.util.createClass(fabric.Group, {
	type: "table",
	data: {},
	subTargetCheck: true,
	objectCaching: false,

	// updateClipPaths() {
	// 	const scaleX = this.scaleX;
	// 	const scaleY = this.scaleY;

	// 	// Update all clipPaths within the group
	// 	this.forEachObject(function (obj) {
	// 		if (obj.clipPath) {
	// 			// obj.clipPath.zoomX = scaleX;
	// 			// obj.clipPath.zoomY = scaleY;

	// 			obj.clipPath.left = obj.left;
	// 			obj.clipPath.top = obj.top;
	// 			obj.clipPath.width = obj.width;
	// 			obj.clipPath.height = obj.height;

	// 			// obj.clipPath.scaleX = scaleX;
	// 			// obj.clipPath.scaleY = scaleY;
	// 			// obj.clipPath.width *= scaleX;
	// 			// obj.clipPath.height *= scaleY;
	// 			obj.clipPath.setCoords();
	// 		}
	// 	});

	// 	this.setCoords(); // Recalculate coordinates after adjustments
	// },

	initialize(data) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { options, rows, columns } = data || {};
		if (!data?.numRows) data.numRows = rows?.length || 1;

		this.data = data || {};
		this.callSuper("initialize", [], options);

		// this.on("scaling", this.updateClipPaths); // Event listener for scaling
		this.createTable();

		this.on("scaling", this.handleScaling.bind(this));
	},

	clearTable() {
		while (this._objects.length) {
			this.remove(this._objects[0]);
		}
	},

	recreateTable(data) {
		this.data = data;

		this.createTable();
	},

	createTable() {
		const scaleX = this.scaleX;
		const scaleY = this.scaleY;
		const left = this.left;
		const top = this.top;
		const angle = this.angle;

		this.clearTable();

		const {
			options: {
				cellHeight,
				cellHeightAutoAdjust,
				cellBackgroundColor,
				textColor,
				fontSize,
				fontFamily,
				cellSpacing,
				cellPadding = { horizontal: 0, vertical: 0 },
			} = {
				cellHeight: 30,
				cellHeightAutoAdjust: false,
				cellBackgroundColor: "white",
				textColor: "black",
				fontSize: 12,
				fontFamily: "",
				cellSpacing: { horizontal: 5, vertical: 5 },
				cellPadding: { horizontal: 5, vertical: 5 },
			},
			columns: bareColumns,
			rows,
		} = this.data;

		const normalizedCellSpacing = typeof cellSpacing === "number" ? { horizontal: cellSpacing, vertical: cellSpacing } : cellSpacing;
		const normalizedCellPadding = typeof cellPadding === "number" ? { horizontal: cellPadding, vertical: cellPadding } : cellPadding;

		const columns = bareColumns.map((c, i) => {
			const cellWidth = parseInt(c.width);
			const cellWidthWithPadding = parseInt(c.width) + normalizedCellPadding.horizontal * 2;
			const totalCellWidth = parseInt(c.width) + normalizedCellPadding.horizontal * 2 + normalizedCellSpacing.horizontal;
			const cellWidthWithPaddingAndSpacing = cellWidthWithPadding + (i === 0 ? 0 : normalizedCellSpacing.horizontal);

			const left = bareColumns.reduce((acc, c, index) => {
				const totalCellWidth = parseInt(c.width) + normalizedCellPadding.horizontal * 2 + normalizedCellSpacing.horizontal;

				if (index < i) {
					acc += totalCellWidth;
				}
				return acc;
			}, 0);

			return { ...c, left, cellWidth, totalCellWidth, cellWidthWithPadding, cellWidthWithPaddingAndSpacing };
		});

		const tableRows: { cell: fabric.Rect; text: fabric.Textbox }[][] = [];

		if (columns?.length) {
			const headerCells: { cell: fabric.Rect; text: fabric.Textbox }[] = [];

			for (let colIndex = 0; colIndex < columns?.length; colIndex++) {
				const header = columns[colIndex];
				const cellWidth = parseInt(header.cellWidth);
				const left = header.left;
				const top = 0;

				const cellTotalHeight = cellHeight + normalizedCellPadding.vertical * 2;
				const cellWidthWithPadding = header.cellWidthWithPadding;

				const cell = new fabric.Rect({
					left,
					top,
					width: cellWidthWithPadding,
					height: cellTotalHeight,
					fill: cellBackgroundColor,
					originX: "left",
					originY: "top",
					selectable: false,
				});

				//TextBox enabled text align but cant disabled wrap
				//Text enabled wrap but disables text align
				const text = new fabric.Textbox(header?.label ?? "", {
					left: left + cellWidthWithPadding / 2,
					top: top + cellTotalHeight / 2,
					originX: "center",
					originY: "center",
					fill: header.fill || textColor,
					editable: true,
					selectable: true,
					fontSize: header?.fontSize || fontSize,
					fontWeight: header?.fontWeight || "normal",
					fontStyle: header?.fontStyle || "normal",
					fontFamily: header?.fontFamily || fontFamily || "",
					textAlign: header?.textAlign || "",
					// backgroundColor: "lightblue", // for debugging
					width: cellWidth, // not working
					// styles: { 0: { textDecoration: "clip" } }, //tried something, didnt work
					splitByGrapheme: true, //When this is used, the variable gets a weird name with <span's /> and stuff because it wraps lines.
					clipPath: new fabric.Rect({
						width: cellWidth,
						height: cellHeight,
						top: 0,
						left: 0,
						originX: "center",
						originY: "center",
						// absolutePositioned: true, // Makes the clip path is positioned absolutely relative to the canvas
					}),
				});

				headerCells.push({ cell, text });
			}

			tableRows.push(headerCells);
		}

		for (let rowIndex = 0; rowIndex < rows?.length; rowIndex++) {
			const row = rows[rowIndex];
			const rowCells: { cell: fabric.Rect; text: fabric.Textbox }[] = [];

			for (let columnIndex = 0; columnIndex < columns?.length; columnIndex++) {
				const column = columns[columnIndex];
				const cellWidth = column.cellWidth;
				const left = column.left;

				const startIndex = columns?.length ? 1 : 0;
				const cellTotalHeight = cellHeight + normalizedCellPadding.vertical * 2;
				const cellWidthWithPadding = column.cellWidthWithPadding;

				const top = (rowIndex + startIndex) * (cellTotalHeight + normalizedCellSpacing.vertical);

				const cell = new fabric.Rect({
					left,
					top,
					width: cellWidthWithPadding,
					height: cellTotalHeight,
					fill: cellBackgroundColor,
					originX: "left",
					originY: "top",
					selectable: false,
				});

				const text = new fabric.Textbox(row[columnIndex] ?? "", {
					left: left + cellWidthWithPadding / 2,
					top: top + cellTotalHeight / 2,
					originX: "center",
					originY: "center",
					editable: true,
					selectable: true,

					fontSize: column?.rows?.fontSize || fontSize,
					fill: column?.rows?.fill || textColor,
					fontWeight: column?.rows?.fontWeight || "normal",
					fontStyle: column?.rows?.fontStyle || "normal",
					textAlign: column?.rows?.textAlign || "left",
					fontFamily: fontFamily || "",

					// backgroundColor: "red", // for debugging
					width: cellWidth, // Set the width of the text object to the width of the cell
					height: cellHeight, // Set the height of the text object to the height of the cell
					// styles: { 0: { textDecoration: "clip" } }, //tried something, didnt work
					splitByGrapheme: true, //When this is used, the variable gets a weird name with <span's /> and stuff because it wraps lines.
					clipPath: new fabric.Rect({
						top: 0,
						left: 0,
						width: cellWidth,
						height: cellHeight,
						originX: "center",
						originY: "center",
						// absolutePositioned: true,
					}),
				});
				rowCells.push({ cell, text });
			}

			tableRows.push(rowCells);
		}

		if (cellHeightAutoAdjust) this.adjustCellHeights(tableRows, normalizedCellPadding, normalizedCellSpacing);

		tableRows.forEach((row) => {
			const cells = row;
			cells.forEach(({ cell, text }) => {
				this.addWithUpdate(cell);
				this.addWithUpdate(text);
			});
		});

		const totalWidth = columns.reduce((acc, c) => acc + c.cellWidthWithPaddingAndSpacing, 0);
		this.set({
			scaleX,
			scaleY,
			left,
			width: totalWidth,
			top,
			angle,
		});

		this.setCoords();
		setTimeout(() => {
			this.setCoords();
		}, 0);
		// setTimeout(() => {
		// 	this.setCoords();
		// }, 1000);
	},

	adjustCellHeights(tableRows: { cell: fabric.Rect; text: fabric.Textbox }[][], cellPadding, cellSpacing) {
		const rowHeights = {};

		// Calculate maximum height for each row
		tableRows.forEach((row, rowIndex) => {
			row.forEach(({ cell, text }, columnIndex) => {
				const cellHeight = Math.max(text.height + cellPadding.vertical * 2, cell.height);
				if (!rowHeights[rowIndex] || rowHeights[rowIndex] < cellHeight) {
					rowHeights[rowIndex] = cellHeight;
				}
			});
		});

		// Set height for each cell in the row
		tableRows.forEach((row, rowIndex) => {
			const rowHeight = rowHeights[rowIndex];
			row.forEach(({ cell, text }, columnIndex) => {
				cell.set({ height: rowHeight });
				text.set({ top: cell.top + rowHeight / 2 });

				text.clipPath.set({ height: rowHeight });
			});
		});

		// Set top for each row
		tableRows.forEach((row, rowIndex) => {
			const rowHeight = rowHeights[rowIndex];
			const top = tableRows.slice(0, rowIndex).reduce((acc, r) => acc + r[0].cell.height + cellSpacing.vertical, 0);
			row.forEach(({ cell, text }, columnIndex) => {
				cell.set({ top });
				text.set({ top: cell.top + rowHeight / 2 });
			});
		});
	},
	handleScaling(event) {
		const current = event.transform.original;
		const target = event.transform.target;

		if (event?.transform?.action === "scaleX") {
			const newScale = target.scaleX / (current.scaleX || 1);
			const columns = this.resizeColumns(newScale);
			this.set({
				left: target.left,
				scaleX: current.scaleX,
			});

			this.data.columns = columns;
		}

		if (event?.transform?.action === "scaleY") {
			const newScale = target.scaleY / (current.scaleY || 1);
			this.resizeVertical(newScale);

			this.set({
				top: target.top,
				scaleY: current.scaleY,
			});
		}

		this.recreateTable({ ...this.data });
	},

	resizeColumns(scale) {
		const columns = this.data.columns.map((column, index) => {
			return { ...column, width: column.width * scale };
		});

		return columns;
	},

	resizeVertical(scale) {
		this.data.options.cellHeight *= scale;
	},

	toObject() {
		return fabric.util.object.extend(this.callSuper("toObject"), {
			data: this.data,

			left: this.left,
			top: this.top,
			scaleX: this.scaleX,
			scaleY: this.scaleY,
			angle: this.angle,
			width: this.width,
			height: this.height,
		});
	},

	toSVG(reviver) {
		const markup = this.callSuper("toSVG", reviver);

		return markup;
	},
});

const fromObject = function (object, callback) {
	const data = object.data || {};
	const fabricTable = new fabric.Table(data, object);
	fabricTable.set({
		left: object.left,
		top: object.top,
		scaleX: object.scaleX,
		scaleY: object.scaleY,
		angle: object.angle,
		width: object.width,
		height: object.height,
	});

	if (callback) callback(fabricTable);

	return fabricTable;
};

Table.fromObject = fromObject;

export default Table;
