export default (state = {}, action) => {
	switch (action.type) {
		case "SET_CHATS": {
			return action.chats;
		}
		case "ADD_CHAT":
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return [action.chat, ...state];
		case "UPDATE_CHAT": {
			const index = state.findIndex((ch) => String(ch.id) === String(action.chat.id));

			if (!action.chat?.group) {
				// eslint-disable-next-line no-undef
				state.splice(index, 1, { ...(state[index] || {}), ...action.chat });
			} else {
				state.splice(index, 1, Object.assign(state[index] || {}, action.chat));
			}

			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return [...state];
		}
		case "REMOVE_CHAT": {
			const chatIndex = state.findIndex((ch) => ch.id == action.chat.id);
			state.splice(chatIndex, 1);
			return [...state];
		}
		case "UPDATE_MEMBER": {
			const chatIndex = state.findIndex((ch) => String(ch.id) === String(action.member?.chat_id));

			if (chatIndex >= 0) {
				const memberIndex = state[chatIndex]?.members?.findIndex((mem) => String(mem.id) === String(action.member.id));
				const member = { ...(state[chatIndex]?.members?.[memberIndex] || {}), ...action.member };

				if (memberIndex >= 0 && member) {
					member.last_read_id *= 1;
					state[chatIndex]?.members.splice(memberIndex, member);
				}

				if (String(member?.user?.id) === String(initialState?.user?.id)) {
					state.splice(chatIndex, 1, { ...(state[chatIndex] || {}), me_as_member: member });
				} else {
					state.splice(chatIndex, 1, { ...(state[chatIndex] || {}) });
				}
			}

			return [...state.map((chat) => ({ ...chat }))];
		}

		default:
			return state;
	}
};
