import React, { useContext } from "react";
import EntityLogs from "src/js/components/EntityLogs";
import OrderContext from "../OrderContext";

const OrderLogs = () => {
	const { id } = useContext(OrderContext);

	return <EntityLogs url={`/api/orders/${id}/logs.json`} />;
};
export default OrderLogs;
