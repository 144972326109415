import i18next from "i18next";

const options = {
	label: i18next.t("fortnox.article.label", "Artikel"),
	value: "Fortnox.Article",
	options: [
		{ label: i18next.t("fortnox.article.fields.active.label", "Aktiv"), value: "Fortnox.Article.Active" },
		{ label: i18next.t("fortnox.article.fields.articleNumber.label", "Artikelnummer"), value: "Fortnox.Article.ArticleNumber" },
		{ label: i18next.t("fortnox.article.fields.bulky.label", "Skrymmande"), value: "Fortnox.Article.Bulky" },
		{ label: i18next.t("fortnox.article.fields.constructionAccount.label", "Byggnadskonto"), value: "Fortnox.Article.ConstructionAccount" },
		{
			label: i18next.t("fortnox.article.fields.costCalculationMethod.label", "Kostnadsberäkningsmetod"),
			value: "Fortnox.Article.CostCalculationMethod",
		},
		{ label: i18next.t("fortnox.article.fields.defaultStockLocation.label", "Standardlagerplats"), value: "Fortnox.Article.DefaultStockLocation" },
		{ label: i18next.t("fortnox.article.fields.defaultStockPoint.label", "Standard lagerpunkt"), value: "Fortnox.Article.DefaultStockPoint" },
		{ label: i18next.t("fortnox.article.fields.depth.label", "Djup"), value: "Fortnox.Article.Depth" },
		{ label: i18next.t("fortnox.article.fields.description.label", "Beskrivning"), value: "Fortnox.Article.Description" },
		{ label: i18next.t("fortnox.article.fields.directCost.label", "Direkt kostnad"), value: "Fortnox.Article.DirectCost" },
		{ label: i18next.t("fortnox.article.fields.disposableQuantity.label", "Disponibelt antal"), value: "Fortnox.Article.DisposableQuantity" },
		{ label: i18next.t("fortnox.article.fields.ean.label", "EAN"), value: "Fortnox.Article.EAN" },
		{ label: i18next.t("fortnox.article.fields.euAccount.label", "EU-konto"), value: "Fortnox.Article.EUAccount" },
		{ label: i18next.t("fortnox.article.fields.euVATAccount.label", "EU-moms konto"), value: "Fortnox.Article.EUVATAccount" },
		{ label: i18next.t("fortnox.article.fields.expired.label", "Utgånget"), value: "Fortnox.Article.Expired" },
		{ label: i18next.t("fortnox.article.fields.exportAccount.label", "Exportkonto"), value: "Fortnox.Article.ExportAccount" },
		{ label: i18next.t("fortnox.article.fields.freightCost.label", "Fraktkostnad"), value: "Fortnox.Article.FreightCost" },
		{ label: i18next.t("fortnox.article.fields.height.label", "Höjd"), value: "Fortnox.Article.Height" },
		{ label: i18next.t("fortnox.article.fields.housework.label", "Hushållsarbete"), value: "Fortnox.Article.Housework" },
		{ label: i18next.t("fortnox.article.fields.houseworkType.label", "Typ av hushållsarbete"), value: "Fortnox.Article.HouseworkType" },
		{ label: i18next.t("fortnox.article.fields.manufacturer.label", "Tillverkare"), value: "Fortnox.Article.Manufacturer" },
		{
			label: i18next.t("fortnox.article.fields.manufacturerArticleNumber.label", "Tillverkarens artikelnummer"),
			value: "Fortnox.Article.ManufacturerArticleNumber",
		},
		{ label: i18next.t("fortnox.article.fields.note.label", "Notering"), value: "Fortnox.Article.Note" },
		{ label: i18next.t("fortnox.article.fields.otherCost.label", "Övriga kostnader"), value: "Fortnox.Article.OtherCost" },
		{ label: i18next.t("fortnox.article.fields.purchaseAccount.label", "Inköpskonto"), value: "Fortnox.Article.PurchaseAccount" },
		{ label: i18next.t("fortnox.article.fields.purchasePrice.label", "Inköpspris"), value: "Fortnox.Article.PurchasePrice" },
		{ label: i18next.t("fortnox.article.fields.quantityInStock.label", "Antal i lager"), value: "Fortnox.Article.QuantityInStock" },
		{ label: i18next.t("fortnox.article.fields.reservedQuantity.label", "Reserverad mängd"), value: "Fortnox.Article.ReservedQuantity" },
		{ label: i18next.t("fortnox.article.fields.salesAccount.label", "Försäljningskonto"), value: "Fortnox.Article.SalesAccount" },
		{ label: i18next.t("fortnox.article.fields.salesPrice.label", "Försäljningspris"), value: "Fortnox.Article.SalesPrice" },
		{ label: i18next.t("fortnox.article.fields.stockAccount.label", "Lagerkonto"), value: "Fortnox.Article.StockAccount" },
		{ label: i18next.t("fortnox.article.fields.stockChangeAccount.label", "Lagerändringskonto"), value: "Fortnox.Article.StockChangeAccount" },
		{ label: i18next.t("fortnox.article.fields.stockGoods.label", "Lager varor"), value: "Fortnox.Article.StockGoods" },
		{ label: i18next.t("fortnox.article.fields.stockPlace.label", "Lagerplats"), value: "Fortnox.Article.StockPlace" },
		{ label: i18next.t("fortnox.article.fields.stockValue.label", "Lagervärde"), value: "Fortnox.Article.StockValue" },
		{ label: i18next.t("fortnox.article.fields.stockWarning.label", "Lagervarning"), value: "Fortnox.Article.StockWarning" },
		{ label: i18next.t("fortnox.article.fields.supplierName.label", "Leverantörsnamn"), value: "Fortnox.Article.SupplierName" },
		{ label: i18next.t("fortnox.article.fields.supplierNumber.label", "Leverantörsnummer"), value: "Fortnox.Article.SupplierNumber" },
		{ label: i18next.t("fortnox.article.fields.type.label", "Typ"), value: "Fortnox.Article.Type" },
		{ label: i18next.t("fortnox.article.fields.unit.label", "Enhet"), value: "Fortnox.Article.Unit" },
		{ label: i18next.t("fortnox.article.fields.vat.label", "Moms"), value: "Fortnox.Article.VAT" },
		{ label: i18next.t("fortnox.article.fields.webshopArticle.label", "Webbshopartikel"), value: "Fortnox.Article.WebshopArticle" },
		{ label: i18next.t("fortnox.article.fields.weight.label", "Vikt"), value: "Fortnox.Article.Weight" },
		{ label: i18next.t("fortnox.article.fields.width.label", "Bredd"), value: "Fortnox.Article.Width" },
	],
};

export default options;
