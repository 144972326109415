import React from "react";
import { AttachmentMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Modal from "../modal";
import RenderEmail from "./RenderEmail";
import Attachment from "../Attachment";

const EmailViewerModal = ({ open, onClose, title, email, comment, t }) => {
	const getTitle = () => {
		if (title) return title;

		try {
			const content = JSON.parse(email);
			return content?.subject;
		} catch (error) {
			return null;
		}
	};

	const getContent = () => {
		try {
			return JSON.parse(email);
		} catch (error) {
			return {
				subject: null,
				body: email,
			};
		}
	};

	const emailContent = getContent();

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={getTitle()}
			large
			secondaryActions={[
				{
					content: t("common.actions.close", "Stäng"),
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				{emailContent?.webLink && (
					<a href={emailContent?.webLink} target="_blank" rel="noreferrer">
						Länk till mail
					</a>
				)}
				{comment?.files?.map((file, key) => (
					<div style={{ display: "flex", gap: "0.0625rem", alignItems: "center" }} key={key}>
						<span>
							<Icon source={AttachmentMajor} />
						</span>

						<Attachment icon={null} key={key} file={file} plain />
					</div>
				))}
				<RenderEmail email={email} comment={comment} inline />
			</Modal.Section>
		</Modal>
	);
};
export default withTranslation(["emails", "common"])(EmailViewerModal);
