import styled from "styled-components";

const Wrapper = styled.div`
	display: grid;
	gap: 1.875rem;
	grid-template-columns: repeat(2, 1fr);

	grid-template-areas:
		"line_graph leaderboard"
		"bar_graph boxes";

	.statsBoxWrapper {
		margin: 0;

		.statsBox {
			min-height: 100px;
		}
	}
	.Polaris-LegacyCard {
		height: 100%;
	}
`;

const GraphWrapper = styled.div`
	flex: 1;
	position: relative;
	height: 100%;
`;

const FilterWrapper = styled.div`
	width: max-content;
	margin-bottom: 1.25rem;
	display: flex;
	gap: 10px;

	button:first-of-type {
		height: max-content;
	}

	.Polaris-Tag {
		background: var(--main2);
	}

	.Polaris-Filters-ConnectedFilterControl__Wrapper {
		width: max-content;
	}

	/* position: absolute;
	top: 0;
	right: 1.5625rem;
	z-index: 1; */
`;

const DatesWrapper = styled.div`
	display: flex;
	gap: 10px;

	.new_datepicker {
		min-width: unset;
		max-width: unset;

		.no_date_suffix > div {
			padding: 0;
		}
	}
`;

export { Wrapper, GraphWrapper, FilterWrapper, DatesWrapper };
