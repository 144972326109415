/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import {
	AnalyticsBarStackedMinor,
	CancelMajor,
	CirclePlusMajor,
	DuplicateMinor,
	EmailMajor,
	ExternalMinor,
	ImportMinor,
	ReceiptMajor,
	SendMajor,
} from "@shopify/polaris-icons";
import Badge from "src/js/components/Badge";
import { store } from "src/js/store";
import { Spinner } from "@shopify/polaris";
import FortnoxOrderContext, { FortnoxOrderContextProvider } from "./FortnoxOrderContext";
import FortnoxPDFView from "../FortnoxPDFView";
import Header from "./Header";
import { downloadPDF } from "../Utilities";
import FortnoxOrderInfoView from "./FortnoxOrderInfoView";
import FortnoxContactView from "../FortnoxContactView";
import RowSheet from "../../Workspaces/components/RowSheet";
import BoardHelper from "../../Workspaces/BoardHelper";
import { PDFContext } from "src/js/PDF/PDFContext";
import CopyModal from "../Invoices/CopyModal";
import FortnoxCustomerFields from "../FortnoxCustomerFields";
import FortnoxPDFUploadsList from "../FortnoxPDFUploadsList";
import FortnoxDeliveryFields from "../FortnoxDeliveryFields";

const FortnoxOrder = ({ id: propsId, match, history, t }) => {
	const {
		form,
		pdf,
		id,
		order,
		handleSave,
		contact,
		customer,
		handleCreateInvoiceFromOrder,
		isFetching,
		row,
		handleOpenRowSelectModal,
		handleCancelOrder,
		handleSendToScrive,
		upload,
		isSaving,
		handleSetAsSentOrder,
		handleSendOrderAsEmailPre,
		setForm,
		handleCreatePdfUpload,
		pdfUploads,
		isFetchingPDFUploads,
	} = useContext(FortnoxOrderContext);
	const [boardRowIsOpen, setBoardRowIsOpen] = useState(false);
	const { handleOpen: openpDF, handleClose: closePdf } = useContext(PDFContext);

	const handleOpenBoardRow = () => setBoardRowIsOpen(true);
	const handleCloseBoardRow = () => setBoardRowIsOpen(false);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "pdf",
					content: "PDF",
					render: <FortnoxPDFView history={history} pdf={pdf} loading={isFetching} />,
					enabled: !!id,
				},
				{
					id: "info",
					content: t("fortnox.tabs.base_info", "Grunduppgifter"),
					render: <FortnoxOrderInfoView history={history} />,
				},
				{
					id: "customer",
					content: t("fortnox.tabs.customer_info", "Kunduppgifter"),
					render: <FortnoxCustomerFields history={history} setForm={(form: any) => setForm(form)} invoice={form} isSaving={isSaving} />,
				},
				{
					id: "delivery",
					content: t("fortnox.tabs.delivery.overview", "Leveransuppgifter"),
					render: <FortnoxDeliveryFields history={history} setForm={(form: any) => setForm(form)} invoice={form} isSaving={isSaving} />,
				},
				{
					id: "contact",
					content: t("fortnox.tabs.contact", "Kontakt"),
					render: (
						<FortnoxContactView contact={contact} customer={customer} history={history} type="order" EmailInformation={order?.EmailInformation} />
					),
					enabled: pdfUploads?.files?.length,
				},
				{
					id: "uploads",
					content: (
						<div style={{ display: "flex", gap: "0.3125rem", alignItems: "center" }}>
							{isFetchingPDFUploads && <Spinner size="small" />}
							{t("fortnox.tabs.pdf_uploads", "Sellfinity PDF's")}
							{!!pdfUploads?.files?.length && <Badge status="success">{pdfUploads?.files?.length}</Badge>}
						</div>
					),
					render: <FortnoxPDFUploadsList data={pdfUploads} history={history} isLoading={!!isFetchingPDFUploads} />,
					enabled: !!contact || !!customer,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, id, contact, customer, t, pdf, isFetching, order, form, isSaving, setForm, pdfUploads, isFetchingPDFUploads]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as FortnoxOrderType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);
	const board = store.getState().boards[row?.board_id || store.getState().account.sales_board_id];

	const handleCreatePdf = async ({ template, pages, name, variables }) => {
		const res = await handleCreatePdfUpload(form as FortnoxOrderType, {
			template,
			pages,
			name,
			variables,
		});

		closePdf();
		return res;
	};

	return (
		<Page
			history={history}
			title={
				isFetching
					? ((<Spinner size="small" />) as any)
					: form?.DocumentNumber
					? t("fortnox.orders.title", "Fortnox order: {{id}}", { id: form?.DocumentNumber || "-" })
					: t("fortnox.orders.new_title", "Ny Fortnox order")
			}
			titleMetadata={
				order?.InvoiceReference && (
					<Badge
						status="success"
						onClick={() => {
							history.push(`/admin/fortnox/invoices/${order?.InvoiceReference}`);
						}}
					>
						{t("fortnox.orders.status.invoiceCreated", "Fakturerad")}: {order?.InvoiceReference}
					</Badge>
				)
			}
			primaryAction={
				!order?.InvoiceReference && {
					content: form?.DocumentNumber ? t("fortnox.actions.save", "Spara") : t("fortnox.actions.create", "Skapa"),
					onAction: handleSaveForm,
					disabled: !form,
					loading: isSaving,
				}
			}
			secondaryActions={[
				{
					content: t("fortnox.actions.download", "Ladda ner"),
					onAction: () => downloadPDF(pdf, `fortnox_order_${form?.DocumentNumber}.pdf`),
					disabled: !pdf,
					icon: ImportMinor,
					enabled: !!form?.DocumentNumber,
				},
				{
					content: t("fortnox.actions.download2", "Skapa sellfinity PDF"),
					onAction: () =>
						openpDF(
							{ Fortnox: { order: form, customer }, contact },
							{ saveHandler: handleCreatePdf, name: `Fortnox Order #${form?.DocumentNumber}` }
						),
					icon: ImportMinor,
				},

				{
					isGroup: true,
					title: t("common.actions.row", "{{singular}}", { singular: board?.singular?.capitalize() || "Rad" }),
					icon: AnalyticsBarStackedMinor,
					actions: [
						{
							content: t("fortnox.actions.open_row", "Öppna {{singular}}", { singular: board?.singular || "rad" }),
							onAction: handleOpenBoardRow,
							icon: AnalyticsBarStackedMinor,
							enabled: !!row,
						},
						{
							content: t("fortnox.actions.select_row", "Välj {{singular}}", { singular: board?.singular || "rad" }),
							onAction: handleOpenRowSelectModal,
							icon: AnalyticsBarStackedMinor,
							enabled: !row,
						},
						{
							content: t("fortnox.actions.change_row", "Ändra {{singular}}", { singular: board?.singular || "rad" }),
							onAction: handleOpenRowSelectModal,
							icon: AnalyticsBarStackedMinor,
							enabled: !!row,
						},
					].filter((a) => !("enabled" in a) || a.enabled),
				},
				{
					title: t("fortnox.actions.send", "Skicka"),
					icon: SendMajor,
					actions: [
						{
							id: "scrive",
							content: t("scrive.actions.prepare1", "Skicka till Scrive"),
							onAction: handleSendToScrive,
							icon: (<img src="/assets/scrive.png" alt="scrive" />) as any,
							enabled: !!(row && !upload?.scrive_id),
							confirmation: {
								title: t("fortnox.order.actions.scrive.title", "Skicka till Scrive"),
								content: t("fortnox.order.actions.scrive.confirmation", "Är du säker på att du vill skicka ordern till Scrive?"),
								confirm: t("fortnox.order.actions.scrive.confirm", "Skicka till Scrive"),
								cancel: t("common.actions.cancel", "Avbryt"),
							},
						},
						{
							content: t("fortnox.actions.send_email", "Skicka som e-post"),
							onAction: handleSendOrderAsEmailPre,
							icon: EmailMajor,
							enabled: !!form?.DocumentNumber,
						},
						{
							content: t("scrive.offer.actions.setAsSent", "Markera som skickad"),
							icon: SendMajor,
							onAction: handleSetAsSentOrder,
							enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.Sent),
							confirmation: {
								title: t("scrive.offer.actions.setAsSent.title", "Markera som skickad"),
								content: t("scrive.offer.actions.setAsSent.confirmation", "Är du säker på att du vill markera offerten som skickad?"),
								confirm: t("scrive.offer.actions.setAsSent.confirm", "Markera som skickad"),
								cancel: t("common.actions.cancel", "Avbryt"),
							},
						},
					],
				},
				{
					content: t("fortnox.order.actions.create_invoice", "Skapa faktura"),
					icon: ReceiptMajor,
					onAction: handleCreateInvoiceFromOrder,
					enabled: !!(form?.DocumentNumber && !order?.InvoiceReference && !order?.Cancelled),
					confirmation: {
						title: t("fortnox.order.actions.invoice.title", "Skapa faktura"),
						content: t("fortnox.order.actions.invoice.confirmation", "Är du säker på att du vill skapa en faktura?"),
						confirm: t("fortnox.order.actions.invoice.confirm", "Skapa faktura"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
				},
				{
					content: t("fortnox.order.actions.copy.title", "Kopiera order"),
					icon: DuplicateMinor,
					modal: <CopyModal order={form} />,
				},
				{
					content: t("scrive.actions.new_order", "Ny order"),
					icon: CirclePlusMajor,
					onAction: () => {
						history.push(`/admin/fortnox/contacts/${contact?.id}/orders/create`, {
							contact,
							customer,
						});
					},
					enabled: !!contact?.id,
				},
				{
					content: t("fortnox.actions.contact.content", `Kontakt ({{contact}})`, {
						contact: contact?.name || "-",
					}),
					tooltip: t("fortnox.actions.contact.tooltip", `Gå till kontakt ({{contact}})`, { contact: contact?.name || "-" }),
					icon: ExternalMinor,
					onAction: () => {
						history.push(`/admin/contacts/${contact?.id}#fortnox_orders`, {
							contact,
							customer,
						});
					},
					enabled: !!contact?.id,
				},
				{
					content: t("scrive.order.actions.cancel", "Makulera Order"),
					icon: CancelMajor,
					onAction: handleCancelOrder,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled),
					destructive: true,
					confirmation: {
						title: t("scrive.order.actions.cancel", "Makulera Order"),
						content: t("scrive.order.actions.cancel.confirmation", "Är du säker på att du vill makulera ordern?"),
						confirm: t("scrive.order.actions.cancel", "Makulera Order"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
				},
				{
					content: t("fortnox.actions.created_from_offer", "Skapad ifrån Offert {{offer}}", { offer: order?.OfferReference }),
					onAction: () => history.push(`/admin/fortnox/offers/${order?.OfferReference}`),
					icon: ExternalMinor,
					enabled: !!order?.OfferReference,
				},
			]
				.filter((a) => !("enabled" in a) || a.enabled)
				?.map((a) => ({ ...a, disabled: isSaving || a.disabled }))}
		>
			<Header order={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
			<RowSheet
				open={boardRowIsOpen}
				row={row}
				board={board}
				initialTabIndex={0}
				onUpdateRow={(row) => {
					BoardHelper.updateRows([row], "update");
				}}
				onClose={handleCloseBoardRow}
			/>
		</Page>
	);
};

export default withTranslation(["fortnox", "common"])((props) => {
	return (
		<FortnoxOrderContextProvider {...props}>
			<FortnoxOrder {...props} />
		</FortnoxOrderContextProvider>
	);
});
