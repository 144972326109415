import { Icon } from "@shopify/polaris";
import React, { Component } from "react";
import { colorToRgba } from "../Colors";

/**
 * @param {Object} [style] - inline style
 * @param {String} [className] - classname
 * @param {Number} [size = 50] - size value in number
 * @param {Icon} [icon] - icon
 */
class IconWithBackround extends Component {
	render() {
		const { style, icon, size, className, color, backgroundColor, fill } = this.props;

		return (
			<span
				className={`icon-with-background__wrapper ${className || ""} ${fill ? "fill" : "not_fill"} `}
				style={Object.assign(
					style || {},
					size ? { height: size + "px", width: size + "px", minWidth: size + "px" } : {},
					color ? { backgroundColor: colorToRgba(color, 0.25), fill: color } : {},
					backgroundColor ? { backgroundColor } : {}
				)}
			>
				{React.isValidElement(icon) ? icon : <Icon source={icon} />}
			</span>
		);
	}
}
export default IconWithBackround;
