import React, { useEffect } from "react";
import { Button, LegacyCard, TextField } from "@shopify/polaris";
import nl2br from "react-nl2br";
import { EditMajor, CirclePlusMajor } from "@shopify/polaris-icons";

import Modal from "src/js/components/modal";
import useContact from "src/js/hooks/useContact";
import { useParams } from "react-router-dom";

const Note = (props) => {
	const { id } = useParams();
	const { contact, updateContact, t } = useContact(id);
	const { note = contact?.note || "", disabled, card = true, className } = props;
	const [form, setForm] = React.useState(note);
	const [open, setOpen] = React.useState(false);
	const [expanded, setExpanded] = React.useState(false);

	const toggleExpanded = (v = !expanded) => setExpanded(v);

	useEffect(() => {
		if (open) {
			setForm(note);
		}
	}, [note, open]);

	const onClose = () => {
		setOpen(false);
	};

	const onOpen = () => {
		setOpen(true);
	};

	const onSave = async () => {
		await updateContact({ note: form }, contact?.id);
		// Toast.success(t("contact.note.reponses.updated", "Notering uppdaterad"));
	};

	const content = (
		<div className={`contact_note note ${className}`}>
			{/* <div className="contact_note__icon">
					<IconWithBackround color="#ffffff" backgroundColor="rgba(0,0,0,0.25)" icon={NoteMajor} />
				</div> */}
			<div className="contact_note__body">
				<h2 className="small_card_title">
					{t("contact.note.title", "Allmänna noteringar")}{" "}
					{!disabled && (
						<span className="edit-button">
							<Button onClick={onOpen} plain icon={note ? EditMajor : CirclePlusMajor} />
						</span>
					)}
				</h2>

				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<p>{note ? nl2br(note) : <span style={{ opacity: 0.5, color: "var(--textColor)" }}>Ingen anteckning</span>}</p>
				</div>

				{note && (
					<div className="showmore">
						<button style={{ border: "none", background: "transparent", outline: "none" }} onClick={toggleExpanded}>
							{expanded ? t("timeline.actions.show_less", "Visa mindre") : t("timeline.actions.show_more", "Visa mer")}
						</button>
					</div>
				)}
			</div>

			<Modal
				title={note ? t("contact.note.singular", "Notering") : t("contact.note.actions.add", "Lägg till notering")}
				open={open}
				onClose={onClose}
				primaryAction={{
					content: t("common.actions.save", "Spara"),
					onAction: onSave,
					disabled: form === note,
				}}
				secondaryActions={[{ content: t("common.actions.close", "Stäng"), onAction: onClose }]}
			>
				<Modal.Section>
					<TextField placeholder={t("contact.note.placeholder", "Notering..")} multiline={2} onChange={(text) => setForm(text)} value={form} />
				</Modal.Section>
			</Modal>
			<Modal
				title={note ? t("contact.note.singular", "Notering") : t("contact.note.actions.add", "Lägg till notering")}
				open={expanded}
				onClose={() => {
					toggleExpanded(false);
				}}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng"),
						onAction: () => {
							toggleExpanded(false);
						},
					},
				]}
			>
				<Modal.Section>
					<p style={{ wordBreak: "break-word" }}>{nl2br(note)}</p>
				</Modal.Section>
			</Modal>
		</div>
	);

	if (card) {
		return <LegacyCard sectioned>{content}</LegacyCard>;
	}

	return content;
};

export default Note;
