/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import API from "../API";
import useQuery from "../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import NotificationItem from "../views/Notifications/NotificationItem";
import Toast from "../components/Toast";
import { useHistory, useLocation } from "react-router-dom";
import NotificationSheet from "../views/Notifications/NotificationSheet";

// Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyD3MUA5235MpaKO2imznE8L7FQ5ThMbxmQ",
	authDomain: "kund24-474e5.firebaseapp.com",
	databaseURL: "https://kund24-474e5.firebaseio.com",
	projectId: "kund24-474e5",
	storageBucket: "kund24-474e5.firebasestorage.app",
	messagingSenderId: "409218946122",
	appId: "1:409218946122:web:d23e79406bd7532b6a42b3",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// type NotificationContextValue = {
// 	notifications: NotificationType[];
// 	loading: boolean;
// 	refresh: () => void;
// 	readAllAction: () => void;
// 	toggleNotificationSheet: (open?: boolean) => () => void;
// 	unreadCount: number;
// 	count: number;
// 	notificationsInPopup: Partial<NotificationType>[] | [];
// 	notificationPopupIsOpen: boolean;
// 	closeNotificationPopup: () => void;
// 	markNotificationAsRead: (id: number) => any;
// 	fetchSingelNotification: (id: number) => any;
// 	// fetchSingelNotification: (id: number) => Promise<Partial<NotificationType>>;
// };

const NotificationContext = React.createContext({
	toggleNotificationSheet: (open?: boolean) => () => {},
	unreadCount: 0,
	markNotificationAsRead: (id: number) => {},
	markNotificationAsReadBulk: (ids: number[]) => {},
	markNotificationAsUnReadBulk: (ids: number[]) => {},
	markNotificationAsReadAll: () => {},
	notificationSheetIsOpen: false,
	refreshUnreadCount: () => {},
});
// fetchSingelNotification: (id: number) => Promise.resolve({}),

export const NotificationProvider = ({ children }) => {
	const { t } = useTranslation(["notifications", "common"]);
	const location = useLocation();
	const history = useHistory();

	const audio = useMemo(() => new Audio("https://www.sellfinity.com/assets/discord-notification.mp3"), []);
	const haveRegistered = useRef(false);

	const [notificationSheetIsOpen, setNotificationSheetIsOpen] = useState(false);

	const { data: unreadCount, refetch: refreshUnreadCount } = useQuery({
		queryKey: ["unread_notifications_count"],
		queryFn: async () => {
			try {
				const result = await API.get("/api/notifications/unread_count.json");
				return result.data.count;
			} catch (e) {
				Toast.error(e);
			}
		},
		refetchInterval:
			60000 * //1 min
			5, // 5 min
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: true,
	});

	const toggleNotificationSheet = useCallback(
		(open = !notificationSheetIsOpen) =>
			() => {
				setNotificationSheetIsOpen(open);
				if (open) {
					Toast.dismiss();
					refreshUnreadCount();
				}
			},
		[notificationSheetIsOpen, refreshUnreadCount]
	);

	const markNotificationAsRead = useCallback(
		async (id) => {
			try {
				const res = await API.post(`/api/notifications/${id}/read.json`, {});

				refreshUnreadCount();
				return res.data;
			} catch (error) {
				Toast.error(error);
			}
		},
		[refreshUnreadCount]
	);

	const markNotificationAsReadBulk = useCallback(
		async (ids) => {
			try {
				const res = await API.post(`/api/notifications/read.json`, { ids });

				Toast.success(
					ids?.length > 1
						? t("notifications.response.read_bulk", "{{count}} notifikationer markerade som lästa", { count: ids.length })
						: t("notifications.response.read", "Notifikation markerad som läst")
				);

				refreshUnreadCount();
				return res.data;
			} catch (error) {
				Toast.error(error);
			}
		},
		[refreshUnreadCount, t]
	);

	const markNotificationAsUnReadBulk = useCallback(
		async (ids) => {
			try {
				const res = await API.post(`/api/notifications/unread.json`, { ids });

				Toast.success(
					ids?.length > 1
						? t("notifications.response.unread_bulk", "{{count}} notifikationer markerade som olästa", { count: ids.length })
						: t("notifications.response.unread", "Notifikation markerad som oläst")
				);

				refreshUnreadCount();
				return res.data;
			} catch (error) {
				Toast.error(error);
			}
		},
		[refreshUnreadCount, t]
	);

	const markNotificationAsReadAll = useCallback(async () => {
		try {
			const res = await API.post(`/api/notifications/read_all.json`);
			Toast.dismiss();
			Toast.success(t("notifications.response.read_all", "Alla notifikationer markerade som lästa"));

			refreshUnreadCount();
			return res.data;
		} catch (error) {
			console.error("markNotificationAsReadAll error:", error);
			Toast.error(error);
		}
	}, [refreshUnreadCount, t]);

	const addNotificationToast = useCallback(
		(payload) => {
			try {
				audio.play();
			} catch (error) {
				console.error("audio.play error:", error);
			}

			refreshUnreadCount(); // If this gets to called to much, we can use mutation to update the cache instead

			const notification = {
				title: payload.notification?.title,
				content: payload.notification?.body,
				created_at: payload.data?.created_at,
				id: payload.data?.notification_id,
				creator: payload.data?.creator_id && {
					id: payload.data?.creator_id,
				},
			} as Partial<NotificationType>;

			Toast(
				<NotificationItem payload={payload} history={history} notification={notification} />,

				{
					toastId: notification.id || undefined,
					autoClose: false,
					closeButton: true,
					onClose: (a, b) => {
						// if (notification.id) markNotificationAsRead(notification.id); // Maybe someone one just want to temporary close it to handle it later, but not mark it as read. You mark it as read when you click on it OR through the sheet.
					},
				}
			);
		},
		[audio, history, refreshUnreadCount]
	);

	const registerPushNotifications = useCallback(async () => {
		if (await isSupported()) {
			const messaging = getMessaging(app);
			// Register messaging handlers here

			Notification.requestPermission().then((permission) => {
				if (permission !== "granted") {
					console.debug("Unable to get permission to notify.");
					return;
				}

				console.debug("Notification permission granted.");

				getToken(messaging, { vapidKey: "BO3dDqigeef1PEbmmBOZT-USyJi_dGM5PLl6My5Ptz5IjIVIdYUwLaL3Ou0IGD5oz81DA2p5o55UgymxqP_WEsI" })
					.then((currentToken) => {
						if (currentToken) {
							sendTokenToServer(currentToken);
						} else {
							console.debug("No Instance ID token available. Request permission to generate one.");
						}
					})
					.catch((err) => {
						console.debug("An error occurred while retrieving token.", err);
					});

				onMessage(messaging, async (payload: any) => {
					// console.debug("Message received: ", payload);
					if (!Object.hasOwn(payload.data, "chat_id")) {
						addNotificationToast(payload);
					}
				});
			});
		} else {
			console.warn("Push messaging is not supported in this browser.");
			// Provide fallback or notify the user
		}
	}, [addNotificationToast]);

	useEffect(() => {
		if (haveRegistered.current) return;
		try {
			registerPushNotifications();
			haveRegistered.current = true;
		} catch (e) {
			console.debug("Couldn't initialize notifications", e);
		}
	}, [registerPushNotifications]);

	const sendTokenToServer = (pushSubscription) => {
		API.post("/api/me/push_tokens.json", { token: pushSubscription })
			.then((result) => {})
			.catch((error) => {
				console.error("Error sending token to server", error);
			});
	};

	const fetchSingelNotification = useCallback(async (id) => {
		try {
			const result = await API.get(`/api/notifications/${id}.json`);
			return result.data.notification;
		} catch (e) {
			Toast.error(e);
		}
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const notificationId = searchParams.get("notification_id");

		if (notificationId) {
			markNotificationAsRead(notificationId);
			searchParams.delete("notification_id");

			// Update the URL without reloading the page
			history.replace({
				pathname: location.pathname,
				search: searchParams.toString(),
			});
		}
	}, [location.search, location.pathname, markNotificationAsRead, history]);

	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.addEventListener("message", (event) => {
				if (event.data?.action === "markAsRead") {
					const { notificationId } = event.data;
					markNotificationAsRead(notificationId);
				}
			});
		}
	}, [markNotificationAsRead]);

	const value = useMemo(
		() => ({
			toggleNotificationSheet,
			markNotificationAsRead,
			fetchSingelNotification,
			unreadCount,
			markNotificationAsReadBulk,
			markNotificationAsReadAll,
			markNotificationAsUnReadBulk,
			notificationSheetIsOpen,
			refreshUnreadCount,
		}),
		[
			toggleNotificationSheet,
			markNotificationAsRead,
			fetchSingelNotification,
			unreadCount,
			markNotificationAsReadBulk,
			markNotificationAsReadAll,
			markNotificationAsUnReadBulk,
			notificationSheetIsOpen,
			refreshUnreadCount,
		]
	);

	return (
		<NotificationContext.Provider value={value as any}>
			{children}

			<NotificationSheet />
		</NotificationContext.Provider>
	);
};

export default NotificationContext;
