import { Collapsible, Icon } from "@shopify/polaris";
import { AnalyticsMajor, ChevronUpMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getIconByString } from "../views/Workspaces/components/IconSelector";

class MainSectionFavorites extends Component {
	constructor(props) {
		super(props);
		this.state = { open: true };
	}

	getType(child) {
		return child.handle.replace("_" + child.id, "");
	}

	render() {
		const { items = [] /* history */ } = this.props;
		return (
			<div className="Polaris-Navigation__Section Polaris-Navigation__Section">
				<div
					className="mainmenu-item__secondary__header"
					style={{ cursor: "pointer", paddingRight: 0 }}
					onClick={() => this.setState((c) => ({ open: !c.open }))}
				>
					<span className="workspace-section-title">{this.props.t("maimenu.favories.title", "Favoriter")}</span>
					{!this.state.open && (
						<span style={{ padding: "0 6px" }}>
							<Icon source={ChevronUpMinor} />
						</span>
					)}
				</div>

				<Collapsible open={this.state.open}>
					<div className="mainmenu-item__secondary__items">
						{items &&
							items.map((f, index) => {
								const { title, icon, workspace_id: workspaceId = "none", id } = f;
								const url = (() => {
									switch (this.getType(f)) {
										case "dashboard":
											return "/admin/dashboard/" + id;
										case "board":
											return "/admin/workspaces/" + workspaceId + "/boards/" + id;
										default:
											return "/admin/workspaces/" + workspaceId + `/${this.getType(f)}/` + id;
									}
								})();
								const className = url && this.props.location.pathname.indexOf(url) >= 0 ? "active" : "";
								return (
									<Link to={url} className={`mainmenu-item__secondary__item ${className}`} key={index}>
										<div>
											<Icon source={icon ? getIconByString(icon) : this.getType(f) === "dashboard" ? AnalyticsMajor : getIconByString(icon)} />
											{title}
										</div>
									</Link>
								);
							})}
					</div>
				</Collapsible>
			</div>
		);
	}
}
export default withTranslation(["mainmenu"], { withRef: true })(MainSectionFavorites);
