import { Icon } from "@shopify/polaris";
import React, { Component } from "react";

class CalendarHeaderButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { children, icon, onClick, active, style } = this.props;
		return (
			<button onClick={onClick} className={`calendar_header__button${active ? " active" : ""}`} style={style}>
				<div className="calendar_header__button__icon">{React.isValidElement(icon) ? icon : <Icon source={icon} />}</div>
				{children}
			</button>
		);
	}
}

export default CalendarHeaderButton;
