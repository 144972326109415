import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

class StripeCard extends Component {
	componentDidMount() {
		this.props.setTokenizerCallback(this.tokenizeCard.bind(this));
	}

	tokenizeCard() {
		this.props.stripe.createSource({ type: "card", owner: { name: this.props.name } }).then(this.props.onResponse);
	}

	render() {
		return <CardElement />;
	}
}
export default injectStripe(StripeCard);
