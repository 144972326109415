import React, { useMemo, useState } from "react";
import OptionList from "../OptionList/OptionList";
import styled from "styled-components";
import TextField from "../TextField";

const MyFilterOptionList = ({ filter, onChange, selected }) => {
	const [search, setSearch] = useState("");

	const options = useMemo(() => {
		const filterRegex = new RegExp(search, "i");
		const filteredOptions = filter.options.filter((option) => String(option.label).match(filterRegex));

		if (filter.sort) {
			return filteredOptions.map((opt) => ({ ...opt, selected: selected.includes(opt.value) })).sort(filter.sort);
		}
		return filteredOptions;
	}, [filter.options, search, filter.sort, selected]);

	return (
		<Wrapper>
			{filter.search && <TextField placeholder="Sök.." value={search} onChange={setSearch} suffix={filter.search?.suffix} />}

			<OptionList options={options} selected={selected} onChange={onChange} allowMultiple={filter.allowMultiple} />
		</Wrapper>
	);
};
export default MyFilterOptionList;

const Wrapper = styled.div`
	.Polaris-OptionList-Option__Label {
		align-items: center;
	}
`;
