import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Icon, LegacyStack } from "@shopify/polaris";
import { MobileAcceptMajor, MobileCancelMajor, ChevronDownMinor, CustomerPlusMajor } from "@shopify/polaris-icons";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "../API";
import Popover from "./Popover";
import Button from "./Button";
import UserRoles from "../views/Users/UserRoles";
import { useSelector } from "react-redux";
import TextField from "./TextField";
import flattenOptions from "./NestedChoiceList/flattenOptions";
import Toast from "src/js/components/Toast";

type UserInviterProps = {
	onSave?: () => void;
	open?: boolean;
} & WithTranslation;

const UserInviter = React.forwardRef(({ t, onSave, ...props }: UserInviterProps, ref) => {
	const [users, setUsers] = useState<any[]>([{ email: "", all_permissions: ["ROLE_CONTACTS"] }]);

	const roleOptions = useSelector((state: any) => state.role_options);

	useEffect(() => {
		setUsers([{ email: "", all_permissions: ["ROLE_CONTACTS"] }]);
	}, [props.open]);

	const checkIfEmailIsValid = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const getValidUsers = useCallback(() => {
		return users.filter((user) => user.email && checkIfEmailIsValid(user.email));
	}, [users]);

	const handleUpdateUser = useCallback(
		(index, field) => (value) => {
			setUsers((users) => {
				const newUsers = [...users];
				newUsers[index][field] = value;
				return newUsers;
			});
		},
		[]
	);

	const handleInviteUsers = useCallback(async () => {
		if (getValidUsers() && getValidUsers().length) {
			return await API.post("/api/users/invite.json", { users: getValidUsers() })
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}

					Toast.success(t("user.responses.invited", "Bjöd in användare"));
					if (onSave) onSave();
				})
				.catch((error) => {
					Toast.error(error);
				});
		}
	}, [getValidUsers, onSave, t]);

	useImperativeHandle(ref, () => ({
		onSave: handleInviteUsers,
	}));

	const flattendRoleOptionsMap = useMemo(() => new Map(flattenOptions(roleOptions)?.map((r) => [r.value, r.label]) || []), [roleOptions]);

	const getRoleOptionsString = (user) => {
		return user.all_permissions
			.map((role) => {
				const optionLabel = flattendRoleOptionsMap.get(role);
				return optionLabel || role;
			})
			.join(", ");
	};

	return (
		<div>
			<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: 3 }}>
				<div
					style={{
						display: "inline-block",
						marginLeft: "1.2rem",
					}}
				>
					{t("user.fields.email.label", "Email")}
				</div>
				<div>
					<div
						style={{
							display: "inline-block",
							marginLeft: 12,
						}}
					>
						{t("user.fields.roles.label", "Rättigheter")}
					</div>
				</div>
			</div>
			{users.map((user, index) => {
				const isEmailValid = checkIfEmailIsValid(user.email);
				return (
					<div key={index} className="user_inviter_item" style={{ display: "flex", gridTemplateColumns: "1fr 1fr", marginBottom: 3 }}>
						<div style={{ position: "relative", marginRight: 1, width: "50%" }}>
							<TextField
								name="new_user_email"
								error={
									user.email && user.email.length
										? isEmailValid
											? false
											: (t("user.fields.email.errors.invalid", "Ogiltig email") as string)
										: false
								}
								value={user.email}
								placeholder={t("user.fields.email.placeholder", "Email...") as string}
								onChange={handleUpdateUser(index, "email")}
							/>
							{user.email ? (
								<div
									style={{
										position: "absolute",
										right: 5,
										top: 8,
										zIndex: 35,
										cursor: isEmailValid ? "initial" : "pointer",
									}}
									onClick={!isEmailValid ? handleUpdateUser(index, "email") : () => {}}
								>
									{isEmailValid ? <Icon source={MobileAcceptMajor} color="success" /> : <Icon source={MobileCancelMajor} color="critical" />}
								</div>
							) : null}
						</div>

						<div className="roles_selection">
							<Popover
								activator={
									<div className="fakeInput" onClick={() => handleUpdateUser(index, "active")(!user.active)}>
										{user?.all_permissions.length > 0 ? (
											getRoleOptionsString(user)
										) : (
											<LegacyStack spacing="extraTight">
												<span style={{ marginLeft: 4 }}>{t("user.actions.add_to", "Lägg till")}</span>
												<Icon source={ChevronDownMinor} />
											</LegacyStack>
										)}
									</div>
								}
								active={user.active}
								onClose={() => handleUpdateUser(index, "active")(false)}
								sectioned
								fullWidth
								action={
									<Button primary onClick={() => handleUpdateUser(index, "active")(false)}>
										Klar
									</Button>
								}
							>
								<UserRoles user={user} onChange={handleUpdateUser(index, "all_permissions")} roles={roleOptions} />
							</Popover>
						</div>
					</div>
				);
			})}
			<div style={{ marginTop: 10 }}>
				<LegacyStack distribution="fillEvenly">
					<div>
						<Button
							outline
							onClick={() => {
								setUsers([...users, { email: "", all_permissions: ["ROLE_CONTACTS"] }]);
							}}
						>
							<LegacyStack spacing="extraTight">
								<span style={{ display: "flex", height: "100%", alignItems: "center" }}>{t("user.actions.new_user", "Ny användare")}</span>{" "}
								<Icon source={CustomerPlusMajor} />
							</LegacyStack>
						</Button>
					</div>
					<div style={{ textAlign: "right" }}>
						<Button outline disabled={getValidUsers().length < 1} onClick={handleInviteUsers}>
							{t("user.actions.send_invitation", "Skicka inbjudan")}
						</Button>
					</div>
				</LegacyStack>
			</div>
		</div>
	);
});

export default withTranslation(["user", "common"], { withRef: true })(UserInviter);
