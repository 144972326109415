import React from "react";
import styled from "styled-components";

const LoadingContractRow = () => {
	return (
		<Wrapper>
			<td />
			<td />
			<td />
			<td />
		</Wrapper>
	);
};
export default LoadingContractRow;

const Wrapper = styled.tr<{ onClick?: any; selected?: boolean }>`
	background-color: var(--main2);
	transition: background-color 0.3s;

	&:hover {
		background-color: var(--main3);
	}
	td {
		height: 40px;
	}

	animation: pulse 2.5s infinite;
`;
