type OneOf<T, K extends keyof T> = {
	[P in K]-?: Required<Pick<T, P>> & Partial<Record<Exclude<K, P>, never>>;
}[K];

type FortnoxForm = OneOf<
	{
		invoice: FortnoxInvoiceType | null;
		order: FortnoxOrderType | null;
		offer: FortnoxOfferType | null;
	},
	"invoice" | "order" | "offer"
>;

const getFilledCustomerFields = (customer: FortnoxCustomerType, form: FortnoxForm | any) => {
	return {
		CustomerName: customer?.Name || "",
		CustomerNumber: customer?.CustomerNumber || "",
		OrganisationNumber: customer?.OrganisationNumber || "",
		Address1: customer?.Address1 || "",
		Address2: customer?.Address2 || "",
		ZipCode: customer?.ZipCode || "",
		City: customer?.City || "",
		Phone1: customer?.Phone1 || "",
		EmailInformation: {
			...(form?.EmailInformation || {}),
			EmailAddressTo: customer?.Email || "",
		},
		Country: customer?.Country || "",
	};
};

export default getFilledCustomerFields;
