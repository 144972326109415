import React, { Component } from "react";
import { TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Modal from "src/js/components/modal";

class UpdateContactMetafieldModal extends Component {
	constructor(props) {
		super(props);
		this.state = { value: "" };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.open) {
			this.setState({ value: "" });
		}
	}

	confirm() {
		this.props.onConfirm(this.state.value);
	}

	render() {
		let input = null;
		if (this.props.metafield) {
			if (this.props.metafield.type == "boolean") {
				input = (
					<Select
						options={[
							{ value: "", label: this.props.t("contact.metafield.actions.choose_value", "Välj ett värde") },
							{ value: "1", label: this.props.t("common.terms.yes", "Ja") },
							{ value: "0", label: this.props.t("common.terms.yes", "Nej") },
						]}
						onChange={(value) => {
							this.setState({ value });
						}}
						value={this.state.value}
					/>
				);
			} else if (this.props.metafield.type == "options") {
				const options = [{ label: this.props.t("contact.metafield.actions.choose_value", "Välj ett värde"), value: "" }];
				for (let i = 0; i < this.props.metafield.options.length; i++) {
					options.push(this.props.metafield.options[i]);
				}
				input = (
					<Select
						options={options}
						onChange={(value) => {
							this.setState({ value });
						}}
						value={this.state.value}
					/>
				);
			} else if (this.props.metafield.type == "input") {
				input = (
					<TextField
						onChange={(value) => {
							this.setState({ value });
						}}
						value={this.state.value}
					/>
				);
			}
		}

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("contact.metafield.title2", "Ändra {{title}} för {{counts}} kontakter", {
					title: this.props.metafield?.title || "",
					counts: this.props.numItems,
				})}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.change", "Ändra"),
					loading: this.props.loading,
					disabled: !this.state.value,
					onAction: this.confirm.bind(this),
				}}
			>
				<Modal.Section>{input}</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["contact", "common"], { withRef: true })(UpdateContactMetafieldModal);
