export default (state = [], action) => {
	switch (action.type) {
		case "SET_USERS":
			return action.users;
		case "UPDATE_USER": {
			const index = state.findIndex((u) => String(u.id) === String(action.user.id));
			if (index >= 0) {
				state.splice(index, 1, { ...state[index], ...action.user });
			}
			return [...state];
		}
		case "UPDATE_USERS": {
			return [
				...state.map((user) => {
					const newUser = action.users.find((usr) => String(usr.id) === String(user.id));
					return { ...user, ...(newUser || {}) };
				}),
			];
		}
		case "SET_ONLINE_USERS": {
			return [
				...state.map((user) => {
					return { ...user, online: action.userIds.includes(user.id) };
				}),
			];
		}
		default:
			return state;
	}
};
