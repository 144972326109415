import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Banner from "src/js/components/banner";
import { Wrapper } from "../styles";
import Tabs from "src/js/components/Tabs";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	order: Partial<FortnoxOrderType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const Header = ({ order, tabs, onSelect, selected, t }: HeaderProps) => {
	// const status = getStatus(document);
	return (
		<Wrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
			{order?.InvoiceReference && (
				<Banner
					status="info"
					title={t("fortnox.offers.status_invoice_create", "Faktura skapad") as string}
					actions={[
						{
							content: t("fortnox.order.actions.go_to_invoice", "Gå till faktura") as string,
							url: `/admin/fortnox/invoices/${order.InvoiceReference}`,
						},
					]}
				>
					{t("fortnox.offers.status_order_create_description1", "Faktura {{invoiceId}} har skapats i Fortnox.", {
						invoiceId: order?.InvoiceReference,
					})}
				</Banner>
			)}
			{order?.Cancelled && (
				<Banner status="info" title={t("fortnox.orders.statuses.cancelled.title", "Ordern makulerad")}>
					{t("fortnox.orders.statuses.cancelled.description", "Denna order är makulerad")}
				</Banner>
			)}
			{order?.Sent && (
				<Banner status="info" title={t("fortnox.orders.statuses.sent.title", "Order skickad")}>
					{t("fortnox.orders.statuses.ordert_sent.description", "Denna order är skickad")}
				</Banner>
			)}
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(Header);
