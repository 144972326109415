import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import ProfileAvatar from "../ProfileAvatar";

interface MentionProps extends WithTranslation {
	user: UserType;
	onRemove: (user?: UserType) => void;
	disabled?: boolean;
}

const Mention = ({ user, onRemove, disabled }: MentionProps) => {
	const handleOnRemove = () => {
		onRemove?.(user);
	};

	return (
		<Wrapper>
			<ProfileAvatar user={user} onClick={handleOnRemove} disabled={disabled} />
		</Wrapper>
	);
};

export default withTranslation(["board", "common"], { withRef: true })(Mention);

const Wrapper = styled.div.attrs({ className: "mention" })``;
