import React from "react";

const InfoIcon = ({ color = "4991CC" }) => (
	<svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.64062 4.45984C3.74519 4.45984 4.64062 3.56441 4.64062 2.45984C4.64062 1.35527 3.74519 0.459839 2.64062 0.459839C1.53606 0.459839 0.640625 1.35527 0.640625 2.45984C0.640625 3.56441 1.53606 4.45984 2.64062 4.45984Z"
			fill={color}
		/>
		<path d="M0.640625 5.92981H4.64062V17.9298H0.640625V5.92981Z" fill={color} />
	</svg>
);

export default InfoIcon;
