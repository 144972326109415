import { Popover, Select } from "@shopify/polaris";
import React, { Component } from "react";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { withTranslation } from "react-i18next";
import MyPopover from "../../components/Popover";

const moment = extendMoment(Moment);

class GoalDateSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			year: this.getYear(props),
			month: this.getMonth(props),
			week: this.getWeek(props),
			goal: props.goal,
		};
	}

	getMonth(props = this.props) {
		const month = (props.goal && ["annualy", "monthly"].includes(props.goal.interval) && String(moment(props.goal.start_at).format("MM"))) || "";
		return month;
	}

	getWeek(props = this.props) {
		return (props.goal && ["weekly"].includes(props.goal.interval) && String(moment(props.goal.start_at).isoWeek())) || "";
	}

	getYear(props = this.props) {
		const year = (props.goal && String(moment(props.goal.start_at).format("YYYY"))) || "";
		return year;
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (
			(this.props.goal && this.props.goal.interval) !== (props.goal && props.goal.interval) ||
			(props.goal && props.goal.id) !== (this.props.goal && this.props.goal.id)
		) {
			this.setState({
				year: this.getYear(props),
				month: this.getMonth(props),
				week: this.getWeek(props),
				goal: props.goal,
			});
		}
	}

	closeDate() {
		this.setState({ dateOpen: false });
	}

	toggleDate() {
		this.setState(
			(c) => ({ dateOpen: !c.dateOpen }),
			() => {
				setTimeout(() => {
					// const active = document.querySelector(".date_year.active");
					// Sometimes causes scrolling of the whole window and the popover dissapears
					// if (active) active.scrollIntoView();
				}, 0);
			}
		);
	}

	getYears(to) {
		const mom = moment();
		const dates = [];

		while (mom > moment(to)) {
			dates.push({
				value: mom.format("YYYY"),
				label: mom.format("YYYY"),
			});
			mom.subtract(1, "year");
		}

		return dates;
	}

	getMonths() {
		if (!Array.isArray(this.props.previous_goals)) return [];

		const goalsThisYear = this.props.previous_goals
			?.filter(
				(goal) =>
					moment(goal.start_at).isSame(moment().set("year", this.state.year), "year") ||
					moment(goal.end_at).isSame(moment().set("year", this.state.year), "year")
			)
			?.sort((a, b) => a.start_at - b.start_at);

		const monthRange = moment.range(goalsThisYear?.at(-1)?.start_at, goalsThisYear?.at(0).end_at);
		const months = [
			...new Set(
				[...monthRange.by("month")].map((month) => ({
					value: String(month.format("MM")),
					label: month.locale("sv").format("MMMM").capitalize(),
				}))
			),
		];

		return [
			{
				value: "",
				label: !this.state.month ? this.props.t("goals.actions.month.choose", "Välj månad") : this.props.t("goals.actions.month.non", "Ingen månad"),
			},
			...months.reverse(),
		];
	}

	getWeeks() {
		if (!this.state.month) return [];
		const firstDay = moment()
			.set("year", this.state.year)
			.set("month", this.state.month - 1)
			.startOf("month");
		const endDay = moment()
			.set("year", this.state.year)
			.set("month", this.state.month - 1)
			.endOf("month");

		const monthRange = moment.range(
			moment(firstDay).isBefore(moment(this.props.previous_goals.at(-1).start_at)) ? this.props.previous_goals.at(0 - 1).start_at : firstDay,
			moment(endDay).isAfter(moment(this.props.previous_goals.at(0).end_at)) ? this.props.previous_goals.at(0).end_at : endDay
		);

		const weeks = [...new Set([...monthRange.by("week")].map((week) => week.isoWeek()))];

		return [
			{
				value: "",
				label: !this.state.week ? this.props.t("goals.actions.week.choose", "Välj vecka") : this.props.t("goals.actions.week.non", "Ingen vecka"),
			},
			...weeks.map((week) => ({
				value: String(week),
				label: week,
			})),
		];
	}

	onChange(field, value) {
		if (field === "month") this.state.week = "";
		if (field === "year") {
			const goalsThisYear = this.props.previous_goals
				.filter(
					(goal) =>
						moment(goal.start_at).isSame(moment().set("year", value), "year") || moment(goal.end_at).isSame(moment().set("year", value), "year"),
					"year"
				)
				.sort((a, b) => a.start_at - b.start_at);

			const monthRange = moment.range(goalsThisYear.at(-1)?.start_at, goalsThisYear.at(0).end_at);

			const months = [...new Set([...monthRange.by("month")].map((month) => String(month.format("MM"))))];
			if (!months.includes(this.state.month)) {
				this.state.month = "";
			}
		}

		this.setState({ [field]: String(value) }, () => {
			const shouldUpdate = (() => {
				if (this.props.goal.interval === "annually" && this.state.year) return true;
				if (this.props.goal.interval === "monthly" && this.state.month) return true;
				if (this.props.goal.interval === "weekly" && this.state.week) return true;
			})();

			if (shouldUpdate) {
				const momentDate = moment().set("year", this.state.year);
				if (this.state.month) momentDate.set("month", this.state.month - 1);
				if (this.state.week) momentDate.set("isoWeek", this.state.week);

				const from = (() => {
					if (this.state.week) return momentDate.startOf("isoWeek").format("YYYY-MM-DD");
					if (this.state.month) return momentDate.startOf("month").format("YYYY-MM-DD");
					if (this.state.year) return momentDate.startOf("year").format("YYYY-MM-DD");

					return moment().format("YYYY-MM-DD");
				})();

				const to = (() => {
					if (this.state.week) return momentDate.endOf("isoWeek").format("YYYY-MM-DD");
					if (this.state.month) return momentDate.endOf("month").format("YYYY-MM-DD");
					if (this.state.year) return momentDate.endOf("year").format("YYYY-MM-DD");

					return moment().format("YYYY-MM-DD");
				})();

				this.props.onChange(from, moment(to).isAfter(moment(), "days") ? moment().format("YYYY-MM-DD") : to, this.getDateIntervalType());
			}
		});
	}

	getPeriodOptions() {
		if (!this.props.previous_goals) return [];

		return (
			this.props.previous_goals.map((goal) => ({
				label: `${goal.start_at} - ${goal.end_at}`,
				value: goal.id + "",
			})) || []
		);
	}

	getDateIntervalType() {
		if (this.state.week) return "weekly";
		if (this.state.month) return "monthly";
		if (this.state.year) return "annualy";
		return this.props.goal.interval;
	}

	render() {
		const text = (
			<div className="date-navigation">
				<span>{this.props.from}</span>
				{" - "}
				<span> {this.props.to}</span>
			</div>
		);

		if (this.props.plain) return text;

		return (
			<MyPopover
				active={this.state.dateOpen}
				onClose={this.closeDate.bind(this)}
				activator={
					<div style={{ cursor: "pointer" }} onClick={this.toggleDate.bind(this)}>
						{text}
					</div>
				}
			>
				<Popover.Section>
					{this.props.goal && this.props.goal.interval === "period" ? (
						<Select
							label={this.props.t("email_contact.terms.period", "Period")}
							options={this.getPeriodOptions()}
							onChange={(id) => {
								const goal = this.props.previous_goals.find((goal) => String(goal.id) === String(id));
								this.setState({ goal });

								if (goal) this.props.onChange(goal.start_at, goal.end_at, goal.intervall);
							}}
							value={String(this.state.goal?.id || "")}
						/>
					) : (
						<React.Fragment>
							<Select
								disabled={!this.props.goal}
								label={this.props.t("common.terms.year", "År")}
								value={String(this.state.year)}
								onChange={this.onChange.bind(this, "year")}
								options={this.getYears(this.props.firstDate || "2010-01-01")}
							/>
							{!!this.state.year && (
								<Select
									label={this.props.t("common.terms.month", "Månad")}
									value={String(this.state.month)}
									onChange={this.onChange.bind(this, "month")}
									options={this.getMonths()}
								/>
							)}
							{!!this.state.month && (
								<Select
									label={this.props.t("common.terms.week", "Vecka")}
									value={String(this.state.week)}
									onChange={this.onChange.bind(this, "week")}
									options={this.getWeeks()}
								/>
							)}
						</React.Fragment>
					)}
				</Popover.Section>
			</MyPopover>
		);
	}
}
export default withTranslation(["goals", "common"], { withRef: true })(GoalDateSelector);
