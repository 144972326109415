import React from "react";

const CancelIcon = ({ color = "var(--textColor)" }) => (
	<svg width="20" height="19" viewBox="0 0 20 19" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.1585 3.81501C18.9601 3.05509 18.994 1.78921 18.234 0.987587C17.4741 0.185959 16.2083 0.152142 15.4066 0.912054L9.56904 6.44586L3.73145 0.912054C2.92982 0.152142 1.66395 0.185959 0.904034 0.987587C0.144122 1.78921 0.177939 3.05509 0.979567 3.81501L6.66194 9.20168L0.678749 14.8735C-0.122879 15.6334 -0.156696 16.8993 0.603217 17.7009C1.36313 18.5026 2.62901 18.5364 3.43064 17.7765L9.56904 11.9575L15.7074 17.7765C16.5091 18.5364 17.775 18.5026 18.5349 17.7009C19.2948 16.8993 19.261 15.6334 18.4593 14.8735L12.4761 9.20168L18.1585 3.81501Z"
			fill={color}
		/>
	</svg>
);

export default CancelIcon;
