import i18next from "i18next";

import order from "./ColumnOptions/Fortnox/order";
import invoice from "./ColumnOptions/Fortnox/invoice";
import contract from "./ColumnOptions/Fortnox/contract";
import offer from "./ColumnOptions/Fortnox/offer";
import customer from "./ColumnOptions/Fortnox/customer";
import article from "./ColumnOptions/Fortnox/article";
import getContactOptions from "./ColumnOptions/contact";
import getUserOptions from "./ColumnOptions/user";
import getAccountOptions from "./ColumnOptions/account";
import getOrderOptions from "./ColumnOptions/order";

const filterOptionsByType = (
	options,
	{
		types = [],
		excludeTypes = [],
	}: {
		types?: string[];
		excludeTypes?: string[];
	} = {}
) => {
	const filteredOptions: any[] = [];

	options.forEach((option) => {
		if (option.options && option.options.length > 0) {
			const filteredSubOptions = filterOptionsByType(option.options, { types, excludeTypes });

			if (filteredSubOptions.length > 0 && !excludeTypes?.includes(option.type)) {
				filteredOptions.push({
					...option,
					options: filteredSubOptions,
				});
			} else if ((!types?.length || types?.includes(option.type)) && !excludeTypes?.includes(option.type)) {
				filteredOptions.push({ ...option, options: filteredSubOptions });
			}
		} else if ((!types?.length || types?.includes(option.type)) && !excludeTypes?.includes(option.type)) {
			filteredOptions.push(option);
		}
	});

	return filteredOptions;
};

const getColumnOptions = ({ types, excludeTypes } = { types: [], excludeTypes: [] }) => {
	const options = [
		{ label: i18next.t("common.selectValue", "Välj värde"), value: "" },
		{ label: i18next.t("common.todaysDate", "Dagens datum"), value: "today_full_date" },
		{ label: i18next.t("common.todaysDateWithTime", "Dagens datum med tid"), value: "today_full_date_with_time" },
		{ label: i18next.t("common.todaysTime", "Dagens tid"), value: "today_time" },

		getUserOptions(),
		getAccountOptions(),
		getContactOptions(),
		getOrderOptions(),
		{
			label: i18next.t("fortnox.label", "Fortnox"),
			value: "Fortnox",
			options: [contract, invoice, order, offer, customer, article],
		},
	];

	return filterOptionsByType(options, { types, excludeTypes });
};

export default getColumnOptions;

const getOptionsRecursive = (options) => {
	return options?.flatMap((o) => {
		if (o.options) {
			return [o, ...getOptionsRecursive(o.options)];
		}

		return o;
	});
};

export const findOptionByValue = (options, searchValue) => {
	return getOptionsRecursive(options).find((option) => option.value === searchValue);
};

export const findOptionOptionsByValue = (options, searchValue) => {
	if (!searchValue) return getOptionsRecursive(options);

	return getOptionsRecursive(options).find((option) => option.value === searchValue && option.options)?.options;
};

export const findOptionOptionsByType = (options, type, fullKey) => {
	return getOptionsRecursive(options)?.filter((option) => option.type === type) || [];
};

export const findFullOptionByValue = (options, searchValue) => {
	return options.find((option) => option.value === searchValue || (option.options && findOptionInOptions(option.options, searchValue)));
};

export const findOptionsByValue = (options, searchValue, onlyReturnTheMatchedOption) => {
	return options?.filter((option) => option.value === searchValue || (option.options && findOptionInOptions(option.options, searchValue))) || [];
};

// Auxiliary recursive search specifically within sub-options
const findOptionInOptions = (options, searchValue) => {
	return options.some((option) => option.value === searchValue || (option.options && findOptionInOptions(option.options, searchValue)));
};
