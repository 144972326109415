import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextField from "../TextField";

const HARD_LIMIT = 1000;

const LimitField = ({ value, onChange }) => {
	const [tempValue, setTempValue] = useState(value);

	const handlesSetTempValue = (value) => {
		setTempValue(Math.min(value, HARD_LIMIT));
	};

	useEffect(() => {
		setTempValue(Math.min(value, HARD_LIMIT));
	}, [value]);

	const handleSave = () => {
		onChange(tempValue);
	};

	return (
		<Wrapper>
			<TextField value={tempValue} onChange={handlesSetTempValue} onBlur={handleSave} suffix="limit" />
		</Wrapper>
	);
};
export default LimitField;

const Wrapper = styled.div`
	opacity: 0.5;
	transition: opacity 250ms;

	&:focus,
	:focus-within,
	&:hover {
		opacity: 1;
	}
`;
