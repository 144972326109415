import React, { Component } from "react";
import Colors, { colorToRgba } from "../Colors";
import "../../css/NumberBadge.scss";

/**
 * @param {children} [children] - Children
 * @param {Function} [onClick] - event
 * @param {String} [color] - color
 * @param {Number} [alpha] - alpha
 * @param {Number} [fontSize] - alpha
 * @param {Object} [style] - style
 */
export default class NumberBadge extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div
				className={`number number_badge ${this.props.className} ${this.props.onClick ? "clickable" : ""}`}
				onClick={this.props.onClick}
				style={Object.assign(
					{
						backgroundColor: colorToRgba(this.props.color, this.props.alpha || 0.3),
						color: this.props.textColor || this.props.color,
					},
					this.props.fontSize ? { fontSize: this.props.fontSize } : {},
					this.props.round ? { borderRadius: "20px" } : {},
					this.props.style || {}
				)}
			>
				{this.props.children}
			</div>
		);
	}
}

NumberBadge.defaultProps = {
	color: Colors.yellow,
};
