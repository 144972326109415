/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, FormLayout, Label, Tooltip } from "@shopify/polaris";
import { CirclePlusMajor, LinkMinor, ListMajor, MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField/TextField";
// import Popover from "src/js/components/Popover";
import Modal from "src/js/components/modal/Modal";
import Popover from "src/js/components/Popover";
import TableHeaderColumn from "./TableHeaderColumn";
import ColorPicker from "src/js/components/ColorPicker2";
import Colors, { RGBToHex } from "src/js/Colors";
import FontSizeChanger from "../FontSizeChanger";
import { merge, mergeWith, cloneDeep } from "lodash";
import styled from "styled-components";
import Group from "src/js/components/Styles/Group";
import getColumnOptions, { findOptionOptionsByType } from "./getColumnOptions";
import Select from "src/js/components/select/Select";
import SelectOptions from "./SelectOptions";
import NestedOptionsList from "src/js/components/NestedOptionsList";
import setValueByPath from "src/js/Utilities/setValueByPath";
import getFontFamilies from "../getFontFamilies";

function customizer(objValue, srcValue, key, object, source, stack) {
	if (Array.isArray(objValue)) {
		return srcValue.map((srcItem, index) => merge({}, objValue[index], srcItem));
	}
}

export const DEFAULT_FONT_SIZE = 12;
export const DEFAULT_COLUMN_FONT_SIZE = 12;
const DEFAULT_COLUNM_WIDTH = 100;

const DEFAULT_COLUMN = {
	label: "kolumn",
	value: "",
	fontSize: DEFAULT_COLUMN_FONT_SIZE,
	fontWeight: "bold",
	textAlign: "left",
	width: DEFAULT_COLUNM_WIDTH,

	rows: {
		fontSize: DEFAULT_FONT_SIZE,
		textAlign: "left",
	},
};

const defaultData = {
	numRows: 1,
	type: "table",
	rowsProperty: null,
	columns: [DEFAULT_COLUMN, DEFAULT_COLUMN, DEFAULT_COLUMN],
	rows: [],
	options: {
		textColor: "#000000",
		fontSize: DEFAULT_FONT_SIZE,
		// cellWidth: 50,
		cellHeight: 30,
		cellSpacing: { vertical: 5, horizontal: 5 },
		cellPadding: { vertical: 2, horizontal: 2 },
		cellBackgroundColor: "transparent",
	},
};

const AddTable = ({ onSubmit, t, data: propsData, element }) => {
	const [open, setOpen] = useState(false);

	const [data, setData] = useState(mergeWith(cloneDeep(defaultData), propsData, customizer));

	const fonts = useMemo(() => getFontFamilies({ fontSize: data?.options?.fontSize }), [data?.options?.fontSize]);

	useEffect(() => {
		if (!open) {
			setData(propsData || cloneDeep(defaultData));
		}
	}, [open, propsData]);

	useEffect(() => {
		setData((c) => {
			return merge(c, propsData);
		});
	}, [propsData]);

	const onToggle = () => {
		setOpen(!open);
	};

	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setData((curr) => {
			const data = { ...curr };

			const rows = [...Array(data.numRows || 0)].map((_, i) => {
				const row = data.rows?.[i] || [...Array(data.columns.length)].map(() => "");

				data.columns.forEach((column, index) => {
					const property = column.value
						? `{{${(data.rowsProperty ? `${data.rowsProperty.replace(/{{|}}/g, "")}[${i}]` : "") + (column.value ? `.${column.value}` : "")}}}`
						: "";

					row[index] = row?.[index] || property || "";
				});

				return row;
			});

			data.rows = rows;

			return { ...data };
		});
	}, [data?.columns, data?.numRows, data?.rowsProperty]);

	const handleChangeData =
		(field, formatter = null) =>
		(value) => {
			setData((curr) => {
				const data = {
					...setValueByPath({ ...curr }, field, formatter ? formatter(value) : value),
				};
				return data;
			});
		};

	const handleChangeColumns = (index) => (columnData) => {
		const previousData = JSON.parse(JSON.stringify(data));
		const columns = JSON.parse(JSON.stringify(previousData.columns || []));
		const originalColumn = JSON.parse(JSON.stringify(columnData || {}));
		columns[index] = columnData;

		if ((previousData?.value || previousData?.rowsProperty) !== (originalColumn?.value || originalColumn?.rowsProperty)) {
			const column = columns[index];
			const rows = previousData.rows.map((row, rowIndex) => {
				const property =
					column?.value !== originalColumn?.value && column.value
						? `{{${
								(previousData.rowsProperty ? `${previousData.rowsProperty.replace(/{{|}}/g, "")}[${rowIndex}]` : "") +
								(column.value ? `.${column.value}` : "")
						  }}}`
						: row[index] || "";

				row[index] = property || "";
				return row;
			});

			setData((data) => ({ ...data, columns, rows }));
			return;
		}

		handleChangeData("columns")(columns);
	};

	const handleChangeRows = (index, column_index) => (value) => {
		setData((curr) => {
			const rows = [...curr.rows];
			rows[index][column_index] = value;
			return { ...curr, rows };
		});
	};

	const handleSubmit = () => {
		onSubmit(cloneDeep(data));
		setTimeout(() => {
			setOpen(false);
		}, 1);
	};

	const handleRemoveColumn = (index) => {
		const columns = data.columns;
		columns.splice(index, 1);

		const rows = data.rows.map((row) => {
			row.splice(index, 1);
			return row;
		});

		setData({ ...data, columns, rows });
	};

	const groupProps = {
		labelBackgroundColor: "var(--modals-bg)",
		style: {
			display: "flex",
			flexDirection: "row",
			gap: "10px",
			marginBottom: "20px",
		},
	};

	const title = element ? t("pdf.editor.actions.table.update", "Uppdatera tabell") : t("pdf.editor.actions.table.add", "Lägg till en tabell");
	return (
		<>
			<Tooltip content={title}>
				<Button onClick={onToggle} icon={ListMajor} />
			</Tooltip>
			<Modal
				large
				title={title}
				active={open}
				open={open}
				onClose={onClose}
				sectioned
				primaryAction={{
					content: t("common.actions.save", "Spara"),
					onAction: handleSubmit,
				}}
				secondaryActions={[
					{
						content: t("common.actions.cancel", "Avbryt"),
						onAction: onClose,
					},
				]}
			>
				<Wrapper style={{ minWidth: 600 }}>
					<Group label={t("pdf.editor.table.settings.cells", "Tabellalternativ")} {...groupProps}>
						<FormLayout.Group>
							<NestedOptionsList
								label={t("pdf.editor.table.rowsProperty", "Var ska raderna hämtas ifrån")}
								// options={findOptionOptionsByType(getColumnOptions(), "array")}
								options={getColumnOptions({ types: ["array"] })}
								value={data.rowsProperty?.replace(/{{|}}/g, "")}
								onChange={handleChangeData("rowsProperty", null)}
							/>

							<TextField
								label={t("pdf.editor.table.numRows", "Antal rader")}
								type="number"
								value={data.numRows}
								onChange={handleChangeData("numRows", (v) => parseInt(v))}
							/>
							<TextField
								label={t("pdf.editor.table.cellHeight", "Cellhöjd {{suffix}}", { suffix: data.options?.cellHeightAutoAdjust ? "(min)" : "" })}
								type="number"
								value={data.options?.cellHeight}
								onChange={handleChangeData("options.cellHeight", (v) => parseInt(v))}
								suffix={
									<Tooltip content={t("pdf.editor.table.auto_tooltip", "Automatisk justering av cellhöjd så texten får plats")}>
										<Checkbox
											label={t("pdf.editor.table.auto", "Auto")}
											checked={data.options?.cellHeightAutoAdjust}
											onChange={handleChangeData("options.cellHeightAutoAdjust")}
										/>
									</Tooltip>
								}
							/>
						</FormLayout.Group>
						<FormLayout.Group>
							<TextField
								label={t("pdf.editor.table.cellSpacing_vertical", "Cell mellanrum vertikalt")}
								type="number"
								value={data.options?.cellSpacing?.vertical}
								onChange={handleChangeData("options.cellSpacing.vertical", (v) => parseInt(v))}
							/>
							<TextField
								label={t("pdf.editor.table.cellSpacing_horizontal", "Cell mellanrum horisontellt")}
								type="number"
								value={data.options?.cellSpacing?.horizontal}
								onChange={handleChangeData("options.cellSpacing.horizontal", (v) => parseInt(v))}
							/>

							<TextField
								label={t("pdf.editor.table.cellPadding_vertical", "Cell padding vertikalt")}
								type="number"
								value={data.options?.cellPadding?.vertical}
								onChange={handleChangeData("options.cellPadding.vertical", (v) => parseInt(v))}
							/>
							<TextField
								label={t("pdf.editor.table.cellPadding_horizontal", "Cell padding horisontellt")}
								type="number"
								value={data.options?.cellPadding?.horizontal}
								onChange={handleChangeData("options.cellPadding.horizontal", (v) => parseInt(v))}
							/>
						</FormLayout.Group>
						<FormLayout.Group>
							<ColorPicker
								label={t("pdf.editor.table.cellBackgroundColor", "Cell backgrounds färg")}
								value={data.options?.cellBackgroundColor}
								onChange={handleChangeData("options.cellBackgroundColor")}
								activatorStyle={{ width: "100%" }}
							/>
							<ColorPicker
								label={t("pdf.editor.table.textColor", "Textfärg")}
								value={data.options?.textColor}
								onChange={handleChangeData("options.textColor")}
								activatorStyle={{ width: "100%" }}
							/>

							<FontSizeChanger
								style={{
									width: "100%",
								}}
								label={t("pdf.editor.table.fontSize", "Textstorlek")}
								value={data.options?.fontSize}
								onChange={handleChangeData("options.fontSize", (v) => parseInt(v))}
							/>

							{!!fonts?.length && (
								<Select
									label={t("pdf.editor.table.fontFamily", "Typsnitt")}
									placeholder={data.options.fontFamily || "Välj typsnitt"}
									value={data.options.fontFamily}
									options={fonts}
									onChange={handleChangeData("options.fontFamily")}
								/>
							)}
						</FormLayout.Group>
					</Group>

					{/* <Group label={t("pdf.editor.table.settings.table", "Tabell")} {...groupProps}> */}
					<div style={{ overflow: "auto" }}>
						<table>
							<thead style={{ marginBottom: 10 }}>
								<tr>
									{data?.columns?.map((column, i, array) => (
										<td key={array.length - i}>
											<TableHeaderColumn
												rowsProperty={data?.rowsProperty?.replace(/{{|}}/g, "")}
												key={array.length - i}
												column={{ ...column }}
												index={i}
												onChange={handleChangeColumns}
												onRemove={handleRemoveColumn}
											/>
										</td>
									))}
									<td>
										<Button
											icon={CirclePlusMajor}
											onClick={() =>
												setData({
													...data,
													columns: [...data.columns, DEFAULT_COLUMN],
												})
											}
										/>
									</td>
								</tr>
							</thead>

							<tbody>
								{data.rows?.map((_, i) => (
									<tr key={i}>
										{data.columns.map((column, j) => (
											<td key={j}>
												<TextField value={data.rows[i][j]} onChange={handleChangeRows(i, j)} />
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{/* </Group> */}
				</Wrapper>
			</Modal>
		</>
	);
};

export default withTranslation(["pdf", "common"], { withRef: true })(AddTable);

const Wrapper = styled.div`
	tbody:before {
		line-height: 1em;
		height: 10px;
		content: "";
		display: block;
	}

	table {
		td {
			min-width: 100px;
		}

		thead {
			.Polaris-TextField .Polaris-TextField__Suffix {
				opacity: 0.5;
				transition: opacity 0.3s ease-in-out;

				&:hover,
				&:active {
					opacity: 1;
				}

				button {
					display: flex;
				}
			}
		}
	}

	.Polaris-FormLayout__Items {
		display: flex;
		gap: 0.625rem;
	}
`;
