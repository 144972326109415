import React from "react";

const CalendarMonthIcon = ({ color = "var(--textColor)" }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.59668 4.63641C3.59668 3.8425 4.24027 3.19891 5.03418 3.19891H19.4092C20.2031 3.19891 20.8467 3.8425 20.8467 4.63641V19.0114C20.8467 19.8053 20.2031 20.4489 19.4092 20.4489H5.03418C4.24027 20.4489 3.59668 19.8053 3.59668 19.0114V4.63641ZM19.4092 4.63641H5.03418V19.0114H19.4092V4.63641Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.5342 1.76141C16.9311 1.76141 17.2529 2.08321 17.2529 2.48016V5.35516C17.2529 5.75212 16.9311 6.07391 16.5342 6.07391C16.1372 6.07391 15.8154 5.75212 15.8154 5.35516V2.48016C15.8154 2.08321 16.1372 1.76141 16.5342 1.76141Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.90918 1.76141C8.30613 1.76141 8.62793 2.08321 8.62793 2.48016V5.35516C8.62793 5.75212 8.30613 6.07391 7.90918 6.07391C7.51223 6.07391 7.19043 5.75212 7.19043 5.35516V2.48016C7.19043 2.08321 7.51223 1.76141 7.90918 1.76141Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.59668 8.23016C3.59668 7.83321 3.91848 7.51141 4.31543 7.51141H20.1279C20.5249 7.51141 20.8467 7.83321 20.8467 8.23016C20.8467 8.62712 20.5249 8.94891 20.1279 8.94891H4.31543C3.91848 8.94891 3.59668 8.62712 3.59668 8.23016Z"
			fill={color}
		/>
		<path d="M5.7373 12.7755H7.88794V14.9261H5.7373V12.7755Z" fill={color} />
		<path d="M8.44043 12.7755H10.5911V14.9261H8.44043V12.7755Z" fill={color} />
		<path d="M11.1455 9.97314H13.2961V12.1238H11.1455V9.97314Z" fill={color} />
		<path d="M13.8486 9.97314H15.9993V12.1238H13.8486V9.97314Z" fill={color} />
		<path d="M16.5518 9.97314H18.7024V12.1238H16.5518V9.97314Z" fill={color} />
		<path d="M5.7373 12.7756H7.88794V14.9262H5.7373V12.7756Z" fill={color} />
		<path d="M8.44043 12.7756H10.5911V14.9262H8.44043V12.7756Z" fill={color} />
		<path d="M11.1436 12.7756H13.2942V14.9262H11.1436V12.7756Z" fill={color} />
		<path d="M13.8467 12.7756H15.9973V14.9262H13.8467V12.7756Z" fill={color} />
		<path d="M16.5498 12.7756H18.7004V14.9262H16.5498V12.7756Z" fill={color} />
		<path d="M5.7373 15.5783H7.88794V17.7289H5.7373V15.5783Z" fill={color} />
		<path d="M8.44043 15.5783H10.5911V17.7289H8.44043V15.5783Z" fill={color} />
		<path d="M11.1436 15.5783H13.2942V17.7289H11.1436V15.5783Z" fill={color} />
	</svg>
);

export default CalendarMonthIcon;
