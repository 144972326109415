import React, { Component } from "react";
import { Button, FormLayout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import RowLinkConstraint from "./RowLinkConstraint.js";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

class RowlinkConstraintsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			options: this.props.column.options || {
				constraints: [],
				contact_column_id: null,
			},
		};

		if (!this.state.options?.constraints) {
			this.state.options.constraints = [];
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			const options = this.props.column.options || {
				constraints: [],
				contact_column_id: null,
			};

			if (!options?.constraints) {
				options.constraints = [];
			}
			this.setState({
				open: props.open,
				options,
			});
		}
	}

	saveForm() {
		this.setState({ saving: true });
		const data = {
			options: this.state.options,
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				this.props.onUpdateColumn(result.data.column);
				Toast.success(this.props.t("row.constraints.responses.saved", "Sparade regler"));
				this.props.onClose();
				this.setState({ saving: false });
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	updateConstraint(index, item) {
		this.state.options.constraints[index] = item;
		this.setState({ options: this.state.options });
	}

	removeConstraint(index) {
		this.state.options.constraints.splice(index, 1);
		this.setState({ options: this.state.options });
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("row.constraints.actions.change", "Ändra regler")}
				primaryAction={{
					onAction: this.saveForm.bind(this),
					loading: this.state.saving,
					content: this.props.t("common.actions.change", "Ändra"),
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<FormLayout>
						<p>
							{this.props.t(
								"row.constraints.text",
								"Hantera regler för kunna länka rader, t.ex. att vissa fält får inte vara tomma innan man får skapa länkade rader"
							)}
						</p>
					</FormLayout>
				</Modal.Section>
				{this.state.options.constraints?.map((constraint, index) => (
					<RowLinkConstraint
						key={index}
						board={this.props.board}
						constraint={constraint}
						onRemove={this.removeConstraint.bind(this, index)}
						onChange={this.updateConstraint.bind(this, index)}
					/>
				))}
				<Modal.Section>
					<Button
						plain
						onClick={() => {
							this.state.options.constraints.push({
								column_id: null,
								column_type: null,
								operator: "is_set",
								value: null,
							});
							this.setState({ options: this.state.options });
						}}
					>
						{this.props.t("row.constraints.actions.new", "Ny regel")}
					</Button>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["row", "common"], { withRef: true })(RowlinkConstraintsModal);
