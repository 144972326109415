import { Autocomplete, Icon, TextContainer } from "@shopify/polaris";
import { ChevronDownMinor, SearchMajor } from "@shopify/polaris-icons";
import React, { useMemo, useState } from "react";
import useDebounce from "src/js/hooks/useDebounce";
import useQuery from "src/js/hooks/useQuery";
import styled, { css } from "styled-components";
import API from "src/js/API";
import { withTranslation, WithTranslation } from "react-i18next";
import { useDeepCompareEffect } from "use-deep-compare";
import Spinner from "../Spinner";
import Toast from "src/js/components/Toast";

//!TODO: allowMultiple is not yet done

type SelectSearchFieldProps = {
	style?: React.CSSProperties;
	resourceName: { singular: string; plural: string };
	resource: string;
	resourceHandle: string;
	resourceURL?: string;
	searchHandle?: string | null;
	prefix?: React.ReactNode | string;
	label?: string;
	suffix?: React.ReactNode | string;
	disabled?: boolean;
	readOnly?: boolean;
	onClear?: () => void | boolean;
	placeholder?: string;
	error?: string | boolean;
	value: any | any[] | null;
	onSelect: (value: any) => void;
	params?: any;
	idHandle: string;
	labelHandle: string;
	renderLabel?: (item: any) => string;
	renderValue?: (value: string | any, data?: any) => string;
	allowMultiple?: boolean;
	required?: boolean;
	fetchOnMount?: boolean;
	filterResults?: (results: any[]) => any[];
	limit?: number;
	clearOnSelect?: boolean;
	autoFocus?: boolean;
} & WithTranslation;

const SelectSearchField = ({
	style,
	resourceName,
	resource,
	resourceHandle,
	resourceURL,
	searchHandle = "q",
	prefix,
	label,
	suffix,
	disabled,
	readOnly,
	onClear,
	placeholder,
	error,
	value,
	onSelect,
	params: propsParams,
	idHandle = "id",
	labelHandle,
	renderLabel,
	renderValue,
	allowMultiple = false,
	required,
	t,
	filterResults,
	fetchOnMount = false,
	limit = 25,
	clearOnSelect = false,
	autoFocus = false,
}: SelectSearchFieldProps) => {
	const [input, setInput] = useState((renderValue ? renderValue(value, null) : value) || "");
	const [enabled, setEnabled] = useState(fetchOnMount);
	const [isLoadingPrefix, setIsLoadingPrefix] = useState(false);
	const ref = React.useRef<HTMLDivElement>(null);

	// const [search, setSearch] = useState("");

	const search = useDebounce(input, input ? 300 : 0);

	const handleOnChange = (value) => {
		setInput(value);
	};

	const url = useMemo(() => {
		if (resourceURL) return resourceURL;

		let url = resource;

		if (!url?.includes("/")) url = `/api/${url}`;
		if (!url?.startsWith("/api")) url = `/api/${url}`;
		if (!url?.endsWith(".json")) url = `${url}.json`;

		return url;
	}, [resourceURL, resource]);

	const params = useMemo(() => {
		const requestParams = { limit, ...(propsParams || {}) };

		if (searchHandle !== null) {
			requestParams[searchHandle] = search;
		}

		return requestParams;
	}, [propsParams, limit, search, searchHandle]);

	const fetch = async () => {
		try {
			const result = await API.get(url, { params });

			return result.data?.[resourceHandle] || [];
		} catch (e) {
			Toast.error(e);
			throw e;
		}
	};

	const queryKey = [resourceHandle, JSON.stringify(params)];

	const { data, isFetching } = useQuery({
		queryKey,
		queryFn: fetch,
		enabled,
		initialData: [],
	});

	useDeepCompareEffect(() => {
		if (document.activeElement !== ref.current?.querySelector("input")) {
			setInput((renderValue ? renderValue(value, data) : value) || "");
		}
	}, [value, renderValue, data]);

	const handleClear = () => {
		setInput("");
		onClear?.();
		// onSelect(null);
	};

	const handleFocus = () => {
		setEnabled(true);
	};
	const handleBlur = () => {
		// setEnabled(false);
	};

	const activator = (
		<Autocomplete.TextField
			label={label}
			requiredIndicator={required}
			prefix={
				isLoadingPrefix ? (
					<Spinner size={"small"} />
				) : prefix !== undefined ? (
					React.isValidElement(prefix) || prefix === null ? (
						prefix
					) : (
						<Icon source={prefix as any} />
					)
				) : searchHandle ? (
					<Icon source={SearchMajor} />
				) : (
					<Icon source={ChevronDownMinor} />
				)
			}
			suffix={suffix}
			autoComplete="off"
			placeholder={placeholder}
			error={error}
			type="search"
			value={String(input || "")}
			onChange={handleOnChange}
			clearButton={!!onClear && value}
			onClearButtonClick={onClear ? handleClear : undefined}
			disabled={disabled}
			readOnly={readOnly}
			onFocus={handleFocus}
			onBlur={handleBlur}
			autoFocus={autoFocus}
		/>
	);

	const emptyState = (
		<React.Fragment>
			<Icon source={SearchMajor} />
			<div style={{ textAlign: "center" }}>
				<TextContainer>{t("searches.no_object_found", "Inga {{ object }} hittades", { object: resourceName.plural })}</TextContainer>
			</div>
		</React.Fragment>
	);

	const options = useMemo(() => {
		if (!data) return [];
		return (filterResults ? filterResults(data) : data).map((item) => ({
			value: String(item[idHandle]),
			label: renderLabel ? renderLabel(item) : item[labelHandle],
		}));
	}, [data, idHandle, labelHandle, renderLabel, filterResults]);

	const handleSelect = async (value: string[]) => {
		setIsLoadingPrefix(true);

		if (allowMultiple) {
			const items = data.filter((item) => value.includes(String(item[idHandle])));
			await onSelect(items);
			setIsLoadingPrefix(false);
			return;
		}

		const item = data.find((item) => String(item[idHandle]) === value[0]);

		setInput(clearOnSelect ? "" : value[0]);
		await onSelect(item);
		setIsLoadingPrefix(false);
	};

	return (
		<Wrapper style={style} required={required} haveValue={!!(Array.isArray(value) ? value.length : value)} ref={ref}>
			<Autocomplete
				allowMultiple={allowMultiple}
				// actionBefore={this.props.actionBefore}
				options={options}
				selected={
					allowMultiple
						? (value as unknown as unknown[] as any[]).map((v) => String(idHandle && v ? v?.[idHandle] : v))
						: [String(idHandle && value ? value[idHandle] : value)]
				}
				onSelect={handleSelect}
				loading={isFetching}
				// disabled={this.props.disabled}
				emptyState={emptyState}
				textField={activator}
			/>
		</Wrapper>
	);
};
export default withTranslation(["common"])(SelectSearchField);

const Wrapper = styled.div<{ required?: boolean; haveValue?: boolean }>`
	.Polaris-TextField__Prefix {
		display: flex;
	}

	${(props) => {
		if (props.required && !props.haveValue) {
			return css`
				.Polaris-TextField__Backdrop {
					border: 1px solid red !important;
				}
			`;
		}
	}}
`;
