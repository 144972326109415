import { useHistory } from "react-router-dom";
import API from "../API";
import useWorkspaces from "./useWorkspaces";
import useUser from "./useUser";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "./useQuery";
import Toast from "src/js/components/Toast";

const useBoard = (boardId?: number) => {
	const { fetchWorkspaces } = useWorkspaces();
	const history = useHistory();
	const { fetchUser } = useUser();
	const dispatch = useDispatch();

	const storeBoard = useSelector((state: any) => boardId && state.boards[boardId]);

	const { data: board = storeBoard } = useQuery({
		queryKey: boardId ? ["board", boardId] : [],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/boards/${boardId}.json`, {
					params: {},
				});

				return res.data.board;
			} catch (e) {
				console.error(e);
			}
		},
		enabled: !!boardId && !storeBoard?.id,
		initialData: storeBoard,
	});

	const removeBoard = async (id = boardId) => {
		return await API.delete("/api/boards/" + id + ".json", { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
				}
				const board = result.data.board || { id };

				Toast.success(`Board "${board.title}" borttagen`);

				if (history.length > 1) {
					history.goBack();
				} else {
					history.replace("/admin");
				}

				setTimeout(() => {
					dispatch({ type: "REMOVE_BOARD", board });
					dispatch({ type: "REMOVE_BOARD_FROM_WORKSPACES", board });

					fetchUser();
					fetchWorkspaces();
				}, 1);
			})
			.catch((error) => {
				Toast.error(error);
			});
	};

	return { board, removeBoard };
};

export default useBoard;
