import React, { Component } from "react";
import { LegacyStack, Select, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

class BoardFiltersText extends Component {
	constructor(props) {
		super(props);
		this.state = { operator: "contains" };
	}

	getOperatorOptions() {
		const arr = [
			{ value: "contains", label: this.props.t("board.filters.text.terms.includes", "innehåller") },
			{ value: "equals", label: this.props.t("board.filters.text.terms.equal_to", "är lika med") },
		];
		return arr;
	}

	UNSAFE_componentWillReceiveProps(props) {
		const foundIndex = props.filters.findIndex((filter) => filter.column_id == props.column_id && filter.board_id == props.board.id);
		if (foundIndex >= 0 && props.filters[foundIndex].operator) {
			this.setState({ operator: props.filters[foundIndex].operator });
		}
	}

	toggleFilter(value) {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		if (foundIndex >= 0 && value == "") {
			this.props.filters.splice(foundIndex, 1);
		} else if (value && foundIndex >= 0) {
			this.props.filters[foundIndex].value = value;
			this.props.filters[foundIndex].operator = this.state.operator;
		} else if (value && foundIndex < 0) {
			this.props.filters.push({
				key: this.props.column_id + "-" + this.props.board.id,
				board_id: this.props.board.id,
				operator: this.state.operator,
				column_id: this.props.column_id,
				value,
			});
		}
		this.props.onChangeFilters(this.props.filters);
	}

	changeOperator() {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);
		if (foundIndex >= 0) {
			this.props.filters[foundIndex].operator = this.state.operator;
			this.props.onChangeFilters(this.props.filters);
		}
	}

	render() {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		return (
			<LegacyStack distrubution="fill">
				<Select
					value={this.state.operator}
					onChange={(v) => {
						this.setState({ operator: v }, this.changeOperator.bind(this));
					}}
					options={this.getOperatorOptions()}
				/>
				<TextField value={foundIndex >= 0 ? this.props.filters[foundIndex].value : ""} onChange={this.toggleFilter.bind(this)} />
			</LegacyStack>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardFiltersText);
