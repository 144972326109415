import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import PriceListsRow from "./PriceListsRow";

type PriceListsProps = {
	article: Partial<ArticleType>;
	onChange: (field: ArticleTypeMutableKeys) => (value: string | boolean | ArticlePricelistType[]) => void;
	isSaving: boolean;
	disabled?: boolean;
} & WithTranslation;

const PriceLists = ({ article, onChange, disabled, t }: PriceListsProps) => {
	const columns = [
		{
			header: "Prislista",
			handle: "pricelist",
		},
		{
			header: "Pris",
			handle: "price",
			// handle: "price_in_cents",
		},
		{
			header: "TB1",
			handle: "tb1",
			// handle: "tb1_in_cents",
		},
		{
			header: "TB2",
			handle: "tb2",
			// handle: "tb2_in_cents",
		},
		{
			header: "Senast uppdaterad",
			handle: "updated_at",
			style: {
				width: "1px",
				whiteSpace: "nowrap",
			},
		},
		{
			header: "",
			handle: "actions",
		},
	] as { header: string; handle: ArticlePricelistTypeMutableKeys; style?: React.CSSProperties }[];

	const handleChange = (index: number) => (articlePricelist: ArticlePricelistType | null) => {
		const newPricelists = [...(article.article_pricelists || [])] as ArticlePricelistType[];

		if (articlePricelist === null) {
			newPricelists.splice(index, 1);
			onChange("article_pricelists")(newPricelists);
		} else {
			newPricelists[index] = articlePricelist;
		}

		onChange("article_pricelists")(newPricelists);
	};

	return (
		<Wrapper>
			<table>
				<thead>
					<tr>
						{columns?.map((column) => (
							<th key={column.handle} style={column.style}>
								{column.header}
							</th>
						))}
					</tr>
				</thead>

				<tbody>
					{article.article_pricelists?.map((pricelist, index) => (
						<PriceListsRow
							usedPricelists={article.article_pricelists}
							index={index}
							key={index}
							pricelist={pricelist}
							columns={columns}
							onChange={handleChange(index)}
							disabled={disabled}
						/>
					))}
					<PriceListsRow
						usedPricelists={article.article_pricelists}
						key={article?.article_pricelists?.length}
						columns={columns}
						onChange={handleChange(article?.article_pricelists?.length || 0)}
						disabled={disabled}
					/>
				</tbody>
			</table>
		</Wrapper>
	);
};
export default withTranslation(["article", "common"])(PriceLists);

const Wrapper = styled.div`
	table {
		width: 100%;

		td,
		th {
			&:first-child {
				text-align: left;
			}
			text-align: left;
			padding: 0.5rem 0.25rem;
		}
	}
`;
