import { Icon, Tooltip } from "@shopify/polaris";
import { AppsMajor, ChevronDownMinor, InfoMinor, SortAscendingMajor, SortDescendingMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Leaderboard from "src/js/components/Leaderboard";
import OptionList from "src/js/components/OptionList/OptionList";
import Popover from "src/js/components/Popover";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import Select from "src/js/components/select/Select";
import { getLocalStorage, setLocalStorage } from "src/js/Utilities";
import numberFormatter from "src/js/Utilities/numberFormatter";
import styled from "styled-components";
import deepMergeReplaceKey from "src/js/Utilities/deepMergeReplaceKey";

type SalesLeaderBoardProps = {
	loading?: boolean;
	style?: any;
	data: any;
} & WithTranslation;

const SalesLeaderBoard = ({ loading, style, data, t }: SalesLeaderBoardProps) => {
	const typeOptions = data?.leaderboard && Object.entries(data?.leaderboard).map(([key, col]: any) => ({ label: col.label, value: key }));

	const [type, setType] = useState(typeOptions?.[0]?.value);
	const typeData = useMemo(() => data?.leaderboard?.[type] || {}, [type, data]);
	const [sortBy, setSortBy] = useState<any>("value");
	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
	const storeUsers = useSelector((state: any) => state.users);

	const handleChangeSortBy = (value) => () => {
		setSortBy(value);

		if (value === sortBy) {
			if (sortOrder === "asc") {
				setSortOrder("desc");
				setSortBy("value");
			} else {
				setSortOrder("asc");
			}
		} else {
			setSortOrder("desc");
		}
	};

	useEffect(() => {
		if (typeOptions?.length > 0 && !type) {
			setType(typeOptions[0]?.value);
		}
	}, [typeOptions, type]);

	const propertyOptions = useMemo(() => {
		if (!typeData?.users) return [];

		const allOptions = Object.values(typeData.users).flatMap((user: any) =>
			Object.entries(user).map(([key, col]: any) => ({
				...(col || {}),
				label: (
					<p style={{ display: "flex", gap: "0.3125rem" }}>
						{col.label}
						{col.grouped && (
							<Tooltip content={t("sales.dashboard.terms.grouped", "Grupperad")}>
								<Icon source={AppsMajor} />
							</Tooltip>
						)}
					</p>
				),
				value: key,
			}))
		);

		const uniqueOptions = [...new Map(allOptions.map((option) => [option.value, option])).values()];

		//sort so grouped = true comes first
		return uniqueOptions.sort((a, b: any) => (b.grouped ? 1 : -1));
	}, [typeData, t]);

	const getDefaultProperties = useCallback(() => {
		return propertyOptions
			?.filter((opt) => opt.default)
			?.map((opt) => opt.value)
			.filter(Boolean);
	}, [propertyOptions]);

	const [properties, setProperties] = useState(getLocalStorage("dashboards")?.sales?.leaderboard?.properties || getDefaultProperties);

	useEffect(() => {
		if (propertyOptions?.length > 0 && !properties?.length) {
			setProperties(getDefaultProperties);
		}
	}, [propertyOptions, properties, getDefaultProperties]);

	const handleChangeType = (value) => {
		setType(value);
	};
	const handleChangeProperties = (value) => {
		setProperties(value);

		setLocalStorage("dashboards", (c) =>
			deepMergeReplaceKey(c || {}, {
				sales: {
					leaderboard: {
						properties: value,
					},
				},
			})
		);
	};

	const renderRow = (index, row) => {
		return (
			<>
				<td style={{ display: "flex", gap: "0.6250rem", alignItems: "center" }}>
					<ProfileAvatar size={35} user={row?.user} />
					{row.userId === "no_user" ? t("sales.dashboard.tersm.missing_user", "<Saknar användare>") : row?.user?.name || `- (${row.userId})`}
				</td>

				{properties?.map((property) => (
					<td key={property}>{row?.[property]?.count || "-"}</td>
				))}

				{/* <td>{row?.count ?? "-"}</td> */}
				<td>{numberFormatter({ value: row?.value, maximumFractionDigits: 0 }) ?? "-"}</td>
			</>
		);
	};

	const rows = useMemo(() => {
		if (loading && !typeData.users) {
			return Array.from({ length: 3 }).map(
				(_, index) =>
					({
						user: { name: "Loading...", id: index },
						count: "-",
						value: "-",
					} as any)
			);
		}

		if (!typeData) return [];
		if (!typeData?.users) return [];
		if (!properties?.length) return [];

		const userRows = Object.entries(typeData.users)
			.map(([userId, data]: any) => {
				const user = storeUsers.find((u) => String(u.id) === String(userId));

				const userRow = {
					user,
					count: properties?.length == 1 ? data[properties[0]]?.count : null,
					value: properties.reduce((acc, property) => acc + (data[property]?.total || 0), 0),
					userId,

					...(data || {}),
				};
				return userRow;
			})
			?.filter((i) => i.count || i.value || properties.some((property) => i[property]?.count || i[property]?.total));

		switch (sortBy) {
			case "user":
				if (sortOrder === "asc") {
					return userRows.sort((a, b) => (a.user?.name || "").localeCompare(b.user?.name || ""));
				}

				return userRows.sort((a, b) => (b.user?.name || "").localeCompare(a.user?.name || ""));

			case "value":
				if (sortOrder === "asc") {
					return userRows.sort((a, b) => a.value - b.value);
				}

				return userRows.sort((a, b) => b.value - a.value);

			default:
				if (sortOrder === "asc") {
					return userRows.sort((a, b) => (a[sortBy]?.count || 0) - (b[sortBy]?.count || 0));
				}

				return userRows.sort((a, b) => (b[sortBy]?.count || 0) - (a[sortBy]?.count || 0));
		}
	}, [typeData, properties, loading, sortBy, sortOrder, storeUsers]);

	return (
		<>
			<Select label="" labelHidden options={typeOptions} value={String(type)} onChange={handleChangeType} />

			<Leaderboard
				error={typeData?.error}
				computeItemKey={(index, row) => row?.user?.id || index}
				loading={!!loading}
				style={{
					...(style || {}),
					height: `calc(100%  - ${
						30 + // title
						20 + // title margin
						30 // select
					}px)`,
				}}
				virtuoseStyle={{
					minHeight: 300,
				}}
				data={rows}
				renderRow={renderRow}
				// headers={[{ title: "", width: "50px" }, { title: "Användare", width: "50%" }, "Antal"]}
				headers={[
					{ title: <ColumnText onClick={handleChangeSortBy("user")}>Användare</ColumnText>, width: "35%" },
					// { title: "Antal affärer", width: "15%", enabled: properties?.length == 1 },

					...(properties?.map((property) => ({
						title: (
							<ColumnText onClick={handleChangeSortBy(property)} style={{ justifyContent: "end" }}>
								{sortBy === property && <Icon source={sortOrder === "asc" ? SortAscendingMajor : SortDescendingMajor} />}

								{/* {`${t("sales.dashboard.terms.count", "Antal")} ${properties?.length === 1 ? t("sales.dashboard.terms.deals", "affärer ") : ""}
								`} */}
								{properties?.length === 1 &&
									`${t("sales.dashboard.terms.count", "Antal")} ${t("sales.dashboard.terms.deals", "affärer ")}
								`}

								{(properties?.length > 1 && propertyOptions.find((option) => option.value === property)?.label) || "-"}
							</ColumnText>

							// <ColumnText onClick={handleChangeSortBy(property)} style={{ justifyContent: "end" }}>
							// 	{sortBy === property && <Icon source={sortOrder === "asc" ? SortAscendingMajor : SortDescendingMajor} />}

							// 	{`${t("sales.dashboard.terms.count", "Antal")} ${properties?.length === 1 ? t("sales.dashboard.terms.deals", "affärer ") : ""}(${
							// 		propertyOptions.find((option) => option.value === property)?.label || "-"
							// 	})`}
							// </ColumnText>
						),
						// width: `${Math.max(15, 40 / (properties?.length || 0))}%`,
					})) || []),

					{
						key: "value",
						title: (
							<Popover
								preferredAlignment="right"
								activator={
									<ColumnButton onClick={handleChangeSortBy("value")}>
										{sortBy === "value" && <Icon source={sortOrder === "asc" ? SortAscendingMajor : SortDescendingMajor} />}

										{properties?.length == 1
											? propertyOptions.find((option) => option.value === properties[0])?.label || "-"
											: properties?.length
											? t("sales.dashboard.terms.sum", "Summering")
											: t("sales.dashboard.terms.choose_value", "Välj värde")}

										{properties?.length > 1 && (
											<Tooltip
												content={`${properties
													.map((property) => propertyOptions.find((option) => option.value === property)?.label || "-")
													.join(" + ")}
													`}
												// content={`Summering av: ${properties
												// 	.map((property) => propertyOptions.find((option) => option.value === property)?.label || "-")
												// 	.join(", ")}
												// 	`}
											>
												<Icon source={InfoMinor} />
											</Tooltip>
										)}

										<Icon source={ChevronDownMinor} />
									</ColumnButton>
								}
							>
								<OptionList allowMultiple options={propertyOptions} selected={properties} onChange={handleChangeProperties} />
							</Popover>
						),
						style: {
							paddingLeft: 10,
							justifyContent: "end",
							display: "flex",
						},
						// width: "25%",
					},

					// { title: <Select label="" labelHidden options={options} value={property} onChange={setProperty} /> },
				].filter((i) => (!("enabled" in i) || i.enabled) && i)}
				// title={t("dashboards.service_desk.leaderboard.title", "Topplista")}
				// data={stats?.data.leaderboard}
			/>
		</>
	);
};
export default withTranslation(["sales"])(SalesLeaderBoard);

const ColumnButton = styled.button`
	padding: 3px;
	text-align: start;

	background: transparent;
	color: var(--textColor);
	font-weight: bold;
	border: none;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	/* text-decoration: underline; */

	display: flex;
	gap: 0.2188rem;
`;

const ColumnText = styled.div`
	cursor: pointer;
	display: flex;

	.Polaris-Icon {
		margin: 0;
	}
`;
