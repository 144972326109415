import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import ThemeToggle from "../Dashboard/ThemeToggle";

class Action extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	goToUrl(url) {
		this.props.onClose();
		if (url.startsWith("https://") || url.startsWith("http://") || url.startsWith("www.")) {
			window.location.href = url;
			return;
		}

		this.props.history.push(url, 0);
	}

	render() {
		const { action, navigate, onOpenAction, next } = this.props || {};

		if (action) {
			switch (action) {
				// case "theme":
				// 	return <ThemeToggle />;
				case "calendar":
					return (
						<button className="action" onClick={onOpenAction}>
							{this.props.t("calendar.actions.connect_short", "Koppla")}
						</button>
					);
				case "import_company_register":
					return (
						<button className="action" onClick={onOpenAction}>
							{this.props.t("board.actions.import", "Importera")}
						</button>
					);

				default:
					return (
						<button className="action" onClick={next}>
							{this.props.t("common.terms.continue", "Fortsätt")}
						</button>
					);
			}
		}

		if (navigate && navigate.url) {
			return (
				<button className="action" onClick={this.goToUrl.bind(this, navigate.url)}>
					{navigate.title}
				</button>
			);
		}

		return null;
	}
}

export default withTranslation(["announcements", "common"], { withRef: true })(Action);
