import React, { Component } from "react";
import { Button, LegacyStack } from "@shopify/polaris";
import EmailEditor from "react-email-editor";
import { withTranslation } from "react-i18next";
import API from "../../API";
import SkeletonPage from "src/js/components/Skeletons/Page";
import Toast from "src/js/components/Toast";

class EmailCampaignTemplate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			loaded: false,
			id: props.match.params.id,
			loading: !!props.match.params.id,
			form: { title: "", content: "", design: {} },
			campaign: null,
		};
		if (props.location.state?.email_campaign) {
			this.state.campaign = props.location.state.email_campaign;
			this.state.form.title = this.state.campaign.title;
		}
		if (
			props.location.state?.email_campaign &&
			props.location.state.email_campaign?.sent &&
			props.location.state.email_campaign?.type == "newsletter"
		) {
			this.state.disabled = true;
		}
	}

	componentDidMount() {
		if (this.state.id) {
			this.fetchItem(this.state.id);
		}
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/email_templates/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, form: result.data.email_template });
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	saveForm() {
		if (this.state.disabled) {
			return;
		}
		this.setState({ saving: true });
		this.editor.exportHtml((data) => {
			this.state.form.content = data.html;
			this.state.form.design = data.design;
			this.setState({ form: this.state.form });
			this.doSave();
		});
	}

	doSave() {
		this.setState({ saving: true });

		if (this.state.campaign && this.state.campaign.id && this.state.id) {
			API.put("/api/email_templates/" + this.state.id + ".json", this.state.form)
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(this.props.t("email_campaign.responses.template.updated", "Mall uppdaterad"));
					this.props.history.goBack();
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		} else {
			API.post("/api/email_templates.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}

					if (this.state.campaign) {
						this.state.campaign.template = result.data.email_template;
						API.post("/api/email_campaigns.json", this.state.campaign)
							.then((result) => {
								this.setState({ saving: false });
								if (result.data.error) {
									Toast.error(result.data.error);
									return;
								}
								Toast.success(this.props.t("email_campaign.responses.created", "E-postkampanj skapad"));

								this.props.history.replace("/admin/email_campaigns/" + result.data.email_campaign.id);
							})
							.catch((error) => {
								this.setState({ saving: false });
								Toast.error(error);
							});
					} else {
						Toast.success(this.props.t("email_campaign.responses.template.saved", "Mall sparad"));

						this.setState({ saving: false });
					}
				})
				.catch((error) => {
					this.setState({ saving: false });
					Toast.error(error);
				});
		}
	}

	onLoadEditor() {
		if (this.editor && this.state.id && !this.designLoaded) {
			this.editor.loadDesign(this.state.form.design);
			this.designLoaded = true;
		}
	}

	onDesignLoaded() {
		this.onLoadEditor();
		this.state.loaded = true;
		this.setState({ loaded: true });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonPage />;
		}

		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<div style={{ width: "100%", padding: "1.2500rem" }}>
					<LegacyStack>
						<LegacyStack.Item fill />
						<LegacyStack>
							<Button
								onClick={() => {
									this.props.history.goBack();
								}}
							>
								{this.props.t("common.actions.cancel", "Avbryt")}
							</Button>
							<Button primary disabled={!this.state.loaded || this.state.disabled} loading={this.state.saving} onClick={this.saveForm.bind(this)}>
								{this.props.t("common.actions.save", "Spara")}
							</Button>
						</LegacyStack>
					</LegacyStack>
				</div>
				<div style={{ height: "100%" }} className="unlayer_container">
					<EmailEditor
						ref={(editor) => {
							this.editor = editor;
						}}
						locale="sv-SE"
						appearance={{
							theme: "dark",
							panels: {
								tools: {
									dock: "left",
								},
							},
						}}
						onLoad={this.onLoadEditor.bind(this)}
						onDesignLoad={this.onDesignLoaded.bind(this)}
					/>
				</div>
			</div>
		);
	}
}
export default withTranslation(["email_campaign", "common"], { withRef: true })(EmailCampaignTemplate);
