import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Modal from "src/js/components/modal";
import { FormLayout, InlineError } from "@shopify/polaris";
import TextField from "src/js/components/TextField";
import ScriveDocumentContext from "../ScriveDocumentContext";
import { getDocumentLabel } from "../Utilities";
import Toast from "src/js/components/Toast";
import { getErrorMessage } from "src/js/components/toastr";

const ScriveSendForSigningModal = ({ open, onClose, document, children = null, t }) => {
	const [isSending, setIsSending] = useState(false);
	const { handleSave } = useContext(ScriveDocumentContext);
	const [error, setError] = useState<any>(null);
	const [form, setForm] = useState<ScriveDocumentType>(document);

	useEffect(() => {
		setForm(document);
	}, [open, document]);

	const getChangeHandler = (field: ScriveDocumentTypeKeys) => (value: string | boolean) => {
		setForm((c: ScriveDocumentType) => {
			const newForm = { ...c };
			if (field === "days_to_sign") {
				newForm.days_to_sign = parseInt(value as string);
			} else {
				newForm[field as string] = value;
			}

			return newForm;
		});
	};

	const handleSendForSigning = async () => {
		setIsSending(true);
		setError(null);
		try {
			const res = await handleSave(form, true);
			if (res) setForm(res);
			onClose();
		} catch (error) {
			Toast.error(error);
			setError(getErrorMessage(error));
		}
		setIsSending(false);
	};

	useEffect(() => {
		if (open) setError(null);
	}, [open]);

	return (
		<Modal
			open={open}
			onClose={onClose}
			sectioned
			title={t("scrive.document.send_for_signing.title", "Skicka för signering")}
			primaryAction={{
				content: t("common.actions.send", "Skicka"),
				onAction: handleSendForSigning,
				loading: isSending,
			}}
			secondaryActions={[
				{
					content: t("common.actions.cancel", "Avbryt"),
					onAction: onClose,
				},
			]}
		>
			<FormLayout>
				{error && <InlineError fieldID="scrive_send_id" message={error} />}

				<p>{t("scrive.document.send_for_signing.description", "Är du säker på att du vill skicka dokumentet för signering?") as string}</p>

				<TextField
					disabled={!form}
					label={`${getDocumentLabel("invitation_message")} (valfritt)`}
					value={String(form?.invitation_message)}
					onChange={getChangeHandler("invitation_message")}
					multiline={3}
				/>
				<TextField
					disabled={!form}
					label={`${getDocumentLabel("sms_invitation_message")} (valfritt)`}
					value={String(form?.sms_invitation_message)}
					onChange={getChangeHandler("sms_invitation_message")}
					multiline={3}
				/>

				<p>
					{
						t("scrive.document.send_for_signing.cost", "Kostnad: {{cost}} per signerat dokument", {
							cost: "15kr",
						}) as string
					}
				</p>
			</FormLayout>
		</Modal>
	);
};
export default withTranslation(["scrive", "common"])(ScriveSendForSigningModal);
