import React, { Component } from "react";
import { Spinner, LegacyStack, Button } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

class UploadIntegrationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			options: props.column.options ? props.column.options : { formula: "" },
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}
	}

	saveForm() {
		this.setState({ saving: true });

		API.put(
			"/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json",
			{ options: this.state.options },
			{
				params: {},
			}
		)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					this.setState({ saving: false });
					return false;
				}
				this.props.onUpdateColumn(result.data.column);
				Toast.success(this.props.t("integration.responses.connected", "Integration kopplad"));
				this.setState({ saving: false });
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	setupScrive() {
		this.state.options = { integration: { type: "scrive" } };
		this.saveForm();
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("integration.modals.upload.title", "Integrera kolumn till system")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				{this.state.saving ? (
					<Modal.Section>
						<Spinner size="large" />
					</Modal.Section>
				) : null}
				{!this.state.saving ? (
					<Modal.Section>
						<LegacyStack>
							<Button onClick={this.setupScrive.bind(this)}>
								<img src="/assets/images/scrive-logo.png" style={{ height: 50 }} alt="Scrive" />
								<div>Scrive</div>
							</Button>
						</LegacyStack>
					</Modal.Section>
				) : null}
			</Modal>
		);
	}
}

export default withTranslation(["integration", "common"], { withRef: true })(UploadIntegrationModal);
