import { Icon, TextField } from "@shopify/polaris";
import React, { Component, useEffect } from "react";
import moment from "moment";
import { ClockMajor } from "@shopify/polaris-icons";

class DateTimePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: props.value || "",
			selectedIndex: this.getTimeOptions().findIndex(({ value }) => value === this.getTime(props.value)) || 0,
		};
		this.ref = React.createRef();
		this.listRef = React.createRef();
		this.onKeyDown = this.handleKeyDown.bind(this);
		this.closeList = () => {
			this.setState({ timeOpen: false });
		};
	}

	getTimeOptions() {
		return [...Array(24)]
			.flatMap((x, i) => {
				const hour = String(i).padStart(2, "0");

				return [
					{
						label: `${hour}:00`,
						value: `${hour}:00`,
					},
					{
						label: `${hour}:15`,
						value: `${hour}:15`,
					},
					{
						label: `${hour}:30`,
						value: `${hour}:30`,
					},
					{
						label: `${hour}:45`,
						value: `${hour}:45`,
					},
				];
			})
			.concat([
				{
					label: "00:00",
					value: "00:00",
				},
			]);
	}

	componentDidMount() {
		this.setState({ selectedIndex: this.getTimeOptions().findIndex(({ value }) => value === this.getTime()) || 0 });
		if (this.ref.current) {
			const inputs = this.ref.current.querySelectorAll("input");
			inputs.forEach((input) => {
				if (input) input.addEventListener("keydown", this.onKeyDown);
			});
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ date: props.value });
	}

	componentWillUnmount() {
		if (this.ref.current) {
			const inputs = this.ref.current.querySelectorAll("input");
			inputs.forEach((input) => {
				if (input) input.removeEventListener("keydown", this.onKeyDown);
			});
		}
	}

	handleKeyDown(e) {
		const list = this.listRef.current;
		if (e.key === "Enter") {
			const selected = list.querySelector(".selected");
			if (selected) this.setTime(selected.dataset.value);
			this.setState({ timeOpen: false });
		}

		if (e.key == "ArrowDown" && list) {
			e.preventDefault();
			this.setState(
				(c) => ({ selectedIndex: Math.min(c.selectedIndex + 1, this.getTimeOptions().length - 1) }),
				() => {
					const next = list.querySelector(".selected");
					if (next) {
						this.setTime(next.dataset.value, true);
						// next.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
					}
				}
			);
		}
		if (e.key == "ArrowUp" && list) {
			e.preventDefault();
			this.setState(
				(c) => ({ selectedIndex: Math.max(c.selectedIndex - 1, 0) }),
				() => {
					const next = list.querySelector(".selected");
					if (next) {
						this.setTime(next.dataset.value, true);
						// next.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
					}
				}
			);
		}
	}

	haveTime() {
		if (this.getTime() !== null) {
			return true;
		}
		return false;
	}

	setSelectedDate(date) {
		this.state.date = (moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : date) + (this.haveTime() ? " " + this.getTime() : "");

		this.setState(
			{
				date: this.state.date,
			},
			moment(this.state.date).isValid() ? this.props.onChange(this.state.date) : null
		);
	}

	setTime(time, smoothscroll) {
		this.state.date = (moment(this.getDate()).isValid() ? moment(this.getDate()).format("YYYY-MM-DD") : this.getDate()) + " " + time;
		const index = this.getTimeOptions().findIndex(({ value }) => value === this.getTime(this.state.date));
		this.setState(
			(c) => ({ date: this.state.date, selectedIndex: index !== -1 ? index : c.selectedIndex }),
			() => {
				if (this.listRef.current) {
					const selected = this.listRef.current.querySelector(".selected");
					if (selected)
						selected.scrollIntoView(
							smoothscroll ? { behavior: "smooth", block: "nearest", inline: "start" } : { behavior: "auto", block: "center", inline: "start" }
						);
				}
				if (moment(this.state.date).isValid()) this.props.onChange(this.state.date);
			}
		);
	}

	setTimeHours(hours) {
		if (!/^\d+$/.test(hours)) {
			setTimeout(() => {
				const timeInput = this.ref.current.querySelector("#time_input_hours");
				timeInput.focus();
				timeInput.select();
			}, 0);

			return null;
		}
		const minutes = this.getTimeMinutes();

		this.setTime(`${String(Math.min(23, hours)).padStart(2, "0")}:${minutes}`);
		if (hours.length >= 2 && this.ref) {
			const timeInput = this.ref.current.querySelector("#time_input_minutes");
			if (timeInput) {
				timeInput.focus();
				timeInput.select();
			}
		} else if (this.ref) {
			setTimeout(() => {
				const timeInput = this.ref.current.querySelector("#time_input_hours");
				if (timeInput.value && !timeInput.value.replace("00", "").length) {
					timeInput.focus();
					timeInput.select();
				}
			}, 0);
		}
	}

	getTimeHours() {
		return this.getTime(this.state.date, true);
	}

	getTimeMinutes() {
		return this.getTime(this.state.date, false, true);
	}

	setTimeMinutes(minutes) {
		if (!/^\d+$/.test(minutes)) {
			setTimeout(() => {
				const timeInput = this.ref.current.querySelector("#time_input_minutes");

				timeInput.focus();
				timeInput.select();
			}, 0);

			return null;
		}
		const hours = this.getTimeHours();
		this.setTime(`${hours}:${String(Math.min(59, minutes)).padStart(2, "0")}`);
		setTimeout(() => {
			const timeInput = this.ref.current.querySelector("#time_input_minutes");
			if (timeInput.value && !timeInput.value.replace("00", "").length) {
				timeInput.focus();
				timeInput.select();
			}
		}, 0);
	}

	getDate() {
		return this.state.date && this.state.date.split(" ")[0];
	}

	getTime(date = this.state?.date, retunrHours, returnMinutes) {
		const pcs = date && date?.split(" ");
		if (pcs && pcs.length > 1) {
			const time = date && date?.split(" ")[1];
			const [hours, minutes] = time.split(":");
			if (retunrHours) return hours;
			if (returnMinutes) return minutes;

			return time;
		}
		return null;
	}

	render() {
		return (
			<div ref={this.ref} style={{ display: "flex", gap: "1.2500rem" }} className={this.props.className}>
				<TextField
					label=""
					type="date"
					// type={"text"}
					value={this.getDate()}
					onChange={this.setSelectedDate.bind(this)}
					max="9999-12-31"
					suffix={this.props.suffix}
				/>
				<div style={{ position: "relative" }}>
					<div className="group_datetime">
						<TextField
							label=""
							id="time_input_hours"
							disabled={!this.haveTime()}
							value={this.getTimeHours()}
							onChange={this.setTimeHours.bind(this)}
							onFocus={(e) => {
								if (e.target) {
									e.target.focus();
									e.target.select();
									setTimeout(() => {
										e.target.select();
									}, 0);
									e.preventDefault();
									e.stopPropagation();
								}

								this.setState({ timeOpen: true }, () => {
									if (this.listRef.current) {
										const selected = this.listRef.current.querySelector(".selected");
										if (selected) selected.scrollIntoView({ behavior: "auto", block: "center", inline: "start" });
									}
								});
							}}
						/>
						<span
							onClick={() => {
								const hoursInput = this.ref.current.querySelector("#time_input_hours");
								if (hoursInput) {
									hoursInput.focus();
									hoursInput.select();
								}
							}}
							className="seperator"
						>
							:
						</span>
						<TextField
							label=""
							id="time_input_minutes"
							disabled={!this.haveTime()}
							value={this.getTimeMinutes()}
							onChange={this.setTimeMinutes.bind(this)}
							onFocus={(e) => {
								if (e.target) {
									e.target.focus();
									e.target.select();
									setTimeout(() => {
										e.target.select();
									}, 0);
									e.preventDefault();
									e.stopPropagation();
								}
								this.setState({ timeOpen: true }, () => {
									if (this.listRef) {
										const selected = this.listRef.current.querySelector(".selected");
										if (selected) selected.scrollIntoView({ behavior: "auto", block: "center", inline: "start" });
									}
								});
							}}
						/>
						<span
							className="time-icon"
							onClick={() => {
								const hoursInput = this.ref.current.querySelector("#time_input_hours");
								if (hoursInput) {
									hoursInput.focus();
									hoursInput.select();
								}
							}}
						>
							<Icon source={ClockMajor} />
						</span>
					</div>
					{this.state.timeOpen && (
						<List ref={this.listRef} wrapperRef={this.ref} onClose={this.closeList}>
							{this.getTimeOptions().map(({ label, value }, index) => (
								<div
									key={index}
									onClick={(e) => {
										this.setTime(e.target.dataset.value);
										this.setState({ timeOpen: false });
									}}
									data-value={value}
									className={`item${index === this.state.selectedIndex ? " selected" : ""}`}
									style={{ padding: "8px" }}
								>
									{label}
								</div>
							))}
						</List>
					)}
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default DateTimePicker;
const List = React.forwardRef(({ children, onClose, wrapperRef }, ref) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			// 	event.preventDefault();
			// 	event.stopPropagation();

			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) onClose();
		};

		document.addEventListener("mouseup", handleClickOutside);
		return () => document.removeEventListener("mouseup", handleClickOutside);
	}, [wrapperRef, onClose]);

	return (
		<div ref={ref} className="list">
			{children}
		</div>
	);
});
