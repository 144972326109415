import React, { Component } from "react";
import { Checkbox, LegacyStack, TextField, Icon } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";

class BoardFiltersOptions extends Component {
	constructor(props) {
		super(props);
		this.state = { query: "", showInactive: false };
	}

	toggleFilter(value) {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		if (foundIndex >= 0) {
			if (this.props.filters[foundIndex].value.indexOf(value) >= 0) {
				this.props.filters[foundIndex].value.splice(this.props.filters[foundIndex].value.indexOf(value), 1);
				if (this.props.filters[foundIndex].value.length < 1) {
					this.props.filters.splice(foundIndex, 1);
				}
			} else {
				this.props.filters[foundIndex].value.push(value);
			}
		} else {
			this.props.filters.push({
				key: this.props.column_id + "-" + this.props.board.id,
				board_id: this.props.board.id,
				column_id: this.props.column_id,
				value: [value],
			});
		}
		this.props.onChangeFilters(this.props.filters);
	}

	haveInactive() {
		for (let i = 0; i < this.props.options?.length; i++) {
			if (this.props.options[i].inactive) {
				return true;
			}
		}
		return false;
	}

	render() {
		const filterRegex = new RegExp(this.state.query, "i");

		return (
			<div
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div style={{ marginBottom: 15 }}>
					<div>
						<TextField
							prefix={<Icon source={SearchMinor} />}
							placeholder={this.props.t("board.filters.option.fields.search.placeholder", "Sök...")}
							suffix={
								this.haveInactive() ? (
									<Checkbox
										label={this.props.t("board.filters.option.fields.search.suffix.label", "Alla")}
										checked={this.state.showInactive}
										onChange={() => {
											this.setState({ showInactive: !this.state.showInactive });
										}}
									/>
								) : null
							}
							value={this.state.query}
							onChange={(v) => {
								this.setState({ query: v });
							}}
						/>
					</div>
				</div>

				{this.props.options
					?.filter((opt) => {
						let match = true;
						if (opt.inactive === true && !this.state.showInactive) {
							match = false;
						}
						if (this.state.query && !opt.label.match(filterRegex)) {
							match = false;
						}
						return match;
					})
					.map((opt, index) => {
						const foundIndex = this.props.filters.findIndex(
							(filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id
						);
						const content = (
							<LegacyStack spacing="tight">
								{opt.prefix ? opt.prefix : null}
								<LegacyStack.Item fill>{opt.label}</LegacyStack.Item>
							</LegacyStack>
						);
						return (
							<div key={opt.value} style={{ paddingBlock: 2 }}>
								<Checkbox
									label={content}
									checked={!!(foundIndex >= 0 && this.props.filters[foundIndex].value.indexOf(opt.value) >= 0)}
									onChange={this.toggleFilter.bind(this, opt.value)}
								/>
							</div>
						);
					})}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardFiltersOptions);
