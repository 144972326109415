import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import TargetContext, { TargetContextProvider } from "../Context/TargetContext";
import Page from "src/js/components/page";
import TargetHeader from "./Header";
import TargetOverview from "./Overview";
import TargeUserPermissions from "./UserPermissions";
import TargetUsers from "./Users";
import TargetGroups from "./Groups";
import TargetValues from "./TargetValues";
import TargetFilters from "./Filters";

const TargetEdit = ({ history, t }) => {
	const { form, target, id, handleSave, isLoading } = useContext(TargetContext);
	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "overview",
					content: t("tagets.tabs.overview", "Grunduppgifter"),
					render: <TargetOverview />,
				},
				{
					id: "values",
					content: t("tagets.tabs.values", "Målvärden"),
					render: <TargetValues />,
					enabled: !!id,
				},
				{
					id: "users",
					content: t("tagets.tabs.users", "Användare"),
					render: <TargetUsers />,
					enabled: !!id,
				},
				{
					id: "groups",
					content: t("tagets.tabs.groups", "Grupper"),
					render: <TargetGroups />,
					enabled: !!id,
				},
				{
					id: "user_permissions",
					content: t("tagets.tabs.user_permissions", "Användarbehörigheter"),
					render: <TargeUserPermissions />,
					enabled: !!id,
				},
				{
					id: "filters",
					content: t("tagets.tabs.filters", "Filter"),
					render: <TargetFilters />,
					enabled: !!id,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[t, id]
	);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as TargetType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "overview";
	}, [tabs]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};
	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());

	const tab = tabs.find((tab) => tab.id === tabID);
	const title = id ? t("targets.edit.title", "Redigera mål: {{target}}", { target: target?.title }) : t("targets.create.title", "Skapa nytt mål");

	return (
		<Page
			title={title}
			history={history}
			primaryAction={{
				content: id ? t("common.save", "Spara") : t("common.create", "Skapa"),
				onClick: handleSaveForm,
				disabled: isLoading,
			}}
		>
			<TargetHeader tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["targets", "common"])((props) => {
	return (
		<TargetContextProvider {...props}>
			<TargetEdit {...props} />
		</TargetContextProvider>
	);
});
