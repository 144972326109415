import { LegacyCard, FormLayout, Layout } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectSearchField from "src/js/components/SelectSearchField";
import TextField from "src/js/components/TextField";
import Select from "src/js/components/select/Select";
import { store } from "src/js/store";

type OneOf<T, K extends keyof T> = {
	[P in K]-?: Required<Pick<T, P>> & Partial<Record<Exclude<K, P>, never>>;
}[K];

type PartialFortnoxForm = OneOf<
	{
		invoice: Partial<FortnoxInvoiceType> | null;
		order: Partial<FortnoxOrderType> | null;
		offer: Partial<FortnoxOfferType> | null;
	},
	"invoice" | "order" | "offer"
>;

type FortnoxCustomerFieldsPropsBase = {
	history: any;
	setForm: (c: any) => void;
	isSaving: boolean;
} & WithTranslation;

type FortnoxDeliveryFieldsProps = PartialFortnoxForm & FortnoxCustomerFieldsPropsBase;

const FortnoxDeliveryFields = ({ invoice, order, offer, t, setForm, history, isSaving }: FortnoxDeliveryFieldsProps) => {
	const form: FortnoxInvoiceType | FortnoxOrderType | FortnoxOfferType = invoice || order || (offer as any);
	const disabled = !form || (form && "InvoiceReference" in form && !!form?.InvoiceReference) || form?.Cancelled || form.Sent || isSaving;

	const onChange =
		(field: FortnoxInvoiceTypeMutableKeys | FortnoxOrderTypeMutableKeys | FortnoxOfferTypeMutableKeys) => (value: string | boolean) => {
			setForm((c: PartialFortnoxForm) => {
				const newForm = { ...c };
				newForm[field as string] = value;

				return newForm;
			});
		};

	return (
		<LegacyCard sectioned>
			<Layout>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.DeliveryName.label", "Namn")}
							value={form?.DeliveryName}
							onChange={onChange("DeliveryName")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.DeliveryDate.label", "Leveransdatum")}
							value={form?.DeliveryDate}
							onChange={onChange("DeliveryDate")}
							type="date"
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.DeliveryAddress1.label", "Leveransadress")}
							value={form?.DeliveryAddress1}
							onChange={onChange("DeliveryAddress1")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.DeliveryAddress2.label", "Alternativ leveransadress")}
							value={form?.DeliveryAddress2}
							onChange={onChange("DeliveryAddress2")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.DeliveryZipCode.label", "Postnummer")}
							value={form?.DeliveryZipCode}
							onChange={onChange("DeliveryZipCode")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.DeliveryCity.label", "Ort")}
							value={form?.DeliveryCity}
							onChange={onChange("DeliveryCity")}
						/>

						<Select
							disabled={disabled}
							label={t("fortnox.fields.DeliveryCountry.label", "Land")}
							options={[{ label: "Välj land", value: "" }, ...store.getState().countries.map((c) => ({ label: c.label, value: c.label }))]}
							value={form?.DeliveryCountry || ""}
							onChange={onChange("DeliveryCountry")}
						/>
					</FormLayout>
				</Layout.Section>
				<Layout.Section oneHalf>
					<FormLayout>
						<SelectSearchField
							fetchOnMount
							label={t("fortnox.fields.TermsOfDelivery.label", "Leveransvillkor") as string}
							value={form?.TermsOfDelivery}
							disabled={disabled}
							resourceName={{ singular: "Leveransvilkor", plural: "Leveransvilkor" }}
							resource="TermsOfDeliveries"
							resourceHandle="TermsOfDeliveries"
							resourceURL="/api/fortnox/termsofdeliveries.json"
							labelHandle="Description"
							idHandle="Code"
							searchHandle={null}
							renderValue={(value, data) => data?.find((item) => item.Code == value)?.Description || value}
							onSelect={(v) => onChange("TermsOfDelivery")(v?.Code)}
						/>
						<SelectSearchField
							fetchOnMount
							label={t("fortnox.fields.WayOfDelivery.label", "Leveranssätt") as string}
							value={form?.WayOfDelivery}
							disabled={disabled}
							resourceName={{ singular: "Leveranssätt", plural: "Leveranssätt" }}
							resource="WayOfDeliveries"
							resourceHandle="WayOfDeliveries"
							resourceURL="/api/fortnox/wayofdeliveries.json"
							labelHandle="Description"
							idHandle="Code"
							searchHandle={null}
							renderValue={(value, data) => data?.find((item) => item.Code == value)?.Description || value}
							onSelect={(v) => onChange("WayOfDelivery")(v?.Code)}
						/>
					</FormLayout>
				</Layout.Section>
			</Layout>
		</LegacyCard>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxDeliveryFields);
