import React, { Component } from "react";

/**
 * OnIntersect
 * parent
 */
class IntersectionObserverElement extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.observer = null;
		this.ref = React.createRef();
	}

	handleIntersection(entries) {
		if (entries && entries.length) {
			entries.forEach((entry) => {
				this.setState({ intersecting: entry.isIntersecting });
				if (this.props.onChange) this.props.onChange(entry.isIntersecting);
			});
		}
	}

	componentDidMount() {
		setTimeout(() => {
			if (this.ref.current) {
				this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
					root: (this.props.parent && this.props.parent.current) || this.props.parent,
					threshold: 1,
				});

				this.observer.observe(this.ref.current);
			}
		}, 0);
	}

	componentWillUnmount() {
		if (this.observer) this.observer.disconnect();
	}

	// scrollbarVisible(element) {
	// 	if (!element) return null;
	// 	return element.scrollHeight > element.clientHeight;
	// }

	render() {
		return (
			<React.Fragment>
				<div ref={this.ref} style={{ height: "1px", width: "1px", visibility: "hidden" }} />
				{!this.props.hideShadow && (
					<div className={`bottom-scroll-shadow${!this.state.intersecting ? " visible" : " invisible"}`} style={this.props.shadowStyle} />
				)}
			</React.Fragment>
		);
	}
}
export default IntersectionObserverElement;
