/* eslint-disable no-continue */
import React, { Component } from "react";
import { Button, Checkbox, Icon, Spinner } from "@shopify/polaris";
import { FixedSizeList, areEqual } from "react-window";
import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import API from "../../../API";
import RowSnippet2, { StyledDocumentItem } from "./RowSnippet2";
import { store } from "../../../store";
import RowModal from "./RowModal";
import BoardHelper from "../BoardHelper";
import BoardFilters from "./BoardFilters";
import Toast from "src/js/components/Toast";

class Miniboard2 extends Component {
	constructor(props) {
		super(props);
		this.Row = React.memo(this.renderRow.bind(this), areEqual);
		this.state = {
			query: "",
			filteredRows: [],
			rows: [],
			groups: [],
			filters: [],
		};
		this.requests = [];
	}

	componentDidMount() {
		if (this.props.board && this.props.board.columns) {
			// Sometimes we have a light version of a board
			this.setState({ board: this.props.board, board_id: this.props.board.id });
		} else {
			this.fetchBoard();
		}
		if (this.props.rows && this.props.rows.length) {
			this.setState({ rows: this.props.rows }, this.updateGroupRows.bind(this));
		} else {
			this.fetchRows();
		}
		if (this.props.groups && this.props.groups.length) {
			this.setState({ groups: this.props.groups }, this.updateGroupRows.bind(this));
		} else {
			this.fetchGroups();
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	createCancelToken(request, c) {
		request.cancel = c;
	}

	chechValidlength(arr) {
		return arr && (Array.isArray(arr) ? arr.length : typeof arr === "object" && Object.keys(arr).length);
	}

	filterDuplicates(ar) {
		return ar.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ((this.chechValidlength(props.rows) || this.chechValidlength(props.groups)) && props.board && props.board.id) {
			this.setState((c) => {
				const rows = this.filterDuplicates(
					[]
						.concat(Object.values(props.rows), c.rows)
						.filter((r) => r && !r.removed && !r.archived && parseInt(r.board_id) === parseInt(props.board.id))
				);
				const groups = this.filterDuplicates(
					[]
						.concat(props.groups ? Object.values(props.groups) : [], c.groups)
						.filter((r) => r && !r.removed && !r.archived && parseInt(r.board_id) === parseInt(props.board.id))
				);
				const row = (c.row && rows.find((r) => parseInt(r.id) === parseInt(c.row.id))) || c.row;

				return { rows, groups, row };
			}, this.updateGroupRows.bind(this));
		}

		if (props.query != this.state.query) {
			this.setState({ query: props.query }, this.updateGroupRows.bind(this));
		}

		const boardId = props.board?.id || props.board_id;
		if (boardId !== this.state.board_id) {
			this.setState({ board_id: boardId, filteredRows: [], rows: [], groups: [] });

			if (props.board?.id && props.board.id != this.state.board_id) {
				this.setState({ board: props.board });
			} else {
				this.fetchBoard(props);
			}

			if (props.rows?.length) {
				this.setState({ rows: props.rows });
			} else {
				this.fetchRows(props);
			}

			if (props.groups?.length) {
				this.setState({ groups: props.groups }, this.updateGroupRows.bind(this));
			} else {
				this.fetchGroups(props);
			}
		}
	}

	fetchBoard(props = this.props) {
		const boardId = props.board?.id || props.board_id;
		this.setState({ board_id: boardId });

		API.get("/api/boards/" + boardId + ".json", { params: {} })
			.then((result) => {
				store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
				this.setState({ board: result.data.board, board_id: result.data.board.id });
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	fetchRows(props = this.props, silent) {
		const boardId = props.board?.id || props.board_id;

		if (!silent) this.setState({ loadingRows: true });
		const params = props.params || {};
		if (props.contact_id) {
			params.contact_id = props.contact_id;
		}
		BoardHelper.fetchRows(
			[boardId],
			null,
			(rows) => {
				this.setState({ rows, loadingRows: false }, this.updateGroupRows.bind(this));
				if (props.setCount) props.setCount(rows.length);
			},
			params
		);
	}

	componentWillUnmount() {
		this.cancelRequests();
		BoardHelper?.cancelRequests?.();
	}

	cancelRequests() {
		if (this.requests) {
			for (let i = 0; i < this.requests.length; i++) {
				if (!this.requests[i].complete) {
					this.requests[i].cancel();
				}
			}
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	handleResponse(request, result) {
		request.complete = true;
		for (let i = 0; i < result.data.rows.length; i++) {
			this.state.rows.push(result.data.rows[i]);
		}
		for (let i = 0; i < this.requests.length; i++) {
			if (!this.requests[i].complete) {
				return;
			}
		}
		this.setState({ loadingRows: false, rows: this.state.rows }, this.updateGroupRows.bind(this));
	}

	fetchGroups(props = this.props) {
		this.setState({ loadingGroups: true });
		const boardId = props.board?.id || props.board_id;
		API.get("/api/boards/" + boardId + "/groups.json", {
			params: {},
		})
			.then((result) => {
				this.setState({ loadingGroups: false, groups: result.data.groups }, this.updateGroupRows.bind(this));
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ loadingGroups: false });
			});
	}

	renderRow(props) {
		const { data: items, index, style } = props;
		const item = items[index];

		const key =
			item.type == "quickadd" || item.type == "summary" || item.type == "blank" ? item.type + item.group_id : (item.color ? "g" : "") + item.id;

		const obj = this.renderItem(item, index, style, key);
		return obj;
	}

	getColumn(id) {
		if (!this.state.board) return;
		if (this.state.board.columns) return this.state.board.columns.find((c) => c.id == id);
	}

	clickRow(row, onTitle) {
		if (this.props.onClickRow) {
			this.props.onClickRow(row, onTitle);
			return;
		}
		if (this.state.board) {
			Object.values(row.column_values).forEach((col) => {
				if (this.getColumn(col.column_id) && ["contact", "rowlink", "ticket"].includes(this.getColumn(col.column_id).type)) {
					BoardHelper.fetchResources(this.getColumn(col.column_id).type, col.value);
				}
			});
		}

		// Open row modal
		this.setState({ showRowModal: true, row });
	}

	onCreateRow(row) {
		this.state.rows.push(row);
		this.setState({ rows: this.state.rows }, this.updateGroupRows.bind(this));
		if (this.props.onCreateRow) {
			this.props.onCreateRow(row);
		} else {
			BoardHelper.updateRows([row], "add");
		}
		// maybe run this instead/also
		// this.fetchRows(true);
	}

	renderItem(item, index, style, key) {
		// if (item.color || ["blank", "quickadd"].includes(item.type)) return null;

		return (
			<div key={key} className="board-item have-selection" style={style}>
				<RowSnippet2
					onClick={this.clickRow.bind(this)}
					group={this.state.groups ? this.state.groups.find((g) => g.id === item.group_id) : { id: item.group_id, color: item.group_color }}
					row={item}
				/>
			</div>
		);
	}

	updateRow(row) {
		const rows = this.state.rows
			.map((r) => {
				if (r.id === row?.id) {
					return row;
				}
				return r;
			})
			.filter((r) => r && !r.removed && !r.archived && parseInt(r.board_id) === parseInt(this.props.board.id));

		this.setState({ rows }, this.updateGroupRows.bind(this));
	}

	updateGroupRows() {
		const boardFilteredRows = BoardHelper.filterRows(this.state.rows, this.state.filters);

		if (this.props.params?.sort) {
			this.setState({ filteredRows: boardFilteredRows });
		} else {
			const filteredRows = [];
			const groups = this.state.groups.sort((a, b) => a.position - b.position);

			for (let i = 0; i < groups.length; i++) {
				const group = groups[i];
				const rows = boardFilteredRows.sort((a, b) => a.position - b.position);

				if (!this.props.contact_id || rows.length > 0) {
					group.count = rows.length;
					// filteredRows.push(group);

					group.rows = [];
					group.selection = [];
					// let lastPosition = 0;
					for (let s = 0; s < rows.length; s++) {
						if (rows[s].group_id != group.id) continue;
						group.rows.push(rows[s].id); // For the select
						if (rows[s].selected) {
							group.selection.push(rows[s].id);
						}
						rows[s].group_color = group.color;
						rows[s].group_title = group.title;
						// lastPosition = rows[s].position + 1;
						filteredRows.push(rows[s]);
					}
				}
			}

			this.setState({ filteredRows });
		}
	}

	render() {
		const rowHeight = 125;
		const loading = (this.state.loadingRows || this.state.loadingGroups) && (!this.state.filteredRows || !this.state.filteredRows.length);
		const rows = this.state.filteredRows;

		// const rows = this.state.rows.filter((row) => {
		// 	if (!this.state.search) return true;
		// 	if (!row) return false;

		// 	const filterRegex = new RegExp(this.state.search, "i");
		// 	return filterRegex.test(row.title) || filterRegex.test(row.group_title) || (row.board_number && filterRegex.test(row.board_number.toString()));
		// });
		return (
			<div className="miniboard">
				<div className="miniboard_header">
					<LoadingSpinner>{this.state.loadingRows && <Spinner size="small" />}</LoadingSpinner>
					<span style={{ flex: 1 }}>
						{/* <TextField
							prefix={<Icon source={SearchMajor} />}
							placeholder={
								loading
									? `${this.props.t("board.miniboard.terms.getting", "Hämtar")} ${(this.props.board && this.props.board.plural) || "data"}..`
									: this.props.t("board.miniboard.search.placeholder", "Sök..")
							}
							onChange={this.onSearchChange.bind(this)}
							value={this.state.search}
						/> */}

						<BoardFilters
							onChangeFilters={(filters) => {
								this.setState({ filters }, this.updateGroupRows.bind(this));
							}}
							filters={this.state.filters}
							boards={[this.props.board]}
							groups={this.props.groups}
							filteredRows={rows}
							rows={this.state.rows}
							placeholder={
								this.state.loadingRows
									? `${this.props.t("board.miniboard.terms.getting", "Hämtar")} ${(this.props.board && this.props.board.plural) || "data"}..`
									: this.props.t("board.miniboard.search.placeholder", "Sök..")
							}
							additionalAction={
								<>
									<Checkbox
										label={this.props.t("board.miniboard.terms.also_archived", "Visa även arkiverade {{plural}}", {
											plural: this.props.board?.plural?.toLowerCase(),
										})}
										disabled={this.state.loadingRows}
										checked={this.state.filters.archived}
										onChange={(checked) => {
											const props = {
												...this.props,
												params: {
													...this.props.params,
													also_archived: checked ? 1 : 0,
												},
											};

											this.setState({ filters: { ...this.state.filters, archived: checked } }, this.fetchRows.bind(this, props, false));
										}}
									/>
									<span className="create_button">
										<Button plain onClick={() => this.setState({ showRowModal: true })} disabled={this.props.contact?.removed}>
											<div className="button_inner">
												<span>
													{this.props.t("board.actions.add_new", "Lägg till ny")}{" "}
													{(this.props.board && this.props.board.singular) || this.props.t("board.terms.row", "rad")}
												</span>
												<Icon source={CirclePlusOutlineMinor} />
											</div>
										</Button>
									</span>
								</>
							}
						/>
					</span>
				</div>
				{rows && !!rows.length && (
					<div key="noSelected">
						<FixedSizeList
							height={this.props.height ? this.props.height : rows.length * rowHeight}
							// height={rows.length * rowHeight + 1}
							ref={(r) => {
								// eslint-disable-next-line react/no-unused-class-component-methods
								this.list = r;
							}}
							itemCount={rows && rows.length}
							itemSize={rowHeight}
							itemData={rows}
							overscanCount={10}
						>
							{this.Row}
						</FixedSizeList>
					</div>
				)}
				{(!rows || !rows.length) && !this.state.loadingRows && !this.state.loadingGroups && (
					<div className="board-empty_screen">
						<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
						<h2>
							{this.props.t("board.terms.none_found", "Inga {{plural}} hittades", {
								plural: this.props.board?.plural?.toLowerCase(),
							})}
						</h2>
					</div>
				)}
				{loading && (
					<React.Fragment>
						<StyledDocumentItem style={{ height: "125px" }} />
						<StyledDocumentItem style={{ height: "125px" }} />
						<StyledDocumentItem style={{ height: "125px" }} />
					</React.Fragment>
				)}
				{this.state.board && (this.state.row || this.state.showRowModal) ? (
					<RowModal
						open={this.state.showRowModal}
						contact={!this.state.row && this.props.contact}
						row={this.state.row}
						board={this.state.board}
						groups={this.state.groups}
						defaultValues={[]}
						getContact={() => {}}
						onClose={() => {
							this.setState({ showRowModal: false, row: null });
						}}
						onUpdateRow={this.updateRow.bind(this)}
						onCreateRow={this.onCreateRow.bind(this)}
					/>
				) : null}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(Miniboard2);

const LoadingSpinner = styled.div`
	position: absolute;
	z-index: 99999;
	background: var(--main2);
	left: 10px;
	height: 36px;
	top: 0;
	border-radius: 6px;
	display: flex;
	align-items: center;
`;
