import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import CustomfieldContext, { CustomfieldContextProvider } from "./context";
import Info from "./info";
import Header from "./header";
import Articles from "./articles";
import Categories from "./categories";

const CustomField = ({ history, t }) => {
	const { id, form, customfield, handleSave, isFetching, isSaving } = useContext(CustomfieldContext);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "info",
					content: t("customfields.tabs.base_info", "Grunduppgifter"),
					render: <Info history={history} />,
				},
				{
					id: "articles",
					content: t("customfields.tabs.articles", "Artiklar"),
					render: <Articles history={history} />,
					enabled: !!id,
				},
				{
					id: "categories",
					content: t("customfields.tabs.catories", "Kategorier"),
					render: <Categories history={history} />,
					enabled: !!id,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, t, id]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as CustomFieldType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);

	const tite = id
		? t("customfields.editTitle", "Redigera fält: {{title}}", { title: customfield?.title })
		: t("customfields.addTitle", "Lägg till nytt fält");

	return (
		<Page
			title={tite}
			history={history}
			primaryAction={{
				content: t("common.save", "Spara"),
				onClick: handleSaveForm,
				disabled: isSaving || isFetching,
			}}
		>
			<Header customfield={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["customfields", "common"])((props) => {
	return (
		<CustomfieldContextProvider {...props}>
			<CustomField {...props} />
		</CustomfieldContextProvider>
	);
});
