/* eslint-disable no-restricted-syntax */
export default (state = {}, action) => {
	switch (action.type) {
		case "SET_BOARDS": {
			const boards = Object.assign({}, state);
			for (let i = 0; i < action.boards.length; i++) {
				const board = action.boards[i];
				const boardId = board.id;
				boards[boardId] = board;
			}
			return boards;
		}
		case "SET_BOARD": {
			const boards = Object.assign({}, state);

			const boardId = action.board.id;
			boards[boardId] = action.board;

			return boards;
		}
		case "UPDATE_BOARD": {
			state[action.board.id] = action.board;
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return Object.assign({}, state);
		}
		case "REMOVE_BOARD": {
			delete state[action.board.id];
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return Object.assign({}, state);
		}
		case "ADD_BOARD_TAG": {
			for (const i in state) {
				let found = false;
				if (state[i].tags && state[i].tags.length) {
					for (let x = 0; x < state[i].tags.length; x++) {
						if (state[i].tags[x].id == action.tag.id) {
							found = true;
							break;
						}
					}
					if (!found) {
						state[i].tags.push(action.tag);
					}
				}
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return state;
		}
		case "ADD_BOARD_CATEGORY": {
			for (const i in state) {
				let found = false;
				for (let x = 0; x < state[i].categories.length; x++) {
					if (state[i].categories[x].id == action.category.id) {
						found = true;
						break;
					}
				}
				if (!found) {
					state[i].categories.push(action.category);
				}
			}
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return state;
		}
		default:
			return state;
	}
};
