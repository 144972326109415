import i18next from "i18next";

const customerFields = {
	customer: i18next.t("orders.customer.fields.customer.label", "Kontakt"),
	name: i18next.t("orders.customer.fields.name.label", "Namn"),
	organisationNumber: i18next.t("orders.customer.fields.organisationNumber.label", "Organisationsnummer"),
	address: i18next.t("orders.customer.fields.address.label", "Adress"),
	address2: i18next.t("orders.customer.fields.address2.label", "Adress2"),
	city: i18next.t("orders.customer.fields.city.label", "Stad"),
	postalCode: i18next.t("orders.customer.fields.postalCode.label", "Postnummer"),
	country: i18next.t("orders.customer.fields.country.label", "Land"),
	phone: i18next.t("orders.customer.fields.phone.label", "Telefon"),
	email: i18next.t("orders.customer.fields.email.label", "E-post"),
};

export default customerFields;
