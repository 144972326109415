import React, { Component } from "react";
import { Button } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { store } from "../store";
import AppIntegration from "./AppIntegration.js";
import Sheet from "./sheet";

class IntegrationSheet extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open) {
			setTimeout(() => {
				const sheet = document.querySelector(".Polaris-Sheet__Container");
				if (sheet.querySelector(".Integration_Sheet-Content") && !sheet.classList.contains(this.props.integration.type)) {
					sheet.classList.add(this.props.integration.type);
				}
			}, 0);
		}
	}

	onClose() {
		this.props.onClose();
	}

	render() {
		let data = {};
		if (this.props.integration && (this.props.integration.id == 1 || this.props.integration.id == 5)) {
			// Tidrapport24 legacy
			data = Object.assign({}, this.props.contact);
		} else {
			data = {};
			if (this.props.contact) {
				data.id = this.props.contact.id;
				data.is_company = this.props.contact.is_company;
				data.parent = null;
				if (this.props.contact.parent) {
					data.parent = { id: this.props.contact.parent.id };
				}

				data.contact = Object.assign({}, data);
			}
		}

		data.signed_token = store.getState().user.signed_token;
		data.account_id = store.getState().account.id;

		if (this.props.project) {
			data.project = { id: this.props.project.id };
		}
		if (this.props.projectTask) {
			data.project_task = { id: this.props.projectTask.id };
		}
		if (this.props.deal) {
			data.deal = { id: this.props.deal.id };
		}
		if (this.props.ticket) {
			data.ticket = { id: this.props.ticket.id, project_id: this.props.ticket.project ? this.props.ticket.project.id : null };
		}
		if (this.props.board) {
			data.board = { id: this.props.board.id, title: this.props.board.title };
		}
		if (this.props.row) {
			data.row = { id: this.props.row.id, title: this.props.row.title };
		}

		let frameUrl;
		if (this.props.integration && this.props.integration.iframe_url) {
			frameUrl =
				this.props.integration.iframe_url +
				(this.props.integration.iframe_url.indexOf("?") >= 0 ? "&" : "?") +
				httpBuildQuery(data).replace(/\+/g, "%20");
		} else {
			frameUrl = null;
		}
		return (
			<Sheet open={this.props.open} onClose={this.onClose.bind(this)} hideHeader>
				<div
					style={{
						position: "absolute",
						top: 10,
						right: 10,
						zIndex: 9999,
					}}
				>
					<span className={`integration_close_btn${this.props.integration?.type != "web" ? " other" : " web"}`}>
						<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.onClose.bind(this)} plain />
					</span>
				</div>
				{this.props.open ? (
					this.props.integration.type != "web" ? (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<AppIntegration {...this.props} path={this.props.integration.link.handle} integration={this.props.integration} />
					) : (
						<iframe title={frameUrl} src={frameUrl} frameBorder="0" width="100%" height="100%" style={{ minHeight: 100 }} />
					)
				) : null}
			</Sheet>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />
export default IntegrationSheet;
