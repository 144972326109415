import React, { Component } from "react";

import RowActivity from "./RowActivity.js";

class RowActivityLog extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<div>
				<div style={{ marginTop: 20 }}>
					{this.props.events.map((activity) => (
						<RowActivity key={activity.id} activity={activity} />
					))}
				</div>
			</div>
		);
	}
}
export default RowActivityLog;
