import React from "react";

const DiceIcon = ({ color = "var(--textColor)" }) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.66667 1.83333V2.3525L5.83333 3.51917V1.83333H8.16667V4.16667H6.48083L7.6475 5.33333H8.16667V5.8525L9.33333 7.01917V5.33333H11.6667V7.66667H9.98083L11.1475 8.83333H11.6667V9.3525L12.8333 10.5192V1.83333C12.8333 1.19167 12.3083 0.666668 11.6667 0.666668H2.98083L4.1475 1.83333H4.66667ZM9.33333 1.83333H11.6667V4.16667H9.33333V1.83333ZM0.8225 0.165001L0 0.987501L1.16667 2.15417V11.1667C1.16667 11.8083 1.69167 12.3333 2.33333 12.3333H11.3458L12.5183 13.5058L13.3408 12.6833L0.8225 0.165001ZM5.83333 6.82083L6.67917 7.66667H5.83333V6.82083ZM2.33333 3.32083L3.17917 4.16667H2.33333V3.32083ZM4.66667 11.1667H2.33333V8.83333H4.66667V11.1667ZM4.66667 7.66667H2.33333V5.33333H4.34583L4.66667 5.65417V7.66667ZM8.16667 11.1667H5.83333V8.83333H7.84583L8.16667 9.15417V11.1667ZM9.33333 11.1667V10.3208L10.1792 11.1667H9.33333Z"
			fill={color}
		/>
	</svg>
);

export default DiceIcon;
