import styled from "styled-components";

export const ButtonGroupWrapper = styled.div`
	.Polaris-ButtonGroup {
		margin: 0;

		.Polaris-ButtonGroup__Item {
			margin: 0;

			button {
				border-radius: 0;
			}

			&:last-child button {
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
			}

			&:first-child button {
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
			}
		}
	}
`;
