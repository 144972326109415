import React from "react";

const SkipIcon = ({ color = "black" }) => (
	<svg width="25" height="31" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.596 16.5553L1.75252 29.117C1.61373 29.2094 1.45249 29.2623 1.28597 29.2703C1.11946 29.2783 0.953898 29.2409 0.806929 29.1622C0.65996 29.0836 0.537086 28.9665 0.451393 28.8235C0.365701 28.6805 0.320399 28.5169 0.320312 28.3502V2.30143C0.320399 2.13472 0.365701 1.97116 0.451393 1.82816C0.537086 1.68516 0.65996 1.56809 0.806929 1.4894C0.953898 1.41072 1.11946 1.37337 1.28597 1.38133C1.45249 1.3893 1.61373 1.44228 1.75252 1.53464L20.596 14.0964V2.42308C20.596 1.93422 20.7902 1.46539 21.1359 1.11971C21.4816 0.774033 21.9504 0.579834 22.4393 0.579834C22.9282 0.579834 23.397 0.774033 23.7427 1.11971C24.0883 1.46539 24.2825 1.93422 24.2825 2.42308V28.2286C24.2825 28.7174 24.0883 29.1863 23.7427 29.5319C23.397 29.8776 22.9282 30.0718 22.4393 30.0718C21.9504 30.0718 21.4816 29.8776 21.1359 29.5319C20.7902 29.1863 20.596 28.7174 20.596 28.2286V16.5553Z"
			fill="black"
		/>
	</svg>
);

export default SkipIcon;
