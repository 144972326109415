import { ActionList, Icon } from "@shopify/polaris";
import { MobileVerticalDotsMajor, SortMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BoardAccessModal from "../views/Workspaces/BoardAccessModal";
import Popover from "./Popover";
import WorkspaceNavigationMoveModal from "./WorkspaceNavigationMoveModal";

class WorkspaceNavigationBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/* form: props.child */
		};
	}

	// getType(child) {
	// 	return child.handle.replace("_" + child.id, "");
	// }

	render() {
		const { icon, child, url, dragHandleProps, action } = this.props;

		const actions = [
			{
				content: this.props.t("workspace.navigation.actions.child.move", "Flytta"),
				icon: SortMinor,
				onAction: () => {
					this.setState({ moveModalIsOpen: true });
				},
			},
			// {
			// 	content: this.props.t("workspace.navigation.actions.board.access", "Ändra åtkomst"),
			// 	icon: GrammarMajor,
			// 	onAction: () => {
			// 		this.setState({ accessModalIsOpen: true });
			// 	},
			// 	enabled: this.getType(child) === "board",
			// },
			// {
			// 	content: this.props.t("workspace.navigation.actions.board.rename", "Döp om board"),
			// 	icon: GrammarMajor,
			// 	onAction: () => {
			// 		this.setState({ renameModalIsOpen: true });
			// 	},
			// 	enabled: this.getType(child) === "board",
			// },
		].filter((action) => !("enabled" in action) || action.enabled);

		const content = (
			<React.Fragment>
				<div>
					<Icon source={icon} />

					{child.title}
				</div>

				{child.count && child.count > 0 ? (
					<div className="Polaris-Navigation__Badge">
						<span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">
							<span className="Polaris-VisuallyHidden" />

							<span className="Polaris-Badge__Content">{child.count}</span>
						</span>
					</div>
				) : null}
			</React.Fragment>
		);

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<li className={`Polaris-Navigation__ListItem ${actions?.length ? " have_actions" : ""}`} {...dragHandleProps}>
					{url.includes("www") || url.includes("http") ? (
						<div
							onClick={action}
							className={
								"mainmenu-item__secondary__item" + (url.indexOf(this.props.location.pathname) >= 0 ? " Polaris-Navigation__Item--selected" : "")
							}
						>
							{content}
						</div>
					) : (
						<Link
							onClick={action}
							to={url}
							className={
								"mainmenu-item__secondary__item" + (url.indexOf(this.props.location.pathname) >= 0 ? " Polaris-Navigation__Item--selected" : "")
							}
						>
							{content}
						</Link>
					)}
					<span
						className="Polaris-Navigation__Action__Wrapper mainmenu-board_actions"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<Popover
							active={this.state.showAddContext}
							activator={
								<button
									className="Polaris-Navigation__Action"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										this.setState({ showAddContext: true /* form: child */ });
									}}
								>
									<Icon source={MobileVerticalDotsMajor} />
								</button>
							}
							onClose={() => this.setState({ showAddContext: false })}
						>
							<ActionList
								items={actions}
								onActionAnyItem={() => {
									this.setState({ showAddContext: false });
								}}
							/>
						</Popover>
					</span>
				</li>
				<WorkspaceNavigationMoveModal
					open={this.state.moveModalIsOpen}
					onClose={() => {
						this.setState({ moveModalIsOpen: false });
					}}
					child={child}
					onSuccess={this.props.fetch}
				/>
				<BoardAccessModal
					open={this.state.accessModalIsOpen}
					onClose={() => {
						this.setState({ accessModalIsOpen: false });
					}}
					// onUpdateBoard={this.props.onUpdateBoard}
					board={this.props.child}
				/>
			</>
		);
	}
}
export default withTranslation(["workspace", "common"], { withRef: true })(WorkspaceNavigationBoard);
