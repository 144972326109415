const getDefaultForm = (history: any) => {
	let data: Partial<ArticleType> = {
		vat_in_percentage: 25,
	};

	if (history?.location?.state?.data) {
		const article = history.location.state.data;

		data = {
			...data,
			...article,
		};
	}

	return data;
};
export default getDefaultForm;
