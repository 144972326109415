import React, { Component } from "react";
import { GrammarMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import ExpandableSection from "./ExpandableSection.js";
import NumberBadge from "./NumberBadge.js";
import Colors from "../Colors.js";
import { store } from "../store/index.js";
import Miniboard2 from "../views/Workspaces/components/Miniboard2.js";
import BoardHelper from "../views/Workspaces/BoardHelper.js";

class BoardExpandableSection extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const boardLink = `/admin/workspaces/none/boards/${this.props.board.id}`;

		return (
			<ExpandableSection
				icon={GrammarMajor}
				link={boardLink}
				title={this.props.board.title}
				loading={this.state.loadingBoard || this.state.loadingRows || this.state.loadingGroups}
				resourceName={{
					singular: (this.props.board && this.props.board.singular) || this.props.t("row,singular", "rad"),
					plural: (this.props.board && this.props.board.plural) || this.props.t("row,plural", "rader"),
				}}
				onExpand={() => {}}
				count={this.props.badge ? this.props.badge : <NumberBadge color={Colors.yellow}>{this.props.board.count}</NumberBadge>}
				history={this.props.history}
				className={this.props.className}
			>
				<div className="board_expandable_section" style={{ padding: 15, paddingTop: 0, minHeight: 300 }}>
					{/* <Miniboard
							board={store.getState().boards[this.props.board.id]}
							board_id={this.props.board.id}
							contact_id={this.props.onlyRowsWithContactId}
						/> */}
					<Miniboard2
						board={store.getState().boards[this.props.board.id]}
						board_id={this.props.board.id}
						contact_id={this.props.onlyRowsWithContactId}
						contact={store.getState().board_contacts[this.props.onlyRowsWithContactId]}
						onCreateRow={(row) => {
							BoardHelper.updateRows([row], "add");
							if (this.props.fetchResources) this.props.fetchResources(true);
							// this.props.board.count = parseInt(this.props.board.count) + 1;
						}}
					/>
					{/* <BoardView id={this.props.board.id} contact_id={this.props.onlyRowsWithContactId} forceList noheader nopreview /> */}
				</div>
			</ExpandableSection>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(BoardExpandableSection);
/*
const mapStateToProps = (state, ownProps) => ({
	boards: state.boards,
	rows: state.board_rows,
	groups: state.board_groups,
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
	board_linked_rows: state.board_linked_rows,
	board_tickets: state.board_tickets,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(BoardExpandableSection);
*/
