import React, { Component } from "react";
import { Button, Checkbox, Text, LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Modal from "../../components/modal";

class FortnoxSyncedFalseScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// componentDidMount() {
	// 	const { synced } = this.props;
	// 	if (this.props.syncCustomers && !synced) this.props.syncCustomers();
	// }

	render() {
		return (
			<div
				style={{
					height: "100%",
					width: "100%",
					backgroundImage: "url(https://s3-eu-west-1.amazonaws.com/static.kund24.se/crm/uploads/0dabf4cf568973652bef58afb333a639.jpg)",
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
				}}
			>
				<div
					style={{
						backgroundColor: "rgba(0, 0, 0, 0.8)",
						height: "100%",
						width: "100%",
					}}
				>
					<div
						style={{
							textAlign: "center",
							paddingTop: 100,
							margin: "0 auto",
							maxWidth: "90%",
						}}
					>
						<Text variant="headingMd">{this.props.t("fortnox.responses.connection_success", "KOPPLING TILL FORTNOX LYCKADES")}</Text>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div style={{ marginTop: 50, maxWidth: 700 }}>
								<Text variant="bodySm">
									{this.props.t(
										// eslint-disable-next-line quotes
										"fortnox.responses.connection_success_text2",
										"Ert Fortnox är nu sammankopplat med systemet. Nästa steg är att importera alla era företag och det gör ni genom att klicka på Importera företag här nedan."
									)}
								</Text>
							</div>
						</div>
						<div style={{ marginTop: 50 }}>
							<LegacyStack distribution="center" spacing="loose" vertical>
								<Button
									size="large"
									onClick={() => {
										this.setState({ showCodeModal: true });
									}}
									primary
								>
									{this.props.t("fortnox.actionss.import_company", "IMPORTERA FÖRETAG")}
								</Button>
							</LegacyStack>
						</div>
					</div>
				</div>

				<Modal
					title={this.props.t("fortnox.modals.settings.title", "Inställningar")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showCodeModal: false });
							},
						},
					]}
					primaryAction={{
						content: this.props.t("common.actions.continue", "Fortsätt"),
						onAction: this.props.syncCustomers,
					}}
					open={this.state.showCodeModal}
					onClose={() => {
						this.setState({ showCodeModal: false });
					}}
				>
					<Modal.Section>
						<div style={{ marginTop: 5 }}>
							{this.props.t(
								// eslint-disable-next-line quotes
								"fortnox.modals.settings.text",
								'Välj vilken information som ska hämtas från Fortnox och klicka därefter på "Fortsätt" för att importera företagen.'
							)}
						</div>
						<div style={{ marginTop: 15 }}>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("name") >= 0}
								onChange={() => {
									this.props.toggleField("name");
								}}
								label={this.props.t("fortnox.modals.settings.fields.name.label", "Företagsnamn")}
								disabled
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("orgnr") >= 0}
								onChange={() => {
									this.props.toggleField("orgnr");
								}}
								label={this.props.t("fortnox.modals.settings.fields.orgnr.label", "Organisationsnummer")}
								disabled
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("phone") >= 0}
								onChange={() => {
									this.props.toggleField("phone");
								}}
								label={this.props.t("fortnox.modals.settings.fields.phone.label", "Telefon")}
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("email") >= 0}
								onChange={() => {
									this.props.toggleField("email");
								}}
								label={this.props.t("fortnox.modals.settings.fields.email.label", "Email")}
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("address1") >= 0}
								onChange={() => {
									this.props.toggleField("address1");
								}}
								label={this.props.t("fortnox.modals.settings.fields.address1.label", "Adressrad 1")}
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("address2") >= 0}
								onChange={() => {
									this.props.toggleField("address2");
								}}
								label={this.props.t("fortnox.modals.settings.fields.address2.label", "Adressrad 2")}
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("zip") >= 0}
								onChange={() => {
									this.props.toggleField("zip");
								}}
								label={this.props.t("fortnox.modals.settings.fields.zip.label", "Postnummer")}
							/>
						</div>
						<div>
							<Checkbox
								checked={this.props.options.sync_fields.indexOf("city") >= 0}
								onChange={() => {
									this.props.toggleField("city");
								}}
								label={this.props.t("fortnox.modals.settings.fields.city.label", "Stad")}
							/>
						</div>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxSyncedFalseScreen);
