// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useLayoutEffect, useState } from "react";

const useMountTransition = (isMounted: boolean | null | undefined, unmountDelay = 250) => {
	const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

	// useEffect(() => {
	useLayoutEffect(() => {
		let timeoutId: any;

		if (isMounted && !hasTransitionedIn) {
			setHasTransitionedIn(true);
		} else if (!isMounted && hasTransitionedIn) {
			timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [unmountDelay, isMounted, hasTransitionedIn]);

	return hasTransitionedIn;
};

export default useMountTransition;
