/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { EmptyState } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

import NotificationItem from "../../components/NotificationItem";
import IndexTable from "src/js/components/IndexTable/IndexTable";

class NotificationsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstFetch: true,
			empty: false,
			selectedItems: [],
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	updateParams(params) {
		this.setState({});
	}

	renderItem(item) {
		return <NotificationItem notification={item} style={{ paddingLeft: "1.2500rem" }} />;
		// return (
		// 	<ResourceList.Item id={item.id} url={item.url}>
		// 		<div className="CustomerListItem__Main">
		// 			<div className="CustomerListItem__Profile">
		// 				<h3 className="CustomerListItem__Title">{item.content}</h3>
		// 				<span className="CustomerListItem__Location" />
		// 			</div>
		// 			<div className="CustomerListItem__Orders">
		// 				<span className="CustomerListItem__OrderCount" />
		// 				<span className="CustomerListItem__TotalSpent" />
		// 			</div>
		// 		</div>
		// 		<div />
		// 		{moment(item.created_at).format("YYYY-MM-DD HH:mm")}
		// 	</ResourceList.Item>
		// );
	}

	onItemsFetched(items) {
		if (items.length < 1 && this.state.firstFetch) {
			this.setState({ empty: true });
		}
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/contacts/" + items[i].id;
		}
		// eslint-disable-next-line react/no-unused-state
		this.setState({ firstFetch: false, visibleItems: items });
	}

	render() {
		if (this.state.empty) {
			return (
				<EmptyState heading={this.props.t("notifications.empty.heading", "Inga notiser")} image="/assets/images/empty_state/Notification.png">
					<p>{this.props.t("notifications.empty.description", "När en medarbetare nämner eller skickar saker till dig notifieras du om det.")}</p>
				</EmptyState>
			);
		}
		const filters = [
			{
				key: "user_id",
				label: this.props.t("notifications.filters.send_from.label", "Skickat ifrån"),
				type: "user",
			},
		];

		return (
			<div>
				<IndexTable
					titleHidden
					tabsEnabled={false}
					searchFiltersEnabled
					urlParamsEnabled={false}
					selectable={false}
					history={this.props.history}
					title={this.props.t("notifications.title", "Notiser")}
					savedSearchHandle="notifications"
					resourceUrl="/api/notifications.json"
					resourceHandle="notifications"
					onItemsFetched={this.onItemsFetched.bind(this)}
					onSelectionChange={this.onSelectionChange.bind(this)}
					resourceName={{
						singular: this.props.t("notifications.singular", "notis"),
						plural: this.props.t("notifications.plural", "notiser"),
					}}
					onUpdateParams={this.updateParams.bind(this)}
					columns={[
						{
							label: "",
							handle: "",
							render: this.renderItem.bind(this),
						},
					]}
					filters={filters}
					items={this.props.items}
					loading={this.props.loading}
					limit={20}
				/>
			</div>
		);
	}
}

export default withTranslation(["notifications", "common"], { withRef: true })(NotificationsIndex);
