import React, { Component } from "react";

export default class FortnoxTableSummaryColumn extends Component {
	render() {
		const value = ((value) => {
			if (value && !(Number.isNaN(value) || !Number.isFinite(value))) {
				return new Intl.NumberFormat("se-SV", {
					style: "currency",
					currency: (this.props.sufflix || "SEK").toUpperCase(),
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})
					.format(parseFloat(value))
					.replace(",", ".");
			}
			return "";
		})(this.props.value);

		return <p>{`${value}`}</p>;
	}
}
