import React from "react";

const OffertIcon = ({ color = "var(--textColor)" }) => (
	<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.2073 0.0360413H2.05278C1.12194 0.0360413 0.360352 0.797633 0.360352 1.72847V13.5755H2.05278V1.72847H12.2073V0.0360413ZM14.746 3.4209H5.43763C4.5068 3.4209 3.74521 4.18249 3.74521 5.11332V16.9603C3.74521 17.8911 4.5068 18.6527 5.43763 18.6527H14.746C15.6768 18.6527 16.4384 17.8911 16.4384 16.9603V5.11332C16.4384 4.18249 15.6768 3.4209 14.746 3.4209ZM14.746 16.9603H5.43763V5.11332H14.746V16.9603Z"
			fill={color}
		/>
	</svg>
);

export default OffertIcon;
