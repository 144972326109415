import i18next from "i18next";

export default [
	{
		label: i18next.t("customfields.fields.type.options.text", "Text"),
		value: "text",
	},
	{
		label: i18next.t("customfields.fields.type.options.date", "Datum"),
		value: "date",
	},
	{
		label: i18next.t("customfields.fields.type.options.dropdown", "Dropdown"),
		value: "dropdown",
	},
];
