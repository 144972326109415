import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API from "src/js/API";
import Detailsview from "src/js/components/Detailsview";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

const OrderNoteModal = ({ order, note, onClose, onPrimaryAction, onDestructiveAction, open }) => {
	const [form, setForm] = useState<any>(note);
	const { t } = useTranslation(["order", "common"]);
	const storeUser = useSelector((state: any) => state.user);

	useEffect(() => {
		setForm(note);
	}, [note]);

	const handleSave = async () => {
		try {
			const result = form?.id
				? await API.put(`/api/orders/${order.id}/notes/${form.id}.json`, form)
				: await API.post(`/api/orders/${order.id}/notes.json`, form);

			const message = form?.id ? "Noteringen har uppdaterats" : "Noteringen har skapats";
			Toast.success(message);

			setForm(result.data.order_note);

			if (onPrimaryAction) onPrimaryAction(result.data.order_note);

			return result.data.order_note;
		} catch (error) {
			Toast.error(error);
		}
	};

	const handleDeleteNote = async () => {
		try {
			await API.delete(`/api/orders/${order.id}/notes/${note.id}.json`);
			Toast.success("Noteringen har tagits bort");
			if (onDestructiveAction) onDestructiveAction();
		} catch (error) {
			Toast.error(error);
		}
	};

	const getChangeHandler = (key) => (value) => {
		setForm((prev) => ({ ...prev, [key]: value }));
	};

	const canEdit = !note?.id || storeUser.id === note?.created_by?.id;

	const title = (() => {
		if (!note?.id) return t("order.titles.create_note", "Skapa notering");
		if (note?.id && canEdit) return t("order.titles.edit_note", "Redigera notering");
		return t("order.titles.view_note", "Notering");
	})();

	return (
		<Modal
			title={title}
			open={open}
			onClose={onClose}
			primaryAction={{
				content: note?.id ? (t("common.save", "Spara") as string) : (t("common.create", "Skapa") as string),
				onAction: handleSave,
				enabled: !!canEdit,
			}}
			secondaryActions={[
				{
					content: t("common.cancel", "Avbryt") as string,
					onAction: onClose,
				},
				{
					content: t("common.actions.delete", "Ta bort") as string,
					onAction: handleDeleteNote,
					destructive: true,
					enabled: !!note?.id && !!canEdit,
				},
			]}
		>
			<TextField
				readOnly={!canEdit}
				label={t("order.note", "Notering")}
				value={form?.note}
				onChange={getChangeHandler("note")}
				multiline={4}
				showCharacterCount
			/>
			{form?.id && (
				<Detailsview
					style={{ marginTop: 20, width: "fit-content", opacity: 0.5, fontSize: "0.9rem" }}
					items={[
						{
							label: "Skapad av",
							value: form?.created_by?.name,
							type: "string",
						},
						{
							label: "Skapad",
							value: form?.created_at,
							type: "string",
						},
						{
							label: "Uppdaterad",
							value: form?.edited_at,
							type: "string",
						},
					]}
				/>
			)}
		</Modal>
	);
};
export default OrderNoteModal;
