import { Button, Select } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { store } from "../../store";

class Box extends Component {
	constructor(props) {
		super(props);
		this.state = { category: props.categories && props.categories[0] };
	}

	onChange(id) {
		const category = store.getState().ad_accounts.find((cat) => cat.id == id);
		this.setState({ category });
	}

	render() {
		const { categories, onSelect, adAccount, imgUrl, title, createUrl, history } = this.props;
		return (
			<Item>
				<ItemImage src={imgUrl} />
				<ItemTitle>
					{categories && categories?.length > 1 ? (
						<Select
							options={categories.map((category) => ({ label: category.title, value: category.id }))}
							onChange={this.onChange.bind(this)}
							value={categories.find((cat) => cat.id === adAccount.id) && categories.find((cat) => cat.id === adAccount.id).id}
						/>
					) : (
						<h2>{categories && categories[0] && categories[0].title}</h2>
					)}
				</ItemTitle>
				<ItemButton>
					{categories && (
						<Button primary onClick={() => onSelect(this.state.category || categories[0])}>
							{this.props.t("common.term.choose", "Välj")}
						</Button>
					)}
					{!categories && createUrl && (
						<Button
							primary
							onClick={() => {
								history.push(createUrl);
							}}
						>
							{this.props.t("ads.account.action.create", "Skapa {{title}} ad konto", { title })}
						</Button>
					)}
				</ItemButton>
			</Item>
		);
	}
}
export default withTranslation(["ads", "common"], { withRef: true })(Box);

const Item = styled.div`
	padding: 1.2500rem;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	flex: 1;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	gap: 0.6250rem;
`;
const ItemImage = styled.img`
	width: 75px;
	height: 75px;
	object-fit: contain;
`;
const ItemTitle = styled.div`
	margin-top: 0.6250rem;
	color: var(--textColor);
`;
const ItemButton = styled.div`
	.Polaris-Button {
		min-width: 5.0000rem;
	}
`;
