import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class NextButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { onSkip, onClose, index, slides } = this.props;

		if (index < slides.length - 1) {
			return (
				<button className="next" onClick={onSkip}>
					{this.props.t("announcements.actions.skip", "Hoppa över")}
				</button>
			);
		}
		return (
			<button
				className="next"
				onClick={() => {
					onClose();
					const origin = window.location.origin;
					window.location = origin + "/admin";
				}}
			>
				{this.props.t("announcements.actions.done", "Klar")}
			</button>
		);
	}
}
export default withTranslation(["announcements", "common"], { withRef: true })(NextButton);
