import React, { Component } from "react";
import styled from "styled-components";
import { LegacyCard, Icon } from "@shopify/polaris";
import { MobileHorizontalDotsMajor, PlayCircleMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Toast from "src/js/components/Toast";

class FacebookAdPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (this.props.ad_account) {
			API.get(`/api/facebook/${this.props.ad_account.id}/page/${this.props.ad_account.options.page_id}.json`)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					this.setState({ page: result.data.page });
				})
				.catch((error) => {
					Toast.error(error);
				});
		}
	}

	render() {
		const {
			image,
			header_title: headerTitle,
			display_link: displayLink,
			title,
			title2,
			description,
			buttonText,
			showFacebookFooter,
			profileImageUrl,
			video,
		} = this.props;
		const imageWrapperStyle = { position: "relative", background: "grey" };
		if (video && !image) {
			imageWrapperStyle.aspectRatio = "16/9";
		}

		return (
			<Wrapper>
				<LegacyCard>
					<LegacyCard.Section>
						<Header>
							<Logo src={profileImageUrl} />
							<div>
								<Name>{(this.state.page && this.state.page.name) || null}</Name>
								<SponsoredText>
									{this.props.t("ads.facebook_preview.terms.sponsored", "Sponsored")}{" "}
									<img alt="" src="/assets/images/2231957_earth_globe_notification_icon_128.png" />
								</SponsoredText>
							</div>

							<DotsButton>
								<Icon source={MobileHorizontalDotsMajor} />
							</DotsButton>
						</Header>
					</LegacyCard.Section>
					<LegacyCard.Section>
						<PrimaryText style={{ fontSize: 14 }}>{headerTitle}</PrimaryText>
					</LegacyCard.Section>
					<div style={imageWrapperStyle}>
						{video && (
							<PlayButton>
								<Icon source={PlayCircleMajor} />
							</PlayButton>
						)}
						{image && <Image src={image.url} />}
					</div>
					<LegacyCard.Section>
						<Footer>
							<div>
								{displayLink && <DispalyLink>{displayLink}</DispalyLink>}
								<Title>{title}</Title>
								{title2 && <Title2>{title2}</Title2>} <Description>{description}</Description>
							</div>
							{(title || description) && (
								<LearnMoreButtonWrapper>
									<LearnMoreButton>{buttonText || this.props.t("ads.facebook_preview.terms.learn_more", "Learn More")}</LearnMoreButton>
								</LearnMoreButtonWrapper>
							)}
						</Footer>
						{showFacebookFooter && (
							<LikesAndCommnetsFooter>
								<div style={{ display: "flex", gap: "0.6250rem" }}>
									<img alt="" src="/assets/images/FacebookReactionsIcons.png" />
									<span>129</span>
								</div>
								<span>{this.props.t("ads.facebook_preview.terms.stats", "12 Comments 8 Shares")}</span>
							</LikesAndCommnetsFooter>
						)}
					</LegacyCard.Section>
				</LegacyCard>
			</Wrapper>
		);
	}
}
export default withTranslation(["ads", "common"], { withRef: true })(FacebookAdPreview);

const Wrapper = styled.div`
	padding: 0.3125rem;
	position: relative;

	&&& {
		.Polaris-Card__Section,
		.Polaris-LegacyCard__Section {
			display: block;
			padding: 0.625rem;
			border: none;
		}
	}
`;
const Header = styled.div`
	display: flex;
	gap: 1.25rem;
	align-items: center;
	position: relative;
`;
const Name = styled.h1`
	color: rgb(25, 42, 128);
	font-weight: 600;
`;
const PrimaryText = styled.h2`
	font-size: 1.35em;
`;
const Logo = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
const Image = styled.img`
	max-width: 100%;
	height: auto;
	width: 100%;
`;
const SponsoredText = styled.div`
	color: rgb(179, 186, 194);
	font-size: 10px;
	display: flex;
	align-items: center;
	gap: 0.4688rem;

	img {
		height: 10px;
	}
`;

const Title = styled.h2`
	font-size: 16px;
	font-weight: 700;
`;
const Title2 = styled.h2`
	font-size: 16px;
`;
const DispalyLink = styled.p`
	color: #707070;
`;
const Description = styled.p`
	color: #707070;
	font-size: 14px;
`;

const DotsButton = styled.div`
	position: absolute;
	right: 10px;
	height: 100%;
	display: flex;
	align-items: center;

	.Polaris-Icon {
		margin: 0;
		fill: grey;
	}
`;

const Footer = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LearnMoreButtonWrapper = styled.div`
	/* position: absolute; */
	/* right: 10px; */
	height: 100%;
	display: flex;
	align-items: center;
	/* top: 0; */
`;

const LearnMoreButton = styled.div`
	font-weight: 700;
	background-color: rgb(228, 230, 235);
	border-radius: 5px;
	padding: 10px;
	font-size: 13px;
	width: max-content;
	color: black;
`;

const LikesAndCommnetsFooter = styled.div`
	border-top: 1px solid #e5e5e5;
	padding-top: 0.625rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #65676b;

	span {
		color: #65676b;
	}
`;

const PlayButton = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.8;

	.Polaris-Icon {
		width: 100px;
		height: 100px;
		fill: #8d8d8d;
	}
`;
