export default (state = [], action) => {
	switch (action.type) {
		case "SET_BOARD_LINKED_ROWS": {
			const linkedRows = state;
			for (let i = 0; i < action.linked_rows.length; i++) {
				const row = action.linked_rows[i];
				const rowId = row.id;
				linkedRows[rowId] = row;
			}
			return linkedRows;
		}
		case "UPDATE_BOARD_LINKED_ROW": {
			state[action.linked_row.id] = action.linked_row;
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};
