import React, { useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { useEventListener } from "../../hooks/UseEventListener";
import useSheetSettings from "./useSheetSettings";
import { MAX_WIDTH_PERCENTAGE, MIN_WIDTH } from "./SheetContext";

const SheetWidthResizer = () => {
	const {
		sheetSettings: { isPinned },
		updateSheetSettingsKey,
	} = useSheetSettings();
	const [isActive, setIsActive] = useState(false);

	const cssKey = isPinned ? "--sheet-width-pinned" : "--sheet-width";
	const key = isPinned ? "pinnedWidth" : "width";

	const saveWidth = () => {
		const width = document.body.style.getPropertyValue(cssKey);
		if (width) {
			updateSheetSettingsKey(key, parseInt(width?.replace("px", "")));
		}
	};

	useEventListener("blur", () => {
		setIsActive(false);
		saveWidth();
	});

	const onMouseDown = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsActive(true);
	};

	const getWidth = (x) => {
		return Math.max(Math.min(window.innerWidth - x, window.innerWidth * MAX_WIDTH_PERCENTAGE), MIN_WIDTH);
	};

	const onMouseMove = (e) => {
		e.stopPropagation();
		if (!isActive) return;
		if (e.clientX < 10) {
			setIsActive(false);
			saveWidth();
		}

		const width = getWidth(e.clientX);
		document.body.style.setProperty(cssKey, width + "px");
	};

	const onMouseUp = (e) => {
		setIsActive(false);
		saveWidth();
	};

	return (
		<>
			<ResizeBar onMouseDown={onMouseDown} className={isActive ? "active" : ""} />
			{isActive && createPortal(<BackDrop onMouseMove={onMouseMove} onMouseUp={onMouseUp} />, document.body)}
		</>
	);
};
export default SheetWidthResizer;

const BackDrop = styled.div`
	inset: 0;
	position: fixed;
	z-index: 99999999;
	cursor: col-resize;
`;

const ResizeBar = styled.div`
	position: fixed;
	height: 100%;
	width: 10px;
	transition: all 0.2s ease-in-out;
	background-color: var(--modals-bg);
	left: 0px;
	cursor: col-resize;
	z-index: 1;

	&:hover,
	&:active,
	&.active {
		cursor: col-resize;
		filter: brightness(1.5);
		z-index: 3;
	}
`;
