	import { useSelector, useDispatch } from "react-redux";
import API from "../API";

const useUser = () => {
	const user = useSelector((state: any) => state.user);
	const account = useSelector((state: any) => state.account);
	const dispatch = useDispatch();

	const fetchUser = async () => {
		return await API.get("/api/me.json").then((result) => {
			if (result.data.user) {
				dispatch({ type: "SET_USER", user: { ...user, ...result.data.user } });
			}

			return result.data.user;
		});
	};

	const fetchAccount = async () => {
		return await API.get("/api/account.json").then((result) => {
			if (result.data) {
				dispatch({ type: "SET_ACCOUNT", account: result.data });
			}

			return result.data;
		});
	};

	return { user, account, fetchUser, fetchAccount };
};
export default useUser;
