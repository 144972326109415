import React, { Component } from "react";
import moment from "moment";

class CellCreatedAt extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="overflow-ellipsis" style={{ width: "100%", cursor: "text", textAlign: "center", height: 29 }}>
				{this.props.row && moment(this.props.row.created_at).locale("sv").calendar()}
			</div>
		);
	}
}
export default CellCreatedAt;
