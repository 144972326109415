import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ResourcePicker from "./resource_picker.js";

class SniSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<ResourcePicker
				resource="sni"
				caption={this.props.caption === undefined ? this.props.t("common.sni_selector.caption", "Välj bransch") : this.props.caption}
				resourceName={{ singular: "sni", plural: "snis" }}
				resource_handle="sni"
				id_handle="id"
				label={this.props.label === undefined ? this.props.t("common.sni_selector.label", "Välj bransch") : this.props.label}
				label_handle="title"
				item={this.state.value}
				onChange={(v) => {
					this.setState({ value: v });
					this.props.onChange(v);
				}}
			/>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(SniSelector);
