import React, { useRef, useState } from "react";
import { Badge, Avatar, HorizontalStack, VerticalStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import UserInviter from "../../components/UserInviter";
import IndexTable from "src/js/components/IndexTable/IndexTable";
import Modal from "src/js/components/modal";
import { useSelector } from "react-redux";

const CustomerIndex = ({ t, ...props }: any) => {
	const [showInviteModal, setShowInviteModal] = useState(false);
	const ref = useRef<any>();
	const roleOptions = useSelector((state: any) => state.role_options);

	const filters = [
		{
			key: "roles",
			label: t("user.filters.roles.label", "Behörigheter:"),
			// type: "user_roles",
			type: "nested_options",
			operatorText: "",
			search: true,
			allowMultiple: true,
			options: roleOptions,
			shortcut: true,
		},
		{
			key: "enabled",
			label: t("user.filters.disabled.label", "avstängd"),
			type: "select",
			options: [
				{ value: "0", label: t("common.terms.yes", "Ja") },
				{ value: "1", label: t("common.terms.no", "Nej") },
			],
			shortcut: true,
		},
	];

	return (
		<div>
			<IndexTable
				onClickRow={(item) => {
					props.history.push("/admin/users/" + item.id);
				}}
				columns={[
					{
						handle: "name",
						label: t("user.fields.name", "Namn"),
						sortable: true,
						render: (item) => {
							return (
								<VerticalStack gap="2">
									<HorizontalStack blockAlign="center" gap="2">
										<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />
										{item.name}
										{!item.enabled ? <Badge>{t("user.statuses.disabled", "avstängd")}</Badge> : null}
									</HorizontalStack>
								</VerticalStack>
							);
						},
					},
					{
						handle: "group",
						label: t("user.fields.group", "Grupp"),
						sortable: true,
						render: (item) => {
							return item.group ? item.group.name : "";
						},
					},
				]}
				history={props.history}
				ref={ref}
				setRefreshHandler={(refreshHandler) => {
					refreshHandler.current = refreshHandler;
				}}
				title={t("user.title", "Användare")}
				primaryAction={{
					content: t("user.actions.invite", "Bjud in användare"),
					onAction: () => {
						setShowInviteModal(true);
					},
				}}
				savedSearchHandle="users"
				resourceUrl="/api/users.json"
				resourceHandle="users"
				resourceName={{
					singular: t("user.singular", "användare"),
					plural: t("user.plural", "användare"),
				}}
				filters={filters}
			/>
			<Modal
				open={showInviteModal}
				onClose={() => {
					setShowInviteModal(false);
				}}
				title={t("user.actions.invite", "Bjud in användare")}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng"),
						onAction: () => {
							setShowInviteModal(false);
						},
					},
				]}
				large
			>
				<Modal.Section>
					<UserInviter
						onSave={() => {
							setShowInviteModal(false);
							ref.current.reload();
						}}
					/>
				</Modal.Section>
			</Modal>
		</div>
	);
};

export default withTranslation(["user", "common"], { withRef: true })(CustomerIndex);
