import { Select } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import Modal from "src/js/components/modal";
import useWorkspaces from "../hooks/useWorkspaces";
import Toast from "src/js/components/Toast";

const WorkspaceNavigationMoveModal = ({ open, onClose, onSuccess, child, t }) => {
	const [form, setForm] = useState(child || {});
	const { fetchWorkspaces } = useWorkspaces();

	const getType = (child) => {
		return child.handle.replace("_" + child.id, "");
	};

	const getMoveToWorkspaces = useCallback((workspace, depth) => {
		if (!workspace.children?.length) return [];
		return workspace.children.flatMap((workspace) => [{ ...workspace, depth }, ...getMoveToWorkspaces(workspace, depth + 1)]);
	}, []);

	const workspaces = useMemo(() => {
		if (!open) return [];

		const ignoreWorkSpaceIds = [store.getState().account.sales_workspace_id];
		const mainWorkspaces = store
			.getState()
			.workspaces.map((workspace) => ({ ...workspace, depth: 0 }))
			?.filter((workspace) => !ignoreWorkSpaceIds.includes(workspace?.id));

		if (getType(child) === "board") return mainWorkspaces.flatMap((workspace) => [{ ...workspace, depth: 0 }, ...getMoveToWorkspaces(workspace, 1)]);

		return mainWorkspaces.map((workspace) => ({ ...workspace, depth: 0 }));
	}, [child, open, getMoveToWorkspaces]);

	useEffect(() => {
		if (!open) return;

		setForm(child || {});
	}, [open, child]);

	const onChange = (field) => (value) => {
		setForm((c) => ({ ...c, [field]: value }));
	};

	const onSave = async () => {
		// store.dispatch({ type: "UPDATE_WORKSPACE", workspace: result.data.workspace });

		return await API.put(
			"/api/workspaces/move.json",
			{
				draggableId: child.handle,
				workspace_id: form.parent_id,
				// before_handle: beforeHandle != result.draggableId ? beforeHandle : null,
			},
			{ params: {} }
		)
			.then(async (result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				onClose?.();
				onSuccess?.();

				Toast.success(
					t("workspace.responses.moved_to_workspace", "Flyttade {{title}} till {{toTitle}}", {
						title: form.title,
						toTitle: workspaces.find((w) => String(w.id) === String(form.parent_id))?.title,
					})
				);

				await fetchWorkspaces();
			})
			.catch((error) => {
				Toast.error(error);
			});
	};

	return (
		<Modal
			title={`${t("workspace.terms.move", "Flytta")} ${child?.title}`}
			open={open}
			onClose={onClose}
			primaryAction={{
				content: t("common.actions.save", "Spara"),
				onAction: onSave,
			}}
			secondaryActions={[
				{
					content: t("common.actions.close", "Stäng"),
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<Select
					label={t("workspace.terms.choose_folder", "Välj mapp")}
					options={workspaces.map((workspace) => ({
						label: `${"-".repeat(workspace.depth)}${workspace.title}`,
						value: String(workspace.id),
					}))}
					onChange={onChange("parent_id")}
					value={String(form?.parent_id || form?.workspace_id)}
				/>
			</Modal.Section>
		</Modal>
	);
};
export default withTranslation(["workspace", "common"], { withRef: true })(WorkspaceNavigationMoveModal);
