import { Button } from "@shopify/polaris";
import React, { useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { ColumnHeaderWrapper, ColumnHeader, FortnoxRowsWrapper } from "../styles";
import FortnoxOrderRow from "./FortnoxOrderRow";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import getTotalStats from "../Utilities/getTotalStats";
import InfoView from "src/js/components/InfoView";
import { numberFormatter } from "src/js/Utilities";
import Colors from "src/js/Colors";

const FortnoxOrderRows = ({ t, order, onChange, isSaving }) => {
	const handleChangeForm = (index: number, row: FortnoxOrderRowType | null) => {
		const rows = [...(order.OrderRows || [])];
		if (!row) {
			rows.splice(index, 1);
		} else {
			rows[index] = row;
		}

		onChange("OrderRows")(rows);
	};

	const totalStats = useMemo(() => {
		const stats = getTotalStats(order);

		const valueStyle = {
			textAlign: "end",
		};

		const formatter = (value) => {
			return numberFormatter({
				value: value || 0,
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}) as unknown as number;
		};

		return [
			{
				label: t("fortnox.order.fields.total.label", "Totalt att betala"),
				value: formatter(stats.totalToPay),
				valueStyle,
				style: { fontWeight: "bold", color: Colors.green },
			},
			{
				label: t("fortnox.order.fields.total_exl_vat.label", "Totalt exkl. moms"),
				value: formatter(stats.totalExclVat),
				valueStyle,
			},
			{
				label: t("fortnox.order.fields.total.label", "Ordervärde"),
				value: formatter(stats.total),
				valueStyle,
			},
			{
				label: t("fortnox.order.fields.contribution.label", "TB"),
				value: typeof stats.contribution === "string" ? stats.contribution : formatter(stats.contribution),
				valueStyle: {
					...valueStyle,
					...(typeof stats.contribution === "string"
						? {
								color: Colors.red,
						  }
						: {}),
				},
			},
			{
				label: t("fortnox.order.fields.vat.label", "moms"),
				value: formatter(stats.vat),
				valueStyle,
			},
			{
				label: t("fortnox.order.fields.roundoff.label", "Öresavrundning"),
				value: formatter(stats.roundoff),
				valueStyle,
			},
		];
	}, [order, t]);

	const disabled = !!isSaving;

	const onDragEnd = useCallback(
		(result) => {
			const { destination, source, draggableId } = result;
			if (!destination || !draggableId) return;

			const reorderedRows = Array.from(order?.OrderRows);
			const [removed] = reorderedRows.splice(source.index, 1);
			reorderedRows.splice(destination.index, 0, removed);
			onChange("OrderRows")(reorderedRows);
		},
		[onChange, order?.OrderRows]
	);

	const columns = [
		{
			header: t("fortnox.row.fields.ArticleNumber.label", "Artikelnr"),
			handle: "ArticleNumber",
		},
		{
			header: t("fortnox.row.fields.Description.label", "Benämning"),
			handle: "Description",
		},
		{
			header: t("fortnox.row.fields.OrderedQuantity.label", "Antal"),
			handle: "OrderedQuantity",
		},
		{
			header: t("fortnox.row.fields.Unit.label", "Enhet"),
			handle: "Unit",
		},
		{
			header: t("fortnox.row.fields.Price.label", "À-Pris"),
			handle: "Price",
		},
		{
			header: t("fortnox.row.fields.Discount.label", "Rabatt"),
			handle: "Discount",
		},
		{
			header: t("fortnox.row.fields.VAT.label", "Moms"),
			handle: "VAT",
		},
		{
			header: t("fortnox.row.fields.sum.label", "Summa"),
			handle: "sum",
		},
	] as { header: string; handle: string }[];

	const rows = order?.OrderRows || [];

	return (
		<FortnoxRowsWrapper>
			<ColumnHeaderWrapper>
				{columns.map((column) => (
					<ColumnHeader key={column.handle}>{column.header}</ColumnHeader>
				))}
			</ColumnHeaderWrapper>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={`Order_${order.DocumentNumber}_rows`}>
					{(droppableProvided, snapshot) => {
						return (
							<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
								{rows?.map((row: FortnoxOrderRowType, index: number) => {
									// const key = JSON.stringify({ ...row, index });
									const key = index;
									return (
										<Draggable draggableId={String(index)} index={index} key={key}>
											{(provided, snapshot) => {
												return (
													<div key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="row">
														<FortnoxOrderRow
															columns={columns}
															disabled={disabled}
															order={order}
															key={key}
															row={row}
															index={index}
															onChange={handleChangeForm}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{droppableProvided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
			{!disabled && (
				<FortnoxOrderRow
					columns={columns}
					className="row"
					key={rows?.length}
					order={order}
					row={null}
					index={rows?.length}
					onChange={handleChangeForm}
				/>
			)}
			<br />
			<Button
				onClick={() => {
					handleChangeForm(rows.length, {
						ArticleNumber: "",
						Description: "",
						OrderedQuantity: "",
						Unit: "",
						Discount: 0,
						Price: 0,
						sum: 0,
					} as FortnoxOrderRowType);
				}}
				primary
				icon={CirclePlusMajor}
			/>

			<InfoView style={{ display: "flex", justifyContent: "end" }} details={totalStats} />
		</FortnoxRowsWrapper>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOrderRows);
