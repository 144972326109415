import React, { Component } from "react";
import { LegacyStack, Checkbox, Button, Tooltip } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import Toast from "src/js/components/Toast";
import BoardHelper from "../BoardHelper.js";

class RowSnippet extends Component {
	constructor(props) {
		super(props);

		this.state = { saving: false, title: "" };
	}

	createRow() {
		if (BoardHelper.isSupportBoard(this.props.row.board_id) && this.props.openModal) {
			this.props.openModal("ticketmodal", this.state.title);
			return;
		}
		if (this.state.saving) {
			return;
		}
		const data = Object.assign({
			title: this.state.title,
			comment_count: 0,
			group_id: this.props.row.group_id,
			values: [],
			ref: Math.random(),
		});
		this.setState({ title: "", saving: true });
		API.post("/api/boards/" + this.props.row.board_id + "/groups/" + this.props.row.group_id + "/rows.json", data, {
			params: {},
		})
			.then((result) => {
				if (this.props.onCreateRow) this.props.onCreateRow(result.data.row);
			})
			.catch((error) => {
				Toast.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		if (this.props.quickadd) {
			return (
				<LegacyStack wrap={false} spacing="none">
					{/* <div className="selectbox" style={{ backgroundColor: this.props.row.group_color }} /> */}
					<LegacyStack.Item fill>
						<div style={{ position: "relative" }}>
							<input
								value={this.state.title}
								onChange={(event) => {
									this.setState({ title: event.target.value });
								}}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										this.createRow();
									}
								}}
								type="text"
								style={{
									height: 36,
									padding: 5,
									display: "block",
									width: "100%",
								}}
								placeholder="+Ny"
							/>
							<div style={{ position: "absolute", top: 3, right: 5 }}>
								<Button disabled={!this.state.title} loading={this.state.saving} onClick={this.createRow.bind(this)} primary size="slim">
									{this.props.t("common.actions.create", "Skapa")}
								</Button>
							</div>
						</div>
					</LegacyStack.Item>
				</LegacyStack>
			);
		}
		const haveSelectBox = this.props.selected === false || this.props.selected === true;

		return (
			<div
				className="row-item"
				style={Object.assign(
					{
						paddingLeft: 0,
						height: 37,
						borderLeft: 0,
						marginRight: 0,
					},
					this.props.style,
					this.props.borderColor && { borderColor: this.props.borderColor }
				)}
			>
				<div
					className="board-column"
					style={({ paddingLeft: 0, paddingRight: 5 }, this.props.borderColor && { borderColor: this.props.borderColor })}
				>
					<LegacyStack spacing="none" wrap={false}>
						<div
							className="selectbox"
							style={{
								backgroundColor: this.props.group.color,
								width: haveSelectBox ? null : 9,
							}}
						>
							{haveSelectBox ? (
								<div>
									<Checkbox
										checked={this.props.selected}
										onChange={() => {
											this.props.onClick(this.props.row, false);
										}}
									/>
								</div>
							) : null}
						</div>

						<LegacyStack.Item fill>
							{this.props.onClick ? (
								<Tooltip content={this.props.row.title} preferredPosition="below">
									<a
										className="row-snippet-title"
										style={{
											paddingLeft: 10,
											cursor: "pointer",
											display: "block",
											overflow: "hidden",
											whiteSpace: "nowrap",
											paddingTop: 8,
											textOverflow: "ellipsis",
											textDecoration: "none",
											fontSize: 12,
										}}
										onClick={() => {
											this.props.onClick(this.props.row, true);
										}}
									>
										{this.props.row.title}
									</a>
								</Tooltip>
							) : (
								<div
									style={{
										paddingLeft: 10,
										cursor: "pointer",
										display: "block",
										overflow: "hidden",
										whiteSpace: "nowrap",
										paddingTop: 8,
										textOverflow: "ellipsis",
										textDecoration: "none",
										fontSize: 12,
									}}
								>
									{this.props.row.title}
								</div>
							)}
						</LegacyStack.Item>
						{this.props.rowRightButton ? <span onClick={() => this.props.onClick(this.props.row, true)}>{this.props.rowRightButton}</span> : null}

						{this.props.row.badge ? this.props.row.badge : null}
						{this.props.children ? <div className="row-item-hover">{this.props.children}</div> : null}
					</LegacyStack>
				</div>
			</div>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(RowSnippet);
