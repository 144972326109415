import React from "react";
import { DropZone, Labelled, Icon } from "@shopify/polaris";
import { UploadMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import Attachment from "./Attachment.js";

class Upload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openFileDialog: false,
			files: props.files || [],
		};
	}

	addPicture(f, event) {
		const data = event.target.result;
		const file = {
			id: null,
			name: f.name,
			size: f.size,
			type: f.type,
			url: data,
			data,
		};
		this.props.onUpload(file);
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ files: props.files || [], openFileDialog: props.open });
	}

	updateFile(index, file) {
		this.state.files[index] = file;
		this.setState({ files: this.state.files });
		if (this.props.onChange) this.props.onChange({ files: this.state.files });
	}

	render() {
		const { files, openFileDialog } = this.state;

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<div className="upload_cont" style={{ width: this.props.width, height: this.props.height }}>
					<DropZone
						outline={!!(this.state.files.length < 1 && this.props.width)}
						openFileDialog={openFileDialog}
						size={this.props.small ? this.props.small : "small"}
						type={this.props.type ? this.props.type : "spreadsheet"}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							for (let i = 0; i < acceptedFiles.length; i++) {
								const reader = new FileReader();
								reader.onload = this.addPicture.bind(this, acceptedFiles[i]);
								reader.readAsDataURL(acceptedFiles[i]);
							}
							// this.setState({
							// 	rejectedFiles,
							// 	hasError: rejectedFiles.length > 0,
							// });
						}}
						onFileDialogClose={() => {
							this.setState({ openFileDialog: false });
							this.props.onClose();
						}}
					>
						{this.props.width || this.props.showFiles ? (
							files.length ? (
								<div style={{ width: this.props.width || "max-content" }}>
									{files.map((file, index) => (
										<Attachment onRemove={this.props.removeFile} onChange={this.updateFile.bind(this, index)} key={index} file={file} />
									))}
								</div>
							) : this.props.width ? (
								<div>
									<div className="large-upload-icon">
										<Icon source={UploadMajor} />
									</div>
									<DropZone.FileUpload
										actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
										actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
									/>
								</div>
							) : null
						) : null}
						{this.props.children}
					</DropZone>
				</div>
			</Labelled>
		);
	}
}

Upload.defaultProps = { showFiles: true };

export default withTranslation(["common", "upload"])(Upload);
