import React, { Component } from "react";
import { Button, FormLayout, Popover, Tooltip } from "@shopify/polaris";
import { LinkMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";

class AddLink extends Component {
	constructor(props) {
		super(props);

		this.state = { href: props.href || "", text: props.text || "" };
	}

	onToggle() {
		this.setState((c) => ({ open: !c.open, href: this.props.href, text: this.props.text }));
	}

	onClose(force) {
		if (!this.state.focused || force) {
			this.setState({ open: false });
		}
	}

	handleSubmit() {
		try {
			const url = new URL(this.state.href.startsWith("http") ? this.state.href : "http://" + this.state.href);
			this.props.onSubmit(this.state.text, url.href);
			this.onClose(true);
		} catch (error) {
			this.setState({ linkError: this.props.t("pdf.editor.fields.link.error", "Länken är inte giltig.") });
		}
	}

	render() {
		return (
			<Popover
				activator={
					<Tooltip content={this.props.t("pdf.editor.actions.link.add", "Lägg till länk")}>
						<Button onClick={this.onToggle.bind(this)} icon={LinkMinor} />
					</Tooltip>
				}
				active={this.state.open}
				onClose={this.onClose.bind(this)}
				sectioned
			>
				<div style={{ minWidth: 350 }}>
					<FormLayout>
						<TextField
							label={this.props.t("pdf.editor.fields.text.label", "Text")}
							placeholder="Google.."
							value={this.state.text}
							onChange={(text) => {
								this.setState({ text });
							}}
						/>
						<TextField
							label={this.props.t("pdf.editor.fields.link.label", "Länk")}
							placeholder="https://google.com/.."
							value={this.state.href || ""}
							onChange={(href) => {
								this.setState({ href, linkError: null });
							}}
							error={this.state.linkError}
						/>
						<Button primary onClick={this.handleSubmit.bind(this)} disabled={!this.state.href || !this.state.text}>
							{this.props.text ? this.props.t("common.actions.save", "Spara") : this.props.t("common.actions.add", "Lägg till")}
						</Button>
					</FormLayout>
				</div>
			</Popover>
		);
	}
}

export default withTranslation(["pdf", "common"], { withRef: true })(AddLink);
