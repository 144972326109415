import { Icon } from "@shopify/polaris";
import type { IconProps } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Colors, { colorToRgba } from "../../Colors";
import CheckmarkIcon from "../../icons/CheckmarkIcon";
import InfoIcon from "../../icons/Info";
import SadFaceIcon from "../../icons/SadFace";
import WarningIcon from "../../icons/Warning";
import IconWithBackround from "../IconWithBackround";
import BannerAction from "./BannerAction";
import { BannerActionType, BannerStatus } from "src/js/types/BannerType/BannerType";

// type BannerActionType = any;
// type BannerStatus = any;

type BannerProps = {
	/** Title content for the banner. */
	title?: string | React.ReactNode;
	/** Status icon to display in the banner. Use only major icons */
	icon?: IconProps["source"] | React.ReactNode;
	/** Renders the banner without a status icon. */
	hideIcon?: boolean;
	/** Sets the status of the banner. */
	status?: BannerStatus;
	type?: BannerStatus;
	/** The child elements to render in the banner. */
	children?: React.ReactNode;
	/** Action for banner */
	action?: BannerActionType;
	actions?: BannerActionType[];
	/** Callback when banner is dismissed */
	onDismiss?(e?: EventSource): void;
	onClose?(): void;
	transparent?: boolean;
	dismissible?: boolean;
	backgroundColor?: string;
	style?: React.CSSProperties;
	opacity?: number;
	className?: string;
	iconColor?: string;
};

const Banner = ({
	transparent = true,
	type,
	status,
	onClose,
	title,
	dismissible,
	children,
	actions,
	backgroundColor,
	style,
	onDismiss,
	opacity,
	className,
	iconColor,
	action,
	icon,
	hideIcon,
}: BannerProps) => {
	const [isOpen, setIsOpen] = useState(true);
	const ref = useRef<HTMLDivElement | null>(null);
	// const ref: RefObject<HTMLDivElement | null> = useRef(null);

	useEffect(() => {
		const tooltipWrapper = ref.current?.closest(".Polaris-PositionedOverlay");
		if (tooltipWrapper) {
			tooltipWrapper.classList.add("tooltip-transparent");
		}
	}, []);

	const getStyle = () => {
		switch (type || status) {
			case "accent":
				return {
					color: Colors.accent,
					icon: icon || <CheckmarkIcon color={iconColor || Colors.accent} />,
				};
			case "success":
				return {
					color: Colors.green,
					icon: icon || <CheckmarkIcon color={iconColor || Colors.green} />,
				};
			case "info":
				return {
					color: Colors.blue,
					icon: icon || <InfoIcon color={iconColor || Colors.blue} />,
				};
			case "warning":
				return {
					color: Colors.yellow2,
					icon: icon || <WarningIcon color={iconColor || Colors.yellow2} />,
				};
			case "critical":
			case "error":
				return {
					color: Colors.red,
					icon: icon || <SadFaceIcon color={iconColor || Colors.red} />,
				};
			case "grey":
			case "none":
			default:
				return {
					color: "#ffffff",
					icon: icon || <InfoIcon color={iconColor || Colors.grey} />,
				};
		}
	};

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
		if (onDismiss) {
			onDismiss();
		}

		setIsOpen(false);
	};

	const { color, icon: styleIcon } = getStyle();
	if (!isOpen) return null;

	if (!actions && action) actions = [action];

	return (
		<StyledBanner
			ref={ref}
			className={`Banner ${type || status} ${className}`}
			style={style}
			color={color}
			backgroundColor={backgroundColor || colorToRgba(color, opacity || (!transparent ? 1 : 0.15))}
		>
			{!hideIcon && <IconWithBackround backgroundColor={backgroundColor || colorToRgba(color, 0.2)} color={color} icon={styleIcon} />}
			<BannerContent>
				{title && <BannerTitle>{title}</BannerTitle>} <div>{children}</div>
				{actions && !!actions.length && (
					<BannerButtons>
						{actions.map((i: BannerActionType, key) => (
							// <ActionButton item={i} key={key} />
							<BannerAction action={i} key={key} />
						))}
					</BannerButtons>
				)}
				{(dismissible || onClose || onDismiss) && (
					<BannerCloseButtton onClick={handleClose}>
						<Icon source={MobileCancelMajor} />
					</BannerCloseButtton>
				)}
			</BannerContent>
		</StyledBanner>
	);
};
export default Banner;

const StyledBanner = styled.div<{ backgroundColor?: string }>`
	border-width: 1px;
	border-style: solid;
	border-color: ${({ color }) => colorToRgba(color, 0.15)};
	background-color: ${({ backgroundColor }) => backgroundColor};

	border-radius: var(--p-border-radius-base);
	transition: all 250ms;
	padding: 0.7812rem;
	display: flex;
	gap: 1.2500rem;
	align-items: center;
	position: relative;

	.icon-with-background__wrapper svg {
		filter: brightness(1.1);
	}
`;

const BannerButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 1.2500rem;
	margin-top: 1.2500rem;
`;
const BannerContent = styled.div`
	display: flex;
	flex-direction: column;
`;
const BannerTitle = styled.h1`
	font-size: 1.3em;
	font-weight: 600;
	line-height: 1.3em;
`;
const BannerCloseButtton = styled.h1`
	position: absolute;
	padding: 0.6250rem;
	right: 0;
	top: 0;
	cursor: pointer;
	transition: filter 250ms;
	border: none;
	background: transparent;
	outline-color: none;

	svg {
		fill: var(--color-primary);
	}

	&:hover {
		filter: brightness(1.25);
	}
`;
