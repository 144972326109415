import { Button } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import PlusIcon from "../../../../icons/PlusIcon";
import CalendarDayIcon from "../../../../icons/CalendarDayIcon";
import CalendarMonthIcon from "../../../../icons/CalendarMonthIcon";
import CalendarWeekIcon from "../../../../icons/CalendarWeekIcon";
import RowSheet from "../RowSheet";
import BoardHelper from "../../BoardHelper";
import WorkTimeModal from "./WorkTimeModal";
import CalendarHeaderButton from "./CalendarHeaderButton";

class CalendarHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onOpenSheet(options) {
		this.setState({ open: true, options });
	}

	onCloseSheet() {
		this.setState({ open: false, options: null });
	}

	getLeftButtons() {
		return [
			{
				icon: <PlusIcon />,
				title: this.props.t("calendar.actions.create", "Skapa inlägg"),
				onAction: this.onOpenSheet.bind(this, null),
			},
			{
				icon: <img alt="teams" src="/assets/images/teams.png" />,
				title: this.props.t("calendar.actions.teams.new", "Nytt teams-möte"),
				onAction: this.onOpenSheet.bind(this, {
					isOnlineMeeting: true,
					onlineMeetingProvider: "teamsForBusiness",
				}),
				enabled: BoardHelper.getColumnIntegrationType(this.props.board) === "google",
			},
			// {
			// 	icon: <img alt="google_hangout" src="/assets/images/google_hangout.png" />,
			// 	title: this.props.t("calendar.actions.google_hangout.new", "Nytt google hangout möte"),
			// 	// TODO
			// 	onAction: this.onOpenSheet.bind(this, {
			// 		isOnlineMeeting: true,
			// 		onlineMeetingProvider: "teamsForBusiness",
			// 	}),
			// 	enabled: BoardHelper.getColumnIntegrationType(this.props.board) === "google",
			// },
		].filter((i) => !("enabled" in i) || i.enabled);
	}

	getRightButtons() {
		const { type } = this.props.calendar?.getApi().view || {};
		return [
			{
				icon: <CalendarDayIcon />,
				title: this.props.t("calendar.terms.today", "Idag"),
				onAction: (e) => {
					this.props.calendar?.getApi().today();
					this.props.calendar?.getApi().changeView("timeGridDay");
					this.props.updateState({ showWeekends: true }, this.scrollToTime.bind(this));
				},
				active: moment(this.props.calendar?.getApi().getDate()).isSame(moment(), "day"),
			},
			{
				icon: <CalendarDayIcon />,
				title: this.props.t("calendar.terms.day", "Dag"),
				onAction: (e) => {
					this.props.calendar?.getApi().changeView("timeGridDay");
					this.props.updateState({ showWeekends: true }, this.scrollToTime.bind(this));
				},
				active: type === "timeGridDay",
			},
			{
				icon: <CalendarWeekIcon />,
				title: this.props.t("calendar.terms.workweek", "Arbetsvecka"),
				onAction: (e) => {
					this.props.calendar?.getApi().changeView("timeGridWeek");
					this.props.updateState({ showWeekends: false }, this.scrollToTime.bind(this));
				},
				active: type === "timeGridWeek" && !this.props.showWeekends,
			},
			{
				icon: <CalendarWeekIcon />,
				title: this.props.t("calendar.terms.week", "Vecka"),
				onAction: (e) => {
					this.props.calendar?.getApi().changeView("timeGridWeek");
					this.props.updateState({ showWeekends: true }, this.scrollToTime.bind(this));
				},
				active: type === "timeGridWeek" && this.props.showWeekends,
			},
			{
				icon: <CalendarMonthIcon />,
				title: this.props.t("calendar.terms.month", "Månad"),
				onAction: (e) => {
					this.props.calendar?.getApi().changeView("dayGridMonth");
					this.props.updateState({ showWeekends: true });
				},
				active: type === "dayGridMonth",
			},
		];
	}

	onComplete(e) {}

	scrollToTime() {
		// const slotDuration = this.props.calendar?.getApi().getOption("slotDuration");
		setTimeout(() => {
			this.props.calendar?.getApi().scrollToTime("08:00");
		}, 0);
	}

	render() {
		const leftButtons = this.getLeftButtons();
		const rightButtons = this.getRightButtons();
		const { viewTitle } = this.props.calendar?.getApi().getCurrentData() || {};

		return (
			<div className="calendar_header__wrapper">
				<div className="calendar_header__actions">
					<Button plain onClick={() => this.setState({ openWorkTimeModal: true })}>
						{this.props.t("calendar.actions.work_time.change", "Ändra arbetstid")}
					</Button>
				</div>
				<div className="calendar_header">
					<div className="calendar_header__buttogroup">
						{leftButtons.map(({ icon, title, onAction, active }, key, arr) => (
							<CalendarHeaderButton
								style={{ borderRight: key !== arr.length - 1 ? "0.5px solid #6F6F6F" : "", borderRadius: 0 }}
								onClick={onAction}
								icon={icon}
								active={active}
								key={key}
							>
								{title}
							</CalendarHeaderButton>
						))}
					</div>
					<div className="calendar_view_header">
						<Button
							icon={ChevronLeftMinor}
							onClick={() => {
								this.props.calendar?.getApi().prev();
							}}
						/>
						<h2>{viewTitle}</h2>
						<Button
							icon={ChevronRightMinor}
							onClick={() => {
								this.props.calendar?.getApi().next();
							}}
						/>
					</div>
					<div className="calendar_header__buttogroup">
						{rightButtons.map(({ icon, title, onAction, active }, key) => (
							<CalendarHeaderButton onClick={onAction} icon={icon} active={active} key={key}>
								{title}
							</CalendarHeaderButton>
						))}
					</div>

					<RowSheet
						open={this.state.open}
						row={this.props.row}
						board={this.props.board}
						initialTabIndex={0}
						onClose={this.onCloseSheet.bind(this)}
						onComplete={this.onComplete.bind(this)}
						options={this.state.options}
					/>

					<WorkTimeModal
						board={this.props.board}
						open={this.state.openWorkTimeModal}
						onClose={() => {
							this.setState({ openWorkTimeModal: false });
						}}
					/>
				</div>
			</div>
		);
	}
}
export default withTranslation(["calendar", "common"], { withRef: true })(CalendarHeader);
