import React, { useMemo } from "react";
import { LegacyCard, Spinner } from "@shopify/polaris";
import { useSelector } from "react-redux";
import BoardExpandableSection from "src/js/components/BoardExpandableSection.js";
import useContact from "src/js/hooks/useContact";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

type RelatedResourcesProps = {
	children?: React.ReactNode;
};

const RelatedResources = ({ children }: RelatedResourcesProps) => {
	const { id }: any = useParams();
	const { resources, refetchResources, isLoadingResources } = useContact(id);
	const boards = useMemo(() => resources?.boards || [], [resources?.boards]);
	const history = useHistory();
	const account = useSelector((state: any) => state.account);

	// const { data: uploads, refetch: refetchUploads } = useQuery({
	// 	queryKey: ["uploads", id],
	// 	queryFn: async () => {
	// 		try {
	// 			const response = await API.get("/api/contacts/" + id + "/uploads.json");
	// 			return response.data.uploads;
	// 		} catch (error) {
	// 			Toast.error(error);
	// 		}
	// 	},
	// 	enabled: !!id,
	// });

	if (isLoadingResources) {
		return (
			<LegacyCard sectioned>
				<Spinner />
			</LegacyCard>
		);
	}

	return (
		<TabInRestWrapper>
			<LegacyCard>
				{boards
					.filter((board) => {
						if ([account.support_board_id, account.sales_board_id, account.upload_board_id].some((id) => board.id == id)) return false;

						return true;
					})
					.map((board, key) => (
						<LegacyCard.Section key={key}>
							<BoardExpandableSection
								className="board-container-small"
								key={key}
								board={board}
								onlyRowsWithContactId={board.type == "single" ? id : null}
								history={history}
								fetchResources={refetchResources}
							/>
						</LegacyCard.Section>
					))}
				{children}
			</LegacyCard>
		</TabInRestWrapper>
	);
};

export default RelatedResources;

const TabInRestWrapper = styled.div`
	/* display: flex;
	flex-direction: column; */
	/* gap: 1rem; */

	.icon svg {
		width: 19px;
	}

	.Polaris-Box {
		padding-inline-end: var(
			--pc-box-padding-inline-end-xl,
			var(
				--pc-box-padding-inline-end-lg,
				var(--pc-box-padding-inline-end-md, var(--pc-box-padding-inline-end-sm, var(--pc-box-padding-inline-end-xs)))
			)
		);
	}

	.Polaris-LegacyCard__Section {
		padding: var(--p-space-2);
	}
`;
export const ItemWrapper = styled.div``;
