import React, { Component } from "react";

class CellBoardNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div
				className="overflow-ellipsis"
				style={{
					width: "100%",
					cursor: "text",
					textAlign: "center",
					height: 29,
				}}
			>
				{this.props.row && "#" + this.props.row.board_number}
			</div>
		);
	}
}
export default CellBoardNumber;
