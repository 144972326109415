import { LegacyCard, Layout, Spinner, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import ResourcePicker from "../../components/resource_picker";
import Toast from "src/js/components/Toast";

class AdCompleteFacebook extends Component {
	constructor(props) {
		super(props);
		this.state = { form: {} };
	}

	componentDidMount() {
		this.getAdAccount();
	}

	getFacebookPage(id) {
		API.get(`/api/facebook/${this.props.match.params.id}/page/${id}.json`)
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.state.form.facebook_page = result.data.page;
				this.setState({ form: this.state.form });
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	getAdAccount() {
		this.setState({ loading: true });
		API.get(`/api/accounts_ad/${this.props.match.params.id}.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					Toast.error(result.data.error);
					return;
				}

				this.setState({
					// ad_account: result.data.ad_account,
					form: Object.assign((result.data.ad_account && result.data.ad_account.options) || {}, {
						facebook_page: {
							id: result.data.ad_account && result.data.ad_account.options && result.data.ad_account.options.page_id,
							name: result.data.ad_account && result.data.ad_account.options && result.data.ad_account.options.page_id,
						},
					}),
					loading: false,
				});
				if (result.data.ad_account && result.data.ad_account.options && result.data.ad_account.options.page_id) {
					this.getFacebookPage(result.data.ad_account.options.page_id);
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
				Toast.error(error);
			});
	}

	saveForm() {
		const options = {};

		if (this.state.form.facebook_page) {
			options.page_id = this.state.form.facebook_page.id;
		}
		if (this.state.form.instagram_handle) {
			options.instagram_handle = this.state.form.instagram_handle;
		}

		API.put(`/api/accounts_ad/${this.props.match.params.id}.json`, { options })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
					return;
				}
				this.props.history.push("/admin/ads/new");
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	render() {
		return (
			<Page
				title={this.props.t("ads.facebook.complete.title", "Slutför ditt Facebook-annonskonto")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					// disabled: !this.state.form.title,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				<Layout>
					<Layout.Section>
						{this.state.loading && <Spinner />}
						{!this.state.loading && (
							<React.Fragment>
								<LegacyCard title="Facebook">
									<LegacyCard.Section>
										<ResourcePicker
											label={this.props.t("ads.facebook.resource_picker.label", "Facebook sida")}
											caption={this.props.t("ads.facebook.resource_picker.caption", "Ingen facebook sida")}
											resource={"facebook/" + this.props.match.params.id + "/pages.json"}
											fullWidth={false}
											item={this.state.form.facebook_page}
											onChange={this.updateForm.bind(this, "facebook_page")}
											plain
											clearOption={this.props.t("ads.facebook.resource_picker.clear_option", "Ingen facebook sida")}
											id_handle="id"
											resource_handle="pages"
											label_handle="name"
											resourceName={{
												singular: this.props.t("ads.facebook.resource_picker.singular", "Facebook sida"),
												plural: this.props.t("ads.facebook.resource_picker.plural", "Facebook sidor"),
											}}
										/>
									</LegacyCard.Section>
								</LegacyCard>
								<LegacyCard title="Instagram">
									<LegacyCard.Section>
										<TextField
											label={this.props.t("ads.instagram.handle", "Instagram handle")}
											prefix="@"
											value={this.state.form.instagram_handle || ""}
											onChange={this.updateForm.bind(this, "instagram_handle")}
										/>
									</LegacyCard.Section>
								</LegacyCard>
							</React.Fragment>
						)}
					</Layout.Section>
				</Layout>
			</Page>
		);
	}
}
export default withTranslation(["ads", "common"], { withRef: true })(AdCompleteFacebook);
