import React, { Component } from "react";
import { Spinner } from "@shopify/polaris";

import { withTranslation } from "react-i18next";
import API from "../../API";
import StatsBoxWrapper from "../../components/statsBoxes";
import IconWithBackround from "../../components/IconWithBackround.js";
import Colors, { colorToRgba } from "../../Colors.js";
import { CheckmarkWithBottomIcon, SadFaceIcon, ListIcon, CheckmarkIcon } from "../../icons";
import Toast from "src/js/components/Toast";
import IndexTable from "src/js/components/IndexTable";

class Audiences extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visibleItems: [],
		};
	}

	componentDidMount() {
		this.getStats();
	}

	gotoCampaign(item) {
		item.path = "/admin/audiences/" + item.id;
		this.props.history.push("/admin/audiences/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("audience.plural", "Målgrupper"),
					item: { id: item.id, item, path: "/admin/audiences/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	onItemsFetched(items) {
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/email_campaigns/" + items[i].id;
		}
		this.setState({ visibleItems: items });
	}

	getStats() {
		this.setState({ loadingStats: true });
		API.get("/api/audiences_stats.json")
			.then((result) => {
				if (result.data.error) {
					this.setState({ loadingStats: false });
					Toast.error(result.data.error);
					return;
				}

				this.setState({ stats: result.data.stats, loadingStats: false });
			})
			.catch((error) => {
				this.setState({ loadingStats: false });
				Toast.error(error);
			});
	}

	render() {
		const filters = [
			// {
			// 	key: "due",
			// 	label: "Aktiv",
			// 	type: FilterType.TextField,
			// },
		];

		const columns = [
			{
				handle: "TITLE",
				label: this.props.t("audience.column.name.label", "Namn"),
				sortable: true,
				render: (item) => item.title,
			},
			{
				handle: "TOTAL_COUNT",
				label: this.props.t("audience.column.total_count.label", "Total"),
				sortable: true,
				render: (item) => item.count,
			},
		];

		const getBoxTitle = (key) => {
			if (this.state.loadingStats) return <Spinner size="small" />;

			if (this.state.stats && key in this.state.stats) {
				return `${this.state.stats[key]} st`;
			}
			return "-";
		};

		return (
			<div>
				<IndexTable
					history={this.props.history}
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					extraHeader={
						<StatsBoxWrapper
							perRow={4}
							items={[
								{
									title: getBoxTitle("count"),
									text: this.props.t("audience.box.total.text", "Total"),
									icon: <IconWithBackround icon={<ListIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
								},
								{
									title: getBoxTitle("imported_count"),
									text: this.props.t("audience.box.imported_count.text", "epostkontakter"),
									icon: <IconWithBackround icon={<CheckmarkIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
								},
								{
									title: getBoxTitle("approved_count"),
									text: this.props.t("audience.box.approved_count.text", "Godkända"),
									icon: (
										<IconWithBackround icon={<CheckmarkWithBottomIcon color={Colors.purple} />} backgroundColor={colorToRgba(Colors.purple, 0.2)} />
									),
								},
								{
									title: getBoxTitle("verified_count"),
									text: this.props.t("audience.box.verified_count.text", "Verifierade"),
									icon: <IconWithBackround icon={<SadFaceIcon color={Colors.yellow2} />} backgroundColor={colorToRgba(Colors.yellow2, 0.2)} />,
								},
							]}
						/>
					}
					title={this.props.t("audience.plural", "Målgrupper")}
					primaryAction={{ content: this.props.t("audience.actions.new", "Ny målgrupp"), url: "/admin/audiences/new" }}
					savedSearchHandle="email_campaigns"
					resourceUrl="/api/audiences.json?count=true"
					resourceHandle="audiences"
					onItemsFetched={this.onItemsFetched.bind(this)}
					resourceName={{
						singular: this.props.t("audience.singular", "målgrupp"),
						plural: this.props.t("audience.plural", "målgrupper"),
					}}
					defaultSort="SELLER_DESC"
					onClickRow={(item) => {
						this.gotoCampaign(item);
					}}
					columns={columns}
					filters={filters}
				/>
			</div>
		);
	}
}

export default withTranslation(["audience", "common"], { withRef: true })(Audiences);
