import React from "react";

const LightBulb = ({ color = "white" }) => (
	<svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.71061 22.1979C4.71061 22.8396 5.23561 23.3646 5.87728 23.3646H10.5439C11.1856 23.3646 11.7106 22.8396 11.7106 22.1979V21.0312H4.71061V22.1979ZM8.21061 0.03125C3.70728 0.03125 0.0439453 3.69458 0.0439453 8.19792C0.0439453 10.9746 1.43228 13.4129 3.54395 14.8946V17.5312C3.54395 18.1729 4.06895 18.6979 4.71061 18.6979H11.7106C12.3523 18.6979 12.8773 18.1729 12.8773 17.5312V14.8946C14.9889 13.4129 16.3773 10.9746 16.3773 8.19792C16.3773 3.69458 12.7139 0.03125 8.21061 0.03125ZM11.5356 12.9813L10.5439 13.6812V16.3646H5.87728V13.6812L4.88561 12.9813C3.31061 11.8846 2.37728 10.0996 2.37728 8.19792C2.37728 4.97792 4.99061 2.36458 8.21061 2.36458C11.4306 2.36458 14.0439 4.97792 14.0439 8.19792C14.0439 10.0996 13.1106 11.8846 11.5356 12.9813Z"
			fill={color}
		/>
	</svg>
);

export default LightBulb;
