import { useState } from "react";
import Button from "src/js/components/Button";
import TextField from "src/js/components/TextField";
import { TextFieldProps } from "src/js/types/TextFieldPropsType";

type ColumnValuesToCheckForReturnType = {
	valuesToCheckFor: { label: string; value: any; operator?: string }[];
	custom?: null | JSX.Element;
};

type SelfContainedTextFieldProps = Omit<TextFieldProps, "onChange"> & {
	onChange?: (value: string) => void;
	value: string;
	onSubmit?: (obj: any) => void;
};

const SelfContainedTextField = ({ onChange, value, onSubmit, ...props }: SelfContainedTextFieldProps) => {
	const [input, setInput] = useState(value);

	const handleChange = (value) => {
		setInput(value);
		onChange?.(value);
	};

	return (
		<TextField
			{...props}
			value={input}
			onChange={handleChange}
			connectedRight={
				onSubmit && (
					<Button
						primary
						disabled={!input}
						onClick={() => {
							onSubmit({ value: input });
						}}
					>
						Klar
					</Button>
				)
			}
		/>
	);
};

const getColumnValuesToCheckFor = (
	condition: any,
	column: BoardColumnType,
	board: BoardType,
	changeValueToCheckFor?: (value: { label?: string; value: any }) => void
): ColumnValuesToCheckForReturnType => {
	switch (column.type) {
		case "status":
			return { valuesToCheckFor: column.options.statuses?.filter((s) => !s.removed)?.map((status) => ({ label: status.label, value: status.id })) };
		case "datetime":
			return {
				valuesToCheckFor: [
					{
						label: "Inget datum",
						value: null,
					},
					{
						label: "Ett datum",
						value: 1,
						operator: "not_empty",
					},
				],
				custom: (
					<SelfContainedTextField
						type="date"
						value={condition.value_to_check_for?.value}
						onSubmit={(obj) => {
							changeValueToCheckFor?.(obj);
						}}
					/>
				),

				// <NewDatePicker value={condition.value_to_check_for?.value} onChange={(value) => null} />,
			};
		case "checkbox":
			return {
				valuesToCheckFor: [
					{
						label: "markerad",
						value: 1,
					},
					{
						label: "ej markerad",
						value: 0,
					},
				],
			};
		case "dropdown":
			return {
				valuesToCheckFor: column.options.choices
					?.filter((choice, index) => !column.options?.removed_choices?.includes(index))
					?.map((value) => ({ label: value, value })),
			};
		case "company_number":
		case "phone":
		case "text":
		case "number":
			return {
				valuesToCheckFor: [],
				custom: (
					<SelfContainedTextField
						value={condition.value_to_check_for?.value}
						onSubmit={(obj) => {
							changeValueToCheckFor?.(obj);
						}}
						style={{ marginBottom: "1rem" }}
					/>
				),
			};
		//get options from board?
		case "tags":
			return { valuesToCheckFor: board.tags?.map((tag) => ({ label: tag.title, value: [tag.id] })) || [] };
		case "category":
			return { valuesToCheckFor: board.categories?.map((category) => ({ label: category.title, value: [category.id] })) || [] };
		default:
			return { valuesToCheckFor: [] };
	}
};

export default getColumnValuesToCheckFor;
