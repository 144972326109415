import React from "react";

const CheckmarkWithBottomIcon = ({ color = "#5AC57D" }) => (
	<svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.289062 14.1016H14.2891V16.1016H0.289062V14.1016ZM4.88906 11.4016L0.289062 6.80156L2.28906 4.90156L4.88906 7.50156L12.2891 0.101562L14.2891 2.10156L4.88906 11.4016Z"
			fill={color}
		/>
	</svg>
);

export default CheckmarkWithBottomIcon;
