import styled from "styled-components";

const LoadingBox = styled.div.attrs(({ loading = true }: any) => ({ className: loading ? "isLoading" : "" }))<{
	width?: number | string;
	height?: number | string;
	margin?: number | string;
	padding?: number | string;
	loading?: boolean;
}>`
	position: relative;
	${(props) => (props.width ? (typeof props.width === "number" ? `width: ${props.width}px;` : `width: ${props.width};`) : "")}
	${(props) => (props.height ? (typeof props.height === "number" ? `height: ${props.height}px;` : `height: ${props.height};`) : "")}
	${(props) => (props.margin ? (typeof props.margin === "number" ? `margin: ${props.margin}px;` : `margin: ${props.margin};`) : "")}
	${(props) => (props.padding ? (typeof props.padding === "number" ? `padding: ${props.padding}px;` : `padding: ${props.padding};`) : "")}

	${(props) => (props.loading ? `background: var(--tertiary);` : `background: var(--secondary);`)}

	border-radius: var(--p-border-radius-1);
`;

const FlexDiv = styled.div<{
	gap?: number | string;
	width?: number | string;
	height?: number | string;
	margin?: number | string;
	padding?: number | string;
	loading?: boolean;
}>`
	display: flex;
	position: relative;

	${(props) => (props.width ? (typeof props.width === "number" ? `width: ${props.width}px;` : `width: ${props.width};`) : "")}
	${(props) => (props.height ? (typeof props.height === "number" ? `height: ${props.height}px;` : `height: ${props.height};`) : "")}
	${(props) => (props.margin ? (typeof props.margin === "number" ? `margin: ${props.margin}px;` : `margin: ${props.margin};`) : "")}
	${(props) => (props.padding ? (typeof props.padding === "number" ? `padding: ${props.padding}px;` : `padding: ${props.padding};`) : "")}
	${(props) => (props.gap ? (typeof props.gap === "number" ? `gap: ${props.gap}px;` : `gap: ${props.gap};`) : "")}
`;

const Wrapper = styled.div`
	padding: 0rem 2.08rem 0;

	.isLoading {
		animation: var(--pulseAnimation);
	}
`;

export { LoadingBox, FlexDiv, Wrapper };
