/* eslint-disable react/jsx-props-no-spreading */
import { Spinner as PolarisSpinner, SpinnerProps as PolarisSpinnerProps } from "@shopify/polaris";
import React from "react";
import styled, { css } from "styled-components";

type SpinnerProps = Omit<PolarisSpinnerProps, "size"> & {
	style?: React.CSSProperties;
	wrap?: boolean;
	size?: number | PolarisSpinnerProps["size"];
};

const Spinner = ({ style, wrap = false, size, ...props }: SpinnerProps) => {
	if (wrap || typeof size === "number" || style) {
		return (
			// <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
			<Wrapper size={size} style={style}>
				<PolarisSpinner {...props} />
			</Wrapper>
		);
	}

	return <PolarisSpinner {...props} size={size} />;
};
export default Spinner;

const Wrapper = styled.div<{ size?: any }>`
	${({ size }) => {
		if (typeof size === "number") {
			return css`
				.Polaris-Spinner svg {
					height: ${size}px;
					width: ${size}px;
				}
			`;
		}
	}}
`;
