import React from "react";

const ListIcon = ({ color = "#FFD66B" }) => (
	<svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.847656 0.101562H12.8477V2.10156H0.847656V0.101562ZM0.847656 4.10156H12.8477V6.10156H0.847656V4.10156ZM0.847656 8.10156H8.84766V10.1016H0.847656V8.10156ZM14.8577 11.1016L11.8477 8.10156L10.3477 9.60156L14.8577 14.1016L21.8477 7.10156L20.3477 5.60156L14.8577 11.1016Z"
			fill={color}
		/>
	</svg>
);

export default ListIcon;
