/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonGroup, Spinner } from "@shopify/polaris";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Skeleton1Col from "src/js/components/skeleton_1col";
import Badge from "src/js/components/Badge";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import {
	CancelMajor,
	PagePlusMajor,
	DeleteMajor,
	ImportMinor,
	NotificationMajor,
	AnalyticsBarStackedMinor,
	OrdersMajor,
	ReceiptMajor,
	BlogMajor,
} from "@shopify/polaris-icons";
import { UseQueryResult } from "@tanstack/react-query";
import { store } from "src/js/store";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { TabsContentView, Wrapper } from "../styles";
import Header from "./Header";
import DocumentView from "./DocumentView";
import Timeline from "../Timeline/Timeline";
import { downloadPDF, getStatus, getDataFromCallbackUrl } from "../Utilities";
import ScrivePartyView from "../Party/ScrivePartyList";
import ScriveDocumentContext, { ScriveDocumentContextProvider } from "../ScriveDocumentContext";
import CancelModal from "./CancelModal";
import RowSheet from "../../Workspaces/components/RowSheet";
import BoardHelper from "../../Workspaces/BoardHelper";
import ScriveSendForSigningModal from "./ScriveSendForSigningModal";
import DeleteModal from "./DeleteModal";

const ScriveDocumentView = ({ id: propsId, match, history, t }) => {
	const { form, isFetching, handleDeleteDocument, handleCancelDocument, handleRemindDocument, handleOpenAppendPdfModal } =
		useContext(ScriveDocumentContext);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
	const [boardRowIsOpen, setBoardRowIsOpen] = useState(false);
	const [sendForSigningModalIsOpen, setSendForSigningModalIsOpen] = useState(false);
	const [isReminding, setIsReminding] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { boardID, rowID, valueID }: any = (form?.api_callback_url && getDataFromCallbackUrl(form.api_callback_url)) || {};

	const fetchBoard = async () => {
		try {
			const storeBoard = store.getState().boards[boardID];
			if (storeBoard) return storeBoard;

			const res = await API.get(`/api/boards/${boardID}.json`);
			const board = res?.data?.board;

			if (board) store.dispatch({ type: "SET_BOARD", board });

			return board;
		} catch (error) {
			console.error("error:", error);
			throw error;
		}
	};
	const { data: board, isFetching: loadingBoard }: UseQueryResult<any> = useQuery({
		queryKey: [boardID && `board-${boardID}`],
		queryFn: fetchBoard,
		refetchOnWindowFocus: false,
		enabled: !!boardID,
	});

	const fetchRow = async () => {
		try {
			const res = await API.get(`/api/boards/${boardID}/rows/${rowID}.json`);
			const { linked_rows, contacts, tickets, row } = res?.data || {};

			// eslint-disable-next-line camelcase
			if (linked_rows) store.dispatch({ type: "SET_BOARD_LINKED_ROWS", linked_rows });
			if (contacts) store.dispatch({ type: "SET_BOARD_CONTACTS", contacts });
			if (tickets) store.dispatch({ type: "SET_BOARD_TICKETS", tickets });
			if (row) store.dispatch({ type: "SET_BOARD_ROW", row });

			return row;
		} catch (error) {
			console.error("error:", error);
			throw error;
		}
	};
	const { data: row, isFetching: loadingBoardRow }: UseQueryResult<any> = useQuery({
		queryKey: [boardID && `boardRow-${boardID}`].filter(Boolean),
		queryFn: fetchRow,
		refetchOnWindowFocus: false,
		enabled: !!boardID && !!rowID,
		initialData: store.getState().board_rows?.[rowID],
	});

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() => [
			{
				id: "document",
				content: "Dokument",
				render: <DocumentView />,
			},
			{
				id: "parties",
				content: (<>Deltagare {form?.parties?.length && <Badge style={{ marginLeft: 5 }}>{form?.parties?.length}</Badge>}</>) as unknown as string,
				render: <ScrivePartyView document={form as ScriveDocumentType} />,
			},
			{
				id: "history",
				content: "Historik",
				render: <Timeline document={form} />,
			},
		],
		[form]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "document";
	}, [tabs]);

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const tab = tabs.find((tab) => tab.id === tabID);
	const { color, label } = getStatus(form);

	useEffect(() => {
		const hash = getTabIdFromHash();
		if (hash) setTabID(hash);
	}, [getTabIdFromHash]);

	const handleOpenDeleteModal = () => setDeleteModalIsOpen(true);
	const handleCloseDeleteModal = () => setDeleteModalIsOpen(false);
	const handleOpenCancelModal = () => setCancelModalIsOpen(true);
	const handleCloseCancelModal = () => setCancelModalIsOpen(false);

	const handleOpenBoardRow = () => setBoardRowIsOpen(true);
	const handleCloseBoardRow = () => setBoardRowIsOpen(false);

	const handleOpenSendForSigningModal = () => setSendForSigningModalIsOpen(true);
	const handleCloseSendForSigningModal = () => setSendForSigningModalIsOpen(false);

	const remindDocument = async () => {
		setIsReminding(true);
		try {
			await handleRemindDocument();
		} catch (error) {
			console.error("error:", error);
		}
		setIsReminding(false);
	};

	const boardRow = row || store.getState().board_rows[rowID];

	const uploadValue = useMemo(() => {
		if (!boardRow) return null;

		const file = boardRow.values?.find((v) => v.id == valueID)?.value?.find((f) => f?.scrive_id == form?.id);
		const data = (() => {
			if (file?.fortnox_order_id)
				return { type: "order", id: file?.fortnox_order_id, url: `/admin/fortnox/orders/${file?.fortnox_order_id}`, icon: OrdersMajor };
			if (file?.fortnox_offer_id)
				return { type: "offer", id: file?.fortnox_offer_id, url: `/admin/fortnox/offers/${file?.fortnox_offer_id}`, icon: BlogMajor };
			if (file?.fortnox_invoice_id)
				return { type: "invoice", id: file?.fortnox_invoice_id, url: `/admin/fortnox/invoices/${file?.fortnox_invoice_id}`, icon: ReceiptMajor };
			return null;
		})();

		if (!data) return null;

		const { type, id, icon, url } = data;
		return { type, id, file, icon, url };
	}, [boardRow, form?.id, valueID]);

	return (
		<Page
			primaryAction={
				<ButtonGroup>
					{form?.status === "preparation" && (
						<Button primary onClick={handleOpenSendForSigningModal}>
							{t("scrive.actions.send", "Skicka för signering")}
						</Button>
					)}
				</ButtonGroup>
			}
			backAction={{ content: t("common.actions.back", "Tillbaka"), onAction: () => history.goBack() }}
			title={
				(
					<>
						{form?.title || ((<Spinner size="small" />) as any)}
						{label && (
							<Badge style={{ marginLeft: 10 }} color={color}>
								{label}
							</Badge>
						)}
					</>
				) as any
			}
			secondaryActions={[
				{
					content: t("scrive.actions.download", "Ladda ner"),
					onAction: () => downloadPDF(form as ScriveDocumentType),
					disabled: !form?.blob,
					icon: ImportMinor,
					enabled: !!form,
				},
				{
					content: t("scrive.actions.remind", "Skicka påminnelse"),
					onAction: remindDocument,
					enabled: form?.status === "pending",
					icon: NotificationMajor,
					loading: isReminding,
				},
				// {
				// 	content: t("scrive.actions.restart", "Start om dokument"),
				// 	onAction: handleRestartDocument,
				// 	enabled:
				// 		!!form && ["canceled", "timedout", "rejected"].includes(form.status) && !["preparation", "pending", "closed"].includes(form.status),
				// 	icon: RefreshMajor,
				// },
				{
					content: t("scrive.actions.append_pdf.title", "Bifoga PDF"),
					tooltip: t("scrive.actions.append_pdf.help", "Bifoga PDF till dokumentet"),
					onAction: handleOpenAppendPdfModal,
					enabled: form?.status === "preparation",
					icon: PagePlusMajor,
				},
				{
					content: t("scrive.actions.cancel", "Avbryt dokument"),
					onAction: handleOpenCancelModal,
					enabled: form?.status === "pending",
					destructive: true,
					icon: CancelMajor,
				},
				{
					content: t("scrive.actions.remove", "Ta bort dokument"),
					onAction: handleOpenDeleteModal,
					enabled: !!form && !["pending", "awaiting_start"].includes(form.status) && !form?.is_deleted,
					destructive: true,
					icon: DeleteMajor,
				},
				{
					content: t("scrive.actions.open_board_row", "Öppna {{singular}}", { singular: board?.singular || "rad" }),
					onAction: handleOpenBoardRow,
					enabled: boardRow && board,
					icon: AnalyticsBarStackedMinor,
					loading: loadingBoard || loadingBoardRow,
				},
				{
					content: t("scrive.actions.open_fortnox", "Öppna Fortnox {{type}}", { type: uploadValue?.type }),
					onAction: () => history.push(uploadValue?.url),
					enabled: !!uploadValue?.id,
					icon: uploadValue?.icon,
				},
				{
					content: t("scrive.actions.open_order", "Öppna Order #{{id}}", { id: form?.order?.order_number }),
					onAction: () => history.push(`/admin/orders/${form?.order?.id}`, { data: form?.order }),
					enabled: !!form?.order?.id,
					icon: OrdersMajor,
				},
			].filter((a) => !("enabled" in a) || a.enabled)}
			// additionalMetadata={<Badge status="success">asd</Badge>}
		>
			<Wrapper>
				<Header document={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
				{isFetching && !form && (
					<TabsContentView>
						<Skeleton1Col />
					</TabsContentView>
				)}
				{form && <TabsContentView>{typeof tab?.render === "function" ? tab?.render() : tab?.render}</TabsContentView>}
			</Wrapper>

			<DeleteModal open={deleteModalIsOpen} onClose={handleCloseDeleteModal} document={form} onDelete={handleDeleteDocument}>
				{t("scrive.document.delete.confirm", "Är du säker på att du vill ta bort dokumentet? Detta går inte att ångra.")}
			</DeleteModal>

			<CancelModal open={cancelModalIsOpen} onClose={handleCloseCancelModal} document={form} onCancel={handleCancelDocument}>
				{t("scrive.document.cancel.confirm", "Är du säker på att du vill avbryta dokumentet?")}
			</CancelModal>

			<ScriveSendForSigningModal open={sendForSigningModalIsOpen} onClose={handleCloseSendForSigningModal} document={form} />

			{rowID && (
				<RowSheet
					open={boardRowIsOpen}
					row={boardRow}
					board={board}
					initialTabIndex={0}
					onUpdateRow={(row) => {
						BoardHelper.updateRows([row], "update");
					}}
					onClose={handleCloseBoardRow}
				/>
			)}
		</Page>
	);
};

export default withTranslation(["scrive", "common"])((props) => {
	return (
		<ScriveDocumentContextProvider {...props}>
			<ScriveDocumentView {...props} />
		</ScriveDocumentContextProvider>
	);
});
