import React from "react";

const QuestionMark = ({ color = "var(--textColor)", width, height }) => (
	<svg width={width || "13"} height={height || "14"} viewBox="0 0 13 14" fill={color} xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.08203 9.69739H7.33203V10.9474H6.08203V9.69739ZM6.70703 0.947388C3.25703 0.947388 0.457031 3.74739 0.457031 7.19739C0.457031 10.6474 3.25703 13.4474 6.70703 13.4474C10.157 13.4474 12.957 10.6474 12.957 7.19739C12.957 3.74739 10.157 0.947388 6.70703 0.947388ZM6.70703 12.1974C3.95078 12.1974 1.70703 9.95364 1.70703 7.19739C1.70703 4.44114 3.95078 2.19739 6.70703 2.19739C9.46328 2.19739 11.707 4.44114 11.707 7.19739C11.707 9.95364 9.46328 12.1974 6.70703 12.1974ZM6.70703 3.44739C5.32578 3.44739 4.20703 4.56614 4.20703 5.94739H5.45703C5.45703 5.25989 6.01953 4.69739 6.70703 4.69739C7.39453 4.69739 7.95703 5.25989 7.95703 5.94739C7.95703 7.19739 6.08203 7.04114 6.08203 9.07239H7.33203C7.33203 7.66614 9.20703 7.50989 9.20703 5.94739C9.20703 4.56614 8.08828 3.44739 6.70703 3.44739Z"
			fill={color}
		/>
	</svg>
);

export default QuestionMark;
