import React from "react";

const DashboardIcon = ({ color = "black" }) => (
	<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0 0.946167V13.9462H17V0.946167H0ZM5 11.9462H2V2.94617H5V11.9462ZM10 11.9462H7V8.44617H10V11.9462ZM15 11.9462H12V8.44617H15V11.9462ZM15 6.44617H7V2.94617H15V6.44617Z"
			fill={color}
		/>
	</svg>
);

export default DashboardIcon;
