import React from "react";
import Colors from "../Colors";

const LogoIcon = () =>
	window.location.href.includes("acordo.se") || window.location.href.includes("nollettnollnoll.se") ? (
		<svg width="30" height="28" viewBox="0 0 22 20" fill="none">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M9 3L17.6931 20H22L13.3075 3H9Z" fill="##fff" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5892 0L0 20H4.40488L15 0H10.5892Z" fill="##fff" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M0.605469 11L2.67891 15H15.2894L13.2153 11H0.605469Z" fill="##fff" />
		</svg>
	) : (
		<svg width="618" height="635" viewBox="0 0 618 635" fill={Colors.accent} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M250.076 257.452C183.726 245.651 132.78 232.887 132.78 183.757C132.78 162.242 144.253 144.18 163.508 132.7C179.955 124.511 200.815 119.454 225.285 119.454C282.489 119.454 327.738 152.127 327.738 189.296C327.738 195.557 326.455 201.659 324.048 207.519C338.81 210.971 355.979 216.59 373.79 224.217C398.822 234.894 420.083 247.337 433.882 258.415C442.547 237.463 447.201 214.262 447.201 189.216C447.12 74.8192 350.283 0 225.205 0C224.723 0 224.242 0 223.68 0C223.36 0 223.039 0 222.718 0C211.967 0 201.537 0.642225 191.268 1.76612C190.465 1.8464 189.583 1.92667 188.781 2.00695C187.898 2.08723 187.096 2.24779 186.213 2.32807C117.777 10.7573 63.3815 41.6643 32.2523 88.7876C11.7135 117.607 0 151.886 0 189.216C0 327.535 124.517 360.288 216.46 375.702C250.959 381.642 280.804 386.78 301.022 398.34C317.549 411.024 326.695 427.802 326.695 445.704C326.695 482.793 287.062 515.546 224.242 515.546C167.038 515.546 121.789 482.873 121.789 445.704C121.789 439.362 123.072 433.181 125.56 427.24C111.118 421.46 86.969 411.185 64.0233 397.858C47.0949 388.064 31.4501 376.825 19.6563 367.593C8.50435 390.794 2.32666 417.045 2.32666 445.704C2.32666 560.181 99.1639 635 224.242 635C230.099 635 235.795 634.759 241.411 634.518V634.679C364.724 629.14 461 550.949 461 444.901C461 305.699 340.976 272.865 250.076 257.452Z"
				fill={Colors.accent}
			/>
			<circle cx="550" cy="567" r="68" fill={Colors.accent} />
		</svg>
	);

export default LogoIcon;
