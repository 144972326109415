import i18next from "i18next";

const Options = {
	label: i18next.t("fortnox.order.label", "Order"),
	value: "Fortnox.Order",
	options: [
		{ label: i18next.t("fortnox.order.fields.documentnummer.label", "Dokumentnummer"), value: "Fortnox.Order.DocumentNumber" },
		{ label: i18next.t("fortnox.order.fields.address1.label", "Adress 1"), value: "Fortnox.Order.Address1" },
		{ label: i18next.t("fortnox.order.fields.address2.label", "Adress 2"), value: "Fortnox.Order.Address2" },
		{ label: i18next.t("fortnox.order.fields.cancelled.label", "Annullerad"), value: "Fortnox.Order.Cancelled" },
		{ label: i18next.t("fortnox.order.fields.comments.label", "Kommentarer"), value: "Fortnox.Order.Comments" },
		{ label: i18next.t("fortnox.order.fields.copyRemarks.label", "Kopiera anmärkningar"), value: "Fortnox.Order.CopyRemarks" },
		{ label: i18next.t("fortnox.order.fields.remarks.label", "Anmärkningar"), value: "Fortnox.Order.Remarks" },
		{ label: i18next.t("fortnox.order.fields.currency.label", "Valuta"), value: "Fortnox.Order.Currency" },
		{ label: i18next.t("fortnox.order.fields.currencyRate.label", "Valutakurs"), value: "Fortnox.Order.CurrencyRate" },
		{ label: i18next.t("fortnox.order.fields.currencyUnit.label", "Valutaenhet"), value: "Fortnox.Order.CurrencyUnit" },
		{ label: i18next.t("fortnox.order.fields.customerName.label", "Kundnamn"), value: "Fortnox.Order.CustomerName" },
		{ label: i18next.t("fortnox.order.fields.customerNumber.label", "Kundnummer"), value: "Fortnox.Order.CustomerNumber" },
		{ label: i18next.t("fortnox.order.fields.country.label", "Land"), value: "Fortnox.Order.Country" },
		{ label: i18next.t("fortnox.order.fields.city.label", "Stad"), value: "Fortnox.Order.City" },
		{ label: i18next.t("fortnox.order.fields.deliveryState.label", "Leveransstatus"), value: "Fortnox.Order.DeliveryState" },
		{ label: i18next.t("fortnox.order.fields.deliveryAddress1.label", "Leveransadress 1"), value: "Fortnox.Order.DeliveryAddress1" },
		{ label: i18next.t("fortnox.order.fields.deliveryAddress2.label", "Leveransadress 2"), value: "Fortnox.Order.DeliveryAddress2" },
		{ label: i18next.t("fortnox.order.fields.deliveryCity.label", "Leveransstad"), value: "Fortnox.Order.DeliveryCity" },
		{ label: i18next.t("fortnox.order.fields.deliveryCountry.label", "Leveransland"), value: "Fortnox.Order.DeliveryCountry" },
		{ label: i18next.t("fortnox.order.fields.deliveryDate.label", "Leveransdatum"), value: "Fortnox.Order.DeliveryDate" },
		{ label: i18next.t("fortnox.order.fields.deliveryName.label", "Leveransnamn"), value: "Fortnox.Order.DeliveryName" },
		{ label: i18next.t("fortnox.order.fields.deliveryZipCode.label", "Postnummer för leverans"), value: "Fortnox.Order.DeliveryZipCode" },
		{
			label: i18next.t("fortnox.order.fields.emailInformation.label", "E-postinformation"),
			value: "Fortnox.Order.EmailInformation",
			options: [
				{
					label: i18next.t("fortnox.order.fields.emailInformation.emailAddressFrom.label", "E-postadress Från"),
					value: "Fortnox.Order.EmailInformation.EmailAddressFrom",
				},
				{
					label: i18next.t("fortnox.order.fields.emailInformation.emailAddressTo.label", "E-postadress Till"),
					value: "Fortnox.Order.EmailInformation.EmailAddressTo",
				},
				{
					label: i18next.t("fortnox.order.fields.emailInformation.emailAddressCC.label", "E-postadress CC"),
					value: "Fortnox.Order.EmailInformation.EmailAddressCC",
				},
				{
					label: i18next.t("fortnox.order.fields.emailInformation.emailAddressBCC.label", "E-postadress BCC"),
					value: "Fortnox.Order.EmailInformation.EmailAddressBCC",
				},
				{
					label: i18next.t("fortnox.order.fields.emailInformation.emailSubject.label", "E-postämne"),
					value: "Fortnox.Order.EmailInformation.EmailSubject",
				},
				{
					label: i18next.t("fortnox.order.fields.emailInformation.emailBody.label", "E-postkropp"),
					value: "Fortnox.Order.EmailInformation.EmailBody",
				},
			],
		},
		{
			label: i18next.t("fortnox.order.fields.externalInvoiceReference1.label", "Extern fakturareferens 1"),
			value: "Fortnox.Order.ExternalInvoiceReference1",
		},
		{
			label: i18next.t("fortnox.order.fields.externalInvoiceReference2.label", "Extern fakturareferens 2"),
			value: "Fortnox.Order.ExternalInvoiceReference2",
		},
		{ label: i18next.t("fortnox.order.fields.freight.label", "Frakt"), value: "Fortnox.Order.Freight" },
		{ label: i18next.t("fortnox.order.fields.freightVAT.label", "Moms på frakt"), value: "Fortnox.Order.FreightVAT" },
		{ label: i18next.t("fortnox.order.fields.gross.label", "Brutto"), value: "Fortnox.Order.Gross" },
		{ label: i18next.t("fortnox.order.fields.houseWork.label", "Hushållsarbete"), value: "Fortnox.Order.HouseWork" },
		{ label: i18next.t("fortnox.order.fields.invoiceReference.label", "Fakturareferens"), value: "Fortnox.Order.InvoiceReference" },
		{ label: i18next.t("fortnox.order.fields.labels.label", "Etiketter"), value: "Fortnox.Order.Labels" },
		{ label: i18next.t("fortnox.order.fields.language.label", "Språk"), value: "Fortnox.Order.Language" },
		{ label: i18next.t("fortnox.order.fields.net.label", "Netto"), value: "Fortnox.Order.Net" },
		{ label: i18next.t("fortnox.order.fields.notCompleted.label", "Ej slutförd"), value: "Fortnox.Order.NotCompleted" },
		{ label: i18next.t("fortnox.order.fields.offerReference.label", "Anbudreferens"), value: "Fortnox.Order.OfferReference" },
		{ label: i18next.t("fortnox.order.fields.orderDate.label", "Orderdatum"), value: "Fortnox.Order.OrderDate" },
		{ label: i18next.t("fortnox.order.fields.orderType.label", "Ordertyp"), value: "Fortnox.Order.OrderType" },
		{ label: i18next.t("fortnox.order.fields.organisationNumber.label", "Organisationsnummer"), value: "Fortnox.Order.OrganisationNumber" },
		{ label: i18next.t("fortnox.order.fields.ourReference.label", "Vår referens"), value: "Fortnox.Order.OurReference" },
		{
			label: i18next.t("fortnox.order.fields.rows.label", "Rader"),
			value: "Fortnox.Order.OrderRows",
			type: "array",
			options: [
				{ label: i18next.t("fortnox.order.fields.rows.articleNumber.label", "Artikelnummer"), value: "ArticleNumber" },
				{ label: i18next.t("fortnox.order.fields.rows.description.label", "Beskrivning"), value: "Description" },
				{ label: i18next.t("fortnox.order.fields.rows.deliveredQuantity.label", "Levererad kvantitet"), value: "DeliveredQuantity" },
				{ label: i18next.t("fortnox.order.fields.rows.orderedQuantity.label", "Beställd kvantitet"), value: "OrderedQuantity" },
				{ label: i18next.t("fortnox.order.fields.rows.price.label", "Pris"), value: "Price" },
				{ label: i18next.t("fortnox.order.fields.rows.total.label", "Totalt"), value: "Total" },
				{ label: i18next.t("fortnox.order.fields.rows.unit.label", "Enhet"), value: "Unit" },
				{ label: i18next.t("fortnox.order.fields.rows.vat.label", "Moms"), value: "VAT" },
				{ label: i18next.t("fortnox.order.fields.rows.discount.label", "Rabatt"), value: "Discount" },
			],
		},
	],
};

export default Options;
