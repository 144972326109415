import React, { Component } from "react";
import { Scrollable, ChoiceList, FormLayout, Select } from "@shopify/polaris";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import svLocale from "@fullcalendar/core/locales/sv";

import { withTranslation } from "react-i18next";
import $ from "jquery";
import Page from "src/js/components/page";
import API from "../../API";
import MissingColumnValuesSheet from "./components/MissingColumnValuesSheet";
import Toast from "src/js/components/Toast";
import Sheet from "../../components/sheet";

class BoardViewTimeline extends Component {
	constructor(props) {
		super(props);

		this.state = { events: [], resources: [] };
	}

	componentDidMount() {
		this.updateView(this.props);
		this.updateDefaultValues();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings == this.props.showSettings) {
			this.updateView(props);
		}
	}

	updateDefaultValues() {
		const values = [];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			const column = this.props.board.columns[i];
			if (column.type == "datetime" || column.type == "timeline") {
				if (this.props.view.value_columns.length < 1 || this.props.view.value_columns.indexOf(column.id) >= 0) {
					let val;
					if (column.type == "datetime") {
						val = { datetime: moment().format("YYYY-MM-DD HH") + ":00" };
					}
					if (column.type == "timeline") {
						val = {
							start: moment().format("YYYY-MM-DD HH") + ":00",
							end: moment().add("1", "hours").format("YYYY-MM-DD HH") + ":00",
						};
					}
					values.push({ column_id: column.id, value: val });
				}
			}
		}
		this.props.onChangeDefaultValues(values);
		this.setState({ defaultValues: values });
	}

	getColorByName(name) {
		const colors = ["#47c1bf", "#de3618", "#f49342", "#50b83c", "#006fbb", "#9c6ade"];

		if (!name) {
			return colors[0];
		}
		return colors[name.charCodeAt(0) % colors.length];
	}

	updateView(props) {
		let resources = [];
		let groupByColumn = null;

		if (props.view.group_by == "group") {
			resources = [];
			for (let i = 0; i < props.groups.length; i++) {
				resources.push({
					id: props.groups[i].id + "",
					eventColor: props.groups[i].color,
					title: props.groups[i].title,
				});
			}
		} else {
			for (let i = 0; i < props.board.columns.length; i++) {
				if (props.board.columns[i].id == props.view.group_by) {
					groupByColumn = props.board.columns[i];
					break;
				}
			}

			if (groupByColumn) {
				resources = [
					{
						id: "none",
						title: this.props.t("board.terms.none", "Ingen") + " " + groupByColumn.title,
					},
				];

				if (groupByColumn.type == "status") {
					for (let i = 0; i < groupByColumn.options.statuses.length; i++) {
						let found = false;
						for (let s = 0; s < props.rows.length; s++) {
							const row = props.rows[s];
							for (let x = 0; x < row.values.length; x++) {
								if (row.values[x].column_id == groupByColumn.id) {
									if (row.values[x].value || row.values[x].value === 0 || row.values[x].value === "0") {
										if (row.values[x].value == groupByColumn.options.statuses[i].id) {
											found = true;
											break;
										}
									}
								}
							}
							if (found) {
								break;
							}
						}
						if (found) {
							resources.push({
								id: groupByColumn.options.statuses[i].id + "",
								eventColor: groupByColumn.options.statuses[i].color,
								name: groupByColumn.options.statuses[i].label,
							});
						}
					}
				} else if (groupByColumn.type == "person") {
					for (let i = 0; i < props.board.members.length; i++) {
						let found = false;
						for (let s = 0; s < props.rows.length; s++) {
							const row = props.rows[s];
							for (let x = 0; x < row.values.length; x++) {
								if (row.values[x].column_id == groupByColumn.id) {
									if (row.values[x].value && row.values[x].value.indexOf(props.board.members[i].user_id) >= 0) {
										found = true;
										break;
									}
								}
							}
							if (found) {
								break;
							}
						}
						if (found) {
							resources.push({
								id: props.board.members[i].user_id,
								color: this.getColorByName(props.board.members[i].title),
								name: props.board.members[i].title,
							});
						}
					}
				}
			} else {
				resources = [{ id: 0, name: this.props.t("board.terms.all_data", "All data") }];
			}
		}

		let colorByColumn = null;
		if (props.view.color_by != "group") {
			for (let i = 0; i < props.board.columns.length; i++) {
				if (props.board.columns[i].id == props.view.color_by) {
					colorByColumn = props.board.columns[i];
					break;
				}
			}
		}

		const events = [];

		const columns = {};
		for (let i = 0; i < props.board.columns.length; i++) {
			columns[props.board.columns[i].id + ""] = props.board.columns[i];
		}

		for (let i = 0; i < props.rows.length; i++) {
			const row = props.rows[i];
			// let color = "#666666";
			let resourceIds = [];
			if (props.view.group_by == "group") {
				resourceIds = [row.group_id];
			} else if (groupByColumn) {
				resourceIds = ["none"];
			} else {
				resourceIds = [0];
			}

			if (colorByColumn && (colorByColumn.type == "status" || (colorByColumn.type == "person" && colorByColumn !== groupByColumn))) {
				for (let s = 0; s < row.values.length; s++) {
					const value = row.values[s];
					const column = columns[value.column_id + ""];
					if (column.id == colorByColumn.id) {
						if (value.value || value.value === 0 || value.value === "0") {
							if (colorByColumn.type == "status") {
								// for (let x = 0; x < colorByColumn.options.statuses.length; x++) {
								// 	if (x == value.value) {
								// 		color = colorByColumn.options.statuses[x].color;
								// 		break;
								// 	}
								// }
							} else if (colorByColumn.type == "person" && value.value.length) {
								// for (let x = 0; x < this.props.board.members.length; x++) {
								// 	if (this.props.board.members[x].user_id == value.value[0]) {
								// 		color = this.getColorByName(this.props.board.members[x].title);
								// 		break;
								// 	}
								// }
							}
						}
					}
				}
			}
			//  else {
			// 	for (let s = 0; s < props.groups.length; s++) {
			// 		if (props.groups[s].id == row.group_id) {
			// 			color = props.groups[s].color;
			// 			break;
			// 		}
			// 	}
			// }

			if (groupByColumn) {
				for (let s = 0; s < row.values.length; s++) {
					const value = row.values[s];
					const column = columns[value.column_id + ""];
					if (column.id == groupByColumn.id) {
						if (value.value || value.value === 0 || value.value === "0") {
							if (column.type == "person" && value.value.length) {
								resourceIds = value.value;
								break;
							} else if (column.type == "status") {
								resourceIds = [value.value];
							}
						}
					}
				}
			}
			for (let s = 0; s < row.values.length; s++) {
				const value = row.values[s];
				const column = columns[value.column_id + ""];

				if (column.type == "datetime" || column.type == "timeline") {
					if (value.value && (props.view.value_columns.length < 1 || props.view.value_columns.indexOf(column.id) >= 0)) {
						let taskStart;
						let taskEnd;

						if (column.type == "datetime") {
							if (value.value.datetime && value.value.datetime.length > 10) {
								// Have time
								taskStart = value.value.datetime + ":00";
								taskEnd = moment(value.value.datetime).add(1, "hour").format("YYYY-MM-DD HH:mm") + ":00";
							} else {
								// Only date
								taskStart = value.value.datetime + " 00:00:00";
								taskEnd = moment(value.value.datetime).add(1, "day").format("YYYY-MM-DD") + " 00:00:00";
							}
						} else if (column.type == "timeline") {
							taskStart = value.value.start + ":00";
							taskEnd = value.value.end + ":00";
						}

						for (let x = 0; x < resourceIds.length; x++) {
							// if (groupByColumn && colorByColumn && colorByColumn.type == "person" && colorByColumn === groupByColumn) {
							// 	for (let z = 0; z < this.props.board.members.length; z++) {
							// 		if (this.props.board.members[z].user_id == resourceIds[x]) {
							// 			color = this.getColorByName(this.props.board.members[z].title);
							// 			break;
							// 		}
							// 	}
							// }
							events.push({
								id: row.id + "_" + column.id,
								start: taskStart.replace(" ", "T"),
								end: taskEnd.replace(" ", "T"),
								row,
								column,
								resourceId: resourceIds[x] + "",
								title: row.title,
								startEditable: column.type == "timeline",
								durationEditable: column.type == "timeline",
								editable: true,
								resourceEditable: true,
							});
						}
					}
				}
			}
		}

		this.setState({
			events: events.sort((a, b) => moment(a.start).unix() - moment(b.start).unix()),
			resources,
			resourceName: props.view.group_by == "group" ? this.props.t("board.terms.group", "Grupp") : groupByColumn.title,
		});
	}

	getColumnOptions(types) {
		const arr = [];
		if (types.indexOf("group") >= 0) {
			arr.push({
				value: "group",
				label: this.props.t("board.terms.groups", "Grupper"),
			});
		}
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push({
					value: this.props.board.columns[i].id + "",
					label: this.props.board.columns[i].title,
				});
			}
		}
		return arr;
	}

	render() {
		const validViewValueColumnOptions = this.getColumnOptions(["datetime", "timeline"]);
		const currentViewValueColumns = [];

		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (this.props.board.columns[i].type == "datetime" || this.props.board.columns[i].type == "timeline") {
				if (this.props.view.value_columns.length < 1 || this.props.view.value_columns.indexOf(this.props.board.columns[i].id) >= 0) {
					currentViewValueColumns.push(this.props.board.columns[i]);
				}
			}
		}

		const groupByOptions = this.getColumnOptions(["group", "status", "person"]);

		const colorByOptions = this.getColumnOptions(["group", "status", "person"]);

		return (
			<div>
				{this.props.header}
				<Page fullWidth>
					<FullCalendar
						resourceAreaHeaderContent={this.state.resourceName}
						schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
						initialView="resourceTimelineDay"
						eventClick={(info) => {
							this.props.editRow(info.event.extendedProps.row);
						}}
						eventResize={(info) => {
							const event = info.event;
							const newStart = event.start.toISOString();
							const newEnd = event.end.toISOString();

							const data = {
								value: {
									start: moment(newStart).format("YYYY-MM-DD HH:mm"),
									end: moment(newEnd).format("YYYY-MM-DD HH:mm"),
								},
							};

							API.post(
								"/api/boards/" +
									this.props.board.id +
									"/rows/" +
									event.extendedProps.row.id +
									"/columns/" +
									event.extendedProps.column.id +
									"/values.json",
								data,
								{ params: {} }
							)
								.then((result) => {
									if (result.data.error) {
										Toast.error(result.data.error);
										return;
									}
									this.props.onUpdateRow(result.data.row);
								})
								.catch((error) => {
									Toast.error(error);
								});
						}}
						eventDrop={(info) => {
							const event = info.event;
							const newStart = event.start.toISOString();
							const newEnd = event.end.toISOString();
							let newSlotId;
							if (info.newResource) {
								newSlotId = info.newResource.id;
							} else {
								newSlotId = event.resourceId;
							}

							const row = event.extendedProps.row;

							let data;
							if (event.extendedProps.column.type == "timeline") {
								data = {
									value: {
										start: moment(newStart).format("YYYY-MM-DD HH:mm"),
										end: moment(newEnd).format("YYYY-MM-DD HH:mm"),
									},
								};
							} else if (event.extendedProps.column.type == "datetime") {
								let currentValue;
								for (let i = 0; i < row.values.length; i++) {
									if (row.values[i].column_id == event.extendedProps.column.id) {
										currentValue = row.values[i];
										break;
									}
								}
								if (currentValue.value.datetime && currentValue.value.datetime.length > 10) {
									// Have time
									data = {
										value: {
											datetime: moment(newStart).format("YYYY-MM-DD HH:mm"),
										},
									};
								} else {
									// Only date
									data = {
										value: { datetime: moment(newStart).format("YYYY-MM-DD") },
									};
								}
							}

							for (let i = 0; i < row.values.length; i++) {
								if (row.values[i].column_id == event.extendedProps.column.id) {
									Object.assign(row.values[i], data);
									break;
								}
							}

							if (this.props.view.group_by == "group") {
								if (newSlotId != row.group_id) {
									row.group_id = newSlotId;
								}
							} else {
								let groupByColumn = null;
								for (let i = 0; i < this.props.board.columns.length; i++) {
									if (this.props.board.columns[i].id == this.props.view.group_by) {
										groupByColumn = this.props.board.columns[i];
										break;
									}
								}

								if (groupByColumn) {
									let currentValue = null;
									for (let x = 0; x < row.values.length; x++) {
										if (row.values[x].column_id == groupByColumn.id) {
											currentValue = row.values[x].value;
											break;
										}
									}

									if (newSlotId == "none") {
										newSlotId = null;
									}

									let data = null;

									if (groupByColumn.type == "status" && currentValue !== newSlotId) {
										data = { value: newSlotId };
									} else if (groupByColumn.type == "person") {
										if (newSlotId && (!currentValue || !currentValue.length)) {
											data = { value: [newSlotId] };
										} else if (!newSlotId) {
											data = { value: [] };
										} else if (newSlotId && currentValue.indexOf(newSlotId) < 0) {
											data = { value: [newSlotId] };
										}
									}

									if (data !== null) {
										for (let i = 0; i < row.values.length; i++) {
											if (row.values[i].column_id == groupByColumn.id) {
												Object.assign(row.values[i], data);
												break;
											}
										}
									}
								}
							}
							API.put("/api/boards/" + this.props.board.id + "/rows/" + row.id + ".json", row, {
								params: {},
							})
								.then((result) => {
									if (result.data.error) {
										Toast.error(result.data.error);
										return;
									}
									this.props.onUpdateRow(result.data.row);
								})
								.catch((error) => {
									Toast.error(error);
								});
						}}
						height={$(window).height() - ($(".Polaris-TopBar").length ? 56 : 0) - ($(".board-main-header").height() + 50)}
						nowIndicator
						firstDay={1}
						businessHours={{
							daysOfWeek: [1, 2, 3, 4, 5],
							startTime: "08:00",
							endTime: "17:00",
						}}
						slotDuration="00:15:00"
						locale={svLocale}
						customButtons={{
							itemList: {
								text: this.props.t("board.terms.more.rows", "Fler rader"),
								click: () => {
									this.setState({ showMissing: true });
								},
							},
						}}
						headerToolbar={{
							left: "prev,next today",
							center: "title",
							right: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth itemList",
						}}
						plugins={[resourceTimelinePlugin, interactionPlugin]}
						events={this.state.events}
						resources={this.state.resources}
					/>
				</Page>
				<MissingColumnValuesSheet
					columns={currentViewValueColumns}
					board={this.props.board}
					rows={this.props.rows}
					groups={this.props.groups}
					open={this.state.showMissing}
					onClose={() => {
						this.setState({ showMissing: false });
					}}
					onUpdateRow={this.props.onUpdateRow}
					defaultValues={this.state.defaultValues}
					onEditRow={(row) => {
						this.setState({ showMissing: false });
						this.props.editRow(row);
					}}
					onCreateRow={() => {
						this.setState({ showMissing: false });
						this.props.editRow(null);
					}}
				/>
				<Sheet open={this.props.showSettings} onClose={this.props.onCloseSettings} title={this.props.t("board.settings.title", "Inställningar")}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ padding: "1.0000rem", height: "100%" }}>
							<FormLayout>
								<ChoiceList
									allowMultiple
									title={this.props.t("board.fields.value_columns.label", "Tidskolumner")}
									choices={validViewValueColumnOptions}
									selected={this.props.view.value_columns}
									onChange={(v) => {
										this.props.view.value_columns = v;
										this.props.onUpdateView(this.props.view);
										this.updateDefaultValues();
									}}
								/>
								<Select
									label={this.props.t("board.fields.group_by.label", "Gruppera på")}
									options={groupByOptions}
									value={this.props.view.group_by}
									onChange={(v) => {
										this.props.view.group_by = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
								<Select
									label={this.props.t("board.fields.color_by.label", "Färg från")}
									options={colorByOptions}
									value={this.props.view.color_by}
									onChange={(v) => {
										this.props.view.color_by = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
							</FormLayout>
						</Scrollable>
					</div>
				</Sheet>
			</div>
		);
	}
}
export default withTranslation(["board"], { withRef: true })(BoardViewTimeline);
