import moment from "moment";
import { store } from "src/js/store";

const getDefaultForm = (history: any) => {
	let data: Partial<OrderType> = {
		order_date: moment().format("YYYY-MM-DD"),
		customer_fields: {},
		delivery_fields: {
			delivery_date: moment().format("YYYY-MM-DD"),
		},
		order_rows: [],
		our_reference: store.getState().user?.name || "",
	};

	if (history?.location?.state?.data) {
		const order = history.location.state.data;

		data = {
			...data,
			...order,
		};
	}

	if (history?.location?.state?.contact) {
		const contact = history.location.state.contact;
		if (!data.customer_fields) data.customer_fields = {};
		if (!data.delivery_fields) data.delivery_fields = {};
		data.customer_fields.customer = contact;

		data.customer_fields.name = contact.name;
		data.customer_fields.organisation_number = contact.orgnr;
		data.customer_fields.address = contact.address1;
		data.customer_fields.address2 = contact.address2;
		data.customer_fields.postal_code = contact.zip;
		data.customer_fields.city = contact.city;
		data.customer_fields.country = contact.country;
		data.customer_fields.phone = contact.phone;
		data.customer_fields.email = contact.email;

		data.delivery_fields.name = contact.name;
		data.delivery_fields.address = contact.address1;
		data.delivery_fields.address2 = contact.address2;
		data.delivery_fields.postal_code = contact.zip;
		data.delivery_fields.city = contact.city;
		data.delivery_fields.country = contact.country;
	}

	return data;
};
export default getDefaultForm;
