import { useMutation, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import { formatNumberToRead, formatNumberToWrite } from "../Utilities/formatNumbers";
import { store } from "src/js/store";
import getTargetConnectionsWithData from "../Utilities/getTargetConnectionsWithData";
import Toast from "src/js/components/Toast";

type SetFormFunction = (form: Partial<TargetType> | null | ((prevForm: Partial<TargetType>) => Partial<TargetType>)) => void;

type TargetContextValue = {
	form: Partial<TargetType>;
	target: Partial<TargetType> | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	isSaving: boolean;
	isLoading: boolean;
	id: string | null;
	history?: any;
	handleSave: (target: TargetType) => any;
	refreshTarget: () => void;
	date: string | null;
	setDate: (date: string) => void;
	t: WithTranslation["t"];
};

const TargetContext = React.createContext({} as TargetContextValue);

export const TargetContextProvider = withTranslation(["target", "common"])(({ id: propsId, match, history, children, t, defaultData = null }) => {
	const [form, setForm] = useState<Partial<TargetType>>(defaultData || { number_of_decimals: 0, number_of_periods_per_group: 1 });
	const [date, setDate] = useState<string | null>(new URLSearchParams(window.location.search).get("group_start_at") || moment().format("YYYY-MM-DD"));

	const id = propsId || match?.params?.id || null;
	const queryClient = useQueryClient();

	const params = useMemo(
		() => ({
			group_start_at: date,
		}),
		[date]
	);

	const queryKey = [id && `target-${id}`, params].filter(Boolean);
	const queryEnabled = !!id;

	const fetch = useCallback(async () => {
		try {
			const res = await API.get(`/api/targets/${id}.json`, { params });

			return {
				...res.data.target,
				target_connections: getTargetConnectionsWithData(res.data.target?.target_connections),
			};
		} catch (error) {
			Toast.error(error);
			return defaultData || null;
		}
	}, [id, defaultData, params]);
	const {
		data: target = null,
		isFetching,
		refetch: refreshTarget,
	}: UseQueryResult<TargetType | null> = useQuery({
		queryKey,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.data || defaultData || null,
		enabled: queryEnabled,
	});

	const handleFormatNumbersToWrite = useCallback((target) => {
		if (target?.target_connections) {
			const newForm = { ...target };
			newForm.target_connections = newForm.target_connections.map((tc) => {
				tc.target_connection_values = tc.target_connection_values.map((tcv) => {
					if (tcv.value) tcv.value = formatNumberToWrite(tcv.value);
					return tcv;
				});
				return tc;
			});
			return newForm;
		}

		return target;
	}, []);

	const handleFormatNumbersToRead = useCallback((target) => {
		if (target?.target_connections) {
			const newForm = { ...target };

			newForm.target_connections = newForm.target_connections.map((tc) => {
				tc.target_connection_values = tc.target_connection_values.map((tcv) => {
					if (tcv.value) tcv.value = formatNumberToRead(tcv.value, newForm.number_of_decimals);
					return tcv;
				});
				return tc;
			});
			return newForm;
		}

		return target;
	}, []);

	useEffect(() => {
		if (target) {
			try {
				setForm(handleFormatNumbersToRead(structuredClone({ ...target })));
			} catch (e) {
				setForm(handleFormatNumbersToRead(JSON.parse(JSON.stringify({ ...target }))));
			}
		}
	}, [target, handleFormatNumbersToRead]);

	const saveFunction = useCallback(
		async (target: TargetType) => {
			const endpoint = !id ? `/api/targets.json` : `/api/targets/${id}.json`;

			const res = await (id ? API.put(endpoint, { ...target }, { params }) : API.post(endpoint, { ...target }, { params }));

			const successMessage = id ? t("targets.responses.saved", "Mål uppdaterat") : t("targets.responses.created", "Mål skapat");

			const myPermission = res.data.target?.target_permissions?.find((tp) => tp.user?.id === store.getState().user.id);
			if ((myPermission?.read_permission || myPermission?.read_all_permission) && !res.data.target.disabled) {
				store.dispatch({ type: id ? "UPDATE_TARGET" : "ADD_TARGET", target: res.data.target });
			} else {
				store.dispatch({ type: "REMOVE_TARGET", id });
			}

			Toast.success(successMessage);

			history.replace(`/admin/targets/${res.data.target.id}`, {
				data: res.data.target,
			});

			return res;
		},
		[id, t, history, params]
	);

	const update = async ({ target }) => {
		const response = await saveFunction(target);
		return response?.data.target;
	};

	const mutation = useMutation(update, {
		onSuccess: (data) => {
			if (queryEnabled) queryClient.setQueryData(queryKey, data);
		},
	});

	const handleSave = useCallback(
		async (target: TargetType) => {
			try {
				if (!target) {
					throw new Error("No target");
				}

				return await mutation.mutateAsync({ target: handleFormatNumbersToWrite(target) });
			} catch (e: any) {
				if (!e?.response) throw e;
				Toast.error(e);
			}
		},
		[mutation, handleFormatNumbersToWrite]
	);

	const isSaving = mutation.isLoading;

	const value = useMemo(() => {
		return {
			id,
			form,
			setForm: setForm as SetFormFunction, // Update the type of setForm
			isFetching,
			target,
			history,
			isSaving,
			handleSave,
			isLoading: isFetching || isSaving,
			refreshTarget,
			date,
			setDate,
			t,
		};
	}, [form, id, setForm, history, isFetching, isSaving, handleSave, target, refreshTarget, date, setDate, t]);

	return <TargetContext.Provider value={value}>{children}</TargetContext.Provider>;
});

export default TargetContext;
