import { Button, Popover } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class RemoveButtonPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Popover
				active={this.state.removeModalOpen}
				activator={
					<span className="RemoveButtonPopup_activator">
						<Button
							plain={this.props.plain}
							destructive={this.props.destructive}
							icon={this.props.icon}
							loading={this.props.loading}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								if (this.props.skipConfirm) {
									this.props.onRemove();
								} else {
									this.setState({ removeModalOpen: true });
								}
							}}
						>
							{this.props.children || null}
						</Button>
					</span>
				}
				onClose={(e) => this.setState({ removeModalOpen: false })}
			>
				<div
					className="popup-confirm"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						this.setState({ removeModalOpen: false });
					}}
				>
					<h1>{this.props.title}</h1>
					<div className="popup-confirm_buttons">
						<Button destructive onClick={this.props.onRemove} loading={this.props.loading} size="slim">
							{this.props.actionTitle || this.props.t("common.actions.remove", "Ta bort")}
						</Button>
						<Button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								this.setState({ removeModalOpen: false });
							}}
							size="slim"
						>
							{this.props.t("common.actions.cancel", "Avbryt")}
						</Button>
					</div>
				</div>
			</Popover>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(RemoveButtonPopup);

RemoveButtonPopup.defaultProps = {
	plain: false,
	destructive: true,
	icon: DeleteMajor,
};
