export default (state = {}, action) => {
	switch (action.type) {
		case "SET_BOARD_TICKETS": {
			const tickets = state;
			for (let i = 0; i < action.tickets.length; i++) {
				const row = action.tickets[i];
				const rowId = row.id;
				tickets[rowId] = row;
			}
			return tickets;
		}
		case "UPDATE_BOARD_TICKET": {
			state[action.ticket.id] = action.ticket;
			if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
				// Send to Apps Webview
				window.ReactNativeWebView.postMessage(JSON.stringify(action));
			}
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};
